import FlexDefEngData from '../data/EngData5094.json';
import FlexChassisEngData from '../data/ChassisEngData5094.json';
import { IEngDataComponent, makeEngInfoFrom } from "../../../engData/EngineeringInfo";
import { EngInfoPackage } from "../../../types/EngDataTypes";
import { logger } from '../../../util/Logger';
import { getModulesInPkg } from '../../../util/EngInfoHelp';
import { isSizeEmpty } from '../../../util/GeneralHelpers';
import { PlatformFlex } from '../../PlatformConstants';
import { EngDataImplementation, RegisterEngDataImpl } from '../../../implementation/ImplEngData';



const loadChassis = (pkg: EngInfoPackage) => {
    const chasData = FlexChassisEngData as IEngDataComponent[];
    if (chasData) {
        for (let idx = 0; idx < chasData.length; idx++) {
            const entry = chasData[idx];
            const engInfo = makeEngInfoFrom(entry);
            if (engInfo && engInfo.isChassis) {
                if (engInfo.catNo) {
                    engInfo.isPlaceholder = true;
                    if (!pkg.infoMap.has(engInfo.catNo)) {
                        pkg.infoMap.set(engInfo.catNo, engInfo);
                    }
                    else {
                        logger.error('Flex::loadChassis(): Dup chassis in loadChassis: ' + engInfo.catNo);
                    }
                }
                else {
                    logger.error('Flex::loadChassis(): entry missing catNo!');
                }
            }
            else {
                logger.error('Flex::loadChassis(): unexpected makeEngInfoFrom result!');
            }
        }
    }
    else {
        logger.error('Flex::loadChassis(): no data?');
    }
}

const flexGetDfltEngDataJSON = (): object => {
    return FlexDefEngData;
}


const flexEngDataPostLoad = async (pkg: EngInfoPackage) => {
    const modules = getModulesInPkg(pkg);
    modules.forEach(mod => {
        if (isSizeEmpty(mod.imgSize)) {
            // It appears all images are 136px wide.
            // Heights may vary from 121 to 129 px.
            mod.imgSize.height = 129;
            mod.imgSize.width = 136;
        }
    });
    loadChassis(pkg);

    return;
}

export const RegisterFlexEngDataImpl = () => {
    const flexImpl: EngDataImplementation = {
        platform: PlatformFlex,
        preload: undefined,
        getDfltEngData: flexGetDfltEngDataJSON,
        postLoad: flexEngDataPostLoad,
    };

    RegisterEngDataImpl(flexImpl);
}
