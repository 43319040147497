import React, { useState } from "react";
import { Shape } from "react-konva";
import Konva from 'konva';
import { Point } from "../../types/SizeAndPosTypes";
import { logger } from "../../util/Logger";
import { FancyPathProps } from "../../util/CableHelp";


enum PathDir {
    Up = 'Up',
    Down = 'Down',
    Left = 'Left',
    Right = 'Right',
}

const getPathSegInfo = (pt1: Point, pt2: Point): [PathDir, number] => {
    if (pt1.x === pt2.x) {
        return (pt1.y < pt2.y)
            ? [PathDir.Down, pt2.y - pt1.y]
            : [PathDir.Up, pt1.y - pt2.y];
    }
    else if (pt1.y === pt2.y) {
        return (pt1.x < pt2.x)
            ? [PathDir.Right, pt2.x - pt1.x]
            : [PathDir.Left, pt1.x - pt2.x];
    }
    else {
        throw new Error('getPathSegInfo - invalid point pair?');
    }
}

const getCurveStartAndEndPts = (
    ptCorner: Point,
    dirIn: PathDir,
    dirOut: PathDir,
    radius: number
): [Point, Point] => {
    const ptStart: Point = { x: 0, y: 0 };
    const ptEnd: Point = { x: 0, y: 0 };

    switch (dirIn) {
        case PathDir.Up:
            ptStart.x = ptCorner.x;
            ptStart.y = ptCorner.y + radius;
            break;

        case PathDir.Down:
            ptStart.x = ptCorner.x;
            ptStart.y = ptCorner.y - radius;
            break;

        case PathDir.Left:
            ptStart.x = ptCorner.x + radius;
            ptStart.y = ptCorner.y;
            break;

        case PathDir.Right:
            ptStart.x = ptCorner.x - radius;
            ptStart.y = ptCorner.y;
            break;
    }

    switch (dirOut) {
        case PathDir.Up:
            ptEnd.x = ptCorner.x;
            ptEnd.y = ptCorner.y - radius;
            break;

        case PathDir.Down:
            ptEnd.x = ptCorner.x;
            ptEnd.y = ptCorner.y + radius;
            break;

        case PathDir.Left:
            ptEnd.x = ptCorner.x - radius;
            ptEnd.y = ptCorner.y;
            break;

        case PathDir.Right:
            ptEnd.x = ptCorner.x + radius;
            ptEnd.y = ptCorner.y;
            break;
    }

    return [ptStart, ptEnd];
}


interface Props {
    pathProps: FancyPathProps;
    mouseSensitive?: boolean;
    onMouseDown?: (e: Konva.KonvaEventObject<MouseEvent>) => void;
    onContextMenu?: (e: Konva.KonvaEventObject<MouseEvent>) => void;
    onDblClick?: (e: Konva.KonvaEventObject<MouseEvent>) => void;
}

const FancyPath = (props: Props) =>{

    const [isMouseOver, setIsMouseOver] = useState(false);


    const drawScene = (context: Konva.Context, shape: Konva.Shape) => {

        if (props.pathProps.pts.length < 2) {
            logger.error('FancyPath with invalid number of pts?');
            return;
        }

        // We'll work with 'this' and 'last'
        // data pairs for our pts, as well as
        // for the directions and distances of
        // each segment along the path.
        // Start by thisPt as the first pt in our path.
        // This one will NOT get a radiused corner.
        let thisPt = props.pathProps.pts[0];

        // Start our drawing at that point.
        context.beginPath();
        context.moveTo(thisPt.x, thisPt.y);

        // Remember our start point as our previous
        // point and set our thisPt to be the second
        // point in our path. If we have more than
        // two points, it'll be the first 'interim'
        // point that we'll radius. If we just have
        // two, it'll be the end point.
        let prevPt = thisPt;
        thisPt = props.pathProps.pts[1];

        // See how many pts we actually have. We'll
        // almost ALWAYS have more than 2, but if our
        // path is truly straight, we'll have 2.
        // Our last idx will be one less than that.
        const lastPtIdx = props.pathProps.pts.length - 1;

        // If we have any interim points...
        if (lastPtIdx > 1) {
            // Initialize our previous direction and distance
            // info to be for the first path segment.
            let [prevDir, prevDist] = getPathSegInfo(prevPt, thisPt);

            // Now, walk all of the rest of our
            // path points. For each one...
            for (let idx = 2; idx <= lastPtIdx; idx++) {
                // Our prev pt become our 
                // prior thisPt. 
                prevPt = thisPt;

                // Set our new thisPt.
                thisPt = props.pathProps.pts[idx];

                // At this point, we know that we have a radiused
                // corner case, and the related info includes: 
                //   prevPt -   the turn in the path that we
                //              actually want to add a radius for
                //   thisPt -   the path point FOLLOWING our turn
                //   prevDir -  the direction of the path segment
                //              leading up to prevPt.
                //   prevDist - the point-to-point length of
                //              that segment (leading up to prevPt)
                // Note that the prevDir and prevDist at his point are
                // either what we entered our loop with, or are the
                // leftovers from the LAST loop iteration.

                // Get direction and distance info about the
                // segment FOLLOWING our current path turn using
                // the NEW prevPt and thisPt.
                const [thisDir, thisDist] = getPathSegInfo(prevPt, thisPt);

                // Determine how big our actual radius can be.
                // Normally, it'll be our default size, but we
                // wont let it get any larger than half the length
                // of either of it's 'leg' segments that it connects.
                const maxRadPrev = (idx === 2) ? prevDist : prevDist / 2;
                const maxRadNext = (idx === lastPtIdx) ? thisDist : thisDist / 2;
                const radius = Math.min(props.pathProps.maxRadius, maxRadPrev, maxRadNext);

                // Call our helper to give us the points where
                // the curve actually starts and ends.
                const [curveStart, curveEnd] = getCurveStartAndEndPts(prevPt, prevDir, thisDir, radius);

                // Next, draw the path segment leading
                // up to the start of the curve.
                context.lineTo(curveStart.x, curveStart.y);

                // Now draw the actual curve itself. The
                // first two args here are the x,y of the
                // 'control point' of the curve, which is
                // our corner (prevPt). The last two are
                // the x,y of where we want the curve to be
                // drawn to (its end point).
                context.quadraticCurveTo(
                    prevPt.x,
                    prevPt.y,
                    curveEnd.x,
                    curveEnd.y
                );

                prevDir = thisDir;
                prevDist = thisDist;
            }
        }

        // Draw in the last segment.
        context.lineTo(thisPt.x, thisPt.y);

        // And finalize our custom shape.
        context.fillStrokeShape(shape);
    }

    const getMouseRelatedExtras = () => {
        if (props.mouseSensitive === true) {
            return {
                onMouseDown: props.onMouseDown,
                onContextMenu: props.onContextMenu,
                onMouseOver: () => { setIsMouseOver(true) },
                onMouseOut: () => { setIsMouseOver(false) },
                shadowEnabled: isMouseOver,
                onDblClick: props.onDblClick
            };
        }
        else {
            return {
                listening: false,
                shadowEnabled: false
            };
        }
    }

    return (
        <Shape
            {...props.pathProps}
            sceneFunc={drawScene}
            {...getMouseRelatedExtras()}
        />
    );
}

export default FancyPath;
