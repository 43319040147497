import React, { useRef, useState, useEffect } from 'react';
import '../../common/ChassisConfig.scss';
import '../../../styles/Meteor.scss'
import { PlatformCLX } from '../../PlatformConstants';
import {
	StatusLevel,
	GroupChoiceInfo,
	ChoiceInfo,
	MessageCategory,
	MessageType,
	PanelMessage,
	createNewGrpChoiceInfo,
	BaseChoiceInfo,
	createNewGdSelGrpChoiceInfo,
} from '../../../types/MessageTypes';
import {
	Chassis,
	ChassisProject,
	ResultStatus,
	SupportedWiringType,
	SelectableDevice,
	LocAttributeInfo,
	ChassisCfgGrpCategory
} from '../../../types/ProjectTypes';
import { PSInputVoltage } from '../../../types/PowerTypes';
import {
	addChassis,
	detachModule,
	getChassisName,
	getLocAttrFromProject,
	isWiringTypeSupported,
	updateRackLayout
} from '../../../model/ChassisProject';
import {
	addButton,
	ModalRequestSpec,
	ModalStatus,
	requestModal
} from '../../../modals/ModalHelp';
import {
    addClxRedundancyMod,
	isClxRedundancyMod
} from '../model/CLXChassis';
import {
    AutoScaleFitType,
    autoScaleToContent,
    ChassisLayoutKey,
    requestPendingScrollToBtm
} from '../../../util/LayoutHelp';
//import { replaceCLXChassis } from '../model/CLXChassisReplacement';
import {
	CLXChassisCfgData,
	clxGetCLXChassisCfgDataFromLocAttr,
    clxPrepareLocAttrForChassisConfig,
} from '../model/CLXGuidedSelection';
import { chassisChanging, suspendUndoSnapshots } from '../../../util/UndoRedo';
import { Point } from '../../../types/SizeAndPosTypes';
import { IncludeOldStyleMsgPnlInChassisConfig } from '../../../types/Globals';
import { ProjectSetting } from '../../../types/SettingsTypes';
import { cloneLocAttributeInfo, getLocAttributeSetting } from '../../../model/GuidedSelection';
import { getChassisSlotUsage, replaceChassisPowerSupply } from '../../../implementation/ImplGeneral';
import { getLocAttrInfoForChassisEdit } from '../../../implementation/ImplHardwareGen';
import {
	cfgDlgGetGroupSelection,
	CfgDlgMsgDtls,
	CfgDlgTabID,
	cfgDlgUnspecifiedChassisName,
	cfgDlgUpdateMessages,
	ConfigChassisCBData,
	getChassisEnvType,
	InitCfgData,
	loadTipPanelMsgs,
} from '../../common/ChassisConfig';
import ChassisConfigDlg from '../../common/ChassisConfigComp';
import { cfgDlgGSSelectionChanged, cfgDlgInitializeLocAttrInfoFromChassis } from '../../common/ChassisConfigGuidedSel';
import { cfgDlgGetFirstPSAvailable, cfgDlgGetPSGroup, cfgDlgUpdatePSGroup } from '../../common/ChassisConfigPower';
import { cfgDlgAdjustWiringMapForSwaps, cfgDlgChangeWiringTypeOnMods, cfgDlgGetPossibleModWiringTypes } from '../../common/ChassisConfigWiring';
import { replaceChassis } from '../../common/ChassisConfigReplaceChassis';


const unexpected = (msg: string) => {
	alert(msg);
	throw new Error(msg);
}

const _situationalMsgInfo = new Map<MessageCategory, CfgDlgMsgDtls>();

let liveTipCallback = (category: MessageCategory, type: MessageType, pt: Point) => {
	category;
	type;
	pt;
	alert('No live tip callback established');
}

const tipCallback = (category: MessageCategory, type: MessageType, pt: Point) => {
	liveTipCallback(category, type, pt);
}

const getNumExistingRMMods = (chassis: Chassis): number => {
	let qty = 0;

	for (let slotIdx = 0; slotIdx <= chassis.modules.length; slotIdx++) {
		if (isClxRedundancyMod(chassis.modules[slotIdx])) {
			qty++;
		}
	}

	return qty;
}

const getInitialSelsFromChassis = (chassis: Chassis): CLXChassisCfgData => {

	const envType = getChassisEnvType(chassis);
	const numSlots = chassis.modules.length;
	const wiringType = chassis.defaultIOModWiring;
	const highAvail = chassis.redundant;
	const numRMs = highAvail
		? getNumExistingRMMods(chassis)
		: 0;
	const inputVoltage = (chassis && chassis.ps)
		? chassis.ps.inputVoltage
		: PSInputVoltage.DC24V;
	const psSel = (chassis && chassis.ps)
		? chassis.ps.catNo
		: cfgDlgGetFirstPSAvailable(chassis.platform, tipCallback, envType, inputVoltage);

	return {
		envType: envType,
		numSlots: numSlots,
		wiringType: wiringType,
		highAvail: highAvail,
		numRMs: numRMs,
		psVoltage: inputVoltage,
		psSelCatalog: psSel,
		chassisCatalog: chassis.catNo
	};
}

// Unique to CLX: Tied to checkAndUpdateRedundancy() - > configChassisCallback()
const removeRedundancyMods = (chassis: Chassis, numToRemove: number) => {
	if (numToRemove > 0) {
		let numRemoved = 0;
		for (let slotIdx = chassis.modules.length - 1; slotIdx >= 0; slotIdx--) {
			const mod = chassis.modules[slotIdx];
			if (mod && isClxRedundancyMod(mod)) {
				detachModule(mod);
				numRemoved++;
				if (numRemoved >= numToRemove) {
					return;
				}
			}
		}
	}
	else {
		throw new Error('Invalid call to removeRedundancyMods');
	}
}

// Unique to CLX: Tied to configChassisCallback()
const checkAndUpdateRedundancy = (chassis: Chassis, wantRed: boolean, origQtyRMs: number) => {
	// If we WANT redundancy (high availability)...
	if (wantRed) {

		// Then base our action on how many redundancy
		// modules were found to already be in the chassis.
		switch (origQtyRMs) {
			case 0:
				// Add an RM module if we don't 
				// alreadty have one.
				addClxRedundancyMod(chassis);
				break;

			case 1:
				// Nothing to do
				break;

			default:
				// Remove extra RM module(s), leaving just 1.
				removeRedundancyMods(chassis, origQtyRMs - 1);
				break;
		}
	}
	// We don't want redundancy (high availability).
	// If we had ANY existing RM modules in the chassis...
	else if (origQtyRMs > 0) {

		// Remove ALL of them.
		removeRedundancyMods(chassis, origQtyRMs);
	}
}


// Callback from the Modal.
const configChassisCallback = (status: number, data?: object) => {

	// If we got our 'go-ahead'...
	if (status === ModalStatus.Confirmed) {

		// Get our data object.
		const cbData = data as ConfigChassisCBData;

		// If we can...
		if (cbData) {
			// Get some information from the Guided selection.
			const gsData = clxGetCLXChassisCfgDataFromLocAttr(cbData.cfgAttrInfo);
			// Get the PSU selection.
			const psCat = cfgDlgGetGroupSelection(cbData.psGrp);
			if (!psCat) {
				// We really should not get here. Try to gracefully
				// handle this stituation instead of throwing an error.
				alert('Unable to determine selected Power Supply. Please try again.');
				return;
			}

			const newName = (cbData.chassisName.length > 0)
				? cbData.chassisName
				: cfgDlgUnspecifiedChassisName;

			// If we're editing an EXISTING chassis...
			if (cbData.chassis) {

				// Determine what, if anything actually changed.
				const changeChassis = (gsData.chassisCatalog !== cbData.origCat);
				const changeName = (getChassisName(cbData.chassis) !== newName);
				const changePS = (psCat !== cbData.origPSCat);
				const changeWiring = (gsData.wiringType !== cbData.chassis.defaultIOModWiring);

				// Note that for high availability, we'll not only compare
				// our selection to whata we had before. We'll also say that's
				// changing if we found MORE THAN one RM module in the existing
				// chassis. In that case, we'll fix that.
				const changeHighAvail = (
					(gsData.highAvail !== cbData.chassis.redundant) ||
					(cbData.origQtyRMMods > 1));

				const anyChanges = (changeChassis || changeName || changePS ||
					changeWiring || changeHighAvail);

				// If anything is actually changing...
				if (anyChanges) {
					// BEFORE making any change, call our helper to take
					// a snapshot of our CURRENT content for undo purposes.
					chassisChanging(cbData.chassis);

					// Then suspend those snapshots until we're
					// done here. If we don't do this, adding the
					// new replacement chassis and the modules would
					// EACH end up as their own undo snapsnots.
					suspendUndoSnapshots(true);

					// De-select the currently selected chassis
					// and device if any selected.
					cbData.selectChassisCallback(undefined);

					cbData.selectDeviceCallback(undefined);

					// If we're actually changing the chassis itself...
					if (changeChassis) {

						// Replace using the new info.
						const [rsltStatus, /*msgLevel*/, /*msg*/, swapMap] =
							replaceChassis(cbData.chassis, gsData.chassisCatalog, psCat, false);

						// If we're changing our wiring type AND
						// we got anything back in the swap map,
						// call a helper to make wiring support map
						// adjustments.
						if (changeWiring && (swapMap.size > 0)) {
							if (cbData.mapCatToWiringSpt &&
								(cbData.mapCatToWiringSpt.size > 0)) {
								cfgDlgAdjustWiringMapForSwaps(cbData.mapCatToWiringSpt, swapMap);
							}
						}

						// Test result.
						if (rsltStatus !== ResultStatus.Success) {
							throw new Error('Final Chassis replace FAILED!');
						}
					}
					else {
						// Chassis itself not changing.
						// If we have a different PS...
						if (changePS) {
							// Replace just the power supply.
							replaceChassisPowerSupply(cbData.chassis, psCat);
						}
					}

					// Set the new name if that changed.
					if (changeName) {
						cbData.chassis.name = newName;
					}

					checkAndUpdateRedundancy(cbData.chassis, gsData.highAvail, cbData.origQtyRMMods);

					// If we're changing wiring type...
					if (changeWiring) {
						// Set the new default on the chassis.
						cbData.chassis.defaultIOModWiring = gsData.wiringType;

						// Call a helper to see if there's any overlap
						// with the new type and the combined possible
						// types of all of our chassis' modules. If not,
						// no existing modules can be configured to use
						// the new type, so there's nothing more to do.
						// If we DO have any...
						if (isWiringTypeSupported(gsData.wiringType, cbData.allPossibleWiringTypes)) {

							// Then we should also have the associated
							// map of info in our data object. If we do...
							if (cbData.mapCatToWiringSpt && (cbData.mapCatToWiringSpt.size > 0)) {

								// Call a helper to make the changes needed.
								cfgDlgChangeWiringTypeOnMods(cbData.chassis, gsData.wiringType, cbData.mapCatToWiringSpt);
							}
							else {
								throw new Error('Missing wiring trail in callback!');
							}
						}
					}

					// Turn undo snapshots back on.
					suspendUndoSnapshots(false);

					// Update our rack layout. This might not be
					// needed in all cases, but doesn't hurt anything.
					if (cbData.chassis.parent) {
						updateRackLayout(cbData.chassis.parent);

					}
					else {
						throw new Error('Chassis without parent after replace?');
					}

					// Finally, (re)select our chassis. There shouldn't
					// be a need to 'bring it into view'. If it wasn't
					// already at least partially visible, we wouldn't
					// have been able to edit its configuration.
					cbData.selectChassisCallback(cbData.chassis);
				}
			}
			else {
				// We're adding a NEW chassis. Before we do,
				// De-select the currently selected chassis
				// and device if any selected.
				cbData.selectChassisCallback(undefined);
				cbData.selectDeviceCallback(undefined);

				// Check if our project already has any others.
				const wasEmpty = (cbData.project.content.racks.length === 0);

				// Add the new chassis using the info we got above.
				const newChassis = addChassis(cbData.project, PlatformCLX,
					gsData.chassisCatalog, -1, psCat);

				// If we got new chassis added...
				if (newChassis) {

					// Give the chassis its name.
					newChassis.name = newName;

					// Set its default wiring to match our selection.
					newChassis.defaultIOModWiring = gsData.wiringType;

					if (gsData.highAvail) {
						checkAndUpdateRedundancy(newChassis, true, 0);
						updateRackLayout(cbData.project.content);
					}

                    // If this is the project's FIRST chassis, 
                    // auto-scale our layout appropriately.
                    if (wasEmpty) {
                        autoScaleToContent(ChassisLayoutKey, cbData.project.content.totalExtent,
                            AutoScaleFitType.NewContent);
                    }

					// Select the new chassis
					cbData.selectChassisCallback(newChassis);

                    // If we had NO other chassis, the new one will ALREADY
                    // be visible in our layout. If we DID have others, we
                    // want to MAKE SURE that the new one ends up visible.
                    // Since it was just added, it will ALWAYS be the LAST
                    // rack, so we'll request a scroll-to-btm.
                    if (!wasEmpty) {
                        requestPendingScrollToBtm(ChassisLayoutKey,);
                    }
                }
            }

            // Either way, we changed project content.
            cbData.contentChangedCallback();
        }
        else {
            // Unexpected
            throw new Error('ERROR: Invalid data in configChassisCallback!');
        }
    }
}

let _initData: InitCfgData | undefined = undefined;

export const clxConfigureChassis = (
	project: ChassisProject,
	platform: string,
	selectChassisCallback: (chassis: Chassis | undefined) => void,
	selectDeviceCallback: (device: SelectableDevice | undefined) => void,
	contentChangedCallback: () => void,
	chassis?: Chassis
) => {
	_initData = {
		project: project,
		selectChassisCallback: selectChassisCallback,
		selectDeviceCallback: selectDeviceCallback,
		contentChangedCallback: contentChangedCallback,
		chassis: chassis,
	};

	if (chassis) {
		getLocAttrInfoForChassisEdit(chassis.platform, onChassisEditAttrInfoLoaded);
	}
	else {
		// Get the current loc, if it is NOT CLX, we
		// need to create a new loc for the edit.
		const loc = getLocAttrFromProject(project);
		if (loc.platform !== platform) {
			getLocAttrInfoForChassisEdit(platform, onChassisEditAttrInfoLoaded);
		}
		else {
			// We need to clone the project's guided selection.
			const newLocInfo = cloneLocAttributeInfo(loc);
			clxPrepareLocAttrForChassisConfig(newLocInfo);
			onChassisEditAttrInfoLoaded(true, newLocInfo);
		}
	}
}

const onChassisEditAttrInfoLoaded = (success: boolean, attrInfo: LocAttributeInfo) => {
	if (_initData == null || attrInfo == null)
		return;

	const [totalSlotsInUse, slotFillers] = getChassisSlotUsage(_initData.chassis);

	const minSlotsReqd = totalSlotsInUse - slotFillers;

	const initSelections = (_initData.chassis ? getInitialSelsFromChassis(_initData.chassis) : clxGetCLXChassisCfgDataFromLocAttr(attrInfo));

	if (_initData.chassis)
		cfgDlgInitializeLocAttrInfoFromChassis(attrInfo, initSelections);

	const psGroup = cfgDlgGetPSGroup(attrInfo.platform, initSelections.envType, initSelections.psVoltage, tipCallback);
	if (psGroup === '')
		throw new Error('CLX::onChassisEditAttrInfoLoaded::cfgDlgGetPSGroup() did NOT return a valid Power Supply Group!');

	const psGrpInfo: GroupChoiceInfo =
		createNewGrpChoiceInfo(
			psGroup,
			[initSelections.psSelCatalog]);

	const [allPossibleWiringTypes, mapCatToWiringSpt] = (_initData.chassis && (minSlotsReqd > 0))
		? cfgDlgGetPossibleModWiringTypes(_initData.chassis)
		: [SupportedWiringType.NA, undefined];


	// Convert LocAttrInfo Attribute Groups into
	// usable groups for the 'Choices' paradigm.
	let arrChassisPage: BaseChoiceInfo[] = [];
	const attrGrpChassis = attrInfo.attrGroups.find(x => x.title === ChassisCfgGrpCategory.Chassis);
	if (attrGrpChassis) {
		arrChassisPage = attrGrpChassis.settings.map((setting, idx) => {
			return createNewGdSelGrpChoiceInfo(
				attrInfo, setting, tipCallback, ChassisCfgGrpCategory.Chassis, idx
			);
		});
	}

	let arrPowerPage: BaseChoiceInfo[] = [];
	let lastIdx = 0;
	const attrGrpPower = attrInfo.attrGroups.find(x => x.title === ChassisCfgGrpCategory.Power);
	if (attrGrpPower) {
		arrPowerPage = attrGrpPower.settings.map((setting, idx) => {
			lastIdx = idx;
			return createNewGdSelGrpChoiceInfo(
				attrInfo, setting, tipCallback, ChassisCfgGrpCategory.Power, idx
			);
		});
	}

	// Add the 'non-Guided selection' Power Group
	// to the Power Page.
	psGrpInfo.pageID = ChassisCfgGrpCategory.Power;
	psGrpInfo.index = lastIdx + 1;
	arrPowerPage.push(psGrpInfo);

	const callbackData: ConfigChassisCBData = {
		project: _initData.project,
		cfgAttrInfo: attrInfo,
		initialGSSelections: [], // initially set to empty.

		selectChassisCallback: _initData.selectChassisCallback,
		selectDeviceCallback: _initData.selectDeviceCallback,
		contentChangedCallback: _initData.contentChangedCallback,

		chassis: _initData.chassis,
		chassisName: _initData.chassis ? getChassisName(_initData.chassis) : '',
		origCat: _initData.chassis ? _initData.chassis.catNo : '',
		origPSCat: (_initData.chassis && _initData.chassis.ps) ? _initData.chassis.ps.catNo : '',
		origTotalSlotsInUse: totalSlotsInUse,
		origSlotFillers: slotFillers,
		origQtyRMMods: initSelections.numRMs,

		allPossibleWiringTypes: allPossibleWiringTypes,
		mapCatToWiringSpt: mapCatToWiringSpt,

		msgLevel: StatusLevel.NA,
		panelMessages: [],
		gsPanelMessage: [],

		ChassisPage: arrChassisPage,
		PowerPage: arrPowerPage,

		psGrp: psGrpInfo,
		additionalGrps: [],
	};

	cfgDlgUpdateMessages(callbackData, _situationalMsgInfo, tipCallback);

	const title = _initData.chassis
		? 'Edit Chassis'
		: 'Add Chassis';

	const request: ModalRequestSpec = {
		includeButtons: true,
		closeOnInsideClick: false,
		stayOpenOnBackdropClick: true,
		title: title,
		callback: configChassisCallback,
		requestorData: callbackData,
		content: CLXChassisConfig,
		width: Math.min(800, window.innerWidth - 40),
	};

	addButton(
		request,
		(_initData.chassis ? 'SAVE' : 'ADD'),
		ModalStatus.Confirmed,
		'contained');

	requestModal(request);
}

const CLXChassisConfig = (request: ModalRequestSpec) => {
	const [currentTab, setCurrentTab] = useState<number>(CfgDlgTabID.Chassis);
	const [count, setCount] = useState<number>(0);
	const [infoPanelOpen, setInfoPanelOpen] = useState<boolean>(false);

	const divRef = useRef<HTMLDivElement | null>(null);
	const infoPt = useRef<Point>({ x: 0, y: 0 });
	const initializing = useRef(true);
	const infoMsgs = useRef<PanelMessage[]>(new Array<PanelMessage>());

	const data = request.requestorData as ConfigChassisCBData;

	if (!data) {
		unexpected('INVALID Request to ConfigAccys!');
	}

	const inclOldStyleMsgPanel = IncludeOldStyleMsgPnlInChassisConfig &&
		(data.panelMessages.length > 0);

		useEffect(()=>{
			if(data?.chassis?.isPower){
				setCurrentTab(CfgDlgTabID.PowerSupply)
			}
	
		},[data?.chassis?.isPower])
	const onTabSelected = (tabID: number) => {
		if (tabID !== currentTab) {
			setCurrentTab(tabID);
		}
	}

	const selectionChanged = () => {
		// 2024.2.16 This is for a non-Guided Selection
		// driven change - As of now, only for the PSU.

		// An option selection was changed, or at
		// least an attempt to change it was made.
		// Update the .allSelections collection in
		// our data object to match what we now
		// SHOULD have.
		cfgDlgUpdatePSGroup(data, tipCallback);
		cfgDlgUpdateMessages(data, _situationalMsgInfo, tipCallback);

		// Trigger a re-render.
		setCount(count + 1);
	}

	const gsSelectionChanged = (gsSetting: ProjectSetting, newChoiceInfo: ChoiceInfo) => {
		const reRender = cfgDlgGSSelectionChanged(request, initializing.current, _situationalMsgInfo, tipCallback, gsSetting, newChoiceInfo);
		if (reRender)
			setCount(count + 1);
	}

	const findModalBaseDiv = (): HTMLElement | undefined => {
		if (divRef.current) {
			let parent = divRef.current.parentElement;
			while (parent) {
				if (parent.id === 'ModalBaseDiv') {
					return parent;
				}
				parent = parent.parentElement;
			}
		}
		return undefined;
	}

	const _horizontallyCenterTipInModal = true;

	const onTipCallback = (category: MessageCategory, type: MessageType, pt: Point) => {

		// The pt arg is the center of the icon that was
		// clicked in DOM coords (browser viewport). We
		// need the point relative to our Modal.
		// Find the base div of our parent modal.
		const mdlBase = findModalBaseDiv();

		// The get ITS client rect.
		const baseClRect = mdlBase ? mdlBase.getBoundingClientRect() : undefined;

		// If we can, adjust our pt accordingly.
		if (baseClRect) {
			pt.x -= baseClRect.left;
			pt.y -= baseClRect.top;

			// If we leave our pt here, the tip popup will/would
			// be centered horizontally at our point.
			// If our 'center in modal' flag is set, change
			// the x to the middle of our client instead.
			if (_horizontallyCenterTipInModal) {
				pt.x = (baseClRect.width / 2);
			}
		}


		// Save the adjusted point.
		infoPt.current = { ...pt };

		// Load messages for the given id.
		loadTipPanelMsgs(infoMsgs.current, _situationalMsgInfo, category, type);

		// And and set our panel-open state.
		// That'll re-render us, which will
		// then INCLUDE the floating panel.
		setInfoPanelOpen(true);
	}

	const onCloseInfoPanel = () => {
		setInfoPanelOpen(false);
		infoPt.current.x = 0;
		infoPt.current.y = 0;
		infoMsgs.current.length = 0;
	}

	liveTipCallback = onTipCallback;

	// If this is our first time through...
	if (initializing.current) {
		// Get the EnvRating Setting.
		const er = getLocAttributeSetting(data.cfgAttrInfo, 'ER');
		if (er) {
			// We have to do this here. By simulating
			// a Guided Selection change, the options
			// will be verified and disabled if need be.
			const chInfo: ChoiceInfo = {
				id: er.selectedOption.id,
				label: er.selectedOption.display,
				disabled: false
			}
			gsSelectionChanged(er, chInfo);
		}

		initializing.current = false;
	}

    return (
        <div
			className='config-chassis-dialog'
            ref={divRef}
		>
			<ChassisConfigDlg
				data={data}
				selectionChanged={selectionChanged}
				gsSelectionChanged={gsSelectionChanged}
				onTabSelected={onTabSelected}
				onCloseInfoPanel={onCloseInfoPanel}
				infoPanelOpen={infoPanelOpen}
				currentTab={currentTab}
				inclOldStyleMsgPanel={inclOldStyleMsgPanel}
				infoPt={infoPt.current}
				infoMsgs={infoMsgs.current}
			/>
        </div>
    );
}

export default CLXChassisConfig;
