import React, { useEffect, useRef, useState } from 'react';
import { Group, Image, Rect } from 'react-konva';
import { ModuleDragStatus, SelectableDevice } from '../types/ProjectTypes';
import { LocAndSize, Point } from '../types/SizeAndPosTypes';
import SlotIDComp from './SlotIDComp';
import { AddBrightness, AddContrast, getModuleHighlightProps, HighlightProps } from '../util/ImageHighlightHelp';
import { offsetLoc } from '../util/GeneralHelpers';
import { ChassisItemRenderType } from '../util/LayoutModeHelp';
import { logger } from '../util/Logger';
import { LogRender } from '../types/Globals';
import { useCachedImage } from '../imageHelp/useCachedImage';

type ImageRef = React.ElementRef<typeof Image>;


// Note: In our props below, we COULD use the
// .selected from the device itself. However,
// since we're memoizing, we need to see a prop
// change to get re-rendered. This way also gives
// our parent the option to SHOW us as selected
// or not, independent of the device's .selected
// prop value.

interface Props {
    device: SelectableDevice; // ps, mod, or ???
    devDragStatus: ModuleDragStatus;
    ptOrg: Point;             
    relLocation: LocAndSize;  // location relative to ptOrg
    showSelected?: boolean; 
    renderType?: ChassisItemRenderType;
    slotId?: string;
    darkImagery?: boolean;
}

const SelectableDeviceComp = (props: Props) => {

    const [isMouseOver, setIsMouseOver] = useState(false);

    const [image, imageStatus, /*imgSize*/] = useCachedImage(props.device.imgSrc);

    const imageRef = useRef<ImageRef>(null);


    // IMPORTANT: In order for the Filters used by our
    // highlights to work, our image needs to be cached.
    useEffect(() => {
        if (image && imageRef.current) {
            imageRef.current.cache();
        }
    }, [image])

    if (props.slotId === '19') {
        console.log('Caught comp using slotId 19');
    }

    const imageReady = (imageStatus === 'loaded');

    let hlProps: HighlightProps = {};
    let opacity = 1.0;
    let highlightMouseOvers = false;

    const showSelected = (props.showSelected === true);

    switch (props.renderType) {
        case ChassisItemRenderType.Normal:
            [hlProps, opacity] = getModuleHighlightProps(showSelected, isMouseOver,
                props.devDragStatus, props.darkImagery);
            highlightMouseOvers = true;
            break;

        case ChassisItemRenderType.Pop:
            AddBrightness(hlProps, 0.15);
            break;

        case ChassisItemRenderType.Fade:
            if (props.darkImagery) {
                AddContrast(hlProps, -40);
            }
            else {
                opacity = 0.7;
            }
            break;
    }

    const renderLoc: LocAndSize = { ...props.relLocation };
    offsetLoc(renderLoc, props.ptOrg);

    const onMouseOver = () => {
        setIsMouseOver(true);
    }

    const onMouseOut = () => {
        setIsMouseOver(false);
    }

    // Note: properties for our Group component should
    // only include mouse handling when render type is
    // specified as Normal.
    const groupProps: object = highlightMouseOvers
        ? {
            onMouseOver: onMouseOver,
            onMouseOut: onMouseOut,
            opacity: opacity
        }
        : {
            opacity: opacity
        }

    if (LogRender.Layout) {
        logger.logRender('SelDev ' + props.device.catNo +
            (props.slotId ? ' - slot ' + props.slotId : ''));
    }

    return (
        <Group {...groupProps}>
            {imageReady
                ? <Image
                    ref={imageRef}
                    image={image}
                    {...renderLoc}
                    {...hlProps}
                />
                :
                <Rect
                    fill={'lightgray'}
                    stroke={'gray'}
                    strokeScaleEnabled={false}
                    {...renderLoc}
                />
            }
            {props.slotId
                ? < SlotIDComp
                    slotId={props.slotId}
                    ptOrg={props.ptOrg}
                    relSlotLoc={props.relLocation}
                    emptySlot={false}
                />
                : null
            }
        </Group>
    );
}

export default React.memo(SelectableDeviceComp);


