import { CommRole } from "../engData/CommRole";
import { ConnClientRole } from "../engData/ConnClientRole";
import { ControllerRole } from "../engData/ControllerRole";
import {
    EngInfoBasic,
    EngInfoChassis,
    EngInfoCommModule,
    EngInfoMicroBaseUnit,
    EngInfoComponent,
    EngInfoController,
    EngInfoFPDModule,
    EngInfoInterconnect,
    EngInfoIOModule,
    EngInfoModule,
    EngInfoPLUGINModule,
    EngInfoPowerSupply,
    IODetails
} from "../engData/EngineeringInfo";
import { getEngineeringInfoFor } from "../model/ChassisProject";
import {
    getAlternateFromInfoMap,
    getEngineeringData
} from "../model/EngineeringData";
import { EngDataStrParseData, EngInfoPackage, PSMatches } from "../types/EngDataTypes";
import { PSInputVoltage } from "../types/PowerTypes";
import {
    Chassis,
    EnvRating,
} from "../types/ProjectTypes";
import { getCharCountInString } from "./DataValueHelp";
import { logger } from "./Logger";

export interface CommDtlRoleInfo {
    anyRoles: boolean;
    isController: boolean;
    isComm: boolean;
    isConnClient: boolean;
}

export const getBasicEngInfoFor = (platform: string, catNo: string): EngInfoBasic | undefined => {
    const pkg = getEngineeringData(platform);
    return pkg.infoMap.get(catNo);
}


export const getEngInfoForComp = (platform: string, catNo: string): EngInfoComponent |  undefined => {

    // Get basic info as requested.
    const info = getBasicEngInfoFor(platform, catNo);

    // If there IS any...
    if (info) {
        // This function should ONLY be called for
        // eng info related to components, not
        // accessories, etc. If this IS a component...
        if (info.isComponent) {

            // Return as component object.
            return info as EngInfoComponent;
        }
    //    else {
    //        // We shouldn't have been called.
    //        logger.warn('getEngInfoForComp called for BASIC entry: ' + catNo);
    //    }
    }

    // No such component.
    return undefined;
}

export const getChassisEngInfo = (
  platform: string,
  catNo: string
): EngInfoChassis | undefined => {
  const info = getEngInfoForComp(platform, catNo);
    if (info && info.isChassis) {
        return info as EngInfoChassis;
    }
    return undefined;

};

export const getModuleEngInfo = (platform: string, catNo: string):
    EngInfoModule | undefined => {

    const info = getEngInfoForComp(platform, catNo);
    if (info && info.isModule) {
        return info as EngInfoModule;
    }
    return undefined;
}


export const getModuleEngInfoioExpansionPs = (platform: string, catNo: string):
    EngInfoModule | undefined => {

    const info = getEngInfoForComp(platform, catNo);
    if (info && info.isPS || info && info.isModule) {
        return info as EngInfoModule;
    }
    return undefined;
}

// Used for parsing Engineering Data strings
// in the form of "cat1:2;cat2:1;cat3:2;etc."
// and returning an array of ICatalogAndCount.
export const parseEngDataPropString = (strEngData: string): EngDataStrParseData[] => {
    const info: EngDataStrParseData[] = [];

    const lenStr = strEngData.length;
    if (lenStr > 0) {
        // Semi-Colon will split the string into items.
        const arrItems = strEngData.split(";");
        // For each item...
        arrItems.forEach((item) => {
            const parts = parseEngDataItemStr(item);
            if (parts)
                info.push(parts);
        });
    }

    return info;
}

export const parseEngDataItemStr = (testCat: string): EngDataStrParseData | undefined => {
    // An Eng Data string can have 3 parts to it based
    // on colon ':' and pipe '|' separators. The first
    // part (and maybe the only part), will be the catalog
    // or ID. The text following ':' will be a number/quantity
    // and any characters following the '|' will be text.
    let catalog = testCat.trim();
    let quantity = '';
    let label = '';

    if (catalog.length === 0)
        return undefined;

    const [cntColon, idxColon] = getCharCountInString(testCat, ':');
    const [cntPipe, idxPipe] = getCharCountInString(testCat, '|');

    // If we just have the catalog/ID...
    if (cntColon === 0 && cntPipe === 0)
        return { catalog, count: 1 };

    // Clear the catalog
    catalog = '';

    // We should NEVER have more than one ':' or '|'
    // in the item string. 
    if (cntColon < 2 && cntPipe < 2) {
        if (cntColon && cntPipe) {
            if (idxColon < idxPipe) {
                catalog = testCat.substring(0, idxColon).trim();
                quantity = testCat.substring(idxColon + 1, idxPipe).trim();
                label = testCat.substring(idxPipe + 1).trim();
            }
            else {
                catalog = testCat.substring(0, idxPipe).trim();
                label = testCat.substring(idxPipe + 1, idxColon).trim();
                quantity = testCat.slice(idxColon + 1).trim();
            }
        }
        else if (cntColon) {
            catalog = testCat.substring(0, idxColon).trim();
            quantity = testCat.slice(idxColon + 1).trim();
        }
        else {
            catalog = testCat.substring(0, idxPipe).trim();
            label = testCat.slice(idxPipe + 1).trim();
        }
    }

    if (catalog.length > 0) {
        let count = Number(quantity);
        count = (isNaN(count) || count < 1 ? 1 : count);
        const text = label.length > 0 ? label : undefined;

        return { catalog, count, text };
    }

    return undefined;
}

export const getIOModuleEngInfo = (platform: string, catNo: string):
    EngInfoIOModule | undefined => {
    const info = getEngInfoForComp(platform, catNo);
    if (info && info.isIO) {
        return info as EngInfoIOModule;
    }
    return undefined;
}

// NOTE: Caller guarantees that requested device IS I/O.
export const getConnClientRoleFor = (platform: string, catNo: string):
    ConnClientRole => {

    const ioInfo = getIOModuleEngInfo(platform, catNo);
    if (ioInfo && ioInfo.connClientRole) {
        return ioInfo.connClientRole;
    }
    // Unexpected error if we're still here.
    throw new Error('Invalid call to getConnClientRoleFor!');
}

export const getControllerEngInfo = (platform: string, catNo: string):
    EngInfoController | undefined => {

    const info = getEngInfoForComp(platform, catNo);
    if (info && info.isController) {
        return info as EngInfoController;
    }
    return undefined;
}

// NOTE: Caller guarantees that requested device IS a controller.
export const getControllerRoleFor = (platform: string, catNo: string):
    ControllerRole => {

    const ctlrInfo = getControllerEngInfo(platform, catNo);
    if (ctlrInfo && ctlrInfo.controllerRole) {
        return ctlrInfo.controllerRole;
    }
    // Unexpected error if we're still here.
    throw new Error('Invalid call to getControllerRoleFor!');
}

export const getCommModuleEngInfo = (platform: string, catNo: string):
    EngInfoCommModule | undefined => {

    const info = getEngInfoForComp(platform, catNo);
    if (info && info.isCommModule) {
        return info as EngInfoCommModule;
    }
    return undefined;
}

// NOTE: Caller guarantees that requested device IS a Comm.
export const getCommRoleFor = (platform: string, catNo: string):
    CommRole => {

    // Get module eng info for the requested cat no.
    const modInfo = getModuleEngInfo(platform, catNo);

    // If we can, and the info tells
    // us that the device IS a comm...
    if (modInfo && modInfo.isComm) {

        // Then it COULD be either a controller
        // a comm module. If a controller.
        if (modInfo.isController) {

            // Cast the specific eng info for controller
            const asCtlrInfo = modInfo as EngInfoController;

            // If it has a comm role, return it.
            if (asCtlrInfo.commRole) {
                return asCtlrInfo.commRole;
            }
        }
        else {
            // Not a controller. If a comm module...
            if (modInfo.isCommModule) {
                // Cast to specific eng info for that.
                const asCommModInfo = modInfo as EngInfoCommModule;

                // If it has a comm role, return it.
                if (asCommModInfo.commRole) {
                    return asCommModInfo.commRole;
                }
            }
        }
    }
    // Unexpected error if we're still here.
    throw new Error('Invalid call to getCommRoleFor!');
}

export const getCommDetailRoles = (platform: string, catNo: string): CommDtlRoleInfo => {

    const roleInfo: CommDtlRoleInfo = {
        anyRoles: false,
        isController: false,
        isComm: false,
        isConnClient: false
    }

    const modInfo = getModuleEngInfo(platform, catNo);
    if (modInfo) {
        roleInfo.isController = modInfo.isController;
        roleInfo.isComm = modInfo.isComm;
        roleInfo.isConnClient = modInfo.isConnClient;
    }

    roleInfo.anyRoles = (roleInfo.isController ||
        roleInfo.isComm || roleInfo.isConnClient);

    return roleInfo;
}

export const getPowerSupplyEngInfo = (platform: string, catNo: string):
    EngInfoPowerSupply | undefined => {

    const info = getEngInfoForComp(platform, catNo);
    if (info && info.isPS) {
        return info as EngInfoPowerSupply;
    }
    return undefined;
}

export const getPowerSuppliesInPkg = (pkg: EngInfoPackage): EngInfoPowerSupply[] => {
    const psEntries = Array.from(pkg.infoMap.values()).filter(entry => entry.isPS);
    return psEntries as EngInfoPowerSupply[];
}

export const getPowerSupplies = (platform: string): EngInfoPowerSupply[] => {
    const pkg = getEngineeringData(platform);
    return getPowerSuppliesInPkg(pkg);
}

export const getPowerSuppliesInPkgMicro = (pkg: EngInfoPackage): EngInfoPowerSupply[] => {
    const psEntries = Array.from(pkg.infoMap.values()).filter(entry => entry.isPS);
    return psEntries as EngInfoPowerSupply[];
}

export const getPowerSuppliesMicro = (platform: string): EngInfoPowerSupply[] => {
    const pkg = getEngineeringData(platform);
    return getPowerSuppliesInPkgMicro(pkg);
}

export const getPowerSuppliesFor = (
    platform: string,
    envRating: EnvRating,
    inputVltg: PSInputVoltage
): PSMatches | undefined => {

    const pkg = getEngineeringData(platform);
    const envEntry = pkg.mappedPS.get(envRating);
    if (envEntry) {
        return envEntry.get(inputVltg);
    }
    return undefined;
}

export const isPowerSupplyAvailableFor = (
    platform: string,
    envRating: EnvRating,
    inputVltg: PSInputVoltage
): boolean => {
    const matches = getPowerSuppliesFor(platform, envRating, inputVltg);
    if (matches) {
        if ((matches.singles.length > 0) ||
            (matches.redundants.length > 0)) {
            return true;
        }
        else {
            logger.error('Mapped PSMatches with no entries!');
        }
    }
    return false;
}

//Logic  that used for listing only io modules

export const getIOModulesInPkg = (pkg: EngInfoPackage): EngInfoIOModule[] => {
    const ioModEntries = Array.from(pkg.infoMap.values()).filter(entry => entry.isIO && !entry.isBU);
    return ioModEntries as EngInfoIOModule[];
}

//Logic  that used for listing all iotypes (Includes ioExpansion,plugin and controller data ) based on micro 800 engineering data

export const microgetIOModulesInPkg = (pkg: EngInfoPackage): EngInfoIOModule[] => {
    const ioModEntries = Array.from(pkg.infoMap.values()).filter(entry => entry.isIO);
    return ioModEntries as EngInfoIOModule[];
}

export const getPluginModulesInPkg = (pkg: EngInfoPackage): EngInfoPLUGINModule[] => {
    const ioModEntries = Array.from(pkg.infoMap.values()).filter(entry => entry.isPlugin);
    return ioModEntries as EngInfoPLUGINModule[];
}

export const getIOModules = (platform: string): EngInfoIOModule[] => {
    const pkg = getEngineeringData(platform);
    return getIOModulesInPkg(pkg);
}

export const getModulesInPkg = (pkg: EngInfoPackage): EngInfoModule[] => {
    const modEntries = Array.from(pkg.infoMap.values()).filter(entry => entry.isModule);
    return modEntries as EngInfoModule[];
}

export const getModules = (platform: string): EngInfoModule[] => {
    const pkg = getEngineeringData(platform);
    return getModulesInPkg(pkg);
}

export const getFPDModulesInPkg = (pkg: EngInfoPackage): EngInfoFPDModule[] => {
    const modEntries = Array.from(pkg.infoMap.values()).filter(entry => entry.isFPD);
    return modEntries as EngInfoFPDModule[];
}

export const getMicroModulesInPkg = (pkg: EngInfoPackage): EngInfoMicroBaseUnit[] => {
    const modEntries = Array.from(pkg.infoMap.values()).filter(entry => entry.isBU);
    return modEntries as EngInfoMicroBaseUnit[];
}

export const getMicroModules = (platform: string, catNo: string): EngInfoMicroBaseUnit | undefined => {
    const pkg = getEngineeringData(platform);
      // If there IS any...
    if (pkg) {
        // This function should ONLY be called for
        // eng info related to components, not
        // accessories, etc. If this IS a component...
        return pkg.infoMap.get(catNo) as EngInfoMicroBaseUnit;
    }

 
}

export const getMicroModulesExpansionDetails = (platform: string, catNo: string): number => {
    const pkg = getEngineeringData(platform);
      // If there IS any...
    if (pkg) {
        const getEngData =  pkg.infoMap.get(catNo) as EngInfoMicroBaseUnit;
        return  parseInt(getEngData.Eio) || 0
    }
    return 0;
}

export const getMicroModulesupSizeCheck = (platform: string, ioExpansion: number): string | null => {
  const pkg = getEngineeringData(platform);
 const info =  getMicroModulesInPkg(pkg)
 if(info){
    return info.filter(getEngData=> (parseInt(getEngData.Eio) >=  ioExpansion ) )[0].catNo
 }

   return ''

 
}

export const getFPDModules = (platform: string): EngInfoFPDModule[] => {
    const pkg = getEngineeringData(platform);
    return getFPDModulesInPkg(pkg);
}

export const getInterconnectCblsInPkg = (pkg: EngInfoPackage): EngInfoInterconnect[] => {
    const interconnects = Array.from(pkg.infoMap.values()).filter(entry => entry.isInterconnect);
    return interconnects as EngInfoInterconnect[];
}

export const getInterconnectCbls = (platform: string): EngInfoInterconnect[] => {
    const pkg = getEngineeringData(platform);
    return getInterconnectCblsInPkg(pkg);
}



// Returns modules available that are environmentally
// compatible and, if the chassis is redundant, redundant-capable.
export const getAvailableModules = (chassis: Chassis): EngInfoModule[] => {
    const allMods = getModules(chassis.platform);
    const chassisInfo = getEngineeringInfoFor(chassis);

    if (allMods.length && chassisInfo) {
        const chasRating = chassisInfo.envInfo.rating;
        const matches = allMods.filter(mod => mod.isCompatibleWithEnv(chasRating));
        if (chassis.redundant) {
            const redCapableMods = matches.filter(mod => mod.redCapable);
            return redCapableMods;
        }
        else {
            return matches;
        }
    }

    return [];
}

// Returns Controller Mods that are compatible (environmentally/redundancy)
// with the chassis passed in.
export const getCompatibleControllers = (chassis: Chassis): EngInfoModule[] => {
    const allMods = getModules(chassis.platform);
    const chassisInfo = getEngineeringInfoFor(chassis);

    if (allMods.length && chassisInfo) {
        const chasRating = chassisInfo.envInfo.rating;
        const matches = allMods.filter(mod => mod.isCompatibleWithEnv(chasRating) && mod.isController);
        if (chassis.redundant) {
            const redCapableMods = matches.filter(mod => mod.redCapable);
            return redCapableMods;
        }
        else {
            return matches;
        }
    }

    return [];
}

export const isSlotFillerCatNo = (platform: string, catNo: string): boolean => {
    const info = getEngInfoForComp(platform, catNo);
    if (info) {
        return (info.isSlotFiller());
    }
    return false;
}

export const getProductAlternateFor = (
    platform: string,
    catNo: string,
    needCC: boolean,
    needET: boolean,
    needXTR = false
): string => {
    const pkg = getEngineeringData(platform);
    return getAlternateFromInfoMap(pkg.infoMap, catNo, needCC, needET, needXTR);
}

export const getModDetailsForSorting = (compInfo: EngInfoComponent):
    [isIO: boolean, ioDtls: IODetails | undefined, saPwrUsed: number] => {

    let saPwrUsed = 0;

    if (compInfo.isModule) {
        const asMod = compInfo as EngInfoModule;
        saPwrUsed = asMod.powerUsed.saPower;

        if (asMod.isIO) {
            const ioInfo = asMod as EngInfoIOModule;
            return [true, ioInfo.ptDtls, saPwrUsed];
        }
    }
    return [false, undefined, saPwrUsed];
}

const _sortSafety = 0x2000;
const _sortOutput = 0x1000;
const _sortAnalog = 0x0400;
const _sortInput = 0x0100;

// Used to get a comparable number when sorting
// I/O modules for FPD (SA power) optimization.
// A larger number should sort before a smaller.
export const getIOSortPrecedence = (dtls: IODetails): number => {
    let sortPrec = 0;
    if (dtls.safety) sortPrec |= _sortSafety;
    if (dtls.totalOutputs > 0) sortPrec |= _sortOutput;
    if (dtls.analog) sortPrec |= _sortAnalog;
    if (dtls.totalInputs > 0) sortPrec |= _sortInput;
    return sortPrec;
}
