import { EngInfoPackage } from "../../../types/EngDataTypes"
import { PlatformCLX } from "../../PlatformConstants"
import CLXDefEngData from '../data/EngData1756.json';
import { isSizeEmpty } from "../../../util/GeneralHelpers";
import { getPowerSuppliesInPkg } from "../../../util/EngInfoHelp";
import { EngDataImplementation, RegisterEngDataImpl } from "../../../implementation/ImplEngData";


const clxGetDfltEngDataJSON = (): object => {
    return CLXDefEngData;
}

const clxEngDataPostLoad = async (pkg: EngInfoPackage) => {
    const powerSupplies = getPowerSuppliesInPkg(pkg);
    powerSupplies.forEach(ps => {
        if (isSizeEmpty(ps.imgSize)) {
            ps.imgSize.width = ps.slim ? 148 : 200;
            ps.imgSize.height = 280;
        }
    });

    return;
}

export const RegisterCLXEngDataImpl = () => {
    const clxImpl: EngDataImplementation = {
        platform: PlatformCLX,
        preload: undefined,
        getDfltEngData: clxGetDfltEngDataJSON,
        postLoad: clxEngDataPostLoad,
    };

    RegisterEngDataImpl(clxImpl);
}
