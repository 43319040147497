import React, { useRef } from 'react';
import { Group } from 'react-konva';
import FancyPath from '../../../components/cabling/FancyPath';
import SelectableDeviceComp from "../../../components/SelectableDeviceComp";
import SlotIDComp from '../../../components/SlotIDComp';
import {
    canExtendChassis,
    ChassisCompProps,
    getDefaultXSlotWidth,
    getEmptySlotImage,
    getSlotID,
    getSlotLocation
} from "../../../implementation/ImplGeneral";
import { getDefaultImageSource, getModuleSlotBreakdown, getModuleSlotID } from "../../../model/ChassisProject";
import {
    BankInfo,
    Chassis,
    ChassisModule,
    FlexHAChassis,
    ModuleDragStatus
} from "../../../types/ProjectTypes";
import { LocAndSize } from '../../../types/SizeAndPosTypes';
import { CableSpec, getCableProps } from '../../../util/CableHelp';
import { getEngInfoForComp } from '../../../util/EngInfoHelp';
import { getEmptyLoc, getLocRight } from '../../../util/GeneralHelpers';
import { getChassisRenderSpec, LayoutModeType } from "../../../util/LayoutModeHelp";
import { logger } from '../../../util/Logger';
import ChassisElComp from '../../common/ChassisElComp';
import { ChassisElementType } from '../../common/CommonPlatformTypes';
import { PlatformFlexHA } from '../../PlatformConstants';
import {
    flexHAGetSAPwrLocInfo,
    getFHASizeDetails,
    flexHAGetLayoutInfo,
    flexHAGetCableOpacity,
    flexHAIOBase
} from '../model/FlexHALayout';


export interface FlexHABankProps extends ChassisCompProps {
    bankInfo: BankInfo;
}

enum IOTBType {
    None = 'None',
    Simplex = 'Simplex',
    Duplex = 'Duplex',
    Cover = 'Cover'
}

const _getTBImageSource = (imgType: IOTBType): string => {

    if (imgType === IOTBType.None) {
        return '';
    }

    const basePath = '/assets/flexHA/';
    switch (imgType) {
        case IOTBType.Duplex:
            return basePath + '5015_IO_TB_Duplex.png';

        case IOTBType.Simplex:
            return basePath + '5015_IO_TB_Simplex.png';

        case IOTBType.Cover:
            return basePath + '5015_IO_TB_Cover.png';

        default:
            throw new Error('Unexpected type in _getTBImageSource!');
    }
}

const _getPwrJumperImgSrc = (): string => {
    return '/assets/flexHA/5015_SA_Power_Jumper.png';
}

const _getPwrTBCoverImgSrc = (): string => {
    return '/assets/flexHA/5015_SA_Power_TB_Cover.png';
}

const _addXSlot = (props: FlexHABankProps):
    [show: boolean, loc: LocAndSize, id: string] => {

    // Start with assumption we WON'T 
    // have an 'X' (extra empty) slot.
    let extWidth = 0;

    // Any extension can ONLY happen on the LAST
    // bank. If we have that, AND if we can extend
    // the chassis...
    if (props.bankInfo.lastBank && canExtendChassis(props.chassis)) {

        // We will if the chassis is a drag target
        // AND it has an xSlotWidth...
        if (props.chassis.dragTarget && (props.chassis.xSlotWidth > 0)) {
            extWidth = props.chassis.xSlotWidth;
        }
        else {
            // Not a drag target. We STILL might show
            // the x-slot if either the chassis is
            // selected OR if we're in Copy Mode.
            const extPossible = (props.layoutMode?.type === LayoutModeType.Copy);

            // If either AND the chassis can be extended...
            if (extPossible || props.showAsSelected) {
                // Get the default X slot width from
                // the platform, if any.
                extWidth = getDefaultXSlotWidth(props.chassis.platform);
            }
        }
    }

    // If we ended up with a width...
    if (extWidth) {

        // Get our bank's layout. Note that we already
        // KNOW that it's the LAST bank in the chassis.
        const bankLayout =
            flexHAGetLayoutInfo(props.chassis, props.bankInfo.bankNum);

        // Init our return loc to be the layout's comps
        // loc, which includes just adapter, I/O bases,
        // and bank extension halves.
        const locXSlot: LocAndSize = { ...bankLayout.nonPSCompsLoc };

        // Then adjust its x location and width.
        locXSlot.x = getLocRight(locXSlot);
        locXSlot.width = extWidth;

        // We are not using slot IDs for x-slots FlexHA (yet)
        // but set it up correctly. The extended
        // slot ID will always be the length of 
        // the module array (last slot index + 1)
        const xSlotId = props.chassis.modules.length;

        // We DO want a right slot.
        return [true, locXSlot, xSlotId.toString()];
    }

    // We DON'T want one.
    return [false, getEmptyLoc(), ''];
}

const _getBackplateImgSrc = (): string => {
    const imgSrc = '/assets/flexHA/5015_Backplate.png';
    return imgSrc;
}

const _getIOBaseImgSrc = (): string => {
    const engInfo = getEngInfoForComp(PlatformFlexHA, flexHAIOBase);
    if (engInfo) {
        return getDefaultImageSource(engInfo.imgName);
    }

    logger.error('ERROR: Missing eng info for I/O base kit?');
    return '';
}

const _getIOTBImgUrl = (mod: ChassisModule | undefined): string => {

    if (mod) {
        if (mod.slotsUsed === 2) {
            return _getTBImageSource(IOTBType.Duplex);
        }
        if (mod.slotFiller) {
            return _getTBImageSource(IOTBType.Cover);
        }
        else {
            return _getTBImageSource(IOTBType.Simplex);
        }
    }
    else {
        return '';
    }
}

const _getBankSlotDtls = (chassis: Chassis, bankInfo: BankInfo): [number[], number[]] => {
    const firstSlot = bankInfo.startSlot;
    const lastSlot = firstSlot + bankInfo.slotsInBank - 1;
    return getModuleSlotBreakdown(chassis.modules, firstSlot, lastSlot);
}

const FlexHAChassisBank = (props: FlexHABankProps) => {
    const backplateImgSrc = useRef<string>(_getBackplateImgSrc());
    const ioBaseImgSrc = useRef<string>(_getIOBaseImgSrc());

    const chassis = props.chassis as FlexHAChassis;

    const sizeDtls = getFHASizeDetails();
    const layout = flexHAGetLayoutInfo(chassis, props.bankInfo.bankNum);

    const defaultRender = (!props.renderType) || (!props.layoutMode);
    const modeForSpec = defaultRender ? undefined : props.layoutMode;

    // Call a helper to give us rendering details for the various 
    // elements of our chassis (ps, modules, empty slots, etc.)
    const elRenderSpec = getChassisRenderSpec(modeForSpec,
        chassis, true, (props.showAsSelected === true));

    // Get breakdown of the slots in our bank (which have modules and which are empty).
    // Note: the returned modSlots array will only contains the left-side slot
    // numbers for any 2-slot modules in the chassis. The emptySlots array
    // will contain ONLY those slots that already exist and are truly empty.
    const [modSlots, emptySlots] = _getBankSlotDtls(chassis, props.bankInfo);

    // See if our chassis should be depicted as 'selected'.
    const showSelected = (props.showAsSelected === true);

    const [extraRightSlot, extraSlotLoc] = _addXSlot(props);
    const emptySlotImg = getEmptySlotImage(chassis);

    const backplateLoc = { ...layout.backplateLoc };
    if (extraRightSlot) {
        backplateLoc.width += extraSlotLoc.width;
    }
    const bpClipLoc: LocAndSize = {
        x: backplateLoc.x + sizeDtls.bpInfo.sideExt,
        y: backplateLoc.y,
        width: backplateLoc.width - (2 * sizeDtls.bpInfo.sideExt),
        height: backplateLoc.height - sizeDtls.bpInfo.btmExt
    }

    const bpBorder = {
        stroke: 'lightgray',
        strokeWidth: 2,
        strokeScaleEnabled: false
    }

    const iotbBorder = {
        stroke: 'black',
        strokeWidth: 1,
        strokeScaleEnabled: false
    }

    const [jumperLocs, tbCoverLocs] =
        flexHAGetSAPwrLocInfo(chassis, props.bankInfo);

    const saPJImgSrc = _getPwrJumperImgSrc();
    const saPwrTBCoverImgSrc = _getPwrTBCoverImgSrc();

    // Show slot IDs only if we're showing 'as selected'.
    const showSlotIds = (props.showAsSelected === true);

    const useDarkImageryOption = true;
    const psCblOpac = flexHAGetCableOpacity(props);

    const renderModule = (slotNum: number) => {
        const module = chassis.modules[slotNum];
        if (module) {

            const rndType = (props.renderType !== undefined)
                ? elRenderSpec.modules
                : undefined;

            const slotId = showSlotIds
                ? getModuleSlotID(module)
                : undefined;

            const ioTBImgUrl = _getIOTBImgUrl(module);

            const darkOpt = useDarkImageryOption && (slotNum !== 0);

            const layoutSlotIdx = slotNum - props.bankInfo.startSlot;

            return (
                <Group key={slotNum}>
                    <SelectableDeviceComp
                        device={module}
                        devDragStatus={module.dragStatus}
                        showSelected={showSelected && (props.localDeviceSelected === module)}
                        renderType={rndType}
                        ptOrg={props.ptOrg}
                        relLocation={layout.slotLocs[layoutSlotIdx]}
                        slotId={slotId}
                        darkImagery={darkOpt}
                    />
                    <ChassisElComp
                        elType={ChassisElementType.IOTermBlk}
                        renderType={elRenderSpec.ioTermBlks}
                        imgSrc={ioTBImgUrl}
                        ptOrg={props.ptOrg}
                        relLocation={layout.ioTBLocs[layoutSlotIdx]}
                        borderProps={iotbBorder}
                    />
                </Group>
            );
        }
        else {
            return (
                null
            );
        }
    }

    let nextCblKey = 1;

    const renderPSCbl = (cblSpec: CableSpec) => {
        const cblProps = getCableProps(cblSpec,
            props.ptOrg, psCblOpac);

        return (
            <FancyPath
                key={'C' + nextCblKey++}
                pathProps={cblProps}
            />
        )
    }

    // Note: In order to best preserve key uniqueness
    // from render to render, we'll ONLY use an incrementing
    // number as a key for ioBases.
    // Those should ALWAYS be the same for the same chassis layout.
    let nextIOBaseId = 1;
    let nextJmprId = 1;
    
    return (
        <>
            <ChassisElComp
                key={'BP'}
                elType={ChassisElementType.Backplate}
                renderType={elRenderSpec.backplate}
                imgSrc={backplateImgSrc.current}
                ptOrg={props.ptOrg}
                relLocation={backplateLoc}
                borderProps={bpBorder}
                clippedLoc={bpClipLoc}
            />

            {layout.expLeadComp && layout.locLeadComp
                ? <SelectableDeviceComp
                    device={layout.expLeadComp}
                    devDragStatus={ModuleDragStatus.NA}
                    showSelected={showSelected && (props.localDeviceSelected === layout.expLeadComp)}
                    renderType={props.renderType ? elRenderSpec.modules : undefined}
                    ptOrg={props.ptOrg}
                    relLocation={layout.locLeadComp}
                    darkImagery={useDarkImageryOption}
                />
                : null
            }

            {layout.ioBaseLocs.map(baseLoc => {
                return (
                    <ChassisElComp
                        key={nextIOBaseId++}
                        elType={ChassisElementType.IOBase}
                        renderType={elRenderSpec.ioBases}
                        imgSrc={ioBaseImgSrc.current}
                        ptOrg={props.ptOrg}
                        relLocation={baseLoc}
                    />
                );
            })}

            {modSlots.map(slot => {
                return (renderModule(slot));
            })}

            {showSlotIds && emptySlots.map(slot => {
                return <SlotIDComp
                    key={'SID' + slot}
                    slotId={getSlotID(chassis, slot)}
                    ptOrg={props.ptOrg}
                    relSlotLoc={getSlotLocation(chassis, slot)}
                    emptySlot={true}
                />
            })}

            {layout.expTrailComp && layout.locTrailComp
                ? <SelectableDeviceComp
                    device={layout.expTrailComp}
                    devDragStatus={ModuleDragStatus.NA}
                    showSelected={showSelected && (props.localDeviceSelected === layout.expTrailComp)}
                    renderType={props.renderType ? elRenderSpec.modules : undefined}
                    ptOrg={props.ptOrg}
                    relLocation={layout.locTrailComp}
                    darkImagery={useDarkImageryOption}
                />
                : null
            }

            {props.bankInfo.firstBank && chassis.ps
                ? < Group key={'PS'}>
                    <SelectableDeviceComp
                        device={chassis.ps}
                        devDragStatus={ModuleDragStatus.NA}
                        showSelected={showSelected && (props.localDeviceSelected === chassis.ps)}
                        renderType={props.renderType ? elRenderSpec.ps : undefined}
                        ptOrg={props.ptOrg}
                        relLocation={sizeDtls.psuLoc1}
                        darkImagery={useDarkImageryOption}
                    />
                    <SelectableDeviceComp
                        device={chassis.ps}
                        devDragStatus={ModuleDragStatus.NA}
                        showSelected={showSelected && (props.localDeviceSelected === chassis.ps)}
                        renderType={props.renderType ? elRenderSpec.ps : undefined}
                        ptOrg={props.ptOrg}
                        relLocation={sizeDtls.psuLoc2}
                        darkImagery={useDarkImageryOption}
                    />
                    {sizeDtls.psuCables.map(cblSpec => {
                        return (renderPSCbl(cblSpec));
                    })}
                </Group >
                : null}

            {jumperLocs.map(loc => {
                return (
                    <ChassisElComp
                        key={'J' + nextJmprId++}
                        elType={ChassisElementType.Jumper}
                        renderType={elRenderSpec.jumper}
                        imgSrc={saPJImgSrc}
                        ptOrg={props.ptOrg}
                        relLocation={loc}
                    />
                )
            })}

            {tbCoverLocs.map(loc => {
                return (
                    <ChassisElComp
                        key={'TC' + nextJmprId++}
                        elType={ChassisElementType.Jumper}
                        renderType={elRenderSpec.jumper}
                        imgSrc={saPwrTBCoverImgSrc}
                        ptOrg={props.ptOrg}
                        relLocation={loc}
                    />
                )
            })}

            {extraRightSlot
                ? <>
                    <ChassisElComp
                        key={'XRS'}
                        elType={ChassisElementType.EmptySlot}
                        renderType={elRenderSpec.emptySlots}
                        imgSrc={emptySlotImg}
                        ptOrg={props.ptOrg}
                        relLocation={extraSlotLoc}
                    />
                </>
                : null
            }
        </>
    );
}

export default FlexHAChassisBank;


