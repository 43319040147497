import { prepProjectConfigForPersist } from "../model/LocAttributeInfo";
import { getCachedPriceFor } from "../raiseDatabase/DeviceCache";
import { store } from "../redux/store";
import configurationApiService from "../services/apis/ConfigurationApiService";
import { BOMItem, BOMItemProduct, BOMItemType, getBomInfoForProjectSave } from "../summary/SummaryHelp";
import { ToolbarAction } from "../toolbar/ToolbarActions";
import { ChassisProject, ModuleDragStatus } from "../types/ProjectTypes";
import { logger } from "../util/Logger";
import {
    BOMItemExch,
    BomItemProductType,
    BomItemSourceType,
    ConfigurationExchange,
    ProjectItem
} from "./UserProjectTypes";
import { getDateStringNowUTC } from "../util/DateHelp";
import config from "../config";
import { getChassisMetadata } from "../util/SysDsgHelp";


let _configModified = false;

export const setConfigModified = (modified: boolean) => {
    _configModified = modified;
    logger.logPersist('persist: modified - ' + _configModified);
}

export const isConfigModified = (): boolean => {
    return _configModified;
}

const existingProjItems = new Array<ProjectItem>();
export const projItemNamesInUse = new Set<string>();
export const projCIDsInUse = new Set<string>();

export const loadProjItemInfo = (items: ProjectItem[]) => {
    projItemNamesInUse.clear();
    projCIDsInUse.clear();
    existingProjItems.length = 0;
    items.forEach(item => {
        existingProjItems.push({ ...item });
        projItemNamesInUse.add(item.name);
        projCIDsInUse.add(item.subSystemId);
    })
}


const _isSameGuid = (g1: string, g2: string): boolean => {
    return (g1.toLowerCase() === g2.toLowerCase());
}

export const isConfigNameUnique = (name: string, configId = ''): boolean => {

    // If we GOT a name...
    if (name) {

        // If it's NOT already in use...
        if (!projItemNamesInUse.has(name)) {

            // It's OK.
            return true;
        }
        else {
            // Name is in use. IF we got a config ID...
            if (configId) {
                // Then find existing items matching name.
                const matches = existingProjItems.filter(item => item.name === name);

                // We SHOULD have AT LEAST one match, since the
                // name was in our in-use set, and there should
                // be exactly ONE match, since the API to add
                // a configuration to a project doesn't allow
                // duplicates. If we have exactly 1...
                if (matches.length === 1) {

                    // We'll allow the existing name IFF
                    // it's already being used for a saved
                    // version of OUR OWN config id.
                    if (_isSameGuid(matches[0].subSystemId, configId)) {
                        return true;
                    }
                }
                else {
                    logger.error('Unexpected Error in isConfigNameOk - MULTIPLE name matches!');
                }
            }
        }
    }

    // Anything that gets here is NOT OK.
    return false;
}

export const getConfigSaveStatus = (projectGuid: string | undefined, cfg: ChassisProject): [
    canSave: boolean,   // true if there IS a UserProject
    everSaved: boolean, // true if the UserProject contains
    // a config for the project.
    modified: boolean   // true if project content has changed
    // since the last time the project was saved.
] => {
    const canSave = projectGuid ? true : false;
    const everSaved = canSave
        ? configurationApiService.hasConfigBeenSaved(cfg.id)
        : false;
    const modifiedSinceSave = canSave
        ? isConfigModified()
        : false;
    return [canSave, everSaved, modifiedSinceSave];
}

export const getSummaryProjectBtnInfo = (projectGuid: string | undefined, cfg: ChassisProject):
    [btnText: string, disabled: boolean, toolbarAction: string] => {

    const [canSave, everSaved] = getConfigSaveStatus(projectGuid, cfg);


    // If a save is possible at all...
    if (canSave) {

        const gotoProj = everSaved && !isConfigModified();

        // Set text based on whether this would be a new
        // add or a re-save of an existing config.
        const btnText = gotoProj ? 'GO TO PROJECT' : 'SAVE TO PROJECT';
        const toolbarAction = gotoProj ? ToolbarAction.GOTOPROJECT : ToolbarAction.SAVEPROJECT;

        //const disableBtn = cfg.content.racks.length === 0;
        // We'll now allow a save (or goto project)
        // even if we have no racks.
        return [btnText, false, toolbarAction];
    }
    else {
        return ['NO PROJECT', true, ''];
    }

}

export const _useOrganizedBomFormat = true;
const _getBomForProject = (project: ChassisProject): BOMItemExch[] => {

    // Enlist a helper to get us a consolidate BOM
    // in the form used by our Summary page.
    const [orgBom, consolBom, totalQty] = getBomInfoForProjectSave(project);

    // See if it contains anything.
    // Expected format is an array with 1
    // element (of type MainHeader).
    const haveProducts = ((totalQty > 0) &&
        (consolBom.length === 1));

    // If not, just return an empty array.
    if (!haveProducts) {
        return [];
    }

    if (_useOrganizedBomFormat) {
        const organizedBom = _getOrganizedBomItemExchangeData(orgBom[0], project.config.installLocID);
        return organizedBom;
    }
    else {
        // Get the first (and only) element consolBom[0].
        const consolidatedBom = _getConsolidatedBomItemExchangeData(consolBom[0], project.config.installLocID);
        return consolidatedBom;
    }
}


const _getConsolidatedBomItemExchangeData = (hdrItem: BOMItem, country: string): BOMItemExch[] => {

    // If we can, AND it is the type we expect,
    // AND it has childen (the products)...
    if (hdrItem &&
        (hdrItem.bomItemType === BOMItemType.MainHeader) &&
        (hdrItem.children.length > 0)) {


        // Create our return array.
        const items = new Array<BOMItemExch>();

        // For each child in the main header...
        for (let idx = 0; idx < hdrItem.children.length; idx++) {

            // Get the child.
            const child = hdrItem.children[idx];

            // We EXPECT each child in a consolidated
            // BOM to be a product. If so...
            if (child.bomItemType === BOMItemType.Product) {

                // Cast to product-specific type.
                const asProd = child as BOMItemProduct;

                const priceEach = getCachedPriceFor(asProd.catalogOrTitle);

                // Add an item to our return array.
                items.push({
                    name: asProd.catalogOrTitle,
                    templateTitle: '',
                    description: asProd.description,
                    quantity: asProd.quantity,
                    sourceType: BomItemSourceType.Unknown,
                    productType: BomItemProductType.Product,
                    configThumbprint: '',
                    listPrice: priceEach * asProd.quantity,
                    photo: _getImageFileFromSource(asProd.imgSrc),
                    locale: country,
                    notification: '',
                    userNotes: '',
                    //children: []
                });
            }
            else {
                logger.error('Unexpected consol BOM child type!');
            }
        }

        // Return whatever we ended up with.
        return items;
    }
    throw new Error('Unrecognized consol BOM in _getConsolidatedBomItemExchangeData!');
}

const _getOrganizedBomItemExchangeData = (hdrItem: BOMItem, country: string): BOMItemExch[] => {

    // If we can, AND it is the type we expect,
    // AND it has childen (the products)...
    if (hdrItem &&
        (hdrItem.bomItemType === BOMItemType.MainHeader) &&
        (hdrItem.children.length > 0)) {

        // Create our return array.
        const items = new Array<BOMItemExch>();

        // For each child in the main header...
        for (let idx = 0; idx < hdrItem.children.length; idx++) {
            if (items) {
                _recurseOrganizedBom(hdrItem.children[idx], country, items);
            }
        }
        return items;
    }

    throw new Error('Unrecognized organized BOM in _getOrganizedBomItemExchangeData!');

}

const _recurseOrganizedBom = (bomItem: BOMItem, country: string, children: BOMItemExch[]) => {

    if (bomItem) {
        const asProd = bomItem as BOMItemProduct;

        // Source type for all Products should be 'Unknown'.
        // We had issues for pricing on some products when
        // 'RaiseExactMatchCatalogNumber' was used. Anything
        // that is not a Product will be a 'ProposalWorksGroup'.
        let sourceType = BomItemSourceType.Unknown;
        if (bomItem.bomItemType !== BOMItemType.Product) 
            sourceType = BomItemSourceType.ProposalWorksGroup;

        const itemPhoto = bomItem.photo
            ? bomItem.photo
            : _getImageFileFromSource(asProd.imgSrc);

        children.push({
            name: bomItem.catalogOrTitle,
            templateTitle: bomItem.bomItemType,
            description: asProd.description ? asProd.description : '',
            quantity: bomItem.quantity,
            sourceType: sourceType,
            productType: BomItemProductType.Product,
            configThumbprint: '',
            listPrice: asProd.listPriceEa ? asProd.listPriceEa : 0,
            photo: itemPhoto,
            locale: country,
            notification: '',
            userNotes: '',
            children: new Array<BOMItemExch>()
        })

        if (bomItem.children.length > 0) {
            for (let idx = 0; idx < bomItem.children.length; idx++) {
                if (children && children.length > 0) {
                    const childArray = children[children.length - 1].children as BOMItemExch[];
                    _recurseOrganizedBom(bomItem.children[idx], country, childArray);
                }
            }
        }
        return;
    }

    throw new Error('Unrecognized organized BOM in _getBomForProject!');
}

export const getConfigNameForSave = (cfg: ChassisProject): string => {
    if (cfg.config.projectName) {
        return cfg.config.projectName;
    }
    throw new Error('ERROR: getConfigNameForSave - config with NO NAME!');
}

const _cfgSavePropReplacer = (key: string, value: unknown) => {
    switch (key) {

        // Rehooked after reload.
        case 'parent':
            return null;

        // Recreated after reload.
        case 'statusLog':
        case 'ctlrCommsSpt':
        case 'remCommsReqd':
            return null;

        case 'dragTarget':
            return false;

        case 'xSlotWidth':
            return 0;

        case 'selected':
            return false;

        case 'dragStatus':
            return ModuleDragStatus.NA;

        default:
            break;
    }

    return value;
}

const _addSysDsgMetaData = (cfg: ChassisProject, exch: ConfigurationExchange) => {

    // See how many chassis and chassis BOM headers we have.
    // NOTE: The quantities will generally match, but WILL
    // NOT when any of the chassis is REDUNDANT. Each redundant
    // chassis is represented in the BOM using TWO BOM headers.
    const numChassis = cfg.content.racks.length;
    const numChBomHdrs = exch.bomItems.length;

    // If we don't have anything, just return.
    if ((numChBomHdrs === 0) || (numChassis === 0)) {
        return;
    }

    // Init our BOM header index to 0. We'll manually
    // increment this as we go to account for redundants.
    let nextBomHdrIdx = 0;

    // For each chassis we have...
    for (let chasIdx = 0; chasIdx < numChassis; chasIdx++) {
        // Sanity check that we have another BOM header.
        if (nextBomHdrIdx >= numChBomHdrs) {
            throw new Error('ERROR: Missing BOM header in _addSysDsgMetaData!');
        }

        // Get the chassis.
        const chassis = cfg.content.racks[chasIdx].chassis;

        // And the BOM header that SHOULD match.
        const chasBomHdr = exch.bomItems[nextBomHdrIdx];

        // If the names match...
        if (chasBomHdr.name === chassis.name) {

            // Get metadata for the chassis and store it
            // in the header's thumbprint prop.
            const md = getChassisMetadata(chassis, false);
            chasBomHdr.configThumbprint = md;
        }
        else {
            // Name mismatch?
            throw new Error('ERROR: Unexpected item mismatch in _addSysDsgMetaData!');
        }

        // If the chassis is redundant...
        if (chassis.redundant) {

            // Increment the BOM index and sanity check that
            // we have at least one more header left.
            nextBomHdrIdx += 1;
            if (nextBomHdrIdx >= numChBomHdrs) {
                throw new Error('ERROR: Missing BOM header in _addSysDsgMetaData!');
            }

            // Get the header that SHOULD match the extra,
            // redundant copy of the chassis. 
            const redBomHdr = exch.bomItems[nextBomHdrIdx];

            // If the name in the header matches what it should
            // for the redunant half of the chassis...
            if (redBomHdr.name === (chassis.name + '(R)')) {

                // Put metedata in ITS thumprint prop.
                const redMd = getChassisMetadata(chassis, true);
                redBomHdr.configThumbprint = redMd;
            }
            else {
                // Name mismatch?
                throw new Error('ERROR: Unexpected REDUNDANT item mismatch in _addSysDsgMetaData!');
            }
        }

        // Increment the BOM header index.
        nextBomHdrIdx += 1;
    }
}

export const makeConfigExchangePackage = (
    userProjGuid: string,
    cfg: ChassisProject,
    includeBom: boolean,
    forLocalStg = false
): ConfigurationExchange => {

    // Get our startup details.
    const startDtls = store.getState().startupInfo.startupDetails;
    if (startDtls) {

        // Call a helper to store some extra info into the
        // configuration used ONLY during reloading. The extra
        // info is completely benign otherwise, and does not
        // affect usage of the active project in any way.
        prepProjectConfigForPersist(cfg);

        // Get the BOM portion of our exchange data.
        const bom = includeBom ? _getBomForProject(cfg) : [];

        // Our thumbprint will contain a stringified version
        // of our project. The replacer we use unhooks .parent
        // props to prevent circular references, and removes
        // or resets other data we don't need for reconstitution.
        const thumbprint = JSON.stringify(cfg, _cfgSavePropReplacer, 0);

        // Create our exchange object.
        // Note: the .country is the USER's country, and NOT
        // necessarily the same as the install loc from the cfg.
        const exch: ConfigurationExchange = {
            id: cfg.id,
            name: getConfigNameForSave(cfg),
            projectId: userProjGuid,
            country: startDtls.country,
            hasChanges: true,
            configThumbprint: thumbprint,
            bomItems: bom
        }

        if (forLocalStg) {
            exch.lastModifiedDateUtc = getDateStringNowUTC();
        }

        if (includeBom) {
            _addSysDsgMetaData(cfg, exch);
        }

        // And return it.
        return exch;
    }
    else {
        throw new Error('No startup details in makeConfigExchangePackage!');
    }
}

export const gotoProjectURL = (userProjGuid?: string, project?:ChassisProject) => {

    // Construct the path used to route to the user project.
    // If a project is not passed in, take the user to the
    // 'Projects' (not 'Project') Page.
    const userProjectUri = userProjGuid && project
        ? encodeURI(
            `${config.PROJECTS_SITE}items/detail/${project.id}?v=list&projectGuid=${userProjGuid}`)
        : encodeURI(
            `${config.PROJECTS_SITE}`)

    // Go to project url
    window.location.href = userProjectUri;
}

export const gotoConfigSelPage = () => {

    // Construct page to page to select desired
    // configuration (.../systemdesigner/controlsystem).
    const selPageUri = encodeURI(`${config.CS_PAGE}`);

    // Go there.
    window.location.href = selPageUri;
}

const _isValidImageFile = (fileName: string): boolean => {
    if (fileName) {
        const parts = fileName.split(".");
        if (parts.length > 1) {
            const ext = parts[parts.length - 1];
            switch (ext.toLowerCase()) {
                case 'bmp':
                case 'jpg':
                case 'jpeg':
                case 'gif':
                case 'png':
                case 'svg':
                    return true;
            }
        }
    }

    return false;
}

const _getImageFileFromSource = (imageSource: string): string => {
    let imageFile = '';
    if (imageSource) {
        const parts = imageSource.split("/");
        if (parts.length > 1) {
            imageFile = parts[parts.length - 1].trim();
        }
        else {
            imageFile = imageSource.trim();
        }
    }

    if (_isValidImageFile(imageFile)) {
        return imageFile;
    }

    return '';
}

