import { CheckerImplementation, RegisterCheckerImpl } from "../../../implementation/ImplChecker";
import { getPowerBreakdown } from "../../../model/ChassisProject";
import { PowerBreakdown } from "../../../types/PowerTypes";
import { Chassis, ChassisPowerSupply, FlexHAChassis, FlexHASAPowerSupply } from "../../../types/ProjectTypes";
import { PSUErrorThreshold, PSUWarningThreshold } from "../../../util/Checker";
import { addAutoFix, AFType, getNewAutoFix } from "../../../util/CheckerAutoFix";
import { addPowerBreakdown, calcPowerRatio, getEmptyPowerBreakdown } from "../../../util/PowerHelp";
import { addLogMessage, getNewProjectLog, LogMsgLevel, ProjectLog, StatusLogType } from "../../../util/ProjectLog";
import { PlatformFlexHA } from "../../PlatformConstants";
import { flexHAExecuteAutoFix } from "./FlexHAAutoFix";
import { flexHAGetIOBaseFirstSlotIdx, flexHAIsSAPowerSupply, getAsFlexHAChassis } from "./FlexHAChassis";
import { flexHAGetBankStartIOBases } from "./FlexHAGeneralImpl";
import { flexHA_DefMODPsu } from "./FlexHAHardwareImpl";


const _mapCatToPwrBrk = new Map<string, PowerBreakdown>();

const getPowerBreakdownValue = (catalog: string): PowerBreakdown => {
    const pbMap = _mapCatToPwrBrk.get(catalog);
    if (!pbMap) {
        const pb = getPowerBreakdown(PlatformFlexHA, catalog);
        _mapCatToPwrBrk.set(catalog, pb);
        return pb;
    }

    return pbMap;
}

export const flexHAGetSAPowerBreakdown = (psu: ChassisPowerSupply, chassis: Chassis): [supplied: PowerBreakdown, consumed: PowerBreakdown, idxSlotStart: number, idxSlotEnd: number] => {
    if (!flexHAIsSAPowerSupply(psu))
        throw new Error('flexHAGetSAPowerBreakdown(): Power supply is NOT a FLEXHA SA PSU.');

    const fhaChassis = chassis as FlexHAChassis;
    const saPSU = psu as FlexHASAPowerSupply;

    const supplied = getPowerBreakdownValue(saPSU.catNo);

    // Determine the first slot that 
    // could contain an SA Pwr Consumer.
    const idxModStart = flexHAGetIOBaseFirstSlotIdx(chassis, saPSU.ioBaseIndex);


    // Determine the ending module slot, which
    // is one slot greater than the PSU cares about.
    // Initially set it the mod array length.
    let idxModEnd = chassis.modules.length;

    // Now find the next SA PSU (if any) or
    // next BANK. Combine the bank and SA Psu
    // base indexes.
    const idxBaseSAPwrBrk = flexHAGetBankStartIOBases(fhaChassis);
    fhaChassis.saPSU.forEach((psu) => {
        if (!idxBaseSAPwrBrk.some(x => x === psu.ioBaseIndex))
            idxBaseSAPwrBrk.push(psu.ioBaseIndex);
    });

    idxBaseSAPwrBrk.sort();

    for (let psuIdx = 0; psuIdx < idxBaseSAPwrBrk.length; ++psuIdx) {
        const idxStop = idxBaseSAPwrBrk[psuIdx];
        // If less than or == to our psu that we
        // are processing...
        if (idxStop <= saPSU.ioBaseIndex)
            continue;

        // idxStop must be the NEXT SA PSU or BANK
        // "I/O Base Index" on the chassis
        idxModEnd = flexHAGetIOBaseFirstSlotIdx(chassis, idxStop);
        break;
    }

    const consumed = getEmptyPowerBreakdown();
    for (let idx = idxModStart; idx < idxModEnd; ++idx) {
        const mod = chassis.modules[idx];
        if (mod == null || mod.isPlaceholder || mod.slotFiller)
            continue;

        const pbConsumed = getPowerBreakdownValue(mod.catNo);
        addPowerBreakdown(consumed, pbConsumed, true);
    }

    return [supplied, consumed, idxModStart, idxModEnd - 1];
}

export const flexHAGetMODPowerBreakdown = (chassis: Chassis): [supplied: PowerBreakdown, consumed: PowerBreakdown] => {

    // This will be the chassis MOD power.
    // When the PSU is selected OR there
    // is not an adapter, use the PSU catalog
    let catSupplier = flexHA_DefMODPsu;
    const adapter = chassis.modules[0];
    if (adapter)
        catSupplier = adapter.catNo;

    const supplied = getPowerBreakdownValue(catSupplier);

    // Tally up the consumed power of the
    // modules starting at SLOT 1 (skip the
    // adapter).
    const consumed = getEmptyPowerBreakdown();
    const lenModArr = chassis.modules.length;
    for (let idx = 1; idx < lenModArr; ++idx) {
        const mod = chassis.modules[idx];
        if (mod == null || mod.isPlaceholder || mod.slotFiller)
            continue;

        const pbConsumed = getPowerBreakdownValue(mod.catNo);
        addPowerBreakdown(consumed, pbConsumed, true);
    }

    return [supplied, consumed];
}


const flexHADoChassisPowerCheck = (untypedChassis: Chassis): boolean => {

    const chassis = (untypedChassis ? untypedChassis as FlexHAChassis : undefined);
    if (!chassis || chassis.saPSU == null)
        throw new Error('flexHADoChassisPowerCheck(): Chassis is not a Flex HA Chassis!');
    
    if (untypedChassis.statusLog == null)
        untypedChassis.statusLog = getNewProjectLog(StatusLogType.chassis);

    const statusLog: ProjectLog = untypedChassis.statusLog;

    const platform = chassis.platform;

    let thresholdExceeded = 0;
    let afType = AFType.NA;
    let lvl = LogMsgLevel.none;

    ///////////////  MOD Power //////////////////

    // Check the MOD Power for the chassis.
    // This 'should' always be OK, but check.
    const [modSupl, modCons] = flexHAGetMODPowerBreakdown(chassis);
    const pct = calcPowerRatio(modCons.modPower, modSupl.modPower);
    if (pct > PSUErrorThreshold) {
        thresholdExceeded = PSUErrorThreshold;
        lvl = LogMsgLevel.error;
        afType = AFType.ChassisOutOfPower;
    }
    else if (pct > PSUWarningThreshold) {
        thresholdExceeded = PSUWarningThreshold;
        lvl = LogMsgLevel.warning;
        afType = AFType.ChassisLowOnPower;
    }

    if (thresholdExceeded > 0) {
        const af = getNewAutoFix(platform, chassis.id, afType);
        addAutoFix(af);

        const percentage = (thresholdExceeded * 100).toFixed(1);
        const msg = `The consumed MOD Power (${(modCons.modPower / 1000).toFixed(2)} Amps) in chassis ${chassis.name} ` +
            `exceeds ${percentage}% of the supplied power of ${(modSupl.modPower / 1000).toFixed(2)} Amps.`;
        addLogMessage(
            statusLog,
            msg,
            lvl,
            chassis,
            af.id);
    }

    ///////////////  SA Power //////////////////
    
    // First confirm we have SA Power for each bank.
    const ioBaseIndexes = flexHAGetBankStartIOBases(chassis);
    const noBanks = ioBaseIndexes.length <= 1;
    ioBaseIndexes.forEach((idxIOBase, idxBank) => {
        // Really should not happen, but check.
        if (!chassis.saPSU.some(psu => psu.ioBaseIndex === idxIOBase)) {
            // There is a bank(s) that does NOT
            // have an SA PSU connected to the
            // the first I/O Base.
            const af = getNewAutoFix(platform, chassis.id, AFType.SAPwr_PSUMissing);
            const msg = (
                noBanks
                ?
                    `An SA Power Supply is required on the first I/O Base of chassis ${chassis.name}`
                :
                    `An SA Power Supply is required on the first I/O Base of bank ${idxBank + 1} (slot ${(idxIOBase * 4) + 1}) of chassis ${chassis.name}`
            );
            addLogMessage(
                statusLog,
                msg,
                LogMsgLevel.error,
                chassis,
                af.id);
       }
    });

    // Walk the SA Power Supplies. We will generate 
    // just ONE message for all Chassis SA PSUs.
    let saPSUPwrIssue = false;
    chassis.saPSU.forEach((psu) => {
        if (!saPSUPwrIssue) {
            const [supl, cons] = flexHAGetSAPowerBreakdown(psu, chassis);
            const pct = calcPowerRatio(cons.saPower, supl.saPower);
            saPSUPwrIssue = (pct > PSUWarningThreshold);
        }
    });

    if (saPSUPwrIssue) {
        const af = getNewAutoFix(platform, chassis.id, AFType.SAPwr_Issue);
        addAutoFix(af);

        const msg = 'The configuration currently exceeds SA power capabilities on your I/O base(s), however the configuration ' +
            'is using the maximum module value for evaluating SA Power Requirements. It is recommended you do your own review of SA ' +
            'Power usage to ensure your configuration will function appropriately. Note that I/O bases are capable of having their ' +
            'own SA Power Supply input or pulling power from the previous I/O base.';

        addLogMessage(
            statusLog,
            msg,
            LogMsgLevel.info,
            chassis,
            af.id);
    }

    return true;
}

const _genChkAdapter = (chassis: FlexHAChassis, statusLog: ProjectLog) => {
    // Check for the adapter
    const adapter = chassis.modules[0];
    if (adapter == null) {
        const af = getNewAutoFix(chassis.platform, chassis.id, AFType.NoCommInChassis);
        addAutoFix(af);

        const msg = 'Every chassis must have an Adapter in Slot 0 to properly function. Please add one.';

        addLogMessage(
            statusLog,
            msg,
            LogMsgLevel.error,
            chassis,
            af.id);
    }
}

const _genChkBanking = (chassis: FlexHAChassis, statusLog: ProjectLog) => {
    if (chassis.primaryBankInfo == null || chassis.auxBanks == null)
        return;

    let error = false;

    // Just look at the starting slots. If we have
    // dups OR the start slot is beyond the module
    // array length, we have an error state.
    const lenModArr = chassis.modules.length;
    const lenAuxArr = chassis.auxBanks.length;

    const setStarts = new Set<number>();
    setStarts.add(chassis.primaryBankInfo.startSlot);

    for (let idx = 0; idx < lenAuxArr; ++idx) {
        const info = chassis.auxBanks[idx].info;
        if (setStarts.has(info.startSlot) ||
            info.slotsInBank === 0 ||
            info.startSlot >= lenModArr) {
            error = true;
        }
        else
            setStarts.add(info.startSlot);
    }

    if (error) {
        const af = getNewAutoFix(chassis.platform, chassis.id, AFType.BankingError);
        addAutoFix(af);

        const msg = 'Warning: Your layout currently has no I/O modules following a bank extension kit. ' +
            'Please add I/O modules to your bank, remove the extension kit, or move the extension kit ' +
            'to a more appropriate location between modules.';

        addLogMessage(
            statusLog,
            msg,
            LogMsgLevel.error,
            chassis,
            af.id);
    }
}

const flexHADoGeneralCheck = (chassis: Chassis) => {
    const fhaChassis = getAsFlexHAChassis(chassis);
    if (!fhaChassis)
        throw new Error('flexHADoGeneralCheck(): Chassis is not a Flex HA Chassis!');

    if (chassis.statusLog == null)
        chassis.statusLog = getNewProjectLog(StatusLogType.chassis);

    _genChkAdapter(fhaChassis, chassis.statusLog);
    _genChkBanking(fhaChassis, chassis.statusLog);
}

export const RegisterFlexHACheckerImpl = () => {
    const flexHAImpl: CheckerImplementation = {
        platform: PlatformFlexHA,
        doGeneralCheck: flexHADoGeneralCheck,
        doChassisPowerCheck: flexHADoChassisPowerCheck,
        executeAutoFix: flexHAExecuteAutoFix,
    }

    RegisterCheckerImpl(flexHAImpl);
}
