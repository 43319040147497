import { EngInfoPowerSupply } from '../../../engData/EngineeringInfo';
import { deleteModuleAtSlot, getPowerDetails } from '../../../implementation/ImplGeneral';
import {
    chassisChanged,
	getChassisAndRackIndexById,
	getEnvRatingFromSpec,
} from '../../../model/ChassisProject';
import { PSInputVoltage } from '../../../types/PowerTypes';
import {
	ChassisProject,
} from '../../../types/ProjectTypes'
import { chkrModule, PSUWarningThreshold } from '../../../util/Checker';
import { AFType, AF_Base, deleteAutoFix } from '../../../util/CheckerAutoFix'
import { autoFixNoSafetyController } from '../../../util/CheckerAutoFixProjHelpers';
import { getPowerSuppliesFor, getPowerSupplyEngInfo } from '../../../util/EngInfoHelp';
import {
	addPowerBreakdown,
	getEmptyPowerBreakdown,
	isMoreOrEqualPower,
	IsPowerThresholdExceeded,
	subtractPowerBreakdown
} from '../../../util/PowerHelp';
import { isServerEnvProd } from '../../../util/ServerEnvironment';
import { contentChanging, suspendUndoSnapshots } from '../../../util/UndoRedo';
import { PlatformCLX } from '../../PlatformConstants';
import {
	clxGetValidScannerFromChassis,
	clxMigrateModulesToNewChassis,
	clxMoveModule,
	getMatchingModComps,
	getNewCLXMigrateChassisSpecs,
} from './CLXAutoFixHelpers';
import { autoFixMissingSafetyPartner, autoFixSysPerformance } from './CLXAutoFixSysPerf';
import {
	addClxRedundancyMod,
	clxReplacePowerSupply
} from './CLXChassis';
import { clxScanChassis, getNewCLXChassisComponents } from './CLXChecker';

export const clxExecuteAutoFix = (af: AF_Base, project: ChassisProject, callback: () => void): boolean => {
    let success = false;
    switch (af.type) {
        case AFType.ChassisOutOfPower:
            // 2024.3.26 Chassis being corrected will have modules
            // moved to a new chassis until the WARNING (not Error)
            // threshold is satisfied.
            //success = _afChassisOutOfPower(project, af, PSUErrorThreshold);
            success = _afChassisOutOfPower(project, af, PSUWarningThreshold);
            break;
        case AFType.ChassisLowOnPower:
            success = _afChassisOutOfPower(project, af, PSUWarningThreshold);
            break;

        case AFType.Red_RemoteChassisCannotBeRed:
        case AFType.Red_RemoveRedundancyModule:
        case AFType.Red_MultpleRMModules:
            {
                const removeAllButOne = (af.type === AFType.Red_MultpleRMModules);
                success = _afRemoveRedundancyModules(project, af, removeAllButOne);
            }
            break;

        case AFType.Red_MigrateNonRedModules:
            success = _afMigrateNonRedundantModules(project, af);
            break;

        case AFType.Red_RMModNotNextToController:
            success = _afMoveRMNextToController(project, af);
            break;

        case AFType.Red_TooManyL8s:
            success = _afMigrateExtraRedL8s(project, af);
            break;

        case AFType.Red_MixedL8andL7:
        case AFType.Red_TooManyL7s:
            success = _afMigrateExtraRedL7s(project, af);
            break;

        case AFType.Sys_Performance:
            success = autoFixSysPerformance(project);
            break;

        case AFType.Safety_NoL7SP:
            success = autoFixMissingSafetyPartner(project, af);
            break;

        case AFType.Safety_NoSafetyCtlrInConfig:
            success = autoFixNoSafetyController(project);
            break;

        default:
            // If we are NOT Production...
            if (!isServerEnvProd())
                window.alert('AutoFix not yet implemented: ' + af.type);
            break;
    }

    if (success)
        callback();

    return success;
}

const _afMigrateExtraRedL8s = (project: ChassisProject, autoFix: AF_Base): boolean => {

	// Call our helper to get the chassis and rack index
	const [targetChassis, idxRack] = getChassisAndRackIndexById(project, autoFix.targetInstanceID);

	// Abort if we don't get info.
	if (targetChassis == null || idxRack < 0) {
		alert('Unable to correct issue.');
		return false;
	}

	// If the target chassis is not redundant...
	if (targetChassis.redundant === false) {
		alert('Chassis is not redundant. Auto-correction aborted.');
		deleteAutoFix(autoFix);
		return false;
	}

	const chkrComps = getNewCLXChassisComponents();
	clxScanChassis(targetChassis, chkrComps);

	const l8Comps = getMatchingModComps(chkrComps.processorModComps, '1756-L8');
	if (l8Comps.length < 2) {
		alert('Chassis does not HAVE multiple L8 controllers. Auto-correction aborted.');
		deleteAutoFix(autoFix);
		return false;
	}

	// L8s have onboard comm, so we don't generally need
	// a scanner module. Start with assumption we don't.
	let scannerCat: string | undefined = undefined;

	// However, if ALL of the controllers on the chassis
	// are L8s (if more procMods, some must be L7s)...
	if (l8Comps.length === chkrComps.processorModComps.length) {

		// And we found ANY scanner modules...
		if (chkrComps.commModComps.length > 0) {

			// Then we'll use the catnum of the first.
			scannerCat = chkrComps.commModComps[0].module.catNo;
		}
	}

	let anySuccessful = false;

	// We'll control snapshots ourselves, since
	// we may be adding more than 1 new chassis.
	// Take a snapshot for undo/redo.
	contentChanging(project.content);

	// Suspend any more snapshots while we add our chassis.
	const snapshotsWereSuspended = suspendUndoSnapshots(true);

	// While we STILL have more than 1 L8 controller left...
	while (l8Comps.length > 1) {

		// Get the chkrModule representing the last one left.
		const lastL8 = l8Comps[l8Comps.length - 1];

		// Make a migration spec.
		const migrateSpec = getNewCLXMigrateChassisSpecs(chkrComps, [lastL8], targetChassis, project);
		migrateSpec.idxSourceChassis = idxRack;

		// If we determined we want a scanner,
		// include that in our spec.
		if (scannerCat) {
			migrateSpec.scannerCatalog = scannerCat;
		}

		// Call our migrate helper, which will create a new
		// empty chassis duplicate of the target. Note that we
		// include the arg the says we DON'T want the migrator
		// to 'automatically' add a scanner module. He will if
		// we provided one above, and won't if not.
		const [success, newChassis] = clxMigrateModulesToNewChassis(migrateSpec, false);

		// If that worked...
		if (success) {
			// Then we should have gotten a
			// new chassis back. If so...
			if (newChassis) {
				// Add an RM module.
				addClxRedundancyMod(newChassis);

				// We had some success.
				anySuccessful = true;
			}
			else {
				// Unexpected
				throw new Error('Unexpected error in _afMigrateExtraRedL8s');
			}
		}
		else {
			// Unexpected error. Notify and break out of our loop.
			alert('Migrate of L8 controller to new chassis FAILED.');
			break;
		}

		// Remove the LAST chkrModule object from our
		// array. We just moved it to a new chassis, so
		// it won't be there anymore in our target.
		l8Comps.pop();
	}

	// We're done with our migration(s). Set 
	// undo suspends back to where we started.
	suspendUndoSnapshots(snapshotsWereSuspended);

	// If we moved ANY extra L8s to new chassis...
	if (anySuccessful) {
		// Increment the chassis bumper prop on our
		// original (target) chassis to ensure it
		// gets re - rendered.
		chassisChanged(targetChassis);
	}
	else {
		deleteAutoFix(autoFix);
	}

	return anySuccessful;
}

// Note: This function is dual-purpose, used to
// fix two similar but different checker errors:
//   1. There are more than 2 L7 controllers in a redundant chassis
//   2. A redundant chassis contains BOTH L8 and L7 controllers.
// In BOTH cases, the mission is to add additional chassis and
// migrate L7 module(s) to them. The only difference is how
// many L7 modules we're allowed to RETAIN on the chassis.
const _afMigrateExtraRedL7s = (project: ChassisProject, autoFix: AF_Base): boolean => {

	// Call our helper to get the chassis and rack index
	const [targetChassis, idxRack] = getChassisAndRackIndexById(project, autoFix.targetInstanceID);

	// Abort if we don't get info.
	if (targetChassis == null || idxRack < 0) {
		alert('Unable to correct issue.');
		return false;
	}

	// If the target chassis is not redundant...
	if (targetChassis.redundant === false) {
		alert('Chassis is not redundant. Auto-correction aborted.');
		deleteAutoFix(autoFix);
		return false;
	}

	// Determine the max number of L7 controller modules
	// we'll LEAVE on the target chassis. If we're fixing the
	// too-many case, we'll allow 2. If the mixed controller
	// case, we'll move ALL we find, keeping none on the target.
	const numL7sAllowedToStay = (autoFix.type === AFType.Red_TooManyL7s) ? 2 : 0;

	const chkrComps = getNewCLXChassisComponents();
	clxScanChassis(targetChassis, chkrComps);

	// Call a helper to find all resident L7s.
	const l7Comps = getMatchingModComps(chkrComps.processorModComps, '1756-L7');

	// See how many we're supposed to move.
	const numToMove = l7Comps.length - numL7sAllowedToStay;

	// We should be moving at least 1. If not..
	if (numToMove <= 0) {
		alert('Chassis does not HAVE any invalid L7 controllers. Auto-correction aborted.');
		deleteAutoFix(autoFix);
		return false;
	}

	// L7s do NOT have onboard comm, so we'd expect to find one
	// on the target chassis. If we have any, use the catnum of
	// the first one found. If not, we'll leave undefined for now.
	const scannerCat = (chkrComps.commModComps.length > 0)
		? chkrComps.commModComps[0].module.catNo
		: undefined;

	let anySuccessful = false;

	// We'll control snapshots ourselves, since
	// we may be adding more than 1 new chassis.
	// Take a snapshot for undo/redo.
	contentChanging(project.content);

	// Suspend any more snapshots while we add our chassis.
	const snapshotsWereSuspended = suspendUndoSnapshots(true);

	// While we STILL have MORE L7 controllers to movet...
	while (l7Comps.length > numL7sAllowedToStay) {

		// Create an empty array to hold the
		// chkrModule objects representing the modules
		// we want to migrate to a new chassis.
		const l7sToMove = new Array<chkrModule>();

		// Determine how many we'll be moving
		// in this loop iteration, which will be
		// either 1 or 2.
		const numToMove = Math.min(2, l7Comps.length - numL7sAllowedToStay);

		// Add those last 1 or 2 to our array.
		const firstToMove = l7Comps.length - numToMove;
		const lastToMove = firstToMove + numToMove - 1;
		for (let idx = firstToMove; idx <= lastToMove; idx++) {
			l7sToMove.push(l7Comps[idx]);
		}

		// Make a migration spec.
		const migrateSpec = getNewCLXMigrateChassisSpecs(chkrComps, l7sToMove, targetChassis, project);
		migrateSpec.idxSourceChassis = idxRack;

		// If we found a scanner cat above,
		// include that in our spec.
		if (scannerCat) {
			migrateSpec.scannerCatalog = scannerCat;
		}

		// Call our migrate helper, which will create a new
		// empty chassis duplicate of the target. Note that we
		// since we DON'T include the second optional arg here,
		// the migrator should 'automatically' add a suitable
		// scanner module of we haven't already provided a 
		// cat num to our spec.
		const [success, newChassis] = clxMigrateModulesToNewChassis(migrateSpec);

		// If that worked...
		if (success) {
			// Then we should have gotten a
			// new chassis back. If so...
			if (newChassis) {
				// Add an RM module.
				addClxRedundancyMod(newChassis);

				// We had some success.
				anySuccessful = true;
			}
			else {
				// Unexpected
				throw new Error('Unexpected error in _afMigrateExtraRedL7s');
			}
		}
		else {
			// Unexpected error. Notify and break out of our loop.
			alert('Migrate of L7 controller(s) to new chassis FAILED.');
			break;
		}

		// Remove the any chkrModule objects from our
		// array representing what we just moved. They
		// won't be there anymore in our target.
		for (let idx = 0; idx < numToMove; idx++) {
			l7Comps.pop();
		}
	}

	// We're done with our migration(s). Set 
	// undo suspends back to where we started.
	suspendUndoSnapshots(snapshotsWereSuspended);

	// If we moved ANY extra L7s to new chassis...
	if (anySuccessful) {
		// Increment the chassis bumper prop on our
		// original (target) chassis to ensure it
		// gets re - rendered.
		chassisChanged(targetChassis);
	}
	else {
		deleteAutoFix(autoFix);
	}

	return anySuccessful;
}


const _afMoveRMNextToController = (project: ChassisProject, autoFix: AF_Base): boolean => {
	const [targetChassis, idxRack] = getChassisAndRackIndexById(project, autoFix.targetInstanceID);
	if (targetChassis == null || idxRack < 0) {
		alert('Unable to correct issue.');
		return false;
	}
	else {
		// Scan the chassis.
		const chkrComps = getNewCLXChassisComponents();
		clxScanChassis(targetChassis, chkrComps);

		// If the chassis is not redundant OR we do not have any controllers...
		if (targetChassis.redundant === false ||
			chkrComps.processorModComps.length === 0 ||
			chkrComps.redundancyModComps.length === 0) {
			alert('Chassis is not redundant or does not contain a controller. Auto-correction aborted.');
			deleteAutoFix(autoFix);
			return false;
		}

		// Get the first controller and the first RM module...
		let slotSource = chkrComps.redundancyModComps[0].module.slotIdx;
		let slotDestination = chkrComps.processorModComps[0].module.slotIdx;

		// We always want to shift items to the front of the chassis.
		if (slotDestination > slotSource) {
			// swap the values.
			const x = slotSource;
			slotSource = slotDestination;
			slotDestination = x;
		}

		// Move the src module to the right of the target.
		slotDestination++;

		// Call our helper to do the move.
		const moveRslt = clxMoveModule(targetChassis, slotSource, slotDestination, project);

		// Increment the chassis bumper prop to
		// ensure it gets re-rendered.
		chassisChanged(targetChassis);

		// Return the move result.
		return moveRslt;
	}
}

const _afMigrateNonRedundantModules = (project: ChassisProject, autoFix: AF_Base): boolean => {
	// Remove the RM module.
	// Call our helper to get the chassis and rack index
	// from Project.Content.
	const [targetChassis, idxRack] = getChassisAndRackIndexById(project, autoFix.targetInstanceID);
	if (targetChassis == null || idxRack < 0) {
		alert('Unable to correct issue.');
		return false;
	}
	else {
		// If our chassis is not redundant...
		if (targetChassis.redundant === false) {
			alert('Chassis is not redundant. Auto-correction aborted.');
			deleteAutoFix(autoFix);
			return false;
		}

		const chkrComps = getNewCLXChassisComponents();
		clxScanChassis(targetChassis, chkrComps);

		const arrModuleToMigrate: chkrModule[] = [];

		// Check for any modules that are not redundant.
		chkrComps.allModComps.forEach(modComp => {
			if (!modComp.engInfo.redCapable) {
				arrModuleToMigrate.push(modComp);
			}
		});

		if (arrModuleToMigrate.length === 0) {
			alert('Redundant Chassis does not contain non-redundant modules. Issue correction aborted');
			deleteAutoFix(autoFix);
			return false;
		}

		if (targetChassis.ps == null) {
			alert('The Redundant Chassis does NOT have a Power Supply. Please correct this issue first then try again.');
			deleteAutoFix(autoFix);
			return false;
		}

		const envRating =
			getEnvRatingFromSpec(targetChassis.extendedTemp, targetChassis.conformal);
		const [scannerCatNo,] = clxGetValidScannerFromChassis(chkrComps, envRating);
		if (scannerCatNo === '') {
			alert('Unable to resolve scanner for chassis. Auto-correction aborted.');
			deleteAutoFix(autoFix);
			return false;
		}

		//////////////// Create a new chassis and migrate modules /////////////
		const migrateSpec = getNewCLXMigrateChassisSpecs(chkrComps, arrModuleToMigrate, targetChassis, project);
		migrateSpec.idxSourceChassis = idxRack;
		migrateSpec.scannerCatalog = scannerCatNo;
		const [success,] = clxMigrateModulesToNewChassis(migrateSpec);
		if (!success)
			deleteAutoFix(autoFix);

		chassisChanged(targetChassis);
		return success;
	}
}

const _afRemoveRedundancyModules = (project: ChassisProject, autoFix: AF_Base, allButOneRM: boolean): boolean => {
	// Remove the RM module.
	// Call our helper to get the chassis and rack index
	// from Project.Content.
	const [targetChassis, idxRack] = getChassisAndRackIndexById(project, autoFix.targetInstanceID);
	if (targetChassis == null || idxRack < 0) {
		alert('Unable to correct issue.');
		return false;
	}
	else {
		const chkrComps = getNewCLXChassisComponents();
		clxScanChassis(targetChassis, chkrComps);

		// Take a snapshot for undo/redo
		contentChanging(project.content);
		// Suspend any more snapshots while we add our chassis.
		const snapshotsWereSuspended = suspendUndoSnapshots(true);

		let success = true;
		let numberOfRemoved = 0;
		chkrComps.redundancyModComps.forEach((chkrComp) => {
			// If we are NOT removing ALL BUT ONE RM module OR
			// the number of removed RMs is greater than zero...
			if ((numberOfRemoved > 0 || allButOneRM === false)) {
				if (deleteModuleAtSlot(targetChassis, chkrComp.module.slotIdx) === false)
					success = false;
				else
					numberOfRemoved++;
			}
			else {
				numberOfRemoved++;
			}
		});

		suspendUndoSnapshots(snapshotsWereSuspended);

		return success;
	}
}


const _afChassisOutOfPower = (project: ChassisProject, autoFix: AF_Base, powerThreshold: number): boolean => {
	let success = false;

	// Call our helper to get the chassis and rack index
	// from Project.Content.
	const [targetChassis, idxRack] = getChassisAndRackIndexById(project, autoFix.targetInstanceID);
	if (targetChassis == null || idxRack < 0) {
		alert('Unable to correct issue.');
		return false;
	}
	else {
		// Double check that the chassis is still out of power.
		const [supplied, consumed] = getPowerDetails(targetChassis);
		// If not...
		let pwrExceeded = IsPowerThresholdExceeded(targetChassis.platform, consumed, supplied, powerThreshold);
		if (pwrExceeded === false) {
			return true;
		}

		const envRating =
			getEnvRatingFromSpec(targetChassis.extendedTemp, targetChassis.conformal);
		const currentPSU = targetChassis.ps
			? getPowerSupplyEngInfo(PlatformCLX, targetChassis.ps.catNo)
			: undefined;

		// Find the largest PSU avaiable. It may be the
		// the current one in the chassis. 
		let inputVoltage = PSInputVoltage.DC24V;
		if (currentPSU && currentPSU.supplyVltg.allSupported.length > 0)
			inputVoltage = currentPSU.supplyVltg.allSupported[0];

		// Get all compatible PSU's.
		const allPSUs = getPowerSuppliesFor(PlatformCLX, envRating, inputVoltage);

		// Get the array of Redundant or Single PSUs
		const PSUs = (targetChassis.redundant ? allPSUs?.redundants : allPSUs?.singles);

		// Get the Largest PSU available.
		let largestPSU: EngInfoPowerSupply | undefined = undefined;
		if (PSUs && PSUs.length > 0) {
			largestPSU = PSUs[0];

			PSUs.forEach((psu, index) => {
				if (index > 0 && largestPSU && psu) {
					if (isMoreOrEqualPower(psu.powerAvail, largestPSU.powerAvail)) {
						largestPSU = psu;
					}
				}
			});
		}

		// If we do not have a largest PSU, we're done.
		if (!largestPSU) {
			alert('Unable to find compatible Power Supply. Issue cannot be resolved.');
			deleteAutoFix(autoFix);
			return false;
		}

		// Is the largest PSU suitable, use it.
		pwrExceeded = IsPowerThresholdExceeded(targetChassis.platform, consumed, largestPSU.powerAvail, powerThreshold);
		if (pwrExceeded === false) {
			return clxReplacePowerSupply(targetChassis, largestPSU.catNo);
		}

		// We know that we have to create a new chassis
		// and split the modules in the current chassis up.

		// Scan the chassis to get the components/modules.
		const chkrComps = getNewCLXChassisComponents();
		clxScanChassis(targetChassis, chkrComps);

		////////////// SCANNER ///////////////////////////////////////
		// From the checker chassis components, select the first
		// ENET scanner. If not ENET, then CNET and then a default
		// 1756-EN2T scanner.
		const [scannerCatNo, scannerEngInfo] = clxGetValidScannerFromChassis(chkrComps, envRating);

		if (scannerCatNo === '') {
			deleteAutoFix(autoFix);
			return false;
		}

		//////////////// I/O MODULES TO MIGRATE /////////////////////////////
		// Per task and Colin, we will create another chassis
		// with the SAME chassis, FIRST comm card, and as many
		// I/O modules off the end of the chassis that it takes
		// to get the power in both chassis corrected.

		// Copy the Consumed and setup variables.
		const consumedTracking = { ...consumed };
		let removeMoreIOMods = true;
		const totalNewConsumedTracking = getEmptyPowerBreakdown();
		const arrIOModulesToMove: chkrModule[] = [];
		const arrModulesInReverse = chkrComps.allModComps.reverse();
		arrModulesInReverse.forEach((chkrMod) => {
			if (removeMoreIOMods) {
				if (chkrMod.engInfo.isIO) {
					const pwrBrkDown = chkrMod.engInfo.powerUsed;
					// Add the mod's pwr to total consumed, which 
					// is tallied on totalNewConsumedTracking;
					addPowerBreakdown(totalNewConsumedTracking, pwrBrkDown, true);
					// Subtract the mod's pwr from the chassis' total.
					subtractPowerBreakdown(consumedTracking, pwrBrkDown, true);
					// Add the module to the migration list.
					arrIOModulesToMove.push(chkrMod);

					// Are we good...
					pwrExceeded = IsPowerThresholdExceeded(targetChassis.platform, consumedTracking, supplied, powerThreshold);
					if (pwrExceeded === false) {
						removeMoreIOMods = false;
					}
				}
			}
		});

		if (removeMoreIOMods) {
			alert('Unable to find enough I/O modules to move to a new chassis. Issue cannot be resolved.');
			deleteAutoFix(autoFix);
			return false;
		}

		////////////// VALIDATE NEW CHASSIS COMPONENTS ///////////////
		// Check if the new chassis' PSU can handle the load.
		// Get the power consumed by the scanner and add it to
		// the consumed power from the I/O modules we are going 
		// to migrate.
		const scannerPwrBrkDown = scannerEngInfo
			? { ...scannerEngInfo.powerUsed }
			: getEmptyPowerBreakdown();
		addPowerBreakdown(totalNewConsumedTracking, scannerPwrBrkDown, true);

		// If the current PSU is overloaded...
		pwrExceeded = IsPowerThresholdExceeded(PlatformCLX, totalNewConsumedTracking, supplied, powerThreshold);

		let psuCatNo = (currentPSU ? currentPSU.catNo : largestPSU.catNo);
		if (pwrExceeded) {
			if (currentPSU && currentPSU.catNo === largestPSU.catNo) {
				alert('Unable to find a Power Supply large enough to correct issue.');
				deleteAutoFix(autoFix);
				return false;
			}
			else {
				pwrExceeded = IsPowerThresholdExceeded(PlatformCLX, totalNewConsumedTracking, largestPSU.powerAvail, powerThreshold);
				if (pwrExceeded === false) {
					psuCatNo = largestPSU.catNo;
				}
				else {
					alert('Unable to find a Power Supply large enough to correct issue.');
					deleteAutoFix(autoFix);
					return false;
				}
			}
		}

		/////////////////// CREATE NEW CHASSIS AND MIGRATE MODULES ///////////////
		const migrateSpec = getNewCLXMigrateChassisSpecs(chkrComps, arrIOModulesToMove, targetChassis, project);
		migrateSpec.idxSourceChassis = idxRack;
		migrateSpec.scannerCatalog = scannerCatNo;
		migrateSpec.psuCatalog = psuCatNo;
		[success,] = clxMigrateModulesToNewChassis(migrateSpec);
		if (success === false)
			deleteAutoFix(autoFix);
	}

	return success;
}

