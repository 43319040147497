import { EngInfoCommModule, EngInfoController, EngInfoIOModule, EngInfoModule } from "../../engData/EngineeringInfo";
import { addModuleAtSlot, deleteModuleAtSlot } from "../../implementation/ImplGeneral";
import { convertAppValToGuidedSelAttrOptID } from "../../implementation/ImplHardwareGen";
import {
    addChassis,
    getChassisAndRackIndexById,
    getNetPowerAvailable,
    getPowerBreakdown,
    getProjectFromChassis,
    updateAllChassis,
} from "../../model/ChassisProject";
import { cloneLocAttributeInfo, getLocAttributeSetting } from "../../model/GuidedSelection";
import { getLocationAttrInfo } from "../../model/LocAttributeInfo";
import { collectHardwareInfo, ProdSelCategory } from "../../model/ProductSelection";
import { Chassis, ChassisModule, ChassisProject, DeviceType } from "../../types/ProjectTypes";
import { PSUErrorThreshold, PSUWarningThreshold } from "../../util/Checker";
import { addAutoFix, AFType, AF_Base, getNewAutoFix } from "../../util/CheckerAutoFix";
import { getUniqueChassisName } from "../../util/CheckerAutoFixProjHelpers";
import { getAvailableModules, getCommModuleEngInfo, getControllerEngInfo, getEngInfoForComp } from "../../util/EngInfoHelp";
import { getSAPowerDetails } from "../../util/FieldPowerHelp";
import { isPlatformSnapType } from "../../util/PlatformHelp";
import { calcPowerRatio, subtractPowerBreakdown } from "../../util/PowerHelp";
import { PowerBreakdown } from "../../types/PowerTypes";
import { addLogMessage, getNewProjectLog, LogMsgLevel, StatusLogType } from "../../util/ProjectLog";
import { suspendUndoSnapshots } from "../../util/UndoRedo";
import { getChassisEnvType } from "../common/ChassisConfig";
import { PlatformFlex } from "../PlatformConstants";
import { snapAttachModuleAtSlot, snapCheckFieldPower, snapCompactModuleArray, snapUpdateChassisLayout } from "./SnapGeneralImpl";


export const snapDoGeneralCheck = (chassis: Chassis) => {
    // Note: there is just one check at the chassis level and
    // that is to look at the number of supported modules of
    // the Ctrl/Comm is not exceeded. Applies to 5069/5094.

    const platform = chassis.platform;

    // Get the Comm/Ctrl module and the max modules supported.
    const modCtrlComm = (chassis ? chassis.modules[0] : undefined);
    let maxModsSupported = 0;
    let modZeroType = 'Controller';
    if (modCtrlComm) {
        const infoComm = (modCtrlComm ? getCommModuleEngInfo(platform, modCtrlComm.catNo) : undefined);
        if (infoComm && !infoComm.isController) {
            maxModsSupported = infoComm.maxSnapModules;
            modZeroType = (platform === PlatformFlex ? 'Adapter' : 'Communication Module');
        }
        else {
            const infoCtrl = (modCtrlComm ? getControllerEngInfo(platform, modCtrlComm.catNo) : undefined);
            if (infoCtrl)
                maxModsSupported = infoCtrl.maxSnapModules;
        }
    }

    // If we can...
    if (modCtrlComm && maxModsSupported > 0) {
        // Get an array of non-FPD/Interconnect Cable modules to the right of the comm.
        const arrMods = chassis.modules.filter((mod, idx) => mod && !mod.isFPD && !mod.isInterconnect && idx > 0);
        if (arrMods.length > maxModsSupported) {
            // Create a new log if the chassis does not have one..
            if (chassis.statusLog == null)
                chassis.statusLog = getNewProjectLog(StatusLogType.chassis);

            const af = getNewAutoFix(platform, chassis.id, AFType.Snap_CommHasTooManyModules);
            addAutoFix(af);
            const msg = `The ${modZeroType} in Slot 0 can support up to ${maxModsSupported} modules. You currently have ${arrMods.length} modules in the chassis. ` +
                `Please upgrade your ${modZeroType} to a larger version that supports more modules, or reduce the number of modules in the chassis.`;
            addLogMessage(
                chassis.statusLog,
                msg,
                LogMsgLevel.error,
                chassis,
                af.id);
        }
    }

    snapCheckFieldPower(chassis);
    snapDoAdapterCheck(chassis);
    snapDoInterconnectCheck(chassis);
}

// Check to make sure we have an adapter in Slot 0.
const snapDoAdapterCheck = (chassis: Chassis) => {
    // This check is only for Flex... for now.
    if (chassis.platform !== PlatformFlex)
        return;

    if (chassis.modules[0] == null) {
        if (chassis.statusLog == null)
            chassis.statusLog = getNewProjectLog(StatusLogType.chassis);

        const af = getNewAutoFix(chassis.platform, chassis.id, AFType.NoCommInChassis);
        addAutoFix(af);
        const msg = 'Every chassis must have an Adapter in Slot 0 to properly function. Please add one.';
        addLogMessage(
            chassis.statusLog,
            msg,
            LogMsgLevel.error,
            chassis,
            af.id);
    }
}

// For the Snap Platform, a chassis' MOD
// power, must not exceed power supplied
// by the Slot 0 Module.
export const snapDoChassisPowerCheck = (chassis: Chassis): boolean => {
    if (chassis.statusLog == null)
        chassis.statusLog = getNewProjectLog(StatusLogType.chassis);

    const platform = chassis.platform;

    // Spin through the module and evaluate
    // each Ctrl/Comm/FPD
    const modLen = chassis.modules.length;
    for (let idx = 0; idx < modLen; ++idx) {
        const mod = chassis.modules[idx];
        if (mod == null)
            continue;

        // If the mod is a Power Supplier...
        if (mod.isComm || mod.isController || mod.isFPD) {
            const [supplied, consumed] = getSAPowerDetails(chassis, mod);

            // If we are at Slot 0...
            if (idx === 0) {
                // Check the MOD Power for slot 0
                let thresholdExceeded = 0;
                let afType = AFType.NA;
                let lvl = LogMsgLevel.none;

                const pct = calcPowerRatio(consumed.modPower, supplied.modPower);
               if (pct > PSUErrorThreshold) {
                    thresholdExceeded = PSUErrorThreshold;
                    lvl = LogMsgLevel.error;
                    afType = AFType.ChassisOutOfPower;
                }
                else if (pct > PSUWarningThreshold) {
                    thresholdExceeded = PSUWarningThreshold;
                    lvl = LogMsgLevel.warning;
                    afType = AFType.ChassisLowOnPower;
               }

                if (thresholdExceeded > 0) {
                    const af = getNewAutoFix(platform, chassis.id, afType);
                    addAutoFix(af);

                    const percentage = (thresholdExceeded * 100).toFixed(1);
                    const msg = `The consumed MOD Power (${(consumed.modPower / 1000).toFixed(2)} Amps) in chassis ${chassis.name} ` +
                        `exceeds ${ percentage }% of the supplied power of ${ (supplied.modPower / 1000).toFixed(2) } Amps.`;
                    addLogMessage(
                        chassis.statusLog,
                        msg,
                        lvl,
                        chassis,
                        af.id);
                }
            }

            // We do NOT check SA power for Flex.
            if (platform !== PlatformFlex) {
                // For any module that qualifies
                // as an SA Power Supplier,
                const pct = calcPowerRatio(consumed.saPower, supplied.saPower);
                if (pct > PSUErrorThreshold) {
                    const msg = `The configuration currently exceeds SA power capabilities on ${mod.catNo} in Slot ${mod.slotID}, ` +
                        'however the configuration is using the maximum module value for evaluating SA Power Requirements. ' +
                        'It is recommended you do your own review of SA Power usage to ensure your configuration will function appropriately.'
                    addLogMessage(
                        chassis.statusLog,
                        msg,
                        LogMsgLevel.info,
                        chassis);
                }
            }
        }
    }

    return true;
}

// Select a module to place into an EMPTY Slot 0.
const _getInitialSlot0Module = (chassis: Chassis, selectCtrl: boolean): string => {

    const platform = chassis.platform;

    // Determine if we have any safety I/O in the chassis.
    // Start by saying if we are looking for a Ctrl, then
    // we want safety. Note: Safety I/O not relevant for comms.
    let safetyDesired = selectCtrl;
    if (safetyDesired) {
        // If we have SOME safety I/O, set safetyDesired to true.
        safetyDesired = chassis.modules.some(
            (mod) => {
                // If we have a mod and it's a 'conn client'
                // (meaning it's I/O of some sort)...
                if (mod != null && mod.isConnClient) {
                    // If it's safety I/O...
                    const info = getEngInfoForComp(platform, mod.catNo) as EngInfoIOModule;
                    if (info && info.isSafetyIO)
                        return true;
                }

                return false;
            }
        );
    }

    // First see if we can select a controller/comm
    // through Guided/Product Selection.
    const proj = getProjectFromChassis(chassis);
    if (proj) {
        const loc = getLocationAttrInfo(proj.config.currLocAttrID);
        if (loc) {
            if (loc.platform === platform) {
                // Clone the loc - we do not want to mess with
                // the design page settings.
                const locClone = cloneLocAttributeInfo(loc);
                // Set the ER rating. Start by getting the ER setting.
                const er = getLocAttributeSetting(locClone, 'ER');
                if (er) {
                    // Get the Enum of the chassis' Env. Rating
                    // and convert it to a Guided Sel Option ID.
                    const chassisER = getChassisEnvType(chassis);
                    const locOptionID = convertAppValToGuidedSelAttrOptID(platform, 'ER', chassisER);
                    // If the setting HAS this option (it should)...
                    const selOption = er.options.find(opt => opt.id === locOptionID);
                    if (selOption) {
                        // Set the selected option in the clone and determine
                        // which category (comm/ctrl) we want. 
                        er.selectedOption = selOption;

                        // Start the category as a comm.
                        let category = ProdSelCategory.Comm;
                        // If we are looking for a controller...
                        if (selectCtrl) {
                            category = ProdSelCategory.Controller;
                            // If we desire a safety controller...
                            if (safetyDesired) {
                                // Try to set the Controller Capability 'CC' attribute.
                                const cc = getLocAttributeSetting(locClone, 'CC');
                                if (cc) {
                                    const optID = convertAppValToGuidedSelAttrOptID(platform, 'CC', 'Safety');
                                    const ccOption = cc.options.find(opt => opt.id === optID);
                                    if (ccOption)
                                        cc.selectedOption = ccOption;
                                }
                            }
                        }

                        // Get the comm/ctrl from Product Selection.
                        const arrInfo = collectHardwareInfo(locClone, [], category);
                        if (arrInfo.length > 0)
                            return arrInfo[0].mainCatalog;
                    }
                }
            }
        }
    }

    // Alright - we could NOT get it from Product Selection.
    // Try to get one from Engineering Data.
    const arrayMods = getAvailableModules(chassis);
    const devCategory = (selectCtrl ? DeviceType.Controller : DeviceType.CommModule);
    // Walk the array and collect 'first found' and 'best match'.
    let firstFound: EngInfoModule | undefined = undefined; 
    let bestMatch: EngInfoModule | undefined = undefined; 
    const len = arrayMods.length;
    for (let idx = 0; idx < len; ++idx) {
        const mod = arrayMods[idx];
        if (mod.type === devCategory) {
            if (firstFound == null) {
                firstFound = mod;
            }

            if (devCategory === DeviceType.Controller) {
                const infoCtrl = mod as EngInfoController;
                if (infoCtrl.safetyController === safetyDesired) {
                    bestMatch = infoCtrl;
                    break;
                }
            }
            else {
                // We are looking for a comm. We do not have
                // any other critera than "it's a comm". So,
                // it is our 'best match'...
                bestMatch = mod;
                break;
            }
        }
    }

    if (bestMatch)
        return bestMatch.catNo;
    else if (firstFound)
        return firstFound.catNo;

    return '';
}

// Function will upsize an existing Slot 0
// module, OR add an appropriate module to
// Slot 0 when one does not exist. We are NOT
// creating any new chassis(s) here!
export const snapUpsizeSlot0ToSupportModCount = (chassis: Chassis, numModsToSupport = -1, addController = false): boolean => {
    // Determine the module count we have to support.
    if (numModsToSupport < 0) {
        // Filter out Slot 0 and any FPDs/Interconnect cables.
        const arrMod = chassis.modules.filter((mod, idx) => (mod != null && !mod.isFPD && !mod.isInterconnect && idx > 0));
        numModsToSupport = arrMod.length;
    }

    const platform = chassis.platform;

    // Determine the module in slot 0 (if any).
    let existingSlot0Mod = true;
    let modCtrlCommCat = (chassis.modules.length > 0 ? (chassis.modules[0] ? chassis.modules[0].catNo :'') : '');
    if (!modCtrlCommCat) {
        // We do NOT have a Slot 0 Mod.
        existingSlot0Mod = false;

        // Try to establish an initial Slot 0 mod. We 
        // might have to upsize this initial selection.
        modCtrlCommCat = _getInitialSlot0Module(chassis, addController);

        // If we still do NOT have a module Catalog...
        if (!modCtrlCommCat)
            return false;
    }

    // Get the max modules supported and the 'upsize' catalog
    // for the Slot 0 module.
    let maxModsSupported = 0;
    let upsizeComponent = '';
    const info = getEngInfoForComp(platform, modCtrlCommCat);
    if (info) {
        if (info.isComm) {
            maxModsSupported = (info as EngInfoCommModule).maxSnapModules;
            upsizeComponent = info.altUpsize;
        }
        else if (info.isController) {
            maxModsSupported = (info as EngInfoController).maxSnapModules;
            upsizeComponent = info.altUpsize;
        }
    }

    // Start our replacement as the initial Slot 0 module.
    let compReplacement = modCtrlCommCat;

    // If we have an existing module OR the initial Slot 0 
    // module does NOT support the module count...
    if (existingSlot0Mod || maxModsSupported < numModsToSupport) {
        // Add a count for infinite loop protection - we
        // should not be upsizing more than a 15 times.
        let loopCount = 0;

        // Start our replacement as the 'upsize' component
        // we have for the current slot 0 Ctrl/Comm module.
        compReplacement = upsizeComponent;
        while (maxModsSupported < numModsToSupport) {
            const infoUpSize = getEngInfoForComp(platform, compReplacement);
            if (infoUpSize == null)
                break;
            // Get the 'upsize' max modules supported.
            if (infoUpSize.isController)
                maxModsSupported = (infoUpSize as EngInfoController).maxSnapModules;
            else if (infoUpSize.isComm)
                maxModsSupported = (infoUpSize as EngInfoCommModule).maxSnapModules;

            // If we're still not satisfied, get the upsize
            // component of the current upsize component.
            if (maxModsSupported < numModsToSupport)
                compReplacement = infoUpSize.altUpsize;

            // Infinite loop protection. The data could be biffed.
            loopCount++;
            if (loopCount > 15)
                break;
        }
    }

    // Did we find an 'upsize' component...
    if (maxModsSupported >= numModsToSupport) {
        // If we have an existing Slot 0 Mod...
        if (existingSlot0Mod) {
            // Try do delete it...
            if (deleteModuleAtSlot(chassis, 0) === false)
                return false;
        }

        // Add our replacement.
        return addModuleAtSlot(chassis, compReplacement, 0, true);
    }

    return false;
}


// Helper to get the net MOD Power Available (based
// on the MOD Pwr supplied and used by the Ctrl/Comm)
// and the Ctlr/Comm Upsize catalog if it exists.
const _getSupModPwrAndUpsizeCat = (platform: string, catCtrlComm: string): [modPwrAvail: number, catUpsize: string] => {
    const info = getEngInfoForComp(platform, catCtrlComm);
    if (info) {
        let netPwrAvail: PowerBreakdown | undefined = undefined;

        // If a controller...
        if (info.isController) {
            const asCtlr = info as EngInfoController;
            netPwrAvail = getNetPowerAvailable(asCtlr.powerAvail, asCtlr.powerUsed);
        }
        else if (info.isCommModule) {
            // See if this one has any power available.
            const asCommMod = info as EngInfoCommModule;
            netPwrAvail = getNetPowerAvailable(asCommMod.powerAvail, asCommMod.powerUsed);
        }

        if (netPwrAvail)
            return [netPwrAvail.modPower, info.altUpsize];
    }

    return [-1, ''];
}

// Function will try to upsize the Slot 0
// component first, then migrate modules 
// to a new chassis to correct the power.
export const snapCorrectChassisMODPower = (project: ChassisProject, af: AF_Base): boolean => {
    const [chassis, idxRack] = getChassisAndRackIndexById(project, af.targetInstanceID);
    if (chassis == null || idxRack < 0 || isPlatformSnapType(chassis.platform) === false)
        return false;

    const platform = chassis.platform;
    const modSlot0 = chassis.modules[0];
    const [supplied, consumed] = (modSlot0 ? getSAPowerDetails(chassis, modSlot0) : [undefined, undefined]);
    // We should have a supplied power an a
    // slot 0 component (Ctrl or Comm module).
    if (supplied == null || modSlot0 == null)
        return false;

    // Determine the supplied value we want.
    const desiredSupplied = consumed.modPower / PSUWarningThreshold;

    // Try the Upsize route - not sure this will
    // apply to power like it does for controller
    // capability, but try...
    let loopCount = 0;
    let [modPwrAvail, catUpsize] = _getSupModPwrAndUpsizeCat(platform, modSlot0.catNo);
    while (catUpsize && modPwrAvail < desiredSupplied) {
        [modPwrAvail, catUpsize] = _getSupModPwrAndUpsizeCat(platform, catUpsize);

        // Infinite loop protection.
        loopCount++;
        if (loopCount > 15)
            break; 
    }

    // Did we find an upsize?
    if (modPwrAvail >= desiredSupplied) {
        if (deleteModuleAtSlot(chassis, 0)) {
            // Add our replacement.
            return addModuleAtSlot(chassis, catUpsize, 0, true);
        }
    }

    // We could not upsize to satisfy our power
    // needs. Determine how many modules we need
    // to remove from the END of the chassis to
    // correct the power in the chassis. Reverse
    // iterate (ritr) the module array.
    let modsToMigrate = 0;
    const len = chassis.modules.length;
    for (let ritr = len - 1; ritr > 1; --ritr) {
        // Note: we do not care if we are removing
        // or migrating FPDs - they will be sorted 
        // out after they are migrated.
        const mod = chassis.modules[ritr];
        if (mod) {
            const pb = getPowerBreakdown(platform, mod.catNo);
            subtractPowerBreakdown(consumed, pb, true);
            const pctUsed = calcPowerRatio(consumed.modPower, supplied.modPower);
            if (pctUsed <= PSUWarningThreshold) {
                modsToMigrate = len - ritr;
                break;
            }
        }
    }

    if (modsToMigrate > 0) {
        // Migrate the modules INCLUDING FPDs
        // (4th param set to true) from the
        // tail of the chassis to a new chassis.
        return snapMigrateModulesToNewChassis(chassis, idxRack, modsToMigrate, true);
    }

    return false;
}


// This function is called only when we have
// already failed to upsize the Controller/Comm.
export const snapMigrateModulesToMaxSupported = (chassis: Chassis, idxRack: number) => {
    const platform = chassis.platform;

    // We should ALWAYS have a controller or comm in slot 0.
    const modCtrlComm = chassis.modules[0];

    // Get the max modules supported and the 'upsize' catalog.
    let maxModsSupported = 0;

    // Assume we have more modules than the slot 0
    // module can handle.
    const info = (modCtrlComm ? getEngInfoForComp(platform, modCtrlComm.catNo) : undefined);
    if (info) {
        if (info.isComm) {
            maxModsSupported = (info as EngInfoCommModule).maxSnapModules;
        }
        else if (info.isController) {
            maxModsSupported = (info as EngInfoController).maxSnapModules;
        }
    }

    if (maxModsSupported < 4) {
        alert('Unable to correct issue.');
        return false;
    }

    const arrMods = chassis.modules.filter((mod, idx) => mod && !mod.isFPD && !mod.isInterconnect && idx > 0);
    const nChassisMods = arrMods.length;

    // We have to migrate modules to a new chassis.
    // Collect an array of modules to migrate.
    const nModsToMigrate = nChassisMods - maxModsSupported;
    return snapMigrateModulesToNewChassis(chassis, idxRack, nModsToMigrate, false);
}


// snapMigrateModulesToNewChassis(): Pass in chassis, rack index,
// and number of modules to migrate, which is affected by the
// migrateFPDs flag. When migrateFPDs is true, FPDs will be
// included as part of numModsToMigrate. When migrateFPDs is
// false, we will migrate numModsToMigrate that are NOT FPDs.
export const snapMigrateModulesToNewChassis = (chassis: Chassis, idxRack: number, numModsToMigrate: number, migrateFPDs: boolean ): boolean => {
    const platform = chassis.platform;
    const project = getProjectFromChassis(chassis);
    const modCtrlComm = chassis.modules[0];

   // Check that we have EVERYTHING and validate
    if (project == null ||
        modCtrlComm == null ||
        idxRack < 0) {
        alert('Unable to correct issue.');
        return false;
    }

    const chassisModLen = chassis.modules.length;
    let nModsLeftToMigrate = numModsToMigrate;
    let idxLastModRemoved = chassisModLen - 1;
    const arrModsToMigrate: ChassisModule[] = [];

    // Migrate modules from the end of the chassis.
    // Reverse iterate (ritr) the module array.
    for (let ritr = chassisModLen - 1; ritr > 1; --ritr) {
        const mod = chassis.modules[ritr];
        if (mod) {
            let migrateModule = !mod.isInterconnect;
            if (migrateModule) {
                if (mod.isFPD) 
                    migrateModule = migrateFPDs;
            }
               
            if (migrateModule) {
                nModsLeftToMigrate--;
                arrModsToMigrate.push(mod);
            }

            idxLastModRemoved = ritr;

            // Have we removed enough?
            if (nModsLeftToMigrate === 0)
                break;
        }
    }

    // Create the new chassis.
    const newChassis = addChassis(project, platform, chassis.catNo, idxRack + 1);
    if (newChassis == null) {
        alert('Unable to correct issue.');
        return false;
    }

    // Name the new chassis.
    newChassis.name = getUniqueChassisName(`Split_${chassis.name}`, project);

    // Cleanup the source chassis. Trim the module
    // array to index of lst module removed.
    chassis.modules.length = idxLastModRemoved;
    snapUpdateChassisLayout(chassis);

    const snapshotsWereSuspended = suspendUndoSnapshots(true);

    // Add the Comm/Ctrl.
    addModuleAtSlot(newChassis, modCtrlComm.catNo, 0, true);
    // Add the migrated modules.
    arrModsToMigrate.forEach((mod) => {
        snapAttachModuleAtSlot(newChassis, mod, newChassis.modules.length, true);
    });

    suspendUndoSnapshots(snapshotsWereSuspended);

    // Cleanup the new chassis and update the layout.
    snapCompactModuleArray(newChassis);
    updateAllChassis(project.content);

    return true;
}

const snapDoInterconnectCheck = (chassis: Chassis) => {
    // 5094 Flex 5000 specific check.
    if (chassis.platform !== PlatformFlex)
        return;

    // Check if we have more than one interconnect cable.
    let icCount = 0;
    let icIndex = 0;
    chassis.modules.forEach((mod, idx) => {
        if (mod && mod.isInterconnect) {
            icIndex = idx;
            icCount++;
        }
    });

    if (icCount === 0)
        return;

    if (icCount > 1) {
        if (!chassis.statusLog)
            chassis.statusLog = getNewProjectLog(StatusLogType.chassis);

        const af = getNewAutoFix(chassis.platform, chassis.id, AFType.BankingError);
        addAutoFix(af);

        const msg = `The FLEX 5000 platform supports one Interconnect Cable. Your layout currently has ${icCount} Interconnect Cables. ` +
            'Please reduce the number of Interconnect Cables one.';

        addLogMessage(
            chassis.statusLog,
            msg,
            LogMsgLevel.warning,
            chassis,
            af.id);
    }

    // Check to see if the Interconnect Cable
    // is at the end of the chassis.
    if (icIndex >= chassis.modules.length - 1) {
        if (!chassis.statusLog)
            chassis.statusLog = getNewProjectLog(StatusLogType.chassis);

        const msg = 'Your layout currently has no I/O modules following an interconnect cable. ' +
            'Please add modules to your 2nd bank, remove the cable, or move the cable to a more ' +
            'appropriate location between modules.';

        addLogMessage(
            chassis.statusLog,
            msg,
            LogMsgLevel.warning,
            chassis);
    }
}
