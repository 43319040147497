import { SettingCode } from "../types/SettingsTypes";
import {
    Chassis,
    ChassisProject,
    HardwareBuilder,
    ChassisModule,
} from "../types/ProjectTypes";
import {
    addLogMessage,
    getNewProjectLog,
    LogMsgLevel,
    mergeLog,
    StatusLogType,
} from "./ProjectLog";
import { getChassisPowerLogMessages } from "./PowerHelp";
import { sanitizeNameString } from "./NameHelp";
import { getLocAttrFromProject, getProjectFromChassis } from "../model/ChassisProject";
import {
    addAutoFix,
    AFType,
    clearAllAutoFixData,
    getNewAutoFix,
} from "./CheckerAutoFix";
import { EngInfoModule } from "../engData/EngineeringInfo";
import {
    doChassisPowerCheck,
    doGeneralCheck,
} from "../implementation/ImplChecker";
import { getPowerDetails } from "../implementation/ImplGeneral";


// Note: Thresholds are checked by Greater Than '>'.
// The 0.9999999 Error Threshold equates to >= 100%.
export const PSUErrorThreshold = 0.9999999999999;
export const PSUWarningThreshold = 0.9;

// General Checker Interface(s)
export interface chkrModule {
    module: ChassisModule;
    engInfo: EngInfoModule;
    arrIndex: number;
}

export interface CheckerListener {
    fnListener: () => void;
    removeAfterCall: boolean;
}

const _arrCheckerListeners: CheckerListener[] = [];
const _notifyListeners = () => {
    let idxToRemove: number[] = [];
    _arrCheckerListeners.forEach((listener, idx) => {
        listener.fnListener();
        if (listener.removeAfterCall) idxToRemove.push(idx);
    });

    // Reverse the index order from low to high
    // to high to low.
    idxToRemove = idxToRemove.reverse();
    idxToRemove.forEach((idx) => {
        _arrCheckerListeners.splice(idx, 1);
    });
};

export const addCheckerListener = (
    listener: () => void,
    removeAfterCall = true
) => {
    if (_arrCheckerListeners.some((x) => x.fnListener === listener) === false)
        _arrCheckerListeners.push({
            fnListener: listener,
            removeAfterCall: removeAfterCall,
        });
};

export const removeCheckerListener = (listener: () => void) => {
    const idx = _arrCheckerListeners.findIndex(
        (chkrLis) => chkrLis.fnListener === listener
    );
    if (idx >= 0) _arrCheckerListeners.splice(idx, 1);
};

export const runChecker = (project: ChassisProject) => {
    // Clear any logged messages. For now clear everything out.
    project.content.statusLog = getNewProjectLog(StatusLogType.project);
    clearAllAutoFixData();

    project.content.racks.forEach((rack) => {
        const chassis = rack.chassis;

        // 2023.9.6 Future: When we are able to isolate changes to
        // specific chassis(s), the chassis' statusLog member will
        // be null, indicating it needs to be checked. For now, we
        // are scanning all chassis(s)... Rest the chassis' log.
        chassis.statusLog = getNewProjectLog(StatusLogType.chassis);
        doGeneralCheck(chassis);
        doChassisPowerCheck(chassis);

        // Merge the chassis log into the Project Log. Note:
        // we are NOT merging the messages. We just want the
        // highest error level and tally level counts.
        mergeLog(project.content.statusLog, chassis.statusLog, false);
    });

    // 2023.12.13 Notify anyone who wants to know
    // a checker run is complete.
    _notifyListeners();
};

// General helper. Allows an 'outside' function to add a
// message (error, warning, etc.) to a chassis status log.
// Note: Calls should ALWAYS be made AFTER runChecker is called
// in order for added message to be retained in the log.
export const addChassisMessage = (
    chassis: Chassis,
    msg: string,
    level: LogMsgLevel,
    idAutoFix = ""
) => {
    if (chassis.statusLog == null)
        chassis.statusLog = getNewProjectLog(StatusLogType.chassis);

    addLogMessage(chassis.statusLog, msg, level, chassis, idAutoFix);

    // Merge the chassis log into the Project Log. Note:
    // we are NOT merging the messages. We just want the
    // highest error level and tally level counts.
    const proj = getProjectFromChassis(chassis);
    if (proj) {
        mergeLog(proj.content.statusLog, chassis.statusLog, false);
    }
};

/////////// Standard Functions - Platform Independent ////////////

// Can be used by platforms, which share common checks, as their
// entry in their PlatformCheckerImplementation Interface.

export const doChassisPowerCheckStandard = (chassis: Chassis): boolean => {
    if (chassis.statusLog == null)
        chassis.statusLog = getNewProjectLog(StatusLogType.chassis);

    if (!chassis.ps) {
        // 2023.12.8 Add a FixMe
        const af = getNewAutoFix(
            chassis.platform,
            chassis.id,
            AFType.ChassisOutOfPower
        );
        addAutoFix(af);

        addLogMessage(
            chassis.statusLog,
            `${chassis.name} does not have a Power Supply.`,
            LogMsgLevel.error,
            chassis,
            af.id
        );
        return false;
    }

    let success = true;
    const [supplied, consumed] = getPowerDetails(chassis);

    // 2024.3.11 Part of ITISAB 482 that was NOT completed.
    // Get an array of power messages. If we have any...
    const arrMessages = getChassisPowerLogMessages(consumed, supplied, chassis);
    if (arrMessages) {
        let afPwrErrID = "";
        let afPwrWarnID = "";

        // Note - using 'for loop' since 'forEach' loses the
        // check we did on chassis.statusLog being defined.
        const arrLen = arrMessages.length;
        for (let idx = 0; idx < arrLen; ++idx) {
            const msg = arrMessages[idx];

            let afIDToUse: string | undefined = undefined;

            // Depending on the message type, add an auto fix. Note:
            // if a chassis has mixed LowOnPower and OutOfPower
            // messages, when a user auto fixes a LowOnPower, the
            // OutOfPower issues will be corrected as well. However,
            // the OutOfPower auto fix will NOT correct the LowOnPower
            // issues and may create new LowOnPower messages.

            switch (msg.status) {
                case LogMsgLevel.error:
                    {
                        if (!afPwrErrID) {
                            const afPwrErr = getNewAutoFix(
                                chassis.platform,
                                chassis.id,
                                AFType.ChassisOutOfPower
                            );
                            addAutoFix(afPwrErr);
                            afPwrErrID = afPwrErr.id;
                        }
                        afIDToUse = afPwrErrID;
                        success = false;
                    }
                    break;
                case LogMsgLevel.warning:
                    {
                        if (!afPwrWarnID) {
                            const afPwrWarn = getNewAutoFix(
                                chassis.platform,
                                chassis.id,
                                AFType.ChassisLowOnPower
                            );
                            addAutoFix(afPwrWarn);
                            afPwrWarnID = afPwrWarn.id;
                        }
                        afIDToUse = afPwrWarnID;
                    }
                    break;
            }
            // Add the message
            addLogMessage(
                chassis.statusLog,
                msg.message,
                msg.status,
                chassis,
                afIDToUse
            );
        }
    }

    return success;
};

export const getChkrCompTxt = (
    mod: chkrModule,
    chassis: Chassis | null = null
): string => {
    let segment = `${mod.module.catNo} in slot ${mod.module.slotIdx}`;
    if (chassis != null) segment += ` of chassis '${chassis.name}'`;

    return segment;
};

const isPointEntrySectionValid = (project: ChassisProject): boolean => {
    // Get the point entry section and run
    // the entries. If any entry is invalid
    // AND HAS POINTS, the section is invalid
    const locAttr = getLocAttrFromProject(project);
    const ptEntrySect = locAttr.pointEntrySection;
    const invalidFound = ptEntrySect.entries.some(x => x.invalidEntry === true && x.points > 0);
    return (invalidFound === false);
}

export const checkProjectConfigSettings = (project: ChassisProject, hardwareBldr: HardwareBuilder | undefined = undefined): string[] => {
	const errors: string[] = [];
	// So far we have 1 potential error, which is an empty
	// project config name.
	// Do a final sanitize on it...
	if (project.config.projectName) {
		project.config.projectName =
			sanitizeNameString(project.config.projectName, true, true);
	}
	
	if (!project.config.projectName) {
		const errMessage = 'Configuration Name is required.';
		errors.push(errMessage);
		if (hardwareBldr)
			hardwareBldr.mapErrMessages.set(SettingCode.ConfigName, errMessage);
	}

	// If Industry or Install Location is blank, log an error.
	if (!project.config.industryID) {
		const errMessage = 'An Industry selection is required.';
		errors.push(errMessage);
		if (hardwareBldr)
			hardwareBldr.mapErrMessages.set(SettingCode.Industry, errMessage);
	}

	if (!project.config.installLocID) {
		const errMessage = 'A Location selection is required.';
		errors.push(errMessage);
		if (hardwareBldr)
			hardwareBldr.mapErrMessages.set(SettingCode.Location, errMessage);
    }

    // Check the point entries for invalid entries.
    if (!isPointEntrySectionValid(project)) {
        const errMessage = 'One or more of your I/O Types is not a valid option based upon your ' +
            'Configuration requirements. Please choose a different I/O Type or remove the row.';
        errors.push(errMessage);
        if (hardwareBldr)
            hardwareBldr.mapErrMessages.set(SettingCode.PointEntry, errMessage);
    }

	return errors;
}