import { StatusLevel } from '../types/MessageTypes';
import { LocAndSize, NormRect, Point, Size } from '../types/SizeAndPosTypes';
import { logger } from './Logger';
import {
    ChassisPowerSupply,ChassisBaseUnit
} from "../types/ProjectTypes";

export const getNormRect = (x: number, y: number, width: number, height: number) => {
    const r: NormRect = {
        left: x,
        top: y,
        right: x + width,
        bottom: y + height
    };

    if (r.left > r.right) {
        const tmp = r.left;
        r.left = r.right;
        r.right = tmp;
    }

    if (r.top > r.bottom) {
        const tmp = r.top;
        r.top = r.bottom;
        r.bottom = tmp;
    }

    return r;
}

export const normRectToLoc = (rct: NormRect): LocAndSize => {
    return {
        x: rct.left,
        y: rct.top,
        width: rct.right - rct.left,
        height: rct.bottom - rct.top
    };
}

export const locToNormRect = (loc: LocAndSize): NormRect => {
    return {
        left: loc.x,
        top: loc.y,
        right: loc.x + loc.width,
        bottom: loc.y + loc.height
    };
}

export const getRectUnion = (r1: NormRect, r2: NormRect): NormRect => {
    return {
        left: Math.min(r1.left, r2.left),
        top: Math.min(r1.top, r2.top),
        right: Math.max(r1.right, r2.right),
        bottom: Math.max(r1.bottom, r2.bottom)
    };
}

export const getLocUnion = (loc1: LocAndSize, loc2: LocAndSize): LocAndSize => {

    const rect1 = locToNormRect(loc1);
    const rect2 = locToNormRect(loc2);
    const rectUnion = getRectUnion(rect1, rect2);
    return normRectToLoc(rectUnion);
}

export const getRectIntersection = (r1: NormRect, r2: NormRect):
    [intersects: boolean, rInt: NormRect] => {

    if ((r1.left > r2.right) || (r2.left > r1.right) ||
        (r1.top > r2.bottom) || (r2.top > r1.bottom)) {
        return [false, getNormRect(0, 0, 0, 0)];
    }
    else {
        const intRect: NormRect = {
            left: Math.max(r1.left, r2.left),
            top: Math.max(r1.top, r2.top),
            right: Math.min(r1.right, r2.right),
            bottom: Math.min(r1.bottom, r2.bottom)
            }
        return [true, intRect];
    }
}

export const getRectArea = (r: NormRect): number => {
    return ((r.right - r.left) * (r.bottom - r.top));
}

export const pointInRect = (pt: Point, r: NormRect): boolean => {
    if ((pt.x >= r.left) && (pt.x <= r.right)) {
        if ((pt.y >= r.top) && (pt.y <= r.bottom)) {
            return true;
        }
    }
    return false;
}

export const rectInRect = (r1: NormRect, r2: NormRect): boolean => {
    return ((r1.left >= r2.left) &&
        (r1.right <= r2.right) &&
        (r1.top >= r2.top) &&
        (r1.bottom <= r2.bottom));
}

export const getPointBetween = (pt1: Point, pt2: Point): Point => {
    return {
        x: (pt1.x + pt2.x) / 2,
        y: (pt1.y + pt2.y) / 2
    }
}

export const getDistanceBetween = (x1: number, y1: number, x2: number, y2: number): number => {
    const dx = x2 - x1;
    const dy = y2 - y1;
    const dist = Math.sqrt((dx * dx) + (dy * dy));
    return dist;
}

const _minMoveDelta = 5;

export const movedEnough = (pt: Point, ptLast: Point): boolean => {
    if (Math.abs(pt.x - ptLast.x) > _minMoveDelta) {
        return true;
    }
    else if (Math.abs(pt.y - ptLast.y) > _minMoveDelta) {
        return true;
    }
    return false;
}

export const movedEnoughVertically = (y: number, yLast: number): boolean => {
    if (Math.abs(y - yLast) > _minMoveDelta) {
        return true;
    }
    return false;
}

export const getOffset = (pt1: Point, pt2: Point): Point => {
    return {
        x: pt2.x - pt1.x,
        y: pt2.y - pt1.y
    };
}

export const isZeroOffset = (offset: Point): boolean => {
    return ((offset.x === 0) && (offset.y === 0));
}

export const isZeroPoint = (pt: Point): boolean => {
    return ((pt.x === 0) && (pt.y === 0));
}

export const getLocBtmRight = (loc: LocAndSize): Point => {
    return { x: loc.x + loc.width, y: loc.y + loc.height };
}

export const getEmptyPt = (): Point => {
    return {
        x: 0,
        y: 0
    };
}

export const roundPoint = (pt: Point) => {
    pt.x = Math.round(pt.x);
    pt.y = Math.round(pt.y);
}

export const logPoint = (pt: Point, desc: string, round = true) => {
    const x = round ? Math.round(pt.x) : pt.x;
    const y = round ? Math.round(pt.y) : pt.y;
    logger.logCustom('logPt - ' + desc + ': ' + x + ', ' + y);
}

export const logSize = (sz: Size, desc: string, round = true) => {
    const width = round ? Math.round(sz.width) : sz.width;
    const height = round ? Math.round(sz.height) : sz.height;
    logger.logCustom(desc + ': ' + width + ' x ' + height);
}

export const getMaxPt = (pt1: Point, pt2: Point): Point => {
    return {
        x: Math.max(pt1.x, pt2.x),
        y: Math.max(pt1.y, pt2.y)
    };
}

export const samePoints = (pt1: Point, pt2: Point): boolean => {
    return ((pt1.x === pt2.x) && (pt1.y === pt2.y));
}

export const sameSizes = (size1: Size, size2: Size): boolean => {
    return ((size1.width === size2.width) && (size1.height === size2.height));
}

export const getEmptyLoc = (): LocAndSize => {
    return {
        x: 0,
        y: 0,
        width: 0,
        height: 0
    };
}

// We'll consider a LocAndSize to be 'empty' if it has no
// positive width or height, regardless of its x and y.
export const isEmptyLoc = (loc: LocAndSize): boolean => {
    return ((loc.width <= 0) || (loc.height <= 0));
}

export const getLocCenter = (loc: LocAndSize): Point => {
    return {
        x: loc.x + (loc.width / 2),
        y: loc.y + (loc.height / 2)
    };
}

export const getLocRight = (loc: LocAndSize): number => {
    return (loc.x + loc.width);
}

const _getCheckedPct = (pct: number): number => {
    return Math.min(Math.max(pct, 0), 1);
}

export const getRelPtInLoc = (loc: LocAndSize, xPct: number, yPct: number): Point => {
    const xFact = _getCheckedPct(xPct);
    const yFact = _getCheckedPct(yPct);
    return {
        x: loc.x + (loc.width * xFact),
        y: loc.y + (loc.height * yFact)
    };
}

export const getLocCenteredAt = (pt: Point, width: number, height: number) => {
    return {
        x: pt.x - (width / 2),
        y: pt.y - (height / 2),
        width: width,
        height: height
    };
}

export const isPointInLoc = (pt: Point, loc: LocAndSize): boolean => {
    if ((pt.x >= loc.x) && (pt.y >= loc.y)) {
        const locLR = getLocBtmRight(loc);
        if ((pt.x <= locLR.x) && (pt.y <= locLR.y)) {
            return true;
        }
    }
    return false;
}

//changes of point based on base Unit
export const isPointInLocBu= (pt: Point, loc: LocAndSize, bu: ChassisBaseUnit, ps?:ChassisPowerSupply): boolean => {
    let  totalX = ps?.loc?.width ? ps?.loc?.width : 0;
    totalX = totalX + bu.initialWidth;
    if ((pt.x > loc.x) && (pt.y >= loc.y)) {
        const locLR = getLocBtmRight(loc);
        if ((pt.x <= totalX) && (pt.y <= locLR.y)) {
            return true;
        }
    }
    return false;
}

export const offsetLoc = (loc: LocAndSize, offset: Point) => {
    loc.x += offset.x;
    loc.y += offset.y;
}

export const getOffsetPoint = (pt: Point, offset: Point): Point => {
    return {
        x: pt.x + offset.x,
        y: pt.y + offset.y
    };
}

export const getOffsetPtArray = (pts: Point[], offset: Point): Point[] => {
    const offsetPts = new Array<Point>();
    pts.forEach(pt => {
        offsetPts.push(getOffsetPoint(pt, offset));
    });
    return offsetPts;
}

export const getNumValueFromStr = (s: string | undefined): number => {
    if (s) {
        return Number(s);
    }
    else {
        return 0;
    }
}

// Get the number of set bits in a int32 bitset
export const bitCount32 = (n: number): number => {
    n = n - ((n >> 1) & 0x55555555);
    n = (n & 0x33333333) + ((n >> 2) & 0x33333333);
    return ((n + (n >> 4) & 0xF0F0F0F) * 0x1010101) >> 24;
}


export const makeBold = (text: string): string => {
    return '<b>' + text + '</b>';
}

export const isSizeEqual = (a: Size, b: Size): boolean => {
    return (a.height === b.height && a.width === b.width);
}

export const setSizeEqual = (target: Size, source: Size) => {
    target.height = source.height;
    target.width = source.width;
}

export const getEmptySize = (): Size => {
    return {
        width: 0,
        height: 0
    }
}

export const isSizeEmpty = (s: Size, eitherDim = false): boolean => {
    const noWidth = (s.width <= 0);
    const noHeight = (s.height <= 0);
    return eitherDim ? (noWidth || noHeight) : (noWidth && noHeight);
}

export const scaleSize = (s: Size, scale: number, roundRslt = false): Size => {
    const scaledSize: Size = {
        width: s.width * scale,
        height: s.height * scale
    }
    if (roundRslt) {
        return {
            width: Math.round(scaledSize.width),
            height: Math.round(scaledSize.height)
        }
    }
    return scaledSize;
}

export const getScaledLoc = (loc: LocAndSize, scale: number) => {
    return {
        x: Math.round(loc.x * scale),
        y: Math.round(loc.y * scale),
        width: Math.round(loc.width * scale),
        height: Math.round(loc.height * scale)
    }
}

export const getInflatedLoc = (loc: LocAndSize, amount: number, keepCentered: boolean): LocAndSize => {
    // Increase/Decrease the size.
    // Are keeping the center point...
    const offset = keepCentered ? Math.round(amount / 2) : 0;
    return {
        x: loc.x - offset,
        y: loc.y - offset,
        width: loc.width + amount,
        height: loc.height + amount
    };
}

export const getValueToPrec = (val: number, digits: number): number => {
    const strVal = val.toFixed(digits);
    return Number(strVal);
}

    //NA = 'NA',
    //Info = 'Info',
    //Warning = 'Warning',
    //Error = 'Error'


const _getStatIdx = (status: StatusLevel): number => {
    switch (status) {
        case StatusLevel.Info:
            return 1;

        case StatusLevel.Warning:
            return 2;

        case StatusLevel.Error:
            return 3;

        default:
            return 0;
    }
}

export const isNewStatusWorse = (newStat: StatusLevel, oldStat: StatusLevel): boolean => {
    const newAsIdx = _getStatIdx(newStat);
    const oldAsIdx = _getStatIdx(oldStat);
    return (newAsIdx > oldAsIdx);
}

export const getImgNameFromPath = (fullPath: string): string => {
    const lastSlash = fullPath.lastIndexOf('/');
    if (lastSlash >= 0) {
        return fullPath.substring(lastSlash + 1);
    }
    return fullPath;
}
