import { addModuleAtSlot, deleteModuleAtSlot, updateChassis } from "../../../implementation/ImplGeneral";
import { addChassis, chassisChanged, getChassisAndRackIndexById, getPowerBreakdown, updateAllChassis, updateBanks } from "../../../model/ChassisProject";
import { ChassisModule, ChassisProject, FlexHAChassis, FlexHASAPowerSupply } from "../../../types/ProjectTypes";
import { PSUWarningThreshold } from "../../../util/Checker";
import { AFType, AF_Base, deleteAutoFix } from "../../../util/CheckerAutoFix";
import { getUniqueChassisName } from "../../../util/CheckerAutoFixProjHelpers";
import { calcPowerRatio, subtractPowerBreakdown } from "../../../util/PowerHelp";
import { isServerEnvProd } from "../../../util/ServerEnvironment";
import { contentChanging, suspendUndoSnapshots } from "../../../util/UndoRedo";
import { getAsFlexHAChassis } from "./FlexHAChassis";
import { flexHAGetMODPowerBreakdown, flexHAGetSAPowerBreakdown } from "./FlexHAChecker";
import { flexHAAddRequiredSAPower, flexHASAPwrSortPredicate } from "./FlexHAGeneralImpl";
import { flexHACreatePowerSupply, flexHA_DefAdapter, flexHA_DefSAPsu } from "./FlexHAHardwareImpl";


export const flexHAExecuteAutoFix = (af: AF_Base, project: ChassisProject, callback: () => void): boolean => {
    let success = false;
    switch (af.type) {
        case AFType.NoCommInChassis:
            success = _addCommToChassis(af, project);
            break;

        case AFType.BankingError:
            success = _fixBankingError(af, project);
            break;

       case AFType.SAPwr_PSUMissing: // SA Power
            success = _addRequiredSAPSUs(af, project);
            break;

        case AFType.SAPwr_Issue: // SA Power
            success = _autoFixSAPwrIssues(af, project);
            break;

        case AFType.ChassisLowOnPower: // MOD Power
        case AFType.ChassisOutOfPower: // MOD Power
            success = _fixModPwrIssues(af, project);
            break;

        default:
            // If we are NOT Production...
            if (!isServerEnvProd())
                window.alert('AutoFix not yet implemented: ' + af.type);
            break;
    }

    if (success)
        callback();

    return success;
}

const _addRequiredSAPSUs = (af: AF_Base, project: ChassisProject): boolean => {
    // Call our helper to get the chassis and rack index
    const [targetChassis, idxRack] = getChassisAndRackIndexById(project, af.targetInstanceID);
    const fhaChassis = getAsFlexHAChassis(targetChassis);
    if (fhaChassis == null || idxRack < 0) {
        alert('Unable to correct issue.');
        return false;
    }

    return flexHAAddRequiredSAPower(fhaChassis, project);
}


const _autoFixSAPwrIssues = (af: AF_Base, project: ChassisProject): boolean => {
    // Call our helper to get the chassis and rack index
    const [targetChassis, idxRack] = getChassisAndRackIndexById(project, af.targetInstanceID);
    const fhaChassis = getAsFlexHAChassis(targetChassis);
    if (fhaChassis == null || idxRack < 0) {
        alert('Unable to correct issue.');
        return false;
    }

    // Pass in the project to have chassisChanged()
    // and updateAllChassis() called.
    return flexHAFixSAPwrIssues(fhaChassis, project);
}

export const flexHAFixSAPwrIssues = (chassis: FlexHAChassis, projectIfUpdateNeeded?: ChassisProject): boolean => {
    // Call our helper to get the chassis and rack index
    const platform = chassis.platform;

    // Let the length be checked each loop. The
    // array will get larger as we correct issues.
    for (let idx = 0; idx < chassis.saPSU.length; ++idx) {
        const psu = chassis.saPSU[idx];
        // Note: idxSlotEnd is included in the range and not 1 slot beyond.
        const [supl, cons, idxSlotStart, idxSlotEnd] = flexHAGetSAPowerBreakdown(psu, chassis);
        const pct = calcPowerRatio(cons.saPower, supl.saPower);
        if (pct > PSUWarningThreshold) {
            const maxPwr = supl.saPower * PSUWarningThreshold;
            let consSA = 0;
            for (let idxMod = idxSlotStart; idxMod <= idxSlotEnd; ++idxMod) {
                const mod = chassis.modules[idxMod];
                if (mod && !mod.isPlaceholder && !mod.slotFiller) {
                    consSA += getPowerBreakdown(platform, mod.catNo).saPower;
                    if (consSA > maxPwr) {
                        // We need to get the index of the
                        // I/O Base that the module is on.
                        const idxTargetBase = Math.floor(idxMod / 4);

                        // If we already have an SA PSU on this base OR
                        // we have a negative number....
                        if (idxTargetBase < 0 || chassis.saPSU.some(x => x.ioBaseIndex === idxTargetBase)) {
                            const startSlot = (idxTargetBase * 4) + 1;
                            const endSlot = startSlot + 3;
                            alert(`Unable to correct issue. The modules in slots ${startSlot}-${endSlot} consume more SA Power than can be supplied.`);
                            return false;
                        }

                        // Add a PSU to the target I/O Base (so to speak).
                        const saPSU = flexHACreatePowerSupply(flexHA_DefSAPsu) as FlexHASAPowerSupply;
                        if (saPSU) {
                            saPSU.ioBaseIndex = idxTargetBase;
                            saPSU.parent = chassis;
                            chassis.saPSU.push(saPSU);
                        }
                        else {
                            alert('Unable to correct issue. Failed to create SA PSU!');
                            return false;
                        }

                        // Sort the psu array. The new psu should be the 
                        // next psu in the array and will be checked next.
                        chassis.saPSU.sort(flexHASAPwrSortPredicate);
                        break;
                    }
                }
            }
        }
    }

    if (projectIfUpdateNeeded) {
        chassisChanged(chassis);
        updateAllChassis(projectIfUpdateNeeded.content);
    }

    return true;
}

const _fixModPwrIssues = (af: AF_Base, project: ChassisProject): boolean => {
    const [targetChassis, idxRack] = getChassisAndRackIndexById(project, af.targetInstanceID);
    const fhaChassis = getAsFlexHAChassis(targetChassis);
    if (fhaChassis == null || idxRack < 0) {
        alert('Unable to correct issue.');
        deleteAutoFix(af);
        return false;
    }

    
    // We should always have a PS... Get the
    // Mod Power breakdown. If the percentage
    // used exceeds the WARNING Threshold....
    const [srcSupl, srcCons] = flexHAGetMODPowerBreakdown(fhaChassis);
    const pct = calcPowerRatio(srcCons.modPower, srcSupl.modPower);
    if (pct > PSUWarningThreshold) {
        let satisfied = false;
        const modulesToMove: {slot: number, mod:ChassisModule}[] = [];
        const maxPwr = srcSupl.modPower * PSUWarningThreshold;
        const lenModArr = fhaChassis.modules.length;
        for (let ritr = lenModArr - 1; ritr > 1; --ritr) {
            const mod = fhaChassis.modules[ritr];
            if (mod && !mod.slotFiller && !mod.isPlaceholder) {
                modulesToMove.push({ slot: ritr, mod });

                const pwr = getPowerBreakdown(fhaChassis.platform, mod.catNo);
                subtractPowerBreakdown(srcCons, pwr, true);
                if (srcCons.modPower <= maxPwr) {
                    satisfied = true;
                    break;
                }
            }
        }

        if (!satisfied) {
            alert('Unable to correct issue.');
            deleteAutoFix(af);
            return false;
        }

        // Take a snapshot for undo/redo
        contentChanging(project.content);

        const newChassis = addChassis(project, fhaChassis.platform, fhaChassis.catNo, idxRack + 1, fhaChassis.ps?.catNo);
        if (newChassis == null) {
            alert('Unable to correct issue. New 5015 chassis could not be created.');
            deleteAutoFix(af);
            return false;
        }

        // Find a new chassis name based on the source chassis.
        newChassis.name = getUniqueChassisName(`Split_${fhaChassis.name}`, project);

        const wasSuspended = suspendUndoSnapshots(true);

        // Add the adapter to slot 0.
        const adapter = fhaChassis.modules[0];
        if (adapter)
            addModuleAtSlot(newChassis, adapter.catNo, 0);

        // Reverse the order of the modules to move
        // and run the array.
        modulesToMove.reverse();
        let insert = 1;
        for (let idx = 0; idx < modulesToMove.length; ++idx) {
            const info = modulesToMove[idx];
            const slots = info.mod.slotsUsed;

            // Delete the module from the old chassis.
            deleteModuleAtSlot(fhaChassis, info.slot);
            // Add the module to the new chassis.
            addModuleAtSlot(newChassis, info.mod.catNo, insert);
            insert += slots;
        }

        suspendUndoSnapshots(wasSuspended);

        // Call chassisChanged() to ensure proper rendering.
        chassisChanged(fhaChassis);

        updateAllChassis(project.content);

        return true;
    }

    // There was not an issue(?)
    deleteAutoFix(af);
    return false;
}

const _addCommToChassis = (af: AF_Base, project: ChassisProject): boolean => {
    const [targetChassis,] = getChassisAndRackIndexById(project, af.targetInstanceID);
    const fhaChassis = getAsFlexHAChassis(targetChassis);
    if (fhaChassis == null) {
        alert('Unable to correct issue.');
        deleteAutoFix(af);
        return false;
    }

    const adapter = fhaChassis.modules[0];
    if (adapter == null) {
        addModuleAtSlot(fhaChassis, flexHA_DefAdapter, 0);
        return true;
    }

    // We have an adapter(?)
    deleteAutoFix(af);
    return false;
}

const _fixBankingError = (af: AF_Base, project: ChassisProject): boolean => {
    const [targetChassis,] = getChassisAndRackIndexById(project, af.targetInstanceID);
    const chassis = getAsFlexHAChassis(targetChassis);
    if (!chassis || !chassis.auxBanks || !chassis.primaryBankInfo) {
        alert('Unable to correct banking issue.');
        deleteAutoFix(af);
        return false;
    }

    // Since this error SHOULD NOT HAPPEN, we
    // are going to do the simplest correction
    // by removing banks.

    let somethingChanged = false;
    const lenModArr = chassis.modules.length;

    // Check the Aux Bank Array. Remove any dupes
    // or banks that start beyond the number of
    // slots on the chassis.
    const setStarts = new Set<number>();
    if (chassis.primaryBankInfo)
        setStarts.add(chassis.primaryBankInfo.startSlot);

    for (let ritr = chassis.auxBanks.length - 1; ritr >= 0; --ritr) {
        const info = chassis.auxBanks[ritr].info;
        if (setStarts.has(info.startSlot) ||
            info.slotsInBank === 0 ||
            info.startSlot >= lenModArr)
        {
            chassis.auxBanks.splice(ritr, 1);
            somethingChanged = true;
        }
        else
            setStarts.add(info.startSlot);
    }

    if (somethingChanged) {
        if (chassis.auxBanks.length === 0) {
            chassis.auxBanks = undefined;
            chassis.primaryBankInfo = undefined;
        }
        else {
            updateBanks(chassis);
        }

        chassisChanged(chassis);
        updateChassis(chassis, true);

        return true;
    }

    deleteAutoFix(af);
    return false;
}
