import React, { useCallback, useRef, useState } from 'react';
import './PointEntry.scss'
import './Settings.scss';
import { IOEntryModeEnum } from '../types/SettingsTypes';
import {
	Autocomplete,
	Checkbox,
	FormControl,
	IconButton,
	Select,
	SelectChangeEvent,
	MenuItem,
	TextField,
	Popper,PopperProps, Tooltip
} from '@mui/material';
import {
	overrideShowModuleCount,
	overrideShowModuleSelections,
    updatePointEntry
} from './PointEntrySection';
import {
	hdrChkFlexValue,
	hdrTxtCatFlexValue,
	hdrTxtQtyFlexValue,
	hdrTxtTypeFilterFlexValue,
	hdrTxtTypeFlexValue,
	hdrTxtTypeFlexValueBasic
} from './PointEntryHeader';
import { PointEntryMaxVal } from '../types/Globals';
import { LocAttributeInfo } from '../types/ProjectTypes';
import { IOTypeAndFeatureInfo, txtFeatureANY } from '../types/IOModuleTypes';
import { PointEntryInfo } from '../types/IOPointEntryTypes';
import { getDefaultIOModuleCatalog } from '../util/IOModuleHelp';
import { getIODisplayTitleFromID } from '../model/IOModule';
import FailedIcon from '../svg/failed-filled.svg';
import WarningIcon from '../components/layoutButtons/precoloredSvgs/yellow-errors.svg'
import { displayAlertMsg } from '../util/MessageHelp';
import { StatusLevel } from '../types/MessageTypes';


const sxPropsPointEntry = {
	fontFamily: 'Barlow',
	fontWeight: 400,
	fontSize: '16px',
	lineHeight: '24px'
}

const sxPropsPointEntryIOType = {
	fontFamily: 'Barlow',
	fontWeight: 400,
	fontSize: '16px',
	lineHeight: '20px'
}

interface Props {
	locAttr: LocAttributeInfo;
	entry: PointEntryInfo;
	externalMaskChanged: boolean;
	mapTypesAndFeatures: Map<string, IOTypeAndFeatureInfo>;
}

export const PointEntry = (props: Props) => {

	const locAttr = props.locAttr;
	const [refresh, setRefresh] = useState(0);
	const inputPointsControl = useRef<HTMLInputElement>();

	// Start our Feature array
	const arrPointTypeFeatures: string[] = [txtFeatureANY];
	arrPointTypeFeatures.push(...props.entry.features);

	const getDisplayStringFromSelectedCatalog = useCallback((): string => {
		if (props.entry.advancedModule != null) {
			const idx = props.entry.moduleCatalogs.findIndex((x) => x === props.entry.advancedModule);
			if (idx != null && idx < props.entry.moduleDisplayStrs.length)
				return props.entry.moduleDisplayStrs[idx];
		}

		return (props.entry.moduleDisplayStrs.length > 0 ? props.entry.moduleDisplayStrs[0] : '');
	}, [props]);


	const CustomPopper = useCallback((props: PopperProps) => {
		return (
			<Popper {...props} placement="auto-end">
				{props.children}
			</Popper>
		);
	}, []);

	// ^^^ ALL HOOKS ABOVE HERE ^^^
	
	const updateEntryDefaultStatus = () => {
		// Should only be called is the catalog number has changed!
		if (props.entry.isAdvModDefault) {
			props.entry.isAdvModDefault = false;
		}
		else{
			props.entry.isAdvModDefault =
				(props.entry.advancedModule === getDefaultIOModuleCatalog(props.entry.type, locAttr));
		}
	}

	const OnCmbModuleSelChanged = (event: unknown, newValue: string | null) => {
		if (newValue != null) {
			const idx = props.entry.moduleDisplayStrs.indexOf(newValue);
			// Validate and range check the index.
			if (idx != null && idx >= 0 && idx <= props.entry.moduleCatalogs.length) {
				const newModule = props.entry.moduleCatalogs[idx];
				if (props.entry.advancedModule !== newModule) {
					props.entry.advancedModule = newModule;
					updateEntryDefaultStatus();
					setRefresh(refresh + 1);
					props.entry.OnChanged();
				}
			}
		}

		// Adobe Analytics.
		const changeIOCatNoClick = new CustomEvent("designChangeIOCatNo", {
			detail: {
			action: "Change IO Catalogue Number",
			properties: {
				category: "WebApp",
				label: "Change IO Catalogue Number",
			},
			},
		});
		document.getElementById("root")?.dispatchEvent(changeIOCatNoClick);
	}

	const handlePointTypeChange = (event: SelectChangeEvent) => {

		// if the type has not changed...
		if (props.entry.type === event.target.value)
			return;

		// Verify the type is in the entries type array
		if (!props.entry.types.some(tp => tp === event.target.value))
			throw new Error('PointEntry.handlePointTypeChange(): invalid type selected');

		props.entry.type = event.target.value;
		updatePointEntry(props.entry, locAttr);

		props.entry.OnChanged();

		// Adobe Analytics.
		const changeIOTypeClick = new CustomEvent("designChangeIOType", {
			detail: {
			action: "Change IO Type",
			properties: {
				category: "WebApp",
				label: "Change IO Type",
			},
			},
		});
		document.getElementById("root")?.dispatchEvent(changeIOTypeClick);
	};


	const handlePointFeatureChange = (event: SelectChangeEvent) => {
		const newFeature = (event.target.value === txtFeatureANY ? '' : event.target.value);
		// NOTE: This filter will ONLY apply to ADVANCED
		// ENTRY MODE. Basic mode should not get us here.
		if (advancedEntry === false || props.entry.feature === newFeature)
			return;


		props.entry.feature = newFeature;
		updatePointEntry(props.entry, locAttr);

		props.entry.OnChanged();

		// Adobe Analytics.
		const changeIOFunctionClick = new CustomEvent("designChangeIOFunction", {
			detail: {
			action: "Change IO Function",
			properties: {
				category: "WebApp",
				label: "Change IO Function",
			},
			},
		});
		document.getElementById("root")?.dispatchEvent(changeIOFunctionClick);
	};


	const OnQuantityChanged = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		if (event != null) {
			const val = Math.round(Number(event.target.value));
			if (val !== props.entry.points) {
				// If the value is valid...
				if (val >= 0)
					props.entry.points = val;

				// Regardless, refresh the entry. If the value
				// was invalid, wipe it out.
				setRefresh(refresh + 1);
				props.entry.OnChanged();

				// Adobe Analytics.
				const enterIOPointClick = new CustomEvent("designEnterIOPoint", {
					detail: {
					action: "Enter IO Point",
					properties: {
						category: "WebApp",
						label: "Enter IO Point",
					},
					},
				});
				document.getElementById("root")?.dispatchEvent(enterIOPointClick);
			}
		}
	}

	const showModuleCount = (overrideShowModuleCount);
	const advancedEntry = (locAttr.ioEntryMode === IOEntryModeEnum.Advanced);
	let showAdvancedModeCtrls = (overrideShowModuleSelections || locAttr.ioEntryMode === IOEntryModeEnum.Advanced);

	const validateQuantity: React.FormEventHandler<HTMLInputElement> = (e) => {
		const target = e.target as HTMLInputElement;
		if (target) {
			if (target.valueAsNumber > PointEntryMaxVal) {
				target.value = props.entry.points.toString();
			}
			else {
				target.value = target.valueAsNumber.toString();
			}
		}
	}

	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		switch (event.key) {
			case 'Enter':
			case 'Escape':
				inputPointsControl.current?.blur();
				break;

			default:
				break;
		}
	}

	const selectedID = `selected-${props.entry.indexEntry}`;
	const pointCountID = `point-count-${props.entry.indexEntry}`;
	const modSelComboID = `mod-sel-combo-${props.entry.indexEntry}`;
	const modSelTextID = `mod-sel-text-${props.entry.indexEntry}`;
	const pointTypeTextID = `point-type-${props.entry.indexEntry}`;
	const pointTypeFilterTextID = `point-typefilter--${props.entry.indexEntry}`;

	let selected = (advancedEntry ? props.entry.advancedModule : props.entry.basicModule);
	if (showAdvancedModeCtrls) {
		const index = props.entry.moduleCatalogs.findIndex((val) => val === selected);
		if (index >= 0 && index < props.entry.moduleDisplayStrs.length) {
			selected = props.entry.moduleDisplayStrs[index];
		}
		else {
			showAdvancedModeCtrls = false;
		}
	}

	const onCheckChanged = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
		props.entry.selected = checked;
		setRefresh(refresh + 1);

		// Need to call onChanged to update
		// the Settings Tool Bar.
		props.entry.OnChanged();

		// Adobe Analytics.
		const selectIndivIORowsClick = new CustomEvent("designSelectIndivIORow", {
			detail: {
				action: "Select Individual IO Row",
				properties: {
					category: "WebApp",
					label: "Select Individual IO Row",
				},
			},
		});
		document.getElementById("root")?.dispatchEvent(selectIndivIORowsClick);
	}

	const txtError = 'There are no I/O modules that support your requirements. Please select a valid I/O Type if one exists.';
	const onErrorIcon = () => {
		displayAlertMsg(txtError, StatusLevel.Error);
	}

	const render_TipIcon = () => {
		if (props.entry.invalidEntry) {
			const srcIcon = (props.entry.points > 0 ? FailedIcon : WarningIcon);
			const iconClass = (props.entry.points > 0 ? 'setting-icon-btn-svg-error' : '');
			return (
				<Tooltip title={txtError}>
					<div style={{ height: '27px', width: '5px' }} >
						<IconButton
							disabled={false}
							onClick={onErrorIcon}
							sx={{ flex: hdrChkFlexValue, marginTop: '-8px', marginLeft: '-2px'}}
						>
							<img src={srcIcon} width={20} className={iconClass} />
						</IconButton>
					</div>
				</Tooltip>
			);
		}
		return null;
	}


	const render_Checkbox = () => {
		return (
			<div style={{ height: '27px' }} >
				<Checkbox
					id={selectedID}
					checked={props.entry.selected}
					onChange={onCheckChanged}
					disabled={false}
					sx={{ flex: hdrChkFlexValue, marginTop: '-8px', marginRight: '-15px' }}
				/>
			</div>
		);
	}


	const render_PointQuantity = () => {
		return (
			<TextField
				inputRef={inputPointsControl}
				id={pointCountID}
				variant="standard"
				label=""
				disabled={false}
				error={props.entry.invalidEntry}
				type="number"
				value={props.entry.points}
				className="point-entry-hdr-pt"
				sx={{
					flex: hdrTxtQtyFlexValue,
					"& .MuiInputBase-input.Mui-disabled": {
						WebkitTextFillColor: "black",
					},
				}}
				onKeyDown={handleKeyDown}
				onChange={OnQuantityChanged}
				onFocus={event => { event.target.select(); }}
				onInput={validateQuantity}
				InputProps={{ style: { ...sxPropsPointEntry } }}
			/>
		);
	}


	const render_PointType = () => {
		// Prevent warnings when we get here
		// before the entry is updated.
		if ( props.entry.types.length === 0 )
			return null;

		return (
			<div className={showAdvancedModeCtrls ? "point-entry-type" : "point-entry-type-basic"} >
				<FormControl
					variant="standard"
					className="point-entry-hdr-pt-type"
					disabled={props.entry.invalidEntry}
					error={props.entry.invalidEntry}
					sx={{
						flex: showAdvancedModeCtrls ? hdrTxtTypeFlexValue : hdrTxtTypeFlexValueBasic,
						width: '100%',
						"& .MuiInputBase-input.Mui-disabled": {
							WebkitTextFillColor: "black",
						},
					}}
				>
					<Select	
						disabled={false}
						variant="standard"
						id={pointTypeTextID}
						value={props.entry.type}
						onChange={handlePointTypeChange}
						label=""
						sx={{ ...sxPropsPointEntryIOType }}
					>
						{
							props.entry.types.map((type) => {
								return (
									<MenuItem
										value={type}
										key={type}
										style={{ ...sxPropsPointEntry }}
									>
										{getIODisplayTitleFromID(type)}
									</MenuItem>);
							})
						}
					</Select>

				</FormControl>
			</div>
		);
	}


	const render_PointFeature = () => {
		// Prevent warnings when we get here
		// before the entry is updated.
		if ( props.entry.feature && props.entry.features.length === 0)
			return null;

		return (
			<div className="point-entry-type-filter">
				<FormControl
					variant="standard"
					className="point-entry-hdr-pt-type"
					disabled={props.entry.invalidEntry}
					error={props.entry.invalidEntry}
					sx={{
						width: '100%',
						flex: hdrTxtTypeFilterFlexValue,
						"& .MuiInputBase-input.Mui-disabled": {
							WebkitTextFillColor: "black",
						},
					}}
				>
					<Select
						disabled={props.entry.invalidEntry}
						variant="standard"
						id={pointTypeFilterTextID}
						value={props.entry.feature ? props.entry.feature : txtFeatureANY} // Cannot set value to '', will not display.
						onChange={handlePointFeatureChange}
						label=""
						sx={{ ...sxPropsPointEntryIOType }}
					>
						{
							arrPointTypeFeatures.map((feature) => {
								return (<MenuItem
									value={feature}
									key={feature}
									style={{ ...sxPropsPointEntry }}
								>
									{getIODisplayTitleFromID(feature)}
								</MenuItem>);
							})
						}
					</Select>

				</FormControl>
			</div>
		);
	}


	const render_ModuleSelections = () => {
		// 2023.9.28 Added renderOption to size the text
		// in the combobox list items.
		return (
			<div className="point-entry-modules">
				< Autocomplete
					size='medium'
					disablePortal
					id={modSelComboID}
					options={props.entry.moduleDisplayStrs}
					defaultValue={props.entry.moduleDisplayStrs[0]}
					value={getDisplayStringFromSelectedCatalog()}
					onChange={OnCmbModuleSelChanged}
					disableClearable
					role="listbox"
					sx={{ minWidth: 0 }}
					PopperComponent={CustomPopper}
					renderOption={(props, option: string) => (
						<li {...props} style={{ ...sxPropsPointEntry }}>{option}</li>
					)}
					renderInput={(params) =>
						<TextField
							{...params}
							variant="standard"
							id={modSelTextID}
							label=""
							margin="none"
							className="point-entry-hdr-catalog"
							sx={{
								flex: hdrTxtCatFlexValue,
								minWidth: 0,
								"& .MuiInputBase-input.Mui-disabled": {
									WebkitTextFillColor: "black",
								}
							}}
							InputProps={{ ...params.InputProps, style: { ...sxPropsPointEntry } }}
						/>}
				/>
			</div>
		);
	}

	//// MAIN COMPONENT RENDER /////////////////
	return (
		<div className={props.entry.invalidEntry ? "point-entry-container" : "point-entry-container--valid"} >
			{
				render_TipIcon()
			}
			{
				render_Checkbox()
			}
			{
				render_PointQuantity()
			}

			{
				render_PointType()
			}
			{
				props.entry.invalidEntry ?
					<Tooltip title="There are no I/O modules that support your selected requirements (non-Standard Environmental Rating may have limited offerings). Please Select a valid I/O Type if one exists.">
						<div className={props.entry.points > 0 ? "point-entry-invalid" : "point-entry-invalid--warning"}> * There are no I/O modules that support your selected requirements...</div>
					</Tooltip>
					:
					<div id="comment: ADVANCED POINT TYPE ENTRY CONTROLS" className="point-entry-advanced-control-container">
						{
							showAdvancedModeCtrls ?
								render_PointFeature()
								:
								null
						}

						{
							showAdvancedModeCtrls ?
								render_ModuleSelections()
								:
								null
						}

						{
							showModuleCount ?
								<div className="point-entry-module-count-div">
									{props.entry.moduleSelInMultipleEntries ?
										<a className="point-entry-module-count-text">
											{`*${props.entry.advancedModCount} Total Modules`}
										</a>
										:
										<a className="point-entry-module-count-text">
											{`${props.entry.advancedModCount} Modules`}
										</a>
									}
								</div>
								:
								null
						}
					</div>
			}
		</div >
	);
}
