import React from "react";
import "./PerformanceInfo.scss";
import "../../styles/Meteor.scss";
import { ProjectCommsInfo } from "../../model/CommDetails";
import PerformanceColumn from "./PerformanceColumn";
import { DetailItem } from "../../model/DeviceDetail";
import { ChassisProject, MicroChassis } from "../../types/ProjectTypes";
import { PlatformMicro } from "../../platforms/PlatformConstants";
import { getMicroModules } from "../../util/EngInfoHelp";
import { formatBytesString } from "../../util/StringFormatHelp";

interface Props {
  commsInfo: ProjectCommsInfo;
  project: ChassisProject;
}

    const PerformanceCapabilities = (props: Props) => {

    const rackCount =props.project.content.racks.length;
    const ClientCIP = new Array<DetailItem>();
    const ClientTCP = new Array<DetailItem>();
    const ServerCIP = new Array<DetailItem>();
    const ServerTCP = new Array<DetailItem>();
    let microchassis;
    let getEngData;
    let isbuexist ;
    let MemoryAvail =0;
    let PPSAvail = 0;
    let PPSIOAvail =0;
    let ClientCIPTotal = 0;
    let ClientTCPClass1HMI =0;
    let ClientTCPTotal = 0;
    let ServerCIPTotal =0;
    let ServerTCPTotal = 0;
    
    const CalPerformanceCapabilitiesForAllChassis = (project: ChassisProject) => {
        for (const rack of project.content.racks) {
        microchassis=rack.chassis as MicroChassis
        getEngData = microchassis&&microchassis.bu&&getMicroModules(PlatformMicro, microchassis.bu.catNo);
        isbuexist= microchassis.bu;
        if (getEngData)
            {
                MemoryAvail += isbuexist?parseInt(getEngData?.MemoryAvail):0; 
                PPSAvail += isbuexist?parseInt(getEngData?.PPSIOAvail):0;
                PPSIOAvail+=isbuexist? parseInt(getEngData?.ClientCIPClass1):0;
                ClientCIPTotal+=isbuexist?parseInt(getEngData?.ClientCIPTotal):0;
                ClientTCPClass1HMI+=isbuexist?parseInt(getEngData?.ClientTCPClass1HMI):0;
                ClientTCPTotal+=isbuexist?parseInt(getEngData?.ClientTCPTotal):0;
                ServerCIPTotal+=isbuexist?parseInt(getEngData?.ServerCIPTotal):0;
                ServerTCPTotal+=isbuexist?parseInt(getEngData?.ServerTCPTotal):0;
            }
        } 
        ClientCIP.push({
            name: 'Class 1 Devices (Max):',
            value: PPSIOAvail.toString()
        });
        ClientCIP.push({
            name: 'Total Client CIP Connenctions (Max):',
            value: ClientCIPTotal.toString()
        });
    
        
        ClientTCP.push({
            name: 'Class 1 & HMI (Max): ',
            value: ClientTCPClass1HMI.toString()
        });
        ClientTCP.push({
            name: 'Total Client TCP Connenctions (Max):',
            value: ClientTCPTotal.toString()
        });
       
        ServerCIP.push({
            name: 'Total Server CIP Connenctions (Max):',
            value: ServerCIPTotal.toString()
        });
        
        ServerTCP.push({
            name: 'Total Server TCP Connenctions (Max):',
            value: ServerTCPTotal.toString()
        });   
    }
    if(rackCount>0)
        {
            CalPerformanceCapabilitiesForAllChassis(props.project);
        }
    return (
        <div className='perfcap-columns'>
            <PerformanceColumn
                title={'Client CIP Connections:'}
                progWidth={0}
                perfUsageItems={[]}
                detailItems={ClientCIP}
                platform={PlatformMicro}
            />
            <PerformanceColumn
                title={'Client TCP Connections:'}
                progWidth={0}
                perfUsageItems={[]}
                detailItems={ClientTCP}
                platform={PlatformMicro}
            />
            <PerformanceColumn
                title={'Server CIP Connections:'}
                progWidth={0}
                perfUsageItems={[]}
                detailItems={ServerCIP}
                platform={PlatformMicro}
            />
            <PerformanceColumn
                title={'Server TCP Connections:'}
                progWidth={0}
                perfUsageItems={[]}
                detailItems={ServerTCP}
                platform={PlatformMicro}
            />
              <PerformanceColumn
                title={'Memory : '+  formatBytesString(MemoryAvail, false, true)}
                progWidth={0}
                perfUsageItems={[]}
                detailItems={[]}
                platform={PlatformMicro}
            />
              <PerformanceColumn
                title={'I/O Packets per Second (PPS): '+PPSAvail}
                progWidth={0}
                perfUsageItems={[]}
                detailItems={[]}
                platform={PlatformMicro}
            />
        </div>
    );
};

export default PerformanceCapabilities;

