import React from 'react';
import FancyPath from '../../../components/cabling/FancyPath';
import { ChassisCompProps } from "../../../implementation/ImplGeneral";
import { getInfoAllBanks } from '../../../model/ChassisProject';
import { BankInfo } from '../../../types/ProjectTypes';
import { CableSpec, getCableProps } from '../../../util/CableHelp';
import { flexHAGetCableOpacity, getBankCableSpecs } from '../model/FlexHALayout';
import FlexHAChassisBank from './FlexHAChassisBank';
import FlexHAChassisSAPwr from './FlexHAChassisSAPwr';


const FlexHAChassisComp = (props: ChassisCompProps) => {
    const infoAllBanks = getInfoAllBanks(props.chassis);
    const bankCblSpecs = getBankCableSpecs(props.chassis);
    const bankCblOpac = flexHAGetCableOpacity(props);

    const renderBank = (bankInfo: BankInfo) => {
        return (
            <FlexHAChassisBank
                chassis={props.chassis}
                bumper={props.bumper}
                ptOrg={props.ptOrg}
                showAsSelected={props.showAsSelected}
                localDeviceSelected={props.localDeviceSelected}
                renderType={props.renderType}
                layoutMode={props.layoutMode}
                bankInfo={bankInfo}
                key={bankInfo.bankNum}
            />
        );
    }

    let nextCblKey = 0;

    const renderBankCbl = (cblSpec: CableSpec) => {
        const cblProps = getCableProps(cblSpec,
            props.ptOrg, bankCblOpac);

        return (
            <FancyPath
                key={'C' + nextCblKey++}
                pathProps={cblProps}
            />
        )
    }

    return (
        <>
            {infoAllBanks.map(info => {
                return (renderBank(info));
            })}
            {bankCblSpecs.map(cblSpec => {
                return (renderBankCbl(cblSpec));
            })}

            <FlexHAChassisSAPwr
                {...props}
            />
        </>
    );

}

export default FlexHAChassisComp;

