import { PlatformCLX, PlatformCpLX, PlatformFlex, PlatformFlexHA, PlatformMicro } from "../platforms/PlatformConstants";
import { UseSmartPowerDsply } from "../types/Globals";
import {
    Chassis,
    UsageLevelStatus,
} from "../types/ProjectTypes";
import {
    PowerUnitType,
    PwrUsageInfo,
    PowerBreakdown,
    PowerType,
    PowerStatusLogMessage,
} from "../types/PowerTypes";
import { PSUErrorThreshold, PSUWarningThreshold } from "./Checker";
import { LogMsgLevel } from "./ProjectLog";


export const getPlatformPowerType = (platform: string): PowerType => {
    switch (platform) {
        case PlatformCLX:
            return PowerType.PT_524mA;

        case PlatformCpLX:
        case PlatformFlex:
        case PlatformFlexHA:
            return PowerType.PT_ModSA;

        case PlatformMicro:
            return PowerType.PT_AlwaysOK;

        default:
            throw new Error('Unknown PowerType for platform: ' + platform);
    }
}

export const isMoreOrEqualPower = (p1: PowerBreakdown, p2: PowerBreakdown): boolean => {
    if (p2.mAat24V > p1.mAat24V) return false;
    if (p2.mAat5V > p1.mAat5V) return false;
    if (p2.mWatt > p1.mWatt) return false;
    return true;
}

export const _getConvDtl = (unitType: PowerUnitType): [divisor: number, unitTag: string] => {
    switch (unitType) {
        case PowerUnitType.mA:
            return [1000, 'Amp'];

        case PowerUnitType.mW:
            return [1000, 'Watt'];

        default:
            throw new Error('Invalid unit type in _getConvDtl');
    }
}

export const getEmptyPowerBreakdown = (): PowerBreakdown => {
    return {
        mAat5V: 0,
        mAat24V: 0,
        mWatt: 0,
        modPower: 0,
        saPower: 0
    };
}

export const isEmptyPower = (bd: PowerBreakdown): boolean => {
    return ((bd.mAat5V === 0) &&
        (bd.mAat24V === 0) &&
        (bd.mWatt === 0) &&
        (bd.modPower === 0) &&
        (bd.saPower === 0));
}

const _valQualifiesForFullUnits = (value: number): boolean => {
    // Should the mW/mA value be
    // presented as Watts/Amps.
    const [useFullUnits, ] = _useFullPwrUnits(value);
    return useFullUnits;
}

// Warning: value expected to be PRE-qualified as being
// suitable for conversion.
const _getConvertedValStr = (value: number, unitType: PowerUnitType): string => {
    const [divisor, unitTag] = _getConvDtl(unitType);
    const cnvVal = value / divisor;
    const pluralExt = (cnvVal !== 1.0) ? 's' : '';
    return cnvVal + ' ' + unitTag + pluralExt;
}

export const getPwrValStr = (value: number, unitType: PowerUnitType, forceFullUnits?: boolean): string => {
    if (UseSmartPowerDsply && (_valQualifiesForFullUnits(value) || forceFullUnits)) {
        return _getConvertedValStr(value, unitType);
    }
    return value + ' ' + unitType.toString();
}

export const getPowerUsageStatus = (pctUsed: number): UsageLevelStatus => {

    if (pctUsed > PSUErrorThreshold) {
        return UsageLevelStatus.Error;
    }
    else if (pctUsed > PSUWarningThreshold) {
        return UsageLevelStatus.Warning;
    }
    else {
        return UsageLevelStatus.OK;
    }
}

const _useFullPwrUnits = (used: number, forceFullUnits?: boolean): [useFullUnits: boolean, valFullUnits: number] => {
    // Incoming 'used' value is in 'milli' form (mA or mW).
    // Start by converting to FULL units (Amps or Watts).
    const valFullUnits = used / 1000;
    const useFullUnits = (valFullUnits > 1 || forceFullUnits === true);
    return [useFullUnits, valFullUnits];
}

const _getPwrUsageUsedSide = (used: number, forceFullUnits?: boolean): string => {

    // Determine if the incoming number in mW or mA should
    // be converted to Watts or Amps. Note: forceFullUnits
    // is used when we have a consumed/used number that does
    // not qualify for Full Units, but the SUPPLIED number does.
    const [useFullUnits, valFullUnits] = _useFullPwrUnits(used, forceFullUnits);

    // If the resulting number is greater than 1,
    // We'll use it as a string rounded to include
    // one digit to the right of the decimal point
    //The below code is changed to show exact value
    //in the issues modal.
    //Modfied to prevent rounding of the decimal 
    //value due to toFixed() Funtion
    if (useFullUnits) {
        return String(Math.trunc(Math.round(valFullUnits * 10)) / 10);
    }

    const usedPrec = used.toPrecision(1);
    const newUsed = parseFloat(usedPrec);
    const newUsedFullUnits = newUsed / 1000;
    return newUsedFullUnits.toPrecision(1);
}

export const getPwrUsageInfo = (used: number, avail: number, unitType: PowerUnitType): PwrUsageInfo => {
    const pctUsed = Math.min(1.0, used / avail);

    // 2024.3.26 We ran into situations where the units
    // of the 'used side' are in Amps and the supplied
    // side is in mAmps. Check if either side qualifies
    // to be converted to Amps (or Watts). If one side
    // does and the other does NOT, force full units
    // on both sides (i.e. both in Amps instead of mAmps).
    const forceFullUnits = (_valQualifiesForFullUnits(used) !== _valQualifiesForFullUnits(avail));

    const usageText = _getPwrUsageUsedSide(used, forceFullUnits) + ' / ' + getPwrValStr(avail, unitType, forceFullUnits);

    return { pctUsed: pctUsed, usageText: usageText, status: getPowerUsageStatus(pctUsed) };
}

const _addToPwrBrkdown = (target: PowerBreakdown, toAdd: PowerBreakdown): PowerBreakdown => {
    const bdSum: PowerBreakdown = { ...target };
    bdSum.mAat24V += toAdd.mAat24V;
    bdSum.mAat5V += toAdd.mAat5V;
    bdSum.mWatt += toAdd.mWatt;
    bdSum.modPower += toAdd.modPower;
    bdSum.saPower += toAdd.saPower;
    return bdSum;
}

const _subtractFromPwrBrkdown = (target: PowerBreakdown, toSubtract: PowerBreakdown): PowerBreakdown => {
    const bdRslt: PowerBreakdown = { ...target };
    bdRslt.mAat24V -= toSubtract.mAat24V;
    bdRslt.mAat5V -= toSubtract.mAat5V;
    bdRslt.mWatt -= toSubtract.mWatt;
    bdRslt.modPower -= toSubtract.modPower;
    bdRslt.saPower -= toSubtract.saPower;
    return bdRslt;
}

export const addPowerBreakdown = (pwrTarget: PowerBreakdown, pwrToAdd: PowerBreakdown, tallyOnTarget: boolean): PowerBreakdown => {
    const retVal = _addToPwrBrkdown(pwrTarget, pwrToAdd);
    if (tallyOnTarget) {
        setPowerBreakdownEqual(pwrTarget, retVal);
    }
    return retVal;
}

export const subtractPowerBreakdown = (pwrTarget: PowerBreakdown, pwrToSubtract: PowerBreakdown, tallyOnTarget: boolean): PowerBreakdown => {
    const retVal = _subtractFromPwrBrkdown(pwrTarget, pwrToSubtract);
    if (tallyOnTarget) {
        setPowerBreakdownEqual(pwrTarget, retVal);
    }
    return retVal;
}

export const isPowerBreakdownNegative = (pwrTarget: PowerBreakdown): boolean => {
    return (
        pwrTarget.mAat24V < 0 ||
        pwrTarget.mAat5V < 0 ||
        pwrTarget.mWatt < 0 ||
        pwrTarget.modPower < 0 ||
        pwrTarget.saPower < 0
    );
}

export const setPowerBreakdownEqual = (destination: PowerBreakdown, source: PowerBreakdown) => {
    destination.mAat24V = source.mAat24V;
    destination.mAat5V = source.mAat5V;
    destination.mWatt = source.mWatt;
    destination.modPower = source.modPower;
    destination.saPower = source.saPower;
}


export const getChassisPowerLogMessages = (consumed: PowerBreakdown, supplied: PowerBreakdown, chassis: Chassis): PowerStatusLogMessage[] => {
    const arrayMsgs: PowerStatusLogMessage[] = [];
    const pwrType = getPlatformPowerType(chassis.platform);
    if (pwrType === PowerType.PT_524mA) {
        // 5V, 24V, mW
        let percentage = calcPowerRatio(consumed.mAat5V, supplied.mAat5V);
        // If the percentage consumed is > than 
        // the Warning threshold, we at least have 
        // a warning and maybe an Error...
        if (percentage > PSUWarningThreshold) {
            const status = (percentage > PSUErrorThreshold ? LogMsgLevel.error : LogMsgLevel.warning);
            const pctThreshold = (percentage > PSUErrorThreshold ? PSUErrorThreshold : PSUWarningThreshold) * 100;
            const msg = `The consumed 5V power (${(consumed.mAat5V / 1000.0).toFixed(2)} Amps) in chassis '${chassis.name}' exceeds ${pctThreshold.toFixed(1)}% of the supplied power of ${(supplied.mAat5V / 1000.0).toFixed(2)} Amps.`;
            arrayMsgs.push({ status: status, message: msg });
        }

        percentage = calcPowerRatio(consumed.mAat24V, supplied.mAat24V);
        if (percentage > PSUWarningThreshold) {
            const status = (percentage > PSUErrorThreshold ? LogMsgLevel.error : LogMsgLevel.warning);
            const pctThreshold = (percentage > PSUErrorThreshold ? PSUErrorThreshold : PSUWarningThreshold) * 100;
            const msg = `The consumed 24V power (${(consumed.mAat24V / 1000.0).toFixed(2)} Amps) in chassis '${chassis.name}' exceeds ${pctThreshold.toFixed(1)}% of the supplied power of ${(supplied.mAat24V / 1000.0).toFixed(2)} Amps.`;
            arrayMsgs.push({ status: status, message: msg });
        }

        percentage = calcPowerRatio(consumed.mWatt, supplied.mWatt);
        if (percentage > PSUWarningThreshold) {
            const status = (percentage > PSUErrorThreshold ? LogMsgLevel.error : LogMsgLevel.warning);
            const pctThreshold = (percentage > PSUErrorThreshold ? PSUErrorThreshold : PSUWarningThreshold) * 100;
            const msg = `The consumed power (${(consumed.mWatt / 1000.0).toFixed(2)} Watts) in chassis '${chassis.name}' exceeds ${pctThreshold.toFixed(1)}% of the supplied power of ${(supplied.mWatt / 1000.0).toFixed(2)} Watts.`;
            arrayMsgs.push({ status: status, message: msg });
        }
        return arrayMsgs;
    }
    else if (pwrType === PowerType.PT_ModSA) {
        // Just check MOD Power. SA Power can be
        // adjusted by the user. Most platforms
        // will never exceed the MOD Pwr limits.
        const percentage = calcPowerRatio(consumed.modPower, supplied.modPower);
        if (percentage > PSUWarningThreshold) {
            const status = (percentage > PSUErrorThreshold ? LogMsgLevel.error : LogMsgLevel.warning);
            const pctThreshold = (percentage > PSUErrorThreshold ? PSUErrorThreshold : PSUWarningThreshold) * 100;
            const msg = `The consumed MOD Power (${(consumed.modPower / 1000.0).toFixed(2)} Amps) in chassis '${chassis.name}' exceeds ${pctThreshold.toFixed(1)}% of the supplied power of ${(supplied.modPower / 1000.0).toFixed(2)} Amps.`;
            arrayMsgs.push({ status: status, message: msg });
        }
    }

    return arrayMsgs;
}

export const IsPowerThresholdExceeded = (platform: string, consumed: PowerBreakdown, supplied: PowerBreakdown, threshold: number): boolean => {

    const pwrType = getPlatformPowerType(platform);
    if (pwrType === PowerType.PT_524mA) {
        let percentage = calcPowerRatio(consumed.mAat5V, supplied.mAat5V);
        if (percentage > threshold)
            return true;
        percentage = calcPowerRatio(consumed.mAat24V, supplied.mAat24V);
        if (percentage > threshold)
            return true;
        percentage = calcPowerRatio(consumed.mWatt, supplied.mWatt);
        if (percentage > threshold)
            return true;
    }
    else if (pwrType === PowerType.PT_ModSA) {
        let percentage = calcPowerRatio(consumed.modPower, supplied.modPower);
        if (percentage > threshold)
            return true;
        percentage = calcPowerRatio(consumed.saPower, supplied.saPower);
        if (percentage > threshold)
            return true;
    }

    return false;
}

export const calcPowerRatio = (consumed: number, supplied: number): number => {
    // Avoid div by zero.
    if (supplied === 0) {
        // if we have a consumed value, return > 100%.
        if (consumed > 0)
            return 1.000009;
        else
            return 0.0;
    }

    return consumed / supplied;
}

const _getPowerLabelPart = (breakdown: PowerBreakdown): string => {
    return getPwrValStr(breakdown.mAat5V, PowerUnitType.mA) + ' (5v) / ' +
        getPwrValStr(breakdown.mAat24V, PowerUnitType.mA) + ' (24v)';
}

export const getPSLabelText = (pwrInfo: PowerBreakdown, redundant: boolean, slim: boolean):
    string => {

    let label = '';
    if (redundant) {
        label += 'Redundant: ';
    }

    label += _getPowerLabelPart(pwrInfo);

    if (slim) {
        label += ' (Slim)';
    }

    return label;
}

