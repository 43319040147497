import { PlatformCLX } from "../../PlatformConstants";
import {
	Chassis,
	ChassisElement,
	ChassisModule,
	ChassisPowerSupply,
	DeviceCategory,
	GraphicalDevice,
	ModuleDragStatus,
	SelectableDevice,
	IOModuleWiring,
	EnvRating,
	Rack,
	DeviceType,
	SlotModulePair,
	ModuleSlotRestriction,
} from "../../../types/ProjectTypes";
import { PowerBreakdown, PSInputVoltage } from "../../../types/PowerTypes";
import { getEmptyPowerBreakdown	} from "../../../util/PowerHelp";
import {
	DragDeviceInfo,
	DropResult,
	DropStatus,
	getDragDeviceAlternate
} from "../../../util/DragAndDropHelp";
import { LocAndSize, Point, Size } from "../../../types/SizeAndPosTypes";
import { getImgNameFromPath, getLocCenter, getScaledLoc, offsetLoc, scaleSize } from "../../../util/GeneralHelpers";
import { getNewInstanceId } from "../../../util/InstanceIdHelp";
import {
	detachModule,
	getDeviceCategory,
	getModuleInSlot,
	getModuleSlotBreakdown,
	getProjectFromChassis,
	isValidSlotNumber,
	updateRackLayout,
	getEnvRatingFromSpec,
    isDeviceCompatibleWithChassis,
    getProductDescription,
    getPowerSuppliedBy,
    getPowerConsumedBy,
    getPowerBreakdown,
    getDeviceDimensions,
    getDefaultImageSource,
} from "../../../model/ChassisProject";
import {
	CLXChassisImages,
	CLXLayoutInfo,
	CLXChassisTemplate,
	CLXSlotFiller,
} from "../types/CLXTypes";
import CLXChassisComp from "../components/CLXChassisComp";
import { StageUnitsPerMM } from "../../../types/StageTypes";
import {
	DetailGroup,
	makeAccysGroup,
	makeDevicePowerGrp,
	makeDimensionsGrp,
	makePowerUsageGrp,
	makeSingletonDtlGroup
} from "../../../model/DeviceDetail";
import { RegisterCLXCheckerImpl } from "./CLXChecker";
import { chassisChanging } from "../../../util/UndoRedo";
import { unexpectedError } from "../../../util/ErrorHelp";
import { clxConfigureChassis } from "../config/CLXChassisConfig";
import { logger } from "../../../util/Logger";
import { ActBtnInfo, DfltActBtnSpecs, LayoutActionType } from "../../../types/LayoutActions";
import {
	clxPrepareLocAttrHardwareForGen,
	clxGetLocAttrInfoForChassisEdit,
} from "./CLXGuidedSelection";
import { RegisterCLXEngDataImpl } from "./CLXEngDataImpl";
import {
    EngInfoChassis,
	EngInfoCommModule,
	EngInfoModule
} from "../../../engData/EngineeringInfo";
import {
	getChassisEngInfo,
	getCommDetailRoles,
	getModuleEngInfo,
	getPowerSupplyEngInfo,
	isSlotFillerCatNo
} from "../../../util/EngInfoHelp";
import { addRequiredAccys } from "../../../util/AccessoriesHelp";
import {
    addModuleAtSlot,
	ChassisCompProps,
	createModule,
	GeneralImplSpec,
	getNumSlots,
	getPowerDetails,
	RegisterGeneralImpl
} from "../../../implementation/ImplGeneral";
import { HardwareGenImplSpec, RegisterHardwareGenImpl } from "../../../implementation/ImplHardwareGen";
import { ChassisElementType, ImageInfo } from "../../common/CommonPlatformTypes";
import { clxAddModulesToChassis, genCreateHardwareFromSettings, getCreateHWFromSettingsErrors } from "../../common/HardwareGen";
import { RendInfo, RendPortion } from "../../../util/SysDsgHelp";

const _useLocalModuleImages = false;

const _clxChassisImgLoc = '/assets/clx/chassisImgs/';
const _clxModImgLoc = '/assets/clx/modImages/';

const _psClxDfltStdSmall = '1756-PB72';
const _psClxDfltStdLarge = '1756-PB75';

const _psClxDfltCnfSmall = '1756-PB72K';
const _psClxDfltCnfLarge = '1756-PB75K';

const _psClxDfltXT = '1756-PBXT';

// There seems to be some variance in actual product dims,
// but all of the images we use to render CLX chassis
// elements are the same height - 280 pixels. And, in all
// cases, the given image's height overlays our entire chassis'
// height. That is, each individual image and the combination
// of all of them comprising a chassis rendering are always
// 280 pixels in height. 
const _clxElemImageHt = 280; // pixels

// Again, there appears to be some minor variance in height dims
// according to product documentation between specific chassis,
// but they're close enough to establish what WE will consider
// to be the (nominal) height of an entire CLX chassis (not
// including any mounting tabs that we don't show). That total
// height, as well as the height of the elements that make UP
// the chassis, are ALL considered to be 145 mm tall.
const _clxNominalHt = 145;   // millimeters

// Determine a multiplier we can use to convert a
// number in image-size units to millimeters.
const _clxImageSizeToMM = _clxNominalHt / _clxElemImageHt;

const _clxImageScaleFactor = _clxImageSizeToMM * StageUnitsPerMM;

const _getSizesInStageUnits = true;

const _getCLXSlotSize = (): Size => {

	// Slot images are 70 x 280 pixels.
	// Create a corresponding size in mm.
	const slotSize: Size = {
		width: 70 * _clxImageSizeToMM,
		height: 280 * _clxImageSizeToMM
	};

	// Then convert to stage units
	// (if we're doing that).
	if (_getSizesInStageUnits) {
		slotSize.width *= StageUnitsPerMM;
		slotSize.height *= StageUnitsPerMM;
	}

	// return the final size.
	return slotSize;
}

const _getCLXSlotGroups = (extendedTemp: boolean, slots: number): number[] => {
	if (extendedTemp) {
		switch (slots) {
			case 7:
				return [7];

			case 10:
				return [10];

			default:
				throw new Error('Invalid number of XT slots in _getSlotGroups!');
		}
	}
	else {
		switch (slots) {
			case 4:
				return [4];

			case 7:
				return [7];

			case 10:
				return [5, 5];

			case 13:
				return [6, 7];

			case 17:
				return [5, 6, 6];

			default:
				throw new Error('Invalid number of slots in _getSlotGroups!');
		}
	}
}

const _getCLXChasElImgInfo = (xt: boolean, el: ChassisElementType): ImageInfo => {

	// Start by creating an 'empty' ImageInfo.
	const imgInfo: ImageInfo = {
		imgSrc: '',
		width: 0,
		height: 0
	};

	// Depending on env and element type,
	// we'll have different URLs for our 
	// image, and differing widths of the
	// actual images themselves.
	let imageUrl = '';
	let imageWidth = 0;

	// ALL of our images, however, 
	// have the SAME height.
	const imageHeight = _clxElemImageHt;

	if (xt) {
		switch (el) {
			case ChassisElementType.EmptySlot:
				imageUrl = clxGetImageSource(DeviceCategory.Chassis, CLXChassisImages.emptySlotXT);
				imageWidth = 70;
				break;

			case ChassisElementType.SlotSep:
				imageUrl = clxGetImageSource(DeviceCategory.Chassis, CLXChassisImages.slotSepXT);
				imageWidth = 30;
				break;

			case ChassisElementType.RightCap:
				imageUrl = clxGetImageSource(DeviceCategory.Chassis, CLXChassisImages.rightCapXT);
				imageWidth = 57;
				break;

			default:
				break;
		}
	}
	else {
		switch (el) {
			case ChassisElementType.EmptySlot:
				imageUrl = clxGetImageSource(DeviceCategory.Chassis, CLXChassisImages.emptySlotStd);
				imageWidth = 70;
				break;

			case ChassisElementType.GrpSep:
				imageUrl = clxGetImageSource(DeviceCategory.Chassis, CLXChassisImages.grpSepStd);
				imageWidth = 20;
				break;

			case ChassisElementType.RightCap:
				imageUrl = clxGetImageSource(DeviceCategory.Chassis, CLXChassisImages.rightCapStd);
				imageWidth = 20;
				break;

			default:
				break;
		}
	}

	// If we got a location of the image in question...
	if (imageUrl.length) {
		// Convert our image's height and width to millimeters.
		let scaledWidth = imageWidth * _clxImageSizeToMM;
		let scaledHeight = imageHeight * _clxImageSizeToMM;

		// Then, if we're supposed to provide width and
		// height back in Stage units, multiply by 
		// the number of those per millimeter.
		if (_getSizesInStageUnits) {
			scaledWidth = scaledWidth * StageUnitsPerMM;
			scaledHeight = scaledHeight * StageUnitsPerMM;
		}

		// Push our results into the ImageInfo object.
		imgInfo.imgSrc = imageUrl;
		imgInfo.width = scaledWidth;
		imgInfo.height = scaledHeight;
	}

	// Return whatever we ended up with.
	return imgInfo;
}


const _getCLXChassisTemplate = (
	extendedTemp: boolean,
	conformal: boolean,
	slots: number
): CLXChassisTemplate => {
	const groups = _getCLXSlotGroups(extendedTemp, slots);

	return {
		extendedTemp: extendedTemp,
		conformal: conformal,
		totalSlots: slots,
		slotGroups: groups,
		emptySlot: _getCLXChasElImgInfo(extendedTemp, ChassisElementType.EmptySlot),
		slotSep: _getCLXChasElImgInfo(extendedTemp, ChassisElementType.SlotSep),
		grpSep: _getCLXChasElImgInfo(extendedTemp, ChassisElementType.GrpSep),
		rightCap: _getCLXChasElImgInfo(extendedTemp, ChassisElementType.RightCap)
	};
}

const clxGetChassisSlotUsage = (chassis: Chassis):
	[
		slotsInUse: number,
		slotFillers: number,
		lastUsedSlot: number
	] => {

	// Init return values.
	let slotsInUse = 0;
	let slotFillers = 0;
	let lastUsedSlot = -1;

	// Walk modules array. Note that we DON'T auto-increment
	// our slotIdx in the for statement parms, and do that
	// manually inside the look instead.
	for (let slotIdx = 0; slotIdx < chassis.modules.length;) {

		// Get the module at the given slot if there is one.
		const mod = chassis.modules[slotIdx];

		// If so...
		if (mod) {
			// Bump our total slots in use by the
			// number of slots used by the module.
			slotsInUse += mod.slotsUsed;

			// If the module is a slot filler,
			// bump that quantity as well.
			if (mod.slotFiller) {
				slotFillers += 1;
			}

			// Change our last used slot to be the 
			// LAST slot used by the module.
			lastUsedSlot = mod.slotIdx + mod.slotsUsed - 1;

			// Bump our idx by that size.
			slotIdx += mod.slotsUsed;
		}
		else {
			// No module at the slot. 
			// Just increment our idx.
			slotIdx++;
		}
	}

	// Return our final numbers.
	return [slotsInUse, slotFillers, lastUsedSlot];
}

// Returns number of slots either empty
// or currently occupied by a slot filler.
export const clxGetNumAvailableSlots = (chassis: Chassis): number => {
	const numSlots = getNumSlots(chassis);
    const [slotsInUse, slotFillers,] = clxGetChassisSlotUsage(chassis);
    const inUseNotInclFillers = slotsInUse - slotFillers;
    return (numSlots - inUseNotInclFillers);
}


// WCS: no need for platform specific override.
// What we had here is now default behavior.
// Warning: incoming slotNum expected to ALREADY have
// been range-checked for specific chassis application.
//const clxGetSlotID = (chassis: Chassis, slotNum: number): string => {
//	if (isValidSlotNumber(chassis, slotNum)) {
//		return slotNum.toString();
//	}
//	else {
//		return SlotIDError.InvalidSlotNum;
//	}
//}

export const getCLXChassisBasics = (cat: string):
	[et: boolean, conformal: boolean, slots: number] => {

	const info = getChassisEngInfo(PlatformCLX, cat);
	if (info) {
		return [info.envInfo.etOk, info.envInfo.ccOk, info.numSlots];
	}
	else {
		throw new Error('getCLXChassisBasics FAILED to get eng data for: ' + cat);
	}
}

const _getChassisTemplateForCat = (cat: string): CLXChassisTemplate | null => {
	const [xt, conformal, slots] = getCLXChassisBasics(cat);
	return _getCLXChassisTemplate(xt, conformal, slots);
}


const clxGetImageSource = (category: DeviceCategory, imgName: string): string => {
	let imgSrc = '';
	switch (category) {
		case DeviceCategory.Chassis:
		case DeviceCategory.PS:
			imgSrc = _clxChassisImgLoc + imgName;
			break

		case DeviceCategory.Module:
			imgSrc = _useLocalModuleImages
				? _clxModImgLoc + imgName
				: getDefaultImageSource(imgName);
			break;

		default:
			imgSrc = getDefaultImageSource(imgName);
			break;
	}

	if (imgSrc.charAt(imgSrc.length - 4) !== '.') {
		imgSrc += '.png';
	}

	return imgSrc;
}

const _makeEmptyCLXLayout = (xt: boolean, conformal: boolean): CLXLayoutInfo => {
	return {
		platform: PlatformCLX,
		numFPDs: 0,
		extendedTemp: xt,
		conformal: conformal,
		numSlots: 0,
		emptySlotImgSrc: '',
		slotLocs: new Array<LocAndSize>(),
		slotSepImgSrc: '',
		slotSepLocs: new Array<LocAndSize>(),
		grpSepImgSrc: '',
		grpSepLocs: new Array<LocAndSize>(),
		rightCapImgSrc: '',
		rightCapLoc: { x: 0, y: 0, width: 0, height: 0 },
		size: { width: 0, height: 0 }
	};
}

const _clxMakeChassisPS = (psCat: string, inputVoltage?: PSInputVoltage):
	ChassisPowerSupply | undefined => {

	const psEngInfo = getPowerSupplyEngInfo(PlatformCLX, psCat);
	if (psEngInfo) {
		const psInputVltg = psEngInfo.getInputVoltage(inputVoltage);

		const scaledSize: Size = {
			width: psEngInfo.imgSize.width * _clxImageSizeToMM,
			height: psEngInfo.imgSize.height * _clxImageSizeToMM
		};
		if (_getSizesInStageUnits) {
			scaledSize.width *= StageUnitsPerMM;
			scaledSize.height *= StageUnitsPerMM;
		}

		const ps: ChassisPowerSupply = {
			id: getNewInstanceId(),
			platform: PlatformCLX,
			deviceType: DeviceType.PS,
			catNo: psCat,
			description: psEngInfo.description,
			isPlaceholder: psEngInfo.isPlaceholder,
			extendedTemp: psEngInfo.envInfo.etOk,
			conformal: psEngInfo.envInfo.ccOk,
			accysPossible: psEngInfo.anyAccysPossible(),
			category: DeviceCategory.PS,
			imgSrc: clxGetImageSource(DeviceCategory.PS, psEngInfo.imgName),
			imgSize: scaledSize,
			selected: false,
			movable: false,
			dragStatus: ModuleDragStatus.NA,
			redundant: psEngInfo.redundant,
			loc: {
				x: 0,
				y: 0,
				width: scaledSize.width,
				height: scaledSize.height
			},
			inputVoltage: psInputVltg,
			parent: undefined
		};

		addRequiredAccys(ps);

		return ps;
	}
	return undefined;
}

const getImageInfo = (info: ImageInfo): [imgSrc: string, width: number, height: number] => {
	return [info.imgSrc, info.width, info.height];
}

const _makeCLXLayoutInfo = (tmpl: CLXChassisTemplate, psCatNo: string):
	[layout: CLXLayoutInfo, ps?: ChassisPowerSupply] => {
	const layout = _makeEmptyCLXLayout(tmpl.extendedTemp, tmpl.conformal);

	const [emptySlotSrc, slotWidth, slotHeight] = getImageInfo(tmpl.emptySlot);
	const [slotSepSrc, slotSepWidth, slotSepHeight] = getImageInfo(tmpl.slotSep);
	const [grpSepSrc, grpSepWidth, grpSepHeight] = getImageInfo(tmpl.grpSep);
	const [rightCapSrc, rightCapWidth, rightCapHeight] = getImageInfo(tmpl.rightCap);

	layout.emptySlotImgSrc = emptySlotSrc;
	layout.slotSepImgSrc = slotSepSrc;
	layout.grpSepImgSrc = grpSepSrc;
	layout.rightCapImgSrc = rightCapSrc;

	let lastX = 0;

	layout.size = { width: 0, height: 0 };

	const ps = _clxMakeChassisPS(psCatNo);
	if (ps) {
		lastX += ps.imgSize.width;
	}

	for (let grpIdx = 0; grpIdx < tmpl.slotGroups.length; grpIdx++) {
		if ((grpIdx > 0) && (grpSepWidth > 0)) {
			layout.grpSepLocs.push({ x: lastX, y: 0, width: grpSepWidth, height: grpSepHeight });
			lastX += grpSepWidth;
		}

		const slotsInGrp = tmpl.slotGroups[grpIdx];
		layout.numSlots += slotsInGrp;

		for (let slotIdx = 0; slotIdx < slotsInGrp; slotIdx++) {
			if ((slotIdx > 0) && (slotSepWidth > 0)) {
				layout.slotSepLocs.push({ x: lastX, y: 0, width: slotSepWidth, height: slotSepHeight })
				lastX += slotSepWidth;
			}
			layout.slotLocs.push({ x: lastX, y: 0, width: slotWidth, height: slotHeight });
			lastX += slotWidth;
		}
	}

	layout.rightCapLoc = { x: lastX, y: 0, width: rightCapWidth, height: rightCapHeight };
	lastX += rightCapWidth;

	layout.size.width = lastX;
	layout.size.height = rightCapHeight;

	return [layout, ps];
}

const _getOffsetLoc = (origLoc: LocAndSize, offset: Point): LocAndSize => {
	const newLoc = { ...origLoc };
	offsetLoc(newLoc, offset);
	return newLoc;
}

const _getOffsetLocArray = (origLocs: LocAndSize[], offset: Point): LocAndSize[] => {

	// If our original array is empty, just
	// return it. Nothing needs to change,
	if (origLocs.length === 0) {
		return origLocs;
	}

	// Create a NEW array of locs.
	const newLocs = new Array<LocAndSize>();

	// For each of the OLD ones.
	origLocs.forEach(oldLoc => {
		newLocs.push(_getOffsetLoc(oldLoc, offset));
	});
	return newLocs;
}

const _offsetNonPSCLXLayoutEls = (layout: CLXLayoutInfo, offset: Point) => {

	// Call helpers to give us NEW loc objects, which
	// start as copies of the old, but are then offset
	// by the amount specified.
	layout.slotLocs = _getOffsetLocArray(layout.slotLocs, offset);
	layout.slotSepLocs = _getOffsetLocArray(layout.slotSepLocs, offset);
	layout.grpSepLocs = _getOffsetLocArray(layout.grpSepLocs, offset);
	layout.rightCapLoc = _getOffsetLoc(layout.rightCapLoc, offset);
}

export const getDfltCLXPowerSupply = (xt: boolean, conformal: boolean, slots: number): string => {
	if (xt) {
		return _psClxDfltXT;
	}
	else if (conformal) {
		return (slots > 10) ? _psClxDfltCnfLarge : _psClxDfltCnfSmall;
	}
	else {
		return (slots > 10) ? _psClxDfltStdLarge : _psClxDfltStdSmall;
	}
}

const _isPSValidForEnv = (psCat: string, needXT: boolean,
	needConformal: boolean): boolean => {

	const psEngInfo = getPowerSupplyEngInfo(PlatformCLX, psCat);
	if (psEngInfo) {
		if (needXT && !psEngInfo.envInfo.etOk)
			return false;
		if (needConformal && !psEngInfo.envInfo.ccOk)
			return false;
		return true;
	}
	return false;
}

const clxCreateChassis = (chasEngInfo: EngInfoChassis, psCatNo?: string): Chassis => {

	// Attempt to make a template for the given cat.
	const template = _getChassisTemplateForCat(chasEngInfo.catNo);

	// If we can...
	if (template) {
		// Get PS cat or use default for chassis type
		// if not provided.
		const psCat = (psCatNo && psCatNo.length)
			? psCatNo
			: getDfltCLXPowerSupply(template.extendedTemp,
				template.conformal, template.totalSlots);

		// Confirm that PS is actually valid. If so...
		if (_isPSValidForEnv(psCat, template.extendedTemp, template.conformal)) {

			// BEFORE making any change, call our helper
			// to handle any related undo/redo work for us.
			//contentChanging(project.content);

			// Construct our layout from our template.
			const [layout, ps] = _makeCLXLayoutInfo(template, psCat);

			// Get product data for the chassis itself.
			//const prodData = getCLXProdData(chassisCat);

			// Make the chassis object.
			const chassis: Chassis = {
				id: getNewInstanceId(),
				bump: 0,
				dragTarget: false,
				xSlotWidth: 0,
				platform: PlatformCLX,
				deviceType: DeviceType.Chassis,
				catNo: chasEngInfo.catNo,
				description: chasEngInfo.description,
				isPlaceholder: chasEngInfo.isPlaceholder,
				imgSrc: clxGetImageSource(DeviceCategory.Chassis, '1756-GenericChassis.png'),
				extendedTemp: template.extendedTemp,
				conformal: template.conformal,
				accysPossible: chasEngInfo.anyAccysPossible(),
				layout: layout,
				ps: ps,
				modules: new Array<ChassisModule>(layout.numSlots),
				selected: false,
				parent: undefined,
				redundant: false,
				defaultIOModWiring: IOModuleWiring.Screw,
				statusLog: undefined,
			};

			if (chassis.ps) {
				chassis.ps.parent = chassis;
			}

			// Success.
			return chassis;
		}
		else {
			throw new Error('clxCreateChassis with invalid or incompatible PS!');
		}
	}
	else {
		throw new Error('clxCreateChassis FAILED to get template for:'
			+ chasEngInfo.catNo);
	}
}

//interface SpecialLocalConnMod extends OLD_ModuleProdData {
//	conn_local?: string;
//}

// SPECIAL HANDLING ONLY. Should only be called for a module
// already determined to NOT be any of: controller, comm, or conn client.
// Used to detect special-case modules that aren't recognized as
// communication-related, but DO add LOCAL connection requirements
// to (some processor found in) the chassis they're located in.
const _getSpecialLocalConnRqmt = (modInfo: EngInfoModule): number => {

	// We use this to pick up connection requirements for our
	// 'Communication Module' modules that are NOT qualified as comms,
	// which would include DeviceNet and RIO adapters.
	// IFF the incoming type MIGHT be one of those...
	if (modInfo.isCommModule) {
		const asCommMod = modInfo as EngInfoCommModule;
		return asCommMod.localConns;
	}

	// If we're still here, just return 0.
	// The incoming modData does NOT indicate
	// any special local connection requirement.
	return 0;
}

const clxCreateModule = (catNo: string): ChassisModule | null => {

	//const modData = getCLXProdData(catNo) as OLD_ModuleProdData;
	const modInfo = getModuleEngInfo(PlatformCLX, catNo);

	if (modInfo) {
		const devType = modInfo.type;
		const slotSize = _getCLXSlotSize();
		const commDtlsRoles = getCommDetailRoles(PlatformCLX, catNo);
		const specialLocalConns = commDtlsRoles.anyRoles
			? 0
			: _getSpecialLocalConnRqmt(modInfo);
		return {
			id: getNewInstanceId(),
			platform: PlatformCLX,
			deviceType: devType,
			catNo: catNo,
			description: modInfo.description,
			isPlaceholder: modInfo.isPlaceholder,
			extendedTemp: modInfo.envInfo.etOk,
			conformal: modInfo.envInfo.ccOk,
			accysPossible: modInfo.anyAccysPossible(),
			category: getDeviceCategory(devType),
			imgSrc: clxGetImageSource(DeviceCategory.Module, modInfo.imgName),
			imgSize: { width: slotSize.width * modInfo.slotsUsed, height: slotSize.height },
			movable: true,
			slotIdx: -1,
			slotID: -1,
			slotsUsed: modInfo.slotsUsed,
			selected: false,
			dragStatus: ModuleDragStatus.NA,
			parent: undefined,
			isController: commDtlsRoles.isController,
			isComm: commDtlsRoles.isComm,
			isConnClient: commDtlsRoles.isConnClient,
			spclLocalConns: specialLocalConns,
			slotFiller: modInfo.isSlotFiller(),
			isFPD: false,
			isInterconnect: false,
			isBankExp: false,
			//okInAnyChassisType: modData.okInAnyChassisType
		};
	}

	logger.error('createModule could not create ChassisModule from: ' + catNo);
	return null;
}

//const clxGetChassisElementAtPt = (chassis: Chassis, ptLocal: Point):
//	[el: ChassisElement, slot: number] => {
//	const layout = chassis.layout as CLXLayoutInfo;
//	if (layout) {
//		const loc: LocAndSize = {
//			x: 0,
//			y: 0,
//			width: layout.size.width,
//			height: layout.size.height
//		};
//		if (isPointInLoc(ptLocal, loc)) {
//			if (chassis.ps && isPointInLoc(ptLocal, chassis.ps.loc)) {
//				return [ChassisElement.PS, NO_SLOT];
//			}
//			else {
//				for (let slot = 0; slot < layout.numSlots; slot++) {
//					if (isPointInLoc(ptLocal, layout.slotLocs[slot])) {
//						return [ChassisElement.Slot, slot];
//					}
//				}
//			}
//			return [ChassisElement.Chassis, NO_SLOT];
//		}
//		else {
//			return [ChassisElement.None, NO_SLOT];
//		}
//	}

//	throw new Error('getCLXChassisDtlAtPoint - invalid layout!');
//}

enum DragEnvCompat {
	Compat = 'Compat',
	NotCompat = 'NotCompate',
	CompatWithSwap = 'CompatWithSwap'
}

const _getBestPossibleDropStatus = (envCompat: DragEnvCompat): DropStatus => {
	switch (envCompat) {
		case DragEnvCompat.Compat:
			return DropStatus.DropOk;

		case DragEnvCompat.CompatWithSwap:
			return DropStatus.DropOkAfterSwap;

		default:
			return DropStatus.NoDrop;
	}
}

const _getDragToChassisEnvCompat = (chassis: Chassis, dragInfo: DragDeviceInfo)
	: DragEnvCompat => {

	// Start by resetting (removing) any residual
	// swapTo catalot number.
	dragInfo.swapToCatNo = undefined;

	// If the chassis and device are env-compatible...
	if (isDeviceCompatibleWithChassis(dragInfo.dragMod, chassis)) {
		// Then return compatible.
		return DragEnvCompat.Compat;
	}
	else {
		// Chassis and device do NOT match environment-wise.
		// Call a helper to give us the catalog number of a
		// version of what we're dragging that DOES match
		// environment-wise.
		const altDevCat = getDragDeviceAlternate(dragInfo, chassis);

		// If there IS such an alternate available...
		if (altDevCat) {
			//logger.log('Compatible with alt: ' + alt);

			// Store it in the drag info as the
			// 'swap-to' catalog number, and return
			// the 'Ok-if-you-swap-modules' response.
			dragInfo.swapToCatNo = altDevCat;
			return DragEnvCompat.CompatWithSwap;
		}
	}

	return DragEnvCompat.NotCompat;
}


// Note: Assumes env-compatability already checked.
const _isDragOkToSlot = (
	chassis: Chassis,
	dragInfo: DragDeviceInfo,
	slotNum: number
): boolean => {

	// See if the slot has a current occupant.
	const occupant = getModuleInSlot(chassis, slotNum);

	// If so (and it's NOT a slot filler)...
	if (occupant && !isSlotFillerCatNo(PlatformCLX, occupant.catNo)) {
		// If the drag is a 'move' (NOT a copy)...
		if (!dragInfo.copy) {

			// Then the move will still be ok IFF
			// the occupant is the module that's
			// currently being dragged. It's allowed
			// to go back to its current location.
			return (dragInfo.dragMod === occupant);
		}
	}
	else {
		// The slot is empty, so the
		// drop SHOULD be OK.
		return true;
	}

	// If we get here, the drag should NOT
	// be allowed to the specified slot.
	return false;
}

const clxGetChassisDropStatus = (
	chassis: Chassis,
	dragInfo: DragDeviceInfo,
	touchEl: ChassisElement,
	slotNum: number
): DropStatus => {

	// Disqualify any platform mismatch.
	if (dragInfo.dragMod.platform !== chassis.platform) {
		return DropStatus.NoDrop;
	}

	// Get general environmental matching compatibility
	// between the chassis and the thing being dragged.
	// This will give us a yes, no, or yes-IF respons.
	const envCompat = _getDragToChassisEnvCompat(chassis, dragInfo);

	// Call another helper to tell us what the BEST possible
	// drop status we COULD have given the result of the
	// compatibility test.
	const bestDropStat = _getBestPossibleDropStatus(envCompat);

	// If the best isn't NoDrop...
	if (bestDropStat !== DropStatus.NoDrop) {

		if (touchEl === ChassisElement.Slot) {
			return (_isDragOkToSlot(chassis, dragInfo, slotNum))
				? bestDropStat
				: DropStatus.NoDrop;
		}
		else {
			return DropStatus.NoDrop;
		}
	}
	return DropStatus.NoDrop;
}


export const clxAddModuleAtSlot = (
	chassis: Chassis,
	catNo: string,
	slotNum: number,
	envMismatchOk = false
): boolean => {

	// Special case handling.
	// Regardless of what we were told, we'll AUTOMATICALLY
	// assume that an environment mismatch IS OK in the special
	// case where we're trying to add a STANDARD slot filler
	// to a conformally coated chassis. 
	if (chassis.conformal && (catNo === CLXSlotFiller.Standard)) {
		envMismatchOk = true;
	}

	// Create the requested module.
	const module = createModule(chassis.platform, catNo);

	// If we can, and if we have a suitable environment
	// match between it and our chassis...
	if (module &&
		(envMismatchOk || isDeviceCompatibleWithChassis(module,chassis))) {

		// BEFORE making any change, call our helper
		// to handle any related undo/redo work for us.
		chassisChanging(chassis);

		// THEN, make the actual changes.
		chassis.modules[slotNum] = module;
		module.parent = chassis;
		module.slotIdx = slotNum;
		module.slotID = slotNum;
		addRequiredAccys(module);
		return true;
	}
	return false;
}

export const clxReplacePowerSupply = (chassis: Chassis, psCat: string): boolean => {

	// We'll assume that we WILL be replacing the 
	// power supply on the requested chassis.
	// BEFORE making any change, call our helper
	// to handle any related undo/redo work for us.
	chassisChanging(chassis);

	// Get our chassis layout.
	const layout = chassis.layout as CLXLayoutInfo;

	// See how wide the OLD power supply image was.
	const oldPSWidth = chassis.ps ? chassis.ps.imgSize.width : 0;

	// Make a new PS and store in the layout.
	chassis.ps = _clxMakeChassisPS(psCat);
	if (chassis.ps) {
		chassis.ps.parent = chassis;
	}

	// See how wide the new one is.
	const newPSWidth = chassis.ps ? chassis.ps.imgSize.width : 0;

	// Determine how much the width changed,
	// old to new.
	const xShift = newPSWidth - oldPSWidth;

	// If any change...
	if (xShift !== 0) {
		// Shift everything ELSE (non-PS) accordingly.
		_offsetNonPSCLXLayoutEls(layout, { x: xShift, y: 0 });

		// Adjust the total width.
		layout.size.width += xShift;

		// And update the overall project layout,
		// just in case our width change affected
		// the overall layout extent.
		const proj = getProjectFromChassis(chassis);
		if (proj) {
			updateRackLayout(proj.content);
		}
	}

	// Return success.
	return true;
}

// Note: assumes DropOK status already confirmed.
const _clxDropDragModuleOnChassis = (
	chassis: Chassis,
	dragInfo: DragDeviceInfo,
	slotNum: number
): boolean => {
	// Get the module currently occupying the
	// specified slot, if anything.
	const slotOccupant = getModuleInSlot(chassis, slotNum);

	// If we HAVE a current occupant (that's not a slot filler)...
	if (slotOccupant && !isSlotFillerCatNo(PlatformCLX, slotOccupant.catNo)) {
		// Sanity check. The only valid case (where we have
		// a DropOK status) is the drag was a MOVE (not a copy)
		// and the module being moved (ref'd in the drag info)
		// is the SAME module as the current occupant. If we
		// don't have that case, error out.
		if (dragInfo.copy || (dragInfo.dragMod !== slotOccupant)) {
			throw new Error('Error in _clxDropDragModuleOnChassis!');
		}

		// Drag was a move to the module's original
		// location. Return false indicating that we
		// didn't actually do anything, but this is
		// a valid case.
		return false;
	}

	// If we're supposed to COPY
	// to the specified location...
	if (dragInfo.copy) {

		// For this case, we'll just use the catalog
		// number specified to create and add a new
		// module at the specified location.
		// Choose the cat based on whether we have a 
		// swap situation or not.
		const modCat = (dragInfo.dropStatus === DropStatus.DropOkAfterSwap)
			? dragInfo.swapToCatNo
			: dragInfo.dragMod.catNo;

		if (modCat) {
			return addModuleAtSlot(chassis, modCat, slotNum);
		}
		else {
			unexpectedError('Unexpected Error in _clxDropDragModuleOnChassis!');
			return false;
		}
	}
	else {
		// Not a copy.
		// If we have a clean drop status...
		if (dragInfo.dropStatus === DropStatus.DropOk) {
			// BEFORE making any change, call our helper
			// to handle any related undo/redo work for us.
			chassisChanging(chassis);

			// We're MOVING the existing module
			// to the specified location. Start
			// by detaching it from its old location.
			detachModule(dragInfo.dragMod);

			// Then insert it into the specified chassis
			// at the specified slot location.
			chassis.modules[slotNum] = dragInfo.dragMod;

			// Record that slot location in the module itself.
			dragInfo.dragMod.slotIdx = slotNum;
			dragInfo.dragMod.slotID = slotNum;

			// And set its new parent chassis.
			dragInfo.dragMod.parent = chassis;

			return true;
		}
		else if (dragInfo.dropStatus === DropStatus.DropOkAfterSwap) {
			// We're simulating a move, but we need to swap the actual
			// module on the way. We SHOULD have a swap id. If so...
			if (dragInfo.swapToCatNo) {

				// We'll first use the swap cat to add a new module
				// to the destination chassis and slot. If that works...
				if (addModuleAtSlot(chassis, dragInfo.swapToCatNo, slotNum)) {
					// Use our detach function to effectively remove
					// the dragged module from its original location.
					detachModule(dragInfo.dragMod);

					// Success.
					return true;
				}
			}
		}

		unexpectedError('Unexpected ERROR in _clxDropDragModuleOnChassis');
		return false;
	}
}

const clxDropDragDeviceOnChassis = (
	chassis: Chassis,
	dragInfo: DragDeviceInfo,
	touchEl: ChassisElement,
	slotNum: number
): DropResult => {

	// If the drag info's status tells us that we
	// should be able to actually drop something...
	if ((dragInfo.dropStatus !== DropStatus.NoDrop) &&
		(chassis.platform === dragInfo.dragMod.platform) &&
		(touchEl === ChassisElement.Slot)) {

		if (touchEl === ChassisElement.Slot) {
			// Then call a more specific module
			// drop helper and return its result.
			if (_clxDropDragModuleOnChassis(chassis,
				dragInfo, slotNum)) {
				return DropResult.DropSuccess;
			}
			else {
				return DropResult.DropFailed;
			}
		}
	}
	throw new Error('Unexpected call to dropDragDeviceOnCLXChassis?');
}

export const clxGetModuleLayout = (chassis: Chassis, maxSlotIdx: number)
	: SlotModulePair[] => {

	// Construct an array to hold data pairs, each of which
	// will contain a slot number and a module catalog number.
	const dataPairs = new Array<SlotModulePair>();

	// Determine which slots contain modules in the chassis (if any).
	const [modSlots,] = getModuleSlotBreakdown(chassis.modules);

	// Compare how many slots are in use to our new size.
	const excessMods = modSlots.length - maxSlotIdx - 1;

	// If we have too many...
	if (excessMods > 0) {
		// Then we MUST have some slot fillers that we
		// can trim. Otherwise, we shouldn't be in here.
		let numTrimmed = 0;

		// Get the last idx from our modSlots array.
		const lastIdx = modSlots.length - 1;

		// Walk the array backwards. For each entry...
		for (let idx = lastIdx; idx >= 0; idx--) {

			// Get the slot (that SHOULD contain a module).
			const slot = modSlots[idx];

			// Get the module at that slot.
			const modAtSlot = chassis.modules[slot];

			// If we can...
			if (modAtSlot) {
				// And it's a slot filler...
				if (modAtSlot.slotFiller) {

					// Remove the module from the
					// original chassis. 
					detachModule(modAtSlot);

					// Remove that idx from our slots array.
					modSlots.splice(idx, 1);

					// Add 1 to our num-trimmed accum.
					numTrimmed += 1;

					// If we trimmed enough, stop trimming.
					if (numTrimmed >= excessMods) {
						break;
					}
				}
			}
			else {
				throw new Error('Unexpected - no module at slot?');
			}
		}

		// Final sanity check. We shouldn't STILL
		// have too many modules for our new size.
		if (modSlots.length > (maxSlotIdx + 1)) {
			throw new Error('Unexpected - slot filler trim FAILED');
		}
	}

	// See how many we have in use.
	const slotsInUse = modSlots.length;

	// If any...
	if (slotsInUse > 0) {
		// Walk each entry in the modSlots array. For each...
		for (let i = 0; i < slotsInUse; i++) {
			// Get the slot number.
			const slot = modSlots[i];

			// Then get the module located in the
			// slot of our chassis. We SHOULD ALWAYS
			// get a module here.
			const module = chassis.modules[slot];

			// If we do...
			if (module) {
				// Add a data pair using the current slot and module itself.
				dataPairs.push({ slot: slot, module: module });

				// Then 'detach' the module
				// from its parent chassis.
				detachModule(module);
			}
			else {
				// Unexpected.
				throw new Error('Unexpected ERROR 1 in clxGetModuleLayout!');
			}
		}

		// Now see how many data pairs we collected.
		const numPairs = dataPairs.length;

		// That number SHOULD match the number of
		// slots that were reported to contain modules.
		// If so...
		if (numPairs === slotsInUse) {

			// Walk the pairs backwards, from right to left. For each...
			for (let pairIdx = numPairs - 1; pairIdx >= 0; pairIdx--) {

				// Get the pair.
				const pair = dataPairs[pairIdx]

				// If the current slot is to the RIGHT of
				// our max slot position allowed...
				if (pair.slot > maxSlotIdx) {
					// Then change the slot to the
					// max slot idx available.
					pair.slot = maxSlotIdx;

					// Decrement the max slot, since
					// we just used it.
					maxSlotIdx--;
				}
				else {
					// The current pair's slot is good
					// as is, as should any other pairs
					// to its left. Nothing left to do.
					break;
				}
			}
		}
		else {
			// Unexpected.
			throw new Error('Unexpected ERROR 2 in clxGetModuleLayout!');
		}
	}

	// Return our final array of pairs.
	return dataPairs;
}

export const clxChangeChassisDefinition = (
	chassis: Chassis,
	newChassisCatNo: string,
	psCatNo: string | undefined) => {

	// Before doing anything, confirm that the incoming
	// chassis doesn't have any modules.
	const [modSlots,] = getModuleSlotBreakdown(chassis.modules);
	if (modSlots.length > 0) {
		throw new Error('ERROR: clxChangeChassisDefinition request on chassis with modules!');
	}

	// Make a NEW template for the given cat.
	const template = _getChassisTemplateForCat(newChassisCatNo);

	// If we can...
	if (template) {

		// If we were given a PS catalog number, then
		// it SHOULD have ALREADY been pre-qualified as
		// appropriate for the new chassis. If we weren't
		// given one, we'll use a suitable default.
		const psCat = (psCatNo && psCatNo.length)
			? psCatNo
			: getDfltCLXPowerSupply(template.extendedTemp,
				template.conformal, template.totalSlots);

		// Confirm that PS is actually valid. If so...
		if (_isPSValidForEnv(psCat, template.extendedTemp, template.conformal)) {
			// Get a description for the new chassis.
			//const prodData = getCLXProdData(newChassisCatNo);
			//const newDesc = prodData ? prodData.Description : '<Description ERROR>';

			const newDesc = getProductDescription(PlatformCLX, newChassisCatNo);

			// Next, create a NEW layout using the template
			// and the same base pt we got from the OLD layout.
			const [layout, ps] = _makeCLXLayoutInfo(template, psCat);

			// Finally, update the OLD chassis' to match
			// our new layout, etc. propertie
			chassis.catNo = newChassisCatNo;
			chassis.description = newDesc;
			chassis.extendedTemp = template.extendedTemp;
			chassis.conformal = template.conformal;
			chassis.layout = layout;
			chassis.ps = ps;
			chassis.modules = new Array<ChassisModule | undefined>(layout.numSlots);

			if (ps) {
				ps.parent = chassis;
			}
		}
		else {
			throw new Error('clxChangeChassisDefinition with invalid or incompatible PS!');
		}
	}
	else {
		throw new Error('clxChangeChassisDefinition FAILED to get template for:' + newChassisCatNo);
	}
}

const dropCLXChassisModule = (
	chassis: Chassis,
	mod: ChassisModule,
	element: ChassisElement,
	slotNum: number
): boolean => {

	// If the element provided is a slot..
	if (element === ChassisElement.Slot) {

		// And we were given a valid slot number...
		if (isValidSlotNumber(chassis, slotNum)) {

			// See if that slot already has a module in it.
			const occupant = chassis.modules[slotNum];

			// If so, we can't add the requested module there.
			if (occupant) {
				return false;
			}
			else {
				// We WILL be adding the module, but
				// BEFORE making any change, call our helper
				// to handle any related undo/redo work for us.
				chassisChanging(chassis);

				mod.dragStatus = ModuleDragStatus.NA;
				mod.slotIdx = slotNum;
				mod.slotID = slotNum;
				mod.parent = chassis;
				chassis.modules[slotNum] = mod;
				return true;
			}
		}
		else {
			throw new Error('dropCLXChassisModule - invalid chassis or slot number!');
		}
	}
	return false;
}

const _isModuleValidInSlot = (
	chassis: Chassis,
	module: ChassisModule,
	slotNum: number
): boolean => {
	if (isValidSlotNumber(chassis, slotNum)) {
		const occupant = chassis.modules[slotNum];
		if (!occupant) {
			return true;
		}
	}

	return false;
}


const clxAddModuleToChassis = (
	chassis: Chassis,
	module: ChassisModule
): boolean => {

	//if (_doDeviceAndChassisEnvsMatch(chassis, module.xt, module.conformal)) {
	if (isDeviceCompatibleWithChassis(module, chassis)) {
		const [, emptySlots] = getModuleSlotBreakdown(chassis.modules);
		for (let idx = 0; idx < emptySlots.length; idx++) {
			const slotNum = emptySlots[idx];
			if (_isModuleValidInSlot(chassis, module, slotNum)) {
				if (dropCLXChassisModule(chassis, module, ChassisElement.Slot, slotNum)) {
					return true;
				}
				else {
					unexpectedError('dropCLXChassisModule FAILED after slot validatation?');
				}
			}
		}
	}

	return false;
}

const clxAddNewModuleAtSlot = (chassis: Chassis, slot: number): boolean => {
	// Warning suppression
	chassis;
	slot;

	// Function is not currently used, and will be ultimately
	// replaced with a modal-based approach of some sort.
	// Just Fail for now.
	return false;
}

const clxDeleteModuleAtSlot = (chassis: Chassis, slot: number): boolean => {

	// If slot number is in valid range...
	if ((slot >= 0) && (slot < chassis.modules.length)) {

		// Get the module at the specified slot.
		const module = chassis.modules[slot];

		// If there is one...
		if (module) {

			// BEFORE making any change, call our helper
			// to handle any related undo/redo work for us.
			chassisChanging(chassis);

			// Make sure the module doesn't have
			// a ref back to our chassis.
			module.parent = undefined;

			// Set the associated modules entry to null.
			chassis.modules[slot] = undefined;

			// Success.
			return true;
		}
		else {
			// Unexpected
			unexpectedError('Request to delete module at slot that has no module in it!');
		}
	}
	else {
		// Unexpected
		unexpectedError('Request to delete module at INVALID slot number!');
	}

	// Fail. We didn't delete anything.
	return false;
}

// Now standard behavior.
//const clxOnChassisLoaded = (chassis: Chassis): void => {
//	const [modSlots,] = getModuleSlotBreakdown(chassis.modules);
//	modSlots.forEach(slotNum => {
//		const mod = chassis.modules[slotNum];
//		if (mod) {
//			mod.parent = chassis;
//		}
//		else {
//			throw new Error('clxOnChassisLoaded - modSlot without module!');
//		}
//	});

//	if (chassis.ps) {
//		chassis.ps.parent = chassis;
//	}
//}

//const clxDuplicateChassis = (
//	chassis: Chassis,
//	insertCopyAt: number
//): Chassis | null => {

//	// Get the associated project.
//	const project = getProjectFromChassis(chassis);

//	// If we can...
//	if (project) {

//		// Add a new chassis, using the catNos of
//		// the old and of its power supply.
//		const dup = addChassis(project, PlatformCLX, chassis.catNo,
//			insertCopyAt, chassis.ps?.catNo);

//		// If the add worked...
//		if (dup) {

//			// orig here is still the same as chassis,
//			// but 'orig' makes the following easier to
//			// follow.
//			const orig = chassis;

//			// Our dup chassis should now have whatever
//			// REQUIRED accys were appropriate added to
//			// the chassis itself and its dup'd power supply.
//			// However, we want it to be a real duplicate,
//			// so we'll copy accys from orig to dup here for
//			// both of those elements.
//			copyAccys(orig, dup);
//			if (orig.ps && dup.ps) {
//				copyAccys(orig.ps, dup.ps);
//			}

//			// Start optimistic regarding
//			// module duplication results.
//			let addFailed = false;

//			// Walk all modules in the orig chassis. For each...
//			for (let slot = 0; slot < orig.modules.length; slot++) {

//				// Get the original module (if there IS one at the slot).
//				const origMod = orig.modules[slot];

//				// If so...
//				if (origMod) {

//					// Using the original's id, add the same module
//					// to the dup chassis at the same slot. Note that
//					// we'll use the clx-specific add function here
//					// because we DON'T want required accys added to
//					// the new module. Note also that we say that
//					// we'll allow the add even if the module doesn't
//					// match the chassis env-rating-wise when we're
//					// duplicating. If the add works...
//					if (addModuleAtSlot(dup, origMod.catNo, slot, true)) {

//						// Get the new module from the dup chassis.
//						const newMod = dup.modules[slot];

//						// If we can...
//						if (newMod) {
//							// Then COPY whatever accys found on
//							// the original module to the new one.
//							copyAccys(origMod, newMod);
//						}
//						else {
//							addFailed = true;
//						}
//					}
//					else {
//						addFailed = true;
//					}
//				}
//			}

//			// Deal with any problems encountered above
//			// during the module dup'ing process.
//			if (addFailed) {
//				unexpectedError('ERROR: clxDuplicateChassis could not duplicate all modules!');
//			}

//			return dup;
//		}
//	}
//	return null;
//}


export const clxStdSlotOptions = [4, 7, 10, 13, 17];
export const clxConformalSlotOptions = [4, 7, 10, 13, 17];
export const clxXTSlotOptions = [7, 10];

const clxGetChassisRenderer = (): React.FC<ChassisCompProps> => {
	return CLXChassisComp;
}


// Returns the catalog number of the slot filler to use in
// the requested environment, and a flag inidicating whether
// the filler should be allowed regardless of environment type.
export const clxGetSlotFillerInfo = (chassis: Chassis): [cat: string, envMismatchOk: boolean] => {

	// Determine the environment rating for the chassis.
	const envRating = getEnvRatingFromSpec(chassis.extendedTemp, chassis.conformal);

	// Use the env-specific filler if we have one.
	// There is no specific filler for Conformally Coated
	// cases, so we use the standard, but then say it
	// can be used even though ITS type and the desired
	// type don't actually match.
	switch (envRating) {
		case EnvRating.Standard:
			return [CLXSlotFiller.Standard, false];

		case EnvRating.ConformalCoated:
			return [CLXSlotFiller.Standard, true];

		case EnvRating.ExtTemperature:
			return [CLXSlotFiller.XT, false];

		default:
			throw new Error('Unexpected env rating in _getSlotFillerCat');
	}
}

const clxGetActionBtnInfo = (action: LayoutActionType,
	rack: Rack, slotNum: number): ActBtnInfo => {

	const layout = rack.chassis.layout as CLXLayoutInfo;
	const slotLoc = { ...layout.slotLocs[slotNum] };
	offsetLoc(slotLoc, rack.ptOrg);
	const pt = getLocCenter(slotLoc);
	pt.y += DfltActBtnSpecs.height;
	return {
		action: action,
		chassis: rack.chassis,
		slot: slotNum,
		ctrPt: pt
	};
}

export const BaseClxRedundancyCat = '1756-RM2';

export const isClxRedundancyMod = (module: ChassisModule | undefined): boolean => {
	if (module) {
		return module.catNo.startsWith(BaseClxRedundancyCat);
	}
	return false;
}

export const addClxRedundancyMod = (chassis: Chassis) => {
    for (let slotIdx = 0; slotIdx < chassis.modules.length; slotIdx++) {
        const mod = chassis.modules[slotIdx];
        if (!mod || mod.slotFiller) {
            let redModCat = BaseClxRedundancyCat;
			if (chassis.extendedTemp) {
                redModCat += 'XT';
            }
            else if (chassis.conformal) {
                redModCat += 'K';
            }
            if (!addModuleAtSlot(chassis, redModCat, slotIdx, false)) {
                throw new Error('addModuleAtSlot call in addRedundancyMod FAILED');
            }
            return;
        }
    }
    throw new Error('No empty slots found in addRedundancyMod');
}

const clxGetPowerDetails = (chassis: Chassis):
	[supplied: PowerBreakdown, consumed: PowerBreakdown] => {

	// Get breakdown of power supplied by our power supply.
	const pwrSuppplied = chassis.ps
		? getPowerSuppliedBy(chassis.ps)
		: getEmptyPowerBreakdown()

	// Set up breakdown for consumed power. Start empty.
	const pwrConsumed = getEmptyPowerBreakdown();

	// Find slots with module occupants.
	const [modSlots,] = getModuleSlotBreakdown(chassis.modules);

	// If any...
	if (modSlots.length) {

		// For each one...
		for (let idx = 0; idx < modSlots.length; idx++) {

			// Get the slot number.
			const slot = modSlots[idx];

			// And the module at that slot.
			const mod = getModuleInSlot(chassis, slot);

			// We SHOULD ALWAYS be able to, since we got
			// the slot from the module slot breakdown function.
			// If we can...
			if (mod) {
				// Get consumption info for the module.
				const modConsumption = getPowerConsumedBy(mod);

				// Then add that to our total consumption.
				pwrConsumed.mAat5V += modConsumption.mAat5V;
				pwrConsumed.mAat24V += modConsumption.mAat24V;
				pwrConsumed.mWatt += modConsumption.mWatt;
			}
			else {
				// Unexpected error.
				throw new Error('Module expected!');
			}
		}
	}

	// Return our final breakdowns.
	return [pwrSuppplied, pwrConsumed];
}

const clxGetMaxNewModules = (chassis: Chassis, restriction: ModuleSlotRestriction, treatSlotFillerAsEmptySlot: boolean): number => {

	// CLX doesn't distinguish any modules as
	// being restricted to certain slots only. 
	restriction;

	let maxNew = 0;

	const slots = chassis.modules.length;
	for (let slot = 0; slot < slots; slot++) {
		const mod = chassis.modules[slot];
		if (mod == null) {
			maxNew += 1;
		}
		else if (treatSlotFillerAsEmptySlot && mod.slotFiller) {
			maxNew += 1;
		}
	}

	return maxNew;
}

const clxGetDefaultChassisName = (chassis: Chassis): string => {
	if (chassis.catNo.length) {
		const lastCatDash = chassis.catNo.lastIndexOf('-');
		const postDash = chassis.catNo.substring(lastCatDash + 1);
		return ('Chassis_' + postDash);
	}
	else {
		return 'Chassis';
	}
}

const clxGetSizeDetailValue = (chassis: Chassis): string => {
	const layout = chassis.layout as CLXLayoutInfo;
	return layout.numSlots + ' Slot (' + chassis.catNo + ')';
}

const clxGetPSDetailValue = (chassis: Chassis): string => {
	if (chassis.ps) {
		return chassis.ps.description + ' (' + chassis.ps.catNo + ')';
	}
	else {
		return '<none>';
	}
}

const clxGetChassisDetails = (chassis: Chassis): DetailGroup[] => {
	const grps = new Array<DetailGroup>();

	grps.push(makeSingletonDtlGroup('size', clxGetSizeDetailValue(chassis)));

	grps.push(makeSingletonDtlGroup('Environmental Ratings',
		getDevEnvRatingDtlText(chassis)));

	grps.push(makeSingletonDtlGroup('Power Supply',
		clxGetPSDetailValue(chassis)));

	const [pwrSupplied, pwrConsumed] = getPowerDetails(chassis);
	grps.push(makePowerUsageGrp(chassis.platform, pwrSupplied, pwrConsumed));

	// Get dimensions for our chassis itself,
	// not including the power supply.
	const chassisDims = getDeviceDimensions(chassis);

	// We SHOULD always have a power supply. If we do...
	if (chassis.ps) {
		// Get dims for IT.
		const psDims = getDeviceDimensions(chassis.ps);

		// If we get a width back...
		if (psDims.width > 0) {
			// Add it to our chassis width.
			chassisDims.width += psDims.width;

			// Then subtract a known 26mm to account
			// for whatever ends up as 'overlap' when
			// the PS and chassis are connected. The
			// final width then should line up with
			// complete width as reported in RA chassis
			// documentation. (Note: the 26mm adjustment
			// is ALSO done in IAB 1.0)
			chassisDims.width -= 26;

			// Just doing the above, we still end up
			// 1 mm shorter than RA docs when we have
			// a SLIM power supply. If so, we'll just 
			// add the mm to line up.
			if (psDims.width < 100) {
				chassisDims.width += 1;
			}
		}
	}

	grps.push(makeDimensionsGrp(chassisDims));

	if (chassis.accys && (chassis.accys.length > 0)) {
		grps.push(makeAccysGroup(PlatformCLX, chassis.accys));
	}

	return grps;
}

export const getDevEnvRatingDtlText = (device: GraphicalDevice): string => {
	if (device.extendedTemp) {
		return device.conformal
			? 'Extended Temperature and Conformally Coated'
			: 'Extended Temperature';
	}
	else if (device.conformal) {
		return 'Conformally Coated';
	}
	return 'N/A';
}

const clxGetDeviceDetails = (device: SelectableDevice): DetailGroup[] => {
	const grps = new Array<DetailGroup>();

	grps.push(makeSingletonDtlGroup('Description', device.description));
	grps.push(makeSingletonDtlGroup('Environmental Ratings', getDevEnvRatingDtlText(device)));

	const pwrInfo = getPowerBreakdown(PlatformCLX, device.catNo);
	grps.push(
		makeDevicePowerGrp(
			device.platform,
			(device.category === DeviceCategory.PS),
			pwrInfo)
	);

	const devDims = getDeviceDimensions(device);
	grps.push(makeDimensionsGrp(devDims));

	if (device.accys && (device.accys.length > 0)) {
		grps.push(makeAccysGroup(PlatformCLX, device.accys));
	}

	return grps;
}

export const getLargerChassis = (catNo: string):
    [newCatNo: string, newSlots: number] => {

    switch (catNo) {
        case '1756-A4':
            return ['1756-A7', 7];

        case '1756-A7':
            return ['1756-A10', 10];

        case '1756-A10':
            return ['1756-A13', 13];

        case '1756-A13':
            return ['1756-A17', 17];

        case '1756-A4K':
            return ['1756-A7K', 7];

        case '1756-A7K':
            return ['1756-A10K', 10];

        case '1756-A10K':
            return ['1756-A13K', 13];

        case '1756-A13K':
            return ['1756-A17K', 17];

        case '1756-A7XT':
            return ['1756-A10XT', 10];

        default:
            return ['', 0];
    }
}

const clxGetChassisRendInfo = (chassis: Chassis): RendInfo[] => {
	const layout = chassis.layout as CLXLayoutInfo;

	// Create an array of objects to hold
	// info about EACH image component.
	const imgEls = new Array<RendPortion>();

	if (chassis.ps) {
		imgEls.push({
			loc: chassis.ps.loc,
			image: 'CSA' + chassis.ps.imgSrc
		});
	}

	layout.grpSepLocs.forEach(grpSep => {
		imgEls.push({
			loc: grpSep,
			image: 'CSA' + layout.grpSepImgSrc
		});
	});

	layout.slotSepLocs.forEach(slotSep => {
		imgEls.push({
			loc: slotSep,
			image: 'CSA' + layout.slotSepImgSrc
		});
	});

	for (let slotIdx = 0; slotIdx < layout.numSlots; slotIdx++) {
		const module = chassis.modules[slotIdx];
		if (module) {
			imgEls.push({
				loc: layout.slotLocs[slotIdx],
				image: getImgNameFromPath(module.imgSrc)
			});
		}
		else {
			imgEls.push({
				loc: layout.slotLocs[slotIdx],
				image: 'CSA' + layout.emptySlotImgSrc
			});
		}
	}

	imgEls.push({
		loc: layout.rightCapLoc,
		image: 'CSA' + layout.rightCapImgSrc
	});

	const scaleAdj = 1.0 / StageUnitsPerMM;

	imgEls.forEach(el => {
		el.loc = getScaledLoc(el.loc, scaleAdj);
	})

	const scaledSize = scaleSize(layout.size, scaleAdj, true);

	const rendInfo = new Array<RendInfo>();
	rendInfo.push({
		size: scaledSize,
		els: imgEls
	});

	return rendInfo;
}

const registerGeneralPlatformInfo = () => {

	const impl: GeneralImplSpec = {
		//getNumSlots: clxGetNumSlots, < - now uses default
		//getSlotID: clxGetSlotID, <- now uses default
		//onChassisLoaded: clxOnChassisLoaded, < - now uses default
		//getChassisElementAtPt: clxGetChassisElementAtPt, < - now uses default
		//duplicateChassis: clxDuplicateChassis, < - now uses default
		//getSlotLocation: clxGetSlotLocation, < - now uses default
		platform: PlatformCLX,
		imageScaleFactor: _clxImageScaleFactor,
		createChassis: clxCreateChassis,
		replaceChassisPowerSupply: clxReplacePowerSupply,
		configureChassis: clxConfigureChassis,
		getChassisSlotUsage: clxGetChassisSlotUsage,
		createModule: clxCreateModule,
		addModuleToChassis: clxAddModuleToChassis,
		addNewModuleAtSlot: clxAddNewModuleAtSlot,
		addModuleAtSlot: clxAddModuleAtSlot,
		deleteModuleAtSlot: clxDeleteModuleAtSlot,
		getImageSource: clxGetImageSource,
		getChassisDropStatus: clxGetChassisDropStatus,
		dropDragDeviceOnChassis: clxDropDragDeviceOnChassis,
		getChassisRenderer: clxGetChassisRenderer,
		getChassisDetails: clxGetChassisDetails,
		getDeviceDetails: clxGetDeviceDetails,
		getPowerDetails: clxGetPowerDetails,
		getSlotFillerInfo: clxGetSlotFillerInfo,
		getActBtnInfo: clxGetActionBtnInfo,
		getMaxNewModules: clxGetMaxNewModules,
		getDefaultChassisName: clxGetDefaultChassisName,
		addModulesToChassis: clxAddModulesToChassis,
		getChassisRendInfo: clxGetChassisRendInfo
	};

	RegisterGeneralImpl(impl);
}

const registerHWGenImpl = () => {
	const impl: HardwareGenImplSpec = {
		platform: PlatformCLX,
		createHardwareFromSettings: genCreateHardwareFromSettings,
		getHardwareGenErrors: getCreateHWFromSettingsErrors,
		prepLocAttrHardwareForGen: clxPrepareLocAttrHardwareForGen,
		getLocAttrInfoForChassisEdit: clxGetLocAttrInfoForChassisEdit,
	}

	RegisterHardwareGenImpl(impl);
}

export const initCLXPlatform = (): boolean => {

	registerGeneralPlatformInfo();
	registerHWGenImpl();
	RegisterCLXCheckerImpl();
	RegisterCLXEngDataImpl();

	return true;
}

