import { CheckerImplementation, RegisterCheckerImpl } from "../../../implementation/ImplChecker";
import { PlatformCpLX } from "../../PlatformConstants";
import { cplxExecuteAutoFix, cplxSwapCtrlForSafetyCtrl } from "./CpLXAutoFix";
import { snapDoChassisPowerCheck, snapDoGeneralCheck } from "../../snap/SnapChecker";


export const RegisterCpLXCheckerImpl = () => {
    const cplxImpl: CheckerImplementation = {
        platform: PlatformCpLX,
        doGeneralCheck: snapDoGeneralCheck,
        doChassisPowerCheck: snapDoChassisPowerCheck,
        swapCtlrForSafetyCtrl: cplxSwapCtrlForSafetyCtrl,
        executeAutoFix: cplxExecuteAutoFix,
    };
    RegisterCheckerImpl(cplxImpl);
}
