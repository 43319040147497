import Switch from '@mui/material/Switch';
import { FormControlLabel, Tooltip } from '@mui/material';
import React from 'react';
import './Settings.scss';
import '../styles/Meteor.scss'
import { ProjectSetting, SettingOption } from '../types/SettingsTypes';
import { HdrErrState, SettingHeader } from './SettingHeader';

// Component takes an array of 2 (TWO!) choices. The switch
// is a Toggle Button. When 'checked' (ie true) the second
// value in the choice array is selected. Otherwise, 'not
// checked' (ie false) will be the first choice in the array.
interface Props {
	setting: ProjectSetting;
	valueChanged: (setting: ProjectSetting, newTextValue: SettingOption | undefined) => void;
	tip?:string;
	disabled?:boolean;
}


export const SwitchSetting = (props: Props) => {
	
	// Place any hooks above here!.
	if (props.setting.visible === false || props.setting.options.length !== 2)
		return null;

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newVal = (event.target.checked ? props.setting.options[1] : props.setting.options[0]);
		props.valueChanged(props.setting, newVal);
	};

	let state = HdrErrState.info;
	if (props.setting.msgWarningOrError) {
		state = (props.setting.valid ? HdrErrState.warning : HdrErrState.error);
	}

	return (
		<div>
			<div className="setting-control-container">
				<SettingHeader
					message={props.setting.msgWarningOrError ? props.setting.msgWarningOrError : props.setting.defaultMessage}
					state={state}
					title={props.setting.name}
					key={props.setting.name} />
				<div className="setting-control-body">
					<Switch
						className="setting-switch-control"
						id={props.setting.id}
						checked={props.setting.selectedOption === props.setting.options[0] ? false : true}
						color="success"
						onChange={handleChange}
					/>
					<label className="a-type__caption">{props.setting.options[1].display}</label>
				</div>
			</div>
		</div>
	);
}


// Unlike the Switch Component, the 'No-Header' version will
// ignore {props.setting.name} and set the label of the switch
// to the value in props.setting.choices[1] (ie the checked value).
export const SwitchSettingNoHeader = (props: Props) => {

	const {tip,disabled} = props;
	// Place any hooks above here!. If we are NOT visible
	// or the switch/toggle does NOT have exactly 2 options...
	if (props.setting.visible === false || props.setting.options.length !== 2)
		return null;

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newVal = (event.target.checked ? props.setting.options[1] : props.setting.options[0]);
		props.valueChanged(props.setting, newVal);
	};

    return (
        <div className="setting-control-container">
            <Tooltip title={tip}>
                <span>
                    <FormControlLabel
                        disabled={disabled}
                        className="a-type__caption"
                        control={
                            <Switch
                                className="a-type__caption"
                                checked={props.setting.selectedOption.display === props.setting.options[0].display ? false : true}
                                color="info"
                                onChange={handleChange}
                            />}
                        labelPlacement="start"
                        label={props.setting.options[1].display}
                        sx={{ gap: 1 }}
                    />
                </span>
            </Tooltip>
        </div>
    );
}