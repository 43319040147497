import { Point } from "../types/SizeAndPosTypes";
import { getOffsetPtArray } from "./GeneralHelpers";


export interface CableSpec {
    pathPts: Point[];
    color: string;
    width: number;
    radius: number;
}

export interface FancyPathProps {
    pts: Point[];
    stroke: string;
    strokeWidth: number;
    maxRadius: number;
    opacity?: number;
    shadowOffsetX?: number;
    shadowOffsetY?: number;
    shadowColor?: string;
    shadowOpacity?: number;
}


export const makeEmptyCableSpec = (color: string, width: number, radius: number): CableSpec => {
    return {
        pathPts: new Array<Point>(),
        color: color,
        width: width,
        radius: radius
    }    
}

export const offsetCableSpec = (spec: CableSpec, ptOffset: Point) => {
    spec.pathPts.forEach(pt => {
        pt.x += ptOffset.x;
        pt.y += ptOffset.y;
    });
}


export const getCableProps = (
    spec: CableSpec,
    ptOrg: Point,
    opacity?: number
): FancyPathProps => {
    return {
        pts: getOffsetPtArray(spec.pathPts, ptOrg),
        stroke: spec.color,
        strokeWidth: spec.width,
        maxRadius: spec.radius,
        opacity: opacity
    }
}
