import { Point, Size } from "./SizeAndPosTypes";

// At FULL scale (1.0), our stage resolution
// would effectively be 96 'dots' per inch,
// where one stage unit === one 'dot'.
// Using that, we can calculate how many units we'd
// have per screen millimeter (1" === 25.4mm).
// And AGAIN, that would be at FULL SCALE (1.0).

// The resulting number here is about 3.8.
export const StageUnitsPerMM = 3.8;

export const DfltStageBaseScale = 0.4;
export const DfltStageZoomBy = 1.1;
export const DfltStageZoomRangeFactor = 3;
export const DfltSelRectMargin = 14;

export interface StageScaleInfo {
    baseScale: number;
    zoomBy: number;
    zoomRangeFactor: number;
}

// Scale and size properties used on the Stage itself.
export interface StageSizeProps {
    scaleX: number;
    scaleY: number;
    x: number;
    y: number;
    width: number;
    height: number;
}

export interface StageSpacingDtls {
    scaleFactor: number;
    leftMargin: number;
    rightMargin: number;
    topMargin: number;
    bottomMargin: number;
    verticalSep: number;
    chassisLblFontSize: number;
    chassisLblCharWidth: number;
    chassisToLblGap: number;
    chassisLblIconSize: number;
    leftIconGap: number;
    selRectMargin: number;
}

// Stage and wrapping div-related info we keep for each client.
export interface StageInfo {

    // Ref to the outer div wrapping a Konva Stage.
    // The outer div is where any scrolling takes place
    // when the Stage is larger than the div's client area.
    wrapDiv: HTMLDivElement | undefined;

    // The original base scale provided when registered
    baseScale: number;

    // Adjusment factor for each zoom in or out.
    zoomByFactor: number;

    // Minimum and maximum values we'll use
    // to bracket our allowable scale range.
    minScale: number;
    maxScale: number;

    // X and Y that should be used when asked for
    // Stage Props. Note: At most times, the origin
    // should match, but be opposite to our wrapper div's
    // scroll position. For example, if the scroll pos
    // was 100,50, then the origin would be -100,-50.
    // Scroll positions are never negative, so our origin
    // ordinates will generally be either negative or 0.
    // DURING wheel zooming, however, and ONLY during
    // wheel zooming, we'll tweak these to get our
    // stage to move around as needed WITHOUT having
    // to actually change our div's scroll position.
    origin: Point;

    // Full (unscaled) extent of content the LAST
    // time our getStageSizeProps function was called.
    contentExtent: Point;

    // Virtual size (width and height) of our stage the LAST
    // time our getStageSizeProps function was called.
    // Note: this size is generally the scaled version
    // of the extent, but is set LARGER if/when the
    // view's client size is larger in either or both
    // directions to take up AT LEAST the size of the
    // view.
    virtualStageSize: Size;

    // Visible size (width and height) of our stage the
    // LAST time anyone checked. This is essentially
    // the same size as the wrapper div's client area
    // minus a bit of buffer space on the right and 
    // below to make scrollbars more visible.
    visibleStageSize: Size;

    // The scale being CURRENTLY USED
    // by the Stage component.
    currentScale: number;

    // Content spacing info in stage units, adjusted
    // using baseScale so the resulting distances are
    // consistent regardless of platform's scale specs.
    spacingDtls: StageSpacingDtls;

    // Used ONLY when a new chassis is added.
    scrollToBtmPending: boolean;
}

