// Note: The I/O Type/Feature IDs are
// set to the Eng Data JSON prop name.
// ALSO NOTE: These are what WE KNOW ABOUT!!!!
// Types/Features that we do NOT know about
// can be in the Json and they WILL function
// just fine from the Design Page, but any
// type/feature specific handling will not
// be able to be done.
export const enum IO_Id {
    // Types
    AI = 'IOpoints_AI',
    AO = 'IOpoints_AO',
    DI = 'IOpoints_DI',
    DO = 'IOpoints_DO',
    SAI = 'IOpoints_SAI',
    SAO = 'IOpoints_SAO',
    SDI = 'IOpoints_SDI',
    SDO = 'IOpoints_SDO',
    RO = 'IOpoints_RO',
    SRO = 'IOpoints_SRO',
    RTD = 'IOpoints_RTD',
    Therm = 'IOpoints_THERM',
    HSC = 'IOpoints_HSC',
    PTO = 'IOpoints_PTO',
    PWM = 'IOpoints_PWM',
    // Features
    DC = 'IOFeature_DC',
    HSDC = 'IOFeature_HSDC',
    SEV = 'IOFeature_SEV',
    HART = 'IOFeature_HART',
    Isol = 'IOFeature_Isol',
    Diag = 'IOFeature_Diag',
    Fused = 'IOFeature_Fused'
}

// Display Names - not IDs!
export const enum IO_Txt {
    // Types
    AI = 'Analog Input',
    AO = 'Analog Output',
    DI = 'Digital Input',
    DO = 'Digital Output',
    SAI = 'Safety Analog Input',
    SAO = 'Safety Analog Output',
    SDI = 'Safety Digital Input',
    SDO = 'Safety Digital Output',
    RO = 'Relay Output',
    SRO = 'Safety Relay Output',
    RTD = 'RTD',
    Therm = 'Thermocouple',
    HSC = 'High Speed Counter',
    PTO = 'PTO',
    PWM = 'PWM',
    // Features
    DC = 'Current',
    HSDC = 'High Speed Current',
    SEV = 'Voltage',
    HART = 'HART',
    Isol = 'Isolated',
    Diag = 'Diagnostic',
    Fused = 'Electronically Fused'
}

export enum IO_Bit {
    // Bitset to characterized I/O.
    None = 0x00000000,
    Input = 0x00000001,
    Output = 0x00000002,
    Digital = 0x00000004,
    Analog = 0x00000008,
    HartIO = 0x00000010,
    Conformal = 0x00000020,
    ExTemp = 0x00000040,
    ThermoCouple = 0x00000080,
    RTD = 0x00000100,
    //SelfCfg = 0x00000200, // Repurpose as needed.
    //Combo = 0x00000400,
    HiSpdCurrent = 0x00000800,
    RO = 0x00001000,
    HiSpdCounter = 0x00002000,
    PTO = 0x00004000,
    PWM = 0x00008000,
    Isolated = 0x00010000,
    SafetyIO = 0x00020000,
    Diagnostic = 0x00040000,
    ElectronicFused = 0x00080000,
    AnalogCurrent = 0x00100000,
    AnalogVoltage = 0x00200000,
    Exclude = 0x80000000, // Bit to indicate 'exclude set bits' (ie ~bits) - used in point entry filtering.
}

export const enum IO_Mask {
    // Types
    AI = IO_Bit.Analog | IO_Bit.Input,
    AO = IO_Bit.Analog | IO_Bit.Output,
    DI = IO_Bit.Digital | IO_Bit.Input,
    DO = IO_Bit.Digital | IO_Bit.Output,
    SAI = IO_Bit.SafetyIO | IO_Bit.Analog | IO_Bit.Input,
    SAO = IO_Bit.SafetyIO | IO_Bit.Analog | IO_Bit.Output,
    SDI = IO_Bit.SafetyIO | IO_Bit.Digital | IO_Bit.Input,
    SDO = IO_Bit.SafetyIO | IO_Bit.Digital | IO_Bit.Output,
    RO = IO_Bit.RO,
    SRO = IO_Bit.SafetyIO | IO_Bit.RO,
    RTD = IO_Bit.RTD,
    Therm = IO_Bit.ThermoCouple,
    HSC = IO_Bit.HiSpdCounter,
    PTO = IO_Bit.PTO,
    PWM = IO_Bit.PWM,
    // Features
    DC = IO_Bit.AnalogCurrent,
    HSDC = IO_Bit.HiSpdCurrent,
    SEV = IO_Bit.AnalogVoltage,
    HART = IO_Bit.HartIO,
    Isol = IO_Bit.Isolated,
    Diag = IO_Bit.Diagnostic,
    Fused = IO_Bit.ElectronicFused,

    // Composite/General Masks
    Point_BasicTypes = (IO_Bit.Input | IO_Bit.Output | IO_Bit.Analog | IO_Bit.Digital),
    Point_Types = (IO_Bit.Digital | IO_Bit.Output |
        IO_Bit.Analog | IO_Bit.Input | IO_Bit.SafetyIO |
        IO_Bit.RO | IO_Bit.RTD | IO_Bit.ThermoCouple |
        IO_Bit.HiSpdCounter | IO_Bit.PTO | IO_Bit.PWM),
    Point_Features = (IO_Bit.HartIO |
        IO_Bit.HiSpdCurrent | IO_Bit.Isolated | IO_Bit.ElectronicFused |
        IO_Bit.Diagnostic | IO_Bit.AnalogCurrent | IO_Bit.AnalogVoltage),
    Point_EnvRating = (IO_Bit.Conformal | IO_Bit.ExTemp),
}

export const txtFeatureANY = 'Any';
export const defPointEntryType = IO_Id.AI;

export enum Vltg_Bit {
    none = 0x00000000,
    vac12 = 0x00000001,
    vac24 = 0x00000002,
    vac48 = 0x00000004,
    vac120 = 0x00000008,
    vac240 = 0x00000010,
    vdc5 = 0x00000020,
    vdc12 = 0x00000040,
    vdc24 = 0x00000080,
    vdc48 = 0x00000100,
    vdc60 = 0x00000200,
    vdc125 = 0x00000400,
    rng0_10V = 0x00000800,
    rng0_20mA = 0x00001000,
    rng4_20V = 0x00002000,
}

export interface IOFilterMasks {
    includeMask: number;
    excludeMask: number;
    voltageMask: number;
}

export const areIOFilterMasksEqual = (a: IOFilterMasks, b: IOFilterMasks): boolean => {
    return (a.includeMask === b.includeMask && a.excludeMask === b.excludeMask);
}

export interface IOPointInfo {
    input: number;
    output: number;
    selfCfg: number;
    types: IOTypeInfo[];
}

export interface IOTypeInfo {
    name: string;
    value: number;
}

export interface IOTypeOrFeatureInfo {
    display: string;
    value: string;
}

export interface IOTypeAndFeatureInfo {
    type: string;
    value: number;
    features: IOTypeInfo[];
}

export const createIOTypeAndFeatureInfo = (typeName = '', typeBits = 0): IOTypeAndFeatureInfo => {
    return {
        type: typeName,
        value: typeBits,
        features: [],
    };
}

// Condensed summary of point entries - UserSelectedPoints/ModuleSelection
export interface UserSelectedPoints {
    // 2024.7.9 New Type/Feature Model
    type: string; 
    feature: string; 
    catValidated?: string;

    userPointCount: number;  // Requested points
    typeID: number; 
}

export interface IOModuleSelection {
    selectedPoints: UserSelectedPoints[];
    quantity: number;  // Module Count
    catalog: string;
}

