import { PlatformCLX } from "../../PlatformConstants";
import {
	addChassis,
	chassisChanged,
	getEnvRatingFromSpec,
    updateAllChassis
} from "../../../model/ChassisProject";
import { Chassis, ChassisProject, EnvRating } from "../../../types/ProjectTypes";
import { chkrModule } from "../../../util/Checker";
import { contentChanging, suspendUndoSnapshots } from "../../../util/UndoRedo";
import { chkrCLXChassisComponents } from "./CLXChecker";
import { EngInfoCommModule } from "../../../engData/EngineeringInfo";
import { addModuleAtSlot, deleteModuleAtSlot } from "../../../implementation/ImplGeneral";
import { getCommModuleEngInfo } from "../../../util/EngInfoHelp";
import { getUniqueChassisName } from "../../../util/CheckerAutoFixProjHelpers";


export const clxGetValidScannerFromChassis = (
	chassisChkrComps: chkrCLXChassisComponents,
	envRating: EnvRating): [string, EngInfoCommModule | undefined] => {

	// Anything in the commModComps array has already
	// been pre-certified as a valid communication
	// module that has a comm role (EtherNet).
	// Get the first one (if any.)
	const commChkrMod = (chassisChkrComps.commModComps.length > 0)
		? chassisChkrComps.commModComps[0]
		: undefined;

	// Get eng info for it.
	let commModEngInfo = commChkrMod
		? commChkrMod.engInfo as EngInfoCommModule
		: undefined;

	// Get its catalog number (if we have one).
	let commModCatNo = commModEngInfo ? commModEngInfo.catNo : '';

	// If we don't have a catalog number at
	// this point, choose one based on Env.
	if (!commModCatNo) {
		// For now hardcode EN2TRs.
		switch (envRating) {
			case EnvRating.Standard:
				commModCatNo = '1756-EN2TR';
				break;
			case EnvRating.ConformalCoated:
				commModCatNo = '1756-EN2TRK';
				break;
			case EnvRating.ExtTemperature:
				commModCatNo = '1756-EN2TRXT';
				break;
		}
		commModEngInfo = getCommModuleEngInfo(PlatformCLX, commModCatNo);
	}

	if (!commModEngInfo) {
		alert('Unable to find suitable communication module to correct issue.');
		return ['', undefined];
	}

	return [commModCatNo, commModEngInfo];
}

export const clxMoveModule = (targetChassis: Chassis, sourceSlot: number, destinationSlot: number, project: ChassisProject): boolean => {
	const chassisSlotCount = targetChassis.modules.length;

	// range check
	if (sourceSlot < 0 || sourceSlot > chassisSlotCount ||
		destinationSlot < 0 || destinationSlot > chassisSlotCount) {
		alert('Unable to Auto-correct issue.');
		return false;
	}

	if (sourceSlot === destinationSlot)
		return true;

	// Remove the Mod To Move from the module array.
	let ModToMove = targetChassis.modules[sourceSlot];
	const modToMoveCatalog = (ModToMove ? ModToMove.catNo : '');

	// Take a snap shot.
	contentChanging(project.content);
	// Suspend snapshots
	const wasSuspended = suspendUndoSnapshots(true);

	// Delete the module to move if we have one.
	if (modToMoveCatalog) {
		deleteModuleAtSlot(targetChassis, sourceSlot);
	}

	ModToMove = undefined;
	if (sourceSlot > destinationSlot) {
		for (let idx = destinationSlot; idx < chassisSlotCount; ++idx) {
			// store the module we are displacing.
			const displacedMod = targetChassis.modules[idx];
			// Add the module to move to the array.
			targetChassis.modules[idx] = ModToMove;
			// If the displaced module is Undefined (i.e. an empty slot)...
			if (displacedMod == null)
				// End the shift here.
				break;
			else
				ModToMove = displacedMod;
		}
	}
	else {
		for (let idx = destinationSlot; idx >= 0; --idx) {
			// store the module we are displacing.
			const displacedMod = targetChassis.modules[idx];
			// Add the module to move to the array.
			targetChassis.modules[idx] = ModToMove;
			// If the displaced module is Undefined (i.e. an empty slot)...
			if (displacedMod == null)
				// End the shift here.
				break;
			else
				ModToMove = displacedMod;
		}
	}

	// Add our module to move to the vacated slot.
	if (modToMoveCatalog)
		addModuleAtSlot(targetChassis, modToMoveCatalog, destinationSlot, true);

	// Update all slot locations
	let slotLoc = 0;
	targetChassis.modules.forEach((mod) => {
		if (mod) {
			mod.slotIdx = slotLoc;
			slotLoc += mod.slotsUsed;
			if (mod.slotsUsed > 1) {
				console.log('clxMoveModule encounterd a multi-slot module!!!!');
			}
		}
		else {
			// Empty Slot...
			slotLoc++;
		}
	});

	suspendUndoSnapshots(wasSuspended);

	return true;
}

export interface CLXMigrateChassisSpecs {
	chassisChkrComps: chkrCLXChassisComponents;
	chrkModsToMigrate: chkrModule[];
	sourceChassis: Chassis;
	project: ChassisProject;
	idxSourceChassis?: number;
	scannerCatalog?: string;
	psuCatalog?: string;
	rmCatalog?: string;
}


export const getNewCLXMigrateChassisSpecs = (chassisChkrComps: chkrCLXChassisComponents,
	chrkModsToMigrate: chkrModule[],
	sourceChassis: Chassis,
	project: ChassisProject): CLXMigrateChassisSpecs => {
	return {
		chassisChkrComps: chassisChkrComps,
		chrkModsToMigrate: chrkModsToMigrate,
		sourceChassis: sourceChassis,
		project: project,
	};
}


export const clxMigrateModulesToNewChassis = (
	specs: CLXMigrateChassisSpecs,
	autoAddScanner = true,
	emptyMigrateOk = false
): [success: boolean, newChassis: Chassis | null] => {

	if (!emptyMigrateOk) {
		if (specs.chrkModsToMigrate.length === 0)
			return [true, null];
	}

	// Get our base components needed to create a new chassis to migrate the modules.
	if (!specs.psuCatalog) {
		if (specs.sourceChassis.ps)
			specs.psuCatalog = specs.sourceChassis.ps.catNo;
		else {
			alert(`The Chassis (${specs.sourceChassis.name}) does NOT have a Power Supply. Please correct this issue first then try again.`);
			return [false, null];
		}
	}

	if (autoAddScanner && !specs.scannerCatalog) {
		const envRating = getEnvRatingFromSpec(specs.sourceChassis.extendedTemp, specs.sourceChassis.conformal);
		const [scannerCatNo,] = clxGetValidScannerFromChassis(specs.chassisChkrComps, envRating);
		specs.scannerCatalog = scannerCatNo;
	}

	// Take a snapshot for undo/redo
	contentChanging(specs.project.content);
	// Suspend any more snapshots while we add our chassis.
	const snapshotsWereSuspended = suspendUndoSnapshots(true);

	// We should have all the components we need to create the new chassis.
	const idxToInsertAt: number | undefined = (specs.idxSourceChassis == null ? undefined : specs.idxSourceChassis + 1);
	const newChassis = addChassis(specs.project, PlatformCLX, specs.sourceChassis.catNo, idxToInsertAt, specs.psuCatalog);
	if (newChassis == null) {
		alert('Failed to create new chassis. Issue cannot be corrected.');
		suspendUndoSnapshots(snapshotsWereSuspended);
		return [false, null];
	}
	else {
		// Find a new chassis name based on the source chassis.
		newChassis.name = getUniqueChassisName(`Split_${specs.sourceChassis.name}`, specs.project);

		// Migrate modules and complete the chassis.
		let successNewChassisMigration = true;
		let idxSlot = 0;

		// Add the scanner.
		if (specs.scannerCatalog)
			addModuleAtSlot(newChassis, specs.scannerCatalog, idxSlot++, true);

		specs.chrkModsToMigrate.forEach((modMigrate) => {
			// If we can add the I/O module...
			if (addModuleAtSlot(newChassis, modMigrate.module.catNo, idxSlot++, true)) {
				// Delete the source module from the Target Chassis.
				if (deleteModuleAtSlot(specs.sourceChassis, modMigrate.module.slotIdx) === false)
					successNewChassisMigration = false;
			}
			else {
				successNewChassisMigration = false;
			}
		});

		if (!successNewChassisMigration) {
			alert(`Some modules either could NOT be moved to ${newChassis.name} or deleted from ${specs.sourceChassis.name}.`);
		}

		suspendUndoSnapshots(snapshotsWereSuspended);

		// Call chassisChanged() to ensure proper rendering.
		chassisChanged(specs.sourceChassis);

		updateAllChassis(specs.project.content);

		return [true, newChassis];
	}
}


export const getMatchingModComps = (modComps: chkrModule[], prefix: string): chkrModule[] => {
	const matches = new Array<chkrModule>();

	modComps.forEach(modComp => {
		if (modComp.module.catNo.startsWith(prefix)) {
			matches.push(modComp);
		}
	});

	return matches;
}