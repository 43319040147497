import { convertAppValToGuidedSelAttrOptID, convertGuidedSelAttrToAppVal } from "../../implementation/ImplHardwareGen";
import { ModalRequestSpec } from "../../modals/ModalHelp";
import { getLocAttributeSetting, refreshLocAttrInfoSelectionArray, validateLocAttrInfo } from "../../model/GuidedSelection";
import { ChoiceInfo, ChoiceInfoType, GuidedSelChoiceInfo, MessageCategory, StatusLevel } from "../../types/MessageTypes";
import { LocAttributeInfo } from "../../types/ProjectTypes";
import { ProjectSetting, SettingOption } from "../../types/SettingsTypes";
import { CLXChassisCfgData } from "../clx/model/CLXGuidedSelection";
import { SnapChassisCfgData } from "../snap/snapGuidedSelection";
import { PlatformCLX, PlatformCpLX, PlatformFlex, PlatformFlexHA, PlatformMicro } from "../PlatformConstants";
import { cfgDlgGetGroupSelection, cfgDlgGetMinSlotsNeeded, CfgDlgMapMsgInfo, CfgDlgTipCallback, cfgDlgUpdateMessages, ConfigChassisCBData, createPanelMessageForGS } from "./ChassisConfig";
import { cfgDlgUpdatePSGroup } from "./ChassisConfigPower";
import { getMicroModules } from "../../util/EngInfoHelp";


export const cfgDlgInitializeLocAttrInfoFromChassis = (attrInfo: LocAttributeInfo, cfgChassisData: CLXChassisCfgData | SnapChassisCfgData) => {
	switch (attrInfo.platform) {
		case PlatformCLX:
			initializeCLXLocAttrInfoFromChassis(attrInfo, cfgChassisData as CLXChassisCfgData);
			break;
		case PlatformCpLX:
		case PlatformFlex:
		case PlatformMicro:
		case PlatformFlexHA:
			initializeSnapLocAttrInfoFromChassis(attrInfo, cfgChassisData as SnapChassisCfgData);
			break;
	}
}

const initializeSnapLocAttrInfoFromChassis = (attrInfo: LocAttributeInfo, chassisSels: SnapChassisCfgData) => {
	const erOptId = convertAppValToGuidedSelAttrOptID(attrInfo.platform, 'ER', chassisSels.envType);
	const er = getLocAttributeSetting(attrInfo, 'ER');
	if (er && er.selectedOption.id !== erOptId) {
		const opt = er.options.find(opt => opt.id === erOptId);
		if (opt)
			er.selectedOption = opt;
	}

	// Set the #slots to the max (31);
	const cs = getLocAttributeSetting(attrInfo, 'CS');
	if (cs) {
		const opt = cs.options.find(opt => opt.id === '31');
		if (opt)
			cs.selectedOption = opt;
	}

	const rtbOptId = convertAppValToGuidedSelAttrOptID(attrInfo.platform, 'RTB', chassisSels.wiringType);
	const rtb = getLocAttributeSetting(attrInfo, 'RTB');
	if (rtb && rtb.selectedOption.id !== rtbOptId) {
		const opt = rtb.options.find(opt => opt.id === rtbOptId);
		if (opt)
			rtb.selectedOption = opt;
	}
    
	if(attrInfo.platform === PlatformMicro){
		const cvOptId = convertAppValToGuidedSelAttrOptID(attrInfo.platform, 'CV', chassisSels.psVoltage);
		const cv = getLocAttributeSetting(attrInfo, 'CV');
		if (cv && cv.selectedOption.id !== cvOptId) {
			const opt = cv.options.find(opt => opt.id === cvOptId);
			if (opt)
				cv.selectedOption = opt;
		}
	}
	

}

const initializeCLXLocAttrInfoFromChassis = (attrInfo: LocAttributeInfo, chassisSels: CLXChassisCfgData) => {
	const erOptId = convertAppValToGuidedSelAttrOptID(attrInfo.platform, 'ER', chassisSels.envType);
	const er = getLocAttributeSetting(attrInfo, 'ER');
	if (er && er.selectedOption.id !== erOptId) {
		const opt = er.options.find(opt => opt.id === erOptId);
		if (opt)
			er.selectedOption = opt;
	}

	const numSlots = chassisSels.numSlots.toString();
	const cs = getLocAttributeSetting(attrInfo, 'CS');
	if (cs && cs.selectedOption.id !== numSlots) {
		const opt = cs.options.find(opt => opt.id === numSlots);
		if (opt)
			cs.selectedOption = opt;
	}

	const highAvail = (chassisSels.highAvail ? 'true' : 'false');
	const caOptId = convertAppValToGuidedSelAttrOptID(attrInfo.platform, 'CA', highAvail);
	const ca = getLocAttributeSetting(attrInfo, 'CA');
	if (ca && ca.selectedOption.id !== caOptId) {
		const opt = ca.options.find(opt => opt.id === caOptId);
		if (opt)
			ca.selectedOption = opt;
	}

	const rtbOptId = convertAppValToGuidedSelAttrOptID(attrInfo.platform, 'RTB', chassisSels.wiringType);
	const rtb = getLocAttributeSetting(attrInfo, 'RTB');
	if (rtb && rtb.selectedOption.id !== rtbOptId) {
		const opt = rtb.options.find(opt => opt.id === rtbOptId);
		if (opt)
			rtb.selectedOption = opt;
	}

	const cvOptId = convertAppValToGuidedSelAttrOptID(attrInfo.platform, 'CV', chassisSels.psVoltage);
	const cv = getLocAttributeSetting(attrInfo, 'CV');
	if (cv && cv.selectedOption.id !== cvOptId) {
		const opt = cv.options.find(opt => opt.id === cvOptId);
		if (opt)
			cv.selectedOption = opt;
	}
}

// Returns flag to re-render.
export const cfgDlgGSSelectionChanged = (
	request: ModalRequestSpec,
	initializing: boolean,
	msgInfo: CfgDlgMapMsgInfo,
	tipCallback: CfgDlgTipCallback,
	gsSetting: ProjectSetting,
	newChoiceInfo: ChoiceInfo): boolean => {
	// 2024.2.16 This is for a Guided Selection setting change.
	const data = request.requestorData as ConfigChassisCBData;
	data.gsPanelMessage.length = 0;
	const newOptSelected = gsSetting.options.find(x => x.id === newChoiceInfo.id);
	// Should be there....
	if (newOptSelected) {
		// Set the new value.
		gsSetting.selectedOption = newOptSelected;

		// Is this a setting that needs validation?
		if (gsSetting.validateOnChange) {
			// Reset the error level...
			data.msgLevel = StatusLevel.Info;

			cfgDlgUpdateGuidedSelectionLocAttr(data);

			// Gather all Choice Infos from the pages.
			const infos: GuidedSelChoiceInfo[] = [];
			data.ChassisPage.forEach((info) => {
				if (info.infoType === ChoiceInfoType.GuideSel)
					infos.push(info as GuidedSelChoiceInfo);
			});

			data.PowerPage.forEach((info) => {
				if (info.infoType === ChoiceInfoType.GuideSel)
					infos.push(info as GuidedSelChoiceInfo);
			});

			let revalidateLocAttr = false;
			infos.forEach((gsInfo) => {
				// Clear any errors.
				msgInfo.delete(gsInfo.setting.id as MessageCategory);
				gsInfo.subGroup.situationalTipInfo = undefined;

				// The setting should be the same instance
				// that is in the guided selection.
				const setting = gsInfo.setting;
				gsInfo.subGroup.selectionInfo.forEach((opt) => {
					let updateOption = true;
					const isSelected = setting.selectedOption.id === opt.id;
					if (isSelected) {
						// We have the selected option. If the setting
						// is reporting an error, it is for this option.
						if (setting.msgWarningOrError) {
							// Init an 'unhandled' flag  to true.
							let unhandledError = true;

							// If this is the Controller Voltage....
							if (setting.id === 'CV') {
								// We need a valid PSU selected. This may change,
								// but for now select something valid..
								opt.disabled = true;
								updateOption = false;

								// Find an option that is NOT the current one.
								const currOpt = { ...setting.selectedOption };
								const newOpt = setting.options.find(x => x.id !== currOpt.id);

								if (newOpt) {
									// Get the Env.Rating setting.
									const erInfo = infos.find(x => x.setting.id === 'ER');
									if (erInfo) {
										// Set the 'setting' as good/valid.
										setting.selectedOption = newOpt;
										setting.valid = true;
										setting.msgWarningOrError = '';

										const msg =
											`Control Voltage has been changed from ${currOpt.display} to ${newOpt.display} ` +
											`to comply with the new Environmental Rating of ${erInfo.setting.selectedOption.display}.`;

										let msgData = createPanelMessageForGS(data, tipCallback, msg, StatusLevel.Warning, MessageCategory.Voltage);
										data.gsPanelMessage.push(...msgData.msgDetails.msgs);
										gsInfo.subGroup.situationalTipInfo = msgData.tipInfo;

										// Also add it to the Env. Rating. The ER should have been
										// set already, so what we change here should persist.
										msgData = createPanelMessageForGS(data, tipCallback, msg, StatusLevel.Warning, MessageCategory.Environment);
										data.gsPanelMessage.push(...msgData.msgDetails.msgs);
										erInfo.subGroup.situationalTipInfo = msgData.tipInfo;

										unhandledError = false;
										revalidateLocAttr = true;
									}
								}
							}
							else if (setting.id === 'CS') {
								// Auto select a good chassis size if one exists.
								// Note: The current selected option has an error.
								// Try to find a new option that meets our min slot
								// requirements that is NOT the current selection.
								// In G.Sel., the options array will contain all valid
								// option plus the selected BAD option. 
								const minSlots = cfgDlgGetMinSlotsNeeded(data);
								const currOpt = setting.selectedOption;
								const newOpt = setting.options.find(opt => opt.id !== currOpt.id && Number(opt.id) >= minSlots);
								if (newOpt) {
									// Set the 'setting' as good/valid.
									setting.selectedOption = newOpt;
									setting.msgWarningOrError = '';
									setting.valid = true;

									const idxBadOpt = setting.options.findIndex(opt => opt.id === currOpt.id);
									if (idxBadOpt >= 0)
										setting.options.splice(idxBadOpt, 1);

									unhandledError = false;
									revalidateLocAttr = true;
								}
							}

							if (unhandledError) {
								// Get the new state...
								const state = (setting.valid ? StatusLevel.Warning : StatusLevel.Error);

								const msgData = createPanelMessageForGS(data, tipCallback, setting.msgWarningOrError, state, setting.id as MessageCategory);
								data.gsPanelMessage.push(...msgData.msgDetails.msgs);
								gsInfo.subGroup.situationalTipInfo = msgData.tipInfo;

								opt.disabled = true;
								updateOption = false;
							}
						}
					}

					if (updateOption) {
						// If the opt is in the Options for the
						// setting, then it is valid.
						const valid = setting.options.some(x => x.id === opt.id);
						opt.disabled = !valid;
					}
				})
			});

			if (revalidateLocAttr)
				cfgDlgUpdateGuidedSelectionLocAttr(data);
		}

		// Do we need to update the PSUs...
		if (gsSetting.id === 'ER' || gsSetting.id === 'CV')
			cfgDlgUpdatePSGroup(data, tipCallback);

		cfgDlgUpdateMessages(data, msgInfo, tipCallback);
	}
	else {
		return false;
	}


	if (request.buttonInfo && request.fullRender) {
		// If Guided Selection OR PSU Selection has problems,
		// disable the button. Otherwise, enable it.
		let disableSaveOrAddButton = true;
		// If we have a Platform with PSU selections...
		if (data.cfgAttrInfo.platform === PlatformCLX)
			disableSaveOrAddButton = (data.cfgAttrInfo.hasRuleViolation || !cfgDlgGetGroupSelection(data.psGrp));
		else
			disableSaveOrAddButton = (data.cfgAttrInfo.hasRuleViolation === true);

		const btnSave = request.buttonInfo[0];
		btnSave.disabled = (disableSaveOrAddButton ? true : undefined);
		// Only re-render AFTER initialization.
		if (initializing === false)
			request.fullRender();
	}

	return initializing === false;
}

export const cfgDlgUpdateGuidedSelectionLocAttr = (data: ConfigChassisCBData) => {
	switch (data.cfgAttrInfo.platform) {
		case PlatformCLX:
			updateCLXGuidedSelectionLocAttr(data);
			break;
		case PlatformCpLX:
		case PlatformFlex:
			updateSnapGuidedSelectionLocAttr(data);
			break;
		case PlatformMicro:
			updatemicroGuidedSelectionLocAttr(data);
			break;
		case PlatformFlexHA: // TODO_FLEXHA
			break;
	}
}


//Applicable for micro 800 only
const updatemicroGuidedSelectionLocAttr = (data: ConfigChassisCBData) => {
	// Validate the attr info.
	validateLocAttrInfo(data.cfgAttrInfo);
	// Refresh the selection array (cfgAttrInfo.arrAttributeNameToValue)
	refreshLocAttrInfoSelectionArray(data.cfgAttrInfo);

	// If this is the first time call, initial
	// GS selections will be an empty array.
	if (!data.initialGSSelections)
		data.initialGSSelections = { ...data.cfgAttrInfo.arrAttributeNameToValue }

	const er = getLocAttributeSetting(data.cfgAttrInfo, 'ER');
		if (er) {
			// const idxOptXT = er.options.findIndex(x => x.id === 'XT');
			const chassisBUCat = data.chassis?.bu?.catNo
			const buEngData = chassisBUCat && getMicroModules(PlatformMicro,chassisBUCat);
			
			if (buEngData){
				if(buEngData.altCC.length<=0){
					const idxOptXT = er.options.findIndex(x => x.id === 'CC');
					er.options.splice(idxOptXT, 1)
				}
			}
		}
}


const updateCLXGuidedSelectionLocAttr = (data: ConfigChassisCBData) => {
	// Validate the attr info.
	validateLocAttrInfo(data.cfgAttrInfo);

	if (data.chassis && data.origTotalSlotsInUse > 3) {
		// Get the minimum slots needed.
		const trueSlotsNeeded = cfgDlgGetMinSlotsNeeded(data);

		// Modify the Loc Attr for our purposes.
		// Start by getting the Env Rating - If we
		// need more than 10 slots, we cannot have
		// an XT Chassis.
		if (trueSlotsNeeded > 10) {
			const er = getLocAttributeSetting(data.cfgAttrInfo, 'ER');
			if (er) {
				const idxOptXT = er.options.findIndex(x => x.id === 'XT');
				if (idxOptXT >= 0)
					er.options.splice(idxOptXT, 1);
			}
		}

		// Cleanup the allowed chassis sizes.
		const cs = getLocAttributeSetting(data.cfgAttrInfo, 'CS');
		if (cs) {
			let selectedSizeTooSmall = false;
			const sizeOptions: SettingOption[] = [];
			cs.options.forEach((opt) => {
				const optSize = Number(opt.id);
				if (!isNaN(optSize) && optSize >= trueSlotsNeeded) {
					sizeOptions.push(opt);
					// If the selected size WAS too small...
					if (selectedSizeTooSmall) {
						// Set the selection to this valid size
						cs.selectedOption = opt;
						// Clear our flag.
						selectedSizeTooSmall = false;
					}

				}
				else if (cs.selectedOption.id === opt.id) {
					// The selected size is less than
					// what we need.
					selectedSizeTooSmall = true;
				}
			});

			cs.options = sizeOptions;
		}

		// Do we need to disable Hign Avail (ie addition
		// of an RM module).
		const ca = getLocAttributeSetting(data.cfgAttrInfo, 'CA');
		if (ca) {
			const isHighAval = convertGuidedSelAttrToAppVal(data.cfgAttrInfo.platform, ca.id, ca.selectedOption.id, true);
			const isXT = data.cfgAttrInfo.arrAttributeNameToValue.some(x => x.attrID === 'ER' && x.optionID === 'XT');
			let stripHiAvailOpt = false;
			// If we are NOT adding an RM module...
			if (isHighAval === false) {
				// If we are at the Chassis' module capacity, which
				// is 17 for non-XT and 10 for XT Env. Rating...
				if (trueSlotsNeeded === 17)
					stripHiAvailOpt = true;
				else if (isXT && trueSlotsNeeded === 10)
					stripHiAvailOpt = true;
			}

			if (stripHiAvailOpt) {
				const idxOptHA = ca.options.findIndex(x => x.id === 'Red');
				if (idxOptHA)
					ca.options.splice(idxOptHA, 1);
			}
		}
	}

	// Refresh the selection array (cfgAttrInfo.arrAttributeNameToValue)
	refreshLocAttrInfoSelectionArray(data.cfgAttrInfo);

	// If this is the first time call, initial
	// GS selections will be an empty array.
	if (!data.initialGSSelections)
		data.initialGSSelections = { ...data.cfgAttrInfo.arrAttributeNameToValue }
}

const updateSnapGuidedSelectionLocAttr = (data: ConfigChassisCBData) => {
	// Validate the attr info.
	validateLocAttrInfo(data.cfgAttrInfo);
	
	// Refresh the selection array (cfgAttrInfo.arrAttributeNameToValue)
	refreshLocAttrInfoSelectionArray(data.cfgAttrInfo);

	// If this is the first time call, initial
	// GS selections will be an empty array.
	if (!data.initialGSSelections)
		data.initialGSSelections = { ...data.cfgAttrInfo.arrAttributeNameToValue }
}
