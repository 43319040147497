
export const getIntValFromString = (s: string | undefined): number => {
    if (s) {
        return parseInt(s);
    }
    else {
        return 0;
    }
}

export const isTrueStringValue = (s: string): boolean => {
    return (s === '1');
}

export const getCharCountInString = (str: string, char: string): [count: number, firstIndex: number] => {
    // Keep it simple - usually the fastest. 
    if (char.length > 1)
        throw new Error("getCharCountInString(): String instead of single character passed in.");

    if (char.length === 0)
        return [0, -1];

    let firstIndex = -1;
    let count = 0;
    const len = str.length;
    for (let idx = 0; idx < len; ++idx) {
        if (str[idx] === char) {
            if (firstIndex === -1)
                firstIndex = idx;
            count++;
        }
    }

    return [count, firstIndex];
}