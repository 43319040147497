import { IConfigSpec, initializeConfig } from "platform-services";

// NOTE: platform-services now provides all config
// and identity related interfaces and functionality.

const config: IConfigSpec = {
    ...initializeConfig(),
};

export default config;
