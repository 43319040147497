import { getIntValFromString } from "../util/DataValueHelp";
import { EngInfoIOModule, IEngDataIOModule, IODetails } from "./EngineeringInfo";
import { FamilyCpLX, FamilyFlex, FamilyFlexHA, PlatformFlexHA } from "../platforms/PlatformConstants";
import { IO_Bit } from "../types/IOModuleTypes";


export interface IConnClientData extends IEngDataIOModule {
    CIP_Used: string;
    Conn_DefaultRPI: string;
    Conn_SizeTO: string;
    Conn_SizeOT: string;
}

const _familySupportsAggConns = (family: string) => {
    switch (family) {
        case FamilyCpLX:
        case FamilyFlex:
            return true;

        default:
            return false;
    }
}

export const usesConcurrentConns = ( platformOrFamily : string ) => {
    switch ( platformOrFamily ) {
        case PlatformFlexHA:
        case FamilyFlexHA:
            return true;

        default:
            return false;
    }
}

const _extractConnDataFrom = (data: IConnClientData):
    [
        connsUsed: number,
        defaultRPI: number,
        sizeTO: number,
        sizeOT: number] => {

    const connsUsed = getIntValFromString(data.CIP_Used);
    const defaultRPI = getIntValFromString(data.Conn_DefaultRPI);
    const sizeTO = getIntValFromString(data.Conn_SizeTO);
    const sizeOT = getIntValFromString(data.Conn_SizeOT);

    return [connsUsed, defaultRPI, sizeTO, sizeOT];
}

const _calcMemoryReqdFrom = (ptDtls: IODetails): number => {
    if (ptDtls.digital) {
        return ptDtls.totalPts * 400;
    }
    if (ptDtls.analog) {
        return ptDtls.totalPts * 2600;
    }
    return 0;
}

const _getRPIInfoBasedOnType = (ptDtls: IODetails):
    [minRPI: number, maxRPI: number, defaultRPI: number] => {
    if (ptDtls.safety) {
        return [2, 500, 20];
    }
    if (ptDtls.digital) {
        return [0.2, 750, 5];
    }
    return [0.2, 750, 80];
}


export interface ConnClientRole {
    rackOptOk: boolean;
    digital: boolean;
    safetyIO: boolean;
    inputModule: boolean;
    advancedCtrlReqd: boolean;
    appMemReqd: number;

    numCIPConns: number;
    connSizeOT: number;
    connSizeTO: number;
    minRPI: number;
    maxRPI: number;
    defaultRPI: number;
    canBeAggregated: boolean;
}

export const establishIOModConnClient = (modInfo: EngInfoIOModule, data: IEngDataIOModule) => {

    // Cast incoming data to extended version
    // that should contain relevant connection info.
    const clientData = data as IConnClientData;

    // Get values from data. Sizes SHOULD be there,
    // by cip conns and default RPI may be empty.
    const [cipConnsData, dfltRPIFromData, sizeTO, sizeOT] = _extractConnDataFrom(clientData);

    // Call a helper to give us some RPI range and
    // default info based on the type of our module.
    const [minRPI, maxRPI, dfltRPIForType] = _getRPIInfoBasedOnType(modInfo.ptDtls);

    // If we HAD a default from the source data, use
    // it. Otherwise, we'll use a type-based number.
    const defaultRPI = (dfltRPIFromData > 0) ? dfltRPIFromData : dfltRPIForType;

    // The CIP conns number is only used when we have a 
    // direct connection, and direct conns always use AT
    // LEAST 1. Use 1 if we didn't get a data value.
    const connsDirect = Math.max(cipConnsData, 1);

    // See if aggregration COULD be possible.
    const aggFamily = _familySupportsAggConns(modInfo.family);
    const concurrent = usesConcurrentConns( modInfo.family );

    // Get basic info.
    const digital = modInfo.ptDtls.digital;
    const safety = modInfo.ptDtls.safety;
    const inputMod = ((modInfo.ptDtls.totalInputs > 0) &&
        (modInfo.ptDtls.totalOutputs === 0));

    // Calculate memory requirements using
    // the method used in IAB 1.0, since that's
    // NOT so far in the data we have available.
    const appMemReqd = _calcMemoryReqdFrom(modInfo.ptDtls);

    // Determine if our connection can participate
    // in a Rack Optimized connection. It has to be
    // non-agg, non-safety, digital, 1 conn, and NOT
    // either diagnostic or fused to participate.
    let rackOptOk = false;
    if ( !concurrent && !aggFamily && digital && !safety && (connsDirect === 1)) {
        // Features interface removed. Using the mask.
        // True when Diag/ElectFused bits NOT set.
        rackOptOk = (modInfo.ioMask & (IO_Bit.Diagnostic | IO_Bit.ElectronicFused)) === 0;
    }

    // Determine if it can be aggregated with other connections.
    const canBeAggregated = (aggFamily && !safety && (connsDirect === 1));

    // Create the role.
    const role: ConnClientRole = {
        rackOptOk: rackOptOk,
        digital: digital,
        safetyIO: safety,
        inputModule: inputMod,
        advancedCtrlReqd: modInfo.isAdvancedCtlrReqd(),
        appMemReqd: appMemReqd,
        numCIPConns: connsDirect,
        connSizeOT: sizeOT,
        connSizeTO: sizeTO,
        minRPI: minRPI,
        maxRPI: maxRPI,
        defaultRPI: defaultRPI,
        canBeAggregated: canBeAggregated
    }

    // And add it to the mod info.
    modInfo.connClientRole = role;
    modInfo.isConnClient = true;
}

