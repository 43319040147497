import {  PlatformMicro } from "../../PlatformConstants"
import MICRODefEngData from '../data/EngData20280.json';
import { getPowerSuppliesInPkg } from "../../../util/EngInfoHelp";
import { EngDataImplementation, RegisterEngDataImpl } from "../../../implementation/ImplEngData";
import { EngInfoPackage } from "../../../types/EngDataTypes"
import { isSizeEmpty } from "../../../util/GeneralHelpers";
import MicroChassisEngData from '../data/ChassisEngData2080.json';
import { IEngDataComponent, makeEngInfoFrom } from '../../../engData/EngineeringInfo';
import { logger } from '../../../util/Logger';


const microGetDfltEngDataJSON = (): object => {
    return MICRODefEngData;
}

const microEngDataPostLoad = async (pkg: EngInfoPackage) => {
    const powerSupplies = getPowerSuppliesInPkg(pkg);
    powerSupplies.forEach(ps => {
        if (isSizeEmpty(ps.imgSize)) {
            ps.imgSize.width = 126
            ps.imgSize.height = 280;
        }
    });
    loadChassis(pkg);
    return;
}

const loadChassis = (pkg: EngInfoPackage) => {
    const chasData = MicroChassisEngData as IEngDataComponent[];
    if (chasData) {
        for (let idx = 0; idx < chasData.length; idx++) {
            const entry = chasData[idx];
            const engInfo = makeEngInfoFrom(entry);
            if (engInfo && engInfo.isChassis) {
                if (engInfo.catNo) {
                    // Placeholder Chassis Catalog - should NOT
                    // be exposed in BOM, etc...
                    engInfo.isPlaceholder = true;
                    if (!pkg.infoMap.has(engInfo.catNo)) {
                        pkg.infoMap.set(engInfo.catNo, engInfo);
                    }
                    else {
                        logger.error('Micro:loadChassis(): Dup chassis in loadChassis: ' + engInfo.catNo);
                    }
                }
                else {
                    logger.error('Micro::loadChassis(): entry missing catNo!');
                }
            }
            else {
                logger.error('Micro::loadChassis(): unexpected makeEngInfoFrom result!');
            }
        }
    }
    else {
        logger.error('Micro::loadChassis(): no data?');
    }
    
}


export const RegisterMicroEngDataImpl = () => {
    const microImpl: EngDataImplementation = {
        platform: PlatformMicro,
        preload: undefined,
        getDfltEngData: microGetDfltEngDataJSON,
        postLoad: microEngDataPostLoad,
    };

    RegisterEngDataImpl(microImpl);
}
