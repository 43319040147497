import { EngInfoComponent, EngInfoModule } from "../../engData/EngineeringInfo";
import { getImageScale, getImageSource } from "../../implementation/ImplGeneral";
import { getDeviceCategory } from "../../model/ChassisProject";
import { AltChassisSAPwrSupplyInfo, PowerType } from "../../types/PowerTypes";
import { Chassis, ChassisModule, ChassisPowerSupply, ModuleDragStatus, SelectableDevice } from "../../types/ProjectTypes";
import { Size } from "../../types/SizeAndPosTypes";
import { getEngInfoForComp } from "../../util/EngInfoHelp";
import { getNewInstanceId } from "../../util/InstanceIdHelp";
import { getPlatformPowerType } from "../../util/PowerHelp";
import { flexHAIsSAPowerSupply } from "../flexHA/model/FlexHAChassis";
import { PlatformFlexHA, PlatformMicro } from "../PlatformConstants";



export const getScaledImageSize = (modInfo: EngInfoComponent): Size => {
    const scale = getImageScale(modInfo.platform);
    return {
        width: modInfo.imgSize.width * scale,
        height: modInfo.imgSize.height * scale
    }
}

export const createModuleFor = (modInfo: EngInfoModule): ChassisModule => {
    const devCategory = getDeviceCategory(modInfo.type);
    return {
        id: getNewInstanceId(),
        platform: modInfo.platform,
        deviceType: modInfo.type,
        catNo: modInfo.catNo,
        description: modInfo.description,
        isPlaceholder: modInfo.isPlaceholder,
        extendedTemp: modInfo.envInfo.etOk,
        conformal: modInfo.envInfo.ccOk,
        accysPossible: modInfo.anyAccysPossible(),
        category: devCategory,
        imgSrc: getImageSource(modInfo.platform, devCategory, modInfo.imgName),
        imgSize: getScaledImageSize(modInfo),
        movable: true,
        slotIdx: -1,
        slotID: -1,
        slotsUsed: modInfo.slotsUsed,
        selected: false,
        dragStatus: ModuleDragStatus.NA,
        parent: undefined,
        isController: modInfo.isController,
        isComm: modInfo.isComm,
        isConnClient: modInfo.isConnClient,
        spclLocalConns: 0,
        slotFiller: modInfo.isSlotFiller(),
        isFPD: modInfo.isFPD,
        isInterconnect: modInfo.isInterconnect,
        isBankExp: modInfo.isBankExp
    };
}

export const getChassisAltSAPwrSupplier = (chassis: Chassis, selectedDevice?: SelectableDevice): AltChassisSAPwrSupplyInfo | undefined => {
    if (chassis && chassis.isPlaceholder) {

        if (chassis.platform === PlatformFlexHA) {
            // If we have an SA Power Supply...
            if (flexHAIsSAPowerSupply(selectedDevice)) {
                const returnVal: AltChassisSAPwrSupplyInfo = { chassis: chassis, saPowerSupply: selectedDevice as ChassisPowerSupply };
                return returnVal;
            }
            return undefined;
        }

        // TODO_MICRO 800 - Power handling tabs
        if (chassis.platform === PlatformMicro)
            return undefined;

        // Get the selected device - could be a chassis or module.
        const infoSelDev = (selectedDevice ? getEngInfoForComp(selectedDevice.platform, selectedDevice.catNo) : undefined);
        if (infoSelDev && selectedDevice) {
            // If we are NOT a chassis...
            if (infoSelDev.isChassis === false) {
                // Is the selectable device an SA Power Supplier.
                // Note: Ctrl/Comm/FPD are all SA power suppliers
                // for the 'snap' platform types.
                if (infoSelDev.saPwrSupplier) {
                    const returnVal: AltChassisSAPwrSupplyInfo = { chassis: chassis, saPowerSupplier: selectedDevice as ChassisModule };
                    return returnVal;
                }
            }
        }

        // We're here because we did not satisfy the above.
        // If the platform is NOT an SA/MOD power...
        if (getPlatformPowerType(chassis.platform) !== PowerType.PT_ModSA)
            throw new Error('getChassisAltModAsPwrSupInfo(): Chassis is not an SA Power Snap platform!');

        const returnVal: AltChassisSAPwrSupplyInfo = { chassis: chassis, saPowerSupplier: undefined };

        // Set the power supplier to the first module.
        if (chassis.modules.length > 0)
            returnVal.saPowerSupplier = chassis.modules[0];

        return returnVal;
    }

    return undefined;
}