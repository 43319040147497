import { PlatformCLX, PlatformCpLX, PlatformFlex, PlatformFlexHA, PlatformMicro } from "../platforms/PlatformConstants";
import {
    Chassis,
    ChassisModule,
    ChassisProject,
    DeviceType,
    MicroChassis,
    UsageLevelStatus
} from "../types/ProjectTypes";
import { addChassisMessage } from "../util/Checker";
import {
    addLogMessage,
    LogMsgLevel,
    ProjectLog
} from "../util/ProjectLog";
import {
    formatAsIntWithCommas,
    formatBitsPerSecond,
    formatBytesString
} from "../util/StringFormatHelp";
import {
    chassisHasController,
    getEngineeringInfoFor,
    getProjectFromChassis,
    getUpsizeCatalogNum
} from "./ChassisProject";
import {
    addDetailItem,
    DetailGroup,
    DetailGrpType,
    DetailItem,
    makeDetailGroup
} from "./DeviceDetail";
import { logger } from "../util/Logger";
import { addAutoFix, AFType, clearAllAutoFixData, getAutoFix, getFirstAutoFixIDByType, getNewAutoFix } from "../util/CheckerAutoFix";
import { IncludeNetBandwidthInBytes } from "../types/Globals";
import { getNumSlots } from "../implementation/ImplGeneral";
import {
    getCommRoleFor,
    getConnClientRoleFor,
    getControllerRoleFor
} from "../util/EngInfoHelp";
import { EngInfoCommModule } from "../engData/EngineeringInfo";
import { ConnClientRole, usesConcurrentConns } from "../engData/ConnClientRole";
import { executeAutoFix } from "../implementation/ImplChecker";

export const carrierBytesPerPacket = 94;
const defaultRackOptRPI = 20.0;

// Applicable ONLY to aggregated connections.
// Max payload size in either direction.
const MaxAggPayload = 488;

// Extra bytes added to EACH participant's
// normal connection payload size for each dir.
const AggParticipantOverhead = 4;

// Maximum participants per aggregated conn.
const MaxAggParticipants = 32;

// Round a size in bytes up to the next highest
// size in bytes that is a multiple of 4.
const sizeToDWordBoundary = (size: number): number => {
    return Math.ceil(size / 4) * 4;
}

// Gets the number of bytes required by a
// partipant in an aggregated connection.
// Incoming payload is the size in a given
// direction (O->T or T->O).
const getAggParticipantSize = (payload: number): number => {
    return sizeToDWordBoundary(payload) + AggParticipantOverhead;
}

const _stdWarningThreshold = 0.75;
const _stdErrorThreshold = 1.00;
const _redWarningThreshold = 0.50;
const _redErrorThreshold = 0.75;


// NOTE: The following two interfaces, CtlrChassisCommsSpt
// and RemIOChasCommsReqd are 'dual-purpose'. As we run our
// comm calcs, each chassis in the project is given its OWN
// version of whichever is appropriate, the controller or
// remote version. There, the properties refer to JUST THAT
// chassis. The second purpose for these is when used as
// properties in our ProjectCommsInfo interface (below).
// For THOSE, each contains an ACCUMULATION of ALL of the
// data from matching chassis in the project.
// For example, the .redundant prop when used for
// an individual chassis means that the associated chassis
// is itself redundant. When used in ProjectCommsInfo, the
// .redundant prop indicates that AT LEAST one controller
// chassis is redundant.
export interface CtlrChassisCommsSpt {
    controllers: number;
    redundant: boolean;
    advancedOk: boolean;
    safetyOk: boolean;
    advSafetyOk: boolean;
    anyEnetNodeType: boolean;
    anyComms: boolean;
    availNodes: number;
    availCtlrCIPConns: number;
    availCommCIPConns: number;
    maxPPS: number;
    localCIPConns: number;
    localMemConsumed: number;
    totalAvailMemory: number;
    anyLocalSafetyIO: boolean;
    localSafetyIOReqAdv: boolean;
}

export interface RemIOChasCommsReqd {
    nodes: number;
    reqCIPConns: number;
    ppsToCtlr: number;
    ppsFromCtlr: number;
    bpsToCtlr: number;
    bpsFromCtlr: number;
    reqAppMem: number;
}

export interface ProjectCommsInfo {
    allCtlrChassis: CtlrChassisCommsSpt;
    allRemIOChassis: RemIOChasCommsReqd;
}

export const getEmptyCtlrChassisCommSpt = (): CtlrChassisCommsSpt => {
    return {
        controllers: 0,
        redundant: false,
        advancedOk: false,
        safetyOk: false,
        advSafetyOk: false,
        anyEnetNodeType: false,
        anyComms: false,
        availNodes: 0,
        availCtlrCIPConns: 0,
        availCommCIPConns: 0,
        maxPPS: 0,
        localCIPConns: 0,
        localMemConsumed: 0,
        totalAvailMemory: 0,
        anyLocalSafetyIO: false,
        localSafetyIOReqAdv: false
    };
}

export const getEmptyRemIOChasCommReqd = (): RemIOChasCommsReqd => {
    return {
        nodes: 0,
        reqCIPConns: 0,
        ppsToCtlr: 0,
        ppsFromCtlr: 0,
        bpsToCtlr: 0,
        bpsFromCtlr: 0,
        reqAppMem: 0
    };
}

export const getEmptyProjectCommsInfo = (): ProjectCommsInfo => {
    return {
        allCtlrChassis: getEmptyCtlrChassisCommSpt(),
        allRemIOChassis: getEmptyRemIOChasCommReqd()
    };
}

export const addCtrlSupport = (chasSpt: CtlrChassisCommsSpt, accum: CtlrChassisCommsSpt) => {
    accum.controllers += chasSpt.controllers;
    accum.redundant = (accum.redundant || chasSpt.redundant);
    accum.advancedOk = (accum.advancedOk || chasSpt.advancedOk);
    accum.safetyOk = (accum.safetyOk || chasSpt.safetyOk);
    accum.advSafetyOk = (accum.advSafetyOk || chasSpt.advSafetyOk);
    accum.anyEnetNodeType = (accum.anyEnetNodeType || chasSpt.anyEnetNodeType);
    accum.availNodes += chasSpt.availNodes;
    accum.availCtlrCIPConns += chasSpt.availCtlrCIPConns;
    accum.availCommCIPConns += chasSpt.availCommCIPConns;
    accum.maxPPS += chasSpt.maxPPS;
    accum.localCIPConns += chasSpt.localCIPConns;
    accum.localMemConsumed += chasSpt.localMemConsumed;
    accum.totalAvailMemory += chasSpt.totalAvailMemory;
    accum.anyLocalSafetyIO = (accum.anyLocalSafetyIO || chasSpt.anyLocalSafetyIO);
    accum.localSafetyIOReqAdv = (accum.localSafetyIOReqAdv || chasSpt.localSafetyIOReqAdv);
}

export const addRemIOReq = (chasReq: RemIOChasCommsReqd, accum: RemIOChasCommsReqd) => {
    accum.nodes += chasReq.nodes;
    accum.reqCIPConns += chasReq.reqCIPConns;
    accum.ppsToCtlr += chasReq.ppsToCtlr;
    accum.ppsFromCtlr += chasReq.ppsFromCtlr;
    accum.bpsToCtlr += chasReq.bpsToCtlr;
    accum.bpsFromCtlr += chasReq.bpsFromCtlr;
    accum.reqAppMem += chasReq.reqAppMem;
}

const _getDfltUsageStatus = (usedPct: number, redundant: boolean): UsageLevelStatus => {
    const errTheshold = redundant ? _redErrorThreshold : _stdErrorThreshold;
    if (usedPct > errTheshold) {
        return UsageLevelStatus.Error;
    }
    const warnThreshold = redundant ? _redWarningThreshold : _stdWarningThreshold;
    if (usedPct > warnThreshold) {
        return UsageLevelStatus.Warning;
    }
    return UsageLevelStatus.OK;
}


const _calcHeartbeatRPI = (rpi: number): number => {

    const logResult = Math.ceil(Math.log10(100.0 / rpi) / Math.log10(2.0));

    // Note: 2^2 (4) is the smallest timeout multiplier allowed.
    const timeoutMultiplier = Math.max(Math.pow(2.0, logResult), 4.0);

    const calcHeartbeatRPi = Math.floor(2000.0 / timeoutMultiplier);

    return Math.max(rpi, calcHeartbeatRPi);
}

export const getConnRPI = (
    connClient: ConnClientRole,
    reqRPI = -1,
    allowHeartbeatOut = true
): [rpiIn: number, rpiOut: number] => {

    const rpiIn = (reqRPI > 0)
        ? ((reqRPI < connClient.minRPI)
            ? connClient.minRPI
            : ((reqRPI > connClient.maxRPI)
                ? connClient.maxRPI
                : reqRPI))
        : connClient.defaultRPI;

    const rpiOut = (allowHeartbeatOut && (connClient.connSizeOT === 0))
        ? _calcHeartbeatRPI(rpiIn)
        : rpiIn;

    return [rpiIn, rpiOut];
}

export interface PerfUsageDetails {
    label: string;
    pctUsed: number; // Ex: 0.5 for 50%
    valueText: string;
    status: UsageLevelStatus;
}

// Collect any modules present and include any we care
// about in the relevant return arrays of modules.
// NOTE: The justComms array returned will contain
// ONLY those modules that ARE Comms, but ARE NOT
// also controllers.
const _collectModsInChassis = (chassis: Chassis):
    [
        controllers: ChassisModule[],
        justComms: ChassisModule[],
        connClients: ChassisModule[],
        motion: ChassisModule[],
        spclLocalConnsReqd: number
    ] => {

    // Start empty on all of our return arrays.
    const controllers = new Array<ChassisModule>();
    const justComms = new Array<ChassisModule>();
    const connClients = new Array<ChassisModule>();
    const motionMods = new Array<ChassisModule>();

    // Our 'special local conns' return will contain the
    // total number of those we find in any module(s)
    // that do NOT fit into ANY of our category buckets.
    // Used for modules like DeviceNet and RIO scanners
    // that we DO need to account for CIP-conn-wise, but
    // that are NOT considered comms.
    let spclLocalConnsReqd = 0;

    // Walk any modules in the chassis. For each...
    chassis.modules.forEach(module => {

        const modInfo = module
            ? getEngineeringInfoFor(module)
            : undefined;

        // If we HAVE a module and eng info for it...
        if (module && modInfo) {

            // If it fits one of our buckets,
            // add it accordingly.
            if (modInfo.isController) {
                controllers.push(module);
            }
            else if (modInfo.isCommModule) {
                // A comm module may or may not be a 'comm'
                // for our purposes. If this one is...
                if (modInfo.isComm) {
                    // Add it to our justComms array.
                    justComms.push(module);
                }
                else {
                    // It's some other non-EtherNet communication
                    // module. Cast to specific eng info.
                    const asCommModInfo = modInfo as EngInfoCommModule;

                    // And tally whatever it tells us it
                    // requires for local CIP Connections
                    // it requires (from a local controller).
                    spclLocalConnsReqd += asCommModInfo.localConns;
                }
            }
            else {
                if (module.isConnClient) {
                    connClients.push(module);
                }
                else {
                    // If we get here, we'll still look for motion
                    // by looking at the module's device type. Our
                    // caller is responsible for determining exactly
                    // WHAT to do with these.
                    if (module.deviceType === DeviceType.Motion) {
                        motionMods.push(module);
                    }
                    else {
                        // The module did NOT qualify for ANY of our
                        // category buckets. Add any special local conns
                        // it wants (if any) to our tally.
                        spclLocalConnsReqd += module.spclLocalConns;
                    }
                }
            }
        }
    });

    return [controllers, justComms, connClients, motionMods, spclLocalConnsReqd];
}

const testSupportForConnClient = (
    platform: string,
    connClient: ConnClientRole,
    availCtlrInfo: CtlrChassisCommsSpt,
    project?: ChassisProject
): [result: boolean, err: string, idAutoFix: string] => {

    let result = true;
    let err = '';
    let errCtrlType = '';
    let afType: AFType = AFType.NA;

    // Check safety I/O and/or advanced controller
    // requirements against known quantities of
    // suitable controllers available for use.
    // If safety...
    if (connClient.safetyIO) {

        // AND advanced...
        if (connClient.advancedCtrlReqd) {
            // Then we need at least one advanced safety ctrl.
            if (!availCtlrInfo.advSafetyOk) {
                result = false;
                errCtrlType = 'an Advanced Safety';
                afType = AFType.Safety_NoSafetyCtlrInConfig;
            }
        }
        else {
            // NOT advanced. We need at least one safety
            // controller (either advanced or not).
            if (!availCtlrInfo.safetyOk) {
                result = false;
                errCtrlType = 'a Safety';
               afType = AFType.Safety_NoSafetyCtlrInConfig;
            }
        }
    }
    else {
        // Not safety. If advanced...
        if (connClient.advancedCtrlReqd) {

            // Then we need at least one advanced
            // controller (safety or not).
            if (!availCtlrInfo.advancedOk) {
                result = false;
                err = 'requires an Advanced controller';
            }
        }
        else {
            // Final sanity check. Our client has NO
            // specific requirements for controller type.
            // Here, we should ALWAYS have AT LEAST one
            // controller available. Otherwise, we should
            // NOT have been called. If not...
            if (availCtlrInfo.controllers === 0) {
                // Unexpected case. Error out.
                throw new Error('Unexpected call to testSupportForConnClient');
            }
        }
    }

    let idAutoFix = '';
    if (afType !== AFType.NA) {
        if (afType === AFType.Safety_NoSafetyCtlrInConfig) {
            // This will be a project level message.
            // If we have a project and we do NOT have
            // an Auto Fix created...
            if (project && !getFirstAutoFixIDByType(afType)) {
                // Create the auto fix.
                const af = getNewAutoFix(platform, '', afType);
                addAutoFix(af);

                // Determine whether or not to add the Auto Fix
                // to the log message. Note: We still want to
                // create the Auto-Fix so that the message is
                // only added ONCE to the log.
                const afID = (isPlatformValidForCtrlAutoFix(platform) ? af.id : '');

                // Add the log to the project.
                const errMsg = `You have Safety I/O as part of your configuration and have not included ${errCtrlType} Controller. Please ensure that you have included ${errCtrlType} Controller as part of your system`;              
                addLogMessage(project.content.statusLog, errMsg, LogMsgLevel.warning, undefined, afID);                
            }
        }
        else {
            const af = getNewAutoFix(platform, '', afType);
            addAutoFix(af);
            idAutoFix = af.id;
        }
    }

    return [result, err, idAutoFix];
}

const getCtlrChassisCommSupport = (chassis: Chassis, numRemotes: number): CtlrChassisCommsSpt => {

    const spt = getEmptyCtlrChassisCommSpt();

    // Set our redundant flag to match.
    spt.redundant = chassis.redundant;

    // Call a helper to return arrays of modules
    // that fit into 4 categories: controllers, modules
    // that are (ENET) comms, but NOT also controllers,
    // connection clients (I/O), and motion modules.
    // Note that since we KNOW we're a chassis that has
    // a controller in it, the ONLY possibility of finding
    // any motion modules is if they are INTEGRATED motion,
    // which is exclusive to CLX.
    const [controllers, justComms, connClients,
        motionMods, spclLocalConns] = _collectModsInChassis(chassis);

    // We're supposed to be working with a CONTROLLER
    // chassis, so we should have at LEAST 1 of them.
    if (controllers.length === 0) {
        throw new Error('Invalid call to getCtlrChassisCommSupport - NO controllers in chassis!');
    }

    // If we got any 'specials' back...
    if (spclLocalConns > 0) {

        // Add them spt value for extra local client
        // connections (which is ALSO used to include
        // connections needed by integrated motion modules.
        spt.localCIPConns += spclLocalConns;
    }

    // For each controller we were given...
    controllers.forEach(ctlr => {

        // Add 1 to our total of ALL controllers.
        spt.controllers++;

        // Get controller comms details using the catNo.
        const ctlrInfo = getControllerRoleFor(ctlr.platform, ctlr.catNo);

        spt.totalAvailMemory += ctlrInfo.memory;

        // Bump the specific quantity based
        // on the controllers capabilities.
        if (ctlrInfo.isSafetyController) {
            if (ctlrInfo.isAdvanced) {
                spt.advSafetyOk = true;
                spt.safetyOk = true;
                spt.advancedOk = true;
            }
            else {
                spt.safetyOk = true;
            }
        }
        else if (ctlrInfo.isAdvanced) {
                spt.advancedOk = true;
        }

        // If it's an 'Enet Node' type controller,
        // bump that tally, along with the total
        // number of nodes we can support, and set
        // the flag indicating that we have at least
        // 1 of those.
        if (ctlrInfo.enetNodeType) {
            spt.availNodes += ctlrInfo.maxEnetNodes;
            spt.anyEnetNodeType = true;
        }

        // Add available CIP Conns supported by
        // the CONTROLLER
        spt.availCtlrCIPConns += ctlrInfo.maxCIPConns;

        // If our controller is ALSO a comm...
        if (ctlr.isComm) {

            spt.anyComms = true;

            // Get comm specs.
            const ctlrAsComm = getCommRoleFor(ctlr.platform, ctlr.catNo);

            // Our comm role gives us CIP conn max that
            // would apply to BOTH the comm aspect as well
            // as the controller itself. The controller's
            // built-in comm isn't allowed for use as a
            // pass-thru path to a DIFFERENT controller.
            spt.availCommCIPConns += ctlrAsComm.maxCIPConns;

            // Also include its total available PPS max.
            spt.maxPPS += ctlrAsComm.maxIOPPS;
        }
    });

    // For each (if any) modules we got back
    // that are 'just' comms. Each of these should
    // be an Ethernet scanner of some sort.
    justComms.forEach(commMod => {

        spt.anyComms = true;

        // Get comm role info using the catNo.
        const commInfo = getCommRoleFor(commMod.platform, commMod.catNo);

        // Add the comm's CIP conns number to our
        // tally for thos via comms.
        spt.availCommCIPConns += commInfo.maxCIPConns;

        // And its max PPS number.
        spt.maxPPS += commInfo.maxIOPPS
    });


    // We we have any remote chassis and NO comms,
    // add a suitable warning to the chassis.
    if ((numRemotes > 0) && !spt.anyComms) {
        const msg = 'A network connection is required to be able to ' +
            'control any Remote I/O from this chassis. Add a Scanner ' +
            'module, or change your Controller to one that can be ' +
            'connected to the network.';
        addChassisMessage(chassis, msg, LogMsgLevel.warning);
    }

    const project = getProjectFromChassis(chassis);

    // If we found any conn clients (I/O modules)...
    connClients.forEach(clientMod => {

        // All will be ASSUMED (and required for our purposes)
        // to be controlled by a LOCAL controller. These will
        // communicate via the backplane, so we're NOT adding
        // any PPS. We just need to add the associated connection's
        // CIP conn count to our 'local' tally for each one we
        // found (IF we indeed CAN control it).
        // Get client role info using the catNo.
        const clientInfo = getConnClientRoleFor(clientMod.platform, clientMod.catNo);

        // If/as we find any local safety I/O, make
        // sure we mark that in our support object.
        if (clientInfo.safetyIO) {
            spt.anyLocalSafetyIO = true;
            if (clientInfo.advancedCtrlReqd) {
                spt.localSafetyIOReqAdv = true;
            }
        }

        // Test to see if the module CAN be controlled by
        // what we know is available as far as controllers go.
        const [clientOk, err, idAutoFix] = testSupportForConnClient(chassis.platform, clientInfo, spt, project);

        // If we're good.
        if (clientOk) {
            // Add the client's CIP conn requirement to our
            // tally of local connections used and mem reqd;
            spt.localCIPConns += clientInfo.numCIPConns;
            spt.localMemConsumed += clientInfo.appMemReqd
        }
        else if (err) { // Only log a message when 'err' valid.
            // Not OK, add an error message to chassis' statusLog.       
            const errMsg = 'Module ' + clientMod.catNo + ' ' + err +
                ', and there is not a suitable controller in the chassis. ' +
                'Note: I/O modules in a chassis that contains a controller ' +
                'must be locally controlled.';
            addChassisMessage(chassis, errMsg, LogMsgLevel.error, idAutoFix);
        }
    });

    // If we found any integrated motion modules...
    if (motionMods.length > 0) {
        // Add 3 for each to our LOCAL CIP conns tally.
        // Note that none of the integrated motion modules
        // require any specific processor type, other than
        // it has to support motion. All controllers that
        // could ALSO have such a module sharing the same
        // chassis qualify.
        spt.localCIPConns += (3 * motionMods.length);
    }

    return spt;
}

const _tallyRemConn = (remReq: RemIOChasCommsReqd,
    numCIPConns: number, sizeIn: number, rpiIn: number,
    sizeOut: number, rpiOut: number, appMemNeeded: number) => {

    const ppsIn = 1000.0 / rpiIn;
    const packetSizeIn = sizeIn + carrierBytesPerPacket;

    remReq.ppsToCtlr += ppsIn;
    remReq.bpsToCtlr += (ppsIn * packetSizeIn);

    const ppsOut = 1000.0 / rpiOut;
    const packetSizeOut = sizeOut + carrierBytesPerPacket;

    remReq.ppsFromCtlr += ppsOut;
    remReq.bpsFromCtlr += (ppsOut * packetSizeOut);

    remReq.reqCIPConns += numCIPConns;
    remReq.reqAppMem += appMemNeeded;
}

const tallyRemConnection = (
    remReq: RemIOChasCommsReqd,
    connClient: ConnClientRole,
    reqRPI = -1,
    allowHeartbeatOut = true
) => {
    const [rpiIn, rpiOut] = getConnRPI(connClient, reqRPI, allowHeartbeatOut);
    _tallyRemConn(remReq, connClient.numCIPConns, connClient.connSizeTO, rpiIn,
        connClient.connSizeOT, rpiOut, connClient.appMemReqd);
}

const _isRackOptConnOk = (connClients: ChassisModule[]):
    [rackOptOk: boolean, appMemNeeded: number] => {

    // Start pessimistic with no mem required.
    let rackOptOk = false;
    let appMemNeeded = 0;

    // Walk the provided conn client array. For each (if any)...
    for (let idx = 0; idx < connClients.length; idx++) {

        const client = connClients[idx];

        // Get the comms info for the client using its catNo.
        const clientInfo = getConnClientRoleFor(client.platform, client.catNo);

        // Get its ability to be rack optimized.
        rackOptOk = clientInfo.rackOptOk;

        // If it can...
        if (rackOptOk) {
            // Add its mem requirement to our total.
            appMemNeeded += clientInfo.appMemReqd;
        }
    }

    // Return our final answers.
    return [rackOptOk, appMemNeeded];
}

// Note: Caller responsible for PRE-checking that
// chassis has EXACTLY one comm in it.
const tallyRackOptConn = (
    chassis: Chassis,
    connClients: ChassisModule[],
    remReq: RemIOChasCommsReqd,
): boolean => {
    const [rackOptOk, totalParticipantAppMem] = _isRackOptConnOk(connClients);
    if (rackOptOk) {
        const chassisSlots = getNumSlots(chassis);

        // Start with cover numbers for output and total size.
        let sizeOut = 6;
        let totalConnSize = 12;

        // Then add fixed numbers of bytes for EACH slot.
        sizeOut += (chassisSlots * 4);
        totalConnSize += (chassisSlots * 12);

        // Back-calculate input size based from total and output.
        const sizeIn = totalConnSize - sizeOut;

        _tallyRemConn(remReq, 1, sizeIn, defaultRackOptRPI,
            sizeOut, defaultRackOptRPI, totalParticipantAppMem);
    }

    return rackOptOk;
}

const tallyAggRPIGroup = (remIOReq: RemIOChasCommsReqd, rpi: number, rpiGrp: ConnClientRole[]) => {

    // See how many are in the group.
    switch (rpiGrp.length) {

        case 0:
            // There should NEVER be 0.
            throw new Error('ERROR: Empty group in tallyAggRPIGroup!');

        case 1:
            // Tally a singleton as a
            // normal direct connection.
            tallyRemConnection(remIOReq, rpiGrp[0]);
            return;

        default:
            break;
    }

    // If we're still here, our group has multiple
    // members. Start total payload size accumulators
    // for each direction of the connection.
    let grpPayloadOT = 0;
    let grpPayloadTO = 0;

    // And one to accumulate the combined
    // app mem needed by the group.
    let grpAppMemReqd = 0;

    // Start with assumption we WON'T be breaking
    // early because a connection is full.
    let startRecurseAtIdx = -1;

    // Determine the last idx we could possibly
    // want to look at, based on the lessor of
    // the number of potential candidates we have
    // and the MAX allowed in a single connection.
    const lastIdx = (rpiGrp.length > MaxAggParticipants)
        ? MaxAggParticipants - 1
        : rpiGrp.length - 1;

    /// Walk potential candidates. For each...
    for (let idx = 0; idx <= lastIdx; idx++) {

        // Get the next one.
        const client = rpiGrp[idx];

        // Get the size it would required an aggregate
        // participant in each direction of the connection.
        const clSizeOT = getAggParticipantSize(client.connSizeOT);
        const clSizeTO = getAggParticipantSize(client.connSizeTO);

        // If adding this one would NOT exceed the maximum
        // size of an agg connection in EITHER direction...
        if (((grpPayloadOT + clSizeOT) <= MaxAggPayload) &&
            ((grpPayloadTO + clSizeTO) <= MaxAggPayload)) {

            // Our client can fit in this connection.
            // Add its payload sizes to our tallies.
            grpPayloadOT += clSizeOT;
            grpPayloadTO += clSizeTO;

            // And its memory requirement.
            grpAppMemReqd += client.appMemReqd;
        }
        else {
            // Client won't fit into the current connection.
            // Remember where we need to start for our
            // recursion call below.
            startRecurseAtIdx = idx;
            break;
        }
    }

    // When we get here, we either got everyone in, or we
    // stopped early because our connection was full. In
    // EITHER case, we SHOULD have at least SOME payload.
    // If so...
    if ((grpPayloadOT + grpPayloadTO) > 0) {
        // Add our aggregated connection.
        _tallyRemConn(remIOReq, 1, grpPayloadOT, rpi, grpPayloadTO, rpi, grpAppMemReqd);
    }
    else {
        throw new Error('ERROR: unexpected EMPTY agg connection in tallyAggRPIGroup!');
    }

    // If we quit early with a full connection...
    if (startRecurseAtIdx > 0) {

        // Get the remaining candidates
        // that haven't yet been handled. 
        const remCandidates = rpiGrp.slice(startRecurseAtIdx);

        // And recurse back again on that group.
        tallyAggRPIGroup(remIOReq, rpi, remCandidates);
    }
}

const tallyAggCandidates = (remIOReq: RemIOChasCommsReqd, candidates: ConnClientRole[]) => {

    const mapByRPI = new Map<number, ConnClientRole[]>();
    candidates.forEach(candidate => {
        const existingGrp = mapByRPI.get(candidate.defaultRPI);
        if (existingGrp) {
            existingGrp.push(candidate);
        }
        else {
            mapByRPI.set(candidate.defaultRPI, [candidate]);
        }
    });

    mapByRPI.forEach((grp, rpi) => {
        tallyAggRPIGroup(remIOReq, rpi, grp);
    });
}

const getRemChassisCommsReqd = (chassis: Chassis, availCtlrSpt: CtlrChassisCommsSpt): RemIOChasCommsReqd => {
    const [controllers, adapterComms, connClients,
        motionMods, spclLocalConns] = _collectModsInChassis(chassis);

    if (controllers.length > 0) {
        throw new Error('Invalid call to tallyRemChasCommsInfo - Controller(s) in chassis!');
    }

    const req = getEmptyRemIOChasCommReqd();

    // We'll ignore any special local connections required. Those
    // SHOULD apply only to a controller chassis. In the RIO case,
    // its actually an error caught by our checker. In the DNB case,
    // we only care about the DNB module communicating across a chassis
    // backplane with a controller, which DOES tack CIP connection(s)
    // usage onto the controller's tally.
    if (spclLocalConns > 0) {
        logger.log('Modules like ControlNet, DeviceNet, and RIO scanners add CIP connections ' +
            'to their controller, but are IGNORED in remote I/O chassis.');
    }

    // Determine up front if we care about any motion mods we find.
    // For CLX, if there are any in a remote chassis, our checker
    // includes an error, so we can ignore any such found here. If
    // NOT CLX, we care only if we actually FOUND any motion modules.
    const checkMotion = ((chassis.platform !== PlatformCLX) && (motionMods.length > 0));

    // If we have no possible communication at this point,
    // we'll just return without adding ANYTHING to our
    // requirements object's tallys.
    if ((connClients.length === 0) && !checkMotion) {
        return req;
    }

    // If we're still here, we KNOW that we have at least
    // ONE module that needs to communicate with a controller
    // elsewhere. If we have no way to get out, add a warning
    // to the chassis and return.
    if (adapterComms.length === 0) {
        //For 5069 error message and auto fix for no Cotroller/ Add Adapter 
      if (chassis.platform == PlatformCpLX) {
        const afType: AFType = AFType.No_Controller;
        let idAutoFix = "";
        const af = getNewAutoFix(PlatformCpLX, "", afType);
        addAutoFix(af);
        idAutoFix = af.id;
        af.targetInstanceID = chassis.id;
        const msg =
          "Every chassis must have either a Controller or an Adapter in " +
          "Slot 0 to properly function. Please add one. ";
        addChassisMessage(chassis, msg, LogMsgLevel.error, idAutoFix);
      } else {
        const msg =
          "Without access to a controller, the communication requirements " +
          "of I/O modules in this chassis will be ignored, and will not be included " +
          "in system performance calculations. To include them, add either a controller " +
          "or a communication module.";
        addChassisMessage(chassis, msg, LogMsgLevel.warning);
      }
      return req;
    }
    
    // Determine whether we'll be using Rack optimization.
    // In order to do so, we need exactly one adapter,
    // AND at LEAST one IO connection client that (fully)
    // supports rack optimization.
    const usingRackOpt = (adapterComms.length === 1)
        ? tallyRackOptConn(chassis, connClients, req)
        : false;

    let anyClientsAdded = false;

    const anyProjControllers = (availCtlrSpt.controllers > 0);

    const aggCandidates = new Array<ConnClientRole>();

    // If we found any conn clients (I/O modules)...
    connClients.forEach(clientMod => {

        // All will be ASSUMED (and required for our purposes)
        // to be controlled by a LOCAL controller. These will
        // communicate via the backplane, so we're NOT adding
        // any PPS. We just need to add the associated connection's
        // CIP conn count to our 'local' tally for each one we
        // found (IF we indeed CAN control it).
        // Get client role info using the catNo.
        const clientInfo = getConnClientRoleFor(clientMod.platform, clientMod.catNo);

        // IF there are ANY controllers available in our
        // system, then we'll assume that ALL remote I/O
        // is expected be controlled by something in the system.
        // If so, test to see if there's anything suitable
        // available. If there are NOT any controllers,
        // because our system is ONLY remote I/O, we'll
        // just skip the test and treat all connections
        // as good.
        const [clientOk, err, idAutoFix] = anyProjControllers
            ? testSupportForConnClient(chassis.platform, clientInfo, availCtlrSpt, getProjectFromChassis(chassis))
            : [true, '', ''];

        // If we're good.
        if (clientOk) {

            // If the client can be aggregated, just
            // add it to our agg collector for now.
            if (clientInfo.canBeAggregated) {
                aggCandidates.push(clientInfo);
            }
            else {
                // Otherwise, see if it is a participant in a
                // a rack optimized connection. It is IFF
                // we're USING rack optimization, AND 
                // this conn client is fully RO-capable.
                const rackOptParticipant = usingRackOpt && clientInfo.rackOptOk;

                // If it is, we've ALREADY included it in our rack
                // opt bundled connection above. If not...
                if (!rackOptParticipant) {
                    // Add the client's CIP (direct) conn requirement
                    // to our tally of remote I/O connection info.
                    tallyRemConnection(req, clientInfo);
                }
            }
            anyClientsAdded = true;
        }
        else if (err) { // Only log a message when 'err' valid.
            // Not OK, add an error message to chassis' statusLog.
            const errMsg = 'Module ' + clientMod.catNo + ' ' + err +
                ', and there is no suitable controller in your system.';
            addChassisMessage(chassis, errMsg, LogMsgLevel.error, idAutoFix);
        }
    });

    if (aggCandidates.length > 0) {
        tallyAggCandidates(req, aggCandidates);
    }

    // If any motion modules are present...
    if (motionMods.length > 0) {

        // Then look at our platform. If CLX, we COULD add
        // an error to the chassis at this point, but our
        // checker ALREADY does that for us. As such, we'll
        // just ignore the case here. If the platform is
        // something OTHER than CLX...
        if (chassis.platform !== PlatformCLX) {
            // TBD for other platforms.
            // Any motion modules will need to be
            // checked and either motion conn info
            // tallied or errors added as appropriate.
        }
    }

    // If we found ANY clients, set our nodes
    // number to 1. Each participating remote
    // chassis will have 1 (enet) node.
    if (anyClientsAdded) {
        req.nodes = 1;
    }

    return req;
}

export enum PrfAspect {
    nodes = 'nodes',
    ctlrCIPConns = 'ctlrCIPConns',
    commCIPConns = 'commCIPConns',
    pps = 'pps',
    memory = 'memory'
}


export const getPerformanceUsage = (aspect: PrfAspect, commsInfo: ProjectCommsInfo)
    :[used: number, avail: number] => {
    switch (aspect) {
        case PrfAspect.nodes:
            return _getEnetNodeInfo(commsInfo);
            break;

        case PrfAspect.ctlrCIPConns:
            return _getCtlrCIPConnInfo(commsInfo);
            break;

        case PrfAspect.commCIPConns:
            return _getCommCIPConnInfo(commsInfo);
            break;

        case PrfAspect.pps:
            return _getPPSInfo(commsInfo);
            break;

        case PrfAspect.memory:
            return _getMemoryInfo(commsInfo);
            break;

        default:
            throw new Error('Unexpected aspect requested in _getUsagePct');
    }
}

const _getUsagePct = (aspect: PrfAspect, commsInfo: ProjectCommsInfo): number => {
    const [used, avail] = getPerformanceUsage(aspect, commsInfo);

    const pctUsed = used / avail;
    //logger.log('Usage of ' + aspect + ': ' + (pctUsed * 100).toFixed(1) + '%');
    return pctUsed;
}

const _uMsgStart = 'One or more performance aspects of your system exceeds the ';
const _uMsgRed = ' for systems with a Redundant Controller. ';
const _uMsgEnd = ' that you review the Project Performance section above to ' +
    'determine the issue and include additional Controllers or Communication ' +
    'Modules to provide adequate capacity for your system.';

const _addPerfUsageLogMessage = (platform: string, red: boolean,
    usage: number, projLog: ProjectLog) => {

    let [msg, lvl] = ['', LogMsgLevel.none];

    if (red) {
        if (usage >= _redErrorThreshold) {
            msg = _uMsgStart + 'maximum allowable level of 75%' + _uMsgRed +
                'It is required' + _uMsgEnd;
            lvl = LogMsgLevel.error;
        }
        else if (usage > _redWarningThreshold) {
            msg = _uMsgStart + 'recommended level of 50%' + _uMsgRed +
                'It is suggested' + _uMsgEnd;
            lvl = LogMsgLevel.warning;
        }
    }
    else {
        if (usage >= _stdErrorThreshold) {
            msg = _uMsgStart + 'maximum level of 100%. ' +
                'It is required' + _uMsgEnd;
            lvl = LogMsgLevel.error;
        }
        else if (usage > _stdWarningThreshold) {
            msg = _uMsgStart + 'recommended level of 75%. ' + 
                'It is suggested' + _uMsgEnd;
            lvl = LogMsgLevel.warning;
        }
    }

    if (lvl !== LogMsgLevel.none) {
        if (lvl === LogMsgLevel.info) {
            addLogMessage(projLog, msg, lvl);
        }
        else {
            const af = getNewAutoFix(platform, '', AFType.Sys_Performance);
            addAutoFix(af);
            addLogMessage(projLog, msg, lvl, null, af.id);
        }
    }
}

const checkCalcResults = (platform: string, commsInfo: ProjectCommsInfo, projLog: ProjectLog) => {

    // If we have no controllers, we have nothing to do.
    if (commsInfo.allCtlrChassis.controllers < 1) {
        return;
    }

    // Start max usage percentage at 0.
    let maxUsage = 0;

    // For each performance aspect we care about,
    // get ITS percentage used number, and move that
    // to the maxUsage if greater. Note that
    // we only include nodes if we have any Enet
    // Node type controllers, and we only include
    // CIP Conn checks (both Ctlr AND Comm) if we DON'T
    // have any Enet Node type controllers.
    if (commsInfo.allCtlrChassis.anyEnetNodeType) {
        maxUsage = Math.max(maxUsage, _getUsagePct(PrfAspect.nodes, commsInfo));
    }
    else {
        maxUsage = Math.max(maxUsage, _getUsagePct(PrfAspect.ctlrCIPConns, commsInfo));
        maxUsage = Math.max(maxUsage, _getUsagePct(PrfAspect.commCIPConns, commsInfo));
    }

    maxUsage = Math.max(maxUsage, _getUsagePct(PrfAspect.pps, commsInfo));
    maxUsage = Math.max(maxUsage, _getUsagePct(PrfAspect.memory, commsInfo));

    //logger.log('max usage: ' + maxUsage);

    _addPerfUsageLogMessage(platform, commsInfo.allCtlrChassis.redundant, maxUsage, projLog);
}

export const runCommCalcs = (project: ChassisProject, logIssues = true):
    [commsInfo: ProjectCommsInfo, ctlrChassis: Chassis[]] => {

    const commsInfo = getEmptyProjectCommsInfo();

    if (project.content.racks.length === 0) {
        return [commsInfo, []];
    }

    const ctlrChassis = new Array<Chassis>();
    const remChassis = new Array<Chassis>();

    project.content.racks.forEach(rack => {

        rack.chassis.ctlrCommsSpt = undefined;
        rack.chassis.remCommsReqd = undefined;

        if (chassisHasController(rack.chassis)) {
            ctlrChassis.push(rack.chassis);
        }
        else {
            remChassis.push(rack.chassis);
        }
    });

    const anyControllers = (ctlrChassis.length > 0);
    const numRemotes = remChassis.length;

    const platform = anyControllers
        ? ctlrChassis[0].platform
        : (numRemotes > 0)
            ? remChassis[0].platform
            : '';

    if (anyControllers) {
        ctlrChassis.forEach(ctlrChas => {
            const ctlrSpt = getCtlrChassisCommSupport(ctlrChas, numRemotes);
            ctlrChas.ctlrCommsSpt = ctlrSpt;
            addCtrlSupport(ctlrSpt, commsInfo.allCtlrChassis);
        });
    }

    let safetyIOWarnAdded = anyControllers;
    remChassis.forEach(remChas => {
        const microChassis = remChas as MicroChassis
        const remReq = getRemChassisCommsReqd(remChas, commsInfo.allCtlrChassis);
        if (platform == PlatformMicro && !microChassis.bu) {
            const afType: AFType = AFType.No_Controller;
            let idAutoFix = "";
            const af = getNewAutoFix(PlatformMicro, "", afType);
            addAutoFix(af);
            idAutoFix = af.id;
            af.targetInstanceID = remChas.id;
            const msg =
              "Every chassis must have a Controller in Slot 0. Please add one ";
            addChassisMessage(remChas, msg, LogMsgLevel.error, idAutoFix);
        }

        // If the remote chassis uses
        // concurrent connections...
        if ( usesConcurrentConns( remChas.platform ) ) {
            // Double the Enet/IP nodes, packets
            // per second, and bits per second.
            remReq.nodes *= 2;
            remReq.ppsFromCtlr *= 2;
            remReq.ppsToCtlr *= 2;
            remReq.bpsFromCtlr *= 2;
            remReq.bpsToCtlr *= 2;
        }

        remChas.remCommsReqd = remReq;
        addRemIOReq(remReq, commsInfo.allRemIOChassis);

        if (!safetyIOWarnAdded && _hasSafetyIO(remChas)) {
            safetyIOWarnAdded = true;
            _addSafetyIOWarnToProj(project, remChas.platform);
        }
    });

    if (logIssues) {
        checkCalcResults(platform, commsInfo, project.content.statusLog);
    }

    return [commsInfo, ctlrChassis];
}

const _hasSafetyIO = (chassis: Chassis): boolean => {
    if (chassis.modules) {
        const safetyIOFound = chassis.modules.some((mod, idx) => {
            if (idx > 0) {
                if (mod && mod.isConnClient) {
                    const role = getConnClientRoleFor(chassis.platform, mod.catNo);
                    if (role.safetyIO)
                        return true;
                }
            }
            return false;
        });

        return safetyIOFound;
    }
    return false;
}

const _addSafetyIOWarnToProj = (project: ChassisProject, platform: string) => {
    if (!getFirstAutoFixIDByType(AFType.Safety_NoSafetyCtlrInConfig)) {
        // Create the auto fix.
        const af = getNewAutoFix(platform, '', AFType.Safety_NoSafetyCtlrInConfig);
        addAutoFix(af);

        // Determine whether or not to add the Auto Fix
        // to the log message. Note: We still want to
        // create the Auto-Fix so that the message is
        // only added ONCE to the log.
        const afID = (isPlatformValidForCtrlAutoFix(platform) ? af.id : '');

        const errMsg = `You have Safety I/O as part of your configuration. Please ensure that you have included a Safety Controller as part of your system.`;

        // Add the log to the project.
        addLogMessage(project.content.statusLog, errMsg, LogMsgLevel.info, undefined, afID);
    }
}

export const getWarningThreshold = (commsInfo: ProjectCommsInfo): number => {
    const warnAt = commsInfo.allCtlrChassis.redundant
        ? _redWarningThreshold
        : _stdWarningThreshold;
    return warnAt;
}


const _usageValSep = ' / ';

const _labelMemory = 'Memory';
const _labelNodes = 'EtherNet/IP Nodes';

// Note: the following labels were added to
// temporarily differentiate our two CIP
// Conns supported values.
// When we move our performance data display
// info out of the existing 'floating U/I' and
// into the new 'Status' modal (formally Issues),
// BOTH values should use the OLD label (above).
// Differentiation for which is which will be
// implied based on the column each is found
// (controller values or comm values)

// Temp label for CIP Conns supported
// by controllers (C -> Controllers)
const _labelCtlrCIPConns = 'CIP Connections'; 

// Temp label for CIP Conns supported
// by comms (S -> Scanners)
const _labelCommCIPConns = 'CIP Connections';

const _labelPPS = 'Packets per Second';
const _labelBPS = 'Network Bandwidth:';

const _getMemoryInfo = (commsInfo: ProjectCommsInfo): [used: number, avail: number] => {
    // Memory used includes the tally of use by modules in
    // remote I/O chassis PLUS whatever might have been required
    // in controller chassis.
    return [
        commsInfo.allRemIOChassis.reqAppMem + commsInfo.allCtlrChassis.localMemConsumed,
        commsInfo.allCtlrChassis.totalAvailMemory
    ];
}

export const getMemoryUsage = (commsInfo: ProjectCommsInfo): PerfUsageDetails => {

    const [memUsed, memAvail] = _getMemoryInfo(commsInfo);
    const memPctUsed = memUsed / memAvail;

    const usedText = formatBytesString(memUsed, false, true);
    const availText = formatBytesString(memAvail, false, true);

    const memUsage: PerfUsageDetails = {
        label: _labelMemory,
        pctUsed: memPctUsed,
        valueText: usedText + _usageValSep + availText,
        status: _getDfltUsageStatus(memPctUsed, commsInfo.allCtlrChassis.redundant)
    };
    return memUsage;
}

const _getCtlrCIPConnInfo = (commsInfo: ProjectCommsInfo): [used: number, avail: number] => {
    return [
        commsInfo.allRemIOChassis.reqCIPConns + commsInfo.allCtlrChassis.localCIPConns,
        commsInfo.allCtlrChassis.availCtlrCIPConns
    ];
}

export const getCtlrCIPConnUsage = (commsInfo: ProjectCommsInfo): PerfUsageDetails => {

    const [connsUsed, ctlrCIPConnsAvail] = _getCtlrCIPConnInfo(commsInfo);

    const connsPctUsed = connsUsed / ctlrCIPConnsAvail;

    const cipConnUsage: PerfUsageDetails = {
        label: _labelCtlrCIPConns,
        pctUsed: connsPctUsed,
        valueText: connsUsed + _usageValSep + ctlrCIPConnsAvail,
        status: _getDfltUsageStatus(connsPctUsed, commsInfo.allCtlrChassis.redundant)
    };
    return cipConnUsage;
}

const _getCommCIPConnInfo = (commsInfo: ProjectCommsInfo): [used: number, avail: number] => {
    return [
        commsInfo.allRemIOChassis.reqCIPConns, // Note: LOCAL connections don't apply.
        commsInfo.allCtlrChassis.availCommCIPConns
    ];
}

export const getCommCIPConnUsage = (commsInfo: ProjectCommsInfo): PerfUsageDetails => {

    const [connsUsed, commCIPConnsAvail] = _getCommCIPConnInfo(commsInfo);

    const connsPctUsed = connsUsed / commCIPConnsAvail;

    const cipConnUsage: PerfUsageDetails = {
        label: _labelCommCIPConns,
        pctUsed: connsPctUsed,
        valueText: connsUsed + _usageValSep + commCIPConnsAvail,
        status: _getDfltUsageStatus(connsPctUsed, commsInfo.allCtlrChassis.redundant)
    };
    return cipConnUsage;
}

const _getEnetNodeInfo = (commsInfo: ProjectCommsInfo): [used: number, avail: number] => {
    if (commsInfo.allCtlrChassis.anyEnetNodeType) {
        return [commsInfo.allRemIOChassis.nodes, commsInfo.allCtlrChassis.availNodes];
    }
    else {
        return [0, 0];
    }
}

export const getEnetNodeUsage = (commsInfo: ProjectCommsInfo): PerfUsageDetails => {

    // NOTE: This one will need some work at some point if/when
    // we ever decide how to better deal with mixing controllers,
    // some of which are Enet Node type and some not.

    const [nodesUsed, nodesAvail] = _getEnetNodeInfo(commsInfo);
    const nodesPctUsed = nodesUsed / nodesAvail;

    const enetNodeUsage: PerfUsageDetails = {
        label: _labelNodes,
        pctUsed: nodesPctUsed,
        valueText: nodesUsed + _usageValSep + nodesAvail,
        status: _getDfltUsageStatus(nodesPctUsed, commsInfo.allCtlrChassis.redundant)
    };
    return enetNodeUsage;
}

const _getPPSInfo = (commsInfo: ProjectCommsInfo): [used: number, avail: number] => {
    //logger.log('PPS to: ' + commsInfo.allRemIOChassis.ppsToCtlr +
    //    ', from: ' + commsInfo.allRemIOChassis.ppsFromCtlr);
    return [
        commsInfo.allRemIOChassis.ppsToCtlr + commsInfo.allRemIOChassis.ppsFromCtlr,
        commsInfo.allCtlrChassis.maxPPS
    ];
}

export const getPPSUsage = (commsInfo: ProjectCommsInfo): PerfUsageDetails => {

    const [ppsUsed, ppsAvail] = _getPPSInfo(commsInfo);
    const ppsPctUsed = ppsUsed / ppsAvail;

    const enetNodeUsage: PerfUsageDetails = {
        label: _labelPPS,
        pctUsed: ppsPctUsed,
        valueText: formatAsIntWithCommas(ppsUsed) +
            _usageValSep + formatAsIntWithCommas(ppsAvail),
        status: _getDfltUsageStatus(ppsPctUsed, commsInfo.allCtlrChassis.redundant)
    };
    return enetNodeUsage;
}

export const getRequirementsGroup = (commsInfo: ProjectCommsInfo): DetailGroup => {
    const remInfo = commsInfo.allRemIOChassis;
    const reqGrp = makeDetailGroup(DetailGrpType.Group);

    // Per ITISAB-526, requirements group (used when we have
    // no controllers), should no longer include required CIP
    // connections.
    addDetailItem(reqGrp, _labelNodes, formatAsIntWithCommas(remInfo.nodes));
    addDetailItem(reqGrp, _labelPPS, formatAsIntWithCommas(remInfo.ppsToCtlr + remInfo.ppsFromCtlr));
    addDetailItem(reqGrp, _labelMemory, formatBytesString(remInfo.reqAppMem, false, true));
    reqGrp.items.push(getNetBandwidthItemInfo(commsInfo.allRemIOChassis));
    return reqGrp;
}

const _oldGetNetBandwidthItemInfo = (remInfo: RemIOChasCommsReqd): DetailItem => {
    const totalBPS = remInfo.bpsToCtlr + remInfo.bpsFromCtlr;
    const item: DetailItem = {
        name: _labelBPS,
        value: formatBytesString(totalBPS, true, true) + ' per second'
    };
    return item;
}

export const getNetBandwidthItemInfo = (remInfo: RemIOChasCommsReqd): DetailItem => {
    const totalBytesPerSec = remInfo.bpsToCtlr + remInfo.bpsFromCtlr;
    const totalBitsPerSec = totalBytesPerSec * 8;

    let fmtValue = formatBitsPerSecond(totalBitsPerSec);

    if (IncludeNetBandwidthInBytes) {
        logger.log('Full bytes per sec: ' + totalBytesPerSec);
        const oldFmtItem = _oldGetNetBandwidthItemInfo(remInfo);
        fmtValue += (' (' + oldFmtItem.value + ')');
    }
    const item: DetailItem = {
        name: _labelBPS,
        value: fmtValue
    };
    return item;
}

// Given a catNum expected to be a controller, return the
// amount of memory that could be gained via up-sizing.
// If the maxBump arg is true, we return the gain if we
// bumped up to the largest associated size available.
// If false, we return the gain if we bumped up to the
// NEXT size available.
// In either case, if any gain, we also return the catNo
// we would upSize to.
export const getAddedCtlrMemViaUpsize = (platform: string, startCat: string, maxBump: boolean):
    [upsizeCat: string, memGain: number] => {

    // Try to get the 'next' upsize of the catNo
    // provided, if one has been defined. Note that
    // the getUpsize function is guaranteed NOT to
    // give us the SAME catalog number back.
    let toCat = getUpsizeCatalogNum(platform, startCat);

    // If we can...
    if (toCat.length > 0) {

        // If we're supposed to get the
        // max upsize possible...
        if (maxBump) {

            // Iteratively request the next upsize
            // from each catalog number returned
            // until we don't get anything back.
            let stillUpsizing = true;

            // While we're not done...
            while (stillUpsizing) {

                // See if there's another upsize...
                const nextUpsize = getUpsizeCatalogNum(platform, toCat);

                // If we got one, replace our toCat with the
                // new one and loop again. Otherwise, we're done.
                if (nextUpsize.length > 0) {
                    toCat = nextUpsize;
                }
                else {
                    stillUpsizing = false;
                }
            }
        }

        // Here we should have two catalog numbers, the
        // one we started with, and the one we could
        // upsize to (max or not). Get the memory
        // supported by each.
        const startMem = getControllerRoleFor(platform, startCat).memory;
        let endMem = getControllerRoleFor(platform, toCat).memory;

        // If we did NOT max upsize already...
        if (!maxBump) {
            // Try to get the next upsize that has more memory
            // then what we started with.
            while (endMem <= startMem) {
                const oldToCat = toCat;
                toCat = getUpsizeCatalogNum(platform, toCat);
                if (toCat) {
                    endMem = getControllerRoleFor(platform, toCat).memory;
                }
                else {
                    toCat = oldToCat;
                    break;
                }
            }
        }

        // If the upsize's is greater, return
        // the upsize catNo and the mem gain.
        if (endMem > startMem) {
            return [toCat, (endMem - startMem)];
        }
    }

    // If we get here, there's either no
    // upsize available, or the one we were
    // given results in no memory gain.
    return ['', 0];
}

const isPlatformValidForCtrlAutoFix = (platform: string): boolean => {
    // A platform is valid for Controller
    // Auto-Fixes when it contains controllers.
    switch (platform) {
        case PlatformCLX:
        case PlatformCpLX:
            return true;
        case PlatformFlex:
        case PlatformFlexHA:
        case PlatformMicro:
            return false;
        default:
            break;
    }

    throw new Error(`isPlatformValidForCtrlAutoFix(): Platform (${platform}) not recognized`)
}


// doPostHWCreatePerformance() Is called within a Platform's
// CreateHardwareFromSettings(). This is a general function
// executed after ALL of the hardware is generated from the
// Design Page, but before we update layouts and move onto
// the Layout Page.
export const doPostHWCreateCorrections = (project: ChassisProject) => {
    // Clearout any AutoFix data and run
    // the performance/comm calcs on the
    // system. Note: A full check is done
    // again after any adjustments made here.
    clearAllAutoFixData();
    runCommCalcs(project);

    // Check if we have an auto-fix to update
    // the controller to handle safety I/O.
    let af = getAutoFix(getFirstAutoFixIDByType(AFType.Safety_NoSafetyCtlrInConfig));
    if (af) {
        // Apply the fix.
        executeAutoFix(af.platform, af, project, () => { return });
    }

    // Do we have a system performance AutoFix...
    af = getAutoFix(getFirstAutoFixIDByType(AFType.Sys_Performance));
    if (af) {
        // Apply it.
        executeAutoFix(af.platform, af, project, () => { return });
    }
}
