import { EngInfoChassis, EngInfoComponent, EngInfoModule } from "../engData/EngineeringInfo";
import {
    addChassis,
    copyAccys,
    getNumBanks,
    getBankInfo,
    getChassisName,
    getDefaultImageSource,
    getModuleInSlot,
    getProjectFromChassis,
    isValidSlotNumber,
    updateRackLayout,
    getAuxBank,
    copyAuxBank
} from "../model/ChassisProject";
import { DetailGroup } from "../model/DeviceDetail";
import { SlotContent } from "../projTree/ProjectTreeChassis";
import { SelectedCompInfo } from "../selectComponents/SelectComponentsTypes";
import { LogImplDefaults } from "../types/Globals";
import { ActBtnInfo, LayoutActionType } from "../types/LayoutActions";
import { DfltStageBaseScale, DfltStageZoomBy, DfltStageZoomRangeFactor, StageUnitsPerMM } from "../types/StageTypes";
import {
    Chassis,
    ChassisElement,
    ChassisModule,
    ChassisProject,
    ChassisRendType,
    DeviceCategory,
    MicroChassis,
    ModuleDragStatus,
    ModuleSlotRestriction,
    NO_SLOT,
    Rack,
    SelectableDevice,
    SlotIDError,
    GraphicalDevice,
    AuxBank,
    BankExpModule,
} from "../types/ProjectTypes";
import {
    PowerBreakdown,
    PowerBreakdownTips,
} from "../types/PowerTypes";
import { LocAndSize, Point, Size } from "../types/SizeAndPosTypes";
import { getUniqueChassisName } from "../util/CheckerAutoFixProjHelpers";
import { DragDeviceInfo, DropResult, DropStatus } from "../util/DragAndDropHelp";
import {
    getAvailableModules,
    getChassisEngInfo,
    getModuleEngInfo
} from "../util/EngInfoHelp";
import { getEmptyLoc, getEmptyPt, getLocCenter, isPointInLoc, scaleSize } from "../util/GeneralHelpers";
import { StageScaleInfo } from "../types/StageTypes";
import { LayoutMode } from "../util/LayoutModeHelp";
import { logger } from "../util/Logger";
import { getEmptyPowerBreakdown } from "../util/PowerHelp";
import { areUndoSnapshotsSuspended, chassisChanging, suspendUndoSnapshots } from "../util/UndoRedo";
import { createModuleFor } from "../platforms/common/Common";
import { microDeleteBaseUnitAtChassis, microIsPointOnBU } from "../platforms/micro/model/MicroGeneralImpl";
import { getSysDsgDefChassisImg, RendInfo } from "../util/SysDsgHelp";

// Registered General Implementations.
const _generalImplRegistry = new Map<string, GeneralImplSpec>();

// Specifications for optional platform-specific routes.
interface _implCreateChassis {
    (chasEngInfo: EngInfoChassis, psCatNo?: string, buCatNo?: string): Chassis | undefined;
}

interface _implReplaceChassisPowerSupply {
    (chassis: Chassis, psCatNo: string): boolean;
}

interface _implConfigureChassis {
    (
        project: ChassisProject,
        platform: string,
        selectChassisCallback: (chassis: Chassis | undefined) => void,
        selectDeviceCallback: (device: SelectableDevice | undefined) => void,
        contentChangedCallback: () => void,
        chassis?: Chassis): void;
}

interface _implGetNumSlots {
    (chassis: Chassis): number;
}

interface _implGetChassisSlotUsage {
    (chassis: Chassis): [slotsInUse: number, slotFillers: number, lastUsedSlot: number];
}

interface _implGetSlotID {
    (chassis: Chassis, slotNum: number): string;
}

interface _implCreateModule {
    (catNo: string): ChassisModule | null;
}

interface _implAddModuleToChassis {
    (chassis: Chassis, module: ChassisModule): boolean;
}

interface _implAddNewModuleAtSlot {
    (chassis: Chassis, slot: number): boolean;
}

interface _implAddModuleAtSlot {
    (chassis: Chassis, catNo: string, slot: number, envMismatchOk: boolean, specialContext: boolean): boolean;
}

interface _implDeleteModuleAtSlot {
    (chassis: Chassis, slot: number): boolean;
}

interface _implOnChassisLoaded {
    (chassis: Chassis): void;
}

export interface _implGetImageSource {
    (category: DeviceCategory, imgName: string): string;
}

interface _implGetChassisElementAtPt {
    (chassis: Chassis, ptLocal: Point):
        [element: ChassisElement, slotOrBank: number, rightSide: boolean];
}

interface _implGetChassisDropStatus {
    (chassis: Chassis, dragInfo: DragDeviceInfo,
        touchEl: ChassisElement, slotOrBank: number): DropStatus;
}

interface _implDropDragDeviceOnChassis {
    (chassis: Chassis, dragInfo: DragDeviceInfo, touchEl: ChassisElement,
        slot: number): DropResult;
}

interface _implDuplicateChassis {
    (chassis: Chassis, insertCopyAt: number): Chassis | null;
}

export interface ActionRequestFuncType {
    (type: LayoutActionType, chassis: Chassis, slotNum: number): void;
}

//export enum ChassisRendType {
//    Standard = 'Standard',
//    RedPrimary = 'RedPrimary',
//    RedSecondary = 'RedSecondary'
//}

// WCS - NOTE: The .bumper property was
// added as a simple means of being able to make sure a 
// ChassisComp gets re-rendered, when all of the other props
// we send to it are otherwise unchanged.
// ChassisComp is now memo-ised and without at least ONE prop change,
// a memo copy of the previous rendering is used, even if something
// else changed INSIDE of our chassis (like adding or removing a
// module). The bumper prop always just gets whatever's in the
// Chassis object's .bump (formerly .renderBump) property.
export interface ChassisCompProps {
    chassis: Chassis;
    bumper: number;
    ptOrg: Point;
    showAsSelected?: boolean;
    localDeviceSelected?: SelectableDevice;
    renderType?: ChassisRendType;
    layoutMode?: LayoutMode;
}

export interface MicroChassisCompProps {
    chassis: MicroChassis;
    bumper: number;
    ptOrg: Point;
    showAsSelected?: boolean;
    localDeviceSelected?: SelectableDevice;
    renderType?: ChassisRendType;
    layoutMode?: LayoutMode;
}

interface _implGetChassisRenderer {
    (): React.FC<ChassisCompProps> | undefined;
}

interface _implGetChassisDetails {
    (chassis: Chassis): DetailGroup[];
}

interface _implGetDeviceDetails {
    (device: SelectableDevice): DetailGroup[];
}

interface _implGetPowerDetails {
    (chassis: Chassis): [supplied: PowerBreakdown, consumed: PowerBreakdown];
}

interface _implGetSlotLocation {
    (chassis: Chassis, slotNum: number): LocAndSize;
}

interface _implGetBankExpLoc {
    (bankExp: BankExpModule): LocAndSize;
}

interface _implGetSlotFillerInfo {
    (chassis: Chassis): [string, boolean];
}

interface _implGetActBtnInfo {
    (action: LayoutActionType, rack: Rack, slotNum: number): ActBtnInfo;
}

interface _implGetMaxNewModules {
    (chassis: Chassis, restrict: ModuleSlotRestriction, treatSlotFillerAsEmptySlot: boolean): number;
}

interface _implGetDefaultChassisName {
    (chassis: Chassis): string;
}

interface _implGetSlotTypeRestriction {
    (chassis: Chassis, slotNum: number): ModuleSlotRestriction;
}

interface _implFilterAvailableModules {
    (chassis: Chassis, restrict: ModuleSlotRestriction, unfiltered: EngInfoModule[]): EngInfoModule[];
}

interface _implCanExtendChassis {
    (chassis: Chassis): boolean;
}

interface _implCanModuleBeAdded {
    (module: ChassisModule, chassis: Chassis): number;
}

interface _implGetModuleSlotRestriction {
    (modInfo: EngInfoComponent): ModuleSlotRestriction;
}

interface _implUpdateChassisLayout {
    (chassis: Chassis): void;
}

interface _implAddModulesToChassis {
    (chassis: Chassis, sels: SelectedCompInfo[],
        totalQty: number, targetSlot: number): void;
}

interface _implGetChassisSizeAsDrawn {
    (chassis: Chassis, copyMode: boolean): Size;
}

interface _implGetXSlotWidthFor {
    (modInfo: EngInfoComponent): number;
}

interface _implGetDefaultXSlotWidth {
    (platform: string): number;
}

interface _implGetEmptySlotImage {
    (chassis: Chassis): string;
}

interface _implDoesSlotQualifyForBtnAction {
    (mode: LayoutMode, type: LayoutActionType, chassis: Chassis, slotNum: number): boolean;
}

interface _implIsPointOnPS {
    (ptLocal: Point, chassis: Chassis): boolean;
}

interface _implIsPointOnBankExp {
    (ptLocal: Point, chassis: Chassis): [boolean, number, boolean];
}

interface _implGetBankExpComp {
    (chassis: Chassis, bank: number, rightSide: boolean)
        : BankExpModule | undefined;
}

interface _implGetXSlotLoc {
    (chassis: Chassis): LocAndSize;
}

interface _implgetSlotContent {
    (chassis: Chassis): SlotContent[];
}

interface _implgetChassisNameSelection {
    (chassis: Chassis): string;
} 

interface _implGetLayoutScaleInfo {
    (): StageScaleInfo;
}

interface _implGetDevicePowerTips {
    (device: GraphicalDevice, singleLineFrm?: boolean): PowerBreakdownTips;
}

interface _implSetNumBanks {
    (chassis: Chassis, qty: number): boolean;
}

interface _implDeleteBankExpMod {
    (chassis: Chassis, expMod: BankExpModule): boolean;
}

interface _implGetChassisRendInfo {
    (chassis: Chassis): RendInfo[];
}

interface _implGetExternalPSU {
    (chassis: Chassis, idPSU: number): SelectableDevice | undefined;
}

interface _implCanDeletePowerSupply {
    (psu: SelectableDevice): boolean;
}

interface _implDeletePowerSupply {
    (psu: SelectableDevice): boolean;
}

// Routing specifications for a platform.
export interface GeneralImplSpec {
    // Required
    platform: string;
    imageScaleFactor: number;

    // Optional
    createChassis?: _implCreateChassis;
    replaceChassisPowerSupply?: _implReplaceChassisPowerSupply,
    configureChassis?: _implConfigureChassis,
    getNumSlots?: _implGetNumSlots;
    getChassisSlotUsage?: _implGetChassisSlotUsage;
    getSlotID?: _implGetSlotID;
    createModule?: _implCreateModule;
    addModuleToChassis?: _implAddModuleToChassis;
    addNewModuleAtSlot?: _implAddNewModuleAtSlot;
    addModuleAtSlot?: _implAddModuleAtSlot;
    deleteModuleAtSlot?: _implDeleteModuleAtSlot;
    onChassisLoaded?: _implOnChassisLoaded;
    getImageSource?: _implGetImageSource;
    getChassisElementAtPt?: _implGetChassisElementAtPt;
    getChassisDropStatus?: _implGetChassisDropStatus,
    dropDragDeviceOnChassis?: _implDropDragDeviceOnChassis;
    duplicateChassis?: _implDuplicateChassis;
    getChassisRenderer?: _implGetChassisRenderer;
    getChassisDetails?: _implGetChassisDetails;
    getDeviceDetails?: _implGetDeviceDetails;
    getPowerDetails?: _implGetPowerDetails;
    getSlotLocation?: _implGetSlotLocation;
    getBankExpLoc?: _implGetBankExpLoc;
    getSlotFillerInfo?: _implGetSlotFillerInfo;
    getActBtnInfo?: _implGetActBtnInfo;
    getMaxNewModules?: _implGetMaxNewModules;
    getDefaultChassisName?: _implGetDefaultChassisName;
    filterAvailableModules?: _implFilterAvailableModules;
    canExtendChassis?: _implCanExtendChassis;
    canModuleBeAdded?: _implCanModuleBeAdded;
    getSlotTypeRestriction?: _implGetSlotTypeRestriction,
    getModuleSlotRestriction?: _implGetModuleSlotRestriction;
    updateChassisLayout?: _implUpdateChassisLayout;
    addModulesToChassis?: _implAddModulesToChassis;
    getChassisSizeAsDrawn?: _implGetChassisSizeAsDrawn;
    getXSlotWidthFor?: _implGetXSlotWidthFor;
    getDefaultXSlotWidth?: _implGetDefaultXSlotWidth;
    getEmptySlotImage?: _implGetEmptySlotImage;
    doesSlotQualifyForBtnAction?: _implDoesSlotQualifyForBtnAction;
    isPointOnPS?: _implIsPointOnPS;
    isPointOnBankExp?: _implIsPointOnBankExp;
    getBankExpComp?: _implGetBankExpComp;
    getXSlotLoc?: _implGetXSlotLoc;
    getSlotContent?: _implgetSlotContent;
    getChassisNameSelection?: _implgetChassisNameSelection;
    getLayoutScaleInfo?: _implGetLayoutScaleInfo;
    getDevicePowerTips?: _implGetDevicePowerTips;
    setNumBanks?: _implSetNumBanks;
    deleteBankExpMod?: _implDeleteBankExpMod;
    getChassisRendInfo?: _implGetChassisRendInfo;
    getExternalPSU?: _implGetExternalPSU;
    canDeletePowerSupply?: _implCanDeletePowerSupply;
    deletePowerSupply?: _implDeletePowerSupply;
}


// Implementation registration.
export const RegisterGeneralImpl = (impl: GeneralImplSpec) => {
    const platformID = impl.platform.trim();
    if (platformID && platformID === impl.platform) {
        if (!_generalImplRegistry.has(platformID)) {
            _generalImplRegistry.set(platformID, impl);
        }
        else {
            logger.warn('RegisterGeneralImpl ignoring duplicate register: ' + impl.platform);
        }
    }
    else {
        throw new Error('Invalid platform ID in RegisterGeneralImpl!');
    }
}


// Implementation retrieval - NOT EXPORTED.
const _getImplementation = (platform: string): GeneralImplSpec | undefined => {
    return _generalImplRegistry.get(platform);
}


// EXPORTED API
// NOTE: In ANY of the following, an empty or invalid
// platform ID will result in default functionality.

export const createChassis = (platform: string, chassisCat: string, psCatNo?: string, buCatNo?: string):
    Chassis | undefined => {

    const chasEngInfo = getChassisEngInfo(platform, chassisCat);
    if (chasEngInfo) {
        const impl = _getImplementation(platform);
        if (impl && impl.createChassis) {
            return impl.createChassis(chasEngInfo, psCatNo, buCatNo);
        }

        // Default - Do nothing
        if (LogImplDefaults && platform) {
            logger.logImpl('No createChassis implemented for: ' + platform);
        }
    }
    else {
        logger.error('createChassis got invalid chassis cat for platform ' +
            platform + ': ' + chassisCat)
    }


    return undefined;
}

export const replaceChassisPowerSupply = (chassis: Chassis, psCatNo: string):
    boolean => {

    const impl = _getImplementation(chassis.platform);
    if (impl && impl.replaceChassisPowerSupply) {
        return impl.replaceChassisPowerSupply(chassis, psCatNo);
    }

    // Default - Do nothing
    if (LogImplDefaults && chassis.platform) {
        logger.logImpl('No replaceChassisPowerSupply implemented for: ' + chassis.platform);
    }

    return false;
}

export const configureChassis = (
    platform: string,
    project: ChassisProject,
    selectChassisCallback: (chassis: Chassis | undefined) => void,
    selectDeviceCallback: (device: SelectableDevice | undefined) => void,
    contentChangedCallback: () => void,
    chassis?: Chassis
) => {

    const impl = _getImplementation(platform);
    if (impl && impl.configureChassis) {
        impl.configureChassis(
            project,
            platform,
            selectChassisCallback,
            selectDeviceCallback,
            contentChangedCallback,
            chassis);
    }
    else {
        // Default - Do nothing
        if (LogImplDefaults && platform) {
            logger.logImpl('No configureChassis implemented for: ' + platform);
        }
    }
}

export const getNumSlots = (chassis: Chassis): number => {

    // Allow platorm to override.
    const impl = _getImplementation(chassis.platform);
    if (impl && impl.getNumSlots) {
        return impl.getNumSlots(chassis);
    }

    // Otherwise, std is just length of modules array.
    return chassis.modules.length;
}

const _addBankContent = (chassis: Chassis, bank: number,
    content: SlotContent[]) => {

    let lastWasEmpty = false;

    const info = getBankInfo(chassis, bank);
    const lastSlot = info.startSlot + info.slotsInBank - 1;

    for (let slot = info.startSlot; slot <= lastSlot; slot++) {
        const mod = getModuleInSlot(chassis, slot);
        if (!mod && lastWasEmpty) {
            content[content.length - 1].toSlotNum += 1;
        }
        else {
            content.push({
                slotNum: slot,
                toSlotNum: slot,
                module: mod,
                selected: ((mod !== undefined) && mod.selected)
            });
            lastWasEmpty = (mod === undefined);
        }
    }
}

export const getSlotContent = (chassis:Chassis) : SlotContent[] => {

    // Allow platorm to override.
    const impl = _getImplementation(chassis.platform);
    if (impl && impl.getSlotContent) {
        return impl.getSlotContent(chassis);
    }

    // Default Behavior: Create new empty content array.
    const content = new Array<SlotContent>();

    // Walk the banks (if any)...
    const numBanks = getNumBanks(chassis);
    for (let bank = 0; bank < numBanks; bank++) {

        // If past the primary...
        if (bank > 0) {
            // Get the aux info.
            const aux = getAuxBank(chassis, bank - 1);

            // If it has any extra components required...
            if (aux.compsReqd) {
                // Then for each...
                for (let compIdx = 0; compIdx < aux.compsReqd.length; compIdx++) {
                    // Push a special using 'be:' for a slot
                    // ID, indicating bank extension component.
                    content.push({
                        slotNum: NO_SLOT,
                        toSlotNum: NO_SLOT,
                        module: undefined,
                        selected: false,
                        spSlotId: 'be:',
                        spLabel: aux.compsReqd[compIdx]
                    });
                }
            }
        }
        _addBankContent(chassis, bank, content);
    }

    //const numSlots = getNumSlots(chassis);

    //let lastWasEmpty = false;

    //for (let slot = 0; slot < numSlots; slot++) {
    //    const mod = getModuleInSlot(chassis, slot);
    //    if (!mod && lastWasEmpty) {
    //        content[content.length - 1].toSlotNum += 1;
    //    }
    //    else {
    //        content.push({
    //            slotNum: slot,
    //            toSlotNum: slot,
    //            module: mod,
    //            selected: ((mod !== undefined) && mod.selected)
    //        });
    //        lastWasEmpty = (mod === undefined);
    //    }
    //}

    return content;
}



export const getChassisSlotUsage = (chassis?: Chassis):
    [slotsInUse: number, slotFillers: number, lastUsedSlot: number] => {

    if (chassis) {
        const impl = _getImplementation(chassis.platform);
        if (impl && impl.getChassisSlotUsage) {
            return impl.getChassisSlotUsage(chassis);
        }

        // Default - Do nothing
        if (LogImplDefaults && chassis.platform) {
            logger.logImpl('No getChassisSlotUsage implemented for: ' + chassis.platform);
        }
    }

    return [0, 0, -1];
}

export const getSlotID = (chassis: Chassis, slotNum: number): string => {

    const impl = _getImplementation(chassis.platform);
    if (impl && impl.getSlotID) {
        return impl.getSlotID(chassis, slotNum);
    }

    // Default
    const mod = chassis.modules[slotNum];
    if (mod) {
        // If the module is single-slot or has 'No Slot' ID...
        if (mod.slotsUsed <= 1 || mod.slotID === NO_SLOT)
            return mod.slotID.toString();

        const lastSlot = mod.slotID + (mod.slotsUsed - 1);
        return `${mod.slotID}..${lastSlot}`
    }
    else {
        if (isValidSlotNumber(chassis, slotNum)) {
            return slotNum.toString();
        }
        else {
            return SlotIDError.InvalidSlotNum;
        }
    }
}

export const getImageScale = (platform: string): number => {
    const impl = _getImplementation(platform);
    return (impl) ? impl.imageScaleFactor : 1.0;
}

export const createModule = (platform: string, catNo: string): ChassisModule | null => {

    const impl = _getImplementation(platform);
    if (impl && impl.createModule) {
        return impl.createModule(catNo);
    }

    // Default
    const modInfo = getModuleEngInfo(platform, catNo);
    if (modInfo) {
        return createModuleFor(modInfo);
    }

    return null;
}

export const addModuleToChassis = (chassis: Chassis, module: ChassisModule): boolean => {

    const impl = _getImplementation(chassis.platform);
    if (impl && impl.addModuleToChassis) {
        return impl.addModuleToChassis(chassis, module);
    }

    // Default - Do nothing
    if (LogImplDefaults && chassis.platform) {
        logger.logImpl('No addModuleToChassis implemented for: ' + chassis.platform);
    }

    return false;
}

export const addNewModuleAtSlot = (chassis: Chassis, slot: number): boolean => {

    const impl = _getImplementation(chassis.platform);
    if (impl && impl.addNewModuleAtSlot) {
        return impl.addNewModuleAtSlot(chassis, slot);
    }

    // Default - Do nothing
    if (LogImplDefaults && chassis.platform) {
        logger.logImpl('No addNewModuleAtSlot implemented for: ' + chassis.platform);
    }

    return false;
}

export const addModuleAtSlot = (chassis: Chassis, catNo: string,
    slot: number, envMismatchOk = false, specialContext = false): boolean => {

    const impl = _getImplementation(chassis.platform);
    if (impl && impl.addModuleAtSlot) {
        return impl.addModuleAtSlot(chassis, catNo, slot, envMismatchOk, specialContext);
    }

    // Default - Do nothing
    if (LogImplDefaults && chassis.platform) {
        logger.logImpl('No addModuleAtSlot implemented for: ' + chassis.platform);
    }

    return false;
}

export const deleteModuleAtSlot = (chassis: Chassis, slotNum: number): boolean => {

    const impl = _getImplementation(chassis.platform);
    if (impl && impl.deleteModuleAtSlot) {
        return impl.deleteModuleAtSlot(chassis, slotNum);
    }

    // Default - Do nothing
    if (LogImplDefaults && chassis.platform) {
        logger.logImpl('No deleteModuleAtSlot implemented for: ' + chassis.platform);
    }

    return false;
}

export const deleteModule = (chassis: Chassis, module: ChassisModule): boolean => {
    if (module.parent === chassis) {
        if (module.isBankExp) {
            const impl = _getImplementation(chassis.platform);
            if (impl && impl.deleteBankExpMod) {
                const bankExp = module as BankExpModule;
                return impl.deleteBankExpMod(chassis, bankExp);
            }
        }
        else if (module.slotIdx >= 0) {
            return deleteModuleAtSlot(chassis, module.slotIdx);
        }
    }
    return false;
}

// Called to make any modifications required after
// the chassis was loaded (from JSON). Rehooks parent refs, etc.
export const onChassisLoaded = (chassis: Chassis): void => {

    //console.warn('onChassisLoaded')

    // Standard for all chassis.
    // Set parent refs on modules and ps.
    const numSlots = chassis.modules.length;
    for (let slot = 0; slot < numSlots; slot++) {
        const mod = chassis.modules[slot];
        if (mod) {
            mod.parent = chassis;
        }
        else {
            // When loaded from JSON, empty slots can end up
            // with values of 'null'.If we find any such cases
            // here, we'll set the values to 'undefined' to match
            // the actual definition of our .modules array.
            if (mod === null) {
                chassis.modules[slot] = undefined;
            }
        }
    }

    if (chassis.ps) {
        chassis.ps.parent = chassis;
    }
    const microchassis  = chassis as MicroChassis
    if (microchassis.bu) {
        microchassis.bu.parent = chassis;
    }

    if (chassis.auxBanks) {
        chassis.auxBanks.forEach(bank => {
            bank.parent = chassis;
        })
    }

    chassis.dragTarget = false;
    chassis.xSlotWidth = 0;

    // Allow platform to do anything additional reqd.
    const impl = _getImplementation(chassis.platform);
    if (impl && impl.onChassisLoaded) {
        return impl.onChassisLoaded(chassis);
    }
}

export const getImageSource = (platform: string,
    category: DeviceCategory, imgName: string): string => {

    const impl = _getImplementation(platform);
    if (impl && impl.getImageSource) {
        return impl.getImageSource(category, imgName);
    }

    // Default
    return getDefaultImageSource(imgName);
}

// Get the location of an 'X' (extra) slot, used by
// some platforms during drag and drop operations.
export const getXSlotLoc = (chassis: Chassis): LocAndSize => {

    const impl = _getImplementation(chassis.platform);
    if (impl && impl.getXSlotLoc) {
        return impl.getXSlotLoc(chassis);
    }

    // Default...
    // WARNING: Must override for platforms
    // that utilize aux banks (like FlexHA).
    // If the chassis is currently tagged as a drag
    // target AND it has a positive X Slot width...
    if (chassis.dragTarget && (chassis.xSlotWidth > 0)) {

        // Get a copy of the last 'real' slot loc.
        const slotLoc = { ...chassis.layout.slotLocs[chassis.layout.slotLocs.length - 1] };

        // Shift right by its own width.
        slotLoc.x += slotLoc.width;

        // Then set the width to the XSlot
        // width value and return.
        slotLoc.width = chassis.xSlotWidth;
        return slotLoc;
    }

    // Just return an empty loc.
    return getEmptyLoc();
}

export const getChassisElementAtPt = (chassis: Chassis, ptLocal: Point):
    [
        element: ChassisElement,
        slotOrBank: number,
        rightSide: boolean  // Used only for slots and BankExp
    ] => {

    const impl = _getImplementation(chassis.platform);
    if (impl && impl.getChassisElementAtPt) {
        return impl.getChassisElementAtPt(chassis, ptLocal);
    }

    return standardGetChassisElementAtPt(chassis, ptLocal);
}

export const standardGetChassisElementAtPt = (chassis: Chassis, ptLocal: Point):
    [
        element: ChassisElement,
        slotOrBank: number,
        rightSide: boolean  // Used only for slots and BankExp
    ] => {
    // Default behavior.
    // If the chassis is currently tagged as a drag
    // target with an 'X' slot specified...
    if (chassis.dragTarget && (chassis.xSlotWidth > 0)) {

        // Get the loc of that extra slot.
        const xSlotLoc = getXSlotLoc(chassis);

        // If our point is inside, return as a slot
        // using the NEXT slot idx that WOULD be available.
        if (isPointInLoc(ptLocal, xSlotLoc)) {
            return [ChassisElement.Slot, chassis.modules.length, false];
        }
    }

    // If we're still here, get overall size as loc.
    const loc: LocAndSize = {
        x: 0,
        y: 0,
        width: chassis.layout.size.width,
        height: chassis.layout.size.height
    };

    // If the point is inside...
    if (isPointInLoc(ptLocal, loc)) {

        // If the chassis has a ps, and the point
        // is in the loc it provides...
        //if (chassis.ps && isPointInLoc(ptLocal, chassis.ps.loc)) {
        if (isPointOnPS(ptLocal, chassis)) {

            // We're pointing at the PS.
            return [ChassisElement.PS, NO_SLOT, false];
        }
        // We're pointing at the Base Unit/ Controller of micro 800.
        else if (microIsPointOnBU(ptLocal, chassis)) {

            // We're pointing at the PS.
            return [ChassisElement.BU, NO_SLOT, false];
        }

        const [onExp, bank, bankRight] = isPointOnBankExp(ptLocal, chassis);
        if (onExp) {
            return [ChassisElement.BankExp, bank, bankRight];
        }

        // Otherwise, we'll look for a hit
        // on one of the chassis' slots.
        const lastSlotIdx = chassis.modules.length - 1;
        const microChassis = chassis as MicroChassis
        let startIDx = 0;
        if (microDeleteBaseUnitAtChassis(chassis)) {
            startIDx = microChassis?.pluginModules?.length || 0
        }

        // For each slot...
        for (let slotIdx = startIDx; slotIdx <= lastSlotIdx; slotIdx++) {

            // Call a helper to get the the associated loc.
            // NOTE: Simply looking directly at the slotLocs
            // array in the chassis' layout WILL NOT WORK
            // if the chassis has multiple banks!
            const loc = getSlotLocation(chassis, slotIdx);

            // If we're pointing at the loc...
            if (isPointInLoc(ptLocal, loc)) {

                // Return pertinent info.
                const ctrLoc = getLocCenter(loc);
                const rightSide = (ptLocal.x > ctrLoc.x);
                return [ChassisElement.Slot, slotIdx, rightSide];
            }
        }

        // If we're still here, we're pointing at the
        // chassis somewhere, but NOT at a slot.
        return [ChassisElement.Chassis, NO_SLOT, false];
    }
    else {
        // Point is NOT on the chassis at all.
        return [ChassisElement.None, NO_SLOT, false];
    }
}

export const getChassisDropStatus = (chassis: Chassis, dragInfo: DragDeviceInfo,
    touchEl: ChassisElement, slotOrBank: number): DropStatus => {

    const impl = _getImplementation(chassis.platform);
    if (impl && impl.getChassisDropStatus) {
        return impl.getChassisDropStatus(chassis, dragInfo, touchEl, slotOrBank);
    }

    // Default - Do nothing
    if (LogImplDefaults && chassis.platform) {
        logger.logImpl('No getChassisDropStatus implemented for: ' + chassis.platform);
    }

    return DropStatus.NoDrop;
}

export const dropDragDeviceOnChassis = (
    chassis: Chassis,
    dragInfo: DragDeviceInfo,
    touchEl: ChassisElement,
    slot: number,
): DropResult => {

    dragInfo.dragMod.dragStatus = ModuleDragStatus.NA;

    const impl = _getImplementation(chassis.platform);
    if (impl && impl.dropDragDeviceOnChassis) {
        return impl.dropDragDeviceOnChassis(chassis, dragInfo,
            touchEl, slot);
    }

    // Default - Do nothing
    if (LogImplDefaults && chassis.platform) {
        logger.logImpl('No dropDragDeviceOnChassis implemented for: ' + chassis.platform);
    }

    return DropResult.DropFailed;
}

export const defDuplicateChassis = (chassis: Chassis, insertCopyAt: number): Chassis | null => {
    // Get the associated project.
    const project = getProjectFromChassis(chassis);

    // If we can...
    if (project) {

        const psCat = chassis.ps ? chassis.ps.catNo : undefined;

        // Add a new chassis, using the catNos of the
        // old and of its power supply (if it has one).
        const dup = addChassis(project, chassis.platform, chassis.catNo,
            insertCopyAt, psCat);

        // If the add worked...
        if (dup) {

            // orig here is still the same as chassis,
            // but 'orig' makes the following easier to
            // follow.
            const orig = chassis;

            // Our dup chassis should now have whatever
            // REQUIRED accys were appropriate added to
            // the chassis itself and its dup'd power supply.
            // However, we want it to be a real duplicate,
            // so we'll copy accys from orig to dup here for
            // both of those elements.
            copyAccys(orig, dup);
            if (orig.ps && dup.ps) {
                copyAccys(orig.ps, dup.ps);
            }

            // Copy default I/O wiring type.
            dup.defaultIOModWiring = chassis.defaultIOModWiring;

            // Start optimistic regarding
            // module duplication results.
            let addFailed = false;

            // Walk all modules in the orig chassis. For each...
            for (let slot = 0; slot < orig.modules.length; slot++) {

                // Get the original module (if there IS one at the slot).
                const origMod = orig.modules[slot];

                // If so...
                if (origMod) {

                    // Using the original's catNo, add the same module
                    // to the dup chassis at the same slot.
                    // If the add works...
                    if (addModuleAtSlot(dup, origMod.catNo, slot, true)) {

                        // Get the new module from the dup chassis.
                        const newMod = dup.modules[slot];

                        // If we can...
                        if (newMod) {
                            // Then COPY whatever accys found on
                            // the original module to the new one.
                            copyAccys(origMod, newMod);

                            // Bump the slot by the amount of
                            // slots occupied beyond 1.
                            slot += newMod.slotsUsed - 1;
                        }
                        else {
                            addFailed = true;
                        }
                    }
                    else {
                        addFailed = true;
                    }
                }
            }

            // Deal with any problems encountered above
            // during the module dup'ing process.
            if (addFailed) {
                throw new Error('ERROR: _stdDupChassis could not duplicate all modules!');
            }

            if (orig.auxBanks) {
                dup.auxBanks = new Array<AuxBank>();
                for (let auxIdx = 0; auxIdx < orig.auxBanks.length; auxIdx++) {
                    const aux = orig.auxBanks[auxIdx];
                    dup.auxBanks.push(copyAuxBank(aux, dup));
                }
            }

            return dup;
        }
    }
    return null;
}

export const duplicateChassis = (chassis: Chassis, insertCopyAt: number): Chassis | null => {

    let dup: Chassis | null = null;

    // If there's a platform-specific implementation,
    // use it. Otherwise, dup using the std method.
    const impl = _getImplementation(chassis.platform);
    if (impl && impl.duplicateChassis) {
        dup = impl.duplicateChassis(chassis, insertCopyAt);
    }
    else {
        dup = defDuplicateChassis(chassis, insertCopyAt);
    }

    // If we got a duplicate...
    if (dup) {
        // If the original chassis had a name,
        // we'll use the SAME name (plus a decorator) for
        // the duplicate. If the original had no name,
        // we'll leave the duplicate without one as well.
        if (chassis.name && chassis.name.length) {
            const project = getProjectFromChassis(chassis);
            if(project)
                dup.name = getUniqueChassisName(getChassisName(chassis), project);
        }
        return dup;
    }

    return null;
}

export const getChassisRenderer = (platform: string):
    React.FC<ChassisCompProps> | undefined => {

    const impl = _getImplementation(platform);
    if (impl && impl.getChassisRenderer) {
        return impl.getChassisRenderer();
    }

    // Default - Do nothing
    if (LogImplDefaults && platform) {
        logger.logImpl('No getChassisRenderer implemented for: ' + platform);
    }

    return undefined;
}

export const getChassisDetails = (chassis: Chassis): DetailGroup[] => {

    const impl = _getImplementation(chassis.platform);
    if (impl && impl.getChassisDetails) {
        return impl.getChassisDetails(chassis);
    }

    // Default - Do nothing
    if (LogImplDefaults && chassis.platform) {
        logger.logImpl('No getChassisDetails implemented for: ' + chassis.platform);
    }

    return [];
}

export const getDeviceDetails = (device: SelectableDevice): DetailGroup[] => {

    const impl = _getImplementation(device.platform);
    if (impl && impl.getDeviceDetails) {
        return impl.getDeviceDetails(device);
    }

    // Default - Do nothing
    if (LogImplDefaults && device.platform) {
        logger.logImpl('No getDeviceDetails implemented for: ' + device.platform);
    }

    return [];
}

export const getPowerDetails = (chassis: Chassis):
    [supplied: PowerBreakdown, consumed: PowerBreakdown] => {

    const impl = _getImplementation(chassis.platform);
    if (impl && impl.getPowerDetails) {
        return impl.getPowerDetails(chassis);
    }

    // Default - Do nothing
    if (LogImplDefaults && chassis.platform) {
        logger.logImpl('No getPowerDetails implemented for: ' + chassis.platform);
    }
    return [getEmptyPowerBreakdown(), getEmptyPowerBreakdown()];
}

export const getSlotLocation = (chassis: Chassis, slotNum: number): LocAndSize => {

    const impl = _getImplementation(chassis.platform);
    if (impl && impl.getSlotLocation) {
        return impl.getSlotLocation(chassis, slotNum);
    }

    // Default implementation.
    // WARNING: Must override for platforms
    // that utilize aux banks (like FlexHA).
    // If there is an associated entry in the chassis
    // layout's slotLocs array, return it.
    if (slotNum < chassis.layout.slotLocs.length) {
        return chassis.layout.slotLocs[slotNum];
    }

    // If we're still here, just return an empty loc.
    return getEmptyLoc();
}

export const getModuleLocation = (mod: ChassisModule): LocAndSize => {

    // If the module is a bank expander (special comp)...
    if (mod.isBankExp) {
        // See if we have a platform-specific
        // implementation for getting its location,
        // and if so, hand off to it.
        const impl = _getImplementation(mod.platform);
        if (impl && impl.getBankExpLoc) {
            return impl.getBankExpLoc(mod as BankExpModule);
        }
        throw new Error('ERROR: Missing platform implementation for getBankExpLoc!');
    }
    else if (mod.parent) {
        // Not a bank expander. 
        // Hand off to getSlotLocation.
        return getSlotLocation(mod.parent, mod.slotIdx);
    }

    // If we're still here, just return an empty loc.
    return getEmptyLoc();
}

export const getSlotFillerInfo = (chassis: Chassis): [string, boolean] => {

    const impl = _getImplementation(chassis.platform);
    if (impl && impl.getSlotFillerInfo) {
        return impl.getSlotFillerInfo(chassis);
    }

    // Default - Do nothing
    if (LogImplDefaults && chassis.platform) {
        logger.logImpl('No getSlotFillerInfo implemented for: ' + chassis.platform);
    }

    return ['', false];
}

export const hasGetActBtnInfo = (platform: string): boolean => {
    const impl = _getImplementation(platform);
    if (impl && impl.getActBtnInfo) {
        return true;
    }
    return false;
}


export const getActBtnInfo = (action: LayoutActionType, rack: Rack,
    slotNum: number): ActBtnInfo => {
    const impl = _getImplementation(rack.chassis.platform);
    if (impl && impl.getActBtnInfo) {
        return impl.getActBtnInfo(action, rack, slotNum);
    }

    // Default - Do nothing
    if (LogImplDefaults && rack.chassis.platform) {
        logger.logImpl('No getActionBtnInfo implemented for: ' + rack.chassis.platform);
    }

    return {
        action: action,
        chassis: rack.chassis,
        slot: slotNum,
        ctrPt: getEmptyPt()
    };
}

export const getMaxNewModules = (chassis: Chassis, restrict: ModuleSlotRestriction, treatSlotFillerAsEmptySlot: boolean): number => {

    const impl = _getImplementation(chassis.platform);
    if (impl && impl.getMaxNewModules) {
        return impl.getMaxNewModules(chassis, restrict, treatSlotFillerAsEmptySlot);
    }

    // Default - Do nothing
    if (LogImplDefaults && chassis.platform) {
        logger.logImpl('No getMaxNewModules implemented for: ' + chassis.platform);
    }

    return 0;
}

export const getDefaultChassisName = (chassis: Chassis): string => {

    const impl = _getImplementation(chassis.platform);
    if (impl && impl.getDefaultChassisName) {
        return impl.getDefaultChassisName(chassis);
    }

    return 'Chassis';
}

export const getModuleSelectionInfo = (chassis: Chassis, restrict: ModuleSlotRestriction):
    EngInfoModule[] => {

    const allMods = getAvailableModules(chassis);
    if (allMods.length > 0) {
        const impl = _getImplementation(chassis.platform);
        if (impl && impl.filterAvailableModules) {
            return impl.filterAvailableModules(chassis, restrict, allMods);
        }
    }
    return allMods;
}

export const canExtendChassis = (chassis: Chassis): boolean => {
    const impl = _getImplementation(chassis.platform);
    if (impl && impl.canExtendChassis) {
        return impl.canExtendChassis(chassis);
    }
    return false;
}

// Check to see if the specified module COULD be added to
// the given chassis. If yes, answers the first slot that
// would work. If no, answers NO_SLOT (-1).
export const canModuleBeAdded = (module: ChassisModule, chassis: Chassis): number => {

    // FAIL on platform mismatch.
    if (module.platform !== chassis.platform) {
        return NO_SLOT;
    }

    // If we have a platform-specific implementation,
    // pass the request to that and return the result.
    const impl = _getImplementation(chassis.platform);
    if (impl && impl.canModuleBeAdded) {
        return impl.canModuleBeAdded(module, chassis);
    }

    // Default behavior.
    // Checks for enough adjacent slots if
    // module requires more than 1.
    const slotsNeeded = module.slotsUsed;

    let adjacentEmpties = 0;
    let firstEmptySlot = NO_SLOT;

    for (let slotIdx = 0; slotIdx < chassis.modules.length; slotIdx++) {

        // Get whatever's in the slot.
        const mod = chassis.modules[slotIdx];

        // For our purposes here, we'll ignore slot fillers
        // and treat those slots as though they were empty.
        if (mod && !mod.slotFiller) {
            adjacentEmpties = 0;
            firstEmptySlot = NO_SLOT;
        }
        else {
            if (firstEmptySlot === NO_SLOT) {
                firstEmptySlot = slotIdx;
            }
            adjacentEmpties += 1;
            if (adjacentEmpties >= slotsNeeded) {
                return firstEmptySlot;
            }
        }
    }
    return NO_SLOT;
}

export const getSlotTypeRestriction = (chassis: Chassis, slotNum: number): ModuleSlotRestriction => {
    const impl = _getImplementation(chassis.platform);
    if (impl && impl.getSlotTypeRestriction) {
        return impl.getSlotTypeRestriction(chassis, slotNum);
    }
    return ModuleSlotRestriction.None;
}

export const getModuleSlotRestriction = (modInfo: EngInfoComponent): ModuleSlotRestriction => {
    const impl = _getImplementation(modInfo.platform);
    if (impl && impl.getModuleSlotRestriction) {
        return impl.getModuleSlotRestriction(modInfo);
    }
    return ModuleSlotRestriction.None;
}

// Note: Function name changed (from updateChassisLayout).
// It might do MORE than just layout-related updates.
export const updateChassis = (chassis: Chassis, updateParentLayout = true) => {

    // If undo snapshots are currently suspended, so
    // are chassis updates. Just return in that case.
    if (areUndoSnapshotsSuspended()) {
        return;
    }

    // See if there's an implementation provided by
    // the platform to update the chassis' layout.
    const impl = _getImplementation(chassis.platform);

    // If so, call it.
    if (impl && impl.updateChassisLayout) {
        impl.updateChassisLayout(chassis);
    }

    // REGARDLESS of whether anything was done above,
    // we'll do the parent content layout if asked.
    if (updateParentLayout && chassis.parent) {
        updateRackLayout(chassis.parent);
    }
}

export const addModulesToChassis = (chassis: Chassis,
    sels: SelectedCompInfo[],
    totalQty: number,
    targetSlot: number) => {

    // See if there's a platform-specific implementation.
    const impl = _getImplementation(chassis.platform);

    // If so...
    if (impl && impl.addModulesToChassis) {
        // Take an undo snapshot.
        chassisChanging(chassis);

        // Suspend them so we don't get one for
        // every new module added.
        const snapsWereSuspended = suspendUndoSnapshots(true);

        impl.addModulesToChassis(chassis, sels, totalQty, targetSlot);

        // DE-suspend snapshots.
        suspendUndoSnapshots(snapsWereSuspended);

        // Give the chassis a chance to do anything
        // that may have been delayed due to undo
        // snapshots being suspended. This will also
        // update our overall content by calling
        // updateRackLayout for us.
        updateChassis(chassis);
    }

    // Default does nothing.
}

export const getChassisSizeAsDrawn = (chassis: Chassis, copyMode: boolean): Size => {

    // See if there's a platform-specific implementation.
    const impl = _getImplementation(chassis.platform);

    // If so...
    if (impl && impl.getChassisSizeAsDrawn) {

        return impl.getChassisSizeAsDrawn(chassis, copyMode);
    }

    // Default
    return { ...chassis.layout.size };
}

export const getXSlotWidthFor = (modInfo: EngInfoComponent): number => {

    // See if there's a platform-specific implementation.
    const impl = _getImplementation(modInfo.platform);

    // If so...
    if (impl && impl.getXSlotWidthFor) {

        return impl.getXSlotWidthFor(modInfo);
    }

    // Default
    return 0;
}

export const getDefaultXSlotWidth = (platform: string): number => {

    // See if there's a platform-specific implementation.
    const impl = _getImplementation(platform);

    // If so...
    if (impl && impl.getDefaultXSlotWidth) {

        return impl.getDefaultXSlotWidth(platform);
    }

    // Default
    return 0;
}

export const getEmptySlotImage = (chassis: Chassis): string => {

    // See if there's a platform-specific implementation.
    const impl = _getImplementation(chassis.platform);

    // If so...
    if (impl && impl.getEmptySlotImage) {

        return impl.getEmptySlotImage(chassis);
    }

    // Default
    return '';
}

export const doesSlotQualifyForBtnAction = (mode: LayoutMode, type: LayoutActionType, chassis: Chassis, slotNum: number): boolean => {
    // See if there's a platform-specific implementation.
    const impl = _getImplementation(chassis.platform);

    // If so...
    if (impl && impl.doesSlotQualifyForBtnAction) {
        return impl.doesSlotQualifyForBtnAction(mode, type, chassis, slotNum);
    }

    // Default - Slot Qualifies!
    return true;
}

export const isPointOnPS = (ptLocal: Point, chassis: Chassis): boolean => {
    const impl = _getImplementation(chassis.platform);
    if (impl && impl.isPointOnPS) {
        return impl.isPointOnPS(ptLocal, chassis);
    }

    // Default
    if (chassis.ps && isPointInLoc(ptLocal, chassis.ps.loc)) {
        return true;
    }
    return false;
}

export const isPointOnBankExp = (ptLocal: Point, chassis: Chassis):
    [onExp: boolean, bank: number, rightSide: boolean] => {
    const impl = _getImplementation(chassis.platform);
    if (impl && impl.isPointOnBankExp) {
        return impl.isPointOnBankExp(ptLocal, chassis);
    }

    // Default
    return [false, -1, false];
}

export const getBankExpComp = (
    chassis: Chassis,
    bank: number,
    rightSide: boolean
): BankExpModule | undefined => {
    const impl = _getImplementation(chassis.platform);
    if (impl && impl.getBankExpComp) {
        return impl.getBankExpComp(chassis, bank, rightSide);
    }

    return undefined;
}

export const getDevicePowerTips = (device: GraphicalDevice, singleLineFrm?: boolean): PowerBreakdownTips => {
    const impl = _getImplementation(device.platform);
    if (impl && impl.getDevicePowerTips) {
        return impl.getDevicePowerTips(device, singleLineFrm);
    }

    // Default returns an empty power tips.
    return {};
}

export const getChassisNameSelection = (chassis: Chassis): string => {

    const impl = _getImplementation(chassis.platform);
    if (impl && impl.getChassisNameSelection) {
        return impl.getChassisNameSelection(chassis);
    }

    return chassis.name || "";
}

export const getLayoutScaleInfo = (platform: string): StageScaleInfo => {
    const impl = _getImplementation(platform);
    if (impl && impl.getLayoutScaleInfo) {
        return impl.getLayoutScaleInfo();
    }

    // Default
    return {
        baseScale: DfltStageBaseScale,
        zoomBy: DfltStageZoomBy,
        zoomRangeFactor: DfltStageZoomRangeFactor
    };
}

export const setNumBanks = (chassis: Chassis, qty: number): boolean => {
    const impl = _getImplementation(chassis.platform);
    if (impl && impl.setNumBanks) {
        return impl.setNumBanks(chassis, qty);
    }

    // Default
    return false;
}

// NOTE: The primary purpose of this function is to provide
// a .photo propery value for our chassis BOM headers included
// when we save a project. For now, we'll just call a helper
// to give us a SINLGE, standard photo based on platform.
// At some point, we COULD provide more photo variations based on
// attributes of the chassis, such as rating(XT), chassis size, number
// of banks, etc. 
export const getChassisPhotoForBOMHeader = (chassis: Chassis): string | undefined => {
    const photo = getSysDsgDefChassisImg(chassis.platform);
    return photo;
}

export const getDfltChassisRendInfo = (chassis: Chassis): RendInfo[] => {
    const scaleAdj = 1.0 / StageUnitsPerMM;
    const mdSize = scaleSize(chassis.layout.size, scaleAdj, true);

    const rd: RendInfo =  {
        size: mdSize
    }
    return [rd];
}

export const getChassisRendInfo = (chassis: Chassis): RendInfo[] => {
    const impl = _getImplementation(chassis.platform);
    if (impl) {
        const rendInfo = impl.getChassisRendInfo
            ? impl.getChassisRendInfo(chassis)
            : getDfltChassisRendInfo(chassis);

        return rendInfo;
    }
    throw new Error('ERROR: No platform implementation in makeSysDsgMetadata!');
}

export const getExternalPSU = (chassis: Chassis, idPSU: number): SelectableDevice | undefined => {
    const impl = _getImplementation(chassis.platform);
    if (impl && impl.getExternalPSU) {
        return impl.getExternalPSU(chassis, idPSU);
    }

    return undefined;
}

export const canDeletePowerSupply = (psu: SelectableDevice): boolean => {
    const impl = _getImplementation(psu.platform);
    if (impl && impl.canDeletePowerSupply) {
        return impl.canDeletePowerSupply(psu);
    }

    return false;
}

export const deletePowerSupply = (psu: SelectableDevice): boolean => {
    const impl = _getImplementation(psu.platform);
    if (impl && impl.deletePowerSupply) {
        return impl.deletePowerSupply(psu);
    }

    return false;
}
