import {
    GeneralImplSpec,
    RegisterGeneralImpl,
} from "../../../implementation/ImplGeneral";
import {
    Chassis,
} from "../../../types/ProjectTypes";
import { Size } from "../../../types/SizeAndPosTypes";
import { StageUnitsPerMM } from "../../../types/StageTypes";
import { snapAddModulesToChassis } from "../../common/HardwareGen";
import { PlatformFlex } from "../../PlatformConstants";
import { snapConfigureChassis } from "../../snap/SnapChassisConfig";
import {
    RegisterSnapClientDetails,
    snapAddModuleAtSlot,
    snapCanExtendChassis,
    snapCanModuleBeAdded,
    snapCreateChassis,
    snapDeleteModuleAtSlot,
    snapDropDragDeviceOnChassis,
    snapDuplicateChassis,
    snapFilterAvailableModules,
    snapGetActionBtnInfo,
    snapGetChassisDropStatus,
    snapGetChassisRenderer,
    snapGetChassisRendInfo,
    snapGetChassisSizeAsDrawn,
    snapGetDefaultChassisName,
    snapGetDefaultXSlotWidth,
    snapGetDevicePowerTips,
    snapGetMaxNewModules,
    snapGetModuleSlotRestriction,
    snapGetSlotTypeRestriction,
    snapGetXSlotWidthFor,
    SnapPlatformDetails,
    snapUpdateChassisLayout
} from "../../snap/SnapGeneralImpl";

// Image widths look pretty consistent @ 136px.
const _flexElemImageHt = 129; // (129/121)pixels
const _flexNominalHt = 93;   // (92.7/88.7) millimeters
const _flexImageSizeToMM = _flexNominalHt / _flexElemImageHt;
const _flexImageScaleFactor = _flexImageSizeToMM * StageUnitsPerMM;

const _rightCapImgSrc = '/assets/flex/5094_ECR.png';
const _rightCapImgSize: Size = { width: 8, height: 129 }; // height (129 for end cap and empty slot)

const _flexChassisImgLoc = '/assets/flex/';

export const flexGetEmptySlotImage = (chassis: Chassis): string => {
    chassis;
    return _flexChassisImgLoc + '5094_Empty.png';
}

const getFlexImpl = (): GeneralImplSpec => {
    return {
        platform: PlatformFlex,
        imageScaleFactor: _flexImageScaleFactor,

        createChassis: snapCreateChassis,
        replaceChassisPowerSupply: undefined,
        configureChassis: snapConfigureChassis,
        addModuleAtSlot: snapAddModuleAtSlot,
        deleteModuleAtSlot: snapDeleteModuleAtSlot,
        getChassisDropStatus: snapGetChassisDropStatus,
        dropDragDeviceOnChassis: snapDropDragDeviceOnChassis,
        getChassisRenderer: snapGetChassisRenderer,
        getDefaultChassisName: snapGetDefaultChassisName,
        getActBtnInfo: snapGetActionBtnInfo,
        filterAvailableModules: snapFilterAvailableModules,
        getMaxNewModules: snapGetMaxNewModules,
        canExtendChassis: snapCanExtendChassis,
        canModuleBeAdded: snapCanModuleBeAdded,
        getSlotTypeRestriction: snapGetSlotTypeRestriction,
        getModuleSlotRestriction: snapGetModuleSlotRestriction,
        duplicateChassis: snapDuplicateChassis,
        updateChassisLayout: snapUpdateChassisLayout,
        addModulesToChassis: snapAddModulesToChassis,
        getChassisSizeAsDrawn: snapGetChassisSizeAsDrawn,
        getXSlotWidthFor: snapGetXSlotWidthFor,
        getDefaultXSlotWidth: snapGetDefaultXSlotWidth,
        getEmptySlotImage: flexGetEmptySlotImage,
        getDevicePowerTips: snapGetDevicePowerTips,
        getChassisRendInfo: snapGetChassisRendInfo
    };
}

export const RegisterFlexGeneralImpl = () => {
    const flexSnapDtls: SnapPlatformDetails = {
        imgScaleFactor: _flexImageScaleFactor,
        leftSlotStartSize: { width: 136, height: 129 },
        defaultXSlotWidth: 136,
        firstSlotRestricted: true,
        absMaxModules: 16,
        cableSplitAllowed: false,
        rightCapInfo: {
            imgSrc: _rightCapImgSrc,
            width: _rightCapImgSize.width,
            height: _rightCapImgSize.height
        },
        getFPDMap: undefined, // Flex does not have FPDs
    }

    RegisterSnapClientDetails(PlatformFlex, flexSnapDtls);

    RegisterGeneralImpl(getFlexImpl());
}
