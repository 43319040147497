import { PlatformCLX } from "../../PlatformConstants";
import { Chassis, DeviceType } from "../../../types/ProjectTypes";
import { isDeviceCompatibleWithChassis, isSlotFiller, updateRackLayout } from "../../../model/ChassisProject";
import {
    chkrModule,
    doChassisPowerCheckStandard,
    getChkrCompTxt
} from "../../../util/Checker";
import { addAutoFix, AFType, getNewAutoFix } from "../../../util/CheckerAutoFix";
import {
    addLogMessage,
    getNewProjectLog,
    LogMsgLevel,
    ProjectLog,
    StatusLogType
} from "../../../util/ProjectLog";
import {
    EngInfoController,
} from "../../../engData/EngineeringInfo";
import { getModuleEngInfo } from "../../../util/EngInfoHelp";
import { CheckerImplementation, RegisterCheckerImpl } from "../../../implementation/ImplChecker";
import { clxExecuteAutoFix } from "./CLXAutoFix";
import { clxSwapCtlrForSafetyCtrl } from "./CLXAutoFixSysPerf";


/////////// Interfaces //////////////////////////////////
// We may make these global.


export interface chkrCLXChassisComponents {
    redundancyModComps: chkrModule[];
    processorModComps: chkrModule[];
    coprocessorModComps: chkrModule[];

    // NOTE: commModComps replaces old
    // scannerComps. Includes ONLY modules
    // that meet BOTH of the following:
    //   1. Communication Module (not a controller)
    //   2. Has 'comm role' (is EtherNet).
    commModComps: chkrModule[];

    // Includes any/all modules of type
    // 'Communication Module' that are do
    // NOT have a 'comm role'.
    otherCommMods: chkrModule[];

    otherModComps: chkrModule[];
    allModComps: chkrModule[];
    procChassis: boolean;
    redundant: boolean;
}


///////////// Platform Impl Methods /////////////////////////////////////////////////////

export const clxDoGeneralCheck = (chassis: Chassis) => {

    // Scan the chassis - sets _currentChassis.
    clxScanChassis(chassis); 

    // If the chassis' current redundant flag
    // doesn't match what was just scanned...
    if (chassis.redundant !== _currentChassisComps.redundant) {

        // Set it to what it SHOULD be...
        chassis.redundant = _currentChassisComps.redundant;

        // Then update the rack layout. This will ensure that
        // the content's .anyRedundant flag is correct after
        // the change, and ALSO make sure that our extents are
        // calculated correctly if that flag changes values.
        // If we have any redundant chassis, its extent is always
        // larger (in at least one direction) than if not.
        if (chassis.parent) {
            updateRackLayout(chassis.parent);
        }
        else {
            throw new Error('clxDoGeneralCheck on chassis without parent?');
        }
    }

    clxDoRedundancyCheck(chassis);
    clxDoEnviromentalRatingCheck(chassis);
    clxDoSafetyControllerCheck(chassis);
    clxDoHeatDissipationCheck(chassis);
    clxDoMiscellaneousModuleCheck(chassis);
    clxDoScannerCheck(chassis);

    // Toss the chassis data collected by the scan.
    _currentChassisComps = getNewCLXChassisComponents();

   /////////////// Skipped Checks /////////////////////////////////////////

    // MVI56E-MCMxxx : IAB ref: RA1756Container::CheckProsoftMCMSupport()
    // Warning if in a remote chassis with EN4TR Redundant adapter.
    //      "ProSoft’s MVI56E-MCM module has been tested only in few architectures that
    //      use Redundant 1756 - EN4TR.Please contact ProSoft technical support, to validate
    //      the module’s compatibility with your application."

    // Impedance of 1756-OF8I: IAB ref: RA1756Container::Display1756AddingChassisWarningMsg()
    // Warning if any 1756-OF8I(K) modules are in a chassis... 

    return;
}


//////////////////// Chassis Scan /////////////////////////////

export const getNewCLXChassisComponents = (): chkrCLXChassisComponents => {
    return {
        redundancyModComps: [],
        processorModComps: [],
        coprocessorModComps: [],
        commModComps: [],
        otherCommMods: [],
        otherModComps: [],
        allModComps: [], // includes the refs from the other arrays.
        procChassis: false,
        redundant: false,
    };
}

// Note: this could be a general chassis scan(????).
// _currentChassisComps is populated by the chassis scan
// and then tossed upon the scan of the next chassis.
// Most, it not all, checks will use _currentChassisComps.
let _currentChassisComps: chkrCLXChassisComponents = getNewCLXChassisComponents();

export const clxScanChassis = (chassis: Chassis, outScanResults: chkrCLXChassisComponents | undefined = undefined) => {
    if (chassis.statusLog == null)
        chassis.statusLog = getNewProjectLog(StatusLogType.chassis);

    const currentChassisComps = (outScanResults ? outScanResults : _currentChassisComps);

    // Redefine chassis.statusLog, which can be null,
    // to prevent linting error in forEach loops.
    const statusLog: ProjectLog = chassis.statusLog;

    chassis.modules.forEach((mod) => {
        if (mod && mod.catNo) {
            const modInfo = getModuleEngInfo(chassis.platform, mod.catNo);
            if (modInfo && (modInfo.slotsUsed > 0)) {
                const chassisComp: chkrModule = { module: mod, engInfo: modInfo, arrIndex: _currentChassisComps.allModComps.length };

                // Add the comp to 'all Comps' array. 
                currentChassisComps.allModComps.push(chassisComp);

                switch (modInfo.type) {
                    case DeviceType.RedundMod:
                        currentChassisComps.redundancyModComps.push(chassisComp);
                        currentChassisComps.redundant = true;
                        break;
                    case DeviceType.SafetyPartner:
                        currentChassisComps.coprocessorModComps.push(chassisComp);
                        break;
                    case DeviceType.CommModule:
                        if (modInfo.isComm) {
                            currentChassisComps.commModComps.push(chassisComp);
                        }
                        else {
                            currentChassisComps.otherCommMods.push(chassisComp);
                        }
                        break;
                    case DeviceType.Controller:
                        {
                            // If the info claims to be for a controller, add
                            // it as a proc. Otherwise toss it in the 'others'.
                            if (modInfo.isController) {
                                currentChassisComps.processorModComps.push(chassisComp);
                                currentChassisComps.procChassis = true;
                            }
                            else {
                                addLogMessage(statusLog,
                                    `${mod.catNo} in chassis ${chassis.name} is a processor but does not have the correct data.`, LogMsgLevel.error, chassis);
                                currentChassisComps.otherModComps.push(chassisComp);
                            }
                        }
                        break;
                    default:
                        _currentChassisComps.otherModComps.push(chassisComp);
                        break;
                }
            }
        }
    });
}


/////////////////// Checks ///////////////////////////////////////////

const clxDoRedundancyCheck = (chassis: Chassis): boolean => {
    if (_currentChassisComps.redundant === false)
        return true;

    if (chassis.statusLog == null)
        chassis.statusLog = getNewProjectLog(StatusLogType.chassis);

    // Redefine chassis.statusLog, which can be null,
    // to prevent linting error in forEach loops.
    const statusLog: ProjectLog = chassis.statusLog;

    // Start by doing some 'one and done' checks where if
    // the check fails, do not continue checking.

	// We can only have 1 Redundancy Module per chassis. If
	// we fail here, we're done.
    if (_currentChassisComps.redundancyModComps.length > 1) {
        const af = getNewAutoFix(PlatformCLX, chassis.id, AFType.Red_MultpleRMModules);
		addAutoFix(af);
		addLogMessage(statusLog,
            `The chassis '${chassis.name}' cannot contain more than 1 redundancy module.`,
            LogMsgLevel.error,
            chassis,
            af.id);
		return false;
	}

	// We cannot have an RM Module in a remote I/O chassis.
	// If we do not have any controllers, we're done.
	if (_currentChassisComps.processorModComps.length === 0) {
        const af = getNewAutoFix(PlatformCLX, chassis.id, AFType.Red_RemoteChassisCannotBeRed);
		addAutoFix(af);
		addLogMessage(statusLog,
            `The chassis '${chassis.name}' is a remote I/O chassis and cannot contain a redundancy module.`,
            LogMsgLevel.error,
            chassis,
            af.id);
		return false;
	}

    let success = true;

    //const chassisContainsRedCapableCtrl = _currentChassisComps.processorModComps.some(x => (x.prodData as OLD_RedundantCheckProdData).RedundantCapable !== '0');
    const chassisContainsRedCapableCtrl = _currentChassisComps.processorModComps.some(x => x.engInfo.redCapable);

    // Check for any modules that are not redundant.
    let autoFixID = '';
   _currentChassisComps.allModComps.forEach(modComp => {
       //if ((modComp.prodData as OLD_RedundantCheckProdData).RedundantCapable === '0') {
       if (!modComp.engInfo.redCapable) {
            if (!autoFixID) {
                // Create an auto fix that will apply to ALL non-redundant
                // capable modules. Based on whether we have a Controller
                // that IS REDUNDANT CAPABLE, we will either migrate all
                // non-redundant modules to a new chassis or simply remove
                // the redundancy module from the chassis.
                const afType = (chassisContainsRedCapableCtrl ? AFType.Red_MigrateNonRedModules : AFType.Red_RemoveRedundancyModule);
                const af = getNewAutoFix(PlatformCLX, chassis.id, afType);
                addAutoFix(af);
                autoFixID = af.id;
            }

            addLogMessage(statusLog,
                `${getChkrCompTxt(modComp, chassis)} cannot be included in a redundant controller chassis.`,
                LogMsgLevel.error,
                chassis,
                autoFixID);

            success = false;
        }
    });

    // 2023.9.4 Get quantity of L8 and L7 controllers
    let numL8 = 0;
    let numL7 = 0;
    const arrProcSlots: number[] = [];

    _currentChassisComps.processorModComps.forEach(procComp => {
        if (procComp.module.catNo.startsWith('1756-L8'))
            numL8++;
        else //if (procComp.module.id.startsWith('1756-L7'))
            numL7++; // I believe it has to be an L7

        arrProcSlots.push(procComp.module.slotIdx);
    })

    // We can have a max of 7 Enet/Cnet scanners
    const clxRedScannerLimit = 7;

    // Start with assumption we have no CN2.
    let cnetCN2ScannerFound = false;

    // Start combined total of Enet AND CNet
    // scanners using the number of commModComps.
    // Those are now ALL EtherNet.
    let numCnetEnetScanners = _currentChassisComps.commModComps.length;

    // Then walk all of the OTHER (non-comm-role)
    // Comm modules. FOr each...
    _currentChassisComps.otherCommMods.forEach(otherMod => {

        // If it's CNet...
        if (otherMod.engInfo.catNo.startsWith('1756-C')) {

            // Bump our tally.
            numCnetEnetScanners++;

            // If it's a CN2, remember that we
            // have (at least) one of those.
            if (otherMod.engInfo.catNo.startsWith('1756-CN2')) {
                cnetCN2ScannerFound = true;
            }
        }
    });

    if (numCnetEnetScanners > clxRedScannerLimit) {
        addLogMessage(statusLog,
            `The chassis '${chassis.name}' contains a redundancy module and contains ${numCnetEnetScanners} ` +
            `communication modules. A chassis with a redundancy module cannot support more than ${clxRedScannerLimit} ` +
            'communication modules.', LogMsgLevel.error, chassis);

        success = false;
    }

    // 2023.9.4 We cannot mix L8 and L7 controllers in a redundant chassis.
    if (numL8 > 0 && numL7 > 0) {
        const af = getNewAutoFix(PlatformCLX, chassis.id, AFType.Red_MixedL8andL7);
        addAutoFix(af);
        addLogMessage(statusLog,
            `The chassis '${chassis.name}' contains a redundancy module and cannot support both L8 and L7 Controllers at the same time.`,
            LogMsgLevel.error,
            chassis,
            af.id);
    }

    // 2023.9.4 We can have only 1 L8 controller in a redundant chassis.
    if (numL8 > 1) {
        const af = getNewAutoFix(PlatformCLX, chassis.id, AFType.Red_TooManyL8s);
        addAutoFix(af);
        addLogMessage(statusLog,
            `The chassis '${chassis.name}' contains a redundancy module and cannot support more than one L8 Controller.`,
            LogMsgLevel.error,
            chassis,
            af.id);
    }

    // 2023.9.4 We can have a max of 2 L7 controllers in a redundant chassis.
    if (numL7 > 2) {
        const af = getNewAutoFix(PlatformCLX, chassis.id, AFType.Red_TooManyL7s);
        addAutoFix(af);
        addLogMessage(statusLog,
            `The chassis '${chassis.name}' contains a redundancy module and cannot support more than two L7 Controller.`,
            LogMsgLevel.error,
            chassis,
            af.id);
    }

    if (numL8 > 0 && cnetCN2ScannerFound) {
        // 2023.9.4 L8x redundant not compatible with CNet 1756-CN2xxx scanners.
        addLogMessage(statusLog,
            `'${chassis.name}' chassis: ControlLogix L8x Redundancy does NOT support ControlNet. ` +
            'You may want to consider replacing your ControlNet adapters with EtherNet/IP adapters.',
            LogMsgLevel.error, chassis);

    }

    // Whether we have many procs or not, we should have the RM 
    // module next to a proc. If we have a proc(s) and ONE RM Module.
    if (arrProcSlots.length > 0 && _currentChassisComps.redundancyModComps.length === 1) {
        const slotRedMod = _currentChassisComps.redundancyModComps[0].module.slotIdx;
        const idxRMNextToProc = arrProcSlots.find(x => x === slotRedMod + 1 || x === slotRedMod - 1);
        if (idxRMNextToProc == null) {
            const af = getNewAutoFix(PlatformCLX, chassis.id, AFType.Red_RMModNotNextToController);
            addAutoFix(af);
            addLogMessage(statusLog,
                `'${chassis.name}' chassis: For best performance, it is recommended the Redundancy Module is placed in a slot next to the Controller.`,
                LogMsgLevel.info,
                chassis,
                af.id);
        }
    }


    return success;
}

const clxDoEnviromentalRatingCheck = (chassis: Chassis): boolean => {
    if (chassis.statusLog == null)
        chassis.statusLog = getNewProjectLog(StatusLogType.chassis);

    // Redefine chassis.statusLog, which can be null,
    // to prevent linting error in forEach loops.
    const statusLog: ProjectLog = chassis.statusLog;

   // For now, all EnvRt mismatches will be WARNINGs.
    // If this changes and errors are generated, 'success'
    // should be set to FALSE. Currently we always return TRUE.
    let success = true;

    if (chassis.ps) {
        if (!isDeviceCompatibleWithChassis(chassis.ps, chassis)) {
            addLogMessage(statusLog,
                `The Power Supply (${chassis.ps.catNo}) in chassis '${chassis.name}' ` +
                `has a different Environmental Rating than the chassis. The chassis will be rated to the lowest of the ratings.`, LogMsgLevel.warning, chassis);
        }
    }

    let logMismatch = true;
    _currentChassisComps.allModComps.forEach(modComp => {
        //if (isEnvironmentalRatingCompatible(modComp.prodData, chassis) === false) {
        if (!isDeviceCompatibleWithChassis(modComp.module, chassis)) {
            // Start out by saying we are logging the mismatch.
            logMismatch = true;

            // Check for a slot filler. Standard slot fillers
            // can be in a Conf Coated chassis, but only XT
            // slot fillers can be in an XT chassis. If we
            // have a slot filler (ie catalog starts with...)
            //if (modComp.module.catNo.startsWith('1756-N2')) {
            if (isSlotFiller(modComp.module)) {
                // If XT flags match, we're OK.
                if (chassis.extendedTemp === modComp.module.extendedTemp)
                    logMismatch = false;
            }

            if( logMismatch)
                addLogMessage(statusLog,
                    `${getChkrCompTxt(modComp, chassis)} has a different Environmental Rating than the chassis. The chassis will be rated to the lowest of the ratings.`, 
                    LogMsgLevel.warning, chassis);
        }
    })

    // To prevent linting error
    success = true;

    return success;
}


const clxDoSafetyControllerCheck = (chassis: Chassis): boolean => {
    // If we do not have any controllers/procs or 
    // partners...
    if (_currentChassisComps.processorModComps.length === 0 &&
        _currentChassisComps.coprocessorModComps.length === 0)
        return true;

    if (chassis.statusLog == null)
        chassis.statusLog = getNewProjectLog(StatusLogType.chassis);

    // Redefine chassis.statusLog, which can be null,
    // to prevent linting error in forEach loops.
    const statusLog: ProjectLog = chassis.statusLog;

    ////// Check PART 1 //////////////////////////////////
    // This is a bit trickier than IAB. For the L8 safety
    // controllers, IAB had 2 flavors - SIL2 and SIL3. The
    // SIL2 safety controllers did NOT require a safety
    // partner and the SIL3 version does. We cannot
    // distinguish between these here (at least for now).
    // It appears only the L8 SIL2 does NOT require a
    // partner - ALL other controllers need a partner AND
    // the partner needs to be in the next slot to the RIGHT!

    // The safety partner, if required, should match the
    // controller (ie L8 -> L8 partner and L7 -> L7 partner).
    // Note: L6 controllers are not included here therefore
    // we do NOT need to worry about the 1756-LSP module.

    // Start by getting an array of just safety processors.
    const arrSafetyProcs: chkrModule[] = [];
    _currentChassisComps.processorModComps.forEach(comp => {
        const asCtrlInfo = comp.engInfo as EngInfoController;
        if (asCtrlInfo.safetyController)
            arrSafetyProcs.push( comp );
    });

    let success = true;

    // if we do not have any safety procs...
    if (arrSafetyProcs.length === 0) {
        // For any coprocessors/partners...
        _currentChassisComps.coprocessorModComps.forEach((comp) => {
            addLogMessage(statusLog,
                `${getChkrCompTxt(comp, chassis)} requires a Safety Processor of a compatible type in the slot to the LEFT of it.`, LogMsgLevel.error, chassis);
            success = false;
        });
        // We're done.
        return success;
    }

    let safetyL8ProcFound = false;

    // Dupe the array of coprocessors.partners.
    const arrPartners = [..._currentChassisComps.coprocessorModComps];

    // Next try to match them with coprocessors (safety parnters)
    arrSafetyProcs.forEach((procComp) => {
        // Start our flag as FALSE
        let procAndPartnerOK = false;

        // Set our flags for L8/L7 safety controllers.
        const procIsL8 = (procComp.module.catNo.startsWith('1756-L8'));
        if (procIsL8)
            safetyL8ProcFound = true;

        // Check the slot to the right of the proc.
        const compToTheRight = _currentChassisComps.allModComps[procComp.arrIndex + 1];
        if (compToTheRight != null) {
            // Even though there is a component next to
            // the proc in the component ARRAY does not
            // mean it is in the slot next to the proc.
            if (compToTheRight.module.slotIdx === procComp.module.slotIdx + 1) {
                // So far, so good... is this comp a partner
                if (arrPartners.some((x) => x.arrIndex === compToTheRight.arrIndex) === true) {
                    // It is a coprocessor! Is it the right kind...
                    const prtnrIsL8 = (compToTheRight.module.catNo.startsWith('1756-L8'));
                    if (procIsL8 === prtnrIsL8) {
                        procAndPartnerOK = true;
                    }
                }
            }
        }

        if (procAndPartnerOK) {
            // Remove the partner from the partner array
            let shift = false;
            arrPartners.forEach((x, index) => {
                if (shift) {
                    arrPartners[index - 1] = arrPartners[index];
                }
                else if (x.arrIndex === compToTheRight.arrIndex) {
                    // If there is an element after our match...
                    if (index < arrPartners.length - 1) {
                        // Set shift to true, which will shift
                        // all remaining elements to the 'left'
                        shift = true;
                    }
                }
            });
            // Set the length to one less.
            arrPartners.length -= 1;
        }
        else {
            // The processor does NOT have a partner to the right of it.
            // If we do NOT have an L8 controller, it is an error. If
            // we DO HAVE an L8, it is a warning.
            if (procIsL8 === false) {
                const af = getNewAutoFix(PlatformCLX, chassis.id, AFType.Safety_NoL7SP);
                addAutoFix(af);
                addLogMessage(statusLog,
                    `${getChkrCompTxt(procComp, chassis)} requires a Safety Partner Processor (1756-L7SP*) in the slot to the RIGHT of it.`,
                    LogMsgLevel.error,
                    chassis,
                    af.id);
                success = false;
            }
            else {
                addLogMessage(statusLog,
                    `1756-L8xS Safety Controllers have a default rating of SIL2. ${getChkrCompTxt(procComp, chassis)} will need a Safety Partner Processor (1756-L8SP*) ` +
                    'in the slot to the RIGHT to increase the rating from SIL2 to SIL3.', LogMsgLevel.info, chassis);
                // success = false; Note - warnings are NOT failures. We are still successful.
            }
        }
    });

    // If we have any partners left, post errors.
    arrPartners.forEach((comp) => {
        addLogMessage(statusLog,
            `${getChkrCompTxt(comp, chassis)} requires a Safety Processor in the slot to the LEFT of it.`, LogMsgLevel.error, chassis);
        success = false;
    });

    // If we get here, we know we have at least one safety proc in
    // the chassis. If we have any Safety I/O in the chassis, we
    // need an L8 safety proc.
    // If we do NOT have an L8 safety controller...
    if (safetyL8ProcFound === false ) {
        // Check for any 1756 safty I/O
        //const safetyIOFound = _currentChassisComps.otherModComps.some((otherComp) => (otherComp.prodData as OLD_SafetyCheckProdData).SafetyIO === '1');

        const safetyIOFound = _currentChassisComps.otherModComps.some(otherComp => otherComp.engInfo.isSafetyIO);

        if (safetyIOFound === true) {
            // Add a warning...
            addLogMessage(statusLog,
                `The chassis '${chassis.name}' contains 1756 Safety I/O. Local safety I/O is only ` +
                'compatible with ControlLogix L8x Safety Controllers (GuardLogix 5580).', LogMsgLevel.warning, chassis);
        }
    }

    return success;
}


const clxDoHeatDissipationCheck = (chassis: Chassis): boolean => {
    // For Processors, Coprocessors, and Scanners, we need to
    // make sure adjacent module power dissipation does NOT
    // exceed 6200 mW (6.2 Watts); We have processors,
    // coprocessors, and scanners separated out...

    let success = true;

    _currentChassisComps.processorModComps.forEach((comp) => {
        if (clxLogHeatDissipationWarning(comp, chassis) === false)
            success = false;
    });

    _currentChassisComps.coprocessorModComps.forEach((comp) => {
        if (clxLogHeatDissipationWarning(comp, chassis) === false)
            success = false;
    });

    _currentChassisComps.commModComps.forEach((comp) => {
        if (clxLogHeatDissipationWarning(comp, chassis) === false)
            success = false;
    });

    return success;
}

//const _powerDispNAVal = 99900;
const _maxAdjacentPowerDisp = 6200;

//const _getClxModPowerDsp = (mod: chkrModule): number => {

//    // Try to get a PowerDissipation value from the module's product data.
//    const dspPropValue = (mod.prodData as OLD_SafetyCheckProdData).PowerDissipation;

//    // If we can...
//    if (dspPropValue) {

//        // The dsp prop is a string. Convert to a number.
//        const mwValue = Number(dspPropValue);

//        // If we get back a positive number that
//        // is NOT what we see in our data that SEEMS
//        // to indicate something like N/A or 'Unknown',
//        // return it.
//        if ((mwValue > 0) && (mwValue != _powerDispNAVal)) {
//            return mwValue;
//        }
//    }

//    // If we're still here, just return 0.
//    return 0;
//}

const clxLogHeatDissipationWarning = (target: chkrModule, chassis: Chassis): boolean => {
    if (chassis.statusLog == null)
        chassis.statusLog = getNewProjectLog(StatusLogType.chassis);

    // Check the module to the left...
    if (target.arrIndex > 0) {
        const compToTheLeft = _currentChassisComps.allModComps[target.arrIndex - 1];
        // Even though this component is to the left in the ARRAY,
        // is it in the SLOT next to the target...
        if (compToTheLeft.module.slotIdx === target.module.slotIdx - 1) {

            // Call a helper to get heat dissipation
            // for us expressed as a number of milliwatts.
            //const mwValue = _getClxModPowerDsp(compToTheLeft);
            const mwValue = compToTheLeft.engInfo.powerUsed.mWatt;

            // Add a message if it exceeds our max.
            if (mwValue > _maxAdjacentPowerDisp) {
                addLogMessage(chassis.statusLog,
                    `${getChkrCompTxt(target, chassis)} is adjacent to a ${compToTheLeft.module.catNo} in slot ` +
                    `${compToTheLeft.module.slotIdx} whose Power Dissapation exceeds the recommened limit of 6.2 W ` +
                    'when the ambient temperature is between +55 degrees C and +60 degrees C.', LogMsgLevel.info, chassis);
            }

        //    const dissipation = (compToTheLeft.prodData as SafetyCheckProdData).PowerDissipation;
        //    if (dissipation !== undefined && Number(dissipation) > 6200) {
        //        addLogMessage(chassis.statusLog,
        //            `${getChkrCompTxt(target, chassis)} is adjacent to a ${compToTheLeft.module.catNo} in slot ` +
        //            `${compToTheLeft.module.slotLocation} whose Power Dissapation exceeds the recommened limit of 6.2 W ` +
        //            'when the ambient temperature is between +55 degrees C and +60 degrees C.', LogMsgLevel.info, chassis);
        //    }
        }
    }

    // Check the module to the right...
    if (target.arrIndex < _currentChassisComps.allModComps.length - 1) {
        const compToTheRight = _currentChassisComps.allModComps[target.arrIndex + 1];
        // Even though this component is to the right in
        // the ARRAY, is it in the SLOT next to the target...
        if (compToTheRight.module.slotIdx === target.module.slotIdx + 1) {

            // Call a helper to get heat dissipation
            // for us expressed as a number of milliwatts.
            //const mwValue = _getClxModPowerDsp(compToTheRight);
            const mwValue = compToTheRight.engInfo.powerUsed.mWatt;

            // Add a message if it exceeds our max.
            if (mwValue > _maxAdjacentPowerDisp) {
                addLogMessage(chassis.statusLog,
                    `${getChkrCompTxt(target, chassis)} is adjacent to a ${compToTheRight.module.catNo} in slot ` +
                    `${compToTheRight.module.slotIdx} whose Power Dissapation exceeds the recommened limit of 6.2 W ` +
                    'when the ambient temperature is between +55 degrees C and +60 degrees C.', LogMsgLevel.info, chassis);
            }

        //    const dissipation = (compToTheRight.prodData as SafetyCheckProdData).PowerDissipation;
        //    if (dissipation !== undefined && Number(dissipation) > 6200) {
        //        addLogMessage(chassis.statusLog,
        //            `${getChkrCompTxt(target, chassis)} is adjacent to a ${compToTheRight.module.catNo} in slot ` +
        //            `${compToTheRight.module.slotLocation} whose Power Dissapation exceeds the recommened limit of 6.2 W ` +
        //            'when the ambient temperature is between +55 degrees C and +60 degrees C.', LogMsgLevel.info, chassis);
        //    }
        }
    }

    // Return true since we only log warnings
    return true;
}

const clxDoMiscellaneousModuleCheck = (chassis: Chassis): boolean => {
    if (chassis.statusLog == null)
        chassis.statusLog = getNewProjectLog(StatusLogType.chassis);

    // Determine if we have motion in the chassis...
    let numMotion = 0;
    let numPPAxMPC = 0;
    let numIEC61850C = 0;
    let numHistME = 0;

    _currentChassisComps.otherModComps.forEach((comp) => {
        //if (comp.prodData.Type === DeviceType.Motion || comp.module.catNo.startsWith('1756-HYD02'))
        if (comp.engInfo.type === DeviceType.Motion || comp.module.catNo.startsWith('1756-HYD02'))
            numMotion++;
        else if (comp.module.catNo.startsWith('1756-PPMPC'))
            numPPAxMPC++;
        else if (comp.module.catNo.startsWith('MVI56E-61850C'))
            numIEC61850C++;
        else if (comp.module.catNo.startsWith('1756-HIST'))
            numHistME++;
    });

    // Note: Motion modules cannot be in a redundant chassis. However,
    // the redundancy check should catch that issue.
    if (numMotion + numPPAxMPC + numIEC61850C + numHistME === 0)
        return true; // We're good.

    let success = true;

    // We have motion/PPAxMPC in the chassis - we MUST have a controller
    // in the chassis as well - Motion and Hydraulics (1756-HYD02)
    // modules CANNOT be controlled remotely! From IAB 1.0, I believe
    // all processors can handle motion now.
    if (_currentChassisComps.processorModComps.length == 0) {
        if (numMotion > 0) {
            addLogMessage(chassis.statusLog,
                `${chassis.name} chassis contains at least one motion module but does not contain a controller. ` +
                'Motion modules CANNOT be controlled remotely. Either add a controller to the chassis ' +
                'or move the motion module(s) to another chassis with a controller.', LogMsgLevel.error, chassis);
        }

        if (numPPAxMPC > 0) {
            addLogMessage(chassis.statusLog,
                `${chassis.name} chassis contains at least one PlantPAx MPC module but does not contain a controller. ` +
                'PlantPAx MPC modules CANNOT be controlled remotely. Either add a controller to the chassis ' +
                'or move the PlantPAx MPC module(s) to another chassis with a controller.', LogMsgLevel.error, chassis);
        }

        success = false;
    }

    if (numPPAxMPC > 1) {
        addLogMessage(chassis.statusLog,
            `${chassis.name} chassis contains more than one PlantPAx MPC module. IAB has no method ` +
            'to estimate controller memory requirements nor can IAB model the backplane communications ' +
            'needed with these modules.Therefore if your application is untested, then it is suggested ' +
            'that you contact normal Rockwell Automation technical support for guidance.', LogMsgLevel.info, chassis);
    }

    // 2023.9.4 MVI56E-61850C can only communicate with an L8x controller OR
    // needs an EN4TR if in a remote chassis.
    if (numIEC61850C > 0) {
        // If we have a controller in the chassis
        if (_currentChassisComps.processorModComps.length > 0) {
            const L8CtrlFound = _currentChassisComps.processorModComps.some((procComp) => procComp.module.catNo.startsWith('1756-L8'));
            if (L8CtrlFound === false) {
                addLogMessage(chassis.statusLog,
                    `${chassis.name} chassis contains one or more MVI56E-61850C modules. The MVI56E-61850C ` +
                    'is only compatible with a ControlLogix L8x Controller.', LogMsgLevel.warning, chassis);
            }
        }
        else if (_currentChassisComps.commModComps.length > 0) {
            const en4trFound = _currentChassisComps.commModComps.some((comp) => comp.module.catNo.startsWith('1756-EN4TR'));
            if (en4trFound === false) {
                addLogMessage(chassis.statusLog,
                    `${chassis.name} chassis contains one or more MVI56E-61850C modules. The MVI56E-61850C ` +
                    'can only communicate through a 1756-EN4TR* communication adapter.', LogMsgLevel.warning, chassis);
            }

        }
    }

    if (numHistME > 0) {
        // IAB Ref: 1756Container::DoModuleCheck()
        const numProcs = _currentChassisComps.processorModComps.length;
        if (numProcs == 0) {
            addLogMessage(chassis.statusLog,
                `${chassis.name} chassis contains one or more 1756-HIST1G/2G modules. FactoryTalk Historian Machine ` +
                'Edition modules must be located in a chassis that contains a controller.', LogMsgLevel.warning, chassis);

            // Since we do not have any procs, just check for more than 2 Hist modules and we're done.
            if (numHistME > 2) {
                addLogMessage(chassis.statusLog,
                    `${chassis.name} chassis contains ${numHistME} 1756-HIST1G/2G modules. A chassis should not contain ` +
                    'more than 2 FactoryTalk Historian Machine Edition modules.', LogMsgLevel.warning, chassis);
            }
        }
        else {
            switch (numHistME) {
                case 1:
                case 2:
                    {
                        const maxProcs = (4 * numHistME);
                        if (numProcs > maxProcs) {
                            addLogMessage(chassis.statusLog,
                                `${chassis.name} chassis contains ${numProcs} controllers and ${numHistME} 1756-HIST1G/2G module(s). ` +
                                `Each FactoryTalk Historian Machine Edition module should not be configured for more than 4 controllers.`,
                                LogMsgLevel.warning, chassis);
                        }
                    }
                    break;
                default:
                    addLogMessage(chassis.statusLog,
                        `${chassis.name} chassis contains ${numHistME} 1756-HIST1G/2G modules. A chassis should not contain ` +
                        'more than 2 FactoryTalk Historian Machine Edition modules.', LogMsgLevel.warning, chassis);
                   break;
            }
        }
    }

    return success;
}


const clxDoScannerCheck = (chassis: Chassis): boolean => {
    if (_currentChassisComps.commModComps.length === 0)
        return true;

    if (chassis.statusLog == null)
        chassis.statusLog = getNewProjectLog(StatusLogType.chassis);

    // If we have a RIO scanner, it MUST have a controller in the chassis.
    // Look for one and count up any/all EN2* and EN4*
    // comms.
    let rioScannerFound = false;
    let en2tScanners = 0;
    let en4tScanners = 0;

    // Walks all comm mods (with comm roles) and
    // tally any EN2 and/or EN4 modules found.
    _currentChassisComps.commModComps.forEach((comp) => {
        if (comp.engInfo.catNo.startsWith('1756-EN2'))
            en2tScanners++;
        else if (comp.engInfo.catNo.startsWith('1756-EN4'))
            en4tScanners++;
    });

    // Then, walk the 'other' (non-comm-role)
    // comm mods array to see if we have an RIO.
    _currentChassisComps.otherCommMods.forEach((comp) => {
        if (comp.module.catNo.startsWith('1756-RIO')) {
            rioScannerFound = true;
        }
    });

    if (rioScannerFound && _currentChassisComps.processorModComps.length === 0) {
        addLogMessage(chassis.statusLog,
            `${chassis.name} chassis contains one or more 1756-RIO(K) modules. The 1756-RIO module cannot function as a normal RIO product adapter. ` +
            'Either add a controller to the chassis or switch out the 1756-RIO/K module to another communication module that can act as an I/O adapter like a 1756-EN2TR. ' +
            'For more information on how the 1756-RIO/K module works see publication 1756-um534_-en-p.pdf.', LogMsgLevel.warning, chassis);

    }

    if (en2tScanners > 5 || en4tScanners > 2) {
        addLogMessage(chassis.statusLog,
            `${chassis.name} chassis contains multiple scanners. The packets/second (PPS) limitation on a 1756 chassis ` +
            'is 80,000 - 100,000 depending on the type of traffic. Take this into consideration while designing a 1756 system.',
            LogMsgLevel.info, chassis);
    }

    // Only posting warnings - return true.
    return true;
}


export const RegisterCLXCheckerImpl = () => {
    const clxImpl: CheckerImplementation = {
        platform: PlatformCLX,
        doGeneralCheck: clxDoGeneralCheck,
        doChassisPowerCheck: doChassisPowerCheckStandard,
        swapCtlrForSafetyCtrl: clxSwapCtlrForSafetyCtrl,
        executeAutoFix: clxExecuteAutoFix
    };

    RegisterCheckerImpl(clxImpl);
}
