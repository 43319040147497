import { Size } from "../types/SizeAndPosTypes";
import { logger } from "../util/Logger";


export type ImageElType = HTMLImageElement | undefined;
export type ImageStatus = 'none' | 'loaded' | 'loading' | 'failed' | 'error';
export type ImageRequestCallback = (image: ImageElType, status: ImageStatus) => void;


const _getImageSize = (img: ImageElType): Size => {
    if (img) {
        return { width: img.width, height: img.height };
    }
    else {
        return { width: 0, height: 0 };
    }
}

interface ImageInfo {
    url: string;
    goldenCopy: ImageElType;
    status: ImageStatus;
    imgSize: Size;
    callbacks: Set<ImageRequestCallback>;
}

const _imageCache = new Map<string, ImageInfo>();
let _numImagesLoading = 0;

const _addCacheEntry = (url: string): ImageInfo => {
    if (!_imageCache.has(url)) {

        const info: ImageInfo = {
            url: url,
            goldenCopy: undefined,
            status: 'none',
            imgSize: _getImageSize(undefined),
            callbacks: new Set<ImageRequestCallback>()
        }

        _imageCache.set(url, info);
        return info;
    }
    else {
        throw new Error('_addCacheEntry found existing entry!');
    }
}

// Note: Caller responsible for checking cache
// for EXISTING entry BEFORE calling.
const _getCachedInfo = (url: string): ImageInfo => {
    if (_imageCache.has(url)) {
        const info = _imageCache.get(url);
        if (info) {
            return info;
        }
    }
    throw new Error('Missing entry in _getCachedInfo');
}

const _makeClone = (goldenImg: ImageElType): HTMLImageElement => {
    if (goldenImg) {
        const theClone = goldenImg.cloneNode(true) as HTMLImageElement;
        if (theClone) {
            return theClone;
        }
        else {
            throw new Error('ERROR: cloneNode failed in _makeClone!');
        }
    }
    throw new Error('ERROR: No goldenImg in _makeClone!');
}

// Called when a client's image COPY wasn't immediately
// ready for use (.complete) after being cloned from a goldenCopy.
const _delayedNotify = (clientCallback: ImageRequestCallback,
    clientImg: HTMLImageElement, url: string) => {

    // Internal listener callback functions. In
    // each case, we call back to the client with
    // the relevant results.
    const onCloneLoad = () => {
        //logger.logImage('Delayed img LOADED: ' + url);
        clientCallback(clientImg, 'loaded');
    }

    const onCloneLoadError = () => {
        logger.error('Delayed img load FAILED: ' + url);
        clientCallback(undefined, 'failed');
    }

    // Start with assumption that we WON'T
    // actually need to be delayed.
    let delayed = false;

    // Check the image provided. If it's
    // already in a complete state.
    if (clientImg.complete) {

        // Then we can immediatly call the callback
        // function notifying the client that the
        // image is loaded and ready for use.
        //logger.logImage('clientImg ALREADY complete in _delayedNotify: ' + url);
        clientCallback(clientImg, 'loaded');
    }
    else {
        // Not yet complete.
        // Add load and error event listeners.
        //logger.logImage('clientImg DELAYED: ' + url)
        clientImg.addEventListener('load', onCloneLoad);
        clientImg.addEventListener('error', onCloneLoadError);

        // Remember tht we did that.
        delayed = true;
    }

    // On return...
    return (() => {
        // Remove our event listeners if we added them.
        if (delayed) {
            clientImg.removeEventListener('load', onCloneLoad);
            clientImg.removeEventListener('error', onCloneLoadError);
        }
    });

}

const _getImgForClient = (info: ImageInfo): HTMLImageElement => {

    // Sanity check. We should ALWAYS have a
    // goldenCopy that's finished loading.
    if ((info.goldenCopy === undefined) || (info.status !== 'loaded')) {
        throw new Error('Invalid call to _getImgForClient: ' + info.url);
    }

    // Make a new clone of the goldenCopy for
    // our client. Each client needs its OWN img.
    const clientCopy = _makeClone(info.goldenCopy);

    // Return it. Note that after cloning, the copy may
    // or may not actually be complete and ready for use.
    // Callers are responsible to deal with that.
    return clientCopy;
}

// Called to load the goldenCopy (primary) image for
// a url that we didn't already have in our cache.
const _doPrimaryLoad = (info: ImageInfo) => {

    //logger.logImage('_doPrimaryLoad: ' + info.url);

    // Sanity check that the info provided is
    // in the state we expect it to be in.
    if ((info.goldenCopy === undefined) && (info.status === 'none')) {
        info.status = 'loading';
        _numImagesLoading++;
    }
    else {
        throw new Error('Unexpected ImageInfo in  _primaryLoad!');
    }

    // Create a new image. This is functionally 
    // equivalent to document.createElement('img').
    const img = new Image();

    // See: https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/crossorigin
    // If we don't set this, use of any image sourced from outside of
    // our own app site is limited due to CORS security. For example, any
    // image pulled from https://configurator.rockwellautomation.com/api/doc/
    // causes CORS security errors. We're allowed to show the images
    // in that case, but can't do things like 'cache' them for the purpose
    // of adding filters, etc. By including the .crossOrigin = 'anonymous',
    // images DO seem to work suitably, at least when sourced from the configurator
    // site. More research is warranted.
    img.crossOrigin = 'anonymous';

    // Callback for 'load' event listener.
    const onPrimaryLoad = () => {
        //logger.logImage('Primary LOADED: ' + info.url);

        // The primary image becomes our
        // 'golden' copy, which is now loaded.
        info.goldenCopy = img;
        info.status = 'loaded';

        // Record the image size.
        info.imgSize = _getImageSize(img),

        // Decrement our 'num loading' quantity.
        _numImagesLoading--;

        // If any clients are waiting to be notified...
        if (info.callbacks.size > 0) {

            // Then for each one...
            info.callbacks.forEach(cbFunc => {

                // Get a unique copy of the image.
                const clientImg = _getImgForClient(info);

                // If the copy is already complete...
                if (clientImg.complete) {

                    // We can call the client back directly.
                    cbFunc(clientImg, 'loaded');
                }
                else {

                    // Hand off to our 'delayed' notifier.
                    // It'll track the status (of the copy),
                    // and notify the client when it's loaded.
                    _delayedNotify(cbFunc, clientImg, info.url);
                }
            });
        }

        // Clear out our set of callbacks.
        info.callbacks.clear();
    }

    // Callback for 'error' event listener.
    const onPrimaryError = () => {
        logger.error('ERROR loading img: ' + info.url);

        // Record the 'failed' status.
        info.status = 'failed';

        // Decrement our 'num loading' quantity.
        _numImagesLoading--;

        // Notify any waiting clients via callback.
        info.callbacks.forEach(cbFunc => {
            cbFunc(undefined, info.status);
        });

        // And clear our set of them.
        info.callbacks.clear();
    }

    // Hook up our event listeners.
    img.addEventListener('load', onPrimaryLoad);
    img.addEventListener('error', onPrimaryError);

    // Set the src of our new img
    // using the url provided.
    img.src = info.url;

    // On return, remove the event listeners.
    return () => {
        img.removeEventListener('load', onPrimaryLoad);
        img.removeEventListener('error', onPrimaryError);
    };
}

// Experimental
const _finishPreload = (url: string, img: ImageElType) => {
    const imgInfo = _getCachedInfo(url);
    imgInfo.goldenCopy = img;
    imgInfo.status = img ? 'loaded' : 'error';
    if (img) {
        imgInfo.imgSize = { width: img.width, height: img.height };
    }
    else {
        logger.warn('Image load FAILED for: ' + url);
    }
    logger.logImage('_finishPreload for: ' + url);
}

const _promiseLoadImage = async (url: string) => {
    return new Promise((resolve, reject) => {
        const img: ImageElType = new Image();
        img.addEventListener('load', () => resolve(img));
        img.addEventListener('error', (err) => reject(err));
        img.src = url;
    });
}

export const preloadImage = async (url: string) => {
    if ((url.length > 0) && !_imageCache.has(url)) {
        _addCacheEntry(url);

        logger.logImage('preloadImage for: ' + url);

        return _promiseLoadImage(url)
            .then(img => _finishPreload(url, img as ImageElType))
            .catch(err => {
                logger.error(err);
                _finishPreload(url, undefined);
            });
    }
}

export const preloadImages = async (urls: string[]) => {

    if (urls.length > 0) {
        const promises = new Array<Promise<void>>();
        urls.forEach(url => {
            promises.push(preloadImage(url));
        });

        return Promise.all(promises);
    }
}

export const clearImageCache = () => {
    logger.log('clearImageCache: ' + _imageCache.size);
    _imageCache.clear();
}

// Function used to request an image usable by a client.
// Primary caller is our useCachedImage hook.
export const requestImage = (url: string, cbFunc: ImageRequestCallback):
    [image: ImageElType, status: ImageStatus] => {

    // Sanity check that we were actually 
    // given some sort of url string.
    if (!url) {
        return [undefined, 'error'];
    }

    // If the requested url is already in our cache...
    if (_imageCache.has(url)) {

        // Get info for it.
        const info = _getCachedInfo(url);

        // Then check the status.
        switch (info.status) {

            // If it's still loading, add the requestor's
            // callback function to the info and return.
            case 'loading':
                info.callbacks.add(cbFunc);
                return [undefined, 'loading'];

            // If already loaded...
            case 'loaded':
                {
                    // Get a NEW copy of the image to be
                    // used exclusively by THIS client.
                    const clientImgCopy = _getImgForClient(info);

                    // Depending on a number of factors, including where
                    // the image was sourced from, etc., the copy may
                    // or may not be immediately ready for use. If it is...
                    if (clientImgCopy.complete) {

                        // Return it directly to the caller.
                        // The client can then use it immediately,
                        // and doesn't have to wait for a callback.
                        //logger.logImage('requestImage gets complete copy: ' + url);
                        return [clientImgCopy, 'loaded'];
                    }
                    else {
                        // Otherwise, we'll hand off to our
                        // helper to deal with tracking the load
                        // and notifying the client.
                        //logger.logImage('requestImage calls _delayedNotify: ' + url);
                        _delayedNotify(cbFunc, clientImgCopy, info.url);

                        // Return here tells our caller
                        // that its still loading.
                        return [undefined, 'loading'];
                    }
                }

            // For anything else, just return
            // undefined and the status.
            default:
                return [undefined, info.status];
        }
    }
    else {
        // We haven't seen this url before. Add
        // an entry for it to our cache.
        const info = _addCacheEntry(url);

        // Add the caller's callback function
        // to the info's set.
        info.callbacks.add(cbFunc);

        // Call a helper to load and track what
        // will be our 'goldenCopy' for this url.
        _doPrimaryLoad(info);

        // Return will tell requestor that
        // the image is loading.
        return [undefined, info.status];
    }
}

export const anyImagesLoading = (): boolean => {
    return (_numImagesLoading > 0);
}

