import { ChassisProject, EnvRating, IOModuleWiring, ChassisCfgGrpCategory, onCfgLocAttrCreatedCallback, createNewLocAttributeInfo } from "../../../types/ProjectTypes";
import { PSInputVoltage } from "../../../types/PowerTypes";
import { getUserModuleSelectionsFromPointSectionInfo } from "../../../model/IOModule";
import { updateGuidedSelection } from '../../../model/GuidedSelection';
import {
  LocAttributeInfo,
  MicroparamDigitalinputOutputMax,
  MicroparamDigitalinputOutput,
  MicroInputType,
  DeviceType,
} from "../../../types/ProjectTypes";

import {
    getMicroModules,
    getPowerSuppliesMicro,
} from "../../../util/EngInfoHelp";
import { getIOModulePointsForMask } from "../../../util/IOModuleHelp";
import {
  getPluginModInfoMap,
  getIOModInfoMap,
} from "../../../model/EngineeringData";
import { logger } from "../../../util/Logger";
import {
  refreshLocAttrInfoSelectionArray,
  getLocAttributeSetting,
} from "../../../model/GuidedSelection";
import { IO_Mask } from "../../../types/IOModuleTypes";
import {
  collectHardwareInfo,
  KeyValuePair,
  ProdSelCategory,
} from "../../../model/ProductSelection";
import { makeSettingGroup } from "../../../settings/SettingsHelp";
import { displayAlertMsg } from "../../../util/MessageHelp";
import { PlatformMicro } from "../../PlatformConstants";
import { convertGuidedSelAttrToAppVal } from "../../../implementation/ImplHardwareGen";
import { IOEntryModeEnum } from "../../../types/SettingsTypes";
import {
  EngInfoIOModule,
  EngInfoPowerSupply,
} from "../../../engData/EngineeringInfo";
import { IOModuleMap } from "../../../types/EngDataTypes";
import { ioExpasnionPowerLimit, micropowerExpansionModule } from "./MicroGeneralImpl";

export const microgetPowerCatnumber = (
  microPower: EngInfoPowerSupply[],
  catNumber: string
): EngInfoPowerSupply[] => {
  const getExactPower = microPower.filter((powerModule) => {
    //micro 800 only 3 occurence will happens L20,L50 and L70
    if (catNumber?.indexOf("LC20") !== -1) {
      return powerModule.micro820Support && powerModule.catNo;
    }
    if (catNumber?.indexOf("L50") !== -1) {
      return powerModule.micro850Support && powerModule.catNo;
    }
    if (catNumber?.indexOf("L70") !== -1) {
      return powerModule.micro870Support && powerModule.catNo;
    } else {
      return false;
    }
  });
  return getExactPower;
};

export const microGetChassisCatalog = (envRat: EnvRating): string => {
  switch (envRat) {
    case EnvRating.ConformalCoated:
      return "2080-CHASSIS-K";

    default:
      return "2080-CHASSIS";
  }
};

 const microgetCombo = (info:EngInfoIOModule): boolean=>{
  const typeDatalength = info.pointType.typeData.length || 0;
  const pointTypeData = typeDatalength > 1 && info.pointType.selfCfg === 0;
  if(pointTypeData){
      return pointTypeData;
  }

   return false;
}

const modifySetWithArraySchema = (
  map: IOModuleMap,
  type: number,
  er?: string
): MicroparamDigitalinputOutputMax[] => {
  const newArray = [] as MicroparamDigitalinputOutputMax[];
  newArray.length = 0
  map.forEach((info) => {
    const ptCount = getIOModulePointsForMask(info,type);
    const isComboExist= microgetCombo(info)
    switch (type) {
        case IO_Mask.DI:
            newArray.push({ catalogNumber: info.catNo, ioPoints: ptCount, envRating: info.envInfo.rating, ioType: IO_Mask.DI ,isCombo: isComboExist });
        return;
        case IO_Mask.DO:
            newArray.push({ catalogNumber: info.catNo, ioPoints: ptCount, envRating: info.envInfo.rating, ioType: IO_Mask.DO, isCombo: isComboExist});
        return;
        case IO_Mask.AI:
            newArray.push({ catalogNumber: info.catNo, ioPoints: ptCount, envRating: info.envInfo.rating, ioType: IO_Mask.AI, isCombo: isComboExist });
        return;
        case IO_Mask.AO:
            newArray.push({ catalogNumber: info.catNo, ioPoints: ptCount, envRating: info.envInfo.rating, ioType: IO_Mask.AO, isCombo: isComboExist });
            return;
        case IO_Mask.RO:
          newArray.push({ catalogNumber: info.catNo, ioPoints: ptCount, envRating: info.envInfo.rating, ioType: IO_Mask.RO, isCombo: isComboExist  });
          return;
        case IO_Mask.RTD:
          newArray.push({ catalogNumber: info.catNo, ioPoints: ptCount, envRating: info.envInfo.rating, ioType: IO_Mask.RTD, isCombo: isComboExist });
          return;
        case IO_Mask.Therm:
          newArray.push({ catalogNumber: info.catNo, ioPoints: ptCount, envRating: info.envInfo.rating, ioType: IO_Mask.Therm, isCombo: isComboExist });
          return;
        case IO_Mask.HSC:
          newArray.push({ catalogNumber: info.catNo, ioPoints: ptCount, envRating: info.envInfo.rating, ioType: IO_Mask.HSC, isCombo: isComboExist  });
          return;
    }
  });

  // const updatedNewArray = newArray.filter(rating => rating.envRating === er && rating.ioType === type)
  const updatedNewArray = newArray.filter(rating => rating.envRating === er  && rating.ioPoints > 0)
  return updatedNewArray.sort((a, b) => b?.ioPoints - a?.ioPoints);
};

const getHarwaregenAlgoRequireData = (type:number, envRating?: string): [
  MicroparamDigitalinputOutputMax[],
  MicroparamDigitalinputOutputMax[]
] => {
  const allExpansionIOModules = modifySetWithArraySchema(
    getIOModInfoMap(PlatformMicro),
    type, envRating
  );
  const allPluginIOModules = modifySetWithArraySchema(
    getPluginModInfoMap(PlatformMicro),
    type, envRating
  );
  return [allExpansionIOModules, allPluginIOModules];
};


const getControllerDIMaxCapacity = (catNumber: string,type:number, envRating:string):number => {
  const CatEngData = getMicroModules(PlatformMicro, catNumber);
  if (CatEngData == null)
    return 0;

  const [allExpansionIOModules, allPluginIOModules] =
    getHarwaregenAlgoRequireData(type, envRating);

  // Get controller plugin and exIo limit
  // Get getHighest Limit modules;

  const controllerPlguinLimit = CatEngData?.plugin
    ? parseInt(CatEngData?.plugin)
    : 0;
  const controllerExIoLimit = CatEngData?.Eio ? parseInt(CatEngData?.Eio) : 0;
  const highestLimitPluginModule = allPluginIOModules?.filter(mod=> mod?.ioType === IO_Mask.DI)[0];
  const highestLimitExIoModule = allExpansionIOModules?.filter(mod=> mod?.ioType === IO_Mask.DI)[0];
  // const controllerMaxEmbededDICapacity =
  //     controllerMaxEmbededIOCapacity(CatEngData, IO_Mask.DI) || 0;
  const controllerMaxEmbededDICapacity = getIOModulePointsForMask(CatEngData, IO_Mask.DI);


  const controllerMaxDICapacity =
    controllerMaxEmbededDICapacity +
    (highestLimitPluginModule?.ioPoints || 0) * controllerPlguinLimit +
    controllerExIoLimit * (highestLimitExIoModule?.ioPoints || 0);

  return controllerMaxDICapacity;
};

const getIOsEntryCumalatievSum = (
  loc: LocAttributeInfo
): MicroInputType => {
  // Step 1 : Get Total Input points of all type
  const ioPoints: MicroInputType = {
    digitalInput: 0,
    digitalOutput: 0,
    analogInput: 0,
    analogOutput: 0,
    relayOutput:0,
    rtd:0,
    therm:0,
    hsc:0


  };
  const spareCapacity = loc.hardware.spareCapacity || 0;

  //computing total sum of input points
  loc.hardware.ioModuleSelections.forEach((x) => {
    switch (x.selectedPoints[0].typeID) {
        case IO_Mask.DI:
        ioPoints.digitalInput += Math.ceil(x.selectedPoints[0].userPointCount+ (spareCapacity * x.selectedPoints[0].userPointCount));
        return;

        case IO_Mask.DO:
        ioPoints.digitalOutput += Math.ceil(x.selectedPoints[0].userPointCount+ (spareCapacity * x.selectedPoints[0].userPointCount));
        return;

        case IO_Mask.AI:
        ioPoints.analogInput += Math.ceil(x.selectedPoints[0].userPointCount+ (spareCapacity * x.selectedPoints[0].userPointCount));
        return;

        case IO_Mask.AO:
        ioPoints.analogOutput += Math.ceil(x.selectedPoints[0].userPointCount+ (spareCapacity * x.selectedPoints[0].userPointCount));
        return;

      case IO_Mask.RO:
        ioPoints.relayOutput += Math.ceil(x.selectedPoints[0].userPointCount+ (spareCapacity * x.selectedPoints[0].userPointCount));
        return;

      case IO_Mask.RTD:
        ioPoints.rtd += Math.ceil(x.selectedPoints[0].userPointCount+ (spareCapacity * x.selectedPoints[0].userPointCount));
        return;

      case IO_Mask.Therm:
        ioPoints.therm += Math.ceil(x.selectedPoints[0].userPointCount+ (spareCapacity * x.selectedPoints[0].userPointCount));
        return;

      case IO_Mask.HSC:
        ioPoints.hsc += Math.ceil(x.selectedPoints[0].userPointCount+ (spareCapacity * x.selectedPoints[0].userPointCount));
        return;
      default:
        return;
    }
  });
  return ioPoints ;
};

const harwareGenAlgoBasicModeStep1 = (loc: LocAttributeInfo) => {
  loc.hardware.isMicrovalid = false;
  const ControllersList = loc.hardware.microCatController
    ? loc.hardware.microCatController
    : [];
  if (ControllersList.length === 0) {
    loc.hardware.catScanner = undefined;
  }
  const cumalativeIOEntries = getIOsEntryCumalatievSum(loc);
  const envRating  = loc.arrAttributeNameToValue.find(x => x.attrID === 'ER')?.optionID === 'Std'? EnvRating.Standard:  EnvRating.ConformalCoated
  const filteredCapableControllers = ControllersList.filter(
    (contollerCatNum: string) =>
      getControllerDIMaxCapacity(contollerCatNum, IO_Mask.DI, envRating ) >=
      cumalativeIOEntries.digitalInput 
  );

  if (filteredCapableControllers.length === 0) {
    // throw error tha no controller is capable of handelling given imput points
    loc.hardware.isMicrovalid = true;
    loc.hardware.catScanner = undefined;
  }
  let harwareGenPassed = false;
  for (const filteredCapableController of filteredCapableControllers) {
    if (
      harwareGenAlgoBasicModeStep2(
        filteredCapableController,
        loc,
        cumalativeIOEntries
      )
    ) {
      harwareGenPassed = true;
      return;
    }
  }
  if (!harwareGenPassed) {
    loc.hardware.isMicrovalid = true;
    loc.hardware.catScanner = undefined;

  }

};

 const filterCombinationModule = (allModules: MicroparamDigitalinputOutputMax[], ioTypes: number): MicroparamDigitalinputOutputMax=>{
  
  const getmodules = allModules.filter(mod=> mod.ioPoints === allModules[0].ioPoints).filter(mod=> mod.isCombo)
  if(getmodules.length !== 0 &&  ioTypes === IO_Mask.DO){
    return getmodules[0];
  }
  return allModules[0];
}

 const isComboReplaceAllowed =(pluginMOdule: MicroparamDigitalinputOutputMax,loc: LocAttributeInfo): number=>{
  if(pluginMOdule && pluginMOdule?.isCombo && loc.hardware && loc.hardware.microCatIO && loc.hardware.microCatIO.length !== 0){
    const isComboIndex = loc.hardware.microCatIO.findIndex((mod)=> mod.deviceType === DeviceType.PlugIn && pluginMOdule.catalogNumber.includes(mod.catalogNumber))
    if(isComboIndex !== -1){
       loc.hardware.microCatIO && loc.hardware.microCatIO.length !== 0 && loc.hardware.microCatIO?.splice(isComboIndex,1,{
        idx:loc.hardware.microCatIO?.[isComboIndex]?.idx,
        ioPoints:pluginMOdule?.ioPoints,
        catalogNumber:pluginMOdule?.catalogNumber,
        deviceType:DeviceType.PlugIn
      })
    }
   return isComboIndex;

  }
  return -1;

}

export const shouldContinueOrStop= (isBreak: boolean, isContinue: boolean): string =>{
  if (isBreak) {
      return 'break';
  }
  if (isContinue) {
      return 'continue';
  }
  return 'stop';

}

export const shouldContinueOrStopWrap = (dueRequirement:number, selectedExpansionIOMOdule: MicroparamDigitalinputOutput[], loc: LocAttributeInfo,totControllerLimit: number): string=>{
 
    return shouldContinueOrStop( dueRequirement <=0, isControllerCapable(selectedExpansionIOMOdule, loc,totControllerLimit))
}


const isControllerCapable =(selectedExpansionIOMOdule:MicroparamDigitalinputOutput[],
  loc: LocAttributeInfo, controllerExIoLimit:number): boolean=>{
  const isReminder = loc.hardware.microCatIO && loc.hardware.microCatIO.length !== 0 ?loc.hardware.microCatIO.filter(mod=> mod.catalogNumber !== loc.hardware.microPlugin).length : 0
  const isReserve = loc.hardware.microCatIO && loc.hardware.microCatIO.some(mod=> mod.catalogNumber === loc.hardware.microPlugin);
  const isReservelength = isReserve ? 1: 0;
  return (selectedExpansionIOMOdule.length + isReservelength + isReminder) < controllerExIoLimit ;
  }


  const getIotypePriority = (IOtype: number, dueRequirement: number): boolean => {
    switch (IOtype) {
      case IO_Mask.DI:
      case IO_Mask.DO:
      case IO_Mask.AI:
      case IO_Mask.AO:
      case IO_Mask.RO:
      case IO_Mask.HSC:
        return dueRequirement > 4;
      case IO_Mask.RTD:
      case IO_Mask.Therm:
        return dueRequirement > 2;
      default:
        return dueRequirement > 4;
    }
  };



const 
hardwaregenIOpointsCalc = (
  dueRequirement: number,
  controllerCatNum: string,
  IOtype:number,
  loc: LocAttributeInfo,
) => {
  const CatEngData = getMicroModules(PlatformMicro, controllerCatNum);
  const envRating  = loc.arrAttributeNameToValue.find(x => x.attrID === 'ER')?.optionID === 'Std'? EnvRating.Standard:  EnvRating.ConformalCoated
  const selectedExpansionIOMOdule:MicroparamDigitalinputOutput[] = []
  const [allExpansionIOModules, allPluginIOModules] =
    getHarwaregenAlgoRequireData(IOtype, envRating);

  const controllerPlguinLimit = CatEngData?.plugin
    ? parseInt(CatEngData?.plugin)
    : 0;
  const controllerExIoLimit = CatEngData?.Eio ? parseInt(CatEngData?.Eio) : 0;
  const highestLimitExIoModule = allExpansionIOModules[0];
  const microPower = getPowerSuppliesMicro(PlatformMicro);
  const ioExpansionCat = microPower.filter(res=> res.subType1 === '')[0]
  const totalControllerModuleLimit = controllerPlguinLimit + controllerExIoLimit;
  let remainder = 0;
  const EXIOprioritry = getIotypePriority(IOtype, dueRequirement)
     //adding reserve plugin on first slot based on microPlugin value
     const reservePluginCount  =  !loc.hardware.microPlugin ? 0 : 1;
  
     if(reservePluginCount === 1 && loc.hardware.microPlugin){
       const reservePluginMOdule = allPluginIOModules.filter(item=>item.catalogNumber === loc.hardware.microPlugin).map(item=>{
         return {
           ioPoints:item?.ioPoints,
           catalogNumber:item.catalogNumber
         }
       })[0];
       if(loc.hardware.microCatIO && !loc.hardware.microCatIO.some(item=> item.catalogNumber ===  loc.hardware.microPlugin )){

        loc.hardware.microCatIO.push({
          idx:0,
          ioPoints:(reservePluginMOdule?.ioPoints),
          catalogNumber:loc.hardware.microPlugin,
          deviceType:DeviceType.PlugIn
        })
       }
     }
  //execute the logic for adding Io and pluging in loc for each type and handle remainder also;
  if (EXIOprioritry && allExpansionIOModules.length !== 0) {
    let expansionIOPSAdded = false;
      remainder = loc.hardware.microCatIO?.filter(item=>item.deviceType===DeviceType.IOExpansion || item.deviceType===DeviceType.PS ).length || 0;

    // adding expansion IOs
    const additionalSlotsForIOPS = controllerExIoLimit>ioExpasnionPowerLimit?1:0;
    for (let index = controllerPlguinLimit + remainder; index < totalControllerModuleLimit+additionalSlotsForIOPS; index++) {
      if(index === micropowerExpansionModule(controllerPlguinLimit) && !expansionIOPSAdded){
        selectedExpansionIOMOdule.push({
          idx:index,
          ioPoints:0,
          catalogNumber:ioExpansionCat.catNo,
          deviceType:DeviceType.PS

        })
        expansionIOPSAdded = true;
        continue;
      }
      if (dueRequirement > highestLimitExIoModule?.ioPoints) {
        selectedExpansionIOMOdule.push({
          idx:index,
          ioPoints:highestLimitExIoModule?.ioPoints,
          catalogNumber:highestLimitExIoModule.catalogNumber,
          deviceType:DeviceType.IOExpansion

        })
        dueRequirement -= highestLimitExIoModule?.ioPoints // 72-32 = 40-32 
        if(dueRequirement<=4){
          break;
        }
        continue;
      }
      //find the nearest module of the expansion to satisfy due requirement.;
      const nearestMathedExIOMOdule = allExpansionIOModules.filter(item=>item?.ioPoints-dueRequirement>=0).map(item=>{
        return {
          ioPoints:item?.ioPoints,
          catalogNumber:item.catalogNumber
        }
      }).sort((a,b)=>a?.ioPoints-b?.ioPoints)[0];
      selectedExpansionIOMOdule.push({
        idx:index,
        ioPoints:nearestMathedExIOMOdule?.ioPoints,
        catalogNumber:nearestMathedExIOMOdule?.catalogNumber,
        deviceType:DeviceType.IOExpansion

      })
      dueRequirement -= nearestMathedExIOMOdule?.ioPoints || 0;
      if(dueRequirement <=4){
        break;
      }
   
    }
   
    //if due requirement is >0
  } 
  if((dueRequirement>0 ||!EXIOprioritry) && allPluginIOModules.length !== 0) {
    //adding plugins
    remainder = loc.hardware.microCatIO?.filter(item=>item.deviceType===DeviceType.PlugIn).length || 0;

    const highestLimitPluginModule = filterCombinationModule(allPluginIOModules, IOtype);
    // adding expansion IOs


    for (let index = 0 + remainder ; index < controllerPlguinLimit; index++) {

      if (dueRequirement > highestLimitPluginModule?.ioPoints) {
        const isComboReplace:number = isComboReplaceAllowed(highestLimitPluginModule,loc);
        isComboReplace === -1 && selectedExpansionIOMOdule.push({
           idx:index,
           ioPoints:highestLimitPluginModule?.ioPoints,
           catalogNumber:highestLimitPluginModule.catalogNumber,
           deviceType:DeviceType.PlugIn
         })
        dueRequirement -= highestLimitPluginModule?.ioPoints // 2
        continue;
      }
      //find the nearest module of the expansion to satisfy due requirement.;
      const nearestMathedPluginMOdule = filterCombinationModule(allPluginIOModules.filter(item=>item?.ioPoints-dueRequirement>=0).map(item=>{
        return {
          ioPoints:item?.ioPoints,
          catalogNumber:item?.catalogNumber,
          isCombo: item.isCombo
        }
      }).sort((a,b)=>a?.ioPoints-b?.ioPoints),IOtype);
    
      const isComboReplace:number = isComboReplaceAllowed(nearestMathedPluginMOdule,loc);
        isComboReplace === -1 && selectedExpansionIOMOdule.push({
          idx:index,
          ioPoints:nearestMathedPluginMOdule?.ioPoints,
          catalogNumber:nearestMathedPluginMOdule?.catalogNumber,
          deviceType:DeviceType.PlugIn
        })


      dueRequirement -= nearestMathedPluginMOdule?.ioPoints;
      if(dueRequirement <=0){
        break;
      }
    }
  }


 if(dueRequirement >0 ){

  const additionalSlotsForIOPS = controllerExIoLimit>ioExpasnionPowerLimit?1:0;
  const totControllerLimit = totalControllerModuleLimit + additionalSlotsForIOPS
  if(isControllerCapable(selectedExpansionIOMOdule, loc,totControllerLimit)){
    let expansionIOPSAdded = false;
    remainder = loc.hardware.microCatIO?.filter(item=>item.deviceType===DeviceType.IOExpansion || item.deviceType===DeviceType.PS ).length || 0;
    for (let index = controllerPlguinLimit + remainder; index < totControllerLimit; index++) {
      if(index === micropowerExpansionModule(controllerPlguinLimit) && !expansionIOPSAdded){
        selectedExpansionIOMOdule.push({
          idx:index,
          ioPoints:0,
          catalogNumber:ioExpansionCat.catNo,
          deviceType:DeviceType.PS

        })
        expansionIOPSAdded = true;
 
       
      
      }
      if (dueRequirement > highestLimitExIoModule?.ioPoints) {
        selectedExpansionIOMOdule.push({
          idx:index,
          ioPoints:highestLimitExIoModule?.ioPoints,
          catalogNumber:highestLimitExIoModule.catalogNumber,
          deviceType:DeviceType.IOExpansion

        })
        
        dueRequirement -= highestLimitExIoModule?.ioPoints // 72-32 = 40-32 
        const result = shouldContinueOrStopWrap(dueRequirement, selectedExpansionIOMOdule, loc,totControllerLimit)
        if(result === 'break'){
          break;
        }
        if((result === 'continue')){
          continue;
        }
        if((result === 'stop')){
          return false;
        }
       
    
      }
      //find the nearest module of the expansion to satisfy due requirement.;
      const nearestMathedExIOMOdule = allExpansionIOModules.filter(item=>item?.ioPoints-dueRequirement>=0).map(item=>{
        return {
          ioPoints:item?.ioPoints,
          catalogNumber:item.catalogNumber
        }
      }).sort((a,b)=>a?.ioPoints-b?.ioPoints)[0];
      selectedExpansionIOMOdule.push({
        idx:index,
        ioPoints:nearestMathedExIOMOdule?.ioPoints,
        catalogNumber:nearestMathedExIOMOdule?.catalogNumber,
        deviceType:DeviceType.IOExpansion

      })
      dueRequirement -= nearestMathedExIOMOdule?.ioPoints || 0;
    
      const result = shouldContinueOrStopWrap(dueRequirement, selectedExpansionIOMOdule, loc,totControllerLimit)
      if(result === 'break'){
        break;
      }
      if((result === 'continue')){
        continue;
      }
      if((result === 'stop')){
        return false;
      }
     
    }
    if(dueRequirement > 0){
      return false
    }

  }
  else{
    return false;
  }
  
  
   }
  

  loc.hardware.microCatIO = loc.hardware.microCatIO? [...loc.hardware.microCatIO,...selectedExpansionIOMOdule]:[...selectedExpansionIOMOdule];
  return true;
};


const harwareGenAlgoBasicModeStep2 = (
  controllerCatNum: string,
  loc: LocAttributeInfo,
  cumalativeIOEntries: MicroInputType
): boolean => {
  const CatEngData = getMicroModules(PlatformMicro, controllerCatNum);
  //start consuming controller's embeded Points;
  const getIOEntriesByType=(type:number)=>{
    switch(type){
    case IO_Mask.DI:
        return cumalativeIOEntries.digitalInput;
    case IO_Mask.DO:
        return cumalativeIOEntries.digitalOutput;
    case IO_Mask.AI:
        return cumalativeIOEntries.analogInput;
    case IO_Mask.AO:
        return cumalativeIOEntries.analogOutput;
    case IO_Mask.RO:
      return cumalativeIOEntries.relayOutput;
    case IO_Mask.RTD:
        return cumalativeIOEntries.rtd;
    case  IO_Mask.HSC:
        return cumalativeIOEntries.hsc;
    case IO_Mask.Therm:
          return cumalativeIOEntries.therm;
  default:
        return 0;
    }
  }
  loc.hardware.microCatIO = [];
  let dueRequirement = 0;
  const ioTypes: number[] = []
  loc.hardware.ioModuleSelections.forEach((x) => {
  
    if (!ioTypes.includes(x.selectedPoints[0].typeID)) {
      ioTypes.push (x.selectedPoints[0].typeID) 
  }
    })
  
  for (const ioType of ioTypes) {
    dueRequirement = getIOEntriesByType(ioType) - (CatEngData && getIOModulePointsForMask(CatEngData, ioType) || 0); 
    if (dueRequirement > 0) {
      if(!hardwaregenIOpointsCalc(dueRequirement, controllerCatNum,ioType, loc)){
        return false;
      }
    }
  }

  loc.hardware.catScanner = !loc.hardware.isMicrovalid ? controllerCatNum : undefined;
  return true;
};

export const microPrepareLocAttrHardwareForGen = (
  loc: LocAttributeInfo,
  project: ChassisProject
) => {
  refreshLocAttrInfoSelectionArray(loc);
  // Start our chassis catalog out as 'standard.
  loc.hardware.catChassis = microGetChassisCatalog(EnvRating.Standard);
  // Hardcode for consistency: Attribute 'ER' - Env. Rating.
  const er = loc.arrAttributeNameToValue.find((x) => x.attrID === "ER");
  if (er)
    loc.hardware.envRating = convertGuidedSelAttrToAppVal(
      PlatformMicro,
      er.attrID,
      er.optionID,
      true
    ) as EnvRating;
  loc.hardware.catChassis = microGetChassisCatalog(loc.hardware.envRating);
  // Hardcode necessity: Attribute 'CTRL' - defines whether a
  // configuration will be generated with or without a Controller
  loc.hardware.remoteIOOnly = loc.arrAttributeNameToValue.some(
    (attr) => attr.attrID === "CTRL" && attr.optionID === "No"
  );

  // Hardcode necessity: Attribute 'CT' - Defines whether I/O
  // will be placed in Controller chassis or if all I / O will
  // be in remote chassis. Value 'Ded' for 'dedicated'.
  loc.hardware.ctrlDedicatedChassis = loc.arrAttributeNameToValue.some(
    (attr) => attr.attrID === "CT" && attr.optionID === "Ded"
  );
  loc.hardware.ioModuleSelections = getUserModuleSelectionsFromPointSectionInfo(
    loc.pointEntrySection,
    project.config.IOEntryMode
  );
  loc.hardware.ioModuleSelections.forEach((sel) => {
    for (let idx = 0; idx < sel.quantity; ++idx)
      loc.hardware.catIOModules.push(sel.catalog);
  });

  loc.hardware.ioModuleCount = loc.hardware.catIOModules.length;
  let percentSpareIO = 0;
  const settingSpareIO = getLocAttributeSetting(loc, "SC");
  if (settingSpareIO) {
    percentSpareIO = Number(settingSpareIO.selectedOption.id) / 100;
  }

  loc.hardware.spareCapacity = percentSpareIO || 0;

  // Hardcode necessity: Attribute 'CS' - Defines the fixed slot
  // chassis size to be used in the configuration for all chassis.
  // (Auto - size of Ctrl/Remote Chassis(s) depending upon your
  // I/O requirements and Dedicated Ctrl Chassis).

  const additionalAttributes: KeyValuePair[] = [];
  let arrHardware = collectHardwareInfo(loc, additionalAttributes);
  if (loc.hardware.microCatController?.length !== 0) {
    loc.hardware.microCatController = [];
    loc.hardware.microCatIO = [];
  }
  loc.hardware.microPlugin = undefined;
  arrHardware.sort((a,b)=> parseInt(a.groupPriority) - parseInt(b.groupPriority))
  const cumalativeIOEntries = getIOsEntryCumalatievSum(loc);
  if(cumalativeIOEntries.digitalOutput > cumalativeIOEntries.relayOutput){
    arrHardware = arrHardware.filter(arr=> arr.mainCatalog.includes('BB') || arr.category === ProdSelCategory.PlugIn)

  }
  else if(cumalativeIOEntries.digitalOutput < cumalativeIOEntries.relayOutput){
    arrHardware =arrHardware.filter(arr=> arr.mainCatalog.includes('WB')|| arr.category === ProdSelCategory.PlugIn)
  }

  arrHardware.forEach((hwInfo) => {
    switch (hwInfo.category) {
      case ProdSelCategory.Comm:
        loc.hardware.microCatController?.push(hwInfo.mainCatalog);
        break;
      case ProdSelCategory.PlugIn:
        loc.hardware.microPlugin = hwInfo.mainCatalog;
        break;
      default:
        displayAlertMsg("Unknown Hardware Category: " + hwInfo.category);
        break;
    }
  });
  harwareGenAlgoBasicModeStep1(loc);
  // skiping all catalognumber default set as false
  // let shouldSkip = false;
  // product selection values  of catalog number based on guided selection attributes
  // loc.hardware.microCatController &&
  // loc.hardware.microCatController.forEach((catNumber) => {
  //   // checking catalog number one by one and return true or false based on below function definition
  //   const selectionOfController =
  //     !shouldSkip && getMicroController(catNumber, loc);
  //   // once catalog number matches based on smallest to largest controller it return true
  //   if (selectionOfController) {
  //     //No need to go inside foreach loop once catalog number matches
  //     shouldSkip = true;
  //     //values of selection of one catalog number
  //     loc.hardware.catScanner = catNumber;
  //     loc.hardware.microCatController &&
  //       loc.hardware.microCatController.length === 0;
  //   }
  // });

  // const Step1FilteredArray = loc.hardware.microCatController ? loc.hardware.microCatController.filter(cotrollerCatNum =>  filterGuidedControllerAlgo(cotrollerCatNum,loc)):[];
  // if(Step1FilteredArray.length===0){
  //   // throw the error for modal and block user
  // }
  // //step2:
  // // populating the plugin and modules with each Controller as soon as we got a controller satisfying requirements.

  // Step1FilteredArray.forEach((cotrollerCatNum)=>{

  // })

  //Power supply integration
  const selectedPowervalue = getLocAttributeSetting(loc, "CV");
  const microPower = getPowerSuppliesMicro(PlatformMicro);
  if (
    selectedPowervalue &&
    selectedPowervalue.selectedOption.display !== PSInputVoltage.DC24V &&
    loc.hardware.catScanner
  ) {
    const getPowerCatnumberMicro = microgetPowerCatnumber(
      microPower,
      loc.hardware.catScanner
    );
    loc.hardware.catPowerSupply = getPowerCatnumberMicro[0].catNo;
    loc.hardware.catRemotePowerSupplier = getPowerCatnumberMicro[0].catNo;
  }
  // Validate the hardware - we should always have values for
  // the remote I/O chassis/PSU/Comm
  if (!loc.hardware.catChassis)
    throw new Error("Failed to select valid MICRO Remote chassis(s) and PSUs.");

  const getEngData =
    loc?.hardware?.catScanner &&
    getMicroModules(PlatformMicro, loc?.hardware?.catScanner);

  if (getEngData) {
    const ioExpansionPower = (parseInt(getEngData.Eio)>ioExpasnionPowerLimit?1:0);
    loc.hardware.numChassisSlot =
      parseInt(getEngData.Eio) + parseInt(getEngData.plugin) + ioExpansionPower;
    loc.hardware.numCtrlChassisSlot =
      parseInt(getEngData.Eio) + parseInt(getEngData.plugin) + ioExpansionPower ;
    loc.hardware.ExpansionIO = parseInt(getEngData.Eio);
    loc.hardware.Plugin = parseInt(getEngData.plugin);
  } 
  return;
};

export interface MICROChassisCfgData {
  envType: EnvRating;
  numSlots: number;
  wiringType: IOModuleWiring;
  highAvail: boolean;
  numRMs: number;
  psVoltage: PSInputVoltage;
  psSelCatalog: string;
  chassisCatalog: string;
  controllerOnly?: string;
  buSelCatalog: string;
}

export const createMICROChassisCfgData = (): MICROChassisCfgData => {
  return {
    envType: EnvRating.Standard,
    numSlots: 10,
    wiringType: IOModuleWiring.Screw,
    highAvail: false,
    numRMs: 0,
    psVoltage: PSInputVoltage.DC24V,
    psSelCatalog: "",
    chassisCatalog: "",
    buSelCatalog: "",
  };
};

let _cfgAttrCallback: onCfgLocAttrCreatedCallback | undefined = undefined;

export const microGetLocAttrInfoForChassisEdit = (
  platform: string,
  callback: onCfgLocAttrCreatedCallback
) => {
  _cfgAttrCallback = callback;

  const configAttrInfo = createNewLocAttributeInfo(
    platform,
    "",
    "",
    IOEntryModeEnum.Basic
  );

  // Note: We skip validation so that everything is
  // reloaded and all options are present (3rd param === true)
  updateGuidedSelection(configAttrInfo, _onGdSelForChassisEditLoaded, true);
};

const _onGdSelForChassisEditLoaded = (
  success: boolean,
  locAttrInfo: LocAttributeInfo | undefined
) => {
  if (_cfgAttrCallback == null)
    throw new Error(
      "microGetLocAttrInfoForChassisEdit(): callback function undefined!"
    );

  if (success && locAttrInfo) {
    const valid = microPrepareLocAttrForChassisConfig(locAttrInfo);
    _cfgAttrCallback(valid, locAttrInfo);
    return;
  }

  throw new Error("_onGdSelForChassisEditLoaded(): Guided Selection failed!");
};

export const microPrepareLocAttrForChassisConfig = (
  locAttrInfo: LocAttributeInfo
): boolean => {
  // Start by pulling ALL of the settings into a groups.
  // The idea here is to rearrange the settings into 3
  // attribute groups: Cfg Page 1; Cfg Page 2; and all
  // the other settings not in the first 2 pages. The
  // pages relate to the Cfg Dlg Tabs.
  const cfgDlgPage1 = makeSettingGroup(ChassisCfgGrpCategory.Chassis);
  const cfgDlgPage2 = makeSettingGroup(ChassisCfgGrpCategory.Power);
  const cfgNonDisplay = makeSettingGroup(ChassisCfgGrpCategory.Hidden);

  // Note: the settings will be added in the order that
  // they appear in the guide selection, which at this
  // point is the correct order. If that changes, we will
  // need to add a sort function or a smarter way of
  // building out each page's setting array.
  locAttrInfo.attrGroups.forEach((grp) => {
    grp.settings.forEach((setting) => {
      switch (setting.id) {
        case "ER":
        case "RTB":
          cfgDlgPage1.settings.push(setting);
          break;
        case "CV":
          cfgDlgPage2.settings.push(setting);
          break;
        default:
          // 2024.4.22 Ignore any errors on
          // the hidden attributes. These
          // attributes should NOT impact
          // the Edit/Add of a chassis.
          setting.ignoreError = true;

          cfgNonDisplay.settings.push(setting);
          break;
      }
    });
  });

  // Validate we have the correct number of settings in
  // each page. From above, Page1 has 4 and Page2 has 1.
  const valid =
    cfgDlgPage1.settings.length === 2 && cfgDlgPage2.settings.length === 1;
  if (valid === false)
    logger.error(
      "microGetLocAttrInfoForChassisEdit(): Error - not all required attribute settings found in Guided Selection."
    );

  // Dump the original attr groups, and add
  // the new groups.
  locAttrInfo.attrGroups.length = 0;
  locAttrInfo.attrGroups.push(cfgDlgPage1);
  locAttrInfo.attrGroups.push(cfgDlgPage2);
  locAttrInfo.attrGroups.push(cfgNonDisplay);

  return valid;
};

export const microGetChassisCfgDataFromLocAttr = (
  loc: LocAttributeInfo
): MICROChassisCfgData => {
  const data = createMICROChassisCfgData();
  // Refresh the selections in the loc's arrAttributeNameToValue.
  refreshLocAttrInfoSelectionArray(loc);

  const er = loc.arrAttributeNameToValue.find((x) => x.attrID === "ER");
  if (er)
    data.envType = convertGuidedSelAttrToAppVal(
      PlatformMicro,
      er.attrID,
      er.optionID,
      true
    ) as EnvRating;

  const cv = loc.arrAttributeNameToValue.find((x) => x.attrID === "CV");
  if (cv)
    data.psVoltage = convertGuidedSelAttrToAppVal(
      PlatformMicro,
      cv.attrID,
      cv.optionID,
      true
    ) as PSInputVoltage;

  const rtb = loc.arrAttributeNameToValue.find((x) => x.attrID === "RTB");
  if (rtb)
    data.wiringType = convertGuidedSelAttrToAppVal(
      PlatformMicro,
      rtb.attrID,
      rtb.optionID,
      true
    ) as IOModuleWiring;
  const arrHW = collectHardwareInfo(loc, []);
  arrHW.forEach((comp,index) => {
    switch (comp.category) {
      case ProdSelCategory.Comm:
        if(index === 0){
          data.buSelCatalog = comp.mainCatalog;
        }
      
        break;
      default:
        break;
    }
  });

  return data;
};
