import { EngDataImplementation, RegisterEngDataImpl } from '../../../implementation/ImplEngData';
import { EngInfoPackage } from '../../../types/EngDataTypes';
import { getModulesInPkg } from '../../../util/EngInfoHelp';
import { isSizeEmpty } from '../../../util/GeneralHelpers';
import { PlatformFlexHA } from '../../PlatformConstants';
import FlexHADefEngData from '../data/EngData5015.json';
import FlexHAChassisEngData from '../data/ChassisEngData5015.json';
import { EngInfoModule, IEngDataComponent, IEngDataModule, makeEngInfoFrom } from '../../../engData/EngineeringInfo';
import { logger } from '../../../util/Logger';
import { getFHASizeDetails } from './FlexHALayout';
import { DeviceType } from '../../../types/ProjectTypes';


const flexHAGetDfltEngDataJSON = (): object => {
    return FlexHADefEngData;
}

const loadChassis = (pkg: EngInfoPackage) => {
    const chasData = FlexHAChassisEngData as IEngDataComponent[];
    if (chasData) {
        for (let idx = 0; idx < chasData.length; idx++) {
            const entry = chasData[idx];
            const engInfo = makeEngInfoFrom(entry);
            if (engInfo && engInfo.isChassis) {
                if (engInfo.catNo) {
                    // Placeholder Chassis Catalog - should NOT
                    // be exposed in BOM, etc...
                    engInfo.isPlaceholder = true;
                    if (!pkg.infoMap.has(engInfo.catNo)) {
                        pkg.infoMap.set(engInfo.catNo, engInfo);
                    }
                    else {
                        logger.error('FlexHA::loadChassis(): Dup chassis in loadChassis: ' + engInfo.catNo);
                    }
                }
                else {
                    logger.error('FlexHA::loadChassis(): entry missing catNo!');
                }
            }
            else {
                logger.error('FlexHA::loadChassis(): unexpected makeEngInfoFrom result!');
            }
        }
    }
    else {
        logger.error('FlexHA::loadChassis(): no data?');
    }   
}


const _swapBankExpMod = (devCat: string, pkg: EngInfoPackage) => {
    const entry = pkg.infoMap.get(devCat);
    if (entry && (entry.type === DeviceType.Accessory)) {
        const asMod = new EngInfoModule(entry.engData as IEngDataModule);
        asMod.type = DeviceType.BankExpDevice;
        asMod.isBankExp = true;
        pkg.infoMap.set(devCat, asMod);
    }
}

const flexHAEngDataPostLoad = async (pkg: EngInfoPackage) => {

    _swapBankExpMod('5015-BEBLXT', pkg);
    _swapBankExpMod('5015-BEBRXT', pkg);

    const sizeDtls = getFHASizeDetails();

    const modules = getModulesInPkg(pkg);
    modules.forEach(mod => {
        if (isSizeEmpty(mod.imgSize)) {

            switch (mod.type) {
                case DeviceType.CommModule:
                    if (mod.subType2 === 'Adapter Base') {
                        mod.imgSize = { ...sizeDtls.sizeAdapterKit };
                    }
                    break;

                case DeviceType.SlotFiller:
                    mod.imgSize = { ...sizeDtls.ioBaseInfo.slotSize };
                    break;

                case DeviceType.IOModule:
                    mod.imgSize = { ...sizeDtls.ioBaseInfo.slotSize };
                    if (mod.slotsUsed === 2) {
                        mod.imgSize.width *= 2;
                    }
                    break;

                case DeviceType.BankExpDevice:
                    mod.imgSize = { ...sizeDtls.sizeBankExpComp };
                    break;

                default:
                    break;
            }
        }
    });

    loadChassis(pkg);

    return;
}

export const RegisterFlexHAEngDataImpl = () => {
    const flexHAImpl: EngDataImplementation = {
        platform: PlatformFlexHA,
        preload: undefined,
        getDfltEngData: flexHAGetDfltEngDataJSON,
        postLoad: flexHAEngDataPostLoad,
    };

    RegisterEngDataImpl(flexHAImpl);
}
