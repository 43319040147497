import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import './CommonToolbar.scss';
import '../styles/AppLayout.scss';
import { ToolbarAction } from './ToolbarActions';

import { importTemplate } from '../platforms/PlatformTemplateHelp';
import { logger } from '../util/Logger';
import { getLocAttrFromProject } from '../model/ChassisProject';
import {
	ComponentRenderInfo,
	DesignPageChildID,
	registerDesignPageCompRenderInfo,
	unregisterDesignPageCompRenderInfo
} from '../settings/DesignPageComponentRenderInfo';
import { ChassisProject } from '../types/ProjectTypes';
import { Box, Button, Divider, Tooltip } from '@mui/material';
import { AddIcon, ChevronRightIcon, CopyIcon, DeleteIcon, DownloadIcon, ExportIcon, ImportIcon, InformationCircleIcon } from '@rockwell-automation-inc/ra-meatball';
import { displayAlertMsg } from '../util/MessageHelp';
import { validIOExists } from '../util/IOModuleHelp';
import { useAppSelector } from '../redux/hooks';
import { saveInProgress } from '../redux/slices/SaveRestoreSlice';
import { selectUser, usePSAppSelector } from 'platform-services';
import { startupDetails } from '../redux/slices/StartupInfoSlice';
import {  PlatformMicro } from "../platforms/PlatformConstants";

const getLayoutBtnDtls = (
	saving: boolean,
	projGuid: string,
	user?: unknown
): [text: string, disable: boolean] => {

	if (user) {
		if (projGuid) {
			return ['HARDWARE LAYOUT', saving];
		}
		else {
			return ['CREATE PROJECT TO CONTINUE', true];
		}
	}
	else {
		return ['LOGIN TO CONTINUE', true];
	}
}

interface Props {
	project: ChassisProject;
	onTBAction: (action: string) => void;
	platform: string;
	refreshView: () => void;
}

const SettingsToolbar = (props: Props) => {
	const refImportTemplateFile = useRef<HTMLInputElement>(null);
	const [, setRenderCnt] = useState(0);
	const saving = useAppSelector(saveInProgress);
	const user = usePSAppSelector(selectUser);
	const startDtls = useAppSelector(startupDetails);

	useEffect(() => {
		// Register our refresh hooks so that other comps can use them.
		const hook: ComponentRenderInfo = { componentID: DesignPageChildID.SettingsToolbar, renderCount: 1, setRenderCount: setRenderCnt };
		registerDesignPageCompRenderInfo(hook);

		return (() => unregisterDesignPageCompRenderInfo(hook));
	}, []);

	// All hooks above here^

	//const locAttrInfo = project.config.locationSettings;
	const locAttrInfo = getLocAttrFromProject(props.project);

	// Set a flag to enable/disable the Add Point Entry button.
	let disableAddButton = true;
	const ioExists = validIOExists(locAttrInfo);
	disableAddButton = (ioExists === false);

	const noEntriesSelected = (): boolean => {
		//if (locAttrInfo.pointEntrySection.entries.some(x => x.selected === true && x.invalidEntry === false) === true)
		if (locAttrInfo.pointEntrySection.entries.some(x => x.selected === true) === true)
			return false;
		return true;
	}

	const onButtonClicked = (action: string) => {
		if (action === ToolbarAction.IMPORT) {
			onImportTemplate();
		}
		else {
			// 2024.2.9 If the guided selection is NOT loaded...
			if (locAttrInfo.attrGroups.length === 0) {
				// and the action is Export or Gen HW...
				if (action === ToolbarAction.EXPORT ||
					action === ToolbarAction.LAYOUT) {
					const act = (action === ToolbarAction.EXPORT ? 'Export a Template' : 'Generate Hardware');
					const message = `A valid Installation Location and Industry must be selected to ${act}.`
					displayAlertMsg(message);
					return;
				}
			}

			props.onTBAction(action);
		}
	}

	const onImportTemplate = () => {
		if (refImportTemplateFile.current)
			refImportTemplateFile.current?.click();
	}

	const onFileChange = (event: ChangeEvent) => {
		event.defaultPrevented = true; // Prevent warning.
		logger.log('Input Changed');

		if (refImportTemplateFile.current && refImportTemplateFile.current.files) {
			const fileCount = refImportTemplateFile.current.files.length;
			if (fileCount > 1) {
				alert('Only one file may be selected.');
			}
			else if (fileCount === 1) {
				const file = refImportTemplateFile.current.files[0];
				if (file) {
					importTemplate(file, props.project, props.refreshView);
				}

				// Reset the value of the input to nothing. Note:
				// if we do NOT do this and the user tries to import
				// the SAME file, onFileChange will not be triggered!
				refImportTemplateFile.current.value = '';
			}
			const importIOListClick = new CustomEvent("designImportIOList", {
				detail: {
				action: "Import IO List",
				properties: {
					category: "WebApp",
					label: "Import IO List",
				},
				},
			});
			document.getElementById("root")?.dispatchEvent(importIOListClick);
		}
	}

	const projGuid = startDtls
		? startDtls.projectGuid
		: '';

	const [layoutBtnText, disableLayoutBtn] =
		getLayoutBtnDtls(saving, projGuid, user);

	return (
		<div className='common-toolbar'>
			<div className="settings-toolbar-outer-div">
				<div className="common-toolbar-button-group">
					<Tooltip title='Add an I/O Point Entry'>
						<span>
							<Button
								id={ToolbarAction.ADDPOINTENTRY}
								variant='icon'
								disabled={disableAddButton}
								onClick={() => onButtonClicked(ToolbarAction.ADDPOINTENTRY)}
								color='mediumGrey'
							>
								<AddIcon
									fontSize='large'
								/>
							</Button>
						</span>
					</Tooltip>
					<Tooltip title='Copy Selected I/O Point Entry(s)'>
						<span>
							<Button
								id={ToolbarAction.COPYPOINTENTRY}
								variant='icon'
								disabled={noEntriesSelected()}
								onClick={() => onButtonClicked(ToolbarAction.COPYPOINTENTRY)}
								color='mediumGrey'
							>
								<CopyIcon
									fontSize='large'
								/>
							</Button>
						</span>
					</Tooltip>
					<Tooltip title='Delete Selected I/O Point Entry(s)'>
						<span>
							<Button
								id={ToolbarAction.DELETEPOINTENTRY}
								variant='icon'
								disabled={noEntriesSelected()}
								onClick={() => onButtonClicked(ToolbarAction.DELETEPOINTENTRY)}
								color='mediumGrey'
							>
								<DeleteIcon
									fontSize='large'
								/>
							</Button>
						</span>
					</Tooltip>
					{props.platform === PlatformMicro && 
						<Tooltip title='I/O Priority Information'>
						<span>
							<Button
								id={ToolbarAction.INFOPOINTENTRY}
								variant='icon'
								onClick={() => onButtonClicked(ToolbarAction.INFOPOINTENTRY)}
								color='mediumGrey'
							>
								<InformationCircleIcon
									fontSize='large'
								/>
							</Button>
						</span>
					</Tooltip>
					}
				</div>
				<Box className='common-toolbar-button-group common-toolbar-right-group' sx={{ color: 'mediumGrey.main' }}>
                    <Tooltip title='Download Template'>
                        <span>
                            <Button
                                id={ToolbarAction.DOWNLOADTEMPLATE}
                                variant='outlined'
                                startIcon={<DownloadIcon />}
                                onClick={() => onButtonClicked(ToolbarAction.DOWNLOADTEMPLATE)}
                                disabled={false}
                                color='mediumGrey'
                            >
                                Download Template
                            </Button>
                        </span>
                    </Tooltip>
                    <Tooltip title='Import'>
                        <span>
                            <Button
                                id={ToolbarAction.IMPORT}
                                variant='outlined'
                                startIcon={<ImportIcon />}
                                onClick={() => onButtonClicked(ToolbarAction.IMPORT)}
                                disabled={false}
                                color='mediumGrey'
                            >
                                Import
                            </Button>
                        </span>
                    </Tooltip>
					<Tooltip title='Export'>
						<span>
							<Button
								id={ToolbarAction.EXPORT}
								variant='outlined'
								startIcon={<ExportIcon />}
								onClick={() => onButtonClicked(ToolbarAction.EXPORT)}
								disabled={false}
								color='mediumGrey'
							>
								Export
							</Button>
						</span>
					</Tooltip>

					<Divider flexItem orientation='vertical' sx={{ height: 32, margin: 'auto' }} />
					
					<Tooltip title= "Hardware Layout">
					<Button
						id={ToolbarAction.LAYOUT}
						disabled={disableLayoutBtn}
						variant='contained'
						endIcon={<ChevronRightIcon />}
						onClick={() => onButtonClicked(ToolbarAction.LAYOUT)}
					>
						{layoutBtnText}
					</Button>
					</Tooltip>

				</Box>
				<div className="app-element-hidden">
					<input ref={refImportTemplateFile} type="file" id="FileLoader" onChange={onFileChange} />
				</div>
			</div>
		</div>
	);
}

export default SettingsToolbar;
