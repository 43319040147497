import { LogMsgLevel } from "../util/ProjectLog";
import {
    Chassis,
    ChassisModule,
    ChassisPowerSupply,
    UsageItemProgbarReplacement,
    UsageLevelStatus
} from "./ProjectTypes";


export enum PSInputVoltage {
    DC24V = '24V DC',
    DC48V = '48V DC',
    DC125V = '125V DC',
    AC120V = '120V AC',
    AC240V = '240V AC'
}

export const PSInputVoltages: PSInputVoltage[] = [
    PSInputVoltage.DC24V,
    PSInputVoltage.DC48V,
    PSInputVoltage.DC125V,
    PSInputVoltage.AC120V,
    PSInputVoltage.AC240V
];

export interface PwrUsageInfo {
    pctUsed: number;
    usageText: string;
    status: UsageLevelStatus;
}

export enum PowerUnitType {
    mA = 'mA',  // milliamps
    mW = 'mW'   // milliwatts
}

export enum PowerType {
    PT_None = 'PT_None',
    PT_524mA = 'PT_524mA',
    PT_ModSA = 'PT_ModSA',
    PT_AlwaysOK = 'PT_AlwaysOK'
}

export enum SAPwrVltg {
    VDC24 = '24VDC',
    VAC120 = '120VAC',
    VAC240 = '240VAC'
}


// Dual purpose.
//  - For a power supplier, props indicate supplied,
///   or available power.
//  - For a power consumer, props indicate consumed power
export interface PowerBreakdown {
    // Used for PT_524mA power type
    // only. 0's for other types.
    mAat5V: number;
    mAat24V: number;
    mWatt: number;

    // Used for PT_ModSA power type
    // only. 0's for other types.
    modPower: number;
    saPower: number;
}

//////////////////////// POWER BREAKDOWN TIPS ///////////////////////////////////
// These tips are intended to REPLACE the Power Progressbar.
// If a tip is defined for MOD/SA/5V/24/Watt, the corresponding 
// progress bar for the power type will be replaced with the 
// 'repLabel' text with a icon button next to the label. When 
// the icon button is clicked, the 'replTipText' is displayed.
export interface PowerBreakdownTips {
    tip5V?: UsageItemProgbarReplacement;
    tip24V?: UsageItemProgbarReplacement;
    tipWatt?: UsageItemProgbarReplacement;
    tipMod?: UsageItemProgbarReplacement;
    tipSA?: UsageItemProgbarReplacement;
}

export interface PowerBreakdownUsageInfo {
    info5V: PwrUsageInfo;
    info24V: PwrUsageInfo;
    infoWatt: PwrUsageInfo;
    infoMod: PwrUsageInfo;
    infoSA: PwrUsageInfo;
}

export interface PowerStatusLogMessage {
    status: LogMsgLevel;
    message: string;
}

export interface AltChassisSAPwrSupplyInfo {
    chassis: Chassis;
    saPowerSupplier?: ChassisModule;
    saPowerSupply?: ChassisPowerSupply;
}

