import React, { useEffect, useState } from 'react';
import '../selectComponents/SampleCompCard.scss';
import { SelectedCompInfoExtended } from '../selectComponents/SelectComponentsTypes';
import { DeleteIcon, TeaserCard, TeaserCardContent, TeaserCardDescription, TeaserCardTitle } from '@rockwell-automation-inc/ra-meatball';
import { Box, Button, TextField } from '@mui/material';
//import { useSelector } from "react-redux";
//import { selectUser } from "../redux/slices/oidcSlice";


interface Props {
  quantity: number;
  maxVal?: number;
  comp: SelectedCompInfoExtended;
  onQuantityChanged: (catNo: SelectedCompInfoExtended) => void;
  handleDelete: () => void

}

const SelectedProductCards = (props: Props) => {

  const [isHovered, setIsHovered] = useState(false);
  const [ quantity, setQuantity] = useState(props.quantity);

  const onChangeQty = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10);
    setQuantity(value);
  };

  useEffect(() => {
    props.onQuantityChanged({
      id: props.comp.id,
      catNo: props.comp.catNo,
      quantity: quantity,
      isSelected: false,
      description: props.comp.description
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity]);

  return (
    <TeaserCard
      raVariant="horizontal"
      hover={false}
      sx={{
        "&:hover": {
          boxShadow: "2px 2px 10px 2px #00000040",
        },
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <TeaserCardContent sx={{ width: "60%" }}>
        <TeaserCardTitle sx={{ fontSize: "1rem" }}>{props.comp.catNo}</TeaserCardTitle>
        <TeaserCardDescription
          sx={{
            fontSize: ".8rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            minHeight: "38px",
            "-webkit-line-clamp": "2",
            "-webkit-box-orient": "vertical",
          }}
        >
          {props.comp.description}
        </TeaserCardDescription>
      </TeaserCardContent>
      <Box display="flex" flexDirection="column" alignItems="flex-end" sx={{ width: "40%", p: "20px" }}>
        <TextField
          label="Qty"
          type="number"
          size="small"
          InputProps={{
            inputProps: {
              min: 1,
              sx: { textAlign: 'right', pt: 1, pb: 1 }
            }
          }}
          value={props.quantity}
          sx={{
            mt: "2px",
            width: '5rem',
            mb: 1,
            fieldset: {
              borderWidth: '1px !important',
              borderColor: '#E5E6E9 !important'
            },
          }}
          onClick={e => e.currentTarget.focus()}
          onChange={onChangeQty}
        />
        <Box sx={{ height: "6px" }}>
          {
            isHovered && (
              <Button
                variant="text"
                sx={{
                  marginTop: "1px",
                  minWidth: 0
                }}
                onClick={() => props.handleDelete()}
              >
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <DeleteIcon fontSize="large" />
                </Box>
              </Button>
            )
          }
        </Box>

      </Box>
    </TeaserCard>
  )
}

export default SelectedProductCards;