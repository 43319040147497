import { DEV_DEBUG } from "../types/Globals";
import { 
    ILoggerSpec, 
    IClientOptions,
    IClientConfig
} from "platform-services";
import { setConfigModified } from "../userProject/UserProjectHelp";
import axios from "axios";


const csaOnSigninRedirect = () => {
    // We're being notified by platform-services that we're
    // GOING TO BE redirected AWAY from our current page to
    // a login page. Reset our modified flag to prevent
    // the 'losing your data' alert when we leave. If our
    // config WAS modified, that state will be restored when
    // we come back and reload from a localStorage snapshot.
    setConfigModified(false);
}

const logSpec: ILoggerSpec = DEV_DEBUG
    ? {
        log: false,
        info: false,
        warn: true,
        error: true,
        debug: false,
        auth: false,
        user: false,
        custom: false,
    }
    : {
        log: false,
        info: false,
        warn: true,
        error: true,
        debug: false,
        auth: false,
        user: false,
        custom: false,
    };

// 0: Log.NONE
// 1: Log.ERROR
// 2: Log.WARN
// 3: Log.INFO
// 4: Log.DEBUG

const clientOpts: IClientOptions = {
    onSignInRedirect: csaOnSigninRedirect,
    userMgrLogLevel: DEV_DEBUG ? 2 : 2,

    // Uncomment the following to have unexpired
    // user attempt a silent-renew in 30 seconds.
    //forceSecondsToExpire: 90
}

export const psClientCfg: IClientConfig = {
    clientAppName: 'Control System Advisor',
    axiosInstance: axios,
    options: clientOpts,
    loggerSpec: logSpec,
}

