import {  PlatformMicro } from "../../PlatformConstants";
import { ActBtnInfo, DfltActBtnSpecs, LayoutActionType } from "../../../types/LayoutActions";
import { getLocCenter, offsetLoc, isPointInLocBu, getEmptyLoc, getEmptySize } from "../../../util/GeneralHelpers";
import { microConfigureChassis } from "../config/MICROChassisConfig";
import {
    updateChassis,
} from "../../../implementation/ImplGeneral";
import {

	addChassis,
	chassisChanged,
	copyAccys,
	detachModule,
	getEngineeringInfoFor,
	getModuleInSlot,
	getProjectFromChassis,
	getRack,

} from '../../../model/ChassisProject';
import {
	Chassis,
	ChassisElement,
	ChassisModule,
	ChassisPowerSupply,
	ChassisBaseUnit,
	DeviceCategory,
	ModuleDragStatus,
	Rack,
	IOModuleWiring,
    SlotIDError,
	DeviceType,
	ModuleSlotRestriction,SlotModulePair,
	MicroChassis,
	NO_SLOT,
	LocAttributeInfo
} from "../../../types/ProjectTypes";
import { PSInputVoltage } from "../../../types/PowerTypes";
import { LocAndSize, Size, Point } from "../../../types/SizeAndPosTypes";
import { getNewInstanceId } from "../../../util/InstanceIdHelp";
import {
	getDeviceCategory,
	getModuleSlotBreakdown,
	isValidSlotNumber,
    isDeviceCompatibleWithChassis,
    getDefaultImageSource,
} from "../../../model/ChassisProject";
import {
	MICROChassisImages,
	MICROLayoutInfo,
	MICROChassisTemplate,
} from "../types/MicroTypes";
import MicroChassisComp from "../components/MicroChasisComp";
import { chassisChanging, suspendUndoSnapshots } from "../../../util/UndoRedo";
import { unexpectedError } from "../../../util/ErrorHelp";

import { logger } from "../../../util/Logger";
import {
    EngInfoChassis,
	EngInfoCommModule,
	EngInfoComponent,
	EngInfoModule,
} from "../../../engData/EngineeringInfo";
import {
	getChassisEngInfo,
	getMicroModules,
	getPowerSupplyEngInfo,
	getModuleEngInfoioExpansionPs,
	getPowerSuppliesMicro,
	getMicroModulesExpansionDetails
} from "../../../util/EngInfoHelp";
import { addRequiredAccys } from "../../../util/AccessoriesHelp";
import {
	MicroChassisCompProps,
	createModule,
	GeneralImplSpec,
	getNumSlots,
	RegisterGeneralImpl
} from "../../../implementation/ImplGeneral";
import { ChassisElementType, ImageInfo } from "../../common/CommonPlatformTypes";
import { microAddModulesToChassis } from "../../common/HardwareGen";
import { SlotContent } from "../../../projTree/ProjectTreeChassis";
import { StageUnitsPerMM } from "../../../types/StageTypes";
import { DragDeviceInfo, DropResult, DropStatus, getBestPossibleDropStatus } from "../../../util/DragAndDropHelp";
import { microgetPowerCatnumber } from "./MicroGuidedSelection";
import { convertGuidedSelAttrToAppVal } from "../../../implementation/ImplHardwareGen";
import { getLocAttributeSetting } from "../../../model/GuidedSelection";
import { _snapDropModule } from "../../snap/SnapGeneralImpl";

// const _microChassisImgLoc = '/assets/micro/chassisImgs/';


// There seems to be some variance in actual product dims,
// but all of the images we use to render MICRO chassis
// elements are the same height - 280 pixels. And, in all
// cases, the given image's height overlays our entire chassis'
// height. That is, each individual image and the combination
// of all of them comprising a chassis rendering are always
// 280 pixels in height. 
const _microElemImageHt = 280; // pixels

// Again, there appears to be some minor variance in height dims
// according to product documentation between specific chassis,
// but they're close enough to establish what WE will consider
// to be the (nominal) height of an entire MICRO chassis (not
// including any mounting tabs that we don't show). That total
// height, as well as the height of the elements that make UP
// the chassis, are ALL considered to be 145 mm tall.
const _microNominalHt = 145;   // millimeters

// Determine a multiplier we can use to convert a
// number in image-size units to millimeters.
const _microImageSizeToMM = _microNominalHt / _microElemImageHt;

const _microImageScaleFactor = _microImageSizeToMM * StageUnitsPerMM;

const _getSizesInStageUnits = true;

export const defaultWidthBuDeletion = 350;
export const defaultheight = 551;
export const scaleupfactor = 1.15;
export const scaleDownfactor= 0.8;

//Io expansion more than 4 
export const ioExpasnionPowerLimit = 4;  

export const maxToMicroModules = 9; 
export const micropowerExpansionModule = (pluginLimit: number) => {

      return pluginLimit+ ioExpasnionPowerLimit;
}


const _getMICROSlotGroups = ( slots: string, plugin: string): number[] => {
	return [parseInt(plugin),parseInt(slots)];
}

export const getDefaultIoexpansionDimension= (chassis:MicroChassis):[ width: number, height: number]=>{
	const buCatnumber = chassis.bu?.catNo || chassis.buCatnumber
	const template = buCatnumber && microgetChassisTemplateForCat(chassis.catNo, buCatnumber);
	const slotWidth = 200;
	const slotHeight = 551;
	if(template){
		const [, slotWidth, slotHeight] = getImageInfo(template.emptySlot);
		return [slotWidth, slotHeight]
	}
	return [slotWidth, slotHeight]
}

// It includes slots position and  numbers logic 
export const assignModuleSlotLocationsMicro = (
    chassis: MicroChassis,
) => {
    // ID starts at 0.
    let nextID = 0;
	
    // For each module...
    for (let idx = 0; idx < chassis?.modules?.length; idx++) {

        // Get the module.
        const mod = chassis?.modules[idx];
		const microPower = getPowerSuppliesMicro(PlatformMicro);
		const ioExpansionCat = microPower.filter(res=> res.subType1 === '')[0]
        const pluginLimit = chassis.pluginModules?.length || 0;
        // If we have a module...
		if (mod && chassis?.modules[idx]?.catNo === ioExpansionCat.catNo) {

            // ALL modules get their .slotIdx prop set
            // to match their position in the chassis.
            mod.slotIdx = (pluginLimit + ioExpasnionPowerLimit);
	
			mod.slotID = (pluginLimit + ioExpasnionPowerLimit);
			nextID++;
        }
		else if (mod && chassis?.modules[idx]?.catNo !== ioExpansionCat.catNo && idx === (pluginLimit + ioExpasnionPowerLimit)) {

            // ALL modules get their .slotIdx prop set
            // to match their position in the chassis.
            mod.slotIdx = ++idx;
	
			mod.slotID = ++ nextID;
			nextID++;
            
        }
		
		else if (mod && chassis?.modules[idx]?.catNo !== ioExpansionCat.catNo && idx !== (pluginLimit + ioExpasnionPowerLimit)) {

            // ALL modules get their .slotIdx prop set
            // to match their position in the chassis.
            mod.slotIdx = idx;
	
			mod.slotID = nextID;
			nextID++;
            
        }
        else {
            nextID += 1;
        }
    }
}


const _getMICROChasElImgInfo = (xt: boolean, el: ChassisElementType): ImageInfo => {

	// Start by creating an 'empty' ImageInfo.
	const imgInfo: ImageInfo = {
		imgSrc: '',
		width: 0,
		height: 0
	};

	// Depending on env and element type,
	// we'll have different URLs for our 
	// image, and differing widths of the
	// actual images themselves.
	let imageUrl = '';
	let imageWidth = 0;

	// ALL of our images, however, 
	// have the SAME height.
	const imageHeight = _microElemImageHt;

	if (xt) {
		switch (el) {
			case ChassisElementType.EmptySlot:
				imageUrl = microGetImageSource(DeviceCategory.Chassis, MICROChassisImages.emptySlotXT);
				imageWidth = 102;
				break;

			case ChassisElementType.SlotSep:
				imageUrl = microGetImageSource(DeviceCategory.Chassis, MICROChassisImages.slotSepXT);
				imageWidth = 30;
				break;

			case ChassisElementType.RightCap:
				imageUrl = microGetImageSource(DeviceCategory.Chassis, MICROChassisImages.rightCapXT);
				imageWidth = 0;
				break;

			default:
				break;
		}
	}
	else {
		switch (el) {
			case ChassisElementType.EmptySlot:
				imageUrl = microGetImageSource(DeviceCategory.Chassis, MICROChassisImages.emptySlotStd);
				imageWidth = 102;
				break;

			case ChassisElementType.GrpSep:
				imageUrl = microGetImageSource(DeviceCategory.Chassis, MICROChassisImages.grpSepStd);
				imageWidth = 20;
				break;

			case ChassisElementType.RightCap:
				imageUrl = microGetImageSource(DeviceCategory.Chassis, MICROChassisImages.rightCapStd);
				imageWidth = 0;
				break;

			default:
				break;
		}
	}

	// If we got a location of the image in question...
	if (imageUrl.length) {
		// Convert our image's height and width to millimeters.
		let scaledWidth = imageWidth * _microImageSizeToMM;
		let scaledHeight = imageHeight * _microImageSizeToMM;

		// Then, if we're supposed to provide width and
		// height back in Stage units, multiply by 
		// the number of those per millimeter.
		if (_getSizesInStageUnits) {
			scaledWidth = scaledWidth * StageUnitsPerMM;
			scaledHeight = scaledHeight * StageUnitsPerMM;
		}

		// Push our results into the ImageInfo object.
		imgInfo.imgSrc = imageUrl;
		imgInfo.width = scaledWidth;
		imgInfo.height = scaledHeight;
	}

	// Return whatever we ended up with.
	return imgInfo;
}



const _getMICROChassisTemplate = (
	extendedTemp: boolean,
	conformal: boolean,
	slots: string,
	plugin: string
): MICROChassisTemplate => {
	const groups = _getMICROSlotGroups(slots, plugin);
	return {
		extendedTemp: extendedTemp,
		conformal: conformal,
		totalSlots: groups[0],
		slotGroups: groups,
		emptySlot: _getMICROChasElImgInfo(extendedTemp, ChassisElementType.EmptySlot),
		slotSep: _getMICROChasElImgInfo(extendedTemp, ChassisElementType.SlotSep),
		grpSep: _getMICROChasElImgInfo(extendedTemp, ChassisElementType.GrpSep),
		rightCap: _getMICROChasElImgInfo(extendedTemp, ChassisElementType.RightCap)
	};
}


//slot modules for plugin and i/o expansion sepeartion
export const microgetSlotID = (
  chassis: MicroChassis,
  slotNum: number
): string => {
  const pluginSlot =
    chassis.pluginModules?.length && chassis.pluginModules?.length !== 0
      ? chassis.pluginModules?.length
      : 0;

  // Default
  const mod = chassis.modules[slotNum];
  const pluginSlotLimit = pluginSlot || 0;
  if (mod) {
    switch (mod.deviceType) {
      case DeviceType.IOExpansion:
        return mod.slotID > pluginSlotLimit + ioExpasnionPowerLimit
          ? (mod.slotID - pluginSlotLimit).toString()
          : (mod.slotID - pluginSlotLimit + 1).toString();

      case DeviceType.PS:
        return "";

      case DeviceType.PlugIn:
        return (slotNum + 1).toString();
      default:
        return (slotNum + 1).toString();
    }
  }

  return isValidSlotNumber(chassis, slotNum)
    ? slotNum < pluginSlotLimit
      ? (slotNum + 1).toString()
      : (slotNum - pluginSlotLimit + 1).toString()
    : SlotIDError.InvalidSlotNum;
};

const microGetChassisSlotUsage = (chassis: Chassis):
	[
		slotsInUse: number,
		slotFillers: number,
		lastUsedSlot: number
	] => {

	// Init return values.
	let slotsInUse = 0;
	let slotFillers = 0;
	let lastUsedSlot = -1;

	// Walk modules array. Note that we DON'T auto-increment
	// our slotIdx in the for statement parms, and do that
	// manually inside the look instead.
	for (let slotIdx = 0; slotIdx < chassis.modules.length;) {

		// Get the module at the given slot if there is one.
		const mod = chassis.modules[slotIdx];

		// If so...
		if (mod) {
			// Bump our total slots in use by the
			// number of slots used by the module.
			slotsInUse += mod.slotsUsed;

			// If the module is a slot filler,
			// bump that quantity as well.
			if (mod.slotFiller) {
				slotFillers += 1;
			}

			// Change our last used slot to be the 
			// LAST slot used by the module.
			lastUsedSlot = mod.slotIdx + mod.slotsUsed - 1;

			// Bump our idx by that size.
			slotIdx += mod.slotsUsed;
		}
		else {
			// No module at the slot. 
			// Just increment our idx.
			slotIdx++;
		}
	}

	// Return our final numbers.
	return [slotsInUse, slotFillers, lastUsedSlot];
}

// Returns number of slots either empty
// or currently occupied by a slot filler.
export const microGetNumAvailableSlots = (chassis: Chassis): number => {
	const numSlots = getNumSlots(chassis);
    const [slotsInUse, slotFillers,] = microGetChassisSlotUsage(chassis);
    const inUseNotInclFillers = slotsInUse - slotFillers;
    return (numSlots - inUseNotInclFillers);
}


// WCS: no need for platform specific override.
// What we had here is now default behavior.
// Warning: incoming slotNum expected to ALREADY have
// been range-checked for specific chassis application.
//const microGetSlotID = (chassis: Chassis, slotNum: number): string => {
//	if (isValidSlotNumber(chassis, slotNum)) {
//		return slotNum.toString();
//	}
//	else {
//		return SlotIDError.InvalidSlotNum;
//	}
//}

export const getMICROChassisBasics = (chassisCatno: string, buCatNo: string):
	[et: boolean, conformal: boolean, Eio: string, plugin: string] => {
	const getEngData = getMicroModules(PlatformMicro, buCatNo);
	const info = getChassisEngInfo(PlatformMicro, chassisCatno);
	if (info && getEngData) {
		return [info.envInfo.etOk, info.envInfo.ccOk, getEngData.Eio,getEngData.plugin ];
	}
	else {
		throw new Error('getMicroChassisBasics FAILED to get eng data for: ' + chassisCatno);
	}
}



export const microgetChassisTemplateForCat = (chassisCat: string, buCatNo: string): MICROChassisTemplate | null => {
	const [xt, conformal, Eio, plugin] = getMICROChassisBasics(chassisCat,buCatNo );
	return _getMICROChassisTemplate(xt, conformal, Eio, plugin);
}


const microGetImageSource = (category: DeviceCategory, imgName: string): string => {
	let imgSrc = '';
	switch (category) {
		case DeviceCategory.Chassis:
		case DeviceCategory.BU:
			imgSrc = 'https://configurator.rockwellautomation.com/api/doc/'+imgName;
			break

		case DeviceCategory.Module:
			imgSrc = getDefaultImageSource(imgName);
			break;
			case DeviceCategory.Other:
				imgSrc = getDefaultImageSource(imgName);
				break;
		default:
			imgSrc = getDefaultImageSource(imgName);
			break;
	}

	if (imgSrc.charAt(imgSrc.length - 4) !== '.') {
		imgSrc += '.png';
	}

	return imgSrc;
}

const _makeEmptyMICROLayout = (xt: boolean, conformal: boolean): MICROLayoutInfo => {
	return {
		platform: PlatformMicro,
		numFPDs: 0,
		extendedTemp: xt,
		pluginSlot:0,
		ioExpansionSlot:0,
		conformal: conformal,
		numSlots: 0,
		emptySlotImgSrc: '',
		slotLocs: new Array<LocAndSize>(),
		slotSepImgSrc: '',
		slotSepLocs: new Array<LocAndSize>(),
		grpSepImgSrc: '',
		grpSepLocs: new Array<LocAndSize>(),
		rightCapImgSrc: '',
		rightCapLoc: { x: 0, y: 0, width: 0, height: 0 },
		size: { width: 0, height: 0 }
	};
}


export const _microMakeChassisBU = (pbsCat: string, ps?: ChassisPowerSupply):
	ChassisBaseUnit | undefined=> {
	const psEngInfo = getMicroModules(PlatformMicro, pbsCat);
	let powerSupply = 0;
	if(ps){
		powerSupply = ps.imgSize.width
	}
	if (psEngInfo) {
	let imageWidth = 0;
	let widthCalc = 15;
	if(parseInt(psEngInfo.plugin) === 5){
		imageWidth =  565;
		widthCalc = 20;
	}
	if(parseInt(psEngInfo.plugin) === 3){
		imageWidth =  390;
	}
	if(parseInt(psEngInfo.plugin) === 2){
		imageWidth = 255;

	}
		const scaledSize: Size = {
			width: (imageWidth * _microImageSizeToMM )+ (widthCalc * parseInt(psEngInfo.plugin)),
			height: (150 * _microImageSizeToMM +68) 
		};
		if (_getSizesInStageUnits) {
			scaledSize.width *= StageUnitsPerMM;
			scaledSize.height *= StageUnitsPerMM;
		}

		const initialWidth = getinitialWidthPlugin(parseInt(psEngInfo.plugin))
		const bu: ChassisBaseUnit| undefined = {
			id: getNewInstanceId(),
			platform: PlatformMicro,
			initialWidth:initialWidth,
			deviceType: DeviceType.BU,
			catNo: pbsCat,
			description: psEngInfo.description,
			isPlaceholder: psEngInfo.isPlaceholder,
			extendedTemp: psEngInfo.envInfo.etOk,
			conformal: psEngInfo.envInfo.ccOk,
			accysPossible: psEngInfo.anyAccysPossible(),
			category: DeviceCategory.BU,
			imgSrc: microGetImageSource(DeviceCategory.BU, psEngInfo.imgName),
			imgSize: scaledSize,
			selected: false,
			movable: false,
			dragStatus: ModuleDragStatus.NA,
			loc: {
				x: powerSupply + 0,
				y: 0,
				width: scaledSize.width,
				height: scaledSize.height
			},
		
		
			parent: undefined
		};
		addRequiredAccys(bu);

		return bu;

	}
}


const getImageInfo = (info: ImageInfo): [imgSrc: string, width: number, height: number] => {
	return [info.imgSrc, info.width, info.height];
}

export const getinitialWidthPlugin = (pluginLimit: number)=>{
	let lastX = 0
	if(pluginLimit === 5){
		lastX =  372;
	}
	if(pluginLimit === 2){
		lastX = 208;
	}
	if(pluginLimit === 3){
		lastX = 222;
	}
	return lastX;
}

export const _makeMICROLayoutInfo = (tmpl: MICROChassisTemplate,buCatNo: string, ps?: ChassisPowerSupply):
	[layout: MICROLayoutInfo,bu: ChassisBaseUnit | undefined, lastX: number] => {
	const layout = _makeEmptyMICROLayout(tmpl.extendedTemp, tmpl.conformal);

	const [, slotWidth] = getImageInfo(tmpl.emptySlot);
	layout.pluginSlot = tmpl.slotGroups[0];
	layout.ioExpansionSlot = tmpl.slotGroups[1];
	layout.emptySlotImgSrc = 'https://configurator.rockwellautomation.com/api/doc/2085_Empty.png';
	layout.slotSepImgSrc = '';
	layout.grpSepImgSrc = '';
	layout.rightCapImgSrc = 'https://configurator.rockwellautomation.com/api/doc/2085_ERC_DI.png';

	let lastX = 0;

	layout.size = { width: 0, height: 0 };
	const bu: ChassisBaseUnit | undefined =  _microMakeChassisBU(buCatNo, ps)
  let xps = 0;

	for (let grpIdx = 0; grpIdx < tmpl.slotGroups.length; grpIdx++) {
    if(ps){
			xps =  ps.imgSize.width;
		}
		// lastX calculation of i/o expansion included addition of Controller image width 
		if (bu && grpIdx === 1) {
			lastX =  xps + bu.imgSize.width;
		}
		// lastX calculation of Plugin included addition of starting width is difffrence based on each controller
		if (bu && grpIdx === 0) {	
			lastX = getinitialWidthPlugin(tmpl.slotGroups[0]) + xps 
	}
		const slotsInGrp = tmpl.slotGroups[grpIdx];
		layout.numSlots += slotsInGrp;
        //grpIdx is 0 means it is plugin
		if(grpIdx === 0){

			for (let slotIdx = 0; slotIdx < slotsInGrp; slotIdx++) {
			
				layout.slotLocs.push({ x: lastX, y: 80, width: slotWidth, height: 390 });
				lastX += slotWidth;
			}
		}
	}

    
	return [layout, bu, lastX];
}

export const microCreatePowerSupply = (psCat?: string, inputVoltage?: PSInputVoltage): ChassisPowerSupply | undefined => {
    if (!psCat)
        return undefined;

    const psEngInfo = getPowerSupplyEngInfo(PlatformMicro, psCat);
    if (psEngInfo) {
        const psInputVltg = psEngInfo.getInputVoltage(inputVoltage);

        const scaledSize: Size = {
            width: psEngInfo.imgSize.width * _microImageSizeToMM,
            height: psEngInfo.imgSize.height * _microImageSizeToMM
        };
        if (_getSizesInStageUnits) {
            scaledSize.width *= StageUnitsPerMM;
            scaledSize.height *= StageUnitsPerMM;
        }

        const ps: ChassisPowerSupply = {
            id: getNewInstanceId(),
            platform: PlatformMicro,
            deviceType: DeviceType.PS,
            catNo: psCat,
            description: psEngInfo.description,
            isPlaceholder: psEngInfo.isPlaceholder,
            extendedTemp: psEngInfo.envInfo.etOk,
            conformal: psEngInfo.envInfo.ccOk,
            accysPossible: psEngInfo.anyAccysPossible(),
            category: DeviceCategory.PS,
            imgSrc:  'https://configurator.rockwellautomation.com/api/doc/2080-PS120-240VAC.png',
            imgSize: scaledSize,
            selected: false,
            movable: false,
            dragStatus: ModuleDragStatus.NA,
            redundant: psEngInfo.redundant,
            loc: {
                x: 0,
                y: 0,
                width: scaledSize.width,
                height: scaledSize.height
            },
            inputVoltage: psInputVltg,
            parent: undefined
        };

        addRequiredAccys(ps);

        return ps;
    }
    return undefined;
}

export const microAttachModuleAtSlot = (chassis: Chassis, module: ChassisModule, slot: number, skipUpdate: boolean): boolean => {
    // It is STRONGLY recommended that a call to
    // microCompactModuleArray() is made after
    // attaching modules.

    // If we do not have enough slots, add empty slots...
    if (slot >= chassis.modules.length) {
        // We will add undefined until we get to the slot
        // where the module should go.
        while (slot >= chassis.modules.length)
            chassis.modules.push(undefined);
    }
    else {
        // We have a slot present. If it's not empty...
        const mod = chassis.modules[slot];
        if (mod)
            return false;
    }

    // Set the slot and parent.
    chassis.modules[slot] = module;
    module.parent = chassis;

    // Set the slot lables for micro 800.
    assignModuleSlotLocationsMicro(chassis);

    if (!skipUpdate) {
        updateChassis(chassis);
    }

    return true;
}

export const microGetModuleLayoutAndPrepForReplace = (chassis: Chassis)
	: SlotModulePair[] => {

	// Construct an array to hold data pairs, each of which
	// will contain a slot number and a module catalog number.
	const dataPairs = new Array<SlotModulePair>();

	// Determine which slots contain modules in the chassis (if any).
	const [modSlots,] = getModuleSlotBreakdown(chassis.modules);

	// See how many we have in use.
	const slotsInUse = modSlots.length;
	// If any...
	if (slotsInUse > 0) {
		// Walk each entry in the modSlots array. For each...
		for (let i = 0; i < slotsInUse; i++) {
			// Get the slot number.
			const slot = modSlots[i];

			// Then get the module located in the
			// slot of our chassis. We SHOULD ALWAYS
			// get a module here.
			const module = chassis.modules[slot];

			// If we do...
			if (module) {
				// Add a data pair using the current slot and module itself.
				dataPairs.push({ slot: slot, module: module });

				// Then 'detach' the module
				// from its parent chassis.
				detachModule(module);
			}
			else {
				// Unexpected.
				throw new Error('Unexpected ERROR 1 in microxGetModuleLayout!');
			}
		}

		// Now see how many data pairs we collected.
		const numPairs = dataPairs.length;

		// That number SHOULD match the number of
		// slots that were reported to contain modules.
		// If so...
		if (numPairs !== slotsInUse) {
			// Unexpected.
			throw new Error('Unexpected ERROR 2 in microxGetModuleLayout!');
		}
	}

	// Return our final array of pairs.
	return dataPairs;
}

export const _getLocForMod = (
    module: ChassisModule | undefined,
	leftX: number
): LocAndSize => {
    let imgSize = getEmptySize();

    if (module) {
    
		const modEngInfo = getEngineeringInfoFor(module);
        if (modEngInfo?.isModule ) {
            imgSize = modEngInfo.dimensions;
        }
		else if (modEngInfo?.isPS ) {
			
			imgSize = modEngInfo.imgSize ;
		}
        
    }
   
    const locSize = {
		width: module?.deviceType === DeviceType.IOExpansion ? imgSize.width * 5.23: imgSize.width ,
		height: 550
	}
    return {
        x: leftX,
        y: 0,
        width: locSize.width,
        height: locSize.height
    }
}



const microUpdateChassis = (chassis: MicroChassis) => {
	// Once this isIoSeperate flag is true update chassis happens during deletion of controller no need to go this function

	if(chassis && chassis?.isIoSeperate ){
		const pluginLimit = chassis.pluginModules?.length || 0
        const ps = chassis.modules.some(mod=> mod?.deviceType === DeviceType.PS)
		!ps && microaddioExpansionPowersupplies(chassis,pluginLimit)
		microreArrangeChassisModuleWithPS(chassis,pluginLimit);
		assignModuleSlotLocationsMicro(chassis);
	
		const [slotWidth , slotHeight] = getDefaultIoexpansionDimension(chassis);
		const buCatNo = chassis.bu ? chassis?.bu?.catNo : chassis?.buCatnumber;
		const template = buCatNo && microgetChassisTemplateForCat(chassis.catNo, buCatNo);
		let leftX= 0;
		if(template ){
			const [layout,bu, lastX] = _makeMICROLayoutInfo(template, buCatNo, chassis?.ps);
			const moduleSize = chassis.modules.length;
			const ioExpansionMod = chassis.modules.some(mod=> mod?.deviceType === DeviceType.IOExpansion)
			chassis.layout = layout;
			leftX = lastX;
	
			if(moduleSize >0 && ioExpansionMod){
				// leftX += chassis.layout.slotLocs[pluginLimit].width
				for (let idx =layout.pluginSlot; idx < moduleSize; idx++) {
				
					const nextLoc = _getLocForMod(chassis.modules[idx], leftX);
					chassis.layout.slotLocs[idx]= nextLoc;
					leftX += nextLoc.width;
					
				}
				const rightCapsscaleDownfactor= 0.5;
				layout.rightCapLoc = { x: leftX , y: 0, width: slotWidth *scaleupfactor *rightCapsscaleDownfactor , height: slotHeight };
				leftX += slotWidth *scaleupfactor *rightCapsscaleDownfactor;
			}
	
            chassis.layout.size.width = leftX;
			layout.size.height = defaultheight;
			chassisChanged(chassis);
			chassis.bu = bu;
			chassis.isBU = true;
	
		
			chassis.totalModules = template.slotGroups[0] +template.slotGroups[1] +  (template.slotGroups[1]>4 ? 1:0)
			if(bu){
				bu.parent= chassis;
			}
		}
	}
	else{
			const ps = chassis.modules.some(mod=> mod?.deviceType === DeviceType.PS)
			!ps && microaddioExpansionPowersupplies(chassis,0)
			microreArrangeChassisModuleWithPS(chassis,0);
			assignModuleSlotLocationsMicro(chassis);
			const moduleSize = chassis.modules.length;
			const ioExpansionMod = chassis.modules.some(mod=> mod?.deviceType === DeviceType.IOExpansion)
			const pluginLimit = chassis.pluginModules?.length || 0
			let leftX = defaultWidthBuDeletion;
			if(moduleSize >0 && ioExpansionMod){
				// leftX += chassis.layout.slotLocs[pluginLimit].width
				for (let idx =pluginLimit; idx < moduleSize; idx++) {
				
					const nextLoc = _getLocForMod(chassis.modules[idx], leftX);
					chassis.layout.slotLocs[idx]= nextLoc;
					leftX += nextLoc.width;
					
					
				}

			}
			chassis.layout.size.width = leftX
			chassis.layout.size.height = defaultheight;
			chassisChanged(chassis);
			chassis.totalModules = maxToMicroModules;
		
		}

		chassis.layout.slotLocs.splice(chassis.modules.length,chassis.layout.slotLocs.length )
	}

	




const microCreateChassis = (chasEngInfo: EngInfoChassis, psCatNo?: string, buCatNo?: string): MicroChassis | undefined => {
	const template = buCatNo && microgetChassisTemplateForCat(chasEngInfo.catNo, buCatNo);
	const ps =   microCreatePowerSupply(psCatNo);
	if(template){
		const layout = _makeEmptyMICROLayout(false, false);

		const chassis: MicroChassis = {
			id: getNewInstanceId(),
			bump: 0,
			bu: undefined,
			dragTarget: false,
			buCatnumber:buCatNo,
			xSlotWidth: 0,
			isIoSeperate: true,
			platform: PlatformMicro,
			deviceType: DeviceType.Chassis,
			catNo: chasEngInfo.catNo,
			description: chasEngInfo.description,
			isPlaceholder: chasEngInfo.isPlaceholder,
			isBU: true,
			imgSrc: '',
			extendedTemp: false,
			conformal: template.conformal,
			accysPossible: chasEngInfo.anyAccysPossible(),
			ps: ps,
			layout: layout,
			modules: new Array<ChassisModule>(template.slotGroups[0]),
			pluginModules: new Array<ChassisModule>(template.slotGroups[0]),
			ioExpansionModule: new Array<ChassisModule>(template.slotGroups[1]),
			totalModules: template.slotGroups[0]+ template.slotGroups[1],
			selected: false,
			parent: undefined,
			redundant: false,
			defaultPowerSupplyValue: PSInputVoltage.DC24V, 
			defaultIOModWiring: IOModuleWiring.Screw,
			statusLog: undefined,
		};
  
		updateChassis(chassis)
		// Success.
		return chassis;
	}
	else{
		
			const layout = _makeEmptyMICROLayout(false, false);
	
			const chassis: MicroChassis = {
				id: getNewInstanceId(),
				bump: 0,
				bu: undefined,
				dragTarget: false,
				buCatnumber:buCatNo,
				xSlotWidth: 0,
				isIoSeperate: false,
				platform: PlatformMicro,
				deviceType: DeviceType.Chassis,
				catNo: chasEngInfo.catNo,
				description: chasEngInfo.description,
				isPlaceholder: chasEngInfo.isPlaceholder,
				isBU: true,
				imgSrc: '',
				extendedTemp: false,
				conformal: false,
				accysPossible: chasEngInfo.anyAccysPossible(),
				ps: ps,
				layout: layout,
				modules: new Array<ChassisModule>(maxToMicroModules),
				pluginModules: new Array<ChassisModule>(0),
				ioExpansionModule: new Array<ChassisModule>(maxToMicroModules),
				totalModules: maxToMicroModules - 1,
				selected: false,
				parent: undefined,
				redundant: false,
				defaultPowerSupplyValue: PSInputVoltage.DC24V, 
				defaultIOModWiring: IOModuleWiring.Screw,
				statusLog: undefined,
			};
	
			updateChassis(chassis)
			// Success.
			return chassis;
		
	}

	

}

//interface SpecialLocalConnMod extends OLD_ModuleProdData {
//	conn_local?: string;
//}

// SPECIAL HANDLING ONLY. Should only be called for a module
// already determined to NOT be any of: controller, comm, or conn client.
// Used to detect special-case modules that aren't recognized as
// communication-related, but DO add LOCAL connection requirements
// to (some processor found in) the chassis they're located in.
const _getSpecialLocalConnRqmt = (modInfo: EngInfoModule): number => {

	// We use this to pick up connection requirements for our
	// 'Communication Module' modules that are NOT qualified as comms,
	// which would include DeviceNet and RIO adapters.
	// IFF the incoming type MIGHT be one of those...
	if (modInfo.isCommModule) {
		const asCommMod = modInfo as EngInfoCommModule;
		return asCommMod.localConns;
	}

	// If we're still here, just return 0.
	// The incoming modData does NOT indicate
	// any special local connection requirement.
	return 0;
}

const microCreateModule = (catNo: string): ChassisModule | null => {

	//const modData = getMICROProdData(catNo) as OLD_ModuleProdData;
	const modInfo = getModuleEngInfoioExpansionPs(PlatformMicro, catNo);
	if (modInfo) {
		const modDimension = modInfo.dimensions 
		const devType = modInfo.type;
		const commDtlsRoles = {
			anyRoles: false,
			isController: false,
			isComm: false,
			isConnClient: false
		}
		const specialLocalConns = commDtlsRoles.anyRoles
			? 0
			: _getSpecialLocalConnRqmt(modInfo);
		return {
			id: getNewInstanceId(),
			platform: PlatformMicro,
			deviceType: devType,
			catNo: catNo,
			description: modInfo.description,
			isPlaceholder: modInfo.isPlaceholder,
			extendedTemp: modInfo.envInfo.etOk,
			conformal: modInfo.envInfo.ccOk,
			accysPossible: modInfo.anyAccysPossible(),
			category: getDeviceCategory(devType),
			imgSrc: microGetImageSource(DeviceCategory.Module, modInfo.imgName),
			imgSize: devType===DeviceType.PlugIn ? { width: 200, height: 385 } : {width: modDimension.width * 5.23, height: 550 }, // scales as per same defined for layout image // later we will make it standard scale value
			// imgSize: { width: modDimension.width *3.8*1.15, height: modDimension.height*3.8 } ,
			parent: undefined,
			movable: true,
			slotIdx: -1,
			slotID: -1,
			slotsUsed: 1,
			selected: false,
			dragStatus: ModuleDragStatus.NA,
			isController: commDtlsRoles.isController,
			isComm: commDtlsRoles.isComm,
			isConnClient: commDtlsRoles.isConnClient,
			spclLocalConns: specialLocalConns,
			slotFiller:false,
			isFPD: false,
			isInterconnect: false,
			isBankExp: false
			//okInAnyChassisType: modData.okInAnyChassisType
		};
	}


	logger.error('createModule could not create ChassisModule from: ' + catNo);
	return null;
}

export const microgetpowersupply= (chassisVoltage:string,moduleCatnumber: string ):string=>{
	let psCat = '';
	const microPower = getPowerSuppliesMicro(PlatformMicro)
	if(chassisVoltage !== PSInputVoltage.DC24V){
		const getPowerCatnumberMicro = microgetPowerCatnumber(microPower, moduleCatnumber)
		psCat = getPowerCatnumberMicro[0].catNo;
	

	}
		return psCat;
}

export const updateModuleAtSlot = (
	chassis: MicroChassis,
	catNo: string,
	slotNum: number,
): boolean => {

	// Create the requested module.
	const module = createModule(chassis.platform, catNo);

	//  plugin and io expansion modules added 
	if (module && (module.deviceType !== DeviceType.Controller && chassis.bu)) {
     
		// BEFORE making any change, call our helper
		// to handle any related undo/redo work for us.
		chassisChanging(chassis)
		// THEN, make the actual changes.
		chassis.modules[slotNum] = module;
		// chassis.layout.slotLocs[slotNum] = {...chassis.layout.slotLocs[slotNum],width:module.imgSize.width}
		module.parent = chassis;
		module.slotIdx = slotNum;
		module.slotID = slotNum;
		addRequiredAccys(module);
	
		updateChassis(chassis)
		return true;
	}
	// controller modules modules added 
	else if(module && (module.deviceType == DeviceType.Controller)){
		const template = microgetChassisTemplateForCat(chassis.catNo, module.catNo);
		const psCat = chassis.defaultPowerSupplyValue && microgetpowersupply(chassis.defaultPowerSupplyValue,module.catNo )
		if(psCat){
			chassis.ps = microCreatePowerSupply(psCat);
		}
		// Maximum IO expoansion found is 8 for microcontroller family
		const  maxIoExpansion = 8;
		const buCat = module.catNo;
		if(template && buCat ){
			let  prevIoexpansion = template?.slotGroups[1] ? template?.slotGroups[1] : 0;
			const isPsIoExpansion = template?.slotGroups[1] > ioExpasnionPowerLimit? 1:0;
			template.slotGroups[1] = maxIoExpansion + isPsIoExpansion; 
			prevIoexpansion = prevIoexpansion + isPsIoExpansion
			// chassis.modules= new Array<ChassisModule>(template.slotGroups[0]),
			// chassis.pluginModules= new Array<ChassisModule>(template.slotGroups[0])
		
			const [layout,bu] = _makeMICROLayoutInfo(template, buCat, chassis.ps);
			// if not provided.
			chassis.layout = layout;
			chassis.bu = bu;
			const ioModulesSaveFirst= chassis?.modules?.filter(res=> res?.deviceType === DeviceType.IOExpansion) ?
			chassis?.modules?.filter((res,index)=> res?.deviceType === DeviceType.IOExpansion && index<=ioExpasnionPowerLimit) : [] ;
			const ioModulesSaveLast= chassis?.modules?.filter(res=> res?.deviceType === DeviceType.IOExpansion) ?
			chassis?.modules?.filter((res,index)=> res?.deviceType === DeviceType.IOExpansion && index>ioExpasnionPowerLimit) : [] ;
			const ioModulesPower= chassis?.modules?.filter(res=> res?.deviceType === DeviceType.PS) ?
			chassis?.modules?.filter((res)=> res?.deviceType === DeviceType.PS ) : [] ;
			const ioTotal = ioModulesSaveFirst.length + ioModulesPower.length + ioModulesSaveLast.length;
			chassis.modules= new Array<ChassisModule>(template.slotGroups[0])
			chassis.pluginModules =  new Array<ChassisModule>(template?.slotGroups[0])
			chassis.modules=  [...chassis.modules,...ioModulesSaveFirst, ...ioModulesPower,... ioModulesSaveLast]
			const pluginLength =  chassis?.pluginModules?.length ||  0;
			const widthX = chassis?.bu?.imgSize?.width ? chassis.bu?.imgSize?.width : 0;
			const [slotWidth , slotHeight] = getDefaultIoexpansionDimension(chassis)
			const ioExpansionWidth = slotWidth * scaleupfactor;
			const ioExpansionHeight = slotHeight;
			const ioExpansionWidthPower = slotWidth * scaleDownfactor;
			let lastX = 0;
			chassis?.layout?.slotLocs?.splice(pluginLength ,pluginLength + maxIoExpansion )
			const moduleLength = (prevIoexpansion >= ioTotal) ? prevIoexpansion : ioTotal ;
		
			let xps = 0
			if(chassis.ps){
				xps +=  chassis.ps.imgSize.width;
			}
			lastX = xps + widthX;
			let ioexpansionPSadded = false;
			for (let slotIdx = 0; slotIdx < pluginLength; slotIdx++){
				if(slotIdx<pluginLength){
					chassis.modules[slotIdx]= undefined;
				}
			}
			for (let slotIdx = pluginLength; slotIdx < (pluginLength + moduleLength); slotIdx++) {
					
				if(slotIdx>ioExpasnionPowerLimit && !ioexpansionPSadded){
					ioexpansionPSadded = true;
					chassis.layout.slotLocs.push({ x: lastX, y: 0, width: ioExpansionWidthPower, height: ioExpansionHeight })
					lastX += ioExpansionWidthPower;
					continue;	
				}
				chassis?.layout?.slotLocs.push({ x: lastX, y: 0, width: ioExpansionWidth, height: ioExpansionHeight });
				lastX +=  ioExpansionWidth;
			}

		

			
			if(bu){
				bu.parent = chassis;
			}
	
		
		}
		chassis.isIoSeperate = true;
		chassisChanging(chassis)
		addRequiredAccys(module);
		updateChassis(chassis)
		return true;
		}
		// once controller deleted, io expansion seperation needed and make maximum slots 
		else if (module && (module.deviceType !== DeviceType.Controller && !chassis.bu)) {
			// BEFORE making any change, call our helper
		// to handle any related undo/redo work for us.
		chassisChanging(chassis)
		// THEN, make the actual changes.
		chassis.modules[slotNum] = module;
		module.parent = chassis;
		module.slotIdx = slotNum;
		module.slotID = slotNum;

		addRequiredAccys(module);
		updateChassis(chassis)
		return true;
	}
		
		
		return false;
	}

export const microAddModuleAtSlot = (
	chassis: MicroChassis,
	catNo: string,
	slotNum: number,
	envMismatchOk:boolean,
	specialContext: boolean
): boolean => {

	//this will remove later
	envMismatchOk
	specialContext
	const isAdded = updateModuleAtSlot(chassis, catNo, slotNum)
	return isAdded

}
	

const dropMICROChassisModule = (
	chassis: Chassis,
	mod: ChassisModule,
	element: ChassisElement,
	slotNum: number
): boolean => {

	// If the element provided is a slot..
	if (element === ChassisElement.Slot) {

		// And we were given a valid slot number...
		if (isValidSlotNumber(chassis, slotNum)) {

			// See if that slot already has a module in it.
			const occupant = chassis.modules[slotNum];

			// If so, we can't add the requested module there.
			if (occupant) {
				return false;
			}
			else {
				// We WILL be adding the module, but
				// BEFORE making any change, call our helper
				// to handle any related undo/redo work for us.
				chassisChanging(chassis);

				mod.dragStatus = ModuleDragStatus.NA;
				mod.slotIdx = slotNum;
				mod.slotID = slotNum;
				mod.parent = chassis;
				chassis.modules[slotNum] = mod;
				return true;
			}
		}
		else {
			throw new Error('dropMICROChassisModule - invalid chassis or slot number!');
		}
	}
	return false;
}

const _isModuleValidInSlot = (
	chassis: Chassis,
	module: ChassisModule,
	slotNum: number
): boolean => {
	if (isValidSlotNumber(chassis, slotNum)) {
		const occupant = chassis.modules[slotNum];
		if (!occupant) {
			return true;
		}
	}

	return false;
}

const microGetMaxNewModules = (chassis: MicroChassis, restriction: ModuleSlotRestriction, treatSlotFillerAsEmptySlot: boolean): number => {

	// MICRO doesn't distinguish any modules as
	// being restricted to certain slots only. 
	let maxNew = 0;
	const maxMicroSlot = 9;
	const pluginSlot = chassis?.pluginModules?.length ? chassis?.pluginModules?.length : 0;

	//Iteration for plugin and i/o expansion restriction
    if(restriction === ModuleSlotRestriction.SlotWithPlugin){
      for (let slot = 0; slot < pluginSlot ; slot++) {
        const mod = chassis.modules[slot];
        if (mod == null) {
          maxNew += 1;
        }
        else if (treatSlotFillerAsEmptySlot && mod.slotFiller) {
          maxNew += 1;
        }
      }

	}
	if(restriction === ModuleSlotRestriction.SlotWithIOexpansion){
		let layoutLength = chassis.totalModules || 0;
		if(!chassis.bu){
			layoutLength =  maxMicroSlot  + pluginSlot
		}
		for (let slot = pluginSlot; slot < layoutLength ; slot++) {
			const mod = chassis.modules[slot];
			if (mod == null && slot !== micropowerExpansionModule(pluginSlot)) {
				
				maxNew += 1;
			}
		}
	}

	if(restriction === ModuleSlotRestriction.SlotWithBU){
		maxNew = 1;
		}
		
	return maxNew;
}


const microAddModuleToChassis = (
	chassis: Chassis,
	module: ChassisModule
): boolean => {

	//if (_doDeviceAndChassisEnvsMatch(chassis, module.xt, module.conformal)) {
	if (isDeviceCompatibleWithChassis(module, chassis)) {
		const [, emptySlots] = getModuleSlotBreakdown(chassis.modules);
		for (let idx = 0; idx < emptySlots.length; idx++) {
			const slotNum = emptySlots[idx];
			if (_isModuleValidInSlot(chassis, module, slotNum)) {
				if (dropMICROChassisModule(chassis, module, ChassisElement.Slot, slotNum)) {
					return true;
				}
				else {
					unexpectedError('dropMICROChassisModule FAILED after slot validatation?');
				}
			}
		}
	}

	return false;
}

const microAddNewModuleAtSlot = (chassis: Chassis, slot: number): boolean => {
	// Warning suppression
	chassis;
	slot;

	// Function is not currently used, and will be ultimately
	// replaced with a modal-based approach of some sort.
	// Just Fail for now.
	return false;
}
export const iterationSlotMicro=(chassis: Chassis, pluginLength: number):[totalUsedPluginSlots: number, totalUsedExpansionSlots: number]=>{
	let totalUsedPluginSlots = 0;
    let totalUsedExpansionSlots = 0;
		chassis.modules.forEach((mod, index) => {
		if (mod && index < pluginLength) {
			totalUsedPluginSlots++;
		} else if (mod && index >= pluginLength) {
			totalUsedExpansionSlots++;
		}
    });
	return [totalUsedPluginSlots, totalUsedExpansionSlots]
}


//only Arranging io expansion  power supply in 5th slot if io modules greater than 4 otherwise remove the io expansion power supply if less than 4 modules during deletion logic of micro 800
export const microreArrangeChassisModuleWithPS = (chassis:MicroChassis, pluginLength:number)=>{
	const expansionIOmodules = chassis.modules.filter(mod=>mod?.deviceType === DeviceType.IOExpansion);

	const ps = chassis.modules.filter(mod=>mod?.deviceType===DeviceType.PS);

	if(expansionIOmodules.length>ioExpasnionPowerLimit ){
		chassis.modules = [...chassis.modules.slice(0,pluginLength),...expansionIOmodules.slice(0,4),...ps,...expansionIOmodules.slice(4,expansionIOmodules.length)]
	}
	else{
		const psIndex = chassis.modules.findIndex(mod=>mod?.deviceType=== DeviceType.PS) || -1
		psIndex >= 0 && chassis.modules.splice(psIndex,1);
	}

}

//Adding io expansion power supply and Arranging io expansion  power supply in 5th slot as well  if io modules greater than 4 otherwise remove the io expansion power supply if less than 4 modules during deletion logic of micro 800

export const microaddioExpansionPowersupplies = (chassis:MicroChassis, pluginLength:number)=>{

	const microPower = getPowerSuppliesMicro(PlatformMicro);
    const ioExpansionCat = microPower.filter(res=> res.subType1 === '')[0]
	const expansionIOmodules = chassis.modules.filter(mod=> mod?.deviceType === DeviceType.IOExpansion);
		const module: ChassisModule | null  = createModule(chassis.platform, ioExpansionCat.catNo);
		if(module && ioExpansionCat && expansionIOmodules.length > 4){
		// THEN, make the actual changes.
		chassis.modules[pluginLength + ioExpasnionPowerLimit] = module;
		// chassis.layout.slotLocs[slotNum] = {...chassis.layout.slotLocs[slotNum],width:module.imgSize.width}
		module.parent = chassis;
		module.slotIdx = pluginLength + ioExpasnionPowerLimit;
		module.slotID = pluginLength + ioExpasnionPowerLimit;
		
		
		if(expansionIOmodules.length>ioExpasnionPowerLimit ){
			chassis.modules = [...chassis.modules.slice(0,pluginLength),...expansionIOmodules.slice(0,4),module,...expansionIOmodules.slice(4,expansionIOmodules.length)]
		}
		else{
			const psIndex = chassis.modules.findIndex(mod=>mod?.deviceType=== DeviceType.PS) || -1
			psIndex >= 0 && chassis.modules.splice(psIndex,1);
		
		}
	
	}

}


const microDeleteModuleAtSlot = (chassis: MicroChassis, slot: number): boolean => {
    const pluginLength = chassis.pluginModules?.length ? chassis.pluginModules?.length : 0
	// If slot number is in valid range...
	if ((slot >= 0) && (slot < chassis.modules.length)) {

		// Get the module at the specified slot.
		const module = chassis.modules[slot];

		// If there is one...
		if (module) {

			// BEFORE making any change, call our helper
			// to handle any related undo/redo work for us.
			chassisChanging(chassis);

			// Make sure the module doesn't have
			// a ref back to our chassis.
			module.parent = undefined;

			// Set the associated modules entry to null.
			chassis.modules[slot] = undefined;
			if(slot >= pluginLength){
				chassis.modules.splice(slot,1);

			}
			updateChassis(chassis)
			// getModuleSlotID(chassis.modules)
			// Success.
			return true;
			
		}
		else {
			// Unexpected
			unexpectedError('Request to delete module at slot that has no module in it!');
		}
	}
	else {
		// Unexpected
		unexpectedError('Request to delete module at INVALID slot number!');
	}

	// Fail. We didn't delete anything.
	return false;
}

const microGetActionBtnInfo = (action: LayoutActionType,
	rack: Rack, slotNum: number): ActBtnInfo => {
	//Logic for micro 800 layout once deleted controller/base unit	
    const getMicroChassis = rack.chassis as MicroChassis
	if(slotNum === -1){
		const loc: LocAndSize = {
			x: 0,
			y: 0,
			width: defaultWidthBuDeletion,
			height: defaultheight
		};
		const slotLoc = { ...loc };
		offsetLoc(slotLoc, rack.ptOrg);
		const pt = getLocCenter(slotLoc);
		pt.y += DfltActBtnSpecs.height;
		return {
			action: action,
			chassis: rack.chassis,
			slot: slotNum,
			ctrPt: pt
		};
		
	}
	else{
		const slots = rack.chassis.modules.length;
		const isIomodules = rack.chassis.modules.some(mod=> mod?.deviceType === DeviceType.IOExpansion)
		const pluginLimit = getMicroChassis.pluginModules?.length || 0;
		if (slotNum > slots) {
			throw new Error('Invalid slotNUm in snapGetActionBtnInfo!');
		}
	
		// If the slot requested is 1 to the right
		// of our chassis' last ACTUAL slot...
		if (slotNum === slots) {
	
			// See if the chassis can be extended
			// with another module. If so...
			if (microCanExtendChassis(rack.chassis)) {
	
				// Get platform details...
				let lastSlotLoc = { ...rack.chassis.layout.slotLocs[slots - 1] };
				// Get the location of the last actual slot.
				lastSlotLoc =  isIomodules || getMicroChassis.bu ? { ...rack.chassis.layout.slotLocs[slots - 1] }: {x: 0,y:0, width: 350, height:551}
				if(pluginLimit === slots && getMicroChassis.bu){
					const xpS = rack.chassis.ps && getMicroChassis.bu ? rack.chassis.ps.imgSize.width : 0;
					lastSlotLoc.x = xpS + getMicroChassis.bu?.imgSize.width || 0 ;
					lastSlotLoc.x -= 230
				}
		
		
				offsetLoc(lastSlotLoc, rack.ptOrg);
	
				// Start our 'x' (extra) slot as a copy of that.
				const xSlotLoc = { ...lastSlotLoc };
	
				// Place it's left side at the right
				// side of the last real slot.
				xSlotLoc.x += lastSlotLoc.width;
	
				// Set its width to be the platform's default.
				xSlotLoc.width =230;
	
				// Position the act btn pt inside.
				const pt = getLocCenter(xSlotLoc);
				pt.y += DfltActBtnSpecs.height;
	
				// And return our btn info.
				return {
					action: action,
					chassis: rack.chassis,
					slot: slotNum,
					ctrPt: pt
				};
			}
			else {
				throw new Error('Invalid extension attempt in snapGetActionBtnInfo!');
			}
		}
		const slotLoc = { ...rack.chassis.layout.slotLocs[slotNum] };
		offsetLoc(slotLoc, rack.ptOrg);
		const pt = getLocCenter(slotLoc);
		pt.y += DfltActBtnSpecs.height;
		return {
			action: action,
			chassis: rack.chassis,
			slot: slotNum,
			ctrPt: pt
		};
	}
	}
	



const microGetChassisRenderer = (): React.FC<MicroChassisCompProps> => {
	return MicroChassisComp;
}


export const microGetSlotTypeRestrictionMicro = (
  chassis: MicroChassis,
  slotNum: number)=> {
    const pluginLength = chassis?.pluginModules?.length
    ? chassis?.pluginModules?.length
    : 0;
    if (slotNum < pluginLength && slotNum >= 0 ) {
      return ModuleSlotRestriction.SlotWithPlugin;
    } 
	else if(slotNum === -1){
		return ModuleSlotRestriction.SlotWithBU;
	} 
	else {
      return ModuleSlotRestriction.SlotWithIOexpansion;
    }
}


export const microFilterAvailableModulesMicro = (
    chassis: Chassis,
    restrict: ModuleSlotRestriction,
    unfiltered: EngInfoModule[]): EngInfoModule[] => {
	const numberIoExpasnion = chassis.modules.filter(mod=> mod?.deviceType === DeviceType.IOExpansion);
    if (restrict === ModuleSlotRestriction.SlotWithPlugin) {
        return unfiltered.filter(mod => mod.isPlugin);
    }
	else if(restrict === ModuleSlotRestriction.SlotWithBU){
		return unfiltered.filter(mod => mod.isBU && getMicroModulesExpansionDetails(PlatformMicro,mod.catNo) >= numberIoExpasnion.length)
	}
    return unfiltered.filter(mod => mod.isIO && !mod.isBU);
}

export const micrGetModuleSlotRestriction = (modInfo: EngInfoComponent): ModuleSlotRestriction => {
	if(modInfo.isPlugin){
		return ModuleSlotRestriction.SlotWithPlugin;
	}
  return ModuleSlotRestriction.SlotWithIOexpansion;
}

export const microDefaultChassisName = (chassis: Chassis): string => {
	if (chassis.catNo.length) {
		return chassis.catNo;
	}
	else {
		return 'Chassis';
	}
}

export const microGetChassisNameSelection = (chassis: MicroChassis): string => {
	return chassis.name || "";
	
}

const microCanExtendChassis = (chassis: MicroChassis) => {
    const maxSlots = chassis.totalModules || 0;
	const pluginLimit =  chassis.pluginModules?.length || 0
	let moduleLength = 0
	const isIoexpansion = chassis.modules.some(mod=> mod?.deviceType === DeviceType.IOExpansion)
	const ioExpansion = chassis.modules.filter(mod=> mod?.deviceType === DeviceType.IOExpansion) || []
	if(chassis.bu){
		moduleLength  = chassis.modules.length 
	}
	else{
		moduleLength  =ioExpansion.length + 1
	}
	

    return ((isIoexpansion || !chassis.bu || pluginLimit !== 0) &&  maxSlots  > (moduleLength) );
}


// Delete base unit/controller  from micro 800 by putting bu attributes as undefined.
export const microDeleteBaseUnitAtChassis = (chassis: Chassis) => {
	const getMicroChassis = chassis as MicroChassis
    return !getMicroChassis.bu;
}


// Point of coordinates in the base unit/controller from micro 800 
export const microIsPointOnBU = (ptLocal: Point, chassis: MicroChassis)=>{
	if (chassis.bu && isPointInLocBu(ptLocal, chassis.bu.loc, chassis.bu, chassis.ps)) {
        return true;
    }
    return false;
}




const microGetChassisSizeAsDrawn = (chassis: MicroChassis, copyMode: boolean): Size => {
     // Get platform details.
    // Start with assumption that we're NOT
    // including a 'X' (extra empty) slot.
    let xWidth = 0;

    // We will if the chassis is a drag target
    // AND it has an xSlotWidth already.
    if (chassis.dragTarget && (chassis.xSlotWidth > 0)) {
        xWidth = 120;
    }

    else {
        // Otherwise, we will if we're either in Copy mode
        // or the chassis is selected, AND the chassis actually
        // CAN be extended.
        const extendPossible = chassis.selected || copyMode;
        if (extendPossible && microCanExtendChassis(chassis)) {
            xWidth = 120;
        }
    }

    // Id we DO have an 'extra slot' width...
    if (xWidth > 0) {

        // Start with the chassis' full size.
        const sz = { ...chassis.layout.size };

        // Then adjust width.
        // If a the platform has a right end cap,
        // remove its with.
        // if (dtls.rightCapInfo) {
        //     sz.width -= dtls.rightCapInfo.width;
        // }

        // Then add the specified extra width
		if(chassis.pluginModules?.length === chassis.modules.length || !chassis.bu ){
			xWidth += 120;
		}
        sz.width += xWidth;

        return sz;
    }

    // Otherwise, return the actual size.
    return { ...chassis.layout.size };
}

const microGetXSlotWidthFor = (modInfo: EngInfoComponent): number => {
    const restrict = micrGetModuleSlotRestriction(modInfo);
    if (restrict === ModuleSlotRestriction.FirstSlotOnly || restrict === ModuleSlotRestriction.SlotWithPlugin) {
        return 0;
    }
	// return modInfo.dimensions.width;
	return 230;
}

const microGetSlotContent = (chassis: MicroChassis): SlotContent[] => {

	const content = new Array<SlotContent>();
	const numSlots = getNumSlots(chassis);

	let lastWasEmpty = false;

	const pluginLength =  chassis.pluginModules?.length ? chassis.pluginModules?.length : 0;
	const isControllerDeleted = !chassis.bu;

	for (let slot = isControllerDeleted ? pluginLength : 0; slot < numSlots; slot++) {
		const mod = getModuleInSlot(chassis, slot);
		if (!mod && lastWasEmpty) {
			content.push({
				slotNum: slot,
				toSlotNum: slot,
				module: undefined,
				selected: false
			});
		}
		else {
			content.push({
				slotNum: slot,
				toSlotNum: slot,
				module: mod,
				selected: ((mod !== undefined) && mod.selected)
			});
			lastWasEmpty = (mod === undefined);
		}
	}
	return content;

}

const _insertXOffset = 0;
const _insertWidth = 90;
const _insertHeightExt = 110;

const _getInsertLoc = (chassis: MicroChassis, slot: number): LocAndSize => {
    const lastSlotIdx = chassis.modules.length - 1;
    const isXSlot = (slot > lastSlotIdx);
    const slotBasis = isXSlot
        ? chassis.modules.length - 1
        : slot;

    const loc = { ...chassis.layout.slotLocs[slotBasis] };
    if (isXSlot) {
        loc.x += loc.width;
    }
    loc.x += _insertXOffset;
    loc.width = _insertWidth;
    loc.y -= _insertHeightExt;
    loc.height += (2 * _insertHeightExt);

    const rack = getRack(chassis);
    if (rack) {
        offsetLoc(loc, rack.ptOrg);
    }
    else {
        throw new Error('getRack FAILED in _getInsertLoc');
    }

    return loc;
}


const microGetPluginDragStatus = (
	chassis: MicroChassis,
	dragInfo: DragDeviceInfo,
	slot:number, // target
	pluginLength:number
) :DropStatus =>
	{
		const bestDropPossible = getBestPossibleDropStatus(dragInfo,chassis)
		// return nodrop for if the drop is not plugin slot.
		if(slot < 0  || slot >= pluginLength){
			return DropStatus.NoDrop;	
		}
		if(chassis.modules[slot]){
			return DropStatus.NoDrop;
		}
		return bestDropPossible;
	}

const microGetIOExpansionDragStatus = (
	chassis: MicroChassis,
	dragInfo: DragDeviceInfo,
	slot:number, // target
	pluginLength:number
) :DropStatus =>
	{
		
		const bestDropPossible = getBestPossibleDropStatus(dragInfo,chassis)
		// checking for any IO power supply existis or not.
		const ioPSAvailable = chassis.modules.find(mod=>mod?.deviceType===DeviceType.PS)

		//Getting the last IO module to check for show graphic.
		// const IOModules = chassis.modules.filter(mod=>mod?.deviceType===DeviceType.IOExpansion);
		// const snapModule =  IOModules[IOModules.length-1]
		// const snapModuleSlot = (snapModule?.slotID || pluginLength);
		// const isSnapModule = snapModuleSlot === dragInfo.dragMod.slotID

		//check if the IO mod drag point is on IO Power Supply.
		if(ioPSAvailable && slot === ioPSAvailable.slotID){
			return DropStatus.NoDrop;
		}
		//check if the drop slot is not valid IO slot;
		if(slot < pluginLength){
			return DropStatus.NoDrop;
		}
		//set the graphic for slot insert
		else{
			if(slot === dragInfo.dragMod.slotID && chassis===dragInfo.dragMod.parent){
				return bestDropPossible;
			}
			// if dragMod is not snap module and not at last slot, return with graphic
			if(bestDropPossible !== DropStatus.NoDrop){
				dragInfo.showInsert = true;
				dragInfo.insertLoc = _getInsertLoc(chassis, slot);
	
			}
			// dragInfo.showInsert = true;
			// dragInfo.insertLoc = _getInsertLoc(chassis, slot);
			
			return bestDropPossible;
		}
}

const microGetChassisDropStatus = (
	chassis: MicroChassis,
	dragInfo: DragDeviceInfo,
    touchEl: ChassisElement,
    slot: number
): DropStatus => {

	dragInfo.showInsert = false;
	dragInfo.insertLoc = getEmptyLoc();

	// Disqualify any platform mismatch.
	if (dragInfo.dragMod.platform !== chassis.platform) {
		return DropStatus.NoDrop;
	}
	//Disqualify any other element apart from slot;
	if(touchEl !== ChassisElement.Slot){
		return DropStatus.NoDrop;
	}
	const pluginLength = chassis.pluginModules?.length || 0;
	switch(dragInfo.dragMod.deviceType){
		case DeviceType.IOExpansion :
			return microGetIOExpansionDragStatus(chassis,dragInfo,slot,pluginLength);

		case DeviceType.PlugIn :
			return microGetPluginDragStatus(chassis,dragInfo,slot,pluginLength);

		default:
			return DropStatus.NoDrop;
	}
}

export const microDropDragDeviceOnChassis = (
    chassis: MicroChassis,
    dragInfo: DragDeviceInfo,
    touchEl: ChassisElement,
    slotNum: number
): DropResult => {
	// Not a copy.
	// If we have a clean drop status...
	if (dragInfo.dropStatus !== DropStatus.NoDrop && touchEl===ChassisElement.Slot) {
		// BEFORE making any change, call our helper
		// to handle any related undo/redo work for us.
		if(dragInfo.dragMod.deviceType === DeviceType.IOExpansion){
			_snapDropModule(chassis, dragInfo, slotNum, false)
		}
		else{
		chassisChanging(chassis);
		suspendUndoSnapshots(true);

		const dragModSlot = dragInfo.dragMod.slotID;
		const targetMod = chassis.modules[slotNum];

		if(slotNum - dragModSlot === 1 && targetMod ){
			return DropResult.DropFailed;
		}

		// We're MOVING the existing module
		// to the specified location. Start
		// by detaching it from its old location.
		detachModule(dragInfo.dragMod);

		//remvoe the dragmod from the modules and re-insert the same dragmod to drop slot.
		if(dragInfo.dragMod.deviceType === DeviceType.PlugIn){
			chassis.modules[slotNum] = dragInfo.dragMod
		}
		else{
			chassis.modules.splice(dragModSlot,1)
			chassis.modules.splice(slotNum,0,dragInfo.dragMod)
		}

		// Record that slot location in the module itself.
		dragInfo.dragMod.slotIdx = slotNum;
		dragInfo.dragMod.slotID = slotNum;

		// And set its new parent chassis.
		dragInfo.dragMod.parent = chassis;
		suspendUndoSnapshots(false);

		}

		//reaarange chassis for power supply position and modules.
		updateChassis(chassis)
	

		return DropResult.DropSuccess
	}
		
	return DropResult.DropFailed
}

// Check to see if the specified module COULD be added to
// the given chassis. If yes, answers the first slot that
// would work. If no, answers NO_SLOT (-1).
export const microCanModuleBeAdded = (module: ChassisModule, chassis: MicroChassis): number => {
	const pluginLimit = chassis.pluginModules?.length || 0
    const emptySlot = []
	if(module.deviceType === DeviceType.PlugIn){

		for (let slotIdx = 0; slotIdx < pluginLimit; slotIdx++) {

			// Get whatever's in the slot.
			const mod = chassis.modules[slotIdx];
			if(!mod){
				emptySlot.push(slotIdx)
			}
		}
	}
	
	else if(module.deviceType === DeviceType.IOExpansion ){
	const totalModules = chassis.totalModules || 0
		for (let slotIdx = pluginLimit; slotIdx < totalModules; slotIdx++) {

			// Get whatever's in the slot.
			const mod = chassis.modules[slotIdx];
			if(!mod){
				emptySlot.push(slotIdx)
			}
		}
	}
	if(emptySlot.length !== 0){
		return emptySlot[0] 
	}
	else{
		return NO_SLOT
	}

     
}

export const getPowerSupplyValueTypeSel = (locAttrInfo: LocAttributeInfo, gsValueAlternate = ''): PSInputVoltage => {
    // Note there is a case when we already know the attribute
    // value. That can be passed in as the gsValueAlternate and
    // we can skip over getting/finding the attribute in the loc.
    if (gsValueAlternate)
        return convertGuidedSelAttrToAppVal(locAttrInfo.platform, 'CV', gsValueAlternate, true) as PSInputVoltage;

    const setting = getLocAttributeSetting(locAttrInfo, 'CV');
    if (setting) {
        return convertGuidedSelAttrToAppVal(locAttrInfo.platform, 'CV', setting.selectedOption.id, true) as PSInputVoltage;
    }

	

    // Just call it screw...
    return PSInputVoltage.DC24V;
}

export const microDuplicateChassis = (chassis: MicroChassis, insertCopyAt: number):
    Chassis | null => {
    // Get the associated project.
    const project = getProjectFromChassis(chassis);

    // If we can...
    if (project) {

        const psCat = chassis.ps ? chassis.ps.catNo : undefined;
		const buCat = chassis.bu ? chassis.bu.catNo : undefined;
        // Add a new chassis, using the catNos of the
        // old and of its power supply (if it has one).
        const dup = addChassis(project, chassis.platform, chassis.catNo,
            insertCopyAt, psCat, buCat) as MicroChassis;
		
        // If the add worked...
        if (dup) {

            // orig here is still the same as chassis,
            // but 'orig' makes the following easier to
            // follow.
            const orig = chassis;

            // Our dup chassis should now have whatever
            // REQUIRED accys were appropriate added to
            // the chassis itself and its dup'd power supply.
            // However, we want it to be a real duplicate,
            // so we'll copy accys from orig to dup here for
            // both of those elements.
            copyAccys(orig, dup);
            if (orig.ps && dup.ps) {
                copyAccys(orig.ps, dup.ps);
            }
            // Copy default I/O wiring type.
            dup.defaultIOModWiring = chassis.defaultIOModWiring;
			dup.defaultPowerSupplyValue = chassis.defaultPowerSupplyValue;

            // Start optimistic regarding
            // module duplication results.
            let addFailed = false;
            // Walk all modules in the orig chassis. For each...
            for (let origSlot = 0; origSlot < orig.modules.length; origSlot++) {

                // Get the original module (if there IS one at the slot).
                const origMod = orig.modules[origSlot];

                // If so...
                if (origMod && !origMod.isFPD) {

                    // For slots 0 or 1, we'll add at the SAME slot.
                    // Otherwise, we'll always use the CURRENT length
                    // our our new chassis' modules array for a target slot,
                    // We want the module inserted at the END.
                    const slotForAdd =origSlot;

                    // Using the original's catNo, add the same module
                    // to the dup chassis at the same slot.
                    // If the add works...
                    if (microAddModuleAtSlot(dup, origMod.catNo, slotForAdd, true, false)) {

                        // Get the new module from the dup chassis.
                        const newMod = dup.modules[slotForAdd];

                        // If we can...
                        if (newMod) {
                            // Then COPY whatever accys found on
                            // the original module to the new one.
                            copyAccys(origMod, newMod);
                        }
                        else {
                            addFailed = true;
                        }
                    }
                    else {
                        addFailed = true;
                    }
                }
            }

            // Deal with any problems encountered above
            // during the module dup'ing process.
            if (addFailed) {
                throw new Error('microDuplicateChassis() could not duplicate all modules!');
            }

            return dup;
        }
    }
    return null;
}


export const RegisterMicroGeneralImpl = () => {

	const impl: GeneralImplSpec = {
		platform: PlatformMicro,
		imageScaleFactor: _microImageScaleFactor,
		createChassis: microCreateChassis,
        getSlotID: microgetSlotID,
		getChassisSlotUsage: microGetChassisSlotUsage,
		createModule: microCreateModule,
		addModuleToChassis: microAddModuleToChassis,
		addNewModuleAtSlot: microAddNewModuleAtSlot,
		addModuleAtSlot: microAddModuleAtSlot,
		getActBtnInfo: microGetActionBtnInfo,
		getChassisRenderer: microGetChassisRenderer,
		addModulesToChassis: microAddModulesToChassis,
		getMaxNewModules: microGetMaxNewModules,
		filterAvailableModules: microFilterAvailableModulesMicro,
		getSlotTypeRestriction: microGetSlotTypeRestrictionMicro,
		configureChassis: microConfigureChassis,
		deleteModuleAtSlot: microDeleteModuleAtSlot,
		getDefaultChassisName: microDefaultChassisName,
		canExtendChassis: microCanExtendChassis,
		getChassisSizeAsDrawn: microGetChassisSizeAsDrawn,
		updateChassisLayout: microUpdateChassis,
		getSlotContent:microGetSlotContent,
		getChassisNameSelection:microGetChassisNameSelection,
		getModuleSlotRestriction:micrGetModuleSlotRestriction,
		getXSlotWidthFor:microGetXSlotWidthFor,
		getChassisDropStatus: microGetChassisDropStatus,
        dropDragDeviceOnChassis: microDropDragDeviceOnChassis,
		canModuleBeAdded: microCanModuleBeAdded,
		duplicateChassis: microDuplicateChassis,
	};

	RegisterGeneralImpl(impl);

}

