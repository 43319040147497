import { IOEntryModeEnum } from "../types/SettingsTypes";
import {
    IO_Bit,
    IOModuleSelection,
    txtFeatureANY,
    UserSelectedPoints,
    Vltg_Bit,
    IO_Txt,
    IO_Id
} from "../types/IOModuleTypes";
import { PointEntryInfo, PointEntrySectionInfo } from "../types/IOPointEntryTypes";
import { IOModulePoints } from "../types/EngDataTypes";
import { getPointTypeOrFeatureMask } from "../util/IOModuleHelp";


export const hasIOPointTypeQuantity = (points: IOModulePoints): boolean => {
    if (points.selfCfg > 0)
        return true;
    const lenTypes = points.typeData.length;
    if (lenTypes === 0)
        return false;
    
    return points.typeData.some(type => type.qty > 0);
}

export const createNewPointEntryInterface = (): PointEntryInfo => {
    return {
        moduleCatalogs: [],
        moduleDisplayStrs: [],
        points: 0,
        advancedModule: undefined,
        basicModule: undefined,
        isAdvModDefault: false,
        OnChanged: () => { return; },
        advancedModCount: 0,
        basicModCount: 0,
        moduleSelInMultipleEntries: false,
        indexEntry: 0,
        platform: '',
        savedSelectionInvalid: false,
        invalidEntry: false,
        selected: false,
        type: '',
        feature: '',
        types: [],
        features: [],
    };
}

export const getUserModuleSelectionsFromPointSectionInfo = (pointEntryInfo: PointEntrySectionInfo, mode: IOEntryModeEnum): IOModuleSelection[] => {
    const ModuleSelections: IOModuleSelection[] = [];
    const mapCatalogToUserSelections = new Map<string, UserSelectedPoints[]>();
    const mapCatalogToQuantity = new Map<string, number>();

    pointEntryInfo.entries.forEach((entry) => {
        if (entry.invalidEntry === false) {
            const catNo = mode === IOEntryModeEnum.Basic ? entry.basicModule : entry.advancedModule;
            const numMods = mode === IOEntryModeEnum.Basic ? entry.basicModCount : entry.advancedModCount;
            if (catNo) {
                // We are consolidating modules, which means for each
                // catalog, we add the points requested together.
                const typeMask = getPointTypeOrFeatureMask(entry.type);
                const newMapEntry: UserSelectedPoints = { typeID: typeMask, userPointCount: entry.points, type: entry.type, feature: entry.feature };

                const selection = mapCatalogToUserSelections.get(catNo);
                if (selection == null) {
                    mapCatalogToUserSelections.set(catNo, [newMapEntry]);
                }
                else {
                    selection.push(newMapEntry);
                }

                if (mapCatalogToQuantity.get(catNo) == null) {
                    mapCatalogToQuantity.set(catNo, numMods);
                }
            }
        }
    });

    mapCatalogToUserSelections.forEach((arrUsrSelPts, catalog) => {
        const quantity: number | undefined = mapCatalogToQuantity.get(catalog);
        if (arrUsrSelPts != null && quantity != null) {
            const entryGroup: IOModuleSelection = { catalog: catalog, quantity: quantity, selectedPoints: arrUsrSelPts };
            ModuleSelections.push(entryGroup);
        }
    });

    return ModuleSelections;
}

export const setAllPointEntriesSelStatus = (selectAll: boolean, info: PointEntrySectionInfo) => {
    info.entries.forEach((entry) => {
        entry.selected = selectAll;
    })
}

let _mapTypeIDToDisplay: Map<string, string> | undefined = undefined;

const _loadTypeTitleMap = () => {
    _mapTypeIDToDisplay = new Map<string, string>();

    _mapTypeIDToDisplay.set(IO_Id.DC, IO_Txt.DC);
    _mapTypeIDToDisplay.set(IO_Id.HSDC, IO_Txt.HSDC);
    _mapTypeIDToDisplay.set(IO_Id.SEV, IO_Txt.SEV);
    _mapTypeIDToDisplay.set(IO_Id.HART, IO_Txt.HART);
    _mapTypeIDToDisplay.set(IO_Id.Isol, IO_Txt.Isol);
    _mapTypeIDToDisplay.set(IO_Id.Diag, IO_Txt.Diag);
    _mapTypeIDToDisplay.set(IO_Id.Fused, IO_Txt.Fused);

    _mapTypeIDToDisplay.set(IO_Id.AI, IO_Txt.AI);
    _mapTypeIDToDisplay.set(IO_Id.AO, IO_Txt.AO);
    _mapTypeIDToDisplay.set(IO_Id.DI, IO_Txt.DI);
    _mapTypeIDToDisplay.set(IO_Id.DO, IO_Txt.DO);
    _mapTypeIDToDisplay.set(IO_Id.SAI, IO_Txt.SAI);
    _mapTypeIDToDisplay.set(IO_Id.SAO, IO_Txt.SAO);
    _mapTypeIDToDisplay.set(IO_Id.SDI, IO_Txt.SDI);
    _mapTypeIDToDisplay.set(IO_Id.SDO, IO_Txt.SDO);
    _mapTypeIDToDisplay.set(IO_Id.RO, IO_Txt.RO);
    _mapTypeIDToDisplay.set(IO_Id.SRO, IO_Txt.SRO);
    _mapTypeIDToDisplay.set(IO_Id.RTD, IO_Txt.RTD);
    _mapTypeIDToDisplay.set(IO_Id.Therm, IO_Txt.Therm);
    _mapTypeIDToDisplay.set(IO_Id.HSC, IO_Txt.HSC);
    _mapTypeIDToDisplay.set(IO_Id.PTO, IO_Txt.PTO);
    _mapTypeIDToDisplay.set(IO_Id.PWM, IO_Txt.PWM);
}

export const getIOTypeIDToDisplayMap = (): Map<string, string> => {
    if (_mapTypeIDToDisplay == null) {
        _loadTypeTitleMap();
    }

    if (_mapTypeIDToDisplay)
        return _mapTypeIDToDisplay;

    // Should never happen.
    throw new Error('getIOTypeIDToDisplayMap(): invalid map.')
}

// We are using the JSON property names as the
// IDs for I/O Types and Features. We pre-fill
// a map for the IDs that we know about and
// add the ones that we do not.
export const getIODisplayTitleFromID = (idPropName: string): string => {
    if (!idPropName)
        return txtFeatureANY;

    if (_mapTypeIDToDisplay == null) {
        _loadTypeTitleMap();
    }

    if (_mapTypeIDToDisplay) {

        // If the ID/Title is NOT in the map...
        let title = _mapTypeIDToDisplay.get(idPropName);
        if (title == null) {
            // Create the title: When we have
            // a Type/Feature prefix, trim off 
            // the prefix. Otherwise just use 
            // the property name.
            title = idPropName;
            if (title.startsWith('IOpoints_'))
                title = title.replace('IOpoints_', '');
            else if (title.startsWith('IOFeature_'))
                title = title.replace('IOFeature_', '');

            // Replace any underscores with
            // spaces and add it to the map.
            title = title.replace('_', ' ');
            _mapTypeIDToDisplay.set(idPropName, title);
        }

        return title;
    }

    // Should never happen.
    throw new Error('getIODisplayTitleFromID(): invalid map.')
}


export const getIOTypeIDFromDisplay = (display: string): string | undefined => {
    if (_mapTypeIDToDisplay == null) {
        _loadTypeTitleMap();
    }

    let id: string | undefined = undefined;
    if (_mapTypeIDToDisplay) {
        _mapTypeIDToDisplay.forEach((val, key) => {
            if (val === display)
                id = key;
        });
    }
    else {
        // Should never happen.
        throw new Error('getIODisplayTitleFromID(): invalid map.')
    }

    if (id == null)
        console.warn(`getIOTypeIDFromTitle(): ${display} is not a valid I/O Type or Feature title.`);

    return id;
}

//// Debug Helpers ///////

// Debug: Human readable text from query bitset
export const debugGetIOQueryFromBitset = (ioMask: number, vltgMask = 0) => {
    let retVal = '';

    if (ioMask) {
        if ((ioMask & IO_Bit.Exclude) !== 0)
            retVal += 'Exclude: ';
        if ((ioMask & IO_Bit.Input) !== 0)
            retVal += 'Input/';
        if ((ioMask & IO_Bit.Output) !== 0)
            retVal += 'Output/';
        if ((ioMask & IO_Bit.Digital) !== 0)
            retVal += 'Digital/';
        if ((ioMask & IO_Bit.Analog) !== 0)
            retVal += 'Analog/';
        if ((ioMask & IO_Bit.HartIO) !== 0)
            retVal += 'HartIO/';
        if ((ioMask & IO_Bit.ExTemp) !== 0)
            retVal += 'XT/';
        if ((ioMask & IO_Bit.Conformal) !== 0)
            retVal += 'CC/';
        if ((ioMask & IO_Bit.ThermoCouple) !== 0)
            retVal += 'Thermo/';
        if ((ioMask & IO_Bit.RTD) !== 0)
            retVal += 'RTD/';
        if ((ioMask & IO_Bit.SafetyIO) !== 0)
            retVal += 'Saf/';
        if ((ioMask & IO_Bit.Diagnostic) !== 0)
            retVal += 'Diag/';
        if ((ioMask & IO_Bit.Isolated) !== 0)
            retVal += 'Iso/';
        if ((ioMask & IO_Bit.ElectronicFused) !== 0)
            retVal += 'Fused/';
        if ((ioMask & IO_Bit.AnalogCurrent) !== 0)
            retVal += 'AnlgCurr/';
        if ((ioMask & IO_Bit.AnalogVoltage) !== 0)
            retVal += 'AnlgVolt/';
    }

    if (vltgMask) {
        if ((vltgMask & Vltg_Bit.vac24) !== 0)
            retVal += 'VAC24/';
        if ((vltgMask & Vltg_Bit.vac48) !== 0)
            retVal += 'VAC48/';
        if ((vltgMask & Vltg_Bit.vac120) !== 0)
            retVal += 'VAC120/';
        if ((vltgMask & Vltg_Bit.vac240) !== 0)
            retVal += 'VAC240/';

        if ((vltgMask & Vltg_Bit.vdc5) !== 0)
            retVal += 'VDC5/';
        if ((vltgMask & Vltg_Bit.vdc12) !== 0)
            retVal += 'VDC12/';
        if ((vltgMask & Vltg_Bit.vdc24) !== 0)
            retVal += 'VDC24/';
        if ((vltgMask & Vltg_Bit.vdc48) !== 0)
            retVal += 'VDC48/';
        if ((vltgMask & Vltg_Bit.vdc60) !== 0)
            retVal += 'VDC60/';
        if ((vltgMask & Vltg_Bit.vdc125) !== 0)
            retVal += 'VDC120/';

        if ((vltgMask & Vltg_Bit.rng0_10V) !== 0)
            retVal += '0-10V/';
        if ((vltgMask & Vltg_Bit.rng0_20mA) !== 0)
            retVal += '0-20mA/';
        if ((vltgMask & Vltg_Bit.rng4_20V) !== 0)
            retVal += '4-20V/';
    }

    const len = retVal.length;
    if (len > 2 && retVal[len - 1] === '/')
        retVal = retVal.substring(0, len - 1);

    return retVal;
}

