import { getBankInfo, getNumBanks } from "../../../model/ChassisProject";
import { Chassis, ChassisModule } from "../../../types/ProjectTypes";
import { Point, Size, LocAndSize } from "../../../types/SizeAndPosTypes";
import { StageUnitsPerMM } from "../../../types/StageTypes";
import { getImgNameFromPath, offsetLoc, scaleSize } from "../../../util/GeneralHelpers";
import { RendInfo, RendPortion } from "../../../util/SysDsgHelp";
import { flexHAGetLayoutInfo } from "./FlexHALayout";

const _localImgDir = '/assets/flexHA/';

const _getLocalImageSrc = (imgName: string): string => {
    return 'CSA' + _localImgDir + imgName;
}

const _getIOTbImageSrc = (mod: ChassisModule): string => {
    let imgName = '';
    if (mod.slotsUsed === 2) {
        imgName = '5015_IO_TB_Duplex.png';
    }
    else if (mod.slotFiller) {
        imgName = '5015_IO_TB_Cover.png';
    }
    else {
        imgName = '5015_IO_TB_Simplex.png';
    }
    return _getLocalImageSrc(imgName);
}

const _scaleLoc = (loc: LocAndSize, scale: number) => {
    loc.x = Math.round(loc.x * scale);
    loc.y = Math.round(loc.y * scale);
    loc.width = Math.round(loc.width * scale);
    loc.height = Math.round(loc.height * scale);
}

export const flexHAGetChassisRendInfo = (chassis: Chassis): RendInfo[] => {

    // Get image src for items we'll ALWAYS need.
    const bpImg = _getLocalImageSrc('5015_Backplate.png');
    const baseImg = '5015-A4IOKIT_tp.png';

    const rendInfo = new Array<RendInfo>();

    //// Set up initial md object.
    //const md: SysDsgChassisMetadata = {
    //    guid: chassis.id,
    //    rendInfo: new Array <MdBankRendInfo>()
    //}

    // We'll scale the info we provide so
    // 1 unit is ~equal to 1 millimeter.
    const scaleAdj = 1.0 / StageUnitsPerMM;

    // See how many banks we have.
    const numBanks = getNumBanks(chassis);

    // For each...
    for (let bank = 0; bank < numBanks; bank++) {

        // Get general bank information.
        const bankInfo = getBankInfo(chassis, bank);

        // Create an array of objects to hold
        // info about EACH image component.
        const imgEls = new Array<RendPortion>();

        // Get the bank's layout.
        const layout = flexHAGetLayoutInfo(chassis, bank);


        // Get a copy of the backplate's loc.
        const bp = { ...layout.backplateLoc };

        // Determine the point relative to the layout
        // that we want our bank's upper left to be.
        // want to be the 
        const ptBankUpLeft: Point = {
            x: bp.x,
            y: layout.nonPSCompsLoc.y
        }

        // The size in our layout object is only set
        // for the primary bank (the layout hanging on
        // the chassis itself). And, THAT size refers to
        // the overall size in CSA of ALL banks. HERE, we
        // want the overall depiction size of just this
        // bank.
        const bankSize: Size = {
            width: bp.x + bp.width - ptBankUpLeft.x,
            height: bp.y + bp.height - ptBankUpLeft.y
        }

        // Determine that size at scale.
        const scaledBankSize = scaleSize(bankSize, scaleAdj, true);

        // Layout locs are relative to the upper-left corner
        // of the depiction of the entire chassis in CSA. The
        // locs we provide here will be similar, but relative
        // to the upper-left corner of the individual bank's
        // depiction. Set up an associated offset for that.
        const ptOffset: Point = { x: -ptBankUpLeft.x, y: -ptBankUpLeft.y };

        // For each element, we'll start with the
        // associated loc found in the layout, then
        // offset that, and add an associated img el
        // structure. Scaling will be done at the end
        // on the entire array.
        // Backplate...
        offsetLoc(bp, ptOffset);
        imgEls.push({
            loc: bp,
            image: bpImg,
            border: { stroke: 'lightgray' }
        });

        // Bank exp lead comp if we have one...
        if (layout.locLeadComp) {
            const loc = { ...layout.locLeadComp };
            offsetLoc(loc, ptOffset);
            imgEls.push({
                loc: loc,
                image: '5015-BEBRXT.png'
            });
        }

        // I/O bases...
        for (let baseIdx = 0; baseIdx < layout.ioBaseLocs.length; baseIdx++) {
            const loc = { ...layout.ioBaseLocs[baseIdx] };
            offsetLoc(loc, ptOffset);
            imgEls.push({
                loc: loc,
                image: baseImg
            });
        }

        // Modules and terminal block...
        for (let locSlotIdx = 0; locSlotIdx < bankInfo.slotsInBank; locSlotIdx++) {
            const chasSlot = bankInfo.startSlot + locSlotIdx;
            const mod = chassis.modules[chasSlot];
            if (mod) {
                const modLoc = { ...layout.slotLocs[locSlotIdx] };
                offsetLoc(modLoc, ptOffset);
                imgEls.push({
                    loc: modLoc,
                    image: getImgNameFromPath(mod.imgSrc)
                });

                if (chasSlot > 0) {
                    const tbLoc = { ...layout.ioTBLocs[locSlotIdx] };
                    offsetLoc(tbLoc, ptOffset);
                    imgEls.push({
                        loc: tbLoc,
                        image: _getIOTbImageSrc(mod),
                        border: { stroke: 'black' }
                    });

                    locSlotIdx += (mod.slotsUsed - 1);
                }
            }
        }

        // Bank exp trailer comp if we have one...
        if (layout.locTrailComp) {
            const loc = { ...layout.locTrailComp };
            offsetLoc(loc, ptOffset);
            imgEls.push({
                loc: loc,
                image: '5015-BEBLXT.png'
            });
        }

        // Run the entire array of elements we collected,
        // and scale each's location to our final scale.
        imgEls.forEach(el => {
            _scaleLoc(el.loc, scaleAdj);
        })

        // Add the completed rendering info
        // for the bank to our array. 
        rendInfo.push({
            size: scaledBankSize,
            els: imgEls
        });
    }

    // Return final results.
    return rendInfo;
}
