import React, { ReactNode, useRef } from 'react';
import './FloatingDetails.scss';
import '../../styles/Meteor.scss'
import {
    Chassis,
    ChassisModule,
    UsageLevelStatus,
    UsageItemProgbarReplacement,
    ChassisPowerSupply,
    SelectableDevice,
} from '../../types/ProjectTypes';
import {
    PowerUnitType,
    PwrUsageInfo,
    PowerBreakdownTips,
    PowerBreakdownUsageInfo,
    PowerType,
} from '../../types/PowerTypes';
import {
    getPwrUsageInfo,
    getPlatformPowerType
} from '../../util/PowerHelp';
import UsageItem from './UsageItem';
import { filterButtonTypes } from '../../projStatus/ProjectStatusDisplay';
import { LogMsgLevel } from '../../util/ProjectLog';
import DangerousIcon from '@mui/icons-material/Dangerous';
import WarningIcon from '@mui/icons-material/Warning';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { blue, red, yellow } from '@mui/material/colors';
import { generateFlag } from './PerformanceUsage';
import { getDevicePowerTips, getPowerDetails } from '../../implementation/ImplGeneral';
import { getSAPowerDetails } from '../../util/FieldPowerHelp';
import { StatusLevel } from '../../types/MessageTypes';
import { flexHAGetSAPowerBreakdown } from '../../platforms/flexHA/model/FlexHAChecker';
import { flexHAIsSAPowerSupply } from '../../platforms/flexHA/model/FlexHAChassis';
import { getChassisAltSAPwrSupplier } from '../../platforms/common/Common';

const _convertToUsageLvlStatus = (lvlStat: StatusLevel): UsageLevelStatus => {
    switch (lvlStat) {
        case StatusLevel.Error:
            return UsageLevelStatus.Error;
        case StatusLevel.Warning:
            return UsageLevelStatus.Warning;
    }
    return UsageLevelStatus.OK;
}

const _adjustPowerUsageInfo = (powerTips: PowerBreakdownTips | undefined, usageInfo: PowerBreakdownUsageInfo) => {
    if (powerTips) {
        if (powerTips.tip5V)
            usageInfo.info5V.status = _convertToUsageLvlStatus(powerTips.tip5V.replTipStatus);

        if (powerTips.tip24V)
            usageInfo.info24V.status = _convertToUsageLvlStatus(powerTips.tip24V.replTipStatus);

        if (powerTips.tipWatt)
            usageInfo.infoWatt.status = _convertToUsageLvlStatus(powerTips.tipWatt.replTipStatus);

        if (powerTips.tipMod)
            usageInfo.infoMod.status = _convertToUsageLvlStatus(powerTips.tipMod.replTipStatus);

        if (powerTips.tipSA)
            usageInfo.infoSA.status = _convertToUsageLvlStatus(powerTips.tipSA.replTipStatus);
    }
}

interface Props {
    chassis: Chassis;
    module?: ChassisModule;
    ps?: ChassisPowerSupply;
    selectedButton?: filterButtonTypes;
    oneLineFormat?: boolean;

}
interface PowerUsageCompLevelStatus {
    status: LogMsgLevel;
    icon: ReactNode;
}

const DetailsPowerUsage = (props: Props) => {

    let [sup, cons] = getPowerDetails(props.chassis);
    let initialPwrTips: PowerBreakdownTips | undefined = undefined;
  
    // If we have an SA/MOD power type...
    const typeModSAPwr = (getPlatformPowerType(props.chassis.platform) === PowerType.PT_ModSA);
    if (typeModSAPwr) {
        // Get the SA/MOD power supplier.
        const device: SelectableDevice | undefined = (props.ps ? props.ps : props.module);
        const saPwrInfo = getChassisAltSAPwrSupplier(props.chassis, device);
        if (saPwrInfo) {
            if (saPwrInfo.saPowerSupplier) {
                // Get the power supplied and consumed.
                const module = (props.module ? props.module : saPwrInfo.saPowerSupplier);
                const device = (props.module ? props.module : props.chassis);
                [sup, cons] = getSAPowerDetails(saPwrInfo.chassis, module);
                initialPwrTips = getDevicePowerTips(device, props.oneLineFormat);
            }
            else if (saPwrInfo.saPowerSupply) {
                // So far Only FlexHA has SA PSUs.
                if (flexHAIsSAPowerSupply(saPwrInfo.saPowerSupply)) {
                    [sup, cons] = flexHAGetSAPowerBreakdown(saPwrInfo.saPowerSupply, props.chassis);
                    initialPwrTips = getDevicePowerTips(saPwrInfo.saPowerSupply, props.oneLineFormat);
                }
            }
        }
    }

    // If we still do not have tips, get them for the chassis.
    if (initialPwrTips == null)
        initialPwrTips = getDevicePowerTips(props.chassis, props.oneLineFormat);

    // Define a valid tips object (i.e. never undefined).
    const powerTips: PowerBreakdownTips = (initialPwrTips ? initialPwrTips : {});

    const infoPU: PowerBreakdownUsageInfo = {
        info5V: getPwrUsageInfo(cons.mAat5V, sup.mAat5V, PowerUnitType.mA),
        info24V: getPwrUsageInfo(cons.mAat24V, sup.mAat24V, PowerUnitType.mA),
        infoWatt: getPwrUsageInfo(cons.mWatt, sup.mWatt, PowerUnitType.mW),
        infoMod: getPwrUsageInfo(cons.modPower, sup.modPower, PowerUnitType.mA),
        infoSA: getPwrUsageInfo(cons.saPower, sup.saPower, PowerUnitType.mA),
    }

    // If we have power tips, set their status 
    // level on power usage info objects.
    _adjustPowerUsageInfo(powerTips, infoPU);

    const PUCLStatusError = useRef<PowerUsageCompLevelStatus>({ status: LogMsgLevel.error, icon: <DangerousIcon sx={{ fontSize: 14, color: red[700] }} /> });
    const PUCLStatusWarning = useRef<PowerUsageCompLevelStatus>({ status: LogMsgLevel.warning, icon: <WarningIcon sx={{ fontSize: 14, color: yellow[800] }} /> });
    const PUCLStatusInfo = useRef<PowerUsageCompLevelStatus>({ status: LogMsgLevel.info, icon: <InfoOutlinedIcon sx={{ fontSize: 14, color: blue[700] }} /> });

    const getPowerUsageCompLevelStatus = (): PowerUsageCompLevelStatus => {
        if (typeModSAPwr) {
            if (infoPU.infoMod.status === UsageLevelStatus.Error || infoPU.infoSA.status === UsageLevelStatus.Error) {
                return PUCLStatusError.current;
            }
            else if (infoPU.infoMod.status === UsageLevelStatus.Warning || infoPU.infoSA.status === UsageLevelStatus.Warning) {
                return PUCLStatusWarning.current;
            }
            else {
                return PUCLStatusInfo.current;
            }
        }

        else {
            if (infoPU.info5V.status === UsageLevelStatus.Error || infoPU.info24V.status === UsageLevelStatus.Error || infoPU.infoWatt.status === UsageLevelStatus.Error) {
                return PUCLStatusError.current;
            }
            else if (infoPU.info5V.status === UsageLevelStatus.Warning || infoPU.info24V.status === UsageLevelStatus.Warning || infoPU.infoWatt.status === UsageLevelStatus.Warning) {
                return PUCLStatusWarning.current;
            }
            else {
                return PUCLStatusInfo.current;
            }
        }
    }

    if (props.selectedButton) {
        const compstatus = getPowerUsageCompLevelStatus();
        const flag = generateFlag(compstatus.status, props.selectedButton);
        if (!flag) {
            return <></>;
        }
    }

    const renderUsageItem = (title: string, pui: PwrUsageInfo, lineFormat: boolean, progbarRepl?: UsageItemProgbarReplacement) => {
        if (lineFormat) {
            return ( //power-usage-group-inModel'
                <div className='power-usage-single-line__Item' >
                    <UsageItem
                        label={title}
                        pct={pui.pctUsed}
                        text={pui.usageText}
                        status={pui.status}
                        progWidth={50}
                        boldLabel={true}
                        progbarReplacement={progbarRepl}
                    />
                </div>
            );
        }
        else {
            return (
                <UsageItem
                    label={title}
                    pct={pui.pctUsed}
                    text={pui.usageText}
                    status={pui.status}
                    progbarReplacement={progbarRepl}
                />
            );
        }
    }
    const renderPower = (PTtype:PowerType,oneLine:boolean) => {
        switch (PTtype) {
            case PowerType.PT_AlwaysOK:
                return (
                    <>N/A</>
                );
    
            case PowerType.PT_524mA:
                return (
                    <>
                        {renderUsageItem('5V', infoPU.info5V, oneLine, powerTips.tip5V)}
                        {renderUsageItem('24V', infoPU.info24V, oneLine, powerTips.tip24V)}
                        {renderUsageItem('Watts', infoPU.infoWatt, oneLine, powerTips.tipWatt)}
                    </>
                );
                case PowerType.PT_ModSA:
                    return (
                        <>
                        {renderUsageItem('MOD', infoPU.infoMod, oneLine, powerTips.tipMod)}
                        {renderUsageItem('SA', infoPU.infoSA, oneLine, powerTips.tipSA)}
                    </>
                    );
    
            default:
                return (
                    <>Unknown power type</>
                );
        }
    }

    const title = "Power Consumption"
    if (props.oneLineFormat) {
        // We have a chassis in the Issues Dialog.
        // If we have a 'Snap' type, post a default
        // message for SA Power.
        return (
            <div className='power-usage-single-line'>

                {getPowerUsageCompLevelStatus().icon}

                <div
                    className='power-usage-single-line__title'
                >{`${title}:`}</div>
               {renderPower(getPlatformPowerType(props.chassis.platform),true)}
            </div>)
    }
    return (
        <div className='floating-details-group'>
            <div className='a-type__body highlighted-item'>
                {title}
            </div>

            <div className='floating-details-power-usage-items'>
            {renderPower(getPlatformPowerType(props.chassis.platform),false)}
            </div>
        </div>
    );
}

export default DetailsPowerUsage;
