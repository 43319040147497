import config from "../config";
import { DEV_DEBUG } from "../types/Globals";

export enum ServerEnv {
    DEV = 'dev',
    QA = 'qa',
    PROD = 'prod',
    TEST = 'uat',
}

export enum AppStage {
    local = 'local',
    release = 'release',
}

export const isAppStageLocal = (): boolean => {
    return (process.env.REACT_APP_STAGE === 'release' ? false : true);
}

export const isServerEnvProd = (): boolean => {
    return (config.SERVER_ENV === ServerEnv.PROD);
}

export const isServerEnvQA = (): boolean => {
    return (config.SERVER_ENV === ServerEnv.QA);
}

export const isServerEnvTestMode = (): boolean => {
    return (config.SERVER_ENV === ServerEnv.TEST);
}

export const isServerEnvDev = (): boolean => {
    return (!config.SERVER_ENV ||
        DEV_DEBUG ||
        (config.SERVER_ENV === ServerEnv.DEV));
}