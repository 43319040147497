import axios, { AxiosRequestConfig } from "axios";
import { getApiUrl } from "platform-services";
import { setConfigModified } from "../../userProject/UserProjectHelp";
import { ConfigurationExchange } from "../../userProject/UserProjectTypes";

class ConfigurationApiService {
    api: string;
    config: AxiosRequestConfig;

    // Starts empty. Set whenever we save a NEW
    // configuration (post) or load an existing
    // one (get).
    savedConfigIds: Set<string>;

    constructor() {
        this.api = "CSA_API";

        this.config = {
            headers: {
                "Content-Type": "application/json-patch+json",
                accept: "text/plain",
            },
            withCredentials: true,
        };

        this.savedConfigIds = new Set<string>();
    }

    // Called by caller of saveConfigToProject
    // AFTER a save (post or put) succeeded.
    // Also called after initial load of
    // an existing config.
    recordConfigIdSaved(id: string) {

        // Add the id to our set.
        if (id) {
            this.savedConfigIds.add(id);

            // Since we either JUST saved,
            // or were JUST loaded, reset
            // our isModified flag.
            setConfigModified(false);
        }
    }

    // Can be called to determine IF a
    // given config (by id) has been 
    // successfully saved (at least once).
    hasConfigBeenSaved(id: string): boolean {
        return this.savedConfigIds.has(id);
    }

    getConfiguration(configId: string) {
        const url = `${getApiUrl(
            this.api
        )}/api/Configuration/${configId}`;
        return axios.get(url, this.config);
    }

    saveConfigToProject(data: ConfigurationExchange) {

        // url is the same, regardless of whether
        // this configuration is new, or has been
        // saved to the project before. 
        const url = `${getApiUrl(
            this.api
        )}/api/Configuration`;

        // If our id set contains the id of this
        // config, then it's been saved before.
        // If so, we'll do a put. If not, we'll
        // use the post.
        if (this.savedConfigIds.has(data.id)) {
            return axios.put(url, data, this.config);
        }
        else {
            return axios.post(url, data, this.config);
        }
    }

    getProjectItems(projectId: string) {
       const url = `${getApiUrl(
            this.api
        )}/api/ProjectItem/GetProjectItems/${projectId}`;

        return axios.get(url, this.config);
    }

}

const configurationApiService = new ConfigurationApiService();
export default configurationApiService;