/* eslint-disable react-hooks/rules-of-hooks */
import { CheckerImplementation, RegisterCheckerImpl } from "../../../implementation/ImplChecker";
import { PlatformMicro } from "../../PlatformConstants";
import { AF_Base, AFType, getNewAutoFix, addAutoFix } from "../../../util/CheckerAutoFix";
import {  ChassisProject , MicroChassis, DeviceType, ChassisModule, ChassisBaseUnit} from "../../../types/ProjectTypes";
import { getNewProjectLog, LogMsgLevel, StatusLogType, addLogMessage } from "../../../util/ProjectLog";
import { getMicroModulesupSizeCheck} from "../../../util/EngInfoHelp";

import {
  updateChassis,
} from "../../../implementation/ImplGeneral";
import {
  _microMakeChassisBU,
  microgetpowersupply,
	microCreatePowerSupply,
	microgetChassisTemplateForCat,
} from "./MicroGeneralImpl";
import {
	getChassisAndRackIndexById,
} from "../../../model/ChassisProject";
import { contentChanging, suspendUndoSnapshots } from "../../../util/UndoRedo";
import { isServerEnvProd } from "../../../util/ServerEnvironment";

const autofixCplxNoController = (
    project: ChassisProject,
    chassisID: string
  ): boolean => {
    const [chassis] = getChassisAndRackIndexById(project, chassisID);
    const microChassis  = chassis as MicroChassis
    if (microChassis) {
      contentChanging(project.content);
      suspendUndoSnapshots(true);
      // const snapshotsWereSuspended = suspendUndoSnapshots(false);
      const ioModulesPresent = microChassis.modules.filter(res=> res?.deviceType === DeviceType.IOExpansion)
      // First try to upsize the Slot 0 component.
    if (ioModulesPresent && microUpsizeSlot0ToSupportModCount(microChassis, ioModulesPresent.length)) {
      suspendUndoSnapshots(false);
      microChassis.isIoSeperate = true;
      updateChassis(microChassis)
   
      return true;
    }
    }
    return false;
  };

  export const microUpsizeSlot0ToSupportModCount = (chassis: MicroChassis, expansionModules: number): boolean => {

    const platform = chassis.platform;

    const info =  getMicroModulesupSizeCheck(platform, expansionModules);
    if(info ){

    const psCat = chassis.defaultPowerSupplyValue && microgetpowersupply(chassis.defaultPowerSupplyValue,info )
   
		if (psCat) {
      chassis.ps = microCreatePowerSupply(psCat);
    }

    const template = microgetChassisTemplateForCat(chassis.catNo, info);
    if (template) {
      chassis.pluginModules = new Array<ChassisModule>(template.slotGroups[0]);
      const bu: ChassisBaseUnit | undefined = _microMakeChassisBU(
        info,
        chassis.ps
      );
      chassis.bu = bu;
      if (chassis.bu) {
        chassis.bu.catNo = info;
      }

      const expansionIOmodules = chassis.modules.filter(
        (mod) => mod?.deviceType === DeviceType.IOExpansion
      );
      const ps = chassis.modules.filter(
        (mod) => mod?.deviceType === DeviceType.PS
      );
      chassis.modules = [
        ...chassis.pluginModules,
        ...expansionIOmodules.slice(0, 4),
        ...ps,
        ...expansionIOmodules.slice(4, expansionIOmodules.length),
      ];
    }

    return true;
    }
  
  
    return true;
}

  const _afCommHasTooManyModules = (project: ChassisProject, af: AF_Base): boolean => {
    // Call our helper to get the chassis and rack index
    const [targetChassis, idxRack] = getChassisAndRackIndexById(project, af.targetInstanceID);
    if (targetChassis == null || idxRack < 0) {
      alert('Unable to correct issue.');
      return true;
    }
  const getMicroChassis = targetChassis as MicroChassis
  const ioModulesPresent = getMicroChassis.modules.filter(res=> res?.deviceType === DeviceType.IOExpansion)
    // First try to upsize the Slot 0 component.
	if (ioModulesPresent && microUpsizeSlot0ToSupportModCount(getMicroChassis, ioModulesPresent.length)) {
		return true;
	}
    return true;
  }
  

const microExecuteAutoFix = (af: AF_Base, project: ChassisProject, callback: () => void): boolean => {
    let success = false;
    switch (af.type) {
        case AFType.No_Controller:
            success = autofixCplxNoController(project, af.targetInstanceID);
            break;
        case AFType.Snap_CommHasTooManyModules:
            success = _afCommHasTooManyModules(project, af);
            break;

        default:
            // If we are NOT Production...
            if (!isServerEnvProd())
                window.alert('AutoFix not yet implemented: ' + af.type);
            break;
    }

    if (success)
        callback();

    return success;
}

export const microDoGeneralCheck = (chassis: MicroChassis) => {
  const platform = chassis.platform;
	const template = chassis.bu && chassis.bu.catNo && microgetChassisTemplateForCat(chassis.catNo, chassis.bu.catNo);
     const maxModsSupported = template ? template?.slotGroups[1] : 0;
      // Get an array of non-FPD/Interconnect Cable modules to the right of the comm.
      const arrMods = chassis.modules.filter((mod, idx) =>mod?.deviceType === DeviceType.IOExpansion && idx > 0);
      if (arrMods.length > maxModsSupported && chassis.bu) {
          // Create a new log if the chassis does not have one..
          if (chassis.statusLog == null)
              chassis.statusLog = getNewProjectLog(StatusLogType.chassis);

          const af = getNewAutoFix(platform, chassis.id, AFType.Snap_CommHasTooManyModules);
          addAutoFix(af);
          const msg = `The currently selected controller does not support the number of Expansion I/O present.`;
          addLogMessage(
              chassis.statusLog,
              msg,
              LogMsgLevel.error,
              chassis,
              af.id);
      }
  


}

export const RegisterMicroCheckerImpl = () => {
    const microImpl: CheckerImplementation = {
        platform: PlatformMicro,
        doGeneralCheck: microDoGeneralCheck,
        doChassisPowerCheck: undefined,
        executeAutoFix: microExecuteAutoFix,
    };

    RegisterCheckerImpl(microImpl);
}

