import React from 'react';
import { getLayoutScaleInfo } from '../../implementation/ImplGeneral';
import { LogRender } from '../../types/Globals';
import { ActBtnInfo, LayoutActionType } from '../../types/LayoutActions';
import { DfltStageBaseScale } from '../../types/StageTypes';
import { logger } from '../../util/Logger';
import AddModActionButton from './AddModActionButton';
import ModeActionButton from './ModeActionButton';

export const establishActBtnScale = ( platform : string ) : number => {
    // It was 'requested' that no matter what the
    // platform's initial stage scale, the Action
    // Button visual SIZES should be consistent
    // across all platforms.
    const scalePlatform = getLayoutScaleInfo( platform );
    const scale = DfltStageBaseScale / scalePlatform.baseScale;
    return scale;
}

interface Props {
    actionType: LayoutActionType;
    btnInfo: ActBtnInfo[] | undefined;
    onClickBtn: (actBtnInfo: ActBtnInfo) => void;
}

const ActionButtons = (props: Props) => {

    // Sanity check that we're actually provided
    // with at least one btn info object. If not,
    // we should NOT have been included.
    if ((props.btnInfo === undefined) || (props.btnInfo.length < 1)) {
        throw new Error('Expected button info not found in ActionButtons!');
    }

    if (LogRender.ActButtons) {
        logger.logRender('Render ActionButtons');
    }

    if (props.btnInfo.length === 0)
        return null;

    let nextKey = 1;

    return (
        <>
            {props.btnInfo.map(info => {
                const ctrl =
                    (
                        info.action === LayoutActionType.AddModule
                    ?
                        <AddModActionButton
                            key={nextKey++}
                            btnInfo={info}
                            onClick={props.onClickBtn}
                        />
                    :
                        <ModeActionButton
                            key={nextKey++}
                            btnInfo={info}
                            onClick={props.onClickBtn}
                            />
                    )

                return ctrl;
            })}
        </>
    );
}

export default React.memo(ActionButtons);

        