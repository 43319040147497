import { EngInfoIOModule } from "../engData/EngineeringInfo";
import { queryIOModules } from "../implementation/ImplHardwareGeneralFn";
import { getIOModTypes } from "../model/EngineeringData";
import { hasIOPointTypeQuantity } from "../model/IOModule";
import { getDefaultIO } from "../model/ProductSelection";
import { IOModulePoints, IOTypeAndQty } from "../types/EngDataTypes";
import {
    IO_Bit,
    IOTypeInfo,
    IOTypeAndFeatureInfo,
    createIOTypeAndFeatureInfo,
    IO_Txt,
    IO_Id,
    IO_Mask,
} from "../types/IOModuleTypes";
import { LocAttributeInfo } from "../types/ProjectTypes";
import { getIOModuleEngInfo } from "./EngInfoHelp";


const _basicIOTypeSortPredicate = (a: IOTypeInfo, b: IOTypeInfo): number => {
    // 2024.7.10 If there are NOT basic AI/AO/DI/DO bits
    // set, return the type name comparison. If one has
    // basic AI/AO/DI/DO, that goes before the other. 
    const aBaseType = (a.value & IO_Mask.Point_BasicTypes) !== 0;
    const bBaseType = (b.value & IO_Mask.Point_BasicTypes) !== 0;
    if (!aBaseType && !bBaseType)
        return a.name.localeCompare(b.name);
    else if (aBaseType != bBaseType)
        return aBaseType ? 1 : -1;

    // Both have basic AI/AO/DI/DO. 
    // Safety goes AFTER non-safety.
    const aSafety = (a.value & IO_Bit.SafetyIO) !== 0;
    const bSafety = (b.value & IO_Bit.SafetyIO) !== 0;
    if (aSafety !== bSafety)
        return aSafety ? -1 : 1;

    // Analog goes before Digital.
    const aDigital = (a.value & IO_Bit.Digital) !== 0;
    const bDigital = (b.value & IO_Bit.Digital) !== 0;
    if (aDigital !== bDigital)
        return aDigital ? -1 : 1;

    // Lastly, Input goes before Output.
    return (a.value & IO_Bit.Input) !== 0 ? -1 : 1;
}

export const getBasicIOTypesFromSet = (mapTypes: Map<string, IOTypeAndQty>): IOTypeInfo[] => {
    // While the set contains unique types from what
    // we found in the I/O Modules, we might have entries
    // that are both input and output (combo modules) or
    // both Digital and Analog (Universal Modules). We need
    // to break these into individual DI/DO/AI/AO types.
    const basicTypes = new Array<IOTypeInfo>
    mapTypes.forEach((info, type) => {
        basicTypes.push({ name: type, value: info.typeID });
    });

    return Array.from(basicTypes).sort(_basicIOTypeSortPredicate);
}

let arrOrderedFeatures: string[] = [];
const _convertFeatureSetToArray = (setFeatures: Set<string>): string[] => {
    if (arrOrderedFeatures.length === 0) {
        // This is the displayed Order Of
        // Features that we know about.
        arrOrderedFeatures = [
            'IOFeature_DC', // = 'Current';
            'IOFeature_Diag', // = 'Diagnostic';
            'IOFeature_Fused', // = 'Electronically Fused';
            'IOFeature_HART', // = 'HART';
            'IOFeature_HSDC', // = 'High Speed Current';
            'IOFeature_Isol', // = 'Isolated';
            'IOFeature_SEV'  // = 'Voltage';
        ];
    }

    // Copy the ordered features and
    // the set of features passed in.
    const arrFeatures = [...arrOrderedFeatures];
    const srcFeatures = new Set(setFeatures);

    // Reverse iterate the ordered features.
    for (let ritr = arrFeatures.length - 1; ritr >= 0; --ritr) {
        const feature = arrFeatures[ritr];
        // If the set passed in does NOT
        // have it, remove it from the array.
        // If it DOES, remove it from the set.
        if (!srcFeatures.has(feature))
            arrFeatures.splice(ritr, 1);
        else
            srcFeatures.delete(feature);
    }

    // Add any 'unknown features' to the
    // end of the array.
    if (srcFeatures.size)
        arrFeatures.push(...Array.from(srcFeatures));

    return arrFeatures;
}

let arrOrderedTypes: string[] = [];
const _convertTypeSetToArray = (setTypes: Set<string>): string[] => {
    if (arrOrderedTypes.length === 0) {
        // This is the display Order Of
        // Types that we know about. 
        arrOrderedTypes = [
            'IOpoints_DI', // = 'Digital Input';
            'IOpoints_DO', // = 'Digital Output';
            'IOpoints_AI', // = 'Analog Input';
            'IOpoints_AO', // = 'Analog Output';
            'IOpoints_SDI', // = 'Safety Digital Input';
            'IOpoints_SDO', // = 'Safety Digital Output';
            'IOpoints_SAI', // = 'Safety Analog Input';
            'IOpoints_SAO', // = 'Safety Analog Output';
            'IOpoints_HSC', // = 'High Speed Counter';
            'IOpoints_PTO', // = 'PTO';
            'IOpoints_PWM', // = 'PWM';
            'IOpoints_RO', // = 'Relay Output';
            'IOpoints_SRO', // = 'Safety Relay Output';
            'IOpoints_RTD', // = 'RTD';
            'IOpoints_THERM', // = 'Thermocouple';
        ];
    }

    // Copy the ordered types and
    // the set of types passed in.
    const arrTypes = [...arrOrderedTypes];
    const srcTypes = new Set(setTypes);

    // Reverse iterate the ordered types.
    for (let ritr = arrTypes.length - 1; ritr >= 0; --ritr) {
        const type = arrTypes[ritr];
        // If the set passed in does NOT
        // have it, remove it from the array.
        // If it DOES, remove it from the set.
        if (!srcTypes.has(type))
            arrTypes.splice(ritr, 1);
        else
            srcTypes.delete(type);
    }

    // Add any 'unknown types' to the
    // end of the array.
    if (srcTypes.size)
        arrTypes.push(...Array.from(srcTypes));

    return arrTypes;
}

export const getIOModulesForLoc = (loc: LocAttributeInfo, type: string, feature = '', resultsRequired = false):
    [
        types: string[],
        features: string[],
        arrMod: EngInfoIOModule[],
        type: string,
        feature:string,
    ] =>
{
    const results: EngInfoIOModule[] = [];

    const setFeatures = new Set<string>();
    const setTypes = new Set<string>();
    let currFeature = feature;
    let currType = type;

    // Note: based on the Control Voltage selected,
    // the I/O modules will be sorted but NOT filtered
    // in the results from queryIOModules().
    const arrModInfoRaw = queryIOModules(loc);
    const arrLen = arrModInfoRaw.length;

    // While we need to continue (recurse)...
    let recurse = true;
    while (recurse) {
        for (let idx = 0; idx < arrLen; ++idx) {
            const info = arrModInfoRaw[idx];

            // Collect the types.
            info.pointType.typeData.forEach((type) => {
                setTypes.add(type.type);
            });            

            // If a type is set...
            if (currType) {
                // If the info does NOT have the type...
                if (info.pointType.typeData.some(x => x.type === currType) === false)
                    continue;

                // Only add features when the info has
                // has the current type (checked above).
                info.pointFeature.forEach((feature) => {
                    setFeatures.add(feature.type);
                }); 

                // If a feature is set...
                if (currFeature) {
                    // If the info does NOT have the feature...
                    if (info.pointFeature.some(x => x.type === currFeature) === false)
                        continue;
                }
           }

            results.push(info);
        }

        // Assume we're done.
        recurse = false;

        // If we need results, but do not have them.
        if (resultsRequired && results.length === 0) {
            // Set recurse to true when we are still
            // filtering on type or feature.
            recurse = (currFeature.length > 0 || currType.length > 0);
            if (recurse) {
                // If we have a feature, clear it.
                // Else if we have a type, clear it.
                if (currFeature) {
                    currFeature = '';
                }
                else {
                    // We should always have a type.
                    // However, the type is NOT valid.
                    // Clear the type and break.
                    currType = '';
                    setFeatures.clear();
                    break;
                }

                // May not be needed, but...
                setTypes.clear();
                setFeatures.clear();
            }
        }
    }

    const features = _convertFeatureSetToArray(setFeatures);
    const types = _convertTypeSetToArray(setTypes);
    return [types, features, results, currType, currFeature];
}

export const getAvailableTypesToFeaturesMap = (arrModInfo: EngInfoIOModule[]): Map<string, IOTypeAndFeatureInfo> => {
    const mapTypeToFeatures = new Map<string, IOTypeAndFeatureInfo>();
    arrModInfo.forEach((info) => {
        info.pointType.typeData.forEach((type) => {
            let infoTypeAndFeatures = mapTypeToFeatures.get(type.type);
            if (infoTypeAndFeatures == null) {
                infoTypeAndFeatures = createIOTypeAndFeatureInfo(type.type, getPointTypeOrFeatureMask(type.type));
                mapTypeToFeatures.set(type.type, infoTypeAndFeatures);
            }

            infoTypeAndFeatures.features.push(...info.pointFeature.map(x => { return { name: x.type, value: getPointTypeOrFeatureMask(x.type) } }));
        });
    });

    return mapTypeToFeatures;
}

// 2024.7.9 Bridge function to new text
// based Type and Feature.
export const getPointTypeOrFeatureMask = (type: string, feature = ''): number => {
    // Function takes a Type's or Feature's
    // I/O ID or I/O Display Text, and returns
    // an I/O Mask. Note: We can have types or
    // features that the App does NOT know about,
    // which will return Zero.
    let mask = 0;
    let target = (type.length === 0 ? feature : type);
    while (target.length > 0) {
        switch (target) {
            case IO_Txt.DC: // 'Current';
            case IO_Id.DC:
                mask |= IO_Mask.DC;
                break;
            case IO_Txt.HSDC: // 'High Speed Diff. Current';
            case IO_Id.HSDC:
                mask |= IO_Mask.HSDC;
                break;
            case IO_Txt.SEV: // 'Voltage';
            case IO_Id.SEV:
                mask |= IO_Mask.SEV;
                break;
            case IO_Txt.HART: // 'HART';
            case IO_Id.HART:
                mask |= IO_Mask.HART;
                break;
            case IO_Txt.Isol: // 'Isolated';
            case IO_Id.Isol:
                mask |= IO_Mask.Isol;
                break;
            case IO_Txt.Diag: // 'Diagnostic';
            case IO_Id.Diag:
                mask |= IO_Mask.Diag;
                break;
            case IO_Txt.Fused: // 'Electronically Fused';
            case IO_Id.Fused:
                mask |= IO_Mask.Fused;
                break;
            case IO_Txt.AI: // 'Analog Input';
            case IO_Id.AI:
                mask |= IO_Mask.AI;
                break;
            case IO_Txt.AO: // 'Analog Output';
            case IO_Id.AO:
                mask |= IO_Mask.AO;
                break;
            case IO_Txt.DI: // 'Digital Input';
            case IO_Id.DI:
                mask |= IO_Mask.DI;
                break;
            case IO_Txt.DO: // 'Digital Output';
            case IO_Id.DO:
                mask |= IO_Mask.DO;
                break;
            case IO_Txt.SAI: // 'Safety Analog In';
            case IO_Id.SAI:
                mask |= IO_Mask.SAI;
                break;
            case IO_Txt.SAO: // 'Safety Analog Out';
            case IO_Id.SAO:
                mask |= IO_Mask.SAO;
                break;
            case IO_Txt.SDI: // 'Safety Digital In';
            case IO_Id.SDI:
                mask |= IO_Mask.SDI;
                break;
            case IO_Txt.SDO: // 'Safety Digital Out';
            case IO_Id.SDO:
                mask |= IO_Mask.SDO;
                break;
            case IO_Txt.RO: // 'Relay Output';
            case IO_Id.RO:
                mask |= IO_Mask.RO;
                break;
            case IO_Txt.SRO: // 'Safety Relay Output';
            case IO_Id.SRO:
                mask |= IO_Mask.SRO;
                break;
            case IO_Txt.RTD: // 'RTD';
            case IO_Id.RTD:
                mask |= IO_Mask.RTD;
                break;
            case IO_Txt.Therm: // 'Thermocouple';
            case IO_Id.Therm:
                mask |= IO_Mask.Therm;
                break;
            case IO_Txt.HSC: // 'High Speed Counter';
            case IO_Id.HSC:
                mask |= IO_Mask.HSC;
                break;
            case IO_Txt.PTO: // 'PTO';
            case IO_Id.PTO:
                mask |= IO_Mask.PTO;
                break;
            case IO_Txt.PWM: // 'PWM';
            case IO_Id.PWM:
                mask |= IO_Mask.PWM;
                break;
            case 'Safety I/O': // Legacy Feature Value.
                mask |= IO_Bit.SafetyIO;
                break;
            default:
                break;
        }

        // Do we need to add the Feature mask...
        if (target !== feature)
            target = feature;
        else
            break;
    }

    return mask;
}

// 2024.7.9 Bridge function to new text
// based Type and Feature.
export const parseIOMaskToTypeID = (ioMask: number): string => {
    let idType: string | undefined = '';

    // Start with the one-off types, which are
    // not a safety type.
    if ((ioMask & IO_Mask.PWM) === IO_Mask.PWM)
        idType = IO_Id.PWM;
    else if ((ioMask & IO_Mask.PTO) === IO_Mask.PTO)
        idType = IO_Id.PTO;
    else if ((ioMask & IO_Mask.HSC) === IO_Mask.HSC)
        idType = IO_Id.HSC;
    else if ((ioMask & IO_Mask.Therm) === IO_Mask.Therm)
        idType = IO_Id.Therm;
    else if ((ioMask & IO_Mask.RTD) === IO_Mask.RTD)
        idType = IO_Id.RTD;
    else {
        // Now, Types with combination bits.
        const safety = (ioMask & IO_Bit.SafetyIO) != 0;

        if ((ioMask & IO_Mask.AI) === IO_Mask.AI)
            idType = (safety ? IO_Id.SAI : IO_Id.AI);
        else if ((ioMask & IO_Mask.AO) === IO_Mask.AO)
            idType = (safety ? IO_Id.SAO : IO_Id.AO);
        else if ((ioMask & IO_Mask.DI) === IO_Mask.DI)
            idType = (safety ? IO_Id.SDI : IO_Id.DI);
        else if ((ioMask & IO_Mask.DO) === IO_Mask.DO)
            idType = (safety ? IO_Id.SDO : IO_Id.DO);
        else if (ioMask & IO_Mask.RO)
            idType = (safety ? IO_Id.SRO : IO_Id.RO);
    }

    if (idType)
        return idType;

    return '';
}


// 2024.7.9 Bridge function to new text
// based Type and Feature.
export const parseIOMaskToFeatureID = (ioMask: number): string => {
    let idFeature: string | undefined = '';

    // There should only be one feature
    // bit set in the mask.
    if (ioMask & IO_Bit.AnalogCurrent)
        idFeature = IO_Id.DC;
    else if (ioMask & IO_Bit.HiSpdCurrent)
        idFeature = IO_Id.HSDC;
    else if (ioMask & IO_Bit.AnalogVoltage)
        idFeature = IO_Id.SEV;
    else if (ioMask & IO_Bit.HartIO)
        idFeature = IO_Id.HART;
    else if (ioMask & IO_Bit.Isolated)
        idFeature = IO_Id.Isol;
    else if (ioMask & IO_Bit.Diagnostic)
        idFeature = IO_Id.Diag;
    else if (ioMask & IO_Bit.ElectronicFused)
        idFeature = IO_Id.Fused;

    if (idFeature)
        return idFeature;

   return '';
}


// 2024.7.9 Bridge function to new text
// based Type and Feature.
export const parseIOMaskToTypeAndFeatureTxt = (ioMask: number): [type: string, feature: string] => {
    const type = parseIOMaskToTypeID(ioMask);
    const feature = parseIOMaskToFeatureID(ioMask);

    return [type, feature];
}

export const getDefaultIOModuleCatalog = (type: string, locAttrInfo: LocAttributeInfo): string | undefined => {
    // We should have the Guided Selection loaded before
    // any calls are made here! 
    // Note: ioDefault.catalog.match(/^\d/) will return TRUE
    // when the catalog starts with a NUMBER. In Prod Sel data
    // there are some inconsistencies for non exist catalogs.
    const defIOMods = getDefaultIO(locAttrInfo);
    const defMod = defIOMods.find(ioDefault => (ioDefault.type === type && ioDefault.catalog.match(/^\d/)));
    if (defMod)
        return defMod.catalog;

    // Dig deeper... Get an array of based on type and
    // the current Loc Attribute restrictions (ie Env.Rating). 
    const [, , arrMods, ioType,] = getIOModulesForLoc(locAttrInfo, type);
    if (arrMods.length > 0 && type === ioType)
        return arrMods[0].catNo;

    return undefined;
}

export const validIOExists = (locAttrInfo: LocAttributeInfo) => {
    // Get the supported types of the platform.
    const arrTypes = getIOModTypes(locAttrInfo.platform);
    const ioFound = arrTypes.some(type => getDefaultIOModuleCatalog(type.name, locAttrInfo) != null);
    return ioFound;
}

export const validatePointType = (type: string, infoMod: EngInfoIOModule | undefined): string => {
    if (infoMod == null)
        return '';

    if (infoMod.pointType.typeData.length === 0)
        return '';

    if (infoMod.pointType.typeData.some((t) => t.type === type))
        return type;

    // Take the first type we have...
    return infoMod.pointType.typeData[0].type;
}

export const validatePointFeature = (feature: string, infoMod: EngInfoIOModule | undefined): string => {
    if (infoMod == null)
        return '';

    if (infoMod.pointFeature.length === 0)
        return '';

    if (infoMod.pointFeature.some((f) => f.type === feature))
        return feature;

    // Features are not neccessary.
    // Call it empty...
    return '';
}

const _consolidatePointTypeArr = (arrPT: IOTypeAndQty[]): [reqPoints: IOTypeAndQty[], total: number] => {
    const mapTypeToQty = new Map<string, IOTypeAndQty>();

    // For each point type the user has 
    // requested points for...
    let total = 0;
    arrPT.forEach((type) => {
        // If we have user entered points
        if (type.qty > 0) {
            // Tally the total.
            total += type.qty;
            // Add the quantity to the type.
            const entry = mapTypeToQty.get(type.type);
            if (entry)
                entry.qty += type.qty;
            else
                mapTypeToQty.set(type.type, { ...type });
        }
    });

    // Flatten the map to an array and
    // return total points and an array
    // of I/O type/quantity.
    return [Array.from(mapTypeToQty.values()), total];
}

export const calcModQtyFromPoints = (platform: string, catalog: string, userPoints: IOTypeAndQty[]): number => {
    // Consolidate the users I/O point type
    // and point quantity into an array of
    // type/qty and the total qty.
    const [reqPoints, total] = _consolidatePointTypeArr(userPoints);
    const lenReqPts = reqPoints.length;
    // If we do not have any requested points...
    if (lenReqPts === 0 || total === 0)
        return 0;

    // Get the module information for the given
    // platform. If no module info or I/O points....
    const modInfo = getIOModuleEngInfo(platform, catalog);
    if (modInfo == null || hasIOPointTypeQuantity(modInfo.pointType) === false) {
        // Returning a negative number indicates
        // an error / invalid catalog.
        return -1;
    }

    // Start with the simple case - Self Cfg Points.
    if (modInfo.pointType.selfCfg) {
        // Return the total requested / self cfg.
        // On self cfg modules, every type has the
        // same amount of points.
        return Math.ceil(total / modInfo.pointType.selfCfg);
    }

    // We will walk each requested type and
    // get the number of modules needed for
    // each one. The largest number of modules
    // returned for a type will be the total 
    // needed (i.e. need 4 modules for AI, which
    // would account for the requested AO points).
    let modsNeeded = 0;
    reqPoints.forEach((reqType) => {
        const type = modInfo.pointType.typeData.find(x => x.type === reqType.type);
        if (type) {
            const reqMods = Math.ceil(reqType.qty / type.qty);
            if (reqMods > modsNeeded)
                modsNeeded = reqMods;
        }
    });

    // We should have something. If not,
    // the selected type(s) are not valid 
    // for the module.
    if (modsNeeded === 0) {
        // Returning a negative number indicates
        // an error / invalid catalog.
        return -1;
    }

    return modsNeeded;
}


export const getClosestIOModMatch = (
    loc: LocAttributeInfo,
    oldCatalog: string,
    type: string,
    feature: string,
    matchingModules: EngInfoIOModule[],
    useMatchingModArr = false): EngInfoIOModule | undefined => {

    let potentialReplacements: EngInfoIOModule[] = [];

    // We have 2 ways to find the closest match:
    // 1] Use the matchingModules passed in as
    //    the potentialReplacements.
    // 2] Query the Eng Data for potentialReplacements.
    //
    // Either way, matchingModules array will contain
    // all of the potentials for the caller to use.
    //
    // If we need to query the Eng Data...
    if (!useMatchingModArr) {
        // clear the array of potential matches and get
        // the old module's info. We are returning results
        // in this array so do NOT create a new instance!!!!!
        matchingModules.length = 0;

        // Query for potential matches.
        // The type and feature passed in should be valid,
        const [, , potentialReplacements, ,] = getIOModulesForLoc(loc, type, feature, false);

        if (potentialReplacements.length === 0)
            return undefined;

        // Copy the potentials into matchingModules.
        matchingModules.push(...potentialReplacements);
    }
    else {
        // We are using matchingModules as the potentials
        // array. Copy it into potentialReplacements.
        potentialReplacements.push(...matchingModules);
    }

    // Get the information for the old module
    const oldInfo = getIOModuleEngInfo(loc.platform, oldCatalog);
    if (oldInfo == null)
        return potentialReplacements[0];

    // Is the old catalog in the array...
    if (potentialReplacements.find(x => x.catNo === oldCatalog))
        return oldInfo;

    const typeMatches: EngInfoIOModule[] = [];
    let mostMatches = 0;
    let mostUnlike = 1000;
    potentialReplacements.forEach((info) => {
        const [matches, noMatches] = _compareTypeArrays(oldInfo, info);

        if (matches > mostMatches) {
            // Best match so far. Start a new array.
            mostMatches = matches;
            typeMatches.length = 0;
            typeMatches.push(info);

            // Set the most UNLIKE. Note: Matches 
            // takes precedence over unlike count.
            mostUnlike = noMatches;
        }
        else if (matches === mostMatches) {
            // We have the same matches. Are the
            // unlike types less than what we
            // currently have.
            if (noMatches < mostUnlike) {
                // This module is the best match. 
                // Start a new array.
                mostUnlike = noMatches;
                typeMatches.length = 0;
                typeMatches.push(info);
            }
            else if (noMatches === mostUnlike) {
                // This module has the same matching and unlike
                // counts as the module(s) already in our matches.
                // Add it to the array.
                typeMatches.push(info);
            }
        }
    });

    // If we have any best modules after the bitset comparison (we should)...
    if (typeMatches.length > 0) {
        // If we have just ONE module...
        if (typeMatches.length === 1)
            return typeMatches[0];

        // Set our potential replacements to the
        // best matches we found. This should make
        // short work of the point comparison (next).
        potentialReplacements = typeMatches;
    }

    // Next look at the points... Determine if we
    // are trying to match Input or Output points.
    const pointsMatches: EngInfoIOModule[] = [];
    const pointsGreaterThanOrg: EngInfoIOModule[] = [];
    const pointsSelfCfg: EngInfoIOModule[] = [];
    let pointsClosest: EngInfoIOModule | undefined = undefined;
    let closest = 10000;
    const oldPtCount = _determinePointCount(oldInfo.pointType);
    potentialReplacements.forEach((info) => {
        // If the replacement has selfCfg and it
        // has >= the number of points of the old mod...
        if (info.pointType.selfCfg > 0 && info.pointType.selfCfg >= oldPtCount)
            pointsSelfCfg.push(info);
        else {
            const ptCount = _determinePointCount(info.pointType);
            if (ptCount === oldPtCount)
                pointsMatches.push(info);
            else if (ptCount > oldPtCount)
                pointsGreaterThanOrg.push(info);
            else if (oldPtCount - ptCount < closest) {
                pointsClosest = info;
                closest = oldPtCount - ptCount;
            }
        }
    });

    if (pointsMatches.length > 0)
        return pointsMatches[0];
    else if (pointsGreaterThanOrg.length > 0)
        return pointsGreaterThanOrg[0]
    else if (pointsSelfCfg.length > 0)
        return pointsSelfCfg[0]

    return pointsClosest;
}


const _compareTypeArrays = (infoA: EngInfoIOModule, infoB: EngInfoIOModule): [matches: number, noMatch: number] => {
    let matches = 0;
    let noMatches = 0;

    // Check the Types.
    let lenB = infoB.pointType.typeData.length;
    infoA.pointType.typeData.forEach((a) => {
        for (let idx = 0; idx < lenB; ++idx) {
            const b = infoB.pointType.typeData[idx];
            if (b.type === a.type)
                matches++;
            else
                noMatches++;
        }
    });

    // Check the Features.
    lenB = infoB.pointFeature.length;
    infoA.pointFeature.forEach((a) => {
        for (let idx = 0; idx < lenB; ++idx) {
            const b = infoB.pointFeature[idx];
            if (b.type === a.type)
                matches++;
            else
                noMatches++;
        }
    });

    return [matches, noMatches];
}

const _determinePointCount = (point: IOModulePoints) => {
    if (point.selfCfg > 0)
        return point.selfCfg;

    let max = 0;
    point.typeData.forEach(x => {
        if (x.qty > max)
            max = x.qty;
    });

    return max;
}

export const getIOModulePointsForMask = (modInfo: EngInfoIOModule, ioMask: number): number => {
    const typeData = modInfo.pointType.typeData.find(data => data.typeID === ioMask)
    if (typeData) {
        return typeData.qty
    }
    return 0;
}

export const getIOModulePointsForType = (modInfo: EngInfoIOModule, ioType: string): number => {
    const typeData = modInfo.pointType.typeData.find(data => data.type === ioType)
    if (typeData) {
        return typeData.qty
    }
    return 0;
}
