import {
    EngInfoBasic,
    EngInfoIOModule,
    EngInfoMap,
    EngInfoPLUGINModule,
    EngInfoPowerSupply
} from "../engData/EngineeringInfo";
import { IOTypeInfo } from "./IOModuleTypes";
import { PSInputVoltage } from "./PowerTypes";
import {
    EnvRating,
    LoadStatus,
} from "./ProjectTypes";


export interface PSMatches {
    singles: EngInfoPowerSupply[];
    redundants: EngInfoPowerSupply[];
}

export type InVltgToPSMatches = Map<PSInputVoltage, PSMatches>;
export type PSMap = Map<EnvRating, InVltgToPSMatches>;
export type IOModuleMap = Map<string, EngInfoIOModule>;
export type PLUGINModuleMap = Map<string, EngInfoPLUGINModule>;
export interface EngInfoPackage {

    // Status tracking during package loading.
    status: LoadStatus;

    platform: string;

    // Map keyed by catalog number containing
    // engineering data as loaded from API.
    infoMap: EngInfoMap;

    // Design page of io type dropdown to enable all controller,ioexpansion and plugin for micro 800
    ioModInfoMicro: IOModuleMap;

    // Map keyed by catalog number. Values
    // are pre-cast to EngInfoIOModule type.
    ioModInfo: IOModuleMap;

    //Used to list all plugin and io
    pluginModInfo: IOModuleMap;

    ioModPlugin:PLUGINModuleMap;

    // Precollected and sorted I/O module
    // types. Used in design phase.
    ioModTypes: IOTypeInfo[];

    // Used only where chassis-based power
    // supplies are applicable (like CLX).
    // Map is two level, keyed first by env
    // rating and then by PS input voltage.
    mappedPS: PSMap;

    // Used only if/as needed for a given
    // platform.
    extraData?: object;
}


export const makeEmptyPSMatches = (): PSMatches => {
    return {
        singles: new Array<EngInfoPowerSupply>(),
        redundants: new Array<EngInfoPowerSupply>()
    }
}

export const makeEmptyEngDataPackage = (platform: string): EngInfoPackage => {
    return {
        status: LoadStatus.Startup,
        platform: platform,
        infoMap: new Map<string, EngInfoBasic>(),
        pluginModInfo: new Map<string, EngInfoIOModule>(),
        ioModInfo: new Map<string, EngInfoIOModule>(),
        ioModPlugin:new Map<string, EngInfoPLUGINModule>(),
        ioModInfoMicro: new Map<string, EngInfoIOModule>(),
        ioModTypes: new Array<IOTypeInfo>(),

        mappedPS: new Map<EnvRating, InVltgToPSMatches>(),
    }
}

// Used for parsing Engineering Data strings
// Cases:
//  Catalog always is first.
//  Quantity follows colon ':'
//  Label/Display text follows pipe '|'
// Examples:
//  "cat;cat;etc" => {catalog: cat, count: 1, text: undefined}
//  "cat:2;cat:2;etc" => {catalog: cat, count: 2, text: undefined}
//  "cat:2|display;cat|display:2;etc" => {catalog: cat, count: 2, text: display}
export interface EngDataStrParseData {
    catalog: string;
    count: number;
    text?: string;
}

export interface IOTypeAndQty {
    type: string;
    typeID: number;
    qty: number;
}

export interface IOModulePoints {
    selfCfg: number;
    typeData: IOTypeAndQty[];
}