import {
    EngInfoComponent,
    EngInfoFPDModule,
} from "../engData/EngineeringInfo";
import { Chassis, ChassisModule, EnvRating, NO_SLOT, DeviceType } from "../types/ProjectTypes";
import { getEngineeringInfoFor, getPowerBreakdown } from "../model/ChassisProject";
import { addRequiredAccys } from "./AccessoriesHelp";
import { logger } from "./Logger";
import { addPowerBreakdown, getEmptyPowerBreakdown } from "./PowerHelp";
import { PowerBreakdown, SAPwrVltg } from "../types/PowerTypes";
import { createModuleFor } from "../platforms/common/Common";


export type SAPwrVltgs = Set<SAPwrVltg> | undefined;

// Masks of all SA Vltg bits.
export const enum SAVltgMask {
    None = 0x00,
    VDC24 = 0x01,
    VAC120 = 0x02,
    VAC240 = 0x04,
    DC = SAVltgMask.VDC24,
    AC = SAVltgMask.VAC120 | SAVltgMask.VAC240
}

export const isRecognizedSAPwrVltg = (v: string): boolean => {
    switch (v as SAPwrVltg) {
        case SAPwrVltg.VDC24:
        case SAPwrVltg.VAC120:
        case SAPwrVltg.VAC240:
            return true;

        default:
            return false;
    }
}

export const doSAPwrVltgSetsMatch = (s1: SAPwrVltgs, s2: SAPwrVltgs): boolean => {
    if (s1 && s2 && (s1.size > 0)) {
        if (s1.size === s2.size) {
            let match = true;
            s1.forEach(s1Entry => {
                if (!s2.has(s1Entry)) {
                    match = false;
                }
            });
            return match;
        }
        return false;
    }
    else {
        throw new Error('Unexpected ERROR in doSAPwrVltgSetsMatch!');
    }
}

export const getVltgSetAsMask= (vltgs: Set<SAPwrVltg>): SAVltgMask => {
    let asNum = 0;
    if (vltgs.has(SAPwrVltg.VDC24)) asNum |= SAVltgMask.VDC24;
    if (vltgs.has(SAPwrVltg.VAC120)) asNum |= SAVltgMask.VAC120;
    if (vltgs.has(SAPwrVltg.VAC240)) asNum |= SAVltgMask.VAC240;
    return asNum;
}

export const getSAPwrVoltagesFrom = (dataVal: string | undefined): SAPwrVltgs => {

    // If we were given anything to read from...
    if (dataVal) {

        // Split whatever we got at commas.
        //const vltgVals = dataVal.split(',');
        const vltgVals = dataVal.split(/[;,]/g);

        // If we have any resulting values...
        if (vltgVals.length > 0) {

            // Create a new set of SAPwrVltg enums
            const vSet = new Set<SAPwrVltg>();

            // For each val we got...
            vltgVals.forEach(val => {

                // Trim any surrounding white space.
                val = val.trim();

                // Check to make sure its a valid
                // voltage identifier. If so,
                if (isRecognizedSAPwrVltg(val)) {

                    // Add its enum to our set.
                    vSet.add(val as SAPwrVltg);
                }
                else {

                    // Not valid. Log the error.
                    logger.error('Ignoring unrecognized SA voltage: ' + val);
                }
            });

            // If there's anything in the set, return it.
            if (vSet.size > 0) {
                return vSet;
            }
        }
        // Log a warning that we DID have data, but
        // did NOT find any valid voltages in it.
        logger.warn('No SA voltages found in: ' + dataVal);
    }

    // Return undefined, indicating we either didn't
    // have any data to read from, or got bad data.
    // The former will be the norm for controllers
    // and comm modules for platforms that don't
    // care about SA power.
    return undefined;
}


export type PlatformFPDMap = Map<EnvRating, EngInfoFPDModule> | undefined;
export interface GetFPDMapCallback {
    (): PlatformFPDMap;
}


const _platformFPDs = new Map<string, PlatformFPDMap>();


export const getFPDForChassis = (chassis: Chassis, getMapCallback: GetFPDMapCallback | undefined):
    EngInfoFPDModule | undefined => {

    // fpd info starts undefined.
    let fpdMap: PlatformFPDMap = undefined;

    // If we already have an entry in our 
    // map of platforms-to-FPDMap...
    if (_platformFPDs.has(chassis.platform)) {

        // Use the current entry, which MIGHT be
        // undefined if the platform doesn't use them.
        fpdMap = _platformFPDs.get(chassis.platform);
    }
    else {
        // No entry yet.
        // If the platform supplies a get
        // function for the FPD map...
        if (getMapCallback) {

            // Set to whatever that gives us, undefined
            // or not, and add the new entry to our map.
            fpdMap = getMapCallback();
            _platformFPDs.set(chassis.platform, fpdMap);
        }
        else {
            // No function provided, meaning the
            // platform doesn't use FPDs. Add an
            // entry for the platform to our map
            // with undefined as a value.
            _platformFPDs.set(chassis.platform, undefined);
        }
    }

    // We may or may not have a map
    // at this point. If we do...
    if (fpdMap) {

        // Get eng info for the chassis.
        const chasEngInfo = getEngineeringInfoFor(chassis);

        // We SHOULD be able to. If we can...
        if (chasEngInfo) {

            // Return whatever it has for its env rating.
            // If there isn't a map entry for the given
            // rating, we end up returning undefined.
            return fpdMap.get(chasEngInfo.envInfo.rating);
        }
        else {
            // Unexpected.
            throw new Error('Unexpected error in getFPDForChassis!');
        }
    }
    // No FPD for this chassis.
    return undefined;
}

export const removeAllFPDs = (chassis: Chassis): boolean => {
    let anyRemoved = false;
    if (chassis.layout.numFPDs > 0) {
        for (let idx = chassis.modules.length - 1; idx > 0; idx--) {
            const mod = chassis.modules[idx];
            if (mod && mod.isFPD) {
                mod.parent = undefined;
                chassis.modules.splice(idx, 1);
                anyRemoved = true;
            }
        }
        chassis.layout.numFPDs = 0;
    }
    return anyRemoved;
}

// Sets .slotIdx and .slotID props
// for all modules in the chassis.
// NOTE: FPD Modules and Interconnect 
// Cables get -1 for slotID.
export const assignModuleSlotLocations = (
    chassis: Chassis,
) => {

    // ID starts at 0.
    let nextID = 0;

    // For each module...
    for (let idx = 0; idx < chassis.modules.length; idx++) {

        // Get the module.
        const mod = chassis.modules[idx];

        // If we have a module...
        if (mod) {

            // ALL modules get their .slotIdx prop set
            // to match their position in the chassis.
            mod.slotIdx = idx;

            // If FPD or Interconnect Cable...
            if (mod.isFPD || mod.isInterconnect) {
                // .slotID indicates 'none'
                mod.slotID = NO_SLOT;
            }
            else {
                // Not FPD.
                // .slotID is next avail, then
                // bump next for next module.
                mod.slotID = nextID;
                nextID++;
            }
        }
        else {
            // Empty module. We shouldn't encounter
            // this for snap-type chassis, but we may
            // have been called for a different type.
            // Regardless, we'll just bump the next
            // slot ID for empties.
            nextID += 1;
        }
    }
}

const _getAvailSAPwrVltgsFrom = (supplier: EngInfoComponent | undefined): Set<SAPwrVltg> => {
    if (supplier && supplier.saPwrSupplier) {
        return supplier.getAvailableSAPwrVltgs();
    }
    throw new Error('Missing or invalid SA power supplier in _getAvailSAPwrVltgsFrom!');
}

export const getVltgSetIntersection = (avails: Set<SAPwrVltg>, used: Set<SAPwrVltg>): Set<SAPwrVltg> => {
    const intersect = new Set<SAPwrVltg>();
    used.forEach(usedVltg => {
        if (avails.has(usedVltg)) {
            intersect.add(usedVltg);
        }
    });
    return intersect;
}

export const getUsableSAPwrVltgs = (mod: ChassisModule | undefined): SAPwrVltgs | undefined => {
    if (mod) {
        const modInfo = getEngineeringInfoFor(mod);
        if (modInfo) {
            if (modInfo.saPwrConsumer) {
                return modInfo.getSAPwrVltgsUsed();
            }
            else {
                return undefined;
            }
        }
        else {
            logger.error('ERROR: Failed to get Eng Info for: ' + mod.catNo);
            return undefined;
        }
    }
    throw new Error('Unexpected ERROR in _getUsableSAPwrVltgs!');
}

export const insertRequiredFPDs = (chassis: Chassis, fpdInfo: EngInfoFPDModule) => {

    // If we just have a Controller/Adapter...
    // Note: previously an assumption was made
    // that a Adpt/Ctrl could always supply SA.
    // However, some safety ctrls are only 24V SA.
    if (chassis.modules.length <= 1) {
        return;
    }

    // Get available voltages from the FPD info provided.
    const fpdAvailVltgs = _getAvailSAPwrVltgsFrom(fpdInfo);

    // Sanity check. Any FPD SHOULD have at least
    // one avialable SA power voltage it can use.
    if (fpdAvailVltgs.size === 0) {
        throw new Error('ERROR: insertRequiredFPDs given FPD with NO available voltages!');
    }

    // Our first slot module (if there is one) will be our
    // first 'source' of SA power. Get the left-slot module.
    const leftSlotMod = chassis.modules[0];

    // Determine which voltages we'll start with
    // as available, using the left slot module if
    // we have one, and our FPD info if we don't.
    let availVltgs = leftSlotMod
        ? _getAvailSAPwrVltgsFrom(getEngineeringInfoFor(leftSlotMod))
        : new Set(fpdAvailVltgs);

    // Sanity Check that we have voltage(s) to
    // start with. If not, log an error and return.
    if (availVltgs.size === 0) {
        logger.error('NO SA pwr voltages to start with in insertRequiredFPDs!');
        return;
    }

    for (let slot = 1; slot < chassis.modules.length; slot++) {

        // Call a helper to get SA voltages usable by this
        // module IF it is an SA power consumer. If not, the
        // return we get back will be undefined. 
        const consumerVltgs = getUsableSAPwrVltgs(chassis.modules[slot]);

        // If we DO get any consumable voltages...
        if (consumerVltgs) {

            // Reset our available set to be the intersection of
            // what we HAD available, and what this module can use.
            availVltgs = getVltgSetIntersection(availVltgs, consumerVltgs);

            // If there is no intersection, it means that
            // it's not possible to supply the module with
            // what SA power we can provide up until this 
            // point. If that's the case...
            if (availVltgs.size === 0) {

                // We'll be adding an FPD module, but BEFORE we
                // do that, set our new avialable vltgs to be the
                // intersection of what a new FPD would have allow
                // and what this consumer can use.
                availVltgs = getVltgSetIntersection(fpdAvailVltgs, consumerVltgs);

                // We should ALWAYS have at least one
                // voltage in our avail set here. If so...
                if (availVltgs.size > 0) {

                    // Create a new FPD module.
                    const fpdMod = createModuleFor(fpdInfo);

                    // Insert it into the chassis at the current
                    // slot. That'll place it just in front of
                    // the SA consumer that needed a new voltage.
                    fpdMod.parent = chassis;
                    chassis.modules.splice(slot, 0, fpdMod);

                    // Bump the FPD count on our chassis.
                    chassis.layout.numFPDs += 1;

                    // Add any accys the FPD requires, which will
                    // generally include a wiring accy (trm block).
                    addRequiredAccys(fpdMod);

                    // Advance the slot by 1 so that our next
                    // iteration is for the module to the right
                    // of the SA consumer we just looked at after
                    // the insert of the new FPD.
                    slot += 1;
                }
                else {
                    // Unexpected.
                    throw new Error('Unexpected ERROR in insertRequiredFPDs!');
                }
            }
        }
    }

    assignModuleSlotLocations(chassis);
}

export const getSAPowerDetails = (chassis: Chassis, module: ChassisModule): [supplied: PowerBreakdown, consumed: PowerBreakdown] => {
    const platform = chassis.platform;

    let supplier = false;
    let slot0Supplier = false;
    switch (module.deviceType) {
        case DeviceType.CommModule:
        case DeviceType.Controller:
            slot0Supplier = true;
            supplier = true;
            break;
       case DeviceType.FPD:
            supplier = true;
            break;
    }

    let supplied = getEmptyPowerBreakdown();
    let consumed = getEmptyPowerBreakdown();
    const consumedMod = getEmptyPowerBreakdown();

    if (supplier) {
        supplied = getPowerBreakdown(platform, module.catNo);

        // Get the index in the module array - the
        // module's 'slotLocation' is NOT neccessarily
        // its index in the array.
        const idxSupplier = chassis.modules.findIndex(x => x === module);
        if (idxSupplier < 0)
            throw new Error('getSAPowerDetails(): module not found.');

        // Walk the modules AFTER the supplier.
        let fpdEncountered = false;
        const lenModules = chassis.modules.length;
        for (let idx = idxSupplier + 1; idx < lenModules; ++idx) {
            const mod = chassis.modules[idx];
            if (mod == null)  
                break;

            fpdEncountered = (fpdEncountered || mod.isFPD);
            if (fpdEncountered) {
                // If we are NOT a slot 0 supplier (mod is an FPD)...
                if (!slot0Supplier) {
                    // we're done.
                    break;
                }
                else {
                    if (consumedMod.modPower === 0)
                        consumedMod.modPower = consumed.modPower;

                    // Start tallying on consumedMod. Note: we
                    // only care about mod power here. FPD's do
                    // NOT consume/supply MOD power.
                    addPowerBreakdown(consumedMod, getPowerBreakdown(platform, mod.catNo), true);
                }
            }
            else {
                // If it's not an FPD, it's a consumer.
                // Add it's power to our consumed power.
                addPowerBreakdown(consumed, getPowerBreakdown(platform, mod.catNo), true);
            }
        }

        // Update our final consumed power. If we
        // had a slot 0 supplier, set the Mod Pwr
        // consumed to the total tallied on consumedMod.
        // If not a slot 0 supplier (i.e. an FPD),
        // clear the Mod Pwr consumed since an FPD
        // does NOT supply any.
        if (slot0Supplier) {
            if (fpdEncountered)
                consumed.modPower = consumedMod.modPower;
        }
        else if (supplied.modPower === 0) {
            // If the module is NOT supplying
            // MOD Power, set consumed MOD to 0.
            consumed.modPower = 0;
        }
    }
    else {
        // We have a consumer...
        consumed = getPowerBreakdown(platform, module.catNo);
    }

    return [supplied, consumed];
}