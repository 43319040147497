import React from 'react';
import './FloatingDetails.scss';
import { DetailItem } from '../../model/DeviceDetail';


interface Props {
    item: DetailItem;
}

const DetailsItem = (props: Props) => {
    const isDetailsNA = props.item.name==='N/A';
    if(isDetailsNA){
        return(<span className='details-item-label'>
                {props.item.name}
                </span>);
        }
    return (
        <>
            <span className='details-item-label'>
                {props.item.name + ': '}
            </span>
            <span className='details-item-value'>
                {props.item.value}
            </span>
        </>
    );

}

export default DetailsItem;

