import { RegisterFlexHAEngDataImpl } from "./FlexHAEngDataImpl";
import { RegisterFlexHAHWGenImpl } from "./FlexHAHardwareImpl";
import { RegisterFlexHAGeneralImpl } from "./FlexHAGeneralImpl";
import { RegisterFlexHACheckerImpl } from "./FlexHAChecker";

export const initFlexHAPlatform = (): boolean => {
	RegisterFlexHAHWGenImpl();
	RegisterFlexHAGeneralImpl();
	RegisterFlexHACheckerImpl();
	RegisterFlexHAEngDataImpl();

	return true;
}