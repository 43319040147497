import React from 'react';
import './FloatingDetails.scss';
import '../../styles/Meteor.scss'
import { SelectableDevice } from '../../types/ProjectTypes';
import { Button,Box } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { flexHAIsSAPowerSupply } from '../../platforms/flexHA/model/FlexHAChassis';


interface Props {
    device: SelectableDevice;
    ps: boolean;
    onClickPowerSettings?: () => void;
}

const DetailsDeviceProduct = (props: Props) => {

    const disablePSEdit = flexHAIsSAPowerSupply( props.device );

    return (
        <div className='floating-details-device-product'>
            <Box
            sx={{
               display: 'flex',
               direction:'row',
               gap: 4

            }}>
            <span className='a-type__title5'>
                {props.device.catNo}
            </span>

            { props.ps && !disablePSEdit && <Button
                disableRipple
                variant={'text'}
                startIcon={<EditOutlinedIcon />}
                onClick={ props.onClickPowerSettings }
                sx={{
                    textTransform: 'none',
                    justifyContent: 'left',
                    padding: '4px 4px'
                }}
            >
                Edit Power Supply Settings
            </Button>}
            </Box> 
            <span className='floating-details-device-desc'>
                {props.device.description}
            </span>
        </div>
    );
}

export default DetailsDeviceProduct;
