import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import reportWebVitals from './reportWebVitals';
import { UseStrictModeForDev } from './types/Globals';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from './redux/store';
import { CssBaseline, ThemeProvider } from '@mui/material';
import Theme from './theme/Theme';
import { psClientCfg } from './util/PlatformServicesConfig';
import { AuthProvider, FeatureFlagProvider } from "platform-services";
import AppView from './appLayout/AppView';
//import { logger } from './util/Logger';


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

//logger.warn('auth: CSA index.tsx');

if (UseStrictModeForDev) {
    root.render(
        <React.StrictMode>
            <AuthProvider clientConfig={psClientCfg}>
                <ReduxProvider store={store}>
                    <ThemeProvider theme={Theme}>
                        <FeatureFlagProvider value={window.featureFlags} >
                        <CssBaseline enableColorScheme />
                        <AppView />
                        </FeatureFlagProvider>
                    </ThemeProvider>
                </ReduxProvider>
            </AuthProvider>
        </React.StrictMode>
    );
}
else {
    root.render(
        <>
            <AuthProvider clientConfig={psClientCfg}>
                <ReduxProvider store={store}>
                    <ThemeProvider theme={Theme}>
                    <FeatureFlagProvider value={window.featureFlags} >
                        <CssBaseline enableColorScheme />
                        <AppView />
                        </FeatureFlagProvider>
                    </ThemeProvider>
                </ReduxProvider>
            </AuthProvider>
        </>
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


