import React, { useCallback, useState } from 'react';
import './ProjectTree.scss';
import { Chassis, ChassisModule, SelectableDevice } from '../types/ProjectTypes';
import { getStdSlotIdTagText } from '../model/ChassisProject';
import ProjTreeChassisHdr from './ProjTreeChassisHdr';
import ChassisSlotChild from './ChassisSlotChild';
import { ProjTreeChassisInitExpanded } from '../types/Globals';
import {  getSlotContent } from '../implementation/ImplGeneral';
//import { logger } from '../util/Logger';
//import { LogRender } from '../types/Globals';

export interface SlotContent {
    slotNum: number;
    toSlotNum: number;
    module: ChassisModule | undefined;
    selected: boolean;

    spSlotId?: string;
    spLabel?: string;
}


interface Props {
    chassis: Chassis;
    onItemClick: (chassis: Chassis, device: SelectableDevice | undefined) => void;
    contentChanged: () => void;
}


const ProjectTreeChassis = (props: Props) => {

    const [expanded, setExpanded] = useState(ProjTreeChassisInitExpanded);

    const changeExp = useCallback(() => {
        setExpanded(!expanded);
    }, [expanded]);

    const slotContent = getSlotContent(props.chassis);

    // Note: ProjectTreeChassis components ALL get re-rendered and
    // time a selection is made. (See memoization note at bottom).
    // As such, render logging here just adds extra noise to our
    // console. Also, since ALL of our children ARE memoized, our
    // own RE-renders shouldn't be any sort of performance concern.
    //if (LogRender.ProjTree) {
    //    logger.logRender('ProjectTreeChassis: ' + props.chassis.name);
    //}

    let nextKey = 1;

    const renderSlotChild = (slotInfo: SlotContent) => {
        const slotId = slotInfo.spSlotId
            ? slotInfo.spSlotId
            : getStdSlotIdTagText(props.chassis,
                slotInfo.slotNum, slotInfo.toSlotNum);

        const altLabel = slotInfo.spLabel
            ? slotInfo.spLabel
            : undefined;

        return (
            <ChassisSlotChild
                key={nextKey++}
                chassis={props.chassis}
                slotId={slotId}
                device={slotInfo.module}
                showSelected={slotInfo.selected}
                slotRange={(slotInfo.toSlotNum > slotInfo.slotNum)}
                onItemClick={props.onItemClick}
                altLabel={altLabel}
            />
        );
    }

    const renderChildren = () => {
        return (
            <div className="proj-tree-chassis-children">
                {props.chassis.ps
                    ? <ChassisSlotChild
                        key={nextKey++}
                        chassis={props.chassis}
                        slotId={'ps:'}
                        device={props.chassis.ps}
                        showSelected={props.chassis.ps.selected}
                        slotRange={false}
                        onItemClick={props.onItemClick}
                    />
                    : null
                }
                {slotContent.map(slotInfo => {
                    return renderSlotChild(slotInfo);
                }) }
            </div>
        );
    }

    return (
        <div className="proj-tree-chassis">
            <ProjTreeChassisHdr
                chassis={props.chassis}
                showSelected={props.chassis.selected}
                expanded={expanded}
                changeExp={changeExp}
                onItemClick={props.onItemClick}
                contentChanged={props.contentChanged}
            />
            {expanded ? renderChildren() : null}
        </div>
    );
}

export default ProjectTreeChassis;

// Note: We DON'T memo-ize ourselves. If we did so, without
// doing anything else, we'd end up NOT updating to show
// what's actually selected. For clicks in the tree, we COULD
// add a middle-man function to pass down to our children via
// their onItemClick props. If we did that, setting it up
// as a useCallback (that didn't have any changing dependencies),
// we could then set a local state to get OURSELVES to re-render.
// However, that STILL would not cover cases where selections
// were made in the layout itself. Overall, it's not worth
// the bother to cover all cases. Without the memoization
// on ourselves, ALL ProjectTreeChassis components WILL get
// re-rendered EVERY time a selection is made. HOWEVER, since
// our CHILD components are themselves all memo-ized, our own
// RE-rendering is extremely lightweight.
// export default React.memo(ProjectTreeChassis);