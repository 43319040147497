import React, { useState, useCallback, useRef, useEffect } from 'react';
import '../styles/AppLayout.scss';
import ChassisSettings from './ChassisSettings';
import {
    ProjectSetting,
    SettingOption
} from '../types/SettingsTypes';
import {
    changeProjectSetting,
} from '../settings/SettingsHelp';
import ChassisSelection from './ChassisSelection';
import CLXImage from '../platforms/clx/images/1756ChassisPic.png';
import { ChassisSummary } from './ChassisSummary';
import { runChecker } from '../util/Checker';
import { ProjectViewType } from '../types/ViewTypes';
import { runCommCalcs } from '../model/CommDetails';
import { logger } from '../util/Logger';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { useCommInfo } from '../context/SelectionInfoContext';
import { currentView, setCurrentView } from '../redux/slices/ViewSlice';
//import { authStatus, startupDetails } from '../redux/slices/StartupInfoSlice';
import { startupDetails } from '../redux/slices/StartupInfoSlice';
import { LogRender } from '../types/Globals';
import { ChassisProject } from '../types/ProjectTypes';
import { createProject } from '../model/ChassisProject';
import { makeChassisProjFromStartupDtls } from '../util/AppStartupHelp';
import { displayAlertMsg } from '../util/MessageHelp';
import { StatusLevel } from '../types/MessageTypes';
import { setConfigModified } from '../userProject/UserProjectHelp';
import { persistToLocalStorage } from '../userProject/LocalPersist';
import LoadingView from './LoadingView';
import { getNormalizedNavPath } from '../authentication/util/LocationSaveHelp';
//import { useNavigate } from 'react-router';
//import { AuthStatus } from '../authentication/util/AuthTypes';
import {
    usePSAppSelector,
    authStatus,
    AuthStatus
} from "platform-services";
import { RegisterLayoutStage } from '../util/LayoutHelp';
import { deleteLocationAttrInfo, getAvailableLocAttrInfoIDs } from '../model/LocAttributeInfo';


interface Props {
    onFirstVScroll: () => void;
    onConfigEstablished: (cfg: ChassisProject) => void;
}

const ChassisProjView = (props: Props) => {
    const onCfgEstablished = props.onConfigEstablished;

    const { setCommInfo } = useCommInfo();
    const authStat = usePSAppSelector(authStatus);
    const startupDtls = useAppSelector(startupDetails);
    const currentViewType = useAppSelector(currentView);
    const dispatch = useAppDispatch();
    //const navigate = useNavigate();
    const waitingForLogin = (authStat === AuthStatus.NotLoggedIn);
    const noUserProject = (startupDtls && !startupDtls.projectGuid);

    // Render control
    const [/*renderCount*/, setRenderCount] = useState(0);

    const project = useRef<ChassisProject | undefined>(undefined);

    const contentChanged = useCallback(() => {
        // NOTE: Code that actually makes any changes to
        // project content is now EXPECTED to call one
        // of the undo/redo helper functions (contentChanging
        // or chassisChanging) BEFORE making the change(s).

        if (project.current) {
            runChecker(project.current);

            const [calcResults,] = runCommCalcs(project.current);
            //commsInfo.current = calcResults;
            setCommInfo(calcResults)

            persistToLocalStorage(project.current);

            // Force a re-render
            setRenderCount(renderCount => renderCount + 1);
        }
        else {
            throw new Error('ERROR: contentChanged with no project!');
        }
    }, [setCommInfo]);

    useEffect(() => {
        // We need to wait for all startup details to be
        // available. If so, and we DON'T have a project yet...
        //if (startupDtls && startupDtls.userProj && !project.current) {
        if (startupDtls && !project.current) {

            logger.logStartup('Startup: Proj view needs project.')

            RegisterLayoutStage(startupDtls.platform);

            // If we're RELOADING (editing) a
            // previously saved configuration...
            if (startupDtls.configInfo) {

                logger.logStartup('Startup: rehydrating from saved configuration.');

                // Call our rehydrate helper to
                // do that for us.
                project.current =
                    makeChassisProjFromStartupDtls(startupDtls);

                // If successful...
                if (project.current) {

                    // Set our modified flag. If the configInfo
                    // we loaded from was sourced from localStorage,
                    // we want to start modified, otherwise not.
                    setConfigModified(startupDtls.configFromLocalStg);

                    // Land on our layout page if we actually
                    // had any racks. Otherwise stay on design.
                    if (project.current.content.racks.length > 0) {
                        dispatch(setCurrentView(ProjectViewType.Layout));
                    }

                    // The config is 'version 0' (undefined)...
                    if (!project.current.config.version) {
                        // Remove any un-needed locations. There was
                        // a benign location being added during startup, 
                        // which can be removed. (note: issue was corrected)
                        const currLocID = project.current.config.currLocAttrID;
                        const arrLoc = getAvailableLocAttrInfoIDs();
                        if (arrLoc.length > 1 && arrLoc.some(x => x === currLocID)) {
                            // Remove any locations that are NOT the current one.
                            arrLoc.forEach((idLoc) => {
                                if (idLoc !== currLocID)
                                    deleteLocationAttrInfo(idLoc);
                            });
                        }
                    }

                    // Call content changed to run comm calcs,
                    // checker, etc. 
                    contentChanged();
                }
                else {
                    displayAlertMsg('An ERROR was encountered attempting to load the configuration.',
                        StatusLevel.Error, 'Project Load FAILED');
                }
            }
            else {

                // Otherwise, we'll create a NEW project using
                // the primary platform provided in startup dtls.
                logger.logStartup('Startup: creating NEW project for: ' +
                    startupDtls.platform);
                project.current = createProject(startupDtls);
                if (project.current) {

                    setConfigModified(true);

                    // Call content changed to run comm calcs,
                    // checker, etc. 
                    contentChanged();
                }
                else {
                    throw new Error('createProject FAILED for platform: ' + startupDtls.platform);
                }
            }

            // If we have a project (new or loaded)...
            if (project.current) {

                // Call a helper to get us a 'normalized' version
                // of what we want our browser to display for its
                // url, starting with the path and including any
                // search string.
                const normNavPath = getNormalizedNavPath(startupDtls.platform,
                    startupDtls.projectGuid, project.current.id);


                // Then get the same portion using our current location info.
                const currNavPath = window.location.pathname + window.location.search;

                // If different...
                if (normNavPath !== currNavPath) {

                    // Replace our existing location with
                    // this path. Note: This will
                    // NOT actually cause any reload.
                    window.history.replaceState(null, '', normNavPath);
                }

                onCfgEstablished(project.current);
            }
        }
    }, [contentChanged, dispatch, startupDtls, onCfgEstablished]);


    const projectSettingChanged = useCallback((setting: ProjectSetting, newTextValue: SettingOption | undefined) => {
        if (project.current) {
            if (changeProjectSetting(project.current, setting, newTextValue)) {
                setConfigModified(true);
                contentChanged();

                // Force a re-render.
                //setRenderCount(renderCount => renderCount + 1);
            }
        }
        else {
            throw new Error('ERROR: projectSettingChanged with no project!');
        }
    }, [contentChanged]);


    if (LogRender.Views) {
        logger.logRender('Render ChassisProjView');
    }

    const getProjectForChildProps = (): ChassisProject => {
        if (project.current) {
            return project.current;
        }
        throw new Error('ERROR: Project should have been PRE-QUALIFIED.')
    }

   
    const renderView = (viewType: ProjectViewType) => {
        const projProp = getProjectForChildProps();
        switch (viewType) {
            case ProjectViewType.Design:
                return (
                    <ChassisSettings
                        project={projProp}
                        imgSrc={CLXImage}
                        valueChanged={projectSettingChanged}
                        contentChanged={contentChanged}
                    />
                );

            case ProjectViewType.Layout:
                return (
                   
                        <ChassisSelection
                            project={projProp}
                            contentChanged={contentChanged}
                            onFirstVScroll={props.onFirstVScroll}
                        />
                    
                   
                );

            case ProjectViewType.Summary:
                return (
                    <ChassisSummary
                        project={projProp}
                        chassisImgSrc={CLXImage}
                    />
                );

            default:
                return (null);
        }
    }

    // TO DO:
    // REVIEW THIS (including comments)
    // Show the view only when we have a User and
    // CSA projects AND the user is logged in.
    //const showView = (project.current && (authStat === AuthStatus.Authorized));
    //const showLoadingView = waitingForLogin || noUserProject;

    const showView = (project.current && (authStat !== AuthStatus.Unknown));
    const showLoadingView = false;


    return (
        <div className='project-views'>
            {showView
                ? renderView(currentViewType)
                : showLoadingView
                    ? <LoadingView loginRequired = { waitingForLogin } noProject = { noUserProject } />
                    : null
            }
        </div>
    );

}

export default ChassisProjView;


