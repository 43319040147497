import React from 'react';
import './FloatingDetails.scss';
import '../../styles/Meteor.scss'
import { Chassis, ChassisModule, ChassisPowerSupply } from '../../types/ProjectTypes';
import DetailsPowerUsage from './DetailsPowerUsage';
import { useSelectedDevice } from '../../context/SelectionInfoContext';
import { getChassisAltSAPwrSupplier } from '../../platforms/common/Common';
import { PlatformFlexHA } from '../../platforms/PlatformConstants';

const getGenPSInfo = (chassis: Chassis): [catNo: string, desc: string] => {
    if (chassis.ps) {
        return [chassis.ps.catNo, chassis.ps.description];
    }
    else {
        return ['<none>', ''];
    }
}

const getChassisModPowerSupplier = (chassis: Chassis): ChassisModule | undefined => {
    if (chassis.platform === PlatformFlexHA) {
        // The mod pwr supplier will be the adapter.
        return chassis.modules[0];
    }

    return undefined;
}


interface Props {
    chassis: Chassis;
}

const DetailsChassisPower = (props: Props) => {

    const { selectedDevice, /*setSelectedDevice*/ } = useSelectedDevice();

    let modAsPwrSupplier: ChassisModule | undefined = undefined;
    let pwrSupply: ChassisPowerSupply | undefined = undefined;
    let title = 'Power Supply';
    let [catalog, description] = getGenPSInfo(props.chassis);


    // Check if we have an alternate SA Power source,
    // which could be a Comm/Ctrl/Fpd or an external PSU. 
    const saPwrInfo = getChassisAltSAPwrSupplier(props.chassis, selectedDevice);
    if (saPwrInfo) {
        // If we have a power supply...
        if (saPwrInfo.saPowerSupply) {
            title = 'Power Supply';
            pwrSupply = saPwrInfo.saPowerSupply;
        }
        else {
            // The selected device or alternate provides power.
            title = 'Power Supplied from...';

            // If the selected device is NOT the power supplier...
            if (selectedDevice && selectedDevice.id !== saPwrInfo.saPowerSupplier?.id) {
                // Just return null. We can get into this case
                // when we WERE on the power tab for a legit
                // power supplier, then clicked on an I/O module.
                return null;
            }
            else {
                if (saPwrInfo.saPowerSupplier) {
                    catalog = saPwrInfo.saPowerSupplier.catNo;
                    description = saPwrInfo.saPowerSupplier.description;
                    modAsPwrSupplier = saPwrInfo.saPowerSupplier;
                }
                else {
                    catalog = 'Module Required: ';
                    description = 'A module is required in Slot 0 to supply power.';
                }
            }
        }
    }

    if (!modAsPwrSupplier && !pwrSupply) {
        modAsPwrSupplier = getChassisModPowerSupplier(props.chassis);
        if (modAsPwrSupplier) {
            title = 'Power Supplied from...';
            catalog = modAsPwrSupplier.catNo;
            description = modAsPwrSupplier.description;
        }
    }

    return (
        <div className='floating-details-tab'>
            <div className='floating-details-chassis-ps-info'>
                <span className='a-type__body highlighted-item'>
                    {title}
                </span>
                <span className='a-type__body'>
                    {catalog}
                </span>
                <span className='a-type__body'>
                    {description}
                </span>
            </div>
            {/*We need to differentiate the power display for a chassis*/}
            {/*opposed to a module that supplies power. Only assign 'module'*/}
            {/*when the caller is asking for a 'module as power supplier'.*/}
            <DetailsPowerUsage
                chassis={props.chassis}
                module={modAsPwrSupplier}
                ps={ pwrSupply }
            />
        </div>
    );
}

export default DetailsChassisPower;
