import { LocAndSize, NormRect, Point, Size } from "../types/SizeAndPosTypes";
import { normRectToLoc } from "./GeneralHelpers";
import { getStageSpacingDtls } from "./LayoutHelp";
import {
    getRedSecDistAbovePri,
    getRedSecDistBelowPri,
    getRedSecDistLeftOfPri,
    getRedSecDistRightOfPri,
    getRedBelowGap,
    getRedBesideGap,
    RedDepictOption
} from "./RedChassisHelp";


const addSelRectMargins = (rct: NormRect) => {
    const mrg = getStageSpacingDtls().selRectMargin;
    rct.left -= mrg;
    rct.top -= mrg;
    rct.right += mrg;
    rct.bottom += mrg;
}


export const getChassisSelRect = (
    ptOrg: Point,
    chSize: Size,
    redundant: boolean,
    redDepict: RedDepictOption
): LocAndSize => {

    const rct: NormRect = {
        left: ptOrg.x,
        top: ptOrg.y,
        right: ptOrg.x + chSize.width,
        bottom: ptOrg.y + chSize.height
    };

    if (redundant) {
        switch (redDepict) {
            case RedDepictOption.Behind:
                rct.left -= getRedSecDistLeftOfPri();
                rct.top -= getRedSecDistAbovePri();
                rct.right += getRedSecDistRightOfPri();
                rct.bottom += getRedSecDistBelowPri();
                break;

            case RedDepictOption.Beside:
                rct.right += (chSize.width + getRedBesideGap());
                break;

            case RedDepictOption.Below:
                rct.bottom += (chSize.height + getRedBelowGap());
                break;

            default:
                break;
        }
    }

    addSelRectMargins(rct);
    const loc = normRectToLoc(rct);
    return loc;

}
