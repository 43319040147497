import { ChassisRendType } from "../types/ProjectTypes";
import { LocAndSize, Point } from "../types/SizeAndPosTypes";
import { getEmptyPt } from "./GeneralHelpers";
import { getStageSpacingDtls } from "./LayoutHelp";
import {
    getRedSecDistAbovePri,
	RedDepictOption,
} from "./RedChassisHelp";

// Names can be no longer than 64 characters.
const maxNameLen = 64;

// The first char in a name must be
// a letter or an underscore.
const allowedFirstChars = /^[_a-zA-Z]+$/;

// Characters after the first are only allowed
// to be letters, numbers, underscores, or spaces.
// Note that when used on a name that is NOT supposed
// to allow spaces, any should have already been
// replaced with underscores.
const charsAllowed = /^[_a-zA-Z0-9 ]+$/;

const containsOnlyAllowedChars = (s: string): boolean => {
	return charsAllowed.test(s);
}

const stripDisallowedChars = (str: string): string => {
	if (str.length > 0) {
		if (containsOnlyAllowedChars(str)) {
			return str;
		}
		else {
			let replacement = '';
			for (let i = 0; i < str.length; i++) {
				const ch = str.charAt(i);
				if (containsOnlyAllowedChars(ch)) {
					replacement += ch;
				}
			}
			return replacement;
		}
	}
	else {
		return str;
	}
}

const sanitizeNameStart = (str: string): string => {

	// While we still have any chars left
	// AND the first char is NOT one that's
	// allowed as a name starter...
	while (str.length && !allowedFirstChars.test(str[0])) {

		// If the name has more than just
		// the first bad char... 
		if (str.length > 1) {
			// remove the bad
			// one and loop again.
			str = str.substring(1);
		}
		else {
			// Otherwise, there'd be nothing
			// left after removing it. Just
			// set to empty string in that case.
			str = '';
		}
	}

	// Return whatever we ended up with.
	return str;
}

const stripTrailingUnderscores = (str: string): string => {
	while (str.length && (str[str.length - 1] === '_')) {
		if (str.length > 1) {
			str = str.substring(0, str.length - 1);
		}
		else {
			str = '';
		}
	}
	return str;
}

// Return a 'sanitized' verison of the of the incoming name
// that meets our name standards. When this function is called
// DURING an active edit of a name, the finalize argument should
// be false. If finalName is true, the function completes one
// additional step, removing any trailing underscore characters.
export const sanitizeNameString = (
	name: string,
	insideSpacesOk: boolean,
	finalize: boolean
): string => {

	// Begin by trimming any whitespace 
	// from the START of the name.
	name = name.trimStart();

	// Then, if there's anything left...
	if (name.length > 0) {

		// IFF we're NOT supposed to allow
		// internal spaces, replace ALL
		// spaces with underscores.
		if (!insideSpacesOk) {
			name = name.replace(/ /g, '_');
		}
		//// Start by replacing space chars
		//// (if any) with underscores.
		//name = name.replace(/ /g, '_');

		// Remove any chars from the start
		// that aren't acceptable as name-starters.
		name = sanitizeNameStart(name);

		// Strip out any remaining chars that are NOT
		// allowed anywhere in the name. The ALLOWABLE
		// chars now INCLUDE spaces. However, if we're
		// NOT allowing inside spaces, we've ALREADY
		// removed / replaced them above.
		name = stripDisallowedChars(name);

		// If the resulting name is longer than
		// the max allowed, truncate it to max len.
		if (name.length > maxNameLen) {
			name = name.substring(0, maxNameLen);
		}

		// Names shouldn't have any trailing whitespace
		// or underscore chars. If this is supposed
		// to be the final version (it's NOT still
		// being edited)...
		// any from the right.
		if (finalize && name.length) {

			// Trim any trailing whitespace.
			name = name.trimEnd();

			// Then any trailing underscore chars.
			name = stripTrailingUnderscores(name);
		}

		// Return what we have left.
		return name;
	}
	else {
		// name is empty.
		// Just return it.
		return name;
	}
}

export const getChassisIconLocation = (ptChasOrg: Point): LocAndSize => {
	const spacingDtls = getStageSpacingDtls();
	return {
		x: ptChasOrg.x,
		y: ptChasOrg.y - spacingDtls.chassisToLblGap - spacingDtls.chassisLblFontSize,
		width: spacingDtls.chassisLblIconSize,
		height: spacingDtls.chassisLblIconSize
	};
}


const getStdNameOffset = (): Point => {
	const spacingDtls = getStageSpacingDtls();
	const offsetHt = spacingDtls.chassisLblFontSize + spacingDtls.chassisToLblGap
	return {
		x: spacingDtls.leftIconGap,
		y: -offsetHt
	};
}

export const getChassisNameOffset = (
	rendType: ChassisRendType,
	redDepict: RedDepictOption
): [showName: boolean, offset: Point] => {

	switch (rendType) {
		case ChassisRendType.RedPrimary:
			if (redDepict === RedDepictOption.Behind) {
				const offset = getStdNameOffset();
				offset.y -= getRedSecDistAbovePri();
				return [true, offset];
			}
			else {
				return [true, getStdNameOffset()];
			}

		case ChassisRendType.RedSecondary:
			if (redDepict === RedDepictOption.Behind) {
				return [false, getEmptyPt()];
			}
			else {
				return [true, getStdNameOffset()];
			}

		default: // ChassisRendType.Standard:
			return [true, getStdNameOffset()];
	}
}

