import { convertGuidedSelAttrToAppVal, HardwareGenImplSpec, RegisterHardwareGenImpl } from "../../../implementation/ImplHardwareGen";
import { refreshLocAttrInfoSelectionArray } from "../../../model/GuidedSelection";
import { getUserModuleSelectionsFromPointSectionInfo } from "../../../model/IOModule";
import { collectHardwareInfo, KeyValuePair, ProdSelCategory } from "../../../model/ProductSelection";
import { BOMItem, BOMItemProduct, createBOMItemProduct, IExternalBOM } from "../../../summary/SummaryHelp";
import { Start5015BankQty } from "../../../types/Globals";
import { PSInputVoltage } from "../../../types/PowerTypes";
import {
    Chassis,
    ChassisProject,
    DeviceCategory,
    DeviceType,
    EnvRating,
    FlexHASAPowerSupply,
    ChassisPowerSupply,
    LocAttributeInfo,
    ModuleDragStatus,
    FlexHAChassis,
} from "../../../types/ProjectTypes";
import { Size } from "../../../types/SizeAndPosTypes";
import { addRequiredAccys } from "../../../util/AccessoriesHelp";
import { getModuleEngInfo, getPowerSupplyEngInfo } from "../../../util/EngInfoHelp";
import { getNewInstanceId } from "../../../util/InstanceIdHelp";
import { StageUnitsPerMM } from "../../../types/StageTypes";
import { logger } from "../../../util/Logger";
import { genCreateHardwareFromSettings, getCreateHWFromSettingsErrors } from "../../common/HardwareGen";
import { PlatformFlexHA } from "../../PlatformConstants";
import { flexHAGetLocAttrInfoForChassisEdit } from "./FlexHAGuidedSelection";
import {
    flexHAGetChassisCatalog,
    flexHAGetLayoutInfo,
    maxFlexHAChassisSize,
    flexHAMapSAPower,
    flexHAIOBase
} from "./FlexHALayout";
import { setNumBanks } from "../../../implementation/ImplGeneral";
import { getAuxBank, getNumBanks } from "../../../model/ChassisProject";

const _flexHAImgLoc = '/assets/flexHA/';

// Constants
export const flexHA_DefDuplexMod = '5015-U8IHFTXT (Duplex)';
export const flexHA_DefSimplexMod = '5015-U8IHFTXT';
export const flexHA_DefAdapter = '5015-A2AKITXT';
export const flexHA_DefSAPsu = 'SA Power Kit';
export const flexHA_DefMODPsu = '1606-XLE240ECRZ';
export const flexHA_ImgSAPwrPsu = '5015_SA_Power_2-2.png';
export const flexHA_ImgMODPwrPsu = '5015_System_PS.png';


// There seems to be some variance in actual product dims,
// but all of the images we use to render CLX chassis
// elements are the same height - 280 pixels. And, in all
// cases, the given image's height overlays our entire chassis'
// height. That is, each individual image and the combination
// of all of them comprising a chassis rendering are always
// 280 pixels in height. 
const _flexHAElemImageHt = 280; // pixels TODO_FLEXHA

// Again, there appears to be some minor variance in height dims
// according to product documentation between specific chassis,
// but they're close enough to establish what WE will consider
// to be the (nominal) height of an entire CLX chassis (not
// including any mounting tabs that we don't show). That total
// height, as well as the height of the elements that make UP
// the chassis, are ALL considered to be 145 mm tall.
const _flexHANominalHt = 145;   // millimeters TODO_FLEXHA

// Determine a multiplier we can use to convert a
// number in image-size units to millimeters.
const _flexHAImageSizeToMM = _flexHANominalHt / _flexHAElemImageHt;
const _getSizesInStageUnits = true;

const tempSortCache = new Map<string, number>;
export const flexHAGenHWModArrSortPredicate = (a: string, b: string) => {
    // We want Duplex modules to be added first,
    // then the Simplex Modules. 
    if (a === b)
        return 0;

    let aSlots: number | undefined = tempSortCache.get(a);
    let bSlots: number | undefined = tempSortCache.get(b);

    if (!aSlots) {
        const info = getModuleEngInfo(PlatformFlexHA, a);
        if (info)
            aSlots = info.slotsUsed;
        else
            aSlots = 1;
        tempSortCache.set(a, aSlots);
    }
    if (!bSlots) {
        const info = getModuleEngInfo(PlatformFlexHA, b);
        if (info)
            bSlots = info.slotsUsed;
        else
            bSlots = 1;
        tempSortCache.set(b, bSlots);
    }
        
    if (aSlots > bSlots)
        return -1;

    return 1;
}

export const flexHACreatePowerSupply = (psCat?: string, inputVoltage?: PSInputVoltage): ChassisPowerSupply | FlexHASAPowerSupply | undefined => {
    if (!psCat)
        return undefined;

    const psEngInfo = getPowerSupplyEngInfo(PlatformFlexHA, psCat);
    if (psEngInfo) {
        const psInputVltg = psEngInfo.getInputVoltage(inputVoltage);
        const voltage = (psInputVltg == null ? PSInputVoltage.DC24V : psInputVltg);

        const scaledSize: Size = {
            width: psEngInfo.imgSize.width * _flexHAImageSizeToMM,
            height: psEngInfo.imgSize.height * _flexHAImageSizeToMM
        };
        if (_getSizesInStageUnits) {
            scaledSize.width *= StageUnitsPerMM;
            scaledSize.height *= StageUnitsPerMM;
        }

        // Default to the MOD Pwr (main) PSU. Start
        // by creating a standard PSU.
        const isSAPSU = (psCat === flexHA_DefSAPsu);
        const psuImage = (isSAPSU ? flexHA_ImgSAPwrPsu : flexHA_ImgMODPwrPsu);

        // Start by creating a standard 'MOD Pwr' 
        // PSU for the chassis.
        const ps: ChassisPowerSupply = {
            id: getNewInstanceId(),
            platform: PlatformFlexHA,
            deviceType: DeviceType.PS,
            catNo: psCat,
            description: psEngInfo.description,
            isPlaceholder: psEngInfo.isPlaceholder,
            extendedTemp: psEngInfo.envInfo.etOk,
            conformal: psEngInfo.envInfo.ccOk,
            accysPossible: psEngInfo.anyAccysPossible(),
            category: DeviceCategory.PS,
            imgSrc: _flexHAImgLoc + psuImage,
            imgSize: scaledSize,
            selected: false,
            movable: false,
            dragStatus: ModuleDragStatus.NA,
            redundant: psEngInfo.redundant,
            loc: {
                x: 0,
                y: 0,
                width: scaledSize.width,
                height: scaledSize.height
            },
            inputVoltage: voltage,
            // Needs to be set by the caller.
            parent: undefined,   
        };

        addRequiredAccys(ps);

        // If we have an SA PSU...
        if (isSAPSU) {
            // Create the SA instance by copying the 'MOD
            // Pwr' PSU and adding the ioBaseIndex prop.
            // Return the SA PSU.
            const saPSU: FlexHASAPowerSupply = { ...ps, ioBaseIndex: -1, autoAdded: false };
            return saPSU;
        }

        return ps;
    }
    return undefined;
}

const _prepareLocAttrHardwareForGen = (loc: LocAttributeInfo, project: ChassisProject) => {
    refreshLocAttrInfoSelectionArray(loc);

    const platform = loc.platform;

    // Start our chassis catalog out as 'standard.
    loc.hardware.catChassis = flexHAGetChassisCatalog(EnvRating.Standard);

    // Hardcode necessity: Attribute 'ER' - Env. Rating.
    const er = loc.arrAttributeNameToValue.find(x => x.attrID === 'ER');
    if (er) {
        loc.hardware.envRating = convertGuidedSelAttrToAppVal(platform, er.attrID, er.optionID, true) as EnvRating;
        loc.hardware.catChassis = flexHAGetChassisCatalog(loc.hardware.envRating);
    }

    // Set the controller chassis catalog to the remote.
    loc.hardware.catControllerChassis = undefined;
    loc.hardware.numCtrlChassisSlot = 0;
    // We will never need a comm in a controller chassis.
    loc.hardware.ctrlChassisNeedsScanner = false;

    // Hardcode necessity: Attribute 'CTRL' - defines whether a
    // configuration will be generated with or without a Controller
    loc.hardware.remoteIOOnly = true;

    // Hardcode necessity: Attribute 'CT' - Defines whether I/O
    // will be placed in Controller chassis or if all I/O will
    // be in remote chassis. Value 'Ded' for 'dedicated'. Note:
    // CpLX does not have 'CT' right now but may at some point.
    loc.hardware.ctrlDedicatedChassis = false;

    // We have a redundant remote PSU - set it.
    loc.hardware.catPowerSupply = flexHA_DefMODPsu;
    loc.hardware.catPwrSupCtrlChassis = undefined;

    // We do not have redundancy
    loc.hardware.redCtrlChassis = false;

    loc.hardware.ioModuleSelections = getUserModuleSelectionsFromPointSectionInfo(loc.pointEntrySection, project.config.IOEntryMode);
    loc.hardware.ioModuleSelections.forEach((sel) => {
        for (let idx = 0; idx < sel.quantity; ++idx)
            loc.hardware.catIOModules.push(sel.catalog);
    });

    loc.hardware.ioModuleCount = loc.hardware.catIOModules.length;

    // For chassis size, 'Auto' will return a default value of 31
    // which is the Max modules allowed in a CpLX "chassis"". Below,
    // when retrieving Controller and Comm, we will take the lesser
    // of chassisSize (set here) and what the Controller/Comm says 
    // it supports. Note: We do NOT add any Bank Ext Kits here.
    let chassisSize = maxFlexHAChassisSize; // slot 0 + up to 6 4-slot I/O bases.
    const cs = loc.arrAttributeNameToValue.find(x => x.attrID === 'CS');
    if (cs)
        chassisSize = Number(convertGuidedSelAttrToAppVal(platform, cs.attrID, cs.optionID, true));

    loc.hardware.numChassisSlot = chassisSize;

    // If we need any additional attributes to get the correct
    // selections, add them here. Currently 'snap' does not need any.
    const additionalAttributes: KeyValuePair[] = [];

    const arrHardware = collectHardwareInfo(loc, additionalAttributes);
    arrHardware.forEach((hwInfo) => {
        switch (hwInfo.category) {
            // We should have only one COMM/
            case ProdSelCategory.Comm:
                {
                    loc.hardware.catScanner = hwInfo.mainCatalog;
                    // The Adapter Kit is the power supplier for the chassis.
                    // TODO_FLEXHA - Verify what supplies power.
                    loc.hardware.catRemotePowerSupplier = loc.hardware.catScanner;
                }
                break;

            case ProdSelCategory.SlotFiller:
                {
                    loc.hardware.catSlotFiller = hwInfo.mainCatalog;
                }
                break;

            default:
                logger.error('Unknown Hardware Category: ' + hwInfo.category);
                break;
        }
    });


    // Validate the hardware - we should always have values for
    // the remote I/O chassis/PSU/Comm
    if (!loc.hardware.catScanner)
        throw new Error('_prepareLocAttrHardwareForGen(): Failed to select valid Snap Remote Comm.');

    return;
}

const flexHAsortIOModArrForHWGen = (arrModules: string[], reverseSort: boolean) => {
    // Note: Both sort() and reverse() array functions
    // affect the original array instance (i.e. the
    // source array is affected).
    arrModules.sort(flexHAGenHWModArrSortPredicate);
    if (reverseSort)
        arrModules.reverse();
}

// Assume each backplate extends 15 mm beyond the left
// and right sides of non-PS components on the bank.
const _extraBPWidth = 15 * 2;

const _getBackplateReqmt = (widthComps: number): string[] => {

    // Determine how wide the bp needs to be (in mm).
    const bpWidth = Math.round(widthComps) + _extraBPWidth;
    //logger.log('bp width reqd: ' + bpWidth);

    // If less that a given one-piece option, return it.
    if (bpWidth <= 300) return ['5015-MP300XT'];
    if (bpWidth <= 700) return ['5015-MP700XT'];
    if (bpWidth <= 900) return ['5015-MP900XT'];
    if (bpWidth <= 1250) return ['5015-MP1250XT'];
    if (bpWidth <= 1600) return ['5015-MP900XT', '5015-MP700XT'];

    // The max size bp would occur with a single bank
    // containing 6 I/O bases. Including our extra width
    // of 15mm on each side, resulting width tested to
    // appox 1580mm. We shouldn't ever get this far.
    throw new Error('Unexpected width requirement in _getBackplateReqmt!');
}

const _createMiscBOMItem = (
    cat: string,
    devType = DeviceType.Other,
    qty = 1
): BOMItemProduct => {
    return createBOMItemProduct(PlatformFlexHA, cat, devType, qty);
} 

const flexHAAddChassisExtrasToBOM = (
    chassis: Chassis,
    chassisConsComps: Map<string, BOMItemProduct>,
    chassisHdr: BOMItem,
    redSecondary: boolean,
    IExtBOM: IExternalBOM) => {

    const fhaChas = chassis as FlexHAChassis;

    // If we have a power supply, add it (AGAIN).
    // FlexHA uses redundant power supplies, and
    // we only got one via the default BOM build.
    if (fhaChas.ps) {
        IExtBOM.addDevice(fhaChas.ps, chassisConsComps, redSecondary, 1);
        chassisHdr.quantity += 1;
    }

    // See how many banks we have.
    const numBanks = getNumBanks(fhaChas);

    // Call a helper to give us a map of existing SA
    // Power supplies, keyed by I/O base offsets.
    const saPwrMap = flexHAMapSAPower(fhaChas);

    // Init a tally of bases already
    // dealt with on previous banks.
    let ioBasesOnPrevBanks = 0;

    // For each bank...
    for (let bank = 0; bank < numBanks; bank++) {

        // Get the bank's layout.
        const layout = flexHAGetLayoutInfo(fhaChas, bank);

        // Get the number of I/O bases we need.
        // The layout will have a loc for each.
        const numIOBasesOnBank = layout.ioBaseLocs.length;

        // If any...
        if (numIOBasesOnBank > 0) {

            // For each I/O base...
            for (let lclBaseIdx = 0; lclBaseIdx < numIOBasesOnBank; lclBaseIdx++) {

                // Create and add the product item for the base itself.
                IExtBOM.addComponent(_createMiscBOMItem(flexHAIOBase), chassisConsComps);
                chassisHdr.quantity += 1;

                // Determine the base's offset relative
                // to the entire chassis.
                const chasBaseIdx = lclBaseIdx + ioBasesOnPrevBanks;

                // If this base has SA power attached...
                if (saPwrMap.has(chasBaseIdx)) {

                    // Get the actual PS from the map.
                    const saPS = saPwrMap.get(chasBaseIdx);

                    // If we can...
                    if (saPS) {
                        // Add the SA power item(s).
                        chassisHdr.quantity +=
                            IExtBOM.addDevice(saPS, chassisConsComps, redSecondary, 1);
                    }
                    else {
                        // Unexpected.
                        throw new Error('ERROR: Missing SA PSU in flexHAAddChassisExtrasToBOM!');
                    }

                    // If this isn't the FIRST I/O base on the bank...
                    if (lclBaseIdx > 0) {

                        // Then we need a cover for the SA power
                        // connector in the lower-right corner of
                        // the PREVIOUS base.
                        IExtBOM.addComponent(_createMiscBOMItem('5015-N2SAXT'), chassisConsComps);
                        chassisHdr.quantity += 1;
                    }
                }
                else {
                    // This base does NOT have SA power attached.
                    // If it isn't the FIRST base on the bank...
                    if (lclBaseIdx > 0) {

                        // Then we need an SA power jumper to connect
                        // this base's lower-left SA power connector
                        // with the one on the lower-right of the PREVIOUS
                        // base.
                        IExtBOM.addComponent(_createMiscBOMItem('5015-RTBSAJXT'), chassisConsComps);
                        chassisHdr.quantity += 1;
                    }
                    else {
                        // The first base on EACH bank SHOULD have had power.
                        // Log the unexpected case, but don't throw Error.
                        logger.error('Unexpected ERROR: First I/O base on FlexHA bank without power?');
                    }
                }
            }
        }

        // If this is an aux bank...
        if (bank > 0) {

            // Get the aux.
            const aux = getAuxBank(fhaChas, bank - 1);

            // If it specifies that any extra comps
            // need to be included, add them.
            if (aux.compsReqd) {
                aux.compsReqd.forEach(cat => {
                    IExtBOM.addComponent(_createMiscBOMItem(cat), chassisConsComps);
                    chassisHdr.quantity += 1;
                });
            }
        }

        // Add backplate(s) separately for EACH bank.
        // Call a helper to tell us which backplate
        // catalog number(s) to use for our width.
        const bps = _getBackplateReqmt(layout.nonPSCompsLoc.width / StageUnitsPerMM);

        // Add each that we get back.
        bps.forEach(bpCat => {
            IExtBOM.addComponent(_createMiscBOMItem(bpCat), chassisConsComps);
            chassisHdr.quantity += 1;
        });

        // Add THIS banks base qty to our tally.
        ioBasesOnPrevBanks += numIOBasesOnBank;
    }

}

const flexHAFinalizeHardwareGenChassis = (chassis: Chassis) => {
    // We have a new chassis created
    // from the Design Page. Add SA
    // PSUs where needed.
    chassis; // suppress warning

    // Now part of adjustment process
    // called during updateChassis.
    //flexHAAddInitialSAPSUs(chassis);

    // TEMP
    if (Start5015BankQty > 0) {
        setNumBanks(chassis, Start5015BankQty);
    }
}

export const RegisterFlexHAHWGenImpl = () => {
    const impl: HardwareGenImplSpec = {
        //// General Functions  //////////////////////////////////////////////
        //    queryIOModules?: _implQueryIOModules;
        //    getIOPointFilterMap?: _implGetIOPointFilterMap;
        //    calcModQtyFromPoints?: _implCalcModQtyFromPoints;
        //    getInitialPointEntryInfo?: _implGetInitialPointEntryInfo;
        //    getIOModuleClosestMatch?: _implGetIOModuleClosestMatch;
        //    createDefaultPointEntry?: _implCreateDefaultPointEntry;
        //    validIOExists?: _implValidIOExists;
        //    getIOFilterMasksFromLoc?: _implGetIOFilterMasksFromLoc;
        //    getDefaultIOModuleCatalog?: _implGetDefaultIOModuleCatalog;
        //    finalizeGuidedSelection?: _implFinalizeGuidedSelection;
        //    convertGuidedSelAttrToAppVal?: _implGuidedSelAttrToAppVal;
        //    convertAppValToGuidedSelAttrOptID?: _implAppValToGuidedSelAttrOptID;
        //    getLocIOWiringTypeSel: GetIOModWiringSelectionForApp,

        platform: PlatformFlexHA,
        createHardwareFromSettings: genCreateHardwareFromSettings,
        getHardwareGenErrors: getCreateHWFromSettingsErrors,
        prepLocAttrHardwareForGen: _prepareLocAttrHardwareForGen,
        getLocAttrInfoForChassisEdit: flexHAGetLocAttrInfoForChassisEdit,
        sortIOModulesForHWGen: flexHAsortIOModArrForHWGen,
        addChassisExtrasToBOM: flexHAAddChassisExtrasToBOM,
        finalizeHardwareGenChassis: flexHAFinalizeHardwareGenChassis,
    }

    RegisterHardwareGenImpl(impl);
}