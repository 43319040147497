//// Component Interfaces ///////
// PointEntry Component.
export interface PointEntryInfo {
    // Platform (clx/cplx/etc.)
    platform: string;

    // 2024.7.9 New Type and Feature Model
    type: string;
    feature: string;
    types: string[];
    features: string[];

    // Selections for Advance and Basic I/O Entry Mode.
    advancedModule?: string;
    basicModule?: string;
    isAdvModDefault: boolean;

    // In Advanced Mode, combo catalog options.
    moduleDisplayStrs: string[];
    moduleCatalogs: string[];

    // Selected Points.
    points: number;

    // Calculated module count.
    advancedModCount: number;
    basicModCount: number;

    // Advanced phase 2 features.
    moduleSelInMultipleEntries: boolean;

    // UI
    selected: boolean;
    indexEntry: number;
    OnChanged: () => void; // callback

    savedSelectionInvalid: boolean;
    invalidEntry: boolean;
}

// PointEntrySection Component
export interface PointEntrySectionInfo {
    entries: PointEntryInfo[];
    platform: string;       // Main platform
    externalMaskChanged: boolean;
    externalPercentSpareIOChanged: boolean;
    initialized: boolean;
    clearSelectAll: boolean;
}

export interface PointTypeEntry {
    catalog: string;
    display: string;
}

export interface PointTypeGroup {
    type: string;
    options: PointTypeEntry[];
    groupNotSupported: boolean;
}

export const createNewPointEntrySectionInfo = (): PointEntrySectionInfo => {
    return {
        entries: [],
        externalMaskChanged: false,
        externalPercentSpareIOChanged: false,
        initialized: false,
        platform: '',
        clearSelectAll: false,
    };
}

