import { EngInfoIOModule } from "../engData/EngineeringInfo";
import { convertGuidedSelAttrToAppVal, getIOFilterMasksFromLoc } from "../implementation/ImplHardwareGen";
import { getIOModInfoMap, getIOModInfoMapMicro, getIOModTypes } from "../model/EngineeringData";
import { createNewPointEntryInterface } from "../model/IOModule";
import {
    IO_Bit,
    IOFilterMasks,
    IOModuleSelection,
    Vltg_Bit
} from "../types/IOModuleTypes";
import { PointEntryInfo, PointTypeEntry, PointTypeGroup } from "../types/IOPointEntryTypes";
import { EnvRating, HardwareBuilder, IOModuleWiring, LocAttributeInfo } from "../types/ProjectTypes";
import { bitCount32 } from "../util/GeneralHelpers";
import { PlatformCLX, PlatformCpLX, PlatformFlex, PlatformFlexHA, PlatformMicro } from "../platforms/PlatformConstants";
import { getLocAttributeSetting, refreshLocAttrInfoSelectionArray } from "../model/GuidedSelection";
import { PSInputVoltage } from "../types/PowerTypes";
import {
    getDefaultIOModuleCatalog,
    getIOModulesForLoc,
    getPointTypeOrFeatureMask,
    parseIOMaskToTypeAndFeatureTxt,
    validatePointFeature,
    validatePointType
} from "../util/IOModuleHelp";
import { getIOModuleEngInfo } from "../util/EngInfoHelp";
import { IOModuleMap } from "../types/EngDataTypes";

//////////// UTILITIES ////////////////////////////////////
export const getHardwareErrorMessages = (hw: HardwareBuilder): string[] => {
    const messages: string[] = [];
    hw.mapErrMessages.forEach((msg) => {
        messages.push(msg);
    });

    return messages;
}

export const getHardwarePreview = (hw: HardwareBuilder): string => {
    let message = '';

    message += '\nDedicated Controller Chassis: ';
    message += hw.ctrlDedicatedChassis;

    message += '\nCtrl Chassis Needs Scanner: '
    message += hw.ctrlChassisNeedsScanner;
    message += '\n'

    if (hw.catControllerChassis) {
        message += '\nController Chassis: ';
        message += hw.catControllerChassis;
    }

    if (hw.catPwrSupCtrlChassis) {
        message += '\nController Chassis PSU: ';
        message += hw.catPwrSupCtrlChassis;
    }

    if (hw.catChassis) {
        message += '\nChassis: ';
        message += hw.catChassis;
    }

    if (hw.catPowerSupply) {
        message += '\nChassis PSU: ';
        message += hw.catPowerSupply;
    }

    if (hw.catController) {
        message += '\nController: ';
        message += hw.catController;
    }

    if (hw.catControllerPartner) {
        message += '\nAdditional Ctrl Partner(s):';
        hw.catControllerPartner.forEach((coMod) => {
            message += ' ' + coMod;
        })
    }

    if (hw.catScanner) {
        message += '\nScanner: ';
        message += hw.catScanner;
    }

    if (hw.redCtrlChassis) {
        message += '\nRedundant Ctrl Chassis: ';
        message += hw.redCtrlChassis;
    }

    if (hw.ioModuleSelections.length) {
        message += '\nI/O Module Count: ';
        message += hw.ioModuleCount;
        message += '\nI/O Modules: ';
        hw.ioModuleSelections.forEach((x, idx) => {
            if (idx > 0)
                message += '/';
            message += x.catalog;
            message += ' (' + x.quantity + ')';
        })
    }

    return message;
}

//////////////////////////////////////////////////////////////////////
// The idea here is to "Hide" the Default Hardware Generation
// Function from the outside world. At the bottom of the
// file we export an interface similar to HardwareGenImplSpec
// containing non-exported functions defined locally in this file.
///////////////////////////////////////////////////////////////////////


// Cache of queries performed. Map of query key to result.
const _ioPreviousQueries = new Map<string, EngInfoIOModule[]>();

const createIOModQueryKey = (platform: string, mask: IOFilterMasks, type: string, feature: string) => {
    return `${platform}_${mask.includeMask}_${mask.excludeMask}_${mask.voltageMask}_${type}_${feature}`;
}

const getIomoduleInfo = (platform: string): IOModuleMap =>{
    switch(platform){
        case PlatformCLX:
        case PlatformCpLX:
        case PlatformFlexHA:
        case PlatformFlex:
            return  getIOModInfoMap(platform);        
        case PlatformMicro:
            return getIOModInfoMapMicro(platform);
        default:
            return getIOModInfoMap(platform);  
    
    }

}

export const queryIOModules = (locAttr: LocAttributeInfo, type = '', feature = ''): EngInfoIOModule[] => {
    // Do we have the query cached...
    const platform = locAttr.platform;
    const locMasks = getIOFilterMasksFromLoc(locAttr);
    const queryKey = createIOModQueryKey(platform, locMasks, type, feature);
    const prevQuery = _ioPreviousQueries.get(queryKey);
    if (prevQuery !== undefined)
        return prevQuery;

    // Retrieved the IO module map.
    const ioInfo = getIomoduleInfo(platform);

    // Filter results - we do NOT filter on
    // voltage for I/O Modules. We DO sort on
    // voltage. IF A PLATFORM NEEDS TO FILTER
    // ON VOLTAGE, ADD A PLATFORM SPECIFIC IMPL.
    const results: EngInfoIOModule[] = [];
    ioInfo.forEach((info) => {
        // First filter on Env Rating
        if ((info.ioMask & locMasks.includeMask) === locMasks.includeMask &&
            (info.ioMask & locMasks.excludeMask) === 0) {
            let ioOK = true;
            if (type)
                ioOK = info.pointType.typeData.some(x => x.type === type);

            if (ioOK && feature)
                ioOK = info.pointFeature.some(x => x.type === feature);

            if(ioOK)
                results.push(info);
        }
    });

    sortIOModulesOnVoltage(results, locMasks.voltageMask);

    _ioPreviousQueries.set(queryKey, results);

    return results;
}

const sortIOModulesOnVoltage = (ioModules: EngInfoIOModule[], voltage: number) => {
    // Note: we are NOT creating a new instance of the array.
    // We are just re-ordering ioModules.
    ioModules.sort((a, b) => {
        const vltgMatchA = bitCount32(a.vltgMask & voltage);
        const vltgMatchB = bitCount32(b.vltgMask & voltage);
        if (vltgMatchA > vltgMatchB)
            return -1;
        else if (vltgMatchA < vltgMatchB)
            return 1;

        // Sort on catalog.
        return a.catNo.localeCompare(b.catNo);

    })
}

// Helper for _getInitialPointEntryInfo()
const getInitialPointTypeGroups = (locAttrInfo: LocAttributeInfo): PointTypeGroup[] => {
    // Get the point TYPES allowed for this platform...
    const types: PointTypeGroup[] = [];
    const arrTypes = getIOModTypes(locAttrInfo.platform);
    arrTypes.forEach((type) => {
        const entries: PointTypeEntry[] = [];
        if (type.name) {
            // If we are NOT exluding the basic type...
            if ((type.value & IO_Bit.Exclude) === 0) {
                // Query for an array of IO Module Infos based on the type.
                const [, , arrInfo, typeFound,] = getIOModulesForLoc(locAttrInfo, type.name);

                // Validate the type - should always be good...
                if (type.name === typeFound) {
                    // For each info, add it to the entries array.
                    arrInfo.forEach((info) => {
                        entries.push({ catalog: info.catNo, display: info.displayString });
                    });

                    // Add the point type group to our array.
                    types.push({
                        type: type.name,
                        options: entries,
                        groupNotSupported: entries.length === 0,
                    });
                }
            }
            else {
                types.push({
                    type: type.name,
                    options: [],
                    groupNotSupported: true,
                });
            }
        }
    });

    const initTypes = _prepInitialTypesForDisplay(types);
    return initTypes;
}

const _prepInitialTypesForDisplay = (types: PointTypeGroup[]): PointTypeGroup[] => {
    // Hard code the order and initial types
    // to show. IF this order/allowed types
    // needs to be platform specific, add
    // a function to Hardware Impl Interface.
    // <See getInitialPointEntryInfo()>
    const arrOrdered = ['IOpoints_DI', 'IOpoints_DO', 'IOpoints_AI', 'IOpoints_AO'];

    const initTypes: PointTypeGroup[] = [];
    arrOrdered.forEach((type) => {
        const found = types.find(x => x.type === type);
        if(found)
            initTypes.push(found);
    });

    return initTypes;
}

const _updateModuleSelection = (loc: LocAttributeInfo, sel: IOModuleSelection) => {
    const infoModParent = getIOModuleEngInfo(loc.platform, sel.catalog);

    sel.selectedPoints.forEach((selPoint) => {
        // Check if we need to update the entry
        // to the new text based Type/Feature.
        if (!selPoint.type || selPoint.feature == null) {
            const [type, feature] = parseIOMaskToTypeAndFeatureTxt(selPoint.typeID);
            selPoint.type = type;
            selPoint.feature = feature;
            selPoint.typeID = getPointTypeOrFeatureMask(type);
            selPoint.typeID |= getPointTypeOrFeatureMask(feature);
        }

        // Start the info to the parent's info.
        let infoMod = infoModParent;

        // If the parent catalog no longer exists...
        if (infoMod == null) {
            // Get the default module for the type.
            const defModule = getDefaultIOModuleCatalog(selPoint.type, loc);
            if (defModule) {
                infoMod = getIOModuleEngInfo(loc.platform, sel.catalog);
            }
        }

        // Validate the type and feature 
        if (infoMod) {
            selPoint.type = validatePointType(selPoint.type, infoMod);
            selPoint.feature = validatePointFeature(selPoint.feature, infoMod);
            selPoint.catValidated = infoMod.catNo;
        }
        else {
            // Clear the type. Without a type, a 
            // Point Entry will NOT be created.
            selPoint.type = '';
            selPoint.feature = '';
            selPoint.catValidated = '';
        }
    });
}

const _getInitialPointEntryInfo = (locAttrInfo: LocAttributeInfo, initUserSelections: IOModuleSelection[]): PointEntryInfo[] => {
    const platform = locAttrInfo.platform;
    const initPointTypeGroups = getInitialPointTypeGroups(locAttrInfo);

    const initialEntries: PointEntryInfo[] = [];

    // If we do NOT have user selections....
    if (initUserSelections.length == 0) {
        initPointTypeGroups.forEach((type) => {
            // If the type is not supported, do not add it.
            if (type.groupNotSupported === false) {
                const defEntry = createNewPointEntryInterface();

                defEntry.type = type.type;
                defEntry.platform = platform;
                defEntry.invalidEntry = type.groupNotSupported;
                defEntry.isAdvModDefault = true;

                if (defEntry.invalidEntry === false) {
                    defEntry.moduleCatalogs = type.options.map(x => { return x.catalog; });
                    defEntry.moduleDisplayStrs = type.options.map(x => { return x.display; });
                    defEntry.advancedModule = getDefaultIOModuleCatalog(type.type, locAttrInfo);
                    defEntry.basicModule = defEntry.advancedModule
                }
                initialEntries.push(defEntry);
            }
        });

        return initialEntries;
    }

    // We have user selections. We will ONLY add these... Note: we will
    // recalc the module counts later - the Quantity in the selection is
    // used when we generate the hardware from the settings.
    initUserSelections.forEach((sel) => {
        // The catalog in the parent selection applies to all
        // of the individual point entries (sel.selectedPoints).
        // For instance, '1756-ABC' could be selected in more
        // than one I/O Point Entry on the Design Page.

        // First, update the selections. This will
        // perform an upgrade (if needed) as well
        // as validate the catalog, type, and feature
        // for each entry in the IOModuleSelection.
        _updateModuleSelection(locAttrInfo, sel);

        // Note: when we read in the user selections,
        // we validate the catalog - if the validated
        // catalog no longer matches what the user's
        // config saved out, we will not consolidate
        // the entry (the module saved out does not exist).
        let consolidateMod = sel.selectedPoints.length > 1;
        if (consolidateMod && sel.selectedPoints.some(x => x.catValidated !== sel.catalog))
            consolidateMod = false;

        const entryCount = sel.selectedPoints.length;
        for (let idx = 0; idx < entryCount; ++idx) {
            const selEntry = sel.selectedPoints[idx];

            // _updateModuleSelection() will set type 
            // and catalog empty when invalid.
            if (!selEntry.type || !selEntry.catValidated) {
                // This entry is no longer valid. There
                // is nothing more to do...
                continue;
            }

            // Create and add the entry to the returned array...
            const userEntry = createNewPointEntryInterface();
            initialEntries.push(userEntry);

            userEntry.points = selEntry.userPointCount;
            userEntry.platform = platform;
            userEntry.type = selEntry.type;
            userEntry.feature = selEntry.feature;

            // If the validated catalog is different than what was saved...
            // Note: The validated catalog may replace what was saved if 
            // a valid substitute could be found.
            userEntry.savedSelectionInvalid = (selEntry.catValidated !== sel.catalog);
            // If we do NOT have a validated catalog or a valid type...
            userEntry.invalidEntry = (!selEntry.type || !selEntry.catValidated);

            if (!userEntry.invalidEntry) {
                const defModule = getDefaultIOModuleCatalog(selEntry.type, locAttrInfo);

                userEntry.advancedModule = selEntry.catValidated;
                userEntry.isAdvModDefault = (selEntry.catValidated === defModule);
                userEntry.basicModule = defModule;
                userEntry.moduleSelInMultipleEntries = consolidateMod;

                // Add the options. If we do not have a feature...
                if (!selEntry.feature) {
                    const initTypeInfo = initPointTypeGroups.find((val) => val.type === selEntry.type);
                    if (initTypeInfo) {
                        userEntry.moduleCatalogs = initTypeInfo.options.map(x => { return x.catalog; });
                        userEntry.moduleDisplayStrs = initTypeInfo.options.map(x => { return x.display; });
                    }
                }

                if (userEntry.moduleCatalogs.length === 0) {
                    // Query for the modules.
                    const [, , arrInfo] = getIOModulesForLoc(locAttrInfo, selEntry.type, selEntry.feature, false);
                    userEntry.moduleCatalogs = arrInfo.map(x => { return x.catNo; });
                    userEntry.moduleDisplayStrs = arrInfo.map(x => { return x.displayString; });                  
                }
            }
        }
    });

    return initialEntries;
}

const _getIOFilterMasksFromLoc = (locAttrInfo: LocAttributeInfo): IOFilterMasks => {
    const ioMasks: IOFilterMasks = { includeMask: 0, excludeMask: 0, voltageMask: 0 };

    // Update the locAttrInfo.arrAttributeNameToValue array.
    refreshLocAttrInfoSelectionArray(locAttrInfo);

    // Not sure how to avoid a 'Hardcode'? We need to create a
    // bit mask and if the bit values are not in the JSON, we have
    // to create a translation...

    // So far, it looks like ControlLogix and CompactLogix
    // will work in this general function.
    const erSetting = locAttrInfo.arrAttributeNameToValue.find(x => x.attrID === 'ER');
    if (erSetting) {
        switch (erSetting.optionID) {
            case 'CC':
                ioMasks.includeMask |= IO_Bit.Conformal;
                break;
            case 'XT':
                ioMasks.includeMask |= IO_Bit.ExTemp;
                break;
            default:
                // Standard - For 5015 FlexHA, since all modules
                // are rated Std/CC/XT, we do NOT exclude CC or XT.
                // For all other platforms, we must exclude CC/XT.
                if (locAttrInfo.platform !== PlatformFlexHA) {
                    // Exclude conformal and XT
                    ioMasks.excludeMask |= (IO_Bit.Conformal | IO_Bit.ExTemp);
                }
                break;
        }
    }

    const cvSetting = locAttrInfo.arrAttributeNameToValue.find(x => x.attrID === 'CV');
    if (cvSetting) {
        switch (cvSetting.optionID) {
            case '5D':
                ioMasks.voltageMask |= Vltg_Bit.vdc5;
                break;
            case '12D':
                ioMasks.voltageMask |= Vltg_Bit.vdc12;
                break;
            case '24D':
                ioMasks.voltageMask |= Vltg_Bit.vdc24;
                break;
            case '48D':
                ioMasks.voltageMask |= Vltg_Bit.vdc48;
                break;
            case '125D':
                ioMasks.voltageMask |= Vltg_Bit.vdc125;
                break;
            case '12A':
                ioMasks.voltageMask |= Vltg_Bit.vac12;
                break;
            case '24A':
                ioMasks.voltageMask |= Vltg_Bit.vac24;
                break;
            case '48A':
                ioMasks.voltageMask |= Vltg_Bit.vac48;
                break;
            case '120A':
                ioMasks.voltageMask |= Vltg_Bit.vac120;
                break;
            case '240A':
                ioMasks.voltageMask |= Vltg_Bit.vac240;
                break;
        }
    }

    return ioMasks;
}

const _createDefaultPointEntry = (locAttrInfo: LocAttributeInfo, type = ''): PointEntryInfo => {
    // If we do not have a requested type, set it to Analog Input.
    const platform = locAttrInfo.platform;
    if (!type) {
        const arrPointTypes = getIOModTypes(platform);
        if (!arrPointTypes)
            throw new Error(`_createDefaultPointEntry(): Platform <${platform}> not implemented!`);

        type = arrPointTypes[0].name;
    }

    const defCatalog = getDefaultIOModuleCatalog(type, locAttrInfo);

    const newEntry = createNewPointEntryInterface();

    newEntry.platform = locAttrInfo.platform;
    newEntry.invalidEntry = (defCatalog == null);
    newEntry.isAdvModDefault = true;
    newEntry.type = type;

    if (newEntry.invalidEntry === false) {
        const arrInfo = queryIOModules(locAttrInfo, type);
        newEntry.moduleCatalogs = arrInfo.map(x => { return x.catNo; });
        newEntry.moduleDisplayStrs = arrInfo.map(x => { return x.displayString; });
        // Set both the Adv and Basic mods to the default.
        newEntry.advancedModule = defCatalog;
        newEntry.basicModule = newEntry.advancedModule
    }

    return newEntry;
}


// Unfortunately, hardcoded Guided Selection Attributes
// needed to convert them to App understandable values/enums.
// Note: AS LONG AS THE ATTR ID IS VALID -> although 'void'
// is returned in the signature, there should be 'something'
// returned that needs to be cast to the correct value/enum type.
const _convertGuidedSelAttrToAppVal = (platform: string, attrID: string, optionID: string, returnDefOnFail: boolean) => {
    switch (attrID) {
        case 'ER':
            {
                switch (optionID) {
                    case 'Std':
                        return EnvRating.Standard;
                    case 'CC':
                        return EnvRating.ConformalCoated;
                    case 'XT':
                        return EnvRating.ExtTemperature;
                    default:
                        if (returnDefOnFail)
                            return EnvRating.Standard;
                }
            }
            return '';

        case 'CA':
            // This is a true/false
            return (optionID === 'Red');

        case 'CV':
            {
                switch (optionID) {
                    case '24D':
                        return PSInputVoltage.DC24V;
                    case '48D':
                        return PSInputVoltage.DC48V;
                    case '125D':
                        return PSInputVoltage.DC125V;
                    case '120A':
                        return PSInputVoltage.AC120V;
                    case '240A':
                        return PSInputVoltage.AC240V;
                    default:
                        if (returnDefOnFail)
                            return PSInputVoltage.DC24V;
                }
            }
            return '';

        case 'CS':
            {
                const numSlots = Number(optionID);
                if (isNaN(numSlots) || numSlots < 4) {
                    if (returnDefOnFail) {
                        switch (platform) {
                            case PlatformCLX:
                                return 10;
                            case PlatformCpLX:
                                return 31;
                            case PlatformFlex:
                                return 16;
                                case PlatformMicro:
                                    return 11;
                        }
                    }
                }
                else
                    return numSlots;
            }
            return 0;

        case 'RTB':
            {
                switch (optionID) {
                    case 'Screw':
                        return IOModuleWiring.Screw;
                    case 'Spring':
                        return IOModuleWiring.SpringClamp;
                    case 'IOReady':
                        return IOModuleWiring.IOReadyCable;
                    default:
                        if (returnDefOnFail)
                            return IOModuleWiring.Screw;
                }
            }
            return '';

        // Ctrl Capability (Std, Safety, and Process). Based on 
        // the Guided Sel Opt Id, return the Eng Data's SubType1.
        case 'CC':
            {
                switch (optionID) {
                    case 'Std':
                        return 'Standard';
                    case 'Safe':
                        return 'Safety';
                    case 'Proc':
                        return 'Process';
                    default:
                        if (returnDefOnFail)
                            return 'Standard';
                }
            }
            return '';
    }

    // Someone did not request the right thing!
    // (or we need to enhance/hardcode more attributes)...
    throw new Error('convertGuidedSelAttrToAppValue(): An invalid Attribute ID or Option ID was passed in.')
}


// Unfortunately, a hardcode to return Guided Selection Option
// IDs from Application enums/values that the Guided Selection
// understands.
// NOTE: May need to add a platform argument here. We do not
// have Power Availability yet, but when/if we do, Guided Sel
// Options IDs are different for CLX/CpLX/Flex.
const _convertAppValToGuidedSelAttrOptionID = (attrID: string, appVal: string): string => {
    switch (attrID) {
        case 'ER':
            {
                switch (appVal) {
                    case EnvRating.Standard:
                        return 'Std';
                    case EnvRating.ConformalCoated:
                        return 'CC';
                    case EnvRating.ExtTemperature:
                        return 'XT';
                    default:
                        return 'Std';
                }

            }

        case 'CA':
            // This is a true/false
            return (appVal === 'true' ? 'Red' : 'Std');

        case 'CV':
            {
                switch (appVal) {
                    case PSInputVoltage.DC24V:
                        return '24D';
                    case PSInputVoltage.DC48V:
                        return '48D';
                    case PSInputVoltage.DC125V:
                        return '125D';
                    case PSInputVoltage.AC120V:
                        return '120A';
                    case PSInputVoltage.AC240V:
                        return '240A';
                    default:
                        return '24D';
                }
            }

        case 'CS':
            {
                // Should be the same, meaning the option
                // id for a 4 Slot chassis is '4'...
                return appVal;
            }
                
        case 'RTB':
            {
                switch (appVal) {
                    case IOModuleWiring.Screw:
                        return 'Screw';
                    case IOModuleWiring.SpringClamp:
                        return 'Spring';
                    case IOModuleWiring.IOReadyCable:
                        return 'IOReady';
                    default:
                        return 'Screw';
                }
            }

        // Based on the Eng Data's SubType1 value, return the 
        // Ctrl Capability 'CC' Option ID. 
        case 'CC':
            {
                switch (appVal) {
                    case 'Standard':
                        return 'Std';
                    case 'Safety':
                        return 'Safe';
                    case 'Process':
                        return 'Proc';
                    default:
                        return 'Std';
                }
            }
            return '';
   }

    // Someone did not request the right thing!
    // (or we need to enhance/hardcode more attributes)...
    throw new Error('convertGuidedSelAttrToAppValue(): An invalid Attribute ID or Option ID was passed in.')
}

const _getIOModWiringSelectionForApp = (locAttrInfo: LocAttributeInfo, gsValueAlternate = ''): IOModuleWiring => {
    // Note there is a case when we already know the attribute
    // value. That can be passed in as the gsValueAlternate and
    // we can skip over getting/finding the attribute in the loc.
    if (gsValueAlternate)
        return convertGuidedSelAttrToAppVal(locAttrInfo.platform, 'RTB', gsValueAlternate, true) as IOModuleWiring;

    const setting = getLocAttributeSetting(locAttrInfo, 'RTB');
    if (setting) {
        return convertGuidedSelAttrToAppVal(locAttrInfo.platform, 'RTB', setting.selectedOption.id, true) as IOModuleWiring;
    }

    // Just call it screw...
    return IOModuleWiring.Screw;
}

// Specifications for optional platform-specific routes.
interface _implDefGetInitialPointEntryInfo {(locAttrInfo: LocAttributeInfo, initValues: IOModuleSelection[]): PointEntryInfo[];}
interface _implDefCreateDefaultPointEntry {(locAttrInfo: LocAttributeInfo, type: string): PointEntryInfo;}
interface _implDefGetIOFilterMasksFromLoc {(locAttrInfo: LocAttributeInfo): IOFilterMasks}
interface _implGuidedSelAttrToAppVal { (platform: string, attrID: string, optionID: string, returnDefOnFail: boolean): unknown}
interface _implAppValToGuidedSelAttrOptID {(attrID: string, appValue: string): string}
interface _implGetLocIOWiringTypeSel {(locAttrInfo: LocAttributeInfo): IOModuleWiring}

interface DefHardwareGenImplSpec {
    getInitialPointEntryInfo: _implDefGetInitialPointEntryInfo;
    createDefaultPointEntry: _implDefCreateDefaultPointEntry;
    getIOFilterMasksFromLoc: _implDefGetIOFilterMasksFromLoc;
    convertGuidedSelAttrToAppVal: _implGuidedSelAttrToAppVal;
    convertAppValToGuidedSelAttrOptID: _implAppValToGuidedSelAttrOptID;
    getLocIOWiringTypeSel: _implGetLocIOWiringTypeSel;
}

export const defHardwareGenImplFn: DefHardwareGenImplSpec = {
    getInitialPointEntryInfo: _getInitialPointEntryInfo,
    createDefaultPointEntry: _createDefaultPointEntry,
    getIOFilterMasksFromLoc: _getIOFilterMasksFromLoc,
    convertGuidedSelAttrToAppVal: _convertGuidedSelAttrToAppVal,
    convertAppValToGuidedSelAttrOptID: _convertAppValToGuidedSelAttrOptionID,
    getLocIOWiringTypeSel: _getIOModWiringSelectionForApp,
};

