import { EngInfoController } from "../../../engData/EngineeringInfo";
import {
	addModuleAtSlot,
	deleteModuleAtSlot,
	updateChassis
} from "../../../implementation/ImplGeneral";
import {
	addChassis,
	getChassisAndRackIndexById,
	updateAllChassis
} from "../../../model/ChassisProject";
import { getWarningThreshold, PrfAspect, runCommCalcs } from "../../../model/CommDetails";
import { ChassisProject, ChassisModule, Chassis } from "../../../types/ProjectTypes";
import { AFType, AF_Base } from "../../../util/CheckerAutoFix";
import {
	autoFixNoSafetyController,
	bumpControllerSizes,
	collectCtlrs,
	getPrfShortage,
	getUniqueChassisName,
	isMemAvailViaUpsizing
} from "../../../util/CheckerAutoFixProjHelpers";
import { getAvailableModules, getCompatibleControllers, getEngInfoForComp } from "../../../util/EngInfoHelp";
import { isServerEnvProd } from "../../../util/ServerEnvironment";
import { contentChanging, suspendUndoSnapshots } from "../../../util/UndoRedo";
import { PlatformCpLX } from "../../PlatformConstants";
import { snapCorrectChassisMODPower, snapMigrateModulesToMaxSupported, snapUpsizeSlot0ToSupportModCount } from "../../snap/SnapChecker";
import { snapOptimizeFPDs } from "../../snap/SnapGeneralImpl";


export const cplxExecuteAutoFix = (af: AF_Base, project: ChassisProject, callback: () => void): boolean => {
    let success = false;
    switch (af.type) {
        case AFType.Snap_CommHasTooManyModules:
            success = _afCommHasTooManyModules(project, af);
            break;

        case AFType.Safety_NoSafetyCtlrInConfig:
            success = autoFixNoSafetyController(project);
            break;

        case AFType.Sys_Performance:
            success = cplxAutoFixSysPerformance(project);
            break;

        case AFType.ChassisOutOfPower:
        case AFType.ChassisLowOnPower:
            success = snapCorrectChassisMODPower(project, af);
            break;

        case AFType.Snap_InefficientFPDs:
            {
                const [chassis] = getChassisAndRackIndexById(
                    project,
                    af.targetInstanceID
                );
                if (chassis) {
                    success = snapOptimizeFPDs(chassis);
                }
            }
            break;

        case AFType.No_Controller:
            success = autofixCplxNoController(project, af.targetInstanceID);
            break;

        default:
            // If we are NOT Production...
            if (!isServerEnvProd())
                window.alert('AutoFix not yet implemented: ' + af.type);
            break;
    }

    if (success)
        callback();

    return success;
}

const _afCommHasTooManyModules = (project: ChassisProject, af: AF_Base): boolean => {
	// Call our helper to get the chassis and rack index
	const [targetChassis, idxRack] = getChassisAndRackIndexById(project, af.targetInstanceID);
	if (targetChassis == null || idxRack < 0) {
		alert('Unable to correct issue.');
		return false;
	}

	// First try to upsize the slot 0 module.
	// If we can...
	if (snapUpsizeSlot0ToSupportModCount(targetChassis, -1, false)) {
		return true;
	}

	// Next, call a function to migrate modules
	// to a new chassis to correct the issue.
	return snapMigrateModulesToMaxSupported(targetChassis, idxRack);
}

export const cplxSwapCtrlForSafetyCtrl = (chassis: Chassis): boolean => {
	// We should have a non-safety controller in
	// slot 0. Our goal is to replace it with a
	// suitable safety controller.
	const modSlot0 = (chassis.modules ? chassis.modules[0] : undefined);
	if (modSlot0 == null) {
		//// TEMP ////
		return false;
	}

	// Get the comp Info
	const infoOrg = getEngInfoForComp(PlatformCpLX, modSlot0.catNo);
	if (infoOrg == null || infoOrg.isController === false) {
		//// TEMP ////
		return false;
	}

	const infoCtrlOrg = infoOrg as EngInfoController;
	// If we have a safety controller...
	if (infoCtrlOrg.safetyController)
		return true; // We're good.

	// Get an array of modules excluding FPDs.
	const arrMods = chassis.modules.filter((mod, idx) => idx > 0 && mod != null);
	const nChassisMods = arrMods.length;

	const compatibleCtrls = getCompatibleControllers(chassis) as EngInfoController[];
	const compatibleSafCtrls = compatibleCtrls.filter(ctrl => ctrl.safetyController === true && ctrl.maxSnapModules >= nChassisMods);
	// If we have any....
	if (compatibleSafCtrls) {
		const replacement = findClosestSafCtrlMatch(infoCtrlOrg, compatibleSafCtrls);

		// Did we get a replacement?
		if (replacement == null)
			return false;

		if (deleteModuleAtSlot(chassis, 0)) {
			addModuleAtSlot(chassis, replacement.catNo, 0, true);
			updateChassis(chassis);
			return true;
		}
	}

	return false;
}

const findClosestSafCtrlMatch = (ctrlToMatch: EngInfoController, potentials: EngInfoController[]): EngInfoController => {
	if (!potentials || !ctrlToMatch.controllerRole || potentials.some(x => x.catNo === ctrlToMatch.catNo))
		return ctrlToMatch;

	const roleToMatch = ctrlToMatch.controllerRole;

	// Run a series of filters until we get something close.
	let firstCut = potentials.filter(x => x.controllerRole &&
		x.controllerRole.isAdvanced === roleToMatch.isAdvanced &&
		x.controllerRole.supportsCIPMotion === roleToMatch.supportsCIPMotion);

	if (!firstCut)
		firstCut = potentials;

	let secondCut = firstCut.filter(x => x.controllerRole &&
		x.controllerRole.enetNodeType === roleToMatch.enetNodeType &&
		x.controllerRole.maxCIPConns >= roleToMatch.maxCIPConns &&
		x.controllerRole.maxCIPConns >= roleToMatch.maxCIPConns);

	if (!secondCut)
		secondCut = firstCut;

	let thirdCut = secondCut;
	if (ctrlToMatch.commRole) {
		const commRoleToMatch = ctrlToMatch.commRole;
		thirdCut = secondCut.filter(x => x.commRole &&
			x.commRole.maxIOPPS >= commRoleToMatch.maxIOPPS &&
			x.commRole.maxTCPConns >= commRoleToMatch.maxTCPConns);

	}

	if (thirdCut)
		return thirdCut[0];

	return ctrlToMatch;
}

const _addNewCplxController = (project: ChassisProject, ctlrChassis: Chassis[]): boolean => {

	// See how many ctlr chassis we already have.
	const numCtlrs = ctlrChassis.length;

	// We SHOULD have AT LEAST 1. If so...
	if (numCtlrs > 0) {
		// Pick the last one.
		const srcChas = ctlrChassis[ctlrChassis.length - 1];

		// Get its controller.
		const ctlrToDup = srcChas.modules[0];

		// If we can...
		if (ctlrToDup && ctlrToDup.isController) {

			// Get the idx loc of that chassis' rack.
			const [, idxSrcRack] = getChassisAndRackIndexById(project, srcChas.id);

			// Create a new chassis immediately following that one.
			const newChassis = addChassis(project, srcChas.platform, srcChas.catNo, idxSrcRack + 1);

			// If we can...
			if (newChassis) {
				// Give it a 'split' version of the other's name.
				newChassis.name = getUniqueChassisName(`Split_${srcChas.name}`, project);

				// Add a matching controller. If that works, return success.
				if (addModuleAtSlot(newChassis, ctlrToDup.catNo, 0, true)) {
					return true;
				}
			}

			// Unexpected.
			throw new Error('Unexpected ERROR in _addNewCplxController!');
		}
		else {
			throw new Error('ERROR: No ctrl to dup in _addNewCplxController!');
		}
	}
	else {
		throw new Error('ERROR: No existing ctlrs in _addNewCplxController!');
	}
	return false;
}

const _fixCpLXMemory = (project: ChassisProject,
	ctlrChassis: Chassis[],
	shortage: number): boolean => {

	// Collect all controllers in the system.
	const allCtlrs = new Array<ChassisModule>();
	ctlrChassis.forEach(chassis => {
		collectCtlrs(chassis, allCtlrs);
	})

	// Call a helper to tell us if upsizing (alone) could give
	// us enough extra memory to cover our shortage. If so...
	if (isMemAvailViaUpsizing(allCtlrs, shortage)) {

		// Then bump each controller up one size as
		// possible. Along the way, we'll stop if
		// we cover our shortage.
		// Note that after this step, we might
		// STILL be short of memory. If so, we'll
		// end up getting called again, bumping a
		// size at a time, etc.
		return bumpControllerSizes(allCtlrs, shortage);
	}
	else {
		// Otherwise, call our helper to add another
		// controller for us and return its result.
		// On the next cycle, we might have enough memory,
		// or might be able to up-size, or add yet another
		// controller, etc.
		return _addNewCplxController(project, ctlrChassis);
	}

	return false;
}

const _autoFixCpLXSysPerf = (project: ChassisProject): boolean => {

	// Run our own calcs, but opt out of issue logging. We don't want
	// to RE-ADD the same issue that got us here in the first place.
	const [commsInfo, ctlrChassis] = runCommCalcs(project, false);

	// We SHOULD have at LEAST 1 controller chassis.
	if (ctlrChassis.length === 0) {
		throw new Error('No controller chassis in _autoFixCpLXSysPerf!');
	}

	// Determine the percentage level of performance
	// support that a warning would be included.
	const warnAt = getWarningThreshold(commsInfo);

	// Correct the memory BEFORE node-type controllers.
	// When memory is done after, we were getting an 
	// unneeded controller added.
	const memoryShort = getPrfShortage(PrfAspect.memory, commsInfo, warnAt);
	if (memoryShort > 0) {
		return _fixCpLXMemory(project, ctlrChassis, memoryShort);
	}

	// If the project has any node-type controllers...
	if (commsInfo.allCtlrChassis.anyEnetNodeType) {

		// See if we have an issue there.
		const nodesShort = getPrfShortage(PrfAspect.nodes, commsInfo, warnAt);

		// If so, our solution will be to add a new controller.
		// Note that the new one needs to be advanced in
		// order for it to give us any more Enet Nodes supported.
		if (nodesShort > 0) {
			return _addNewCplxController(project, ctlrChassis);
		}
	}


	return false;
}

const cplxAutoFixSysPerformance = (project: ChassisProject): boolean => {
	let anySuccessful = false;

	// We'll control snapshots ourselves, since
	// we may be adding more than 1 new chassis.
	// Take a snapshot for undo/redo.
	contentChanging(project.content);

	// Suspend any more snapshots while we add our chassis.
	const snapshotsWereSuspended = suspendUndoSnapshots(true);

	// Call our _autoFix helper repeatedly until
	// nothing still needs fixing.
	while (_autoFixCpLXSysPerf(project)) {
		anySuccessful = true;
	}

	// We're done. Set undo suspends
	// back to where we started.
	suspendUndoSnapshots(snapshotsWereSuspended);

	updateAllChassis(project.content);

	return anySuccessful;
}
const autofixCplxNoController = (
  project: ChassisProject,
  chassisID: string
): boolean => {
  const [chassis] = getChassisAndRackIndexById(project, chassisID);
  if (chassis) {
    contentChanging(project.content);
    const snapshotsWereSuspended = suspendUndoSnapshots(true);
    const allMods = getAvailableModules(chassis);
    let catalogNo = "";
    if (allMods.length > 0) {
      allMods.find((index) => {
        if (index.isCommModule) {
          catalogNo = index.catNo;
          return true;
        }
      });
    }
    addModuleAtSlot(chassis, catalogNo, 0, true);
    suspendUndoSnapshots(snapshotsWereSuspended);
    updateAllChassis(project.content);
    return true;
  }
  return false;
};