import {
    getInfoAllBanks,
    getNumBanks
} from "../../../model/ChassisProject";
import { Show5015BanksVertically } from "../../../types/Globals";
import {
    Chassis,
    ChassisLayoutInfo,
    EnvRating,
    BankInfo,
    BankExpModule,
    FlexHAChassis,
    FlexHASAPowerSupply
} from "../../../types/ProjectTypes";
import { LocAndSize, Point, Size } from "../../../types/SizeAndPosTypes";
import {
    DfltStageBaseScale,
    StageScaleInfo,
    DfltStageZoomBy,
    DfltSelRectMargin
} from "../../../types/StageTypes";
import { CableSpec, makeEmptyCableSpec } from "../../../util/CableHelp";
import {
    getEmptyLoc,
    getEmptySize,
    getLocRight,
    locToNormRect,
    scaleSize
} from "../../../util/GeneralHelpers";
import {
    flexHAGetNumIOBasesForModSlots
} from "./FlexHAGeneralImpl";
import { PlatformFlexHA } from "../../PlatformConstants";
import { getModuleEngInfo } from "../../../util/EngInfoHelp";
import { ChassisCompProps } from "../../../implementation/ImplGeneral";
import { LayoutModeType } from "../../../util/LayoutModeHelp";
import { createModuleFor } from "../../common/Common";


export const flexHAIOBase = '5015-A4IOKITXT';

export const maxFlexHAChassisSize = 25;
export const maxFlexHAIOBases = 6;
export const maxFlexHAIOModules = 24;
export const maxFlexHABankExtKits = 2;

export const flexHAStageScaleInfo: StageScaleInfo = {
    baseScale: 0.25,
    zoomBy: DfltStageZoomBy,
    zoomRangeFactor: 6
}

export const flexHAGetChassisCatalog = (envRat: EnvRating) => {
    switch (envRat) {
        case EnvRating.ExtTemperature:
            return '5015-CHASSIS-XT';
        case EnvRating.ConformalCoated:
            return '5015-CHASSIS-K';

        default:
            return '5015-CHASSIS';
    }
}

// FlexHALayoutInfo extents our basic chassis layout
// structure. Our chassis has one of these layouts, as
// well as do each aux bank we have.
// IMPORTANT NOTE:
// The 'size' property (inherited from ChassisLayoutInfo)
// is used in the following ways:
//    - for the chassis itself, it contains the COMBINED
//      sizes of bank 0 AND any aux banks.
//    - for layouts attached to aux banks, the size
//      property is set to 0,0.
export interface FlexHALayoutInfo extends ChassisLayoutInfo {

    // Locs of all I/O bases in the bank,
    // relative to the bank org pt.
    ioBaseLocs: LocAndSize[];

    // Locs of all I/O terminal blocks in the
    // bank, relative to the bank org pt.
    ioTBLocs: LocAndSize[];

    // Loc containing all non-ps hardware:
    //   Adapter kit (if first bank)
    //   All I/O bases
    //   Either (or both) extension
    //     components (when present)
    // Does NOT include backplate.
    nonPSCompsLoc: LocAndSize;

    // Loc for backplate, NOT including any
    // 'extra' I/O base added when extending.
    backplateLoc: LocAndSize;

    // SA No-Power Icon Buttons
    saIconBtnLocs: FlexHASAPwrBtnLoc[];

    // Locs of the two halves of the bank
    // extension kit (if/when present)
    expLeadComp?: BankExpModule;
    locLeadComp?: LocAndSize;

    expTrailComp?: BankExpModule;
    locTrailComp?: LocAndSize;
}

// Extend LocAndSize to include extra info.
export interface FlexHASAPwrBtnLoc extends LocAndSize {
    ioBaseIndex: number;
}

export const flexHAGetLayoutInfo = (chassis: Chassis, bankNum: number): FlexHALayoutInfo => {
    if ((bankNum >= 0) && (bankNum < getNumBanks(chassis))) {
        if (bankNum === 0) {
            return (chassis.layout as FlexHALayoutInfo);
        }
        else if (chassis.auxBanks) {
            const aux = chassis.auxBanks[bankNum - 1];
            if (aux) {
                return (aux.layout as FlexHALayoutInfo);
            }
        }
    }
    throw new Error('ERROR - Invalid bankNum in _getLayoutInfo!');
}


const _resetLayoutLocs = (layout: FlexHALayoutInfo) => {
    layout.slotLocs = new Array<LocAndSize>();
    layout.ioBaseLocs = new Array<LocAndSize>();
    layout.ioTBLocs = new Array<LocAndSize>();
    layout.saIconBtnLocs = new Array<FlexHASAPwrBtnLoc>(),
    layout.rightCapLoc = getEmptyLoc();
    layout.size = getEmptySize();
    layout.nonPSCompsLoc = getEmptyLoc();
    layout.backplateLoc = getEmptyLoc();
    layout.expLeadComp = undefined;
    layout.locLeadComp = undefined;
    layout.expTrailComp = undefined;
    layout.locTrailComp = undefined;
}

const _getBackplateLoc = (compsLoc: LocAndSize,
    bpInfo: FlxHABackplateDtls): LocAndSize => {

    return {
        x: compsLoc.x - bpInfo.sideExt,
        y: compsLoc.y + bpInfo.yOffset,
        width: compsLoc.width + (2 * bpInfo.sideExt),
        height: bpInfo.height
    }
}

// Helper. Given all other loc info already
// available in the incoming layout, establishes
// the loc surrounding all 'hardware', not including
// any sort of PS or backplate.
const _finalizeLayoutLoc = (
    layout: FlexHALayoutInfo,
    firstBank: boolean,
    bpInfo: FlxHABackplateDtls
) => {

    // Init loc depending on circumstances.
    // If first bank...
    if (firstBank) {
        // Then use the first slot loc, which should
        // contain the loc of the adapter kit.
        layout.nonPSCompsLoc = { ...layout.slotLocs[0] };
    }
    else {
        // Not the first bank. The layout SHOULD
        // have a loc for the extension kit piece
        // that sits on the left end of the bank.
        // If so, use that. Otherwise ERROR.
        if (layout.locLeadComp) {
            layout.nonPSCompsLoc = { ...layout.locLeadComp };
        }
        else {
            throw new Error('ERROR: missing locLeadComp in _establishHardwareLoc!');
        }
    }

    // Next, we'll determine the RIGHT side ord of
    // our hardware. Init to the right side of the loc
    // we're starting with.
    let hwRight = getLocRight(layout.nonPSCompsLoc);

    // If our layout has an extension to the right...
    if (layout.locTrailComp) {

        // Use the right side of that instead.
        hwRight = getLocRight(layout.locTrailComp);
    }
    else {

        // Must be the last bank. If it has any I/O
        // bases, use the right side of the LAST one.
        const lastIOBaseIdx = layout.ioBaseLocs.length - 1;
        if (lastIOBaseIdx >= 0) {
            hwRight = getLocRight(layout.ioBaseLocs[lastIOBaseIdx]);
        }
    }

    // Finally, extend the hardware loc's width using
    // that hardware-rightside ordinate.
    layout.nonPSCompsLoc.width = hwRight - layout.nonPSCompsLoc.x;

    // Use the comps loc to establish our backplate loc.
    layout.backplateLoc = _getBackplateLoc(layout.nonPSCompsLoc, bpInfo);
}

const _layoutBankSAPwr = (utChassis: Chassis) => {
    interface LocLayout {
        loc: LocAndSize,
        layout: FlexHALayoutInfo
    }

    // Cast the untyped chassis
    const chassis = utChassis as FlexHAChassis;

    // Collect an array of I/O Base LocAndSize's.
    // Each bank has its own array.
    const numBanks = getNumBanks(chassis);
    const ioBaseLocs: LocLayout[] = [];
    for (let idx = 0; idx < numBanks; ++idx) {
        const chassisLayout = flexHAGetLayoutInfo(chassis, idx);
        if (chassisLayout) {
            // For each I/O Base Loc...
            chassisLayout.ioBaseLocs.forEach(x => {
                // Store the base Loc and the Bank Layout 
                // it came from.
                const info = { loc: x, layout: chassisLayout };
                ioBaseLocs.push(info);
            });
        }
    }

    const sizeDtls = getFHASizeDetails();

    ioBaseLocs.forEach((info, idxBase) => {
        // If we have an SA PSU on the base...
        const saPSU = chassis.saPSU.find(psu => psu.ioBaseIndex === idxBase);
        if (saPSU) {
            saPSU.loc = {
                x: info.loc.x + sizeDtls.saPSUOffsets.width,
                y: info.loc.y + info.loc.height + sizeDtls.saPSUOffsets.height,
                width: sizeDtls.saPSU.width,
                height: sizeDtls.saPSU.height,
            }
        }
        else {
            // No PSU: Add the No-Power icon loc.
            const btnExtent = sizeDtls.saNoPwrIcon;

            info.layout.saIconBtnLocs.push({
                x: info.loc.x - (btnExtent / 2),
                y: info.loc.y + info.loc.height + sizeDtls.bpInfo.btmExt, // Move it down off the backplate..
                width: btnExtent,
                height: btnExtent,
                ioBaseIndex: idxBase,
            });
        }        
    });
}

const _finalizeLayoutSize = (chassis: Chassis) => {

    // Start a size empty.
    const size: Size = getEmptySize();

    // See how many banks we have. We should
    // ALWAYS have AT LEAST 1.
    const numBanks = getNumBanks(chassis);

    // If so...
    if (numBanks > 0) {

        // Get the layout for the chassis itself (bank 0).
        const chassisLayout = flexHAGetLayoutInfo(chassis, 0);

        // Convert its backplate loc to norm rect and
        // use that to set initial size.
        const chasRectBackplate = locToNormRect(chassisLayout.backplateLoc);
        size.width = chasRectBackplate.right;
        size.height = chasRectBackplate.bottom;

        // If any aux banks...
        if (numBanks > 1) {

            // Walk them. For each...
            for (let bankNum = 1; bankNum < numBanks; bankNum++) {

                // Get its layout.
                const auxLayout = flexHAGetLayoutInfo(chassis, bankNum);

                // Get its bp loc as a norm rect.
                const rAux = locToNormRect(auxLayout.backplateLoc);

                // Extend our size as needed.
                size.width = Math.max(size.width, rAux.right);
                size.height = Math.max(size.height, rAux.bottom);
            }
        }

        _layoutBankSAPwr(chassis);

        // If we have any SA PSUs...
        if ((chassis as FlexHAChassis).saPSU.length > 0) {
            // Enlarge the chassis extent height.
            const szDtls = getFHASizeDetails();
            size.height += szDtls.saPSU.height + szDtls.saPSUOffsets.height;
        }

        // Use our final size as the size of our PRIMARY
        // bank's layout (that of the chassis itself). The
        // size prop for aux banks is left empty.
        chassisLayout.size = size;
    }
    else {
        // Unexpected.
        throw new Error('Unexpected Error in _finalizeLayoutSize!');
    }
}

interface FlxHASizeDetailsIO {
    baseSize: Size;
    orgSlots: Point;
    slotSize: Size;
    orgIOTBs: Point;
    iotbSize: Size;
}

export interface FlxHABackplateDtls {
    yOffset: number;
    sideExt: number;
    btmExt: number;
    height: number;
}

export interface FlxHASizeDetails {
    imgScaleFactor: number;
    adapterKitLeft: number;
    sizeAdapterKit: Size;
    bpInfo: FlxHABackplateDtls;
    ioBaseInfo: FlxHASizeDetailsIO;
    sizeBankExpComp: Size;
    sizePwrJumper: Size;
    psuLoc1: LocAndSize;
    psuLoc2: LocAndSize;
    psuCables: CableSpec[];
    saPSU: Size;
    saNoPwrIcon: number; // Height/Width
    saPSUOffsets: Size;
}

// NOTE: We use 'scaled' here to know that we can use the
// info directly. For FlexHA, the image scale factor is 1.0,
// so 1 pixel for a position, width, or height will translate
// directly to 1 Stage Unit in use.
let _scaledSizeInfo: FlxHASizeDetails | undefined = undefined;

const _establishSizeDtls = () => {

    // Size of images used.
    // PSU modules
    const psuSize = { width: 120, height: 473 };

    const baseHeight = 915;

    // 4-slot I/O base unit.
    const ioBaseSize: Size = { width: 816, height: baseHeight };

    const bankExpCompWidth = 220;

    // Simplex I/O module (Duplex has twice width)
    const modImgSize: Size = { width: 204, height: 586 }; 

    // Simple I/O TB and cover (Duplex version has twice width)
    const iotbImgSize: Size = { width: 178, height: 270 }; 

    // Note: Our OVERALL scale for FlexHA is 1.0, which
    // generally means that 1 pixel === 1 stage unit. However,
    // in some cases we make some internal scale adjustments
    // when sizing our slots / modules and terminal blocks.

    // When NOT using our transparent image variants, the
    // slots (modules in position) look better when inset
    // a bit from the left and right sides. 
    //const slotSideMrg = Use5015TransparentImgs ? 0 : 6;
    const slotSideMrg = 0;

    const slotAreaWidth = ioBaseSize.width - (2 * slotSideMrg);
    const slotScale = slotAreaWidth / ioBaseSize.width;
    const slotSize = scaleSize(modImgSize, slotScale);

    const iotbSideMrg = 44;
    const iotbAreaWidth = ioBaseSize.width - (2 * iotbSideMrg);
    const tbWidthAvailEa = iotbAreaWidth / 4;
    const iotbScale = tbWidthAvailEa / iotbImgSize.width;
    const iotbSize = scaleSize(iotbImgSize, iotbScale);

    const iotbBtmMrg = 2;
    const iotbTop = ioBaseSize.height - iotbBtmMrg - iotbSize.height;

    // Our SA power jumper is scaled using the tb scale.
    const pwrJumperImgSize = { width: 80, height: 270 };
    const pwrJumperSize = scaleSize(pwrJumperImgSize, iotbScale);

    const psuTop = 240;
    const psu1LeftX = 0;
    const psuSep = 30;
    const psuToBP = 30;

    const bpYOffset = 210;
    const bpSideExt = 28;
    const bpBtmExt = 71;

    _scaledSizeInfo = {
        imgScaleFactor: 1.0,
        adapterKitLeft: psu1LeftX + (2 * psuSize.width) +
            psuSep + psuToBP + bpSideExt,
        sizeAdapterKit: { width: 995, height: baseHeight },
        //bpYOffset: bpYOffset,
        //bpSideExt: bpSideExt,
        //bpBtmExt: bpBtmExt,
        //bpHeight: baseHeight + bpBtmExt - bpYOffset,
        bpInfo: {
            yOffset: bpYOffset,
            sideExt: bpSideExt,
            btmExt: bpBtmExt,
            height: baseHeight + bpBtmExt - bpYOffset
        },
        ioBaseInfo: {
            baseSize: { ...ioBaseSize },
            orgSlots: { x: slotSideMrg, y: 59 },
            slotSize: slotSize,
            orgIOTBs: { x: iotbSideMrg, y: iotbTop },
            iotbSize: iotbSize
        },
        sizeBankExpComp: { width: bankExpCompWidth, height: baseHeight }, 
        sizePwrJumper: pwrJumperSize,
        psuLoc1: {
            x: psu1LeftX,
            y: psuTop,
            width: psuSize.width,
            height: psuSize.height
        },
        psuLoc2: {
            x: psu1LeftX + psuSize.width + psuSep,
            y: psuTop,
            width: psuSize.width,
            height: psuSize.height
        },
        psuCables: new Array<CableSpec>(),
        saPSU: { width: 200, height: 180 },
        saPSUOffsets: { width: -166, height: 0 },
        saNoPwrIcon: 108,
   };

    _makePSCableSpecs(_scaledSizeInfo);
}

export const getFHASizeDetails = (): FlxHASizeDetails => {
    if (!_scaledSizeInfo) {
        _establishSizeDtls();
    }
    if (_scaledSizeInfo) {
        return _scaledSizeInfo;
    }
    throw new Error('Unexpected ERROR in getFHASizeDetail!');
}

const _isOdd = (num: number): boolean => {
    return (num % 2) ? true : false;
}

const _isDuplexPrimary = (chassis: Chassis, slot: number): boolean => {
    if (_isOdd(slot)) {
        const mod = chassis.modules[slot];
        if (mod && mod.slotsUsed === 2) {
            return true;
        }
    }
    return false;
}

const _addLocs = (
    layout: FlexHALayoutInfo,
    info: FlxHASizeDetailsIO,
    nextSlotOrg: Point,
    nextTBOrg: Point,
    duplex: boolean
) => {
    const widthMult = duplex ? 2 : 1;
    const slotWidth = info.slotSize.width * widthMult;
    layout.slotLocs.push({
        x: nextSlotOrg.x,
        y: nextSlotOrg.y,
        width: slotWidth,
        height: info.slotSize.height
    });
    nextSlotOrg.x += slotWidth;

    const iotbWidth = info.iotbSize.width * widthMult;
    layout.ioTBLocs.push({
        x: nextTBOrg.x,
        y: nextTBOrg.y,
        width: iotbWidth,
        height: info.iotbSize.height
    });
    nextTBOrg.x += iotbWidth;

    if (duplex) {
        layout.slotLocs.push(getEmptyLoc());
        layout.ioTBLocs.push(getEmptyLoc());
    }
}

const _addIOBaseLocs = (
    chassis: Chassis,
    bankLayout: FlexHALayoutInfo,
    ioBaseNum: number,
    ptOrg: Point,
    ioBaseInfo: FlxHASizeDetailsIO
) => {

    // Add the loc for the base itself.
    bankLayout.ioBaseLocs.push({
        x: ptOrg.x,
        y: ptOrg.y,
        width: ioBaseInfo.baseSize.width,
        height: ioBaseInfo.baseSize.height
    });

    // Calculate the idx's of the first and last
    // chassis slot used by this I/O base unit.
    const firstSlotIdx = ioBaseNum * 4 + 1;
    const lastSlotIdx = firstSlotIdx + 3;

    // Start origin (upper-left) points.
    const slotOrg: Point = {
        x: ioBaseInfo.orgSlots.x + ptOrg.x,
        y: ioBaseInfo.orgSlots.y + ptOrg.y
    }

    const iotbOrg: Point = {
        x: ioBaseInfo.orgIOTBs.x + ptOrg.x,
        y: ioBaseInfo.orgIOTBs.y + ptOrg.y
    }

    // For each idx our base covers...
    for (let slotIdx = firstSlotIdx; slotIdx <= lastSlotIdx; slotIdx++) {

        // Determine if the module (if any) in the slot is
        // a duplex module. We only care if the slot is the
        // leftmost slot occupied by the module in question.
        const duplexMod = _isDuplexPrimary(chassis, slotIdx);

        // Call a helper to add associated locs for slot
        // and I/O terminal blocks. Note, the helper advances
        // our 'org' arguments each time it's called.
        _addLocs(bankLayout, ioBaseInfo, slotOrg, iotbOrg, duplexMod);

        // Duplex modules 'consume' two slots. If we have one,
        // advance our slot idx past the duplex mod's right slot.
        if (duplexMod) {
            slotIdx++;
        }
    }
}

const _getIOBasesInBank = (bankInfo: BankInfo):
    [
        numBases: number,
        startIdx: number,
        endIdx: number
    ] => {

    const modSlots = (bankInfo.bankNum === 0)
        ? bankInfo.slotsInBank - 1
        : bankInfo.slotsInBank;

    if (modSlots > 0) {
        const prevModSlots = (bankInfo.bankNum > 0)
            ? bankInfo.startSlot - 1
            : 0;
        const prevIOBases = flexHAGetNumIOBasesForModSlots(prevModSlots);
        const basesOnBank = flexHAGetNumIOBasesForModSlots(modSlots);
        return [basesOnBank, prevIOBases, prevIOBases + basesOnBank - 1];
    }
    else {
        return [0, -1, -2];
    }
}

const _layoutBank = (
    chassis: Chassis,
    bankInfo: BankInfo,
    ptOrg: Point,
    sizeDtls: FlxHASizeDetails
): Point => {

    const layout = flexHAGetLayoutInfo(chassis, bankInfo.bankNum);
    _resetLayoutLocs(layout);

    const basePt: Point = { ...ptOrg };

    if (bankInfo.firstBank) {
        // The FIRST slot loc will ALWAYS be used
        // for the adapter base (slot 0).
        layout.slotLocs.push({
            x: ptOrg.x,
            y: ptOrg.y,
            width: sizeDtls.sizeAdapterKit.width,
            height: sizeDtls.sizeAdapterKit.height,
        });
        basePt.x += sizeDtls.sizeAdapterKit.width;

        // Our I/O TB locs line up with the slots they're
        // used by. The adapter doesn't HAVE an I/O TB, so
        // we'll add an empty (unused) one at that position.
        layout.ioTBLocs.push(getEmptyLoc());
    }
    else {
        layout.locLeadComp = {
            x: basePt.x,
            y: basePt.y,
            width: sizeDtls.sizeBankExpComp.width,
            height: sizeDtls.sizeBankExpComp.height
        };
        basePt.x += sizeDtls.sizeBankExpComp.width;
        layout.expLeadComp =
            flexHAGetExpDevice(bankInfo, false);
        if (layout.expLeadComp) {
            layout.expLeadComp.parent = chassis;
        }
    }

    const [numBases, firstIdx, lastIdx] = _getIOBasesInBank(bankInfo);
    if (numBases > 0) {
        for (let ioBase = firstIdx; ioBase <= lastIdx; ioBase++) {

            // Add the associated locs (for slots and tbs).
            _addIOBaseLocs(chassis, layout, ioBase, basePt, sizeDtls.ioBaseInfo);

            // Then advance base left for the next one.
            basePt.x += sizeDtls.ioBaseInfo.baseSize.width;
        }
    }

    if (!bankInfo.lastBank) {
        layout.locTrailComp = {
            x: basePt.x,
            y: basePt.y,
            width: sizeDtls.sizeBankExpComp.width,
            height: sizeDtls.sizeBankExpComp.height
        };
        basePt.x += sizeDtls.sizeBankExpComp.width;
        layout.expTrailComp =
            flexHAGetExpDevice(bankInfo, true);
        if (layout.expTrailComp) {
            layout.expTrailComp.parent = chassis;
        }
    }

    _finalizeLayoutLoc(layout, bankInfo.firstBank, sizeDtls.bpInfo);
    return basePt;
}

const _getAuxBankOrg = (ptPrev: Point): Point => {
    return Show5015BanksVertically
        ? {
            x: 300,
            y: ptPrev.y + 1300
        }
        : {
            x: ptPrev.x + 300,
            y: ptPrev.y
        }
}

export const flexHALayoutChassis = (chassis: Chassis) => {

    const sizeDtls = getFHASizeDetails();

    const bankInfo = getInfoAllBanks(chassis);
    const numBanks = bankInfo.length;

    if (numBanks > 0) {
        const infoPrimary = bankInfo[0];

        let ptOrg: Point = {
            x: sizeDtls.adapterKitLeft,
            y: 0
        }

        let ptLastRight = _layoutBank(chassis, infoPrimary,
            ptOrg, sizeDtls);

        if (numBanks > 1) {
            for (let bank = 1; bank < numBanks; bank++) {
                ptOrg = _getAuxBankOrg(ptLastRight);
                const auxInfo = bankInfo[bank];
                ptLastRight = _layoutBank(chassis, auxInfo, ptOrg, sizeDtls);
            }
        }

        _finalizeLayoutSize(chassis);
    }
    else {
        throw new Error('ERROR: Invalid numBanks in flexHALayoutChassis!');
    }
}

export const flexHAGetSAPwrLocInfo = (chassis: FlexHAChassis, bankInfo: BankInfo):
    [jumperLocs: LocAndSize[], tbCoverLocs: LocAndSize[]] => {

    // Init empty return arrays.
    const jumperLocs = new Array<LocAndSize>();
    const tbCoverLocs = new Array<LocAndSize>();

    const [numIOBases, firstBaseIdx, /*lastIdx*/] = _getIOBasesInBank(bankInfo);

    // If the bank has ANY I/O bases...
    if (numIOBases > 0) {

        // Get general size and spacing dtls for FlexHA.
        const sizeDtls = getFHASizeDetails();

        // Get the layout for the bank itself.
        const bankLayout = flexHAGetLayoutInfo(chassis, bankInfo.bankNum);

        // Get the base idx's where SA power is applied.
        // NOTE: Returned idx's are chassis (not bank) relative.
        const saPoweredBases = chassis.saPSU.map(x => { return x.ioBaseIndex });

        // Get a representative I/O base loc in the bank.
        const repBaseLoc = bankLayout.ioBaseLocs[0];

        // Use that to establish the top y of our
        // power-related elements (jumpers, TBs).
        const topPwrEl = repBaseLoc.y + repBaseLoc.height -
            sizeDtls.sizePwrJumper.height;

        // Establish the remaining size elements we need.
        const pwrTBWidth = sizeDtls.sizePwrJumper.width / 2;
        const locHeight = sizeDtls.sizePwrJumper.height;

        // Init loc of working I/O base to empty.
        let baseLoc = getEmptyLoc();

        // Walk the I/O base locs in the bank's layout.
        // NOTE: The locs in the bank's layout are relative
        // to the BANK, not the chassis. For each...
        for (let baseLocIdx = 0; baseLocIdx < bankLayout.ioBaseLocs.length; baseLocIdx++) {

            // Get the loc.
            baseLoc = bankLayout.ioBaseLocs[baseLocIdx];

            // Convert to chassis-relative.
            const chassisBaseIdx = baseLocIdx + firstBaseIdx;

            // If this one has SA power...
            if (saPoweredBases.includes(chassisBaseIdx)) {
                // If this is NOT the FIRST base ON THIS BANK...
                if (baseLocIdx !== 0) {

                    // Add a tb cover loc to align with the
                    // lower-right corner of the PREVIOUS I/O base.
                    tbCoverLocs.push({
                        x: baseLoc.x - pwrTBWidth,
                        y: topPwrEl,
                        width: pwrTBWidth,
                        height: locHeight
                    });
                }
            }
            else {
                // This base does NOT get new SA power. Add
                // a loc for the jumper we'll have to transfer
                // power from the previous base to this one.
                jumperLocs.push({
                    x: baseLoc.x - pwrTBWidth,
                    y: topPwrEl,
                    width: pwrTBWidth * 2,
                    height: locHeight
                });
            }
        }

        // We'll ALWAYS have a tb cover on the right
        // side of the LAST I/O base in the bank.
        tbCoverLocs.push({
            x: baseLoc.x + baseLoc.width - pwrTBWidth,
            y: topPwrEl,
            width: pwrTBWidth,
            height: locHeight
        });
    }

    // Return our results.
    return [jumperLocs, tbCoverLocs];
}

const _getExpDevice = (bankNum: number, trailer: boolean): BankExpModule => {

    const devCat = trailer
        ? '5015-BEBLXT'
        : '5015-BEBRXT';

    const modInfo = getModuleEngInfo(PlatformFlexHA, devCat);
    if (modInfo) {
        const mod = createModuleFor(modInfo);
        if (mod && mod.isBankExp) {
            const bankExpMod = mod as BankExpModule;
            bankExpMod.bankNum = bankNum;
            bankExpMod.onRight = trailer;
            return bankExpMod;
        }
    }
    throw new Error('Unexpected ERROR in _getExpDevice!');

//    const engInfo = getBasicEngInfoFor(PlatformFlexHA, devCat);
//    if (engInfo) {
//        const category = DeviceCategory.Other;

//        const dev: BankExpModule = {
//            id: getNewInstanceId(),
//            platform: PlatformFlexHA,
//            deviceType: DeviceType.BankExpDevice,
//            catNo: devCat,
//            description: engInfo.description,
//            isPlaceholder: false,
//            extendedTemp: true,
//            conformal: true,
//            accysPossible: false,
//            category: category,
//            imgSrc: flexHAGetImageSource(category, engInfo.imgName),
//            imgSize: getEmptySize(),
//            selected: false,
//            movable: false,
//            dragStatus: ModuleDragStatus.NA,
//            parent: undefined,

//            slotIdx: -1,
//            slotID: -1,
//            slotsUsed: 0,
//            isController: false,
//            isComm: false,
//            isConnClient: false,
//            spclLocalConns: 0,
//            slotFiller: false,
//            isFPD: false,
//            isInterconnect: false,
//            isBankExp: true,

//            bankNum: bankNum,
//            onRight: trailer
//        }
//        return dev;
//    }
//    throw new Error('Unexpected ERROR in _getExpDevice!');
}

export const flexHAGetExpDevice = (
    bankInfo: BankInfo,
    trailer: boolean
): BankExpModule | undefined => {
    const compReqd = trailer
        ? !bankInfo.lastBank
        : !bankInfo.firstBank;

    if (compReqd) {
        return _getExpDevice(bankInfo.bankNum, trailer);
    }
    return undefined;
}

const _redPSUCableColor = 'red';
const _bluePSUCableColor = 'blue';
const _psuCableWidth = 8;  // strokeWidth

const _makePSCableSpecs = (sizeDtls: FlxHASizeDetails) => {

    // Empty any existing content.
    sizeDtls.psuCables.length = 0;

    // Calculate some common y ordinates.
    const btmPSU = sizeDtls.psuLoc1.y + sizeDtls.psuLoc1.height;
    const btmAdptr = sizeDtls.sizeAdapterKit.height;
    const btmBackplate = sizeDtls.bpInfo.yOffset +
        sizeDtls.bpInfo.height;

    // Cables drop out of PSU's at third
    // points across their widths.
    const psuThird = sizeDtls.psuLoc1.width / 3;

    // We have 4 cables, 1 red and 1 blue for each
    // PSU. They drop from the PSU, cross over to
    // the right, then 'rise' to touch the bottom
    // of the adapter base at specific pts.
    const riseR1X = sizeDtls.adapterKitLeft + 361;
    const riseB1X = sizeDtls.adapterKitLeft + 177;
    const riseR2X = sizeDtls.adapterKitLeft + 54;
    const riseB2X = sizeDtls.adapterKitLeft + 136;

    // Note: we'll do our OWN determination of scale
    // factor, since the stage info hasn't yet been
    // registered.
    const scaleFactor = DfltStageBaseScale / flexHAStageScaleInfo.baseScale
    const selRectMargin = DfltSelRectMargin * scaleFactor;

    // Highest crossing point needs to be below
    // the selection rect (when visible).
    const topCross = btmBackplate + (2 * selRectMargin);

    const factorDiff = scaleFactor - 1;

    // Decent looking vertical distance
    // between crossing cables.
    const crossVertSep = 20 + (4 * factorDiff);

    // Start the crossing y ord as the
    // lowest of our 4 cable crossings.
    let crossY = topCross + (3 * crossVertSep);

    // Set the smallest cable corner radius we want.
    const minRad = 22;

    // And how much the radius should change
    // from cable to cable.
    const radShrink = 16;

    // Use that info to set our starting radius.
    let radius = minRad + (3 * radShrink);

    // First cable drops from left of first PSU.
    let dropX = sizeDtls.psuLoc1.x + psuThird;

    // Set up that RED cable.
    const p1Red = makeEmptyCableSpec(_redPSUCableColor, _psuCableWidth, radius);
    p1Red.pathPts.push({ x: dropX, y: btmPSU });
    p1Red.pathPts.push({ x: dropX, y: crossY });
    p1Red.pathPts.push({ x: riseR1X, y: crossY });
    p1Red.pathPts.push({ x: riseR1X, y: btmAdptr });
    sizeDtls.psuCables.push(p1Red);

    // Adjust as we go.
    dropX += psuThird;
    crossY -= crossVertSep;
    radius -= radShrink;

    // BLUE cable for first PSU.
    const p1Blue = makeEmptyCableSpec(_bluePSUCableColor, _psuCableWidth, radius);
    p1Blue.pathPts.push({ x: dropX, y: btmPSU });
    p1Blue.pathPts.push({ x: dropX, y: crossY });
    p1Blue.pathPts.push({ x: riseB1X, y: crossY });
    p1Blue.pathPts.push({ x: riseB1X, y: btmAdptr });
    sizeDtls.psuCables.push(p1Blue);

    dropX = sizeDtls.psuLoc2.x + psuThird;
    crossY -= crossVertSep;
    radius -= radShrink;

    // RED cable for second PSU.
    const p2Red = makeEmptyCableSpec(_redPSUCableColor, _psuCableWidth, radius);
    p2Red.pathPts.push({ x: dropX, y: btmPSU });
    p2Red.pathPts.push({ x: dropX, y: crossY });
    p2Red.pathPts.push({ x: riseR2X, y: crossY });
    p2Red.pathPts.push({ x: riseR2X, y: btmAdptr });
    sizeDtls.psuCables.push(p2Red);

    dropX += psuThird;
    crossY -= crossVertSep;
    radius -= radShrink;

    // BLUE cable for second PSU.
    const p2Blue = makeEmptyCableSpec(_bluePSUCableColor, _psuCableWidth, radius);
    p2Blue.pathPts.push({ x: dropX, y: btmPSU });
    p2Blue.pathPts.push({ x: dropX, y: crossY });
    p2Blue.pathPts.push({ x: riseB2X, y: crossY });
    p2Blue.pathPts.push({ x: riseB2X, y: btmAdptr });
    sizeDtls.psuCables.push(p2Blue);
}

const _getTrailExpLoc = (layout: FlexHALayoutInfo): LocAndSize => {
    if (layout.locTrailComp) {
        const locTrail = { ...layout.locTrailComp };
        return locTrail;
    }
    throw new Error('ERROR: Invalid call to _getTrailExpLoc');
}

const _getLeadExpLoc = (layout: FlexHALayoutInfo): LocAndSize => {
    if (layout.locLeadComp) {
        const locLead = { ...layout.locLeadComp };
        return locLead;
    }
    throw new Error('ERROR: Invalid call to _getLeadExpLoc');
}

// Bank Cabling y offsets for data and power
// cable pairs. Numbers are distance down
// from the top of the expansion component.
const _coDA = 135;
const _coDB = 343;
const _coPA = 505;
const _coPB = 674;

// Other constants for bank cabling
const _bankCblWidth = 10;
const _vertCblRadius = 30;
const _clrDataCbl = '#7F7F7F';
const _clrPowerCbl = '#ED1C24';

const _getHorzCblSpec = (
    color: string,
    y: number,
    x1: number,
    x2: number
): CableSpec => {
    return {
        pathPts: [{ x: x1, y: y }, { x: x2, y: y }],
        color: color,
        width: _bankCblWidth,
        radius: 0
    }
}

const _addHorzCableSpecs = (
    specs: CableSpec[],
    locTop: number,
    fromX: number,
    toX: number
) => {
    specs.push(
        _getHorzCblSpec(_clrDataCbl, locTop + _coDA, fromX, toX)
    );
    specs.push(
        _getHorzCblSpec(_clrDataCbl, locTop + _coDB, fromX, toX)
    );
    specs.push(
        _getHorzCblSpec(_clrPowerCbl, locTop + _coPA, fromX, toX)
    );
    specs.push(
        _getHorzCblSpec(_clrPowerCbl, locTop + _coPB, fromX, toX)
    );
}

const _getVertCblSpec = (
    color: string,
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    extRight: number,
    yCross: number,
    extLeft: number
): CableSpec => {

    const pts: Point[] = new Array<Point>();

    // Start at x1, y1
    const pt: Point = { x: x1, y: y1 };
    pts.push({ ...pt });

    // Move to right.
    pt.x += extRight;
    pts.push({ ...pt });

    // Move down to crossing
    pt.y = yCross;
    pts.push({ ...pt });

    // Move left past endpt
    pt.x = x2 - extLeft;
    pts.push({ ...pt });

    // Move down to final y
    pt.y = y2;
    pts.push({ ...pt });

    // Move right to endpt.
    pt.x = x2;
    pts.push(pt);

    return {
        pathPts: pts,
        color: color,
        width: _bankCblWidth,
        radius: _vertCblRadius
    }
}

const _verCblSep = 30;
const _horzCblSep = 20;

const _addVertCableSpecs = (
    specs: CableSpec[],
    fromX: number,
    locTopFrom: number,
    toX: number,
    locTopTo: number
) => {
    let rExt = _verCblSep * 7;
    let yCross = locTopTo - (_horzCblSep * 2);
    let lExt = _verCblSep * 3;

    specs.push(
        _getVertCblSpec(
            _clrDataCbl,
            fromX,
            locTopFrom + _coDA,
            toX,
            locTopTo + _coDA,
            rExt,
            yCross,
            lExt
        )
    );

    rExt -= _verCblSep;
    yCross -= _horzCblSep;
    lExt += _verCblSep;

    specs.push(
        _getVertCblSpec(
            _clrDataCbl,
            fromX,
            locTopFrom + _coDB,
            toX,
            locTopTo + _coDB,
            rExt,
            yCross,
            lExt
        )
    );

    rExt -= (2 * _verCblSep);
    yCross -= (2 * _horzCblSep);
    lExt += (2 * _verCblSep);

    specs.push(
        _getVertCblSpec(
            _clrPowerCbl,
            fromX,
            locTopFrom + _coPA,
            toX,
            locTopTo + _coPA,
            rExt,
            yCross,
            lExt
        )
    );

    rExt -= _verCblSep;
    yCross -= _horzCblSep;
    lExt += _verCblSep;

    specs.push(
        _getVertCblSpec(
            _clrPowerCbl,
            fromX,
            locTopFrom + _coPB,
            toX,
            locTopTo + _coPB,
            rExt,
            yCross,
            lExt
        )
    );

}

const _addBankCableSpecs = (
    specs: CableSpec[],
    fromLoc: LocAndSize,
    toLoc: LocAndSize
) => {
    const fromX = getLocRight(fromLoc);
    if (fromX < toLoc.x) {
        _addHorzCableSpecs(specs, fromLoc.y, fromX, toLoc.x);
    }
    else {
        _addVertCableSpecs(specs, fromX, fromLoc.y, toLoc.x, toLoc.y);
    }
}

export const getBankCableSpecs = (chassis: Chassis): CableSpec[] => {
    const specs = new Array<CableSpec>();

    const numBanks = getNumBanks(chassis);
    if (numBanks > 1) {
        let prevLayout = flexHAGetLayoutInfo(chassis, 0);
        for (let bank = 1; bank < numBanks; bank++) {
            const fromLoc = _getTrailExpLoc(prevLayout);
            const thisLayout = flexHAGetLayoutInfo(chassis, bank);
            const toLoc = _getLeadExpLoc(thisLayout);
            _addBankCableSpecs(specs, fromLoc, toLoc);
            prevLayout = thisLayout;
        }
    }

    return specs;
}

export const flexHAGetCableOpacity = (rndProps: ChassisCompProps): number => {
    if (rndProps.layoutMode) {
        if (rndProps.layoutMode.type === LayoutModeType.Normal) {
            return (rndProps.showAsSelected ? 0.6 : 1.0);
        }
        else {
            return 0.3;
        }
    }
    return 1.0;
}

export const flexHAMapSAPower = (chassis: FlexHAChassis):
    Map<number, FlexHASAPowerSupply> => {

    const saPSMap = new Map<number, FlexHASAPowerSupply>();

    chassis.saPSU.forEach(ps => {
        saPSMap.set(ps.ioBaseIndex, ps);
    });

    return saPSMap;
}
