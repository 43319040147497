import { CheckerImplementation, RegisterCheckerImpl } from "../../../implementation/ImplChecker";
import { PlatformFlex } from "../../PlatformConstants";
import { snapDoChassisPowerCheck, snapDoGeneralCheck } from "../../snap/SnapChecker";
import { flexExecuteAutoFix } from "./FlexAutoFix";


export const RegisterFlexCheckerImpl = () => {
    const flexImpl: CheckerImplementation = {
        platform: PlatformFlex,
        doGeneralCheck: snapDoGeneralCheck,
        doChassisPowerCheck: snapDoChassisPowerCheck,
        executeAutoFix: flexExecuteAutoFix,
    };

    RegisterCheckerImpl(flexImpl);
}
