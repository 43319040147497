import { getEngineeringInfoFor, getEnvRatingFromSpec } from "../model/ChassisProject";
import { Chassis, ChassisModule, EnvRating } from "../types/ProjectTypes";
import { LocAndSize, Point } from "../types/SizeAndPosTypes";
import { getEmptyLoc, getEmptyPt, getLocCenteredAt, makeBold } from "./GeneralHelpers";
import { unexpectedError } from "./ErrorHelp";
import { getMsgTextForEnvType } from "./Messages";
import { getMaxNewModules, getModuleSlotRestriction } from "../implementation/ImplGeneral";
import { PlatformFlexHA } from "../platforms/PlatformConstants";

const _msgIndent = '       ';


export const DragItemTypes = {
    TREE_DEVICE: 'TreeDevice'
}

export enum DropStatus {
    NoDrop = 'NoDrop',
    DropOk = 'DropOk',
    DropOkAfterSwap = 'DropOkAfterSwap'

    // Future?
    //Insert = 'Insert',
}

export enum DropResult {
    DropSuccess = 'DropSuccess',
    DropFailed = 'DropFailed',
    ConfirmReqd = 'ConfirmReqd'
}

export interface DragDeviceInfo {
    // Reference to the actaul module being
    // dragged (moved or copied).
    dragMod: ChassisModule;

    // True ONLY if the device IS a
    // standard-rated slot filler.
    stdSlotFiller: boolean;

    // Used to determine filtering for image
    // during drag. Should be set to true when
    // source image is mostly dark (like 5015).
    darkImage: boolean;

    // Current ability to drop
    // if we dropped it now.
    dropStatus: DropStatus;

    // Image and size info.
    imgSrc: string;
    scale: number;
    ptCtr: Point;
    loc: LocAndSize;

    // Env type support for the
    // actual id represented.
    extendedTempCompat: boolean;
    conformal: boolean;

    // Alternate catalog numbers that
    // COULD be used INSTEAD of our id
    // for a drop into/onto an environment
    // not directly supported by id.
    // Where a given alternate is not available,
    // the associated property is undefined.
    altStd?: string;
    altET?: string;
    altCC?: string;

    // The actual alternate catalog number that
    // should be used INSTEAD of our normal id
    // or module. Its value should only be
    // considered valid ONLY if our DropStatus is
    // DropStatus.DropOkAfterSwap.
    swapToCatNo?: string;

    // True ONLY if we have a referenced
    // module (module drag) AND the Ctrl
    // key is currently down. (Will ALWAYS
    // be false for drags from the tree.)
    copy: boolean;

    // True ONLY if the last status check found
    // that the test point was over a slot, and
    // that point was to the right of its center.
    rightSide: boolean;

    // True only when an extra insertion graphic
    // should be displayed during a drag.
    showInsert: boolean;

    // Used only when .showInsert is true. Provides
    // info for positioning the insertion graphic.
    insertLoc: LocAndSize;

    // Generally set to true ONLY when a non-typical
    // device is dropped. Example: FlexHA sets this to
    // true when a bank expansion component is dropped.
    skipAccys: boolean;
}

const _useDarkImage = (module: ChassisModule): boolean => {
    switch (module.platform) {
        case PlatformFlexHA:
            return (module.isBankExp || (module.slotIdx > 0));

        default:
            return false;
    }
}

export const makeModuleDragInfo = (module: ChassisModule, startAsCopy: boolean): DragDeviceInfo => {

    const engInfo = getEngineeringInfoFor(module);
    if (engInfo) {
        const pt00 = getEmptyPt();

        return {
            dragMod: module,
            stdSlotFiller: (module.slotFiller && !module.extendedTemp && !module.conformal),
            darkImage: _useDarkImage(module),
            dropStatus: DropStatus.NoDrop,
            imgSrc: module.imgSrc,
            scale: 1.0,
            ptCtr: pt00,
            loc: getLocCenteredAt(pt00, module.imgSize.width, module.imgSize.height),
            extendedTempCompat: module.extendedTemp,
            conformal: module.conformal,
            altStd: engInfo.getAlternate(false, false),
            altET: engInfo.getAlternate(false, true),
            altCC: engInfo.getAlternate(true, false),
            copy: startAsCopy,
            rightSide: false,
            showInsert: false,
            insertLoc: getEmptyLoc(),
            skipAccys: false
        };
    }
    else {
        throw new Error('makeModuleDragInfo FAILED to get engineering data for: ' + module.catNo);
    }
}

export const updateDragInfo = (
    devInfo: DragDeviceInfo,
    ptCenter: Point,
    copy: boolean
) => {
    devInfo.ptCtr = { ...ptCenter };
    devInfo.loc = getLocCenteredAt(ptCenter, devInfo.loc.width, devInfo.loc.height);
    devInfo.copy = copy;
}

// NOTE: Assumes that caller has ALREADY DETERMINED
// that the device represented by the drag device info
// is NOT compatible with env requirements of a
// (potential) drop target (a chassis).
export const getDragDeviceAlternate = (devInfo: DragDeviceInfo, targetChassis: Chassis)
    : string | undefined => {

    // Check first to make sure our request isn't telling
    // us that the target is both XT AND Conformal. We don't
    // have any chassis (yet) that are both, and we don't
    // support it (again, yet).
    if (targetChassis.extendedTemp && targetChassis.conformal) {
        unexpectedError('getDragDeviceAlternate for unexpected ' +
            'case where target is BOTH XT AND Conformal!', true, false);
        return undefined;
    }
    else {
        // Target is NOT both. If it's XT...
        if (targetChassis.extendedTemp) {
            // and the device is not...
            if (!devInfo.extendedTempCompat) {
                // Return the extended temp alternate
                // (or undefined if not available).
                return devInfo.altET;
            }
        }

        // If the target is conformal (if it
        // is, then it CAN'T ALSO be XT)...
        if (targetChassis.conformal) {
            // and the device is not...
            if (!devInfo.conformal) {
                // Return the conformal alternate
                // (or undefined if not available).
                return devInfo.altCC;
            }
        }

        // If the target is NEITHER XT nor conformal...
        if (!(targetChassis.extendedTemp || targetChassis.conformal)) {
            // and the device is EITHER...
            if (devInfo.extendedTempCompat || devInfo.conformal) {
                // Return the standard alternate
                // (or undefined if not available).
                return devInfo.altStd;
            }
        }

        // If we're still here...
        // Our drag info ALREADY MATCHES what the
        // caller says is needed. We shouldn't have
        // ever been called in that case.
        unexpectedError('getDragDeviceAlternate unexpected case: ' +
            'Device ALREADY MATCHES target chassis!', true, false);
        return undefined;
    }
}

export const getBestPossibleDropStatus = (dragDev: DragDeviceInfo, chassis: Chassis): DropStatus => {

    // If platforms match...
    if (dragDev.dragMod.platform === chassis.platform) {

        // Get eng info for the chassis and dragged module.
        const chasInfo = getEngineeringInfoFor(chassis);
        const modInfo = getEngineeringInfoFor(dragDev.dragMod);

        // if we can....
        if (chasInfo && modInfo) {

            // FPD modules are auto-positioned. If this
            // module is an FPD, don't allow it to be
            // dropped anywhere.
            if (modInfo.isFPD) {
                return DropStatus.NoDrop;
            }

            // Next, we'll do a basic check to see if the
            // module would FIT into the target chassis.
            // Start by determining if the move or copy 
            // would be local (drag mod already in the chassis).
            const local = (dragDev.dragMod.parent === chassis);

            // If we have an Interconnect Cable, 
            // it MUST stay in the same local chassis.
            if (modInfo.isInterconnect) {
                if(local)
                    return DropStatus.DropOk;
                return DropStatus.NoDrop;
            }

            // If NOT local, OR if this is a copy...
            if (!local || dragDev.copy) {

                // Then the target chassis would need ROOM
                // to accept it. Determine if the drag module
                // has a first-slot-only restriction
                const restrict = getModuleSlotRestriction(modInfo);

                // See if the chassis could take another module.
                // If not, we can return NoDrop without more analysis.
                // Note: getMaxNewModules() will NOT treat slot fillers
                // as empty slots (3rd param false).
                if (getMaxNewModules(chassis, restrict, false) === 0) {
                    return DropStatus.NoDrop;
                }
            }

            // If env ratings match, best possible is a clean drop.
            if (modInfo.isCompatibleWithEnv(chasInfo.envInfo.rating)) {
                dragDev.swapToCatNo = undefined;
                return DropStatus.DropOk;
            }

            // Ratings don't match. See if there's
            // an appropriate alternate available.
            const alternate = getDragDeviceAlternate(dragDev, chassis);

            // If so, best possible is ok-after-swap.
            if (alternate) {
                dragDev.swapToCatNo = alternate;
                return DropStatus.DropOkAfterSwap;
            }
        }
        else {
            throw new Error('Missing eng info in getBestPossibleDropStatus!');
        }
    }

    // No-drop if we get here.
    return DropStatus.NoDrop;
}

const _getDropSwapDtl = (dragDev: DragDeviceInfo)
    : [swapToCat: string, targetEnv: EnvRating] => {
    const swapToCat = dragDev.swapToCatNo ? dragDev.swapToCatNo : '';
    if (swapToCat.length) {
        if (dragDev.swapToCatNo === dragDev.altStd) {
            return [swapToCat, EnvRating.Standard];
        }
        else if (dragDev.swapToCatNo === dragDev.altCC) {
            return [swapToCat, EnvRating.ConformalCoated];
        }
        else if (dragDev.swapToCatNo === dragDev.altET) {
            return [swapToCat, EnvRating.ExtTemperature];
        }
    }

    unexpectedError('_getDropSwapDtl found INVALID swap ' +
        'info in drag device.', true, false);
    return ['', EnvRating.Standard];
}

const _getSwapConfirmMsg = (
    dragCat: string,
    swapCat: string,
    devEnv: EnvRating,
    targetEnv: EnvRating
): string => {

    let msg = '';
    if (targetEnv === EnvRating.Standard) {
        msg += 'The device dropped is ';
        msg += makeBold(getMsgTextForEnvType(devEnv));
        msg += ', but the target chassis is not.\n\n';
    }
    else {
        msg += 'The target chassis is ';
        msg += makeBold(getMsgTextForEnvType(targetEnv));
        msg += ', but the dropped device is not.\n\n';
    }
    msg += _msgIndent + 'Device dropped: ' + makeBold(dragCat) + '\n';
    msg += _msgIndent + 'Replacement:    ' + makeBold(swapCat) + '\n\n';
    msg += 'If you wish to continue, the Replacement device displayed above ';
    msg += 'will be used instead. The Replacement is a version of the dropped '
    msg += 'device that matches the environmental rating of the chassis.';
    return msg;
}

export const getDropSwapInfo = (dragDev: DragDeviceInfo): [
    swapOk: boolean,
    confirmMsg: string
] => {

    if (dragDev.dropStatus === DropStatus.DropOkAfterSwap) {
        const [catToUse, targetEnvType] = _getDropSwapDtl(dragDev);
        const dragDevEnvType =
            getEnvRatingFromSpec(dragDev.extendedTempCompat, dragDev.conformal);
        const msg = _getSwapConfirmMsg(dragDev.dragMod.catNo, catToUse, dragDevEnvType, targetEnvType);
        return [true, msg];
    }

    return [false, 'ERROR'];
}

export const getChassisSwapConfirmMsg = (
    oldCat: string,
    newCat: string,
    envChange: boolean,
    newXT: boolean,
    newConformal: boolean,
    movesReqd: boolean,
    swapMap: Map<string, string> | null
): string => {


    let msg = 'Please confirm that you wish to change this chassis from ';
    msg += oldCat + ' to ' + newCat;

    if (envChange) {
        msg += ', which is ';
        msg += getMsgTextForEnvType(getEnvRatingFromSpec(newXT, newConformal));
        msg += '.';
    }
    else {
        msg += '.';
    }

    if (swapMap && (swapMap.size > 0)) {
        msg += '\n\nExisting modules will be replaced with the following ' +
            'counterparts that match the new environment requirement:\n\n';
        swapMap.forEach((newMod, oldMod) => {
            msg += '   ' + newMod + '  (replaces ' + oldMod + ')\n';
        });
    }
    else {
        msg += '\n';
    }

    if (movesReqd) {
        msg += '\nAlso note that since the new chassis has fewer slots, some ' +
            'modules will be moved leftward as needed, but all ' +
            ' will retain their same relative order.';
    }

    return msg;
}

export const getChassisSwapMsgForBadSwaps = (
    newCat: string,
    newXT: boolean,
    newConformal: boolean,
    badSwaps: Set<string>
): string => {
    let msg = '';
    msg += 'The chassis selected ' + '(' + newCat + ') is ';
    msg += getMsgTextForEnvType(getEnvRatingFromSpec(newXT, newConformal));
    msg += '. Suitable counterparts matching the new environment ';
    msg += 'could not be found for the following modules:\n\n';

    badSwaps.forEach(cat => {
        msg += '   ' + cat + '\n';
    });

    msg += '\nIf you wish to change to this chassis, manually remove the ';
    msg += 'modules listed and try again.';

    return msg;
}
