import { EngInfoChassis, EngInfoComponent, EngInfoModule } from "../../../engData/EngineeringInfo";
import {
    createModule,
    GeneralImplSpec,
    RegisterGeneralImpl,
    updateChassis,
    ChassisCompProps,
    canExtendChassis,
    addModuleAtSlot,
    getModuleSlotRestriction,
    standardGetChassisElementAtPt
} from "../../../implementation/ImplGeneral";
import {
    detachModule,
    getDeviceCategory,
    isDeviceCompatibleWithChassis,
    getNumBanks,
    getNewBankInfo,
    getBankInfo,
    getSlotBankInfo,
    updateBanks,
    getRackOrgPt,
    getAuxBankStartSlots,
    updateAllChassis,
    chassisChanged,
    getBankInfoFromSlot
} from "../../../model/ChassisProject";
import {
    Chassis,
    AuxBank,
    ChassisElement,
    ChassisModule,
    DeviceCategory,
    DeviceType,
    FlexHAChassis,
    IOModuleWiring,
    ModuleDragStatus,
    ModuleSlotRestriction,
    NO_SLOT,
    Rack,
    GraphicalDevice,
    BankInfo,
    FlexHASAPowerSupply,
    BankExpModule,
    ChassisProject,
    SelectableDevice
} from "../../../types/ProjectTypes";
import {
    PowerBreakdown,
    PowerBreakdownTips,
} from "../../../types/PowerTypes";
import { LocAndSize, Point, Size } from "../../../types/SizeAndPosTypes";
import {
    DragDeviceInfo,
    DropResult,
    DropStatus,
} from "../../../util/DragAndDropHelp";
import { addRequiredAccys } from "../../../util/AccessoriesHelp";
import { getEngInfoForComp } from "../../../util/EngInfoHelp";
import { getNewInstanceId } from "../../../util/InstanceIdHelp";
import {
    chassisChanging,
    suspendUndoSnapshots
} from "../../../util/UndoRedo";
import { PlatformFlexHA } from "../../PlatformConstants";
import {
    RegisterSnapClientDetails,
    snapFilterAvailableModules,
    snapGetDefaultChassisName,
    snapGetModuleSlotRestriction,
    snapGetSlotTypeRestriction,
    SnapPlatformDetails
} from "../../snap/SnapGeneralImpl";
import FlexHAChassisComp from "../components/FlexHAChassisComp";
import { flexHACreatePowerSupply, flexHA_DefSAPsu } from "./FlexHAHardwareImpl";
import {
    getEmptyLoc,
    getEmptySize,
    getLocCenter,
    getLocRight,
    isEmptyLoc,
    isPointInLoc,
    offsetLoc
} from "../../../util/GeneralHelpers";
import { ActBtnInfo, DfltActBtnSpecs, LayoutActionType } from "../../../types/LayoutActions";
import { SelectedCompInfo } from "../../../selectComponents/SelectComponentsTypes";
import { snapConfigureChassis } from "../../snap/SnapChassisConfig";
import { LayoutMode } from "../../../util/LayoutModeHelp";
import {
    flexHADeleteSAPwr,
    flexHADoesSlotQualifyForCopy,
    flexHADuplicateChassis,
    flexHAIsSAPowerSupply
} from "./FlexHAChassis";
import {
    flexHAStageScaleInfo,
    FlexHALayoutInfo,
    flexHALayoutChassis,
    getFHASizeDetails,
    maxFlexHAChassisSize,
    maxFlexHAIOModules,
    flexHAGetLayoutInfo,
    FlexHASAPwrBtnLoc,
} from "./FlexHALayout";
import { Show5015BanksVertically } from "../../../types/Globals";
import { logger } from "../../../util/Logger";
import { flexHAGetMODPowerBreakdown } from "./FlexHAChecker";
import { StatusLevel } from "../../../types/MessageTypes";
import { createModuleFor } from "../../common/Common";
import { StageScaleInfo } from "../../../types/StageTypes";
import { flexHAGetChassisRendInfo } from "./FlexHASysDsg";
import { flexHAFixSAPwrIssues } from "./FlexHAAutoFix";


const _localImgDir = '/assets/flexHA/';

const _validIOBaseStartSlots = [1, 5, 9, 13, 17, 21];

export const flexHAIsValidIOBaseStartSlot = (idx: number): boolean => {
    return _validIOBaseStartSlots.includes(idx);
}

export const flexHAGetEmptySlotImage = (chassis: Chassis): string => {
    chassis;
    return _localImgDir + '5015-Empty.png';
}

export const flexHAGetDefaultXSlotWidth = (platform: string): number => {
    platform;
    const dtls = getPlatformDetails();
    const xSlotWidth = dtls.defaultXSlotWidth * dtls.imgScaleFactor;
    return xSlotWidth;
}

let _platformDetails: SnapPlatformDetails | undefined = undefined;

const getPlatformDetails = (): SnapPlatformDetails => {
    if (!_platformDetails) {

        const sizeDtls = getFHASizeDetails();

        _platformDetails = {
            imgScaleFactor: sizeDtls.imgScaleFactor,
            leftSlotStartSize: { ...sizeDtls.sizeAdapterKit },
            defaultXSlotWidth: sizeDtls.ioBaseInfo.baseSize.width,
            firstSlotRestricted: true,
            absMaxModules: maxFlexHAChassisSize,
            cableSplitAllowed: false,
            rightCapInfo: undefined,
            getFPDMap: undefined,
            isALeftSlotModule: (modInfo: EngInfoModule) => { return modInfo.isComm },
        }
    }

    return _platformDetails;
}

export enum FlexHABaseType {
    IOBase = 'I/O Base',
    AdapterBase = 'Adapter Base',
}

export const flexHACreateModule = (catNo: string): ChassisModule | null => {
    const info = getEngInfoForComp(PlatformFlexHA, catNo);
    if (info) {
        if (info.isModule)
            return createModuleFor(info as EngInfoModule);
    }

    return null;
}

const flexHACreateChassis = (chasEngInfo: EngInfoChassis, psCatNo?: string): Chassis | undefined => {

    const layout: FlexHALayoutInfo = {
        platform: chasEngInfo.platform,
        numFPDs: 0,
        extendedTemp: chasEngInfo.envInfo.etOk,
        conformal: chasEngInfo.envInfo.ccOk,
        slotLocs: new Array<LocAndSize>(),
        rightCapImgSrc: '',
        rightCapLoc: getEmptyLoc(),
        size: getEmptySize(),
        ioBaseLocs: new Array<LocAndSize>(),
        ioTBLocs: new Array<LocAndSize>(),
        nonPSCompsLoc: getEmptyLoc(),
        backplateLoc: getEmptyLoc(),
        saIconBtnLocs: new Array<FlexHASAPwrBtnLoc>(),
    };

    const chassis: FlexHAChassis = {
        id: getNewInstanceId(),
        bump: 0,
        dragTarget: false,
        xSlotWidth: 0,
        platform: chasEngInfo.platform,
        deviceType: DeviceType.Chassis,
        catNo: chasEngInfo.catNo,
        description: chasEngInfo.description,
        isPlaceholder: chasEngInfo.isPlaceholder,
        imgSrc: '',
        extendedTemp: chasEngInfo.envInfo.etOk,
        conformal: chasEngInfo.envInfo.ccOk,
        accysPossible: chasEngInfo.anyAccysPossible(),
        layout: layout,
        ps: flexHACreatePowerSupply(psCatNo),
        modules: new Array<ChassisModule>(1),
        selected: false,
        parent: undefined,
        redundant: false,
        defaultIOModWiring: IOModuleWiring.Screw,
        statusLog: undefined,
        saPSU: [],

        // Leave auxBanks and primaryBankInfo
        // undefined by default. They're only used
        // when we have MORE than a single bank.
    }

    if (chassis.ps) {
        chassis.ps.parent = chassis;
    }

    updateChassis(chassis);

    return chassis;
}


export const getPlaceholderModSource = (module: ChassisModule): ChassisModule => {
    let parentMod = module;
    if (parentMod.isPlaceholder && module.parent) {
        // For clarity...
        const chassis = module.parent;
        for (let ritr = module.slotIdx - 1; ritr >= 0; --ritr) {
            const prevMod = chassis.modules[ritr];
            if (!prevMod || prevMod.id !== module.id)
                break;

            parentMod = prevMod;
        }
    }

    return parentMod;
}


// Check to see if the specified module COULD be added to
// the given chassis. If yes, answers the first slot that
// would work. If no, answers NO_SLOT (-1).
const _testModuleAddition = (catNo: string, chassis: Chassis, slot = -1): number => {
    const maxSlotIndex = maxFlexHAIOModules;

    // Check if the slot is defined. 5015 can have
    // a max of 6 4-slot I/O bases plus any Bank 
    // Extension Kits. Is the slot in this range...
    if (slot >= 0 && slot > maxSlotIndex)
        return NO_SLOT;

    // Get the module info.
    const engInfo = getEngInfoForComp(PlatformFlexHA, catNo) as EngInfoModule;
    if (!engInfo)
        return NO_SLOT;

    const duplex = (engInfo.slotsUsed === 2);
    const lenModArray = chassis.modules.length;
    const leftSlot = engInfo.isComm;
    if (leftSlot) {
        // Slot passed in must be 0 or -1.
        if (slot !== -1 && slot !== 0)
            return NO_SLOT;
        // Slot 0 must be empty. Slot fillers
        // are NOT valid here...
        if (chassis.modules[0] != null)
            return NO_SLOT;

        return 0;
    }
    else if (slot > 0) {
        // We have a specific slot to add the module...
        if (duplex) {
            // We have a duplex module. As organized with
            // the Comm in slot 0, each I/O base has 2 to 4
            // slots and a duplex module cannot bridge I/O
            // bases and cannot bridge the center div in a 4 slot
            // I/O base. So,the slot we are adding it to MUST
            // be an ODD number. Also if the next slot is beyond
            // the allowed max slots...
            if (slot % 2 === 0 || slot + 1 > maxSlotIndex)
                return NO_SLOT;

            // IF the slot exists in the array...
            if (slot < lenModArray) {
                let slotMod = chassis.modules[slot];
                if (slotMod && !slotMod.slotFiller)
                    return NO_SLOT;
                slotMod = chassis.modules[slot + 1];
                if (slotMod && !slotMod.slotFiller)
                    return NO_SLOT;
            }

            // Return true here since we can add
            // I/O bases to accommodate this module.
            return slot;
        }
        else {
            // IF the slot exists in the array...
            if (slot < lenModArray) {
                const slotMod = chassis.modules[slot];
                if (slotMod && !slotMod.slotFiller)
                    return NO_SLOT;
            }

            // Return true here since we can add
            // I/O bases to accommodate this module.
            return slot;
        }
    }
    else {
        // We do not have a specific slot for a module.
        // Do we have an open slot - first if we have
        // the max modules allowed in our array (meaning
        // we CANNOT extend the chassis)...
        if (lenModArray === maxFlexHAChassisSize) {
            if (duplex) {
                // Need to find 2 adjacent empty slots where
                // the first slot's index is ODD (not even).
                let success = false;
                let idxSlot = NO_SLOT;
                for (let idx = 1; idx < lenModArray; idx += 2) {
                    const slotA = chassis.modules[idx];
                    const slotB = chassis.modules[idx + 1];
                    if (slotA && slotB) {
                        success = (slotA.slotFiller && slotB.slotFiller);
                    }
                    else if (slotA && !slotB) {
                        success = slotA.slotFiller;
                    }
                    else if (!slotA && slotB) {
                       success = slotB.slotFiller;
                    }
                    else {
                        // both slots are empty
                        success = true;
                    }

                    if (success) {
                        idxSlot = idx;
                        break;
                    }
                }

                return idxSlot;
            }
            else {
                let idxSlot = NO_SLOT;
                // Set idxSlot to any open slots.
                (chassis.modules.some((mod, idx) => {
                    if (idx > 0) {
                        if (!mod || mod.slotFiller) {
                            idxSlot = idx;
                            return true;
                        }
                    }
                    return false;

                }));

                return idxSlot;
            }
        }
        else {
            // We have room to add another I/O base if needed.
            if (duplex) {
                // Try to find to adjacent emtpy slots. We will
                // only look at ODD indexes (1,3,5, etc.)
                for (let idx = 1; idx < lenModArray - 1; idx += 2) {

                    const mod = chassis.modules[idx];
                    if (!mod || mod.slotFiller) {
                        const modAdj = chassis.modules[idx + 1];
                        if (!modAdj || modAdj.slotFiller)
                            return idx;
                    }
                }
            }
            else {
                // Try to find an open slot first (no slot filler)
                let idx = chassis.modules.findIndex((mod, ind) => ind > 0 && !mod);
                if (idx > 0)
                    return idx;
                // Try to find a slot filler.
                idx = chassis.modules.findIndex((mod, ind) => ind > 0 && mod && mod.slotFiller);
                if (idx > 0)
                    return idx;
            }

            // If we are here, we need to add another I/O base
            // and the add index will be the first slot in the
            // new base.
            return lenModArray;
        }
    }
}

// Sort predicate for SA PSU array.
export const flexHASAPwrSortPredicate = (a: FlexHASAPowerSupply, b: FlexHASAPowerSupply) => {
    if (a.ioBaseIndex < b.ioBaseIndex)
        return -1;

    return 1;
}

const _assignSlotIDs = (chassis: Chassis) => {

    const lenModArr = chassis.modules.length;
    for (let idx = 0; idx < lenModArr; ++idx) {
        const mod = chassis.modules[idx];
        if (mod) {
            mod.slotIdx = idx;

            if (mod.isPlaceholder) {
                mod.slotID = NO_SLOT;
            }
            else {
                mod.slotID = idx;
            }
        }
    }
}

// Normalize the length of the chassis' modules
// array to align evenly with 4-slot I/O bases.
const _normalizeChassis = (chassis: Chassis): boolean => {

    // Get the current number of slots PAST
    // the first (used by the adapter kit).
    const numIOSlots = chassis.modules.length - 1;

    // See if there's any remainder after
    // dividing into 4-slot buckets.
    const extraSlots = numIOSlots % 4;

    // If we have any extra non-aligned slots...
    if (extraSlots > 0) {
        // Add enough to align.
        chassis.modules.length += (4 - extraSlots);

        // We DID change the array.
        return true
    }

    // We made no changes.
    return false;
}

const _getLastBankInfo = (chassis: Chassis): BankInfo => {
    const numBanks = getNumBanks(chassis);
    return getBankInfo(chassis, numBanks - 1);
}

// Determines whether an I/O base starting at
// the specified slot is empty or not.
const _isBaseEmpty = (chassis: Chassis, startSlot: number): boolean => {

    // Sanity check the slot provided. If valid... 
    if ((startSlot < chassis.modules.length) &&
        (startSlot >= 5) &&
        (flexHAIsValidIOBaseStartSlot(startSlot))) {

        // Walk the 4 relevant slots. If we find one
        // NOT empty, stop looking and return false.
        for (let offset = 0; offset <= 3; offset++) {
            if (chassis.modules[startSlot + offset]) {
                return false;
            }
        }

        // If we're still here, all slots that
        // would fall on the base are empty.
        return true;
    }
    throw new Error('Unexpected ERROR in _isBaseEmpty!');
}

const _removeLastBaseIfEmpty = (chassis: Chassis): boolean => {
    
    const lastBaseStart = chassis.modules.length - 4;
    if (_isBaseEmpty(chassis, lastBaseStart)) {
        chassis.modules.length -= 4;
        return true;
    }
    return false;
}

const _getFirstBaseOnBank = (bankInfo: BankInfo): number => {
    if (bankInfo.slotsInBank > 1) {
        const prevSlot = (bankInfo.bankNum === 0)
            ? 0
            : bankInfo.startSlot - 1;
        return (prevSlot / 4);
    }
    return -1
}

export const flexHAGetBankStartIOBases = (chassis: FlexHAChassis): number[] => {
    // Set up an empty array for base offsets
    // that we KNOW will NEED power.
    const basesToPwr = new Array<number>();

    // See how many banks we have.
    const numBanks = getNumBanks(chassis);

    // For each...
    for (let bank = 0; bank < numBanks; bank++) {

        // Get associated bank info.
        const bankInfo = getBankInfo(chassis, bank);

        // Call a helper to tell us the chassis-based
        // offset of the first I/O base on the bank, 
        // if there IS one. If not we get -1 back.
        const firstOnBank = _getFirstBaseOnBank(bankInfo);

        // If there IS an I/O base on the bank,
        // add it to our array of those to power.
        if (firstOnBank >= 0) {
            basesToPwr.push(firstOnBank);
        }
    }
    return basesToPwr;
}

const _addSAPwr = (chassis: FlexHAChassis, ioBaseIdx: number, autoAdded = false): boolean => {
    const saPSU = flexHACreatePowerSupply(flexHA_DefSAPsu) as FlexHASAPowerSupply;
    if (saPSU) {
        saPSU.ioBaseIndex = ioBaseIdx;
        saPSU.parent = chassis;
        saPSU.autoAdded = autoAdded;
        chassis.saPSU.push(saPSU);
        return true;
    }

    return false;
}

const _hasBankingChanged = (chassis: FlexHAChassis, arrBankStrtIOBaseIdx: number[]) => {
    // Any SA PSUs that are auto added are at the start
    // of a bank.
    const saPwrBankStart = chassis.saPSU.filter(x => x.autoAdded === true);
    const lenArr = saPwrBankStart.length;

    // Quick and dirty test
    if (lenArr != arrBankStrtIOBaseIdx.length)
        return true;

    // Commented - We CAN call a Bank Extension reposition
    // a 'bank change', but will NOT at this time.
    //
    //// We have the same array lengths. The SA PSU I/O
    //// base indexes AND the bank start indexes should
    //// be ordered low to high. Are they the same?
    //for (let idx = 0; idx < lenArr; ++idx) {
    //    if (saPwrBankStart[idx].ioBaseIndex !== arrBankStrtIOBaseIdx[idx])
    //        return true;
    //} 

    return false;
}

export const flexHAAddRequiredSAPower = (chassis: FlexHAChassis, projectFromAutoFix?: ChassisProject): boolean => {
    // Get the I/O Base indexes that start each bank.
    let arrBankStrtIOBaseIdx = flexHAGetBankStartIOBases( chassis );
    const numIOBases = flexHAGetNumIOBases(chassis);

    const bankingChanged = _hasBankingChanged(chassis, arrBankStrtIOBaseIdx);
    if (bankingChanged) {
        // Remove any non-auto added PSUs
        chassis.saPSU = chassis.saPSU.filter(x => x.autoAdded === true);
    }

    const finalArray: FlexHASAPowerSupply[] = [];
    const lenSAArr = chassis.saPSU.length;
    for (let idx = 0; idx < lenSAArr; ++idx) {
        let fltrBankBases = false;
        const psu = chassis.saPSU[idx];

        // Was it auto-added...
        if ( psu.autoAdded ) {
            // Should it still be auto-added...
            if ( arrBankStrtIOBaseIdx.some( bnk => bnk === psu.ioBaseIndex ) ) {
                finalArray.push( psu );
                fltrBankBases = true;
            }
        }
        else {
            // Not auto-added. Should it still be added
            if ( psu.ioBaseIndex < numIOBases ) {
                finalArray.push( psu );

                // Should it be flagged as auto added...
                psu.autoAdded = arrBankStrtIOBaseIdx.some( bnk => bnk === psu.ioBaseIndex );
                fltrBankBases = psu.autoAdded;
            }
        }

        // If we have accounted for a bank,
        // remove the index from idxBankBases.
        if(fltrBankBases)
            arrBankStrtIOBaseIdx = arrBankStrtIOBaseIdx.filter(x => x !== psu.ioBaseIndex);
    }

    // Clear the array on the chassis.
    chassis.saPSU = [];

    // For any indexes left in idxBankBases...
    arrBankStrtIOBaseIdx.forEach((idxIOBase) => {
        if (!_addSAPwr(chassis, idxIOBase, true)) {
            if (projectFromAutoFix)
                alert('Unable to correct issue. Failed to create SA Power Supply Unit.');
            else
                logger.error('ERROR: Failed to create SA PSU in _addSAPwr!');

            return false;
        }
    });

    // If the chassis' arr has anything in it...
    const psuAdded = chassis.saPSU.length > 0;

    // Set the final SA Pwr array
    finalArray.forEach(x => {
        chassis.saPSU.push(x);
    })

    if (psuAdded) {
        // Sort the SA PSU array.
        chassis.saPSU.sort(flexHASAPwrSortPredicate);
    }

    // If we had a banking change, add additional
    // SA PSUs if needed (via our auto fix routine).
    if (bankingChanged) {
        flexHAFixSAPwrIssues(chassis);
    }      
    else if (projectFromAutoFix) {
        // When run from an auto fix, we
        // need to update chassis layouts.
        chassisChanged(chassis);
        updateAllChassis(projectFromAutoFix.content);
    }

    return true;
}

const _makeChassisAdjustments = (chassis: FlexHAChassis) => {

    // Start by 'normalizing' our chassis
    // so that its mod slots are guaranteed
    // to align with 4-slot I/O bases.
    _normalizeChassis(chassis);

    // Update bank info after any adjustments above.
    updateBanks(chassis);

    // Get bank info for the LAST bank we have.
    const info = _getLastBankInfo(chassis);

    // If the last bank has no module slots...
    if (info.slotsInBank <= 1) {
        // Increase overall slots by 4,
        // and update the banks AGAIN.
        chassis.modules.length += 4;
        updateBanks(chassis);
    }
    else {
        // We didn't ADD any slots to our last bank.
        // We MIGHT want to trim off any EMPTY
        // trailing bases. Determine how many module
        // slots should be on that last bank (not
        // including the adapter kit if it's bank 0).
        const modSlots = (info.bankNum === 0)
            ? info.slotsInBank - 1
            : info.slotsInBank;

        // Get the number of I/O bases
        // for that number of slots.
        let bases = flexHAGetNumIOBasesForModSlots(modSlots);

        // We'll never trim the LAST base on the
        // last bank, even if empty. While we still
        // have any that we MIGHT be able to trim...
        while (bases > 1) {

            // Attempt to trim the (now) last one.
            // If it was removed (was empty)...
            if (_removeLastBaseIfEmpty(chassis)) {

                // Adjust the info directly so we don't
                // need another updateBanks call.
                info.slotsInBank -= 4;

                // Try another.
                bases--;
            }
            else {
                // No (more) trimming.
                break;
            }
        }
    }

    // Update slot indexes/IDs
    _assignSlotIDs(chassis);

    // Check/refresh SA power. This essentially
    // checks to see if every base that's the
    // first on its bank already has power. If so,
    // the check is fast, and nothing changes.
    // As such, we'll just check every time.
    flexHAAddRequiredSAPower(chassis);
}

// Original version.
// Replaced with _makeChassisAdjustments above
//const _adjustModifiedChassis = (chassis: FlexHAChassis) => {

//    // Store the original length.
//    const originalModArrLen = chassis.modules.length;

//    // First make sure we have the correct
//    // number of slots that align with 4
//    // slot I/O Bases
//    let adjustedLen = chassis.modules.length;

//    // Make sure the I/O Slots align with I/O bases.
//    // 'adjustedLen' represents ONLY slots now.
//    // Subtract 1 for the adapter.
//    const extraSlots = (adjustedLen - 1) % 4;
//    // If we have any extra non-aligned slots...
//    if (extraSlots > 0) {
//        // Add any slots needed for alignment.
//        adjustedLen += 4 - extraSlots;
//    }
//    // We cannot exceed the max slots allowed.
//    const newModArrLen = Math.min(adjustedLen, maxFlexHAChassisSize);
//    chassis.modules.length = newModArrLen

//    // Now walk the array in reverse...
//    for (let ritr = newModArrLen - 1; ritr > 3; ritr -= 4) {
//        // If we do NOT have an empty 4 slot block...
//        // Note: we do NOT count slot fillers as empties
//        // and we will break on Bank Ext Kits as well.
//        if (chassis.modules[ritr] ||
//            chassis.modules[ritr - 1] ||
//            chassis.modules[ritr - 2] ||
//            chassis.modules[ritr - 3]) {
//            break;
//        }
//        else {
//            // Shorten the array by 4 removing
//            // the empty I/O base.
//            chassis.modules.length -= 4;
//        }
//    }

//    // Update slot indexes/IDs
//    _assignSlotIDs(chassis);

//    // See if we need to refresh the SA PSUs.
//    // If the mod array len has changed OR we
//    // have I/O Bases, but not any SA PSUs....
//    const lenModArr = chassis.modules.length;
//    let refreshSAPower = (originalModArrLen !== lenModArr);
//    refreshSAPower = refreshSAPower || (chassis.saPSU.length === 0 && lenModArr > 1);

//    if (refreshSAPower) {
//        // If we only have the adapter and
//        // nothing else
//        if (lenModArr === 1) {
//            // We do not have any SA PSUs.
//            chassis.saPSU = [];
//            return;
//        }

//        // For now, just re-establish the initial
//        // SA PSUs, which will be on the I/O Base
//        // and any I/O Bases following a Bank 
//        // Extension Kit.
//        flexHAAddInitialSAPSUs(chassis);
//    }
//}

// Dummy Module to occupy slots used by Duplex modules.
// TODO_FLEXHA - If model holds, Move to a general function.
const _createMultiSlotModPlaceholder = (parentMod: ChassisModule): ChassisModule => {
    return {
        id: parentMod.id,                       // SET THE PARENT's ID.
        platform: parentMod.platform,
        deviceType: parentMod.deviceType,
        catNo: parentMod.catNo,
        description: parentMod.description,
        isPlaceholder: true,                    // Mark it as Placeholder
        extendedTemp: parentMod.extendedTemp,
        conformal: parentMod.conformal,
        accysPossible: false,
        category: DeviceCategory.Other,  
        imgSrc: '',
        imgSize: { height: 0, width: 0 },
        movable: false,
        slotIdx: parentMod.slotIdx+1,
        slotID: NO_SLOT,
        slotsUsed: 1,
        selected: false,
        dragStatus: ModuleDragStatus.NA,
        parent: parentMod.parent,               // Set the ref to the parent chassis.
        isController: parentMod.isController,
        isComm: parentMod.isComm,
        isConnClient: parentMod.isConnClient,
        spclLocalConns: 0,
        slotFiller: false,
        isFPD: false,
        isInterconnect: false,
        isBankExp: false,
    };
} 

// Check to see if the specified module COULD be added to
// the given chassis. If yes, answers the first slot that
// would work. If no, answers NO_SLOT (-1).
const flexHACanModuleBeAdded = (module: ChassisModule, chassis: Chassis): number => {
    return _testModuleAddition(module.catNo, chassis);
}

const flexHAAddModuleAtSlot = (chassis: Chassis, catNo: string, slot: number, envMismatchOk: boolean): boolean => {
    // For 5015, all components (as of now) should
    // be compatible.
    envMismatchOk = true;

    // Check if we can add the module.
    const actualSlot = _testModuleAddition(catNo, chassis, slot);

    // If the slot returned is 'no-slot' (-1) or is NOT the
    // requested slot (should always be the same, but check)
    if (actualSlot === NO_SLOT || (slot !== NO_SLOT && actualSlot !== slot))
        return false;

    // Create the requested module.
    const module = createModule(chassis.platform, catNo);

    // If we can, and if we have a suitable environment
    // match between it and our chassis...
    if (module) {
        if (envMismatchOk || isDeviceCompatibleWithChassis(module, chassis)) {

            // Notify the undo/redo before making changes.
            chassisChanging(chassis);

            // Do we need another I/O base - This is to
            // adjust the length of the module array to
            // align with 4-slot I/O bases.
            const lenModArr = chassis.modules.length;
            let numIOBasesToAdd = 0;
            if (actualSlot >= lenModArr) {
                const slotsNeeded = actualSlot - lenModArr + 1;
                numIOBasesToAdd = Math.ceil(slotsNeeded / 4);
                // Add any I/O bases we need via array length...
                const slotsToAdd = numIOBasesToAdd * 4;
                for (let idxAdd = 0; idxAdd < slotsToAdd; ++idxAdd)
                    chassis.modules.push(undefined);
            }

            chassis.modules[actualSlot] = module;
            module.parent = chassis;
            module.slotIdx = actualSlot;
            module.slotID = actualSlot;
            addRequiredAccys(module);

            for (let cnt = 1; cnt < module.slotsUsed; ++cnt) {
                const placeholder = _createMultiSlotModPlaceholder(module);
                const idxPlaceHolder = actualSlot + cnt;
                placeholder.slotIdx = idxPlaceHolder;
                chassis.modules[idxPlaceHolder] = placeholder;
            }

            updateChassis(chassis);

            return true;
        }
    }
    return false;
}

const flexHADeleteModuleAtSlot = (chassis: Chassis, slot: number): boolean => {
    const lenModArr = chassis.modules.length;
    if (slot < 0 || slot >= lenModArr)
        return false;

    const module = chassis.modules[slot];
    if (module) {
        // Handle any related undo/redo work.
        chassisChanging(chassis);

        // Make sure the module doesn't have
        // a ref back to our chassis.
        module.parent = undefined;

        // Set slot content to undefined.
        chassis.modules[slot] = undefined;

        // Cleanup any placeholders. Get the index
        // of the first module with the target ID (if any).
        const idxStart = chassis.modules.findIndex(mod => mod && mod.id === module.id);
        if (idxStart >= 0) {
            for (let idx = idxStart; idx < lenModArr; ++idx) {
                // Any placeholders will have the same id
                // as the parent - Remove them.
                const mod = chassis.modules[idx];
                if (mod) {
                    if (mod.id !== module.id)
                        break;
                    mod.parent = undefined;
                    chassis.modules[idx] = undefined;
                }
                else if(idx > slot)
                    break;
            }
        }

        updateChassis(chassis);

        // Success.
        return true;
    }

    return false;
}


const flexHAGetSlotFillerInfo = (chassis: Chassis): [string, boolean] => {
    if (chassis.platform !== PlatformFlexHA)
        throw new Error(`flexHAGetSlotFillerInfo(): Unexpected chassis platform (${chassis.platform})`);
    return ['5015-N2IOXT', true];
}

const flexHAGetChassisRenderer = (): React.FC<ChassisCompProps> | undefined => {
    return FlexHAChassisComp;
}

const flexHAUpdateChassisLayout = (chassis: Chassis) => {

    // NOTE: Check for suspended snapshots are no
    // longer required here. That's now done in the
    // general updateChassis function that routes to us.

    // Make any adjustments to the chassis.
    const flexHAChassis = chassis as FlexHAChassis;
    _makeChassisAdjustments(flexHAChassis);

    flexHALayoutChassis(chassis);
}


// flexHAGetChassisAvailableSlots()
// From the returned maxDup & maxSimp numbers, the number
// of Simplex Only Slots will be (maxSimp - (2 * maxDup)).
// Simplex Only Slots will have Even number slot indexes 
// with a module in the previous slot.
const flexHAGetChassisMaxModuleAdditions = (
    chassis: Chassis,
    restrict: ModuleSlotRestriction,
    treatSlotFillerAsEmptySlot: boolean
): [maxDuplex: number, maxSimplex: number] => {

    let maxDuplex = 0;
    let maxSimplex = 0;

    if (restrict === ModuleSlotRestriction.FirstSlotOnly) {
        maxSimplex = chassis.modules[0] ? 0 : 1;
        return [maxDuplex, maxSimplex];
    }

    const [arrDuplex, arrSimplexOnly] = flexHAGetChassisSlotBreakdown(chassis, treatSlotFillerAsEmptySlot);
    maxDuplex = arrDuplex.length;

    // max Simplex will be maxDuplex * 2 + any SimplexOnly slots.
    maxSimplex = (maxDuplex * 2) + arrSimplexOnly.length;

    return [maxDuplex, maxSimplex];
}


// flexHAGetChassisSlotBreakdown()
// returns the indexes of Duplex and 
// Simplex Only slots. 
const flexHAGetChassisSlotBreakdown = (
    chassis: Chassis,
    treatSlotFillerAsEmptySlot: boolean
): [duplexSlots: number[], simplexOnlySlots: number[] ] => {

    const duplexSlots: number[] = [];
    const simplexOnlySlots: number[] = [];

    const lenModArr = chassis.modules.length;
    for (let idx = 1; idx < lenModArr; ++idx) {
        const mod = chassis.modules[idx];

        if (!mod || (treatSlotFillerAsEmptySlot && mod.slotFiller)) {
            // If the I/O slot is ODD, it is a potential
            // duplex module opening.
            const potentialDuplex = (idx % 2 !== 0);

            // If not a duplex capable slot...
            if (!potentialDuplex) {
                // If the previous slot is occupied...
                const prevMod = chassis.modules[idx - 1];
                if (prevMod) {
                    // If it is a slot filler, this slot
                    // will NOT be a Simplex Only Slot.
                    if (prevMod.slotFiller && treatSlotFillerAsEmptySlot)
                        continue;
                    
                    simplexOnlySlots.push(idx);
                }
                continue
            }

            // Check the next slot - there should
            // always be one!
            const nextMod = chassis.modules[idx + 1];
            if (!nextMod || (treatSlotFillerAsEmptySlot && nextMod.slotFiller)) {
                duplexSlots.push(idx);
            }
            else
                simplexOnlySlots.push(idx);
        }
    }

    // Now check how many extra I/O bases we can add.
    for (let idx = lenModArr; idx < maxFlexHAChassisSize; idx += 2)
        duplexSlots.push(idx);

    return [duplexSlots, simplexOnlySlots];
}


const flexHAGetMaxNewModules = (chassis: Chassis, restrict: ModuleSlotRestriction, treatSlotFillerAsEmptySlot: boolean): number => {
    if (restrict === ModuleSlotRestriction.FirstSlotOnly) 
        return (chassis.modules[0] ? 0 : 1);

    // Since we can ONLY add Simplex
    // modules via the Add Module Dlg...
    const [ , maxSimplex] = flexHAGetChassisMaxModuleAdditions(chassis, restrict, treatSlotFillerAsEmptySlot);
    return maxSimplex;
}


const flexHACanExtendChassis = (chassis: Chassis) => {
    return (maxFlexHAChassisSize > chassis.modules.length);
}

const _getLastVertBankRight = (chassis: Chassis): number => {

    const layout = flexHAGetLayoutInfo(chassis, getNumBanks(chassis) - 1);
    return (layout.backplateLoc.x + layout.backplateLoc.width);
}

const flexHAGetChassisSizeAsDrawn = (chassis: Chassis, copyMode: boolean): Size => {

    // Get platform details.
    const dtls = _platformDetails;
    if (!dtls)
        return { height: 0, width: 0 };

    // Start with assumption that we're NOT
    // including a 'X' (extra empty) slot.
    let extWidth = 0;

    const xWidth =
        Math.max(dtls.defaultXSlotWidth * dtls.imgScaleFactor, chassis.xSlotWidth);

    // If we can extend the chassis... Note: Other 
    // 'snap' platforms always allow module move 
    // within the same chassis to the Extended Slot, 
    // but FlexHA does NOT.
    if (flexHACanExtendChassis(chassis)) {
        // We will if the chassis is a drag target AND it has
        // an xSlotWidth already. Note, however, that the default
        // xSlotWidth set on the chassis is the size of a module,
        // and WE want the size of the base.
        if (chassis.dragTarget && chassis.xSlotWidth > 0) {
            extWidth = xWidth;
        }
        else {
            // Otherwise, if we are in copy mode or selected...
            if (chassis.selected || copyMode) {
                extWidth = xWidth;
            }
        }
    }

    // Id we DO have an 'extra slot' width...
    if (extWidth > 0) {
        // Start with the chassis' full size.
        const sz = { ...chassis.layout.size };

        // If we're showing banks vertically...
        if (Show5015BanksVertically) {
            // The extension will be added only to the LAST bank,
            // regardless of how many banks we have. Call a helper
            // to get us the (unextended) right x of that last bank.
            const lstBnkRight = _getLastVertBankRight(chassis);

            // Add the extra width needed to THAT.
            const lstRightExt = lstBnkRight + extWidth;

            // Determine how far that extended right side will
            // be TO THE RIGHT of our unextended chassis' width.
            const extraChasWidth = lstRightExt - sz.width;

            // The complete chassis width is the max of ALL banks.
            // Depending on the size of the last bank as compared to
            // others in the chassis, our 'extra' number here could
            // be less than the extension width (extWidth), or even
            // a negative number. At MOST it would be extWidth, which
            // we'd have if the last bank (unextended) was the widest
            // bank.If we have a POSITIVE number here, add it to the
            // width of what we return.
            if (extraChasWidth > 0) {
                sz.width += extraChasWidth;
            }
        }
        else {
            // Horizontal banks. Just add the ext
            // to the unextended chassis' width.
            sz.width += extWidth;
        }

        // Return the size.
        return sz;
    }

    // Otherwise, return the actual size.
    //console.warn('As drawn norm width: ' + chassis.layout.size.width);
    return { ...chassis.layout.size };
}

const _getLastOccupiedSlotLoc = (chassis: Chassis): LocAndSize => {

    // Walk all possible slot idx's for the chassis. For each...
    for (let slotIdx = chassis.modules.length - 1; slotIdx >= 0; slotIdx--) {

        // Call a helper to give us the loc. We CAN'T simply
        // look in the chassis' layout object any longer
        // because that now only contains slotLocs for
        // slots on bank 0. We COULD call the generic getSlotLocation
        // function here, but since we know who we are, we'll
        // call our own implementation of that and save the route.
        const loc = flexHAGetSlotLocation(chassis, slotIdx);

        // If the loc is empty (0,0,0,0), the slot is 'occupied'
        // by the right half of a duplex module. Keep moving.
        // Note also that the get-loc function above gives us
        // a copy of the actual loc (in whichever bank's layout
        // that was found). So, we don't need to copy it again.
        if (!isEmptyLoc(loc)) {
            //return { ...loc };
            return loc;
        }
    }

    throw new Error('Unexpected ERROR in _getLastOccupiedSlotLoc!');
}

const _getAddCopyActionBtnInfo = (action: LayoutActionType, rack: Rack, slotNum: number): ActBtnInfo => {
    // If the slot requested is 1 to the right
    // of our chassis' last ACTUAL slot...
    const slots = rack.chassis.modules.length;
   if (slotNum === slots) {

        // See if the chassis can be extended
        // with another module. If so...
        if (canExtendChassis(rack.chassis)) {

            // Get platform details...
            const dtls = getPlatformDetails();

            // Get the location of the last actual slot.
            const lastSlotLoc = _getLastOccupiedSlotLoc(rack.chassis);
            offsetLoc(lastSlotLoc, rack.ptOrg);

            // Start our 'x' (extra) slot as a copy of that.
            const xSlotLoc = { ...lastSlotLoc };

            // Place it's left side at the right
            // side of the last real slot.
            xSlotLoc.x += lastSlotLoc.width;

            // Set its width to be the platform's default.
            xSlotLoc.width = dtls.defaultXSlotWidth * dtls.imgScaleFactor;

            // Position the act btn pt inside.
            const pt = getLocCenter(xSlotLoc);
            pt.y += DfltActBtnSpecs.height;

            // And return our btn info.
            return {
                action: action,
                chassis: rack.chassis,
                slot: slotNum,
                ctrPt: pt
            };
        }
        else {
            throw new Error('Invalid extension attempt in flexHAGetActionBtnInfo()::_getAddCopyActionBtnInfo()!');
        }
    }

    const slotLoc = flexHAGetSlotLocation(rack.chassis, slotNum);

    offsetLoc(slotLoc, rack.ptOrg);
    const pt = getLocCenter(slotLoc);
    pt.y += DfltActBtnSpecs.height;
    return {
        action: action,
        chassis: rack.chassis,
        slot: slotNum,
        ctrPt: pt
    };
}

const _getGeneralActionBtnInfo = (action: LayoutActionType, rack: Rack, slotNum: number): ActBtnInfo => {
     
    let tip: string | undefined = undefined;
    const slotLoc = flexHAGetSlotLocation(rack.chassis, slotNum);

    offsetLoc(slotLoc, rack.ptOrg);
    const pt = getLocCenter(slotLoc);
    pt.y += DfltActBtnSpecs.height;
    if (action === LayoutActionType.MakeDuplex ||
        action === LayoutActionType.MakeSimplex) {
        // This is some work... 
        // Get the bank from the slot
        const bankInfo = getBankInfoFromSlot(rack.chassis, slotNum);
        // Get the layout for the bank
        const layout = flexHAGetLayoutInfo(rack.chassis, bankInfo.bankNum);
        // Get the index used for the Terminal Block Loc
        const layoutSlotIdx = slotNum - bankInfo.startSlot;
        // Get the Terminal Block Loc
        const tbLoc = { ...layout.ioTBLocs[layoutSlotIdx] };
        // Offset it 
        offsetLoc(tbLoc, rack.ptOrg);
        // Get the center...
        const ptCenter = getLocCenter(tbLoc);
        // Set our x and y
        pt.x = ptCenter.x - 5;
        pt.y = ptCenter.y;
        // Set our tip.
        tip = 'Convert to Simplex Wiring';
        // If we are a Simplex Slot (ie Make Simplex a Duplex)
        if (action === LayoutActionType.MakeDuplex) {
            // Update our x to the Right Edge of 
            // the Simplex Terminal Block Loc.
            pt.x = tbLoc.x + tbLoc.width - 5;
            // Set our tip.
            tip = 'Convert to Duplex Wiring';
        }
    }

    return {
        action: action,
        chassis: rack.chassis,
        slot: slotNum,
        ctrPt: pt,  
        tip: tip,
    };
}


export const flexHAGetActionBtnInfo = (action: LayoutActionType,
    rack: Rack, slotNum: number): ActBtnInfo => {

    // Note: We allow the slot number to be the
    // 'extension slot' index, but NOT beyond.
    const slots = rack.chassis.modules.length;
    if (slotNum > slots) {
        throw new Error('flexHAGetActionBtnInfo(): Invalid slot number passed in!');
    }

    // These may include the Extension Slot
    if (action === LayoutActionType.AddModule || 
        action === LayoutActionType.ModeCopy) 
        return _getAddCopyActionBtnInfo(action, rack, slotNum);

    // Any other actions that are module specific.
    return _getGeneralActionBtnInfo(action, rack, slotNum);
}


const _getNextCatalog = (arrCatalogs: string[]): string | undefined => {
    let catalog: string | undefined = '';
    while (!catalog && arrCatalogs.length > 0) {
        catalog = arrCatalogs.pop();
    }
    return catalog;
}

const _EndAddModulesToChassis = (chassis: Chassis, wereSuspended: boolean) => {
    suspendUndoSnapshots(wereSuspended);
    updateChassis(chassis);
}

export const flexHAAddModulesToChassis = (chassis: Chassis,
    sels: SelectedCompInfo[],
    totalQty: number,
    targetSlot: number) => {

    const arrCatalogs: string[] = [];
    sels.forEach((sel) => {
        if (sel.catNo && sel.catNo.length > 0) {
            for (let cnt = 0; cnt < sel.quantity; ++cnt) {
                arrCatalogs.push(sel.catNo);
            }
        }
    });

    if (arrCatalogs.length === 0)
        return;

    let nextCatalog = _getNextCatalog(arrCatalogs);
    if (!nextCatalog)
        return;

    chassisChanging(chassis);
    const wereSuspended = suspendUndoSnapshots(true);

    // Fill the target slot first.
    const mod: ChassisModule | undefined = chassis.modules[targetSlot];
    if (!mod || mod.slotFiller) {
        addModuleAtSlot(chassis, nextCatalog, targetSlot, true);
    }

    const lenExistingSlots = chassis.modules.length;
    // Fill any empty slots
    for (let idx = 1; idx < lenExistingSlots; ++idx) {
       if (chassis.modules[idx] == null) {
           nextCatalog = _getNextCatalog(arrCatalogs);
           if (!nextCatalog) {
               _EndAddModulesToChassis(chassis, wereSuspended);
               return;
           }

           addModuleAtSlot(chassis, nextCatalog, idx, true);
        }
    }

    // Fill any slot fillers - Maybe we extend
    // the chassis first, then replace slot fillers(?)
    for (let idx = 1; idx < lenExistingSlots; ++idx) {
        if (chassis.modules[idx]?.slotFiller) {
            nextCatalog = _getNextCatalog(arrCatalogs);
            if (!nextCatalog) {
                _EndAddModulesToChassis(chassis, wereSuspended);
                return;
            }

            addModuleAtSlot(chassis, nextCatalog, idx, true);
        }
    }

    // Add any mods left by expanding the chassis.
    const maxModules = maxFlexHAChassisSize;
    for (let idx = lenExistingSlots; idx < maxModules; ++idx) {
        nextCatalog = _getNextCatalog(arrCatalogs);
        if (!nextCatalog) {
            _EndAddModulesToChassis(chassis, wereSuspended);
            return;
        }

        addModuleAtSlot(chassis, nextCatalog, idx, true);
    }

    _EndAddModulesToChassis(chassis, wereSuspended);
}


const _getNonFillerOccupant = (chassis: Chassis, slotNum: number):
    ChassisModule | undefined => {

    const occupant = chassis.modules[slotNum];
    if (occupant && !occupant.slotFiller) {
        return occupant;
    }

    return undefined;
}

// Helper used when determining drop status while dragging and when
// actually dropping a module. Caller provides a slot number, which
// is the actual slot offset under the drag (or drop) point, and a
// bool telling us whether whatever's being dragged is a duplex module
// or not. The function returns an array guaranteed to contain either
// 1 or 2 slot numbers (offsets), 2 IFF the forDuplex argument is true.
// For non-duplex cases, the array returned will just contain the
// slot requested. For a duplex, the array will contain two entries. The
// first will be an odd-numbered slot and the second will be the even
// numbers slot immediately following it.
// Examples for duplex cases:
//   1. If slot 3 is requested, the return will be [3, 4].
//   2. If slot 4 is requested, the return will be [3, 4].
const _getDropTargetSlots = (slotRequested: number, forDuplex: boolean)
    : number[] => {

    const targetSlots = new Array<number>();
    targetSlots.push(slotRequested);

    if (forDuplex) {

        // Add the 'other slot' that we'd use to our
        // our targets. If we started with an 'even'
        // numbered slot, we add the slot to the LEFT
        // of it to the START of our targets array. If
        // 'odd', we add the slot to the RIGHT of it
        // to the END of the array.
        if ((slotRequested % 2) === 0) {
            targetSlots.unshift(slotRequested - 1);
        }
        else {
            targetSlots.push(slotRequested + 1);
        }
    }

    return targetSlots;
}

const _getClosestLocalBase = (layout: FlexHALayoutInfo, x: number): number => {

    // See how many bases are in the layout.
    const numBases = layout.ioBaseLocs.length;

    // For each...
    for (let locBaseIdx = 0; locBaseIdx < numBases; locBaseIdx++) {

        // If our x is left of this one's center,
        // return the current idx.
        if (x < getLocCenter(layout.ioBaseLocs[locBaseIdx]).x) {
            return locBaseIdx;
        }
    }

    // If we get here, the x requested is to the right
    // of the last base's center, or the bank has no I/O
    // bases (bank 0 can have 0 bases). In either case,
    // we return the idx of the base that would follow
    // what's in our current layout.
    return numBases;
}

const _getBankExpStartSlot = (dragExp: BankExpModule): number => {
    if (dragExp.parent) {
        const bankInfo = getBankInfo(dragExp.parent, dragExp.bankNum);
        if (dragExp.onRight) {
            return bankInfo.startSlot + bankInfo.slotsInBank;
        }
        else {
            return bankInfo.startSlot;
        }
    }
    throw new Error('Unexpected ERROR in _getBankExpStartSlot');
}

// Note: Caller guarantees that slot is valid,
// or 1 more than current chassis length AND
// chassis is extendable.
const _getIOBaseLoc = (chassis: Chassis, slot: number): LocAndSize => {

    if (flexHAIsValidIOBaseStartSlot(slot)) {

        if (slot === chassis.modules.length) {
            const layout = flexHAGetLayoutInfo(chassis, getNumBanks(chassis) - 1);
            const loc = { ...layout.ioBaseLocs[layout.ioBaseLocs.length - 1] };
            loc.x += loc.width;
            return loc;
        }
        else {
            const [bank, lclSlot] = getSlotBankInfo(chassis, slot);
            const modSlotsOffset = (bank === 0) ? 1 : 0;
            const locBaseIdx = (lclSlot - modSlotsOffset) / 4;
            const layout = flexHAGetLayoutInfo(chassis, bank);
            return { ...layout.ioBaseLocs[locBaseIdx] };
        }
    }
    throw new Error('Invalid call to _getIOBaseLoc!');
}

const _insXOff = 20;
const _insWidth = 160;
const _insAbove = 170;
const _insBelow = 230;

const _getExpInsertLoc = (chassis: Chassis, slot: number): LocAndSize => {

    const loc = _getIOBaseLoc(chassis, slot);
    loc.x -= _insXOff;
    loc.width = _insWidth;
    loc.height += (_insAbove + _insBelow);
    loc.y -= _insAbove;
    return loc;
}

const _getExpStartAndTargSlots = (
    chassis: Chassis,
    dragExp: BankExpModule,
    layoutPt: Point
): [slotsFound: boolean, expSlot: number, targetSlot: number] => {

    // See how many banks the chassis has.
    const numBanks = getNumBanks(chassis);

    // Walk the banks. For each...
    for (let bank = 0; bank < numBanks; bank++) {

        // Get the bank layout.
        const layout = flexHAGetLayoutInfo(chassis, bank);

        // If our point is somewhere in the rect
        // containing our non-PS components...
        if (isPointInLoc(layoutPt, layout.nonPSCompsLoc)) {

            // Call a helper to get us the idx, local to
            // this bank, of the I/O base that we'll use
            // to get our slot location. Effectively, we'll
            // use the FIRST slot on the associated base.
            const locBaseIdx = _getClosestLocalBase(layout, layoutPt.x);

            // Get our bank info.
            const bankInfo = getBankInfo(chassis, bank);

            // Determine the target slot that our
            // exp insert should fall before.
            const firstModSlot = (bank === 0) ? 1 : bankInfo.startSlot;
            const targetSlot = firstModSlot + (locBaseIdx * 4);

            // Call another helper to tell us which slot
            // the exp device being draggined currently
            // falls before.
            const expSlot = _getBankExpStartSlot(dragExp);

            return [true, expSlot, targetSlot];
        }
    }

    return [false, -1, -1];
}

const _getBankExpDropStatus = (
    chassis: Chassis,
    dragInfo: DragDeviceInfo
): DropStatus => {

    // Start with assumption that we will
    // NOT be showing the insert graphic.
    dragInfo.showInsert = false;

    // Get the exp comp being dragged.
    const dragExp = dragInfo.dragMod as BankExpModule;

    // At least for now, disallow any
    // NON-local move/copy.
    if (dragExp.parent !== chassis) {
        return DropStatus.NoDrop;
    }

    // See how many banks the chassis has.
    const numBanks = getNumBanks(chassis);

    // Disallow a copy if we're already
    // at our maximum number of banks.
    if ((numBanks > 2) && dragInfo.copy) {
        return DropStatus.NoDrop;
    }

    // Get our chassis' orgPt and translate it
    // to local coords we have in our layouts.
    const orgPt = getRackOrgPt(chassis);
    const layoutPt: Point = {
        x: dragInfo.ptCtr.x - orgPt.x,
        y: dragInfo.ptCtr.y - orgPt.y
    }

    // Call a helper to tell us the slot that
    // the dragged exp device precedes and the
    // slot our drag would move/copy it before.
    const [slotsOk, expSlot, targetSlot] =
        _getExpStartAndTargSlots(chassis, dragExp, layoutPt);

    if (slotsOk) {
        // If our slots match, then our drag is effectively
        // over the same location. We'll call this an 'Ok'
        // for status purposes, if we're moving, and we'll
        // disallow for a copy.
        if (targetSlot === expSlot) {
            return dragInfo.copy ? DropStatus.NoDrop : DropStatus.DropOk;
        }

        // Disallow if the target slot would required
        // use to extend beyond our max chassis size.
        if (targetSlot > 24) {
            return DropStatus.NoDrop;
        }

        // If we have MORE than 1 expansion kit,
        // which would give us more than 2 banks...
        if (numBanks > 2) {
            // Disallow if the target slot is already
            // a bank starter. If we allowed that, we'd
            // need to insert an empty base between them.
            if (getAuxBankStartSlots(chassis).includes(targetSlot)) {
                return DropStatus.NoDrop;
            }
        }

        const insLoc = _getExpInsertLoc(chassis, targetSlot);
        offsetLoc(insLoc, orgPt);
        dragInfo.insertLoc = insLoc;
        dragInfo.showInsert = true;

        return DropStatus.DropOk;
    }

    return DropStatus.NoDrop;
}

export const flexHAGetChassisDropStatus = (
    chassis: Chassis,
    dragInfo: DragDeviceInfo,
    touchEl: ChassisElement,
    slotOrBank: number
): DropStatus => {

    // Immediately disqualify if platform mismatch.
    if (dragInfo.dragMod.platform !== chassis.platform) {
        return DropStatus.NoDrop;
    }

    // If we're dragging a bank exp comp,
    // hand off to a helper for that.
    if (dragInfo.dragMod.isBankExp) {
        return _getBankExpDropStatus(chassis, dragInfo);
    }

    // We must be dragging a module.
    // Disqualify if we're not touching a slot.
    if (touchEl !== ChassisElement.Slot) {
        return DropStatus.NoDrop;
    }

    const slotNum = slotOrBank;

    // Do not show the insert.
    dragInfo.showInsert = false;
    
    // If the slot is beyond the max...
    if (slotNum >= maxFlexHAChassisSize)
        return DropStatus.NoDrop;

    // If the slot is beyond our current mod array length,
    // we should be good. 
    if (slotNum >= chassis.modules.length) 
        return DropStatus.DropOk;

    // See if the module being dragged is a duplex (2 slots).
    const duplexMod = (dragInfo.dragMod.slotsUsed === 2);

    // Call a helper to get the actual slot(s) we 
    // should target. See comments there for more info.
    const targetSlots = _getDropTargetSlots(slotNum, duplexMod);

    // If we're dropping a module on itself, regardless
    // of whether its a slot filler or not...
    if (chassis.modules[targetSlots[0]] === dragInfo.dragMod) {
        // Return true if we're NOT copying and
        // false if we ARE. For status purposes, a
        // MOVE to the same location should show OK.
        return dragInfo.copy ? DropStatus.NoDrop : DropStatus.DropOk;
    }

    // Get the occupant of the first (leftmost or only)
    // target slot that is NOT a slot filler.
    const occupant = _getNonFillerOccupant(chassis, targetSlots[0]);

    // If there is one...
    if (occupant) {
        // Target slot is occupied. The drag
        // module can't be moved or copied here.
        return DropStatus.NoDrop;
    }
    else {
        // First (or only) slot wasn't occupied.
        // If we're dragging a duplex module...
        if (duplexMod) {
            // Then, we CAN'T drop if the SECOND slot
            // that would be used is occupied.
            if (_getNonFillerOccupant(chassis, targetSlots[1])) {
                return DropStatus.NoDrop;
            }
        }
        else if (dragInfo.dragMod.isComm && slotNum !== 0) {
            // 2024.8.6 If we have a comm and the slot#
            // is not 0, cannot drop it. We do not care
            // if it is the local or different chassis.
            return DropStatus.NoDrop;
        }
    }

    // If we're still here, target slot(s) we'd
    // use should be available. A move or copy
    // here should be OK.
    return DropStatus.DropOk;
}

const _makeAuxBank = (chassis: Chassis, startSlot: number): AuxBank => {
    return {
        parent: chassis,
        info: getNewBankInfo(startSlot),
        layout: _getEmptyLayoutForAuxBank(chassis),
        compsReqd: ['5015-BEKITXT']
    }
}

const _dropBankExp = (
    chassis: Chassis,
    dragInfo: DragDeviceInfo
): DropResult => {

    const dragExp = dragInfo.dragMod as BankExpModule;
    if (dragExp.parent !== chassis) {
        throw new Error('ERROR: Invalid non-local drop!');
    }

    // Get our chassis' orgPt and translate it
    // to local coords we have in our layouts.
    const orgPt = getRackOrgPt(chassis);
    const layoutPt: Point = {
        x: dragInfo.ptCtr.x - orgPt.x,
        y: dragInfo.ptCtr.y - orgPt.y
    }

    // Call a helper to tell us the slot that
    // the dragged exp device precedes and the
    // slot our drag would move/copy it before.
    const [slotsOk, expSlot, targetSlot] =
        _getExpStartAndTargSlots(chassis, dragExp, layoutPt);

    if (slotsOk) {
        if (targetSlot === expSlot) {

            // Sanity check. The only valid case (where we have a
            // DropOK status) is if the drag was a MOVE (not a copy).
            if (dragInfo.copy) {
                throw new Error('ERROR: Invalid copy in _dropBankExp');
            }
            // Drag was a move to the exp's original location.
            // The failed return here just indicates that we
            // didn't actually do anything, but this is
            // a valid case.
            return DropResult.DropFailed;
        }

        console.warn((dragInfo.copy ? 'Copy' : 'Move') +
            ' exp from slot ' + expSlot +
            ' to slot ' + targetSlot);

        if (chassis.auxBanks) {
            chassisChanging(chassis);

            // Then temporarily suspend any
            // interim undo/redo snapshots.
            const wereSuspended = suspendUndoSnapshots(true);

            if (dragInfo.copy) {
                chassis.auxBanks.push(_makeAuxBank(chassis, targetSlot));
            }
            else {
                const aux = chassis.auxBanks.find((ab) => ab.info.startSlot === expSlot);
                if (aux) {
                    aux.info.startSlot = targetSlot;
                }
                else {
                    throw new Error('ERROR: Missing aux in _dropBankExp?');
                }
            }

            // Set snapshot suspension back to 
            // what it was.
            suspendUndoSnapshots(wereSuspended);

            // Set the skipAccys flag on our dragInfo.
            // We didn't actually drop a module, and
            // we don't want any accy-related work to
            // be attempted.
            dragInfo.skipAccys = true;

            // Success.
            return DropResult.DropSuccess;
        }

        // We should be gone already.
        throw new Error('Unexpected ERROR in _dropBankExp!');
    }

    // TEMP
    return DropResult.DropFailed;
}

const flexHADropDragDeviceOnChassis = (
    chassis: Chassis,
    dragInfo: DragDeviceInfo,
    touchEl: ChassisElement,
    slotOrBank: number
): DropResult => {

    // If we're called, a drop SHOULD be possible.
    // If all prequalifications are met...
    if ((dragInfo.dropStatus !== DropStatus.NoDrop) &&
        (chassis.platform === dragInfo.dragMod.platform)) {

        // If we're dropping a bank exp, call
        // a helper to do that for us.
        if (dragInfo.dragMod.isBankExp) {
            return _dropBankExp(chassis, dragInfo);
        }

        // At this point, we MUST be dragging a module,
        // and the touch element MUST be a slot.
        if (touchEl !== ChassisElement.Slot) {
            throw new Error('Invalid target element for module drop?');
        }

        // See if the module being dragged is a duplex (2 slots).
        const duplexMod = (dragInfo.dragMod.slotsUsed === 2);

        // Call a helper to get the actual slot(s) we 
        // should target. See comments there for more info.
        const targetSlots = _getDropTargetSlots(slotOrBank, duplexMod);

        // If we're dropping a module on itself, regardless
        // of whether its a slot filler or not, 
        if (chassis.modules[targetSlots[0]] === dragInfo.dragMod) {

            // Sanity check. The only valid case (where we have
            // a DropOK status) is the drag was a MOVE (not a copy).
            if (dragInfo.copy) {
                throw new Error('ERROR: Copy on self in flexHADropDragDeviceOnChassis!');
            }

            // Drag was a move to the module's original location.
            // The failed return here just indicates that we
            // didn't actually do anything, but this is
            // a valid case.
            return DropResult.DropFailed;
        }

        // We KNOW now that we DON'T have a drop-on-self case.
        // See if the first target slot is occupied
        // by anything OTHER than a slot filler.
        const slotOccupant = _getNonFillerOccupant(chassis, targetSlots[0]);

        // It should NOT be if we're still here. If it is, error out.
        if (slotOccupant) {
            throw new Error('ERROR: Invalid drop in flexHADropDragDeviceOnChassis!');
        }

        // Get the dragged module's parent chassis.
        const sourceChassis = dragInfo.dragMod.parent as Chassis;

        // It should ALWAYS have one, but fail out if not.
        if (!sourceChassis) {
            return DropResult.DropFailed;
        }

        // We should only be called when a drop has
        // been pre-qualified. As such, we expect to
        // actually succeed here.
        // Start by calling a helper to handle any
        // related undo/redo work for us.
        chassisChanging(chassis);

        // Then temporarily suspend any
        // interim undo/redo snapshots.
        const wereSuspended = suspendUndoSnapshots(true);

        // Remove any slot fillers...
        let abort = false;
        targetSlots.forEach(slot => {
            const destMod = chassis.modules[slot];
            if (destMod) {
                if (destMod.slotFiller) {
                    chassis.modules[slot] = undefined;
                }
                else {
                    logger.error('ERROR: Target slot found occupied in flexHADropDragDeviceOnChassis');
                    abort = true;
                }
            }
        });

        // Bail if we encountered a
        // NON-slotfiller occupant above.
        if (abort) {
            suspendUndoSnapshots(wereSuspended);
            return DropResult.DropFailed;
        }

        // Use the FIRST of our target slots. If our dragged
        // module is duplex, that slot num will ALWAYS be odd.
        const slotTarget = targetSlots[0];

        // If copying...
        if (dragInfo.copy) {
            // Add a new module at the target slot.
            const success = addModuleAtSlot(chassis, dragInfo.dragMod.catNo, slotTarget, true);
            suspendUndoSnapshots(wereSuspended);
            //updateChassis(chassis);
            return (success ? DropResult.DropSuccess : DropResult.DropFailed);
        }

        // We're MOVING the existing module
        // to the specified location. Start
        // by detaching it from its old location.
        detachModule(dragInfo.dragMod);

        // Then insert it into the specified chassis
        // at the specified slot location.
        chassis.modules[slotTarget] = dragInfo.dragMod;

        // Record that slot location in the module itself.
        dragInfo.dragMod.slotIdx = slotTarget;
        dragInfo.dragMod.slotID = slotTarget;

        // And set its new parent chassis.
        dragInfo.dragMod.parent = chassis;

        // Add placeholder module as needed.
        for (let cnt = 1; cnt < dragInfo.dragMod.slotsUsed; cnt++) {
            const placeholder = _createMultiSlotModPlaceholder(dragInfo.dragMod);
            const phSlot = slotTarget + cnt;
            chassis.modules[phSlot] = placeholder;
            placeholder.slotIdx = phSlot;
        }

        // Re-enable snapshots.
        suspendUndoSnapshots(wereSuspended);

        // A return of success will result in the target
        // chassis' layout getting updated. If the source
        // of the drag was a different chassis, we'll
        // update that one ourselves here.
        if (sourceChassis !== chassis) {
            updateChassis(sourceChassis);
        }

        // Success.
        return DropResult.DropSuccess;
    }

    throw new Error('Unexpected call to flexHADropDragDeviceOnChassis?');
}


// flexHADoesSlotQualifyForBtnAction() - Unlike other platforms (so far),
// Flex HA has duplex modules. Certain actions do NOT make sense for the
// duplex modules, which require 2 slots starting at an odd slot index.
const flexHADoesSlotQualifyForBtnAction = (
    mode: LayoutMode,
    type: LayoutActionType,
    chassis: Chassis,
    slotNum: number): boolean => {

    if (chassis.platform !== PlatformFlexHA)
        throw new Error('flexHADoesSlotQualifyForBtnAction(): Invalid Platform!');

    if (type === LayoutActionType.ModeCopy) {
        if (mode.engInfo && mode.engInfo.isModule) {
            // If the module to copy occupies more than 1 slot...
            if ((mode.engInfo as EngInfoModule).slotsUsed > 1) {
                if (!flexHADoesSlotQualifyForCopy(chassis, mode.engInfo as EngInfoModule, slotNum)) {
                    return false;
                }
            }
        }
    }
    else if (type === LayoutActionType.MakeDuplex) {
        // Must be an odd slot number
        if ((slotNum % 2) !== 1)
            return false;

        // Must have a simplex mod in primary slot.
        const mod = chassis.modules[slotNum];
        if (!mod || mod.slotFiller || mod.slotsUsed > 1)
            return false;

        // Must have a simplex in secondary slot.
        const nextMod = chassis.modules[slotNum + 1];
        if (!nextMod || nextMod.slotFiller)
            return false;
    } 
    else if (type === LayoutActionType.MakeSimplex) {
        // Must be an odd slot number
        if ((slotNum % 2) !== 1)
            return false;

        // Must have a Duplex mod in primary slot.
        const mod = chassis.modules[slotNum];
        if (!mod || mod.slotsUsed < 2)
            return false;
    } 

    return true;
}

const _isValidBank = (chassis: Chassis, bank: number): boolean => {
    return ((bank === -1) || ((bank >= 0) && (bank < getNumBanks(chassis))));
}

export const flexHAGetNumModSlots = (chassis: Chassis, bank = -1): number => {
    if (_isValidBank(chassis, bank)) {
        if (bank === -1) {
            return (chassis.modules.length - 1);
        }

        const nonModSlots = (bank === 0) ? 1 : 0;
        const info = getBankInfo(chassis, bank);
        return (info.slotsInBank - nonModSlots);
    }
    throw new Error('Unexpected ERROR in flexHAGetNumModSlots');
}

export const flexHAGetNumIOBasesForModSlots = (modSlots: number): number => {

    // Sanity checks. Mod slot qty should be multiple
    // of 4, and not exceed 24 slots (6 4-slot bases).
    if ((modSlots % 4 === 0) && (modSlots <= 24)) {
        return (modSlots / 4);
    }

    throw new Error('Unexpected ERROR in flexHAGetNumIOBasesForModSlots');
}

const flexHAGetNumIOBases = (chassis: Chassis, bank = -1): number => {

    const modSlots = flexHAGetNumModSlots(chassis, bank);
    return flexHAGetNumIOBasesForModSlots(modSlots);
}

const flexHAIsPointOnPS = (ptLocal: Point, utChassis: Chassis): boolean => {
    if (utChassis.ps) {
        const sizeDtls = getFHASizeDetails();
        if (isPointInLoc(ptLocal, sizeDtls.psuLoc1) ||
            isPointInLoc(ptLocal, sizeDtls.psuLoc2)) {
            return true;
        }
    }

    // Check the SA PSUs - cast the untyped chassis
    const chassis = utChassis as FlexHAChassis;
    chassis.saPSU.forEach(psu => {
        if (isPointInLoc(ptLocal, psu.loc))
            return true;
    });

    return false;
}

const flexHAGetChassisElementAtPt = (utChassis: Chassis, ptLocal: Point):
    [
        element: ChassisElement,
        slotOrBank: number,
        rightSide: boolean  // Used only for slots and BankExp
    ] => {

    // Call the standard function.
    let [element, slotOrBank, rightSide] = standardGetChassisElementAtPt(utChassis, ptLocal);

    // If we come back as 'the chassis',
    // we're not done yet...
    if (element === ChassisElement.Chassis) {
        // Check if we are on a SA PSU
        const chassis = utChassis as FlexHAChassis;
        chassis.saPSU.forEach(psu => {
            if (isPointInLoc(ptLocal, psu.loc)) {
                // Set the 'slotOrBank to the I/O Base Index!!!
                element = ChassisElement.ExtPS;
                slotOrBank = psu.ioBaseIndex;
                rightSide = false;
            }
        });
    }

    return [element, slotOrBank, rightSide];
}

const flexHAGetExternalPSU = (utChassis: Chassis, idPSU: number): SelectableDevice | undefined => {
    // The PSU ID is the I/O Base Index
    const chassis = utChassis as FlexHAChassis;
    return chassis.saPSU.find(psu => psu.ioBaseIndex === idPSU);
}

const flexHAIsPointOnBankExp = (ptLocal: Point, chassis: Chassis):
    [onExp: boolean, bank: number, rightSide: boolean] => {

    const numBanks = getNumBanks(chassis);
    if (numBanks > 1) {
        for (let bank = 0; bank < numBanks; bank++) {
            const layout = flexHAGetLayoutInfo(chassis, bank);
            if (layout.locLeadComp &&
                isPointInLoc(ptLocal, layout.locLeadComp)) {
                return [true, bank, false];
            }
            else if (layout.locTrailComp &&
                isPointInLoc(ptLocal, layout.locTrailComp)) {
                return [true, bank, true];
            }
        }
    }

    return [false, -1, false];
}

const flexHAGetBankExpComp = (
    chassis: Chassis,
    bank: number,
    rightSide: boolean
): BankExpModule | undefined => {

    const layout = flexHAGetLayoutInfo(chassis, bank);
    return rightSide
        ? layout.expTrailComp
        : layout.expLeadComp;
}

// NOTE: getXSlotWidthFor returns the x-slot width we COULD
// have IFF the chassis that cares can actually ACCEPT another
// module. This function may still return a non-zero value
// even if the chassis is already at its module capacity!
const flexHAGetXSlotWidthFor = (modInfo: EngInfoComponent): number => {
    const restrict = getModuleSlotRestriction(modInfo);
    if (restrict === ModuleSlotRestriction.FirstSlotOnly) {
        return 0;
    }

    const sizeDtls = getFHASizeDetails();
    return sizeDtls.ioBaseInfo.baseSize.width;
}

const flexHAGetXSlotLoc = (chassis: Chassis): LocAndSize => {

    // Start with an empty loc.
    let xSlotLoc = getEmptyLoc();

    // If the chassis currently qualifies...
    if (chassis.dragTarget && (chassis.xSlotWidth > 0)) {

        // See how many banks we have.
        const numBanks = getNumBanks(chassis);

        // Get the layout from the LAST bank.
        const bankLayout = flexHAGetLayoutInfo(chassis, numBanks - 1);

        // Set our loc to be a copy of the layout's comps
        // loc, which contains non-ps, non-backplate elements.
        xSlotLoc = { ...bankLayout.nonPSCompsLoc };

        // Change the left side of the loc to its OLD right side.
        xSlotLoc.x = getLocRight(xSlotLoc);

        // And set its width to our default.
        xSlotLoc.width = flexHAGetDefaultXSlotWidth(PlatformFlexHA);
    }

    // Return whatever we end up with.
    return xSlotLoc;
}

const flexHAGetStageScaleInfo = (): StageScaleInfo => {
    return flexHAStageScaleInfo;
}

const flexHAGetPowerDetails = (chassis: Chassis): [supplied: PowerBreakdown, consumed: PowerBreakdown] => {
   return flexHAGetMODPowerBreakdown(chassis);
}

// Override.
const flexHAGetSlotLocation = (chassis: Chassis, slotNum: number): LocAndSize => {

    // TODO: TEMP?
    if (slotNum >= chassis.modules.length) {
        throw new Error('NEED TO SUPPORT X-SLOT HERE??');
    }

    // Call a helper to tell us which bank
    // the requested slot number resides in,
    // and the local offset of the slot inside
    // that bank.
    const [bankNum, bankSlotOffset] =
        getSlotBankInfo(chassis, slotNum);

    // Get the layout from the bank we were given.
    const layout = flexHAGetLayoutInfo(chassis, bankNum);

    // The loc we want should be found at the local
    // offset in the layout's slotLocs array. Return
    // a copy of it.
    const loc: LocAndSize = { ...layout.slotLocs[bankSlotOffset] };
    return loc;
}

const flexHAGetBankExpLoc = (bankExp: BankExpModule): LocAndSize => {
    const chassis = bankExp.parent;
    if (chassis) {
        const layout = flexHAGetLayoutInfo(chassis, bankExp.bankNum);
        const expLoc = bankExp.onRight
            ? layout.locTrailComp
            : layout.locLeadComp;
        if (expLoc) {
            return expLoc;
        }
    }
    throw new Error('Unexpected ERROR in flexHAGetBankExpLoc!');
}

const _5015SAPwrMsg =
    'SA Power is supplied via an SA Power Interface on each ' +
    'I/O Module Base Unit. You can see information about SA ' +
    'Power via the Details window.';

const flexHAGetDevicePowerTips = (device: GraphicalDevice, singleLineFrm?: boolean): PowerBreakdownTips => {
    const tips: PowerBreakdownTips = {};
    const category = getDeviceCategory(device.deviceType);
    let modPowerTips = false;
    switch (category) {
        case DeviceCategory.Chassis:
            modPowerTips = true;
            break;
        case DeviceCategory.Module:
            {
                // This should be the adapter
                const mod = device as ChassisModule;
                if (mod.slotIdx !== 0) {
                    // Something is not right, 
                    // return empty tips.
                    return tips;
                }

                // We're good. Show the MOD Pwr tips.
                modPowerTips = true;
            }
            break;
        case DeviceCategory.PS:
            {
                // Use MOD tips if not an SA PSU.
                modPowerTips = !flexHAIsSAPowerSupply(device);
            }
            break;
    }

    const label = (singleLineFrm ? 'N/A' : 'Not Applicable');
    if (modPowerTips) {
        tips.tipSA = {
            replTipText: _5015SAPwrMsg,
            replTipStatus: StatusLevel.Info,
            repLabel: label,
        };
    }
    else {
        tips.tipMod = {
            replTipText: 'An SA Power Supply does not supply MOD Power.',
            replTipStatus: StatusLevel.Info,
            repLabel: label,
        };
    }

    return tips;
}


const _getEmptyLayoutForAuxBank = (chassis: Chassis): FlexHALayoutInfo => {
    const chasLayout = chassis.layout as FlexHALayoutInfo;
    const auxLayout: FlexHALayoutInfo = { ...chasLayout };
    auxLayout.numFPDs = 0;
    auxLayout.slotLocs.length = 0;
    auxLayout.rightCapLoc = getEmptyLoc();
    auxLayout.size = getEmptySize();
    auxLayout.ioBaseLocs.length = 0;
    auxLayout.ioTBLocs.length = 0;
    auxLayout.backplateLoc = getEmptyLoc();
    return auxLayout;
}

const _getInitialBankStartSlots = (numIOBases: number, auxBanks: number): number[] => {

    const single = (auxBanks === 1);
    switch (numIOBases) {
        case 0:
            return [];

        case 1:
            return (single ? [1] : []);

        case 2:
            return (single ? [1] : [1, 5]);

        case 3:
        case 4:
            return (single ? [5] : [1, 9]);

        case 5:
            return (single ? [9] : [5, 13]);

        case 6:
            return (single ? [13] : [5, 17]);

        default:
            throw new Error('Unexpected ERROR in _getInitialBankStartSlots!');
    }
}

const _getMinBaseQty = (numBanks: number): number => {
    switch (numBanks) {
        case 1:
        case 2:
            return 1;

        case 3:
            return 2;

        default:
            throw new Error('ERROR: Invalid numBanks in _getMinBaseQty!');
    }
}

const _minNumBanks = 1;
const _maxNumBanks = 3;

const flexHASetNumBanks = (chassis: Chassis, numBanksReq: number): boolean => {

    // Get number of banks requested,
    // as limited by allowed range.
    const numBanksWanted =
        Math.min(Math.max(numBanksReq, _minNumBanks), _maxNumBanks);

    // Get the number of banks
    // the chassis currently has.
    const existingBanks = getNumBanks(chassis);

    // Return success right away if we already
    // have what's being asked for.
    if (numBanksWanted === existingBanks) return true;

    // See how many I/O bases we currently
    // have (combined from ALL existing banks).
    let existingBases = flexHAGetNumIOBases(chassis);

    // Determine the MINUMUM number of bases
    // we'd need for the requested banks.
    const minBasesReqd = _getMinBaseQty(numBanksWanted);

    // See how many we're short.
    const basesToAdd = Math.max(0, minBasesReqd - existingBases);

    // If any, add extras by extending
    // the chassis' module array, 4 slots
    // for each base.
    if (basesToAdd > 0) {
        chassis.modules.length += (basesToAdd * 4);
        existingBases += basesToAdd;
    }

    // If we get this far, we know we have
    // SOME sort of CHANGE, either adding
    // to or removeing from our bank qty.
    // Easy case first. If the user just
    // wants a single bank...
    if (numBanksWanted === 1) {

        // Then we'll have NO aux banks.
        // Just remove the array and return.
        chassis.auxBanks = undefined;
        return true;
    }

    // If requested qty is less than
    // what we currently have...
    if (existingBanks > numBanksWanted) {
        // Remove the extras.
        if (chassis.auxBanks) {
            const numToRemove = existingBanks - numBanksWanted;
            chassis.auxBanks.length -= numToRemove;
        }
        else {
            throw new Error('ERROR: missing auxBanks in flexHASetNumBanks!');
        }
    }
    else {
        // We're adding (auxBank qty 1 less than bank qty )
        const numAuxBanks = numBanksWanted - 1;

        // Philosophy is that whenever we're adding 1 or
        // more aux banks, it actually makes more sense
        // to restart existing locations to good starting
        // points for the number of banks. Call a helper
        // to get slot idxs where aux banks should start.
        const startSlots =
            _getInitialBankStartSlots(existingBases, numAuxBanks);

        // We should ALWAYS get at least 1 back. If so.
        if (startSlots.length > 0) {

            // Create a new array of aux banks.
            const auxArr = new Array<AuxBank>();

            // Add 1 for each idx we got back.
            startSlots.forEach(startSlot => {
                auxArr.push(_makeAuxBank(chassis, startSlot));
            });

            // Then use our array for the
            // auxBanks prop in the chassis.
            chassis.auxBanks = auxArr;
        }
        else {
            throw new Error('Unexpected ERROR in flexHASetNumBanks!');
        }
    }

    // With FlexHA, we should ALWAYS succeed.
    return true;
}

const flexHAOnChassisLoaded = (chassis: Chassis) => {
    const fhaChas = chassis as FlexHAChassis;
    if (fhaChas.saPSU) {
        fhaChas.saPSU.forEach(saPS => {
            saPS.parent = fhaChas;
        });
    }
    updateChassis(chassis, false);
}

const flexHADeleteBankExpMod = (chassis: Chassis, expMod: BankExpModule): boolean => {
    const bankToRemove = expMod.bankNum + (expMod.onRight ? 1 : 0);
    if (chassis.auxBanks && (chassis.auxBanks.length >= bankToRemove)) {
        chassisChanging(chassis);
        chassis.auxBanks.splice(bankToRemove - 1, 1);
        updateChassis(chassis);
        return true;
    }
    return false;
}

const flexHACanDeletePowerSupply = (psu: SelectableDevice): boolean => {
    const chassis = psu.parent;
    if (chassis && chassis.ps !== psu && flexHAIsSAPowerSupply(psu)) {
        // If the PSU is NOT a required/auto-added PSU...
        if ((psu as FlexHASAPowerSupply).autoAdded === false)
            return true;
    }

    return false;
}

const flexHADeletePowerSupply = (psu: SelectableDevice): boolean => {
    // We know it is an SA Pwr if true...
    if (flexHACanDeletePowerSupply(psu)) {
        return flexHADeleteSAPwr(psu);
    }
    return false;
}

const getFlexHAImpl = (): GeneralImplSpec => {
    return {
        platform: PlatformFlexHA,
        imageScaleFactor: 1.0,

        //replaceChassisPowerSupply?: _implReplaceChassisPowerSupply,
        //getNumSlots?: _implGetNumSlots;
        //getChassisSlotUsage?: _implGetChassisSlotUsage;
        //getSlotID?: _implGetSlotID;
        //createModule?: _implCreateModule;
        //addModuleToChassis?: _implAddModuleToChassis;
        ////////////////addNewModuleAtSlot?: _implAddNewModuleAtSlot; /// Obsolete - remove
        //getChassisElementAtPt?: _implGetChassisElementAtPt;
        //getChassisDetails?: _implGetChassisDetails;
        //getDeviceDetails?: _implGetDeviceDetails;
        //getXSlotWidthFor: snapGetXSlotWidthFor,
        //getImageSource: flexHAGetImageSource,

        createChassis: flexHACreateChassis,
        addModuleAtSlot: flexHAAddModuleAtSlot,
        canModuleBeAdded: flexHACanModuleBeAdded,
        deleteModuleAtSlot: flexHADeleteModuleAtSlot,
        getSlotFillerInfo: flexHAGetSlotFillerInfo,
        getChassisRenderer: flexHAGetChassisRenderer,
        updateChassisLayout: flexHAUpdateChassisLayout,
        canExtendChassis: flexHACanExtendChassis,
        getChassisSizeAsDrawn: flexHAGetChassisSizeAsDrawn,
        getDefaultXSlotWidth: flexHAGetDefaultXSlotWidth,
        getActBtnInfo: flexHAGetActionBtnInfo,
        addModulesToChassis: flexHAAddModulesToChassis,
        getChassisDropStatus: flexHAGetChassisDropStatus,
        dropDragDeviceOnChassis: flexHADropDragDeviceOnChassis,
        getMaxNewModules: flexHAGetMaxNewModules,
        getEmptySlotImage: flexHAGetEmptySlotImage,
        doesSlotQualifyForBtnAction: flexHADoesSlotQualifyForBtnAction,
        isPointOnPS: flexHAIsPointOnPS,
        getChassisElementAtPt: flexHAGetChassisElementAtPt,
        isPointOnBankExp: flexHAIsPointOnBankExp,
        getBankExpComp: flexHAGetBankExpComp,
        getXSlotLoc: flexHAGetXSlotLoc,
        getPowerDetails: flexHAGetPowerDetails,
        getSlotLocation: flexHAGetSlotLocation,
        getBankExpLoc: flexHAGetBankExpLoc,
        getDevicePowerTips: flexHAGetDevicePowerTips,
        duplicateChassis: flexHADuplicateChassis,
        getXSlotWidthFor: flexHAGetXSlotWidthFor,
        getLayoutScaleInfo: flexHAGetStageScaleInfo,
        setNumBanks: flexHASetNumBanks,
        onChassisLoaded: flexHAOnChassisLoaded,
        deleteBankExpMod: flexHADeleteBankExpMod,
        getChassisRendInfo: flexHAGetChassisRendInfo,
        getExternalPSU: flexHAGetExternalPSU,
        canDeletePowerSupply: flexHACanDeletePowerSupply,
        deletePowerSupply: flexHADeletePowerSupply,

        // Forward to Snap.
        getSlotTypeRestriction: snapGetSlotTypeRestriction,
        filterAvailableModules: snapFilterAvailableModules,
        getDefaultChassisName: snapGetDefaultChassisName,
        getModuleSlotRestriction: snapGetModuleSlotRestriction,
        configureChassis: snapConfigureChassis,

    };
}

export const RegisterFlexHAGeneralImpl = () => {
 
    // We ONLY need this if we are going to 
    // leverage "Snap" General Impl functions.
    RegisterSnapClientDetails(PlatformFlexHA, getPlatformDetails());

    RegisterGeneralImpl(getFlexHAImpl());
}
