export const ToolbarAction = {
    SETTINGS: "Settings",

    ADD: "Add",
    COPY: "Copy",
    DELETE: "Delete",
    INFO: "info",

    SAVE: "Save",
    UNDO: "Undo",
    REDO: "Redo",

    ZOOMIN: "ZoomIn",
    ZOOMOUT: "ZoomOut",

    RESIZE_W: "ResizeW",
    RESIZE_A: "ResizeA",

    COPYMODE: 'CopyMode',
    DELMODE: 'DeleteMode',
    NORMALMODE: 'NormalMode',

    ADDSLOTFILLERS: 'AddSlotFillers',
    REMOVESLOTFILLERS: 'RemoveSlotFillers',

    ADDCHASSIS: "AddChassis",
    ORGANIZECHASSIS: "OrganizeChassis",
    EDITALLCHASSIS: "EditAllChassis",
    ISSUES: 'Issues',

    DESIGN: "GotoDesign",
    LAYOUT: "GotoLayout",
    SUMMARY: "GotoSummary",

    ADDPOINTENTRY: "AddPointEntry",
    DELETEPOINTENTRY: "DeletePointEntry",
    COPYPOINTENTRY: "CopyPointEntry",
    INFOPOINTENTRY: "InfoPointEntry",

    DOWNLOADTEMPLATE: "DownloadTemplate",
    EXPORT: "Export",
    IMPORT: "Import",

    SAVEPROJECT: "SaveProject",
    GOTOPROJECT: "GotoProject",
};
