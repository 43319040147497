import React from "react";
import {
  getModuleSlotBreakdown,
  getModuleSlotID,
} from "../../../model/ChassisProject";
import { MICROLayoutInfo } from "../types/MicroTypes";
import MicroSelectableDeviceComp from "../../../components/microSelectableDeviceComp";
import {
  LayoutModeType,
  getChassisRenderSpec,
} from "../../../util/LayoutModeHelp";
import {
  ChassisRendType,
  DeviceType,
  ModuleDragStatus,
} from "../../../types/ProjectTypes";
import { LocAndSize } from "../../../types/SizeAndPosTypes";
import { getEmptyLoc } from "../../../util/GeneralHelpers";
import {
  MicroChassisCompProps,
  canExtendChassis,
} from "../../../implementation/ImplGeneral";
import ChassisElComp from "../../common/ChassisElComp";
import { ChassisElementType } from "../../common/CommonPlatformTypes";
import SlotIDComp from "../../../components/SlotIDComp";
import { defaultheight, defaultWidthBuDeletion, getDefaultIoexpansionDimension, scaleupfactor } from "../model/MicroGeneralImpl";

const MicroChassisComp = (props: MicroChassisCompProps) => {
  // Get our chassis layout. We should ALWAYS be able to.
  const layout = props.chassis.layout as MICROLayoutInfo;
  if (!layout) {
    throw new Error("MicroChassisComp passed chassis without valid layout!");
  }

  // This is a primary chassis unless our render type
  // specifically says we're a redundant secondary.
  const primaryChassis = props.renderType !== ChassisRendType.RedSecondary;

  const defaultRender = !props.renderType || !props.layoutMode;
  const modeForSpec = defaultRender ? undefined : props.layoutMode;

  // Call a helper to give us rendering details for the various
  // elements of our chassis (ps, modules, empty slots, etc.)
  const elRenderSpec = getChassisRenderSpec(
    modeForSpec,
    props.chassis,
    primaryChassis,
    props.showAsSelected === true
  );

  const _addXSlot = (props: MicroChassisCompProps):
  [show: boolean, loc: LocAndSize, id: string] => {
  const pluginLimit = props.chassis.pluginModules?.length || 0
  // Start with assumption we WON'T 
  // have an 'X' (extra empty) slot.
  let xWidth = 0;
  const [slotWidth , slotHeight] = getDefaultIoexpansionDimension(props.chassis)
  // If the chassis is a drag target AND
  // has a specified xSlot width...
  if (props.chassis.dragTarget && (props.chassis.xSlotWidth > 0)) {

      // Use it.
      xWidth = props.chassis.xSlotWidth;
  }
  else {
      // Not a drag target. We STILL might show
      // the x-slot if either the chassis is selected,
      // OR if we're in Copy layout mode.
      const xPossible = props.showAsSelected ||
          (props.layoutMode && (props.layoutMode.type === LayoutModeType.Copy));

      // If either AND the chassis can be extended...
      if (xPossible && canExtendChassis(props.chassis)) {

          // Get the default X slot width from
          // the platform, if any.
          xWidth = slotWidth * scaleupfactor;
      }
  }
  // If we ended up with a width...
  if (xWidth) {

      // See how many slot locs the chassis layout actually has.
      const slotLocs = props.chassis.layout.slotLocs.length;

      // Get a copy of the LAST one.
      const loc = { ...props.chassis.layout.slotLocs[slotLocs - 1] };

      if(props.chassis.layout.slotLocs.length === pluginLimit){
        const xpS = props.chassis.ps && props.chassis.bu? props.chassis.ps.imgSize.width : 0;
        loc.x = xpS + (props.chassis.bu?.imgSize.width || defaultWidthBuDeletion);
        loc.y =0
        loc.width = slotWidth * scaleupfactor;
        loc.height = slotHeight;
      }
      else{
      // Shift it to the right by its own width.
      loc.x += loc.width;

      // Set its new width to be what we found above.
      loc.width = xWidth;
      }



      const xSlotId =
      props.chassis.modules.filter(
        (mod) => mod?.deviceType === DeviceType.IOExpansion
      ).length + 1;

      // We DO want a right slot.
      return [true, loc, xSlotId.toString()];
  }

  // We DON'T want one.
  return [false, getEmptyLoc(), ''];
}

  const isIOmodules = props.chassis.modules.some(
    (mod) => mod?.deviceType === DeviceType.IOExpansion
  );

  const _addRightCapSlot = (props: MicroChassisCompProps): LocAndSize => {
    const layouRightCapLoc = props.chassis.layout.rightCapLoc;
 
    return layouRightCapLoc;
  };

  const [extraRightSlot, extraSlotLoc, extraSlotId] = _addXSlot(props);
  const layoutRightCapsLoc = _addRightCapSlot(props);

  const showRightCap =
    (!extraRightSlot || !props.chassis.selected) &&
    layout.rightCapImgSrc &&
    isIOmodules;

  // Get breakdown of i/o expansion slots (which have modules and which are empty).
  const [modSlots] = getModuleSlotBreakdown(props.chassis.modules);

  // See if our chassis should be depicted as 'selected'.
  const showSelected = props.showAsSelected === true;
  // Determine the opacity we'll use for empty slots.
  // We want them to be faded and lighter when we have
  // add ('+') action buttons on them.
  const showSlotIds = defaultRender ? false : true;
  const renderModule = (slotNum: number) => {
    const module = props.chassis.modules[slotNum];
    if (module) {
      const rndType =
        props.renderType !== undefined
          ? module.slotFiller
            ? elRenderSpec.slotFillers
            : elRenderSpec.modules
          : undefined;
      const slotId = showSlotIds ? getModuleSlotID(module) : undefined;
      return (
        <MicroSelectableDeviceComp
          key={slotNum}
          device={module}
          devDragStatus={module.dragStatus}
          showSelected={showSelected && props.localDeviceSelected === module}
          renderType={rndType}
          ptOrg={props.ptOrg}
          relLocation={layout.slotLocs[slotNum]}
          slotId={slotId}
        />
      );
    } else {
      return null;
    }
  };

  // Note: In order to best preserve key uniqueness
  // from render to render, we'll ONLY use an incrementing
  // number as a key for separators (slot seps and grp seps).
  // Those should ALWAYS be the same to the same chassis layout.
  return (
    <>
      {props.chassis.ps ? (
        <MicroSelectableDeviceComp
          key={"PS"}
          device={props.chassis.ps}
          devDragStatus={ModuleDragStatus.NA}
          showSelected={
            showSelected && props.localDeviceSelected === props.chassis.ps
          }
          renderType={props.renderType ? elRenderSpec.ps : undefined}
          ptOrg={props.ptOrg}
          relLocation={props.chassis.ps.loc}
        />
      ) : null}

      {props.chassis.bu ? (
        <MicroSelectableDeviceComp
          key={"BU"}
          device={props.chassis.bu}
          devDragStatus={ModuleDragStatus.NA}
          showSelected={
            showSelected && props.localDeviceSelected === props.chassis.bu
          }
          renderType={props.renderType ? elRenderSpec.bu : undefined}
          ptOrg={props.ptOrg}
          relLocation={props.chassis.bu.loc}
        />
      ) : <MicroSelectableDeviceComp
          key={"BU"}
          device={undefined}
          devDragStatus={ModuleDragStatus.NA}
          showSelected={
            showSelected && props.localDeviceSelected === props.chassis.bu
          }
          renderType={props.renderType ? elRenderSpec.bu : undefined}
          ptOrg={props.ptOrg}
          relLocation={{x: 0, y: 0, width: defaultWidthBuDeletion, height: defaultheight}}
      />}

      {modSlots.map((slot) => {
        return renderModule(slot);
      })}
      {showRightCap &&
      props.chassis.bu ? (
        <ChassisElComp
          key={"RC"}
          elType={ChassisElementType.RightCap}
          renderType={elRenderSpec.rightCap}
          imgSrc={layout.rightCapImgSrc}
          ptOrg={props.ptOrg}
          relLocation={layoutRightCapsLoc}
        />
      ) : null}


{extraRightSlot && (props.chassis.selected ||  (props.layoutMode?.type === LayoutModeType.Copy) || (props.layoutMode?.type === LayoutModeType.Drag) )
                ? <>
                    <ChassisElComp
                        key={'XRS'}
                        elType={ChassisElementType.EmptySlot}
                        renderType={elRenderSpec.emptySlots}
                        imgSrc={layout.emptySlotImgSrc}
                        ptOrg={props.ptOrg}
                        relLocation={extraSlotLoc}
                    />
                      <SlotIDComp
                        key={'SID' + extraSlotId}
                        slotId={extraSlotId}
                        ptOrg={props.ptOrg}
                        relSlotLoc={extraSlotLoc}
                        emptySlot={false}
                    />
                </>:  null
}

    </>
  );
};

export default MicroChassisComp;
